import React from "react";
import { ResponsiveBar } from "@nivo/bar";
import Lang from "../../../lang";
import CachedDataSingleton from "../../../cachedDataSingleton";
import { Grid, InputLabel } from "@material-ui/core";
import { graphColors, monthsName } from "../../../Utils/Utils";
import WithVatChartsFilters from "./WithVatChartsFilters";

type OverviewVatInvoicesProps = {
  overview: any;
  selectedTrimester: number;
};

function OverviewVatInvoices(props: OverviewVatInvoicesProps) {
  const lang = Lang.getInstance();
  const cachedData = CachedDataSingleton.getInstance();
  const overview = props.overview ? props.overview : cachedData.get("overview");
  const invoices = overview.invoicesVat;

  /**
   *
   * @returns Returns an array with the data formatted for a Bar chart
   */
  function formatChartData() {
    let graphData = [];
    let index = 0;
    switch (props.selectedTrimester) {
      case 1:
        index = 0;
        break;
      case 2:
        index = 3;
        break;
      case 3:
        index = 6;
        break;
      case 4:
        index = 9;
        break;
      default:
        index = 0;
        break;
    }

    for (let monthIndex = 0; monthIndex < 3; monthIndex++) {
      const coords = {
        month: monthsName()[index],
        invoices: invoices && invoices.months && invoices.months[monthIndex]
          ? invoices.months[monthIndex]
          : null,
        invoicesColor: graphColors().colorsTransparent.invoices
      };
      graphData.push(coords);
      index++;
    }
    return graphData;
  }

  const chartData = formatChartData();
  return (
    <>
      <InputLabel
        style={{
          color: "#000",
          marginBottom: 5,
          fontSize: 15,
          justifyContent: "center",
          alignContent: "center",
          display: "flex"
        }}
      >
        {lang.get("overview") +
          " - " +
          lang.get("vat") +
          ", " +
          lang.get("invoices")}
      </InputLabel>
      <Grid container spacing={3}>
        <Grid item md={6} sm={12} xs={12} style={{ minWidth: "100%" }}>
          {/** CHART */}
          <div style={{ height: 300, marginBottom: 10 }}>
            <ResponsiveBar
              data={chartData}
              colors={[graphColors().colorsTransparent.invoices]}
              keys={["invoices"]}
              indexBy="month"
              margin={{ top: 10, right: 110, bottom: 100, left: 70 }}
              groupMode="grouped"
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickRotation: 45,
                legend: lang.get("date"),
                legendOffset: 90,
                legendPosition: "middle"
              }}
              labelTextColor="rgb(255,255,255,0)"
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: lang.get("amount"),
                legendOffset: -60,
                legendPosition: "middle"
              }}
              legends={[
                {
                  dataFrom: "keys",
                  anchor: "bottom-right",
                  direction: "column",
                  justify: false,
                  translateX: 120,
                  translateY: 0,
                  itemsSpacing: 2,
                  itemWidth: 100,
                  itemHeight: 20,
                  itemDirection: "left-to-right",
                  itemOpacity: 0.85,
                  symbolSize: 20,
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemOpacity: 1
                      }
                    }
                  ]
                }
              ]}
            ></ResponsiveBar>
          </div>
        </Grid>
      </Grid>
    </>
  );
}
export default WithVatChartsFilters(OverviewVatInvoices);
