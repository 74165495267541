import React, { useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import Lang from "../lang";
import PropTypes from "prop-types";
import theme from "../Theme/Theme";
export default function ConfirmDialog(props) {
  const lang = Lang.getInstance();

  useEffect(() => {
    if (props.open) {
      const ua = navigator.userAgent.toLowerCase();
      if (ua.includes("applewebkit") && ua.includes("mobile")) {
        //nu facem nimic
        console.log(ua);
      } else {
        new Notification(lang.get("noActivity"));
      }
    }
  }, [props.open]);

  const handleClose = (e) => {
    const result = e.currentTarget.id === "ok" ? true : false;
    props.onResult(result);
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby="confirm"
        aria-describedby="confirm dialog"
      >
        <DialogTitle id="alert-dialog-title">{lang.get("confirm")}</DialogTitle>
        <DialogContent>
          <div dangerouslySetInnerHTML={{ __html: props.text }}></div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            id="ok"
            style={{ color: theme.palette.header?.main }}
          >
            {lang.get("ok")}
          </Button>
          <Button onClick={handleClose} id="cancel" color="secondary" autoFocus>
            {lang.get("cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

ConfirmDialog.propTypes = {
  text: PropTypes.string.isRequired,
  onResult: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
