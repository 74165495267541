import React, { useState } from "react";
import MessageDialog from "../Components/MessageDialog";
import Lang from "../lang";
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import CachedDataSingleton from '../cachedDataSingleton';
import {Subcontractor} from '../Models/Models';

const lang = Lang.getInstance();
const cachedDataSingleton = CachedDataSingleton.getInstance();

type SubcontractorsDialogProps = {
  open: boolean,
  onResult: Function,
};

type SubcontractorsDialogState = {
  selectedSubcontractorId: number,
};

export default function SubcontractorsDialog(props: SubcontractorsDialogProps) {
  const subcontractors = cachedDataSingleton.get("subcontractors");
  const [selectedSubcontractorId, setsSubcontractorId] = useState(subcontractors[0]?.id);
  /**
   * 
   * @param event 
   */
  function handleSubcontractorChange (event: any){
    setsSubcontractorId(parseInt(event.target.value));
  };


  return (
    <MessageDialog
      title={lang.get("folder")}
      open={props.open}
      onResult={() => props.onResult(selectedSubcontractorId)}
    >
          <FormControl>
            <InputLabel>{lang.get("folders")}</InputLabel>
            <Select 
              value={selectedSubcontractorId}
              onChange={handleSubcontractorChange}
            >
              {subcontractors.map((subcontractor:Subcontractor) => (
                <MenuItem value={subcontractor.id} key={subcontractor.id}>
                  {subcontractor.name }
                </MenuItem>
              ))}
            </Select>
          </FormControl>
    </MessageDialog>
  );
}
