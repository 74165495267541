import { Button, ButtonGroup, makeStyles, Paper } from "@material-ui/core";
import React, { useState } from "react";
import CachedDataSingleton from "../../../cachedDataSingleton";
import Lang from "../../../lang";
import { get } from "../../../Utils/Utils";


const useStyles = makeStyles((theme)=>({
  width100:{
    width:"100%"
  }
}));

const WithVatChartsFilters = (WrappedComponent: any) => {
  
  function WithVatChartsFilters(props: any) {
    const classes = useStyles();
    
    const lang = Lang.getInstance();
    const cachedData = CachedDataSingleton.getInstance();
    const [state, setState] = useState({
      selectedYear: new Date().getFullYear(),
      years: overviewYears(),
      selectedTrimester: cachedData.get("overview").trimester,
      overview: cachedData.get("overview")
    });

    /**
     * Return a array with all years from 2015 to the current year
     * @returns
     */
    function overviewYears() {
      const currentYear = new Date().getFullYear();
      let startYear = 2015;
      let years = [2015];
      while (startYear < currentYear) {
        startYear++;
        years.push(startYear);
      }
      return years;
    }

    /**
     *
     * @param year
     */
    async function getOverviewFromYear(
      selectedYear: number | null,
      selectedTrimester: number | null
    ) {
      let year = selectedYear ? selectedYear : state.selectedYear;
      let trimester = selectedTrimester
        ? selectedTrimester
        : state.selectedTrimester;

      let response = await get("/newVatOverview/" + year + "/" + trimester);
      if (response.error) return;
      setState({
        ...state,
        ...{
          selectedTrimester: trimester,
          selectedYear: year,
          overview: response
        }
      });
    }

    return (
      <Paper variant="outlined" style={{padding:20, width:"100%"}}>
        <div style={{ display: "flex", marginBottom: 5 }}>
          <ButtonGroup 
            size="small" 
            aria-label="small outlined button group"
            classes = {{root:classes.width100}}
          >
            {state.years.map((value: number, index: number) => {
              return (
                <Button
                  key={index}
                  style={{
                    backgroundColor:
                      value === state.selectedYear ? "#E0E0E0" : "#fff"
                  }}
                  onClick={() => getOverviewFromYear(value, null)}
                >
                  {value}
                </Button>
              );
            })}
          </ButtonGroup>
        </div>
        <div style={{ display: "flex", marginBottom: 15 }}>
          <ButtonGroup 
            size="small" 
            aria-label="small outlined button group"
            classes = {{root:classes.width100}}  
          >
            {[1, 2, 3, 4].map((value: number, index: number) => {
              return (
                <Button
                  key={index}
                  style={{
                    backgroundColor:
                      value === state.selectedTrimester ? "#E0E0E0" : "#fff"
                  }}
                  onClick={() => getOverviewFromYear(null, value)}
                >
                  {lang.get("trimester") + " " + value}
                </Button>
              );
            })}
          </ButtonGroup>
        </div>
        <WrappedComponent {...state} />
      </Paper>
    );
  }
  return WithVatChartsFilters;
};

export default WithVatChartsFilters;
