import { Paper } from "@material-ui/core";
import TimetrackingReportForm from "../../Components/Timetracking/TimetrackingReportForm";
import TimetrackingReportTable from "../../Components/Timetracking/TimetrackingReportTable";

type TimetrackingReportProps = {
  showSmallMessage: (message: string, type?: string) => void;
  onFetchResult: Function;
  onSubmit: (data: any) => void;
  reportUrl: string | undefined;
};

function TimetrackingReport(props: TimetrackingReportProps) {
  var columns = new Array();
  return (
    <>
      <Paper style={{ padding: 30 }}>
        <TimetrackingReportForm
          showSmallMessage={props.showSmallMessage}
          onSubmit={props.onSubmit}
        />
      </Paper>
      <br />
      <br />
      <TimetrackingReportTable
        url={props.reportUrl}
        onFetchResult={props.onFetchResult}
        columns={columns}
      />
    </>
  );
}

export default TimetrackingReport;
