import React, { Fragment, useState } from "react";
import {
  FormControl,
  IconButton,
  makeStyles,
  Modal,
  Paper,
  Slide,
  TextField,
  Typography,
} from "@material-ui/core";
import EditClient from "../Forms/EditClient";
import { Client } from "../Models/Models";
import Lang from "../lang";
import { UrlEnum } from "../Utils/Utils";
import CloseIcon from "@material-ui/icons/Close";
import CachedDataSingleton from "../cachedDataSingleton";
import Autocomplete, {
  AutocompleteChangeReason,
} from "@material-ui/lab/Autocomplete";
import EditIcon from "@material-ui/icons/Edit";
import AddCircleIcon from "@material-ui/icons/AddCircle";

const lang = Lang.getInstance();
const cachedData = CachedDataSingleton.getInstance();

const useStyles = makeStyles((theme) => ({
  modalWindow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalPaper: {
    maxWidth: 900,
    maxHeight: 900,
    paddingRight: 10,
    paddingLeft: 10,
    outline: "none",
  },
  contentWrapper: {
    overflowY: "scroll",
    overflowX: "hidden",
    height: 500,
    padding: 10,
  },
  iconButton: {
    marginTop: 10,
  },
  editButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

type EditClientModalProps = {
  client: Client | null;
  onClientChange: (
    e: any,
    value: Client | null,
    reason: AutocompleteChangeReason,
    details?: any
  ) => void;
  disabled: boolean;
};

export default function EditClientModal(props: EditClientModalProps) {
  var title =
    props.client?.id === 0
      ? lang.get("newClient")
      : lang.get("edit") + ": " + lang.get("client");
  const clients = cachedData.get("clients");
  const classes = useStyles(props);

  const [open, setOpen] = useState(false);
  const [newEditClient, setNewEditClient] = useState(props.client);
  const [error, setError] =useState("");
  /**
   * Add a new client in chachedData
   */
  const handleClientChange = (client: Client) => {
    let indexEditedModel = clients.findIndex((c: Client) => c.id === client.id);
    if (indexEditedModel >= 0) {
      clients.splice(indexEditedModel, 1, client);
    } else {
      clients.push(client);
    }
    cachedData.setKey("clients", clients);
    props.onClientChange(null, client, "select-option");
  };

  return (
    <Fragment>
      <FormControl required={true} fullWidth={true}>
        <div className={classes.editButton}>
          <Autocomplete
            disabled={props.disabled}
            style={{ marginLeft: 10 }}
            fullWidth={true}
            id="clientName"
            options={clients}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField {...params} 
              label={lang.get("clientName")} 
              required={true}  
              error={
                props.client &&
                props.client.id > 0
                  ? false
                  : true
              }/>
            )}
            onChange={(
              e: any,
              client: Client | null,
              reason: AutocompleteChangeReason,
              details?: any
            ) => {setError("");
                  if(!client)
                    setNewEditClient(client);
               return props.onClientChange(e, client, reason)}}
            value={props.client}
            getOptionSelected={(option) => option.id === props.client?.id}
          />
          <IconButton
            className={classes.iconButton}
            aria-label="edit"
            onClick={() => {
              if(props.client) 
                {
                  setNewEditClient(props.client);
                  setError("");
                }
              else
                setError(lang.get("nothingSelected"));
              setOpen(true);
            }}
          >
            <EditIcon fontSize="small" />
          </IconButton>

          <IconButton
            className={classes.iconButton}
            aria-label="add"
            onClick={() => {
                setNewEditClient(new Client());    
                setOpen(true);
            }}
          >
            <AddCircleIcon fontSize="small" />
          </IconButton>
        </div>
      </FormControl>
      {newEditClient ?
      <Modal
        className={classes.modalWindow}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <Slide direction="down" in={open} mountOnEnter unmountOnExit>
          <Paper className={classes.modalPaper}>
            <div
              style={{
                position: "relative",
                padding: 5,
                borderBottom: "1px solid #ccc",
              }}
            >
              <Typography variant="h5" gutterBottom>
                {title}
              </Typography>
              <IconButton
                style={{ position: "absolute", right: 0, top: 0 }}
                color="inherit"
                id="addNew"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <br></br>
            <div className={classes.contentWrapper}>
              <EditClient
                url={UrlEnum.clients}
                model={newEditClient}
                submitCallback={handleClientChange}
              /> 
            </div>
          </Paper>
        </Slide>
      </Modal>
      
      : ""}
      {error? 
      <p style={{color:"red"}}>{error}</p>: ""}
    </Fragment>
  );
}
