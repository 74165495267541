import {useRef, useEffect} from 'react';
import { Button, Dialog, DialogActions, DialogContent } from "@material-ui/core";
import Lang from "../../lang";
import scriptLoader from 'react-async-script-loader';
import { GoogleMapsUtils } from '../../Utils/Utils';
import theme from '../../Theme/Theme';

const lang = Lang.getInstance();

type TimeTrackingMapProps = {
    open: boolean,
    timeTrackingReportVal: any,
    onClose: () => void;
};

function TimeTrackingMap(props: TimeTrackingMapProps) {
    const mapWrapperRef = useRef(null);

    useEffect(() => {
        let gmu:GoogleMapsUtils | null = null;

        setTimeout(()=>{
            gmu = new GoogleMapsUtils({
                parent: mapWrapperRef
            });
            try{
                const positions = JSON.parse(props.timeTrackingReportVal.position);
                for(let i=0; i<positions.length; i++){
                    const p = positions[i];
                    const label = (i+1).toString();
                    gmu?.addMarker(p.lat, p.lng, label, label);
                }
                gmu?.map.setCenter({
                    lat:positions[positions.length-1].lat,
                    lng:positions[positions.length-1].lng,
                });
                gmu?.map.setZoom(10);
            }catch(e){
            }
        },500);
        return () => {
            gmu?.destruct();
            gmu = null;
        }
    },[props.timeTrackingReportVal]);
    
    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            maxWidth="lg"
        >
            <DialogContent>
                <div id="mapsWrapped" ref={mapWrapperRef} style={{height:400, width:600, marginBottom:50}} ></div>
            </DialogContent>
            <DialogActions>
                <Button variant="contained"
                    style={{background: theme.palette.errorColor?.main}}
                    onClick={() => props.onClose}>
                    {lang.get("cancel")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
export default scriptLoader("https://maps.google.com/maps/api/js?key=AIzaSyBAfEAPud1Ushe8qqMUCsEoUoH9hzk16ok&language=en")(TimeTrackingMap);