import { useState } from 'react';
import TableComponent from "../TableComponent";
import Lang from '../../lang';
import EditTimetracking from './EditTimetracking';
import DashboardContext from '../../Contexts/DashboardContext';
import { Divider, Grid, IconButton } from '@material-ui/core';
import RoomIcon from '@material-ui/icons/Room';
import TimeTrackingMap from './TimeTrackingMap';
import { Props } from 'react-pdf/dist/Document';

const lang = Lang.getInstance();

type TimetrackingReportTableProps = {
    url: string | undefined;
    onFetchResult: Function;
    columns: Array<object>|undefined;
};

function TimetrackingReportTable(props: TimetrackingReportTableProps) {
    const [editedTk, setEditedTk] = useState(null);
    const [mapTk, setMapTk] = useState(null);
    const [totals, setTotals] = useState({
        totalTime: 0,
        totalTimeWithPause: 0,
        totalPrice: 0
    });

    const columns = [
        { label: lang.get("employee"), name: "subcontractorPersonName" },
        { label: lang.get("subcontractor"), name: "subcontractorName" },
        { label: lang.get("client"), name: "clientName" },
        { label: lang.get("siteAddress"), name: "siteAddress" },
        { label: lang.get("start"), name: "startWork" },
        { label: lang.get("end"), name: "endWork" },
        { label: lang.get("timeElapsed"), name: "elapsedTime" },
        { label: lang.get("withBreak"), name: "elapsedTimeWithPause" },
        { label: lang.get("laborCost"), name: "pricePerHours" },
        {
            label: lang.get("map"), name: "",
            customRender: (tk: any) => (
                <IconButton
                    onClick={() => setMapTk(tk)}
                >
                    <RoomIcon />
                </IconButton>
            )
        },
    ];
    const headerTable = props.columns?.length===0? columns : props.columns;
    const onFetchResult = (data: any) => {
        setTotals({
            totalTime: data.totalTime,
            totalTimeWithPause: data.totalTimeWithPause,
            totalPrice: data.totalPrice
        });
        props.onFetchResult(data)
    };
    return (
        <>
            {props.url ?
                <div id="resultsWrapper">
                    <Grid container>
                        <Grid item md={1}>&nbsp;&nbsp;<strong><big>{lang.get("total")}:</big></strong></Grid>
                        <Grid item md={3}><strong>{lang.get("timeElapsed")}</strong>: {totals.totalTime}</Grid>
                        <Grid item md={3}><strong>{lang.get("withBreak")}</strong>: {totals.totalTimeWithPause}</Grid>
                        <Grid item md={3}><strong>{lang.get("laborCost")}</strong>: {totals.totalPrice}</Grid>
                    </Grid>
                    <Divider />
                    <TableComponent
                        url={props.url}
                        columns={headerTable}
                        pagination={false}
                        editCallback={(tk: any) => setEditedTk(tk)}
                        editedModels={[editedTk]}
                        onFetchResult={onFetchResult}
                    />
                </div>

                : null
            }
            {
                editedTk ?
                    <DashboardContext.Consumer>
                        {({ setSmallMessage }) => (
                            <EditTimetracking
                                timeTrackingReportVal={editedTk}
                                open={editedTk !== null && editedTk !== undefined}
                                onClose={() => setEditedTk(null)}
                                onUpdateSuccess={(m) => { setEditedTk({ ...m }); }}
                                setSmallMessage={setSmallMessage}
                            />)}
                    </DashboardContext.Consumer>
                    : null
            }
            {
                mapTk ?
                    <TimeTrackingMap
                        open={mapTk !== null && mapTk !== undefined}
                        timeTrackingReportVal={mapTk}
                        onClose={() => setMapTk(null)}
                    />
                    : null
            }
        </>
    );
}

export default TimetrackingReportTable;