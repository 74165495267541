import React, { useEffect, useState } from "react";
import PreviewPDF from "../../DocumentComponents/PreviewPDF";
import { UrlEnum } from "../../Utils/Utils";
import { ReminderFooter } from "./ReminderPDF/ReminderFooter";
import { reminderContent } from "./ReminderPDF/ReminderContent";
import Lang from "../../lang";

type reminderPreviewProps = {
  renderPreview: boolean;
  model: any;
  closePreview: () => void;
  hasLiability: () => void;
};

export default function ReminderPreview(props: reminderPreviewProps) {
  const [HTMLContent, setHTMLContent] = useState("");
  const { closePreview, renderPreview, model } = props;
  const lang = Lang.getInstance();

  let url = UrlEnum.pdf.replace("{type}", "reminder");
  url = url.replace("{id}", "0");

  useEffect(()=>{
    reminderContent(model).then(response => {
      if (response) setHTMLContent(response);
    });
  },[]);

  return (
    <PreviewPDF
      open={renderPreview}
      onClose={closePreview}
      htmlContent={HTMLContent}
      footerHtml={ReminderFooter()}
      setSmallMessage={() => {}}
      url={url}
      emailData={{
        to: model.email ? model.email:"",
        subject: lang.get("reminder"),
        message: ""
      }}
    />
  );
}
