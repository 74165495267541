import React from "react";
import { Button, InputLabel, Paper } from "@material-ui/core";
import Lang from "../../lang";
import logo_stripe from "../../Images/logo/cronoptimal_icon.svg";
import { IbanElement } from "@stripe/react-stripe-js";
const IBAN_STYLE = {
  base: {
    color: "#707070",
    fontSize: "16px",
    "::placeholder": {
      color: "#707070",
    },
    ":-webkit-autofill": {
      color: "#707070",
    },
  },
  invalid: {
    color: "#fa755a",
    iconColor: "#fa755a",
    ":-webkit-autofill": {
      color: "#fa755a",
    },
  },
};
const IBAN_ELEMENT_OPTIONS = {
  supportedCountries: ["SEPA"],
  placeholderCountry: "DE",
  style: IBAN_STYLE,
};

function Sepa(props) {
  const lang = Lang.getInstance();

  return (
    <form
      style={{
        maxWidth: "450px",
        marginTop: "2rem",
        padding: "1.3rem",
      }}
      onSubmit={props.onSubmit}
    >
      <Paper
        style={{
          padding: 10,
          width: "100%",
          boxShadow: "none",
        }}
      >
        <div style={{ display: "flex", margin: "20px 10px" }}>
          <img style={{ width: 50 }} src={logo_stripe} alt="logo" />{" "}
          <h2 style={{ margin: "10px 10px 10px 20px" }}>Bancontact</h2>
        </div>
        <InputLabel style={{ fontSize: "15px", margin: "15px 0px" }}>
          {lang.get("companyName")}: {props.client.name}
        </InputLabel>
        <InputLabel style={{ fontSize: "15px", margin: "15px 0px" }}>
          {lang.get("email")}: {props.client.email}
        </InputLabel>
        <InputLabel style={{ fontSize: "15px", margin: "15px 0px" }}>
          IBAN
        </InputLabel>
        <IbanElement options={IBAN_ELEMENT_OPTIONS} />
      </Paper>
      <div
        style={{ margin: "15px 6px", fontSize: "14px", textAlign: "justify" }}
      >
        <p>
        {lang.get("sepaTerms")}
        </p>
      </div>
      <Button
        size="large"
        disabled={props.disabled}
        fullWidth
        variant="contained"
        color="primary"
        style={{ margin: "10px 0px", color: "white" }}
        type="submit"
      >
         {lang.get("pay")}
      </Button>
    </form>
  );
}
export default Sepa;
