import { Button } from "@material-ui/core";
import { useStripe } from "@stripe/react-stripe-js";
import Lang from "../../lang";

export default function PayWithBancontact(props) {
  const stripe = useStripe();
  const lang = Lang.getInstance();

  function handlePayBancontact() {
    const description = `${props.company?.name} - ${lang.get("invoice")} ${
      props.invoice?.invoiceNo
    }`;
    const metadata = {
      clientEmail: props.client.email,
      invoiceId: props.invoice.id,
    };
    const owner = {
      email: props.client.email,
      name: props.client.name,
    };
    const amount = parseInt(props.invoice.totalWithVat * 100);
    return stripe
      .createSource({
        type: "bancontact",
        amount: amount,
        statement_descriptor: description,
        currency: props.invoice.currency,
        metadata: metadata,
        owner: owner,
        redirect: {
          return_url: document.location.href,
        },
      })
      .then((result) => {
        document.location.href = result.source.redirect.url;
      });
  }
  return (
    <Button
      size="large"
      fullWidth
      style={{
        backgroundColor: "#ff5e00",
        color: "white",
        fontSize: 15,
      }}
      onClick={handlePayBancontact}
    >
      {lang.get("pay")}
    </Button>
  );
}
