import LiabilityFooter from "../../../DocumentTemplates/Liabilitys/LiabilityFooter";
import CachedDataSingleton from "../../../cachedDataSingleton";
import ReactDOMServer from "react-dom/server";

const cachedData = CachedDataSingleton.getInstance();

export function ReminderFooter() {
  const company = cachedData.get("company");
  let footer = ReactDOMServer.renderToString(
    <div style={{ height: "100%", marginTop:"15px"}}>
      <LiabilityFooter company={company} />
    </div>
  );
  let newFooter: any = document.createElement("div");
  newFooter.innerHTML = footer;
  newFooter = `<html><head><meta charset='UTF-8'/></head><body>${newFooter.innerHTML}</body></html>`;
  return newFooter;
}
