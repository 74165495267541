import {
  CssBaseline,
  Grid,
  makeStyles,
  Button,
  Paper,
  Divider,
  RadioGroup,
  Radio,
  FormControlLabel,
  CircularProgress,
  Slide,
  Dialog,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import BasicPackage from "./BasicPackage";
import {
  UrlEnum,
  LocalUrlEnum,
  get,
  post,
  StatusEnum,
} from "../../Utils/Utils";
import { useParams, useHistory } from "react-router-dom";
import StripeCheckout from "react-stripe-checkout";
import logo from "../../Images/logo/cronotimal_orizontal.svg";
import logo_stripe from "../../Images/logo/cronoptimal_icon.svg";
import Visa from "../../Images/stripeCards/Visa.png";
import MasterCard from "../../Images/stripeCards/MasterCard_Logo.png";
import Maestro from "../../Images/stripeCards/Maestro.png";
import Sepa from "../../Images/stripeCards/logo-sepa.svg";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Checkout from "./Checkout";
import Lang from "../../lang";
const properties = [
  "Create and send invoices",
  " Create and send Proforma and credit notes",
  " Manage internal costs",
  "Billing reminders by email",
  "Create services, packages and articles libraries",
  "Sales and cost reports",
  "Access from any device",
  "Create a customer list (UNLIMITED)",
  "Advanced reporting and exporting",
  "Create and send estimates",
  "Create and send purchase orders",
  "Multi-user, multiple roles",
  "Manage subcontractor's time with mobile apps",
  "Manage subcontractors",
  "Time tracking with mobile applications",
];
const basicActive = [
  true,
  true,
  true,
  true,
  true,
  true,
  true,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
];
const ecoActive = [
  true,
  true,
  true,
  true,
  true,
  true,
  true,
  true,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
];
const advActive = [
  true,
  true,
  true,
  true,
  true,
  true,
  true,
  true,
  true,
  true,
  true,
  false,
  false,
  false,
  false,
];
const proActive = [
  true,
  true,
  true,
  true,
  true,
  true,
  true,
  true,
  true,
  true,
  true,
  true,
  true,
  true,
  true,
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  container: {
    height: "100vh",
    overflowY: "scroll",
  },

  button: {
    background: "white",
    fontSize: "15px",
    margin: "10px 20px",
    padding: "10px 20px",
    backgroundImage: "linear-gradient(#efeeee  40%, #b7b5b5)",
    filter: "drop-shadow(5px 3px 3px #918f8f)",
  },
  paper: {
    paddingTop: 26,
    paddingBottom: 26,
    margin: "auto",
    marginTop: "50px",
    width: "30%",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  smallMessage: {
    position: "fixed",
    top: 70,
    right: 20,
    zIndex: 2000,
    background: theme.palette.errorColor?.main,
    color: theme.palette.textColorSecondary?.main,
    minWidth: 260,
    padding: "10px 15px 10px 15px",
  },
  success: {
    background: theme.palette.successColor?.main,
  },
  error: {
    background: theme.palette.errorColor?.main,
  },
}));
let TEST_KEY = "pk_test_jrWj0oEHBAsJb14VMRM7Kiw5";
if (document.location.hostname.indexOf("cronoptimal") >= 0
  || document.location.hostname.indexOf("davany") >= 0 )
  TEST_KEY = "pk_live_t9rsQxudLDd401ApvnISTIgL";
export default function GetPackage() {
  const classes = useStyles();
  const stripePromise = loadStripe(TEST_KEY);
  const [client, setClient] = useState(null);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [showPackage, setShowPackage] = useState(true);
  const [openSepa, setOpenSepa] = useState(false);
  const [loading, setLoading] = useState(false);
  const [smallMessage, setSmallMessage] = useState(null);
  const [isCardChecked, setIsCardChecked] = useState("card");
  const smallMessageTimeout = 5000;
  const { id } = useParams();
  const lang = Lang.getInstance();
  const history = useHistory();

  useEffect(() => {
    const url = UrlEnum.clients + "/" + id;
    get(url)
      .then((response) => {
        if (response.errors) console.log(response.errors);
        else setClient(response);
      })
      .catch((error) => console.log(error));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleShowPackage(selPackage) {
    setShowPackage(!showPackage);
    setSelectedPackage(selPackage);
  }
  function handleCardChange(event) {
    setIsCardChecked(event.target.value);
  }
  function handleOpenSepa() {
    setOpenSepa(!openSepa);
  }
  const showSmallMessage = (message, status = StatusEnum.SUCCESS) => {
    setSmallMessage({ message: message, status: status });
    // hide after timeout
    setTimeout(() => {
      setSmallMessage(null);
    }, smallMessageTimeout);
  };
  async function handleToken(token) {
    setLoading(true);
    const form = new FormData();
    form.append("cardId", token ? token.card.id : null);
    form.append("tokenId", token ? token.id : null);
    form.append("description", selectedPackage.description);
    form.append("amount", Math.round(selectedPackage.amount * 100));
    form.append("clientId", client.id);
    form.append("clientEmail", client.email);
    form.append("istrial", 0);
    form.append("period", selectedPackage.period);
    form.append("package", selectedPackage.name);
    form.append("discount", client.discount ? client.discount : 0);

    const response = await post(UrlEnum.buyPackage, form);
    if (response.errors) {
      showSmallMessage(lang.get("error"), StatusEnum.ERROR);
      setLoading(false);
    } else {
      const url = `${UrlEnum.checkPaymentStatus}true/${token.card.id}`;
      get(url).then((res) => {
        if (res === "Paid") {
          setLoading(false);
          history.push(LocalUrlEnum.confirmPayment);
        } else {
          var startTime = new Date().getTime();
          const interval = setInterval(() => {
            get(url).then((res) => {
              if (res === "Paid") {
                setLoading(false);
                history.push({
                  pathname: LocalUrlEnum.confirmPayment,
                  state: { title: "Successfully paid!" },
                });
                clearInterval(interval);
              } else {
                if (new Date().getTime() - startTime > 60000) {
                  clearInterval(interval);
                  showSmallMessage(lang.get("error"), StatusEnum.ERROR);
                  setLoading(false);
                  return;
                }
              }
            });
          }, 10000);
        }
      });
    }
  }
  async function handleSubmitSepa(source) {
    setLoading(true);
    const form = new FormData();
    form.append("sourceId", source ? source.id : null);
    form.append("amount", Math.round(selectedPackage.amount * 100));
    form.append("internalClientId", client.id);
    form.append("subscription", selectedPackage.name);
    setOpenSepa(false);
    const response = await post(`${UrlEnum.buyPackage}/sepa`, form);
    if (response.errors) {
      showSmallMessage(lang.get("error"), StatusEnum.ERROR);
      setLoading(false);
    } else {
      history.push({
        pathname: LocalUrlEnum.confirmPayment,
        state: { title: "Payment processing!" },
      });
    }
  }

  function getPackages() {
    return (
      <>
        <div
          style={{
            width: 350,
            margin: "auto",
            marginTop: 30,
            marginBottom: 30,
          }}
        >
          <img src={logo} alt="logo" />
        </div>
        <Grid
          container
          spacing={3}
          justify="center"
          style={{ margin: 20, width: "auto" }}
        >
          <Grid item xs={3}>
            <BasicPackage
              name="superBasic"
              title="BASIC"
              description="You can try right now!"
              price="3.50"
              properties={properties}
              active={basicActive}
              handleShowPackage={handleShowPackage}
            />
          </Grid>
          <Grid item xs={3}>
            <BasicPackage
              name="minimal"
              title="ECONOMIC"
              description="The best option for beginners!"
              price="23.83"
              properties={properties}
              active={ecoActive}
              handleShowPackage={handleShowPackage}
            />
          </Grid>
          <Grid item xs={3}>
            <BasicPackage
              name="basic"
              title="ADVANCED"
              description="Best option for advanced!"
              price="49.95"
              properties={properties}
              active={advActive}
              handleShowPackage={handleShowPackage}
            />
          </Grid>
          <Grid item xs={3}>
            <BasicPackage
              name="professional"
              title="PRO"
              description="Everything a pro would need!"
              price="79.95"
              properties={properties}
              active={proActive}
              handleShowPackage={handleShowPackage}
            />
          </Grid>
        </Grid>
      </>
    );
  }

  function pay() {
    if (isCardChecked === "card") {
      return (
        <StripeCheckout
          stripeKey={TEST_KEY}
          token={handleToken}
          amount={Math.round(selectedPackage.amount * 100)}
          name="Cronoptimal"
          description={selectedPackage.description}
          image={logo_stripe}
          email={client ? client.email : ""}
        >
          <Button
            size="large"
            fullWidth
            style={{
              backgroundColor: "#ff5e00",
              color: "white",
              fontSize: 15,
            }}
          >
            {lang.get("pay")}
          </Button>
        </StripeCheckout>
      );
    } else {
      return (
        <Button
          size="large"
          fullWidth
          style={{
            backgroundColor: "#ff5e00",
            color: "white",
            fontSize: 15,
          }}
          onClick={handleOpenSepa}
        >
          {lang.get("pay")}
        </Button>
      );
    }
  }
  function getPaymentMethod() {
    return (
      <>
        <div style={{ width: "100%", textAlign: "center", marginTop: "70px" }}>
          <img src={logo} alt="logo" style={{ width: 350, margin: 10 }} />
          <h1 style={{ color: "white", fontWeight: "normal" }}>
            {lang.get("youChoose")} {selectedPackage.description.toLowerCase()}{" "}
            {lang.get("package").toLowerCase()} : {selectedPackage.amount}€
          </h1>
        </div>
        <Paper className={classes.paper}>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="card"
            name="radio-buttons-group"
            onChange={handleCardChange}
          >
            <div style={{ display: "flex", margin: 10 }}>
              <FormControlLabel
                value="card"
                control={<Radio />}
                style={{ margin: "20px 10px 10px 10px" }}
              />
              <img
                src={Visa}
                alt="visa"
                style={{
                  width: 90,
                  height: 35,
                  margin: "20px 10px 0px 10px",
                }}
              />
              <img
                src={MasterCard}
                alt="MasterCard"
                style={{
                  width: 100,
                  height: 55,
                  margin: "10px 10px 0px 10px",
                }}
              />
              <img
                src={Maestro}
                alt="Maestro"
                style={{
                  width: 90,
                  height: 53,
                  margin: "13px 10px 0px 10px",
                }}
              />
            </div>
            <Divider style={{ margin: "10px 20px" }} />
            <div style={{ display: "flex", margin: 10 }}>
              <FormControlLabel
                value="bancontact"
                control={<Radio />}
                style={{ margin: 10 }}
              />
              <img
                src={Sepa}
                alt="Maestro"
                style={{ width: 100, margin: 10 }}
              />
            </div>
            <div style={{ width: "50%", margin: "auto", marginTop: "30px" }}>
              {pay()}
            </div>
          </RadioGroup>
        </Paper>
        <Slide direction="left" in={smallMessage !== null}>
          <Paper
            className={`${classes.smallMessage} ${
              classes[smallMessage?.status]
            }`}
          >
            <p>{smallMessage?.message || ""}</p>
          </Paper>
        </Slide>
        {loading ? (
          <CircularProgress
            color="secondary"
            style={{
              position: "absolute",
              display: "block",
              margin: "0 auto",
              top: "39%",
              left: "49%",
            }}
          />
        ) : (
          ""
        )}
      </>
    );
  }
  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.root}>
        <div className={classes.container}>
          {showPackage ? getPackages() : getPaymentMethod()}
          {openSepa ? (
            <Dialog open={openSepa} onClose={handleOpenSepa}>
              <Elements stripe={stripePromise}>
                <Checkout
                  open={openSepa}
                  onClose={handleOpenSepa}
                  client={client}
                  package={selectedPackage}
                  handleSubmitSepa={handleSubmitSepa}
                  showSmallMessage={showSmallMessage}
                />
              </Elements>
            </Dialog>
          ) : (
            ""
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
