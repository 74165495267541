import CachedDataSingleton from "../../../cachedDataSingleton";
import ReactDOMServer from "react-dom/server";
import { Client } from "../../../Models/Models";
import {
  get,
  getLiabilityElapsedTime,
  macroReplacements,
  UrlEnum
} from "../../../Utils/Utils";
import Lang from "../../../lang";
import LiabilityItems from "../../../DocumentTemplates/Liabilitys/LiabilityItems";
import { replaceMacrosFromText } from "../ReplaceMacrosFromText";
import NoticeHeader from "../../../DocumentTemplates/Notices/NoticeHeader";
import { Divider } from "@material-ui/core";

const cachedData = CachedDataSingleton.getInstance();
const lang = Lang.getInstance();

export async function NotificationContent(model: any) {
  let response = await get(UrlEnum.documentTemplates+"/notice");
  if (response.errors) return "";
  else {
    //----------------------------------------------start Header-----------------
    const company = cachedData.get("company");
    let client = new Client();
    client.name = model.name;
    client.vatNo = model.vatNo;
    client.address.address = model.address;
    client.address.city = model.city;
    client.address.code = model.code;
    let header = ReactDOMServer.renderToString(
      <div>
        <NoticeHeader
          appearance={response.appearance ? response.appearance : 1}
          company={company}
          client={client}
          color={response.color ? response.color : "RGB(170,170,170)"}
        />
      </div>
    );
    let newHeader: any = document.createElement("div");
    newHeader.innerHTML = header;
    newHeader = `<html><head><meta charset='UTF-8'/></head><body>${newHeader.innerHTML}</body></html>`;

    //----------------------------------------------start Table text-----------------
    const invoices = model.invoices;
    let customTextTop = JSON.parse(response.customTextTop);
    customTextTop = ReactDOMServer.renderToString(
      <div>
        {customTextTop?.blocks[0]?.text ? customTextTop.blocks[0].text : ""}
      </div>
    );

    //----------------------------------------------start Table -----------------
    //calculeaza elapsed time
    invoices.map((invoice: any) => {
      invoice.elapsedTime = getLiabilityElapsedTime(invoice.invoiceDate);
      //invoice.reminderDate = moment(new Date(invoice.reminderDate)).format("DD-MM-yyyy");
    });

    let content = ReactDOMServer.renderToString(
      <div>
        <LiabilityItems
          items={invoices}
          color={response.color ? response.color : "RGB(170,170,170)"}
        />
      </div>
    );
    let htmlContent: any = document.createElement("div");
    htmlContent.innerHTML = content;
    htmlContent = `<html><head><meta charset='UTF-8'/></head><body>${htmlContent.innerHTML}</body></html>`;

    //-----------------------------------------------start Custom text and macro replacements------------
    let replacementResults = macroReplacements(model.invoices);
    let customText = JSON.parse(response.customText);
    customText = replaceMacrosFromText(customText.blocks, replacementResults);
    customText = ReactDOMServer.renderToString(
      <div>
        {customText
          ? customText.map((content: any, index: number) => {
              let textStyle = {};
              if (index >= customText.length - 5)
                textStyle = {textAlign: 'right' };
              if (content.text === " " && content.entityRanges.length > 0)
                return <Divider />;
              if (content.text === "") return <br />;
              return <div style={textStyle}>{content.text}</div>;
            })
          : ""}
      </div>
    );

    return newHeader + customTextTop + content + customText;
  }
}
