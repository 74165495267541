import React from "react";
import Lang from "../lang";
import { makeStyles } from "@material-ui/core/styles";
import FlexibleDashboard from "../Components/FlexibleDashboard/FlexibleDashboard";



const drawerWidth = 240;
const useStyles = makeStyles(theme => ({
  menuButton: {
    marginRight: theme.spacing(2)
  },
  hide: {
    display: "none"
  },

  listItemText: {
    fontSize: "10pt",
    textTransform: "capitalize"
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: 0
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: drawerWidth
  },
  test: {
    background: "#888",
  }
}));

export default function Overview (props) {
  const classes = useStyles(props);
  const lang = Lang.getInstance();


  return (
    <div style={{padding:25,height:"100%", overflowY:"scroll"}}>
     <FlexibleDashboard showSmallMessage={props.showSmallMessage} setLoading={props.setLoading}/>
      </div>
  );
}
