export function replaceMacrosFromText(textWithMacros: any, replacements: any) {
  let finalText = [...textWithMacros];
  Object.keys(replacements).forEach((value: string, index: number) => {
    textWithMacros.forEach((lineValue: any, lineIndex: number) => {
      if (lineValue.text.includes("${" + value + "}")) {
        finalText[lineIndex].text = finalText[lineIndex].text.replace(
          "${" + value + "}",
          replacements[value].toString()
        );
      }
    });
  });
  return finalText;
}
