import  { Fragment } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import { ResolutionBreakPoints, isMobile } from "../Utils/Utils";
import { makeStyles } from "@material-ui/core/styles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { AppBar, Button, Toolbar } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import theme from "../Theme/Theme";
// import { lang } from "moment";
import Lang from "../lang";

const mobileBreakPoint = ResolutionBreakPoints.md;
const docWidth = document.body.offsetWidth;
const lang = Lang.getInstance();

export default function DrawerLayout(props) {
  let drawerWidth = docWidth < mobileBreakPoint ? docWidth : docWidth / 2;
  drawerWidth = props.drawerWidth ? props.drawerWidth : drawerWidth;

  const useStyles = makeStyles((theme) => ({
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: "none",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      top: 65,
      height: "calc(100% - 65px)",
      overflowX: "hidden",
      position: "absolute",
      borderRight: "1px solid #ebebeb",
      background:
        "linear-gradient(90deg, rgba(221,226,232,1) 0%, rgba(255,255,255,1) 6%, rgba(255,255,255,1) 100%)",
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      paddingLeft: 15,
    },
    listItemText: {
      fontSize: "10pt",
      textTransform: "capitalize",
    },
    content: {
      height: "calc(100% - 128px)",
      width: "100%",
      position: "absolute",
      flexGrow: 1,
      overflowX: "hidden",
      overflowY: "auto",
      padding: `0px ${theme.spacing(1)}px 0 ${theme.spacing(1)}px`,
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginTop: 0,
      marginLeft: 0,
      [theme.breakpoints.down("sm")]: {
        overflowX: "hidden",
        overflowY: "auto",
        top: 123,
      },
    },
    contentShift: {
      height: "calc(100% - 128px)",
      position: "absolute",
      width: `calc(100% - ${drawerWidth}px)`,
      overflowX: "hidden",
      overflowY: "auto",
      padding: `0px ${theme.spacing(3)}px 0 ${theme.spacing(3)}px`,
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: drawerWidth,
    },
    appBar: {
      left: drawerWidth,
      top: -11,
      padding: 0,
      // transition: theme.transitions.create('left, width', {
      // 	easing: theme.transitions.easing.sharp,
      // 	duration: theme.transitions.duration.leavingScreen,
      // }),
      width: `calc(100% - ${drawerWidth}px)`,
      [theme.breakpoints.down("sm")]: {
        top: -40,
      },
    },
    appBarShift: {
      // transition: theme.transitions.create('left, width', {
      // 	easing: theme.transitions.easing.easeOut,
      // 	duration: theme.transitions.duration.enteringScreen,
      // }),
      left: 0,
      width: "100%",
    },
  }));

  const classes = useStyles(props);

  return (
    <Fragment>
      <Drawer
        anchor="left"
        open={props.open}
        className={classes.drawer}
        classes={{
          paper:
            props.classes && props.classes.paper
              ? props.classes.paper
              : classes.drawerPaper,
        }}
        variant={isMobile() ? "temporary" : "persistent"}
        transitionDuration={250}
        onClose={
          props.handleDrawerClose
            ? props.handleDrawerClose.bind(this)
            : () => {}
        }
      >
        <div className={classes.drawerHeader}>
          <span></span>
          {props.handleDrawerClose ? (
            <IconButton
              onClick={props.handleDrawerClose}
              className={classes.openMenu}
            >
              <ChevronLeftIcon />
            </IconButton>
          ) : null}
        </div>
        <div style={{ padding: "15px" }}>
          {/* drawer content goes here */}
          {props.drawerChildren}
        </div>
      </Drawer>
      <AppBar
        position="relative"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: !props.open,
        })}
      >
        <Toolbar
          variant="dense"
          style={{ background: theme.palette.dashboard?.main }}
        >
          <Button
            color="inherit"
            aria-label="menu"
            onClick={
              props.handleDrawerClose ? props.handleDrawerClose : () => {}
            }
          >
            <MenuIcon />
          </Button>
          {props.title ? (
            isMobile() && props.title === lang.get("subcontractorOrderTickets") ? (
              <h2 style={{ margin: "10px 2px" }}>{lang.get("employeesOrderTickets")}</h2>
            ) : (
              <h2 style={{ margin: "10px 2px" }}>{props.title}</h2>
            )
          ) : null}
          {props.menu ? props.menu : null}
        </Toolbar>
      </AppBar>

      <div
        className={clsx(classes.content, {
          [classes.contentShift]: props.open,
        })}
      >
        {/* content goes here */}
        {props.children}
      </div>
    </Fragment>
  );
}

DrawerLayout.propTypes = {
  drawerChildren: PropTypes.element,
  classes: PropTypes.object,
  drawerWidth: PropTypes.any,
  open: PropTypes.bool.isRequired,
  handleDrawerClose: PropTypes.func,
  children: PropTypes.any,
  menu: PropTypes.any,
  title: PropTypes.string,
};
