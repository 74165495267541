import React, { useEffect } from "react";
import {
  Select,
  AppBar,
  Toolbar,
  IconButton,
  Badge,
  Button,
  MenuItem,
  Menu,
  ListItemAvatar,
  // Avatar,
  ListItemText,
  Tooltip,
} from "@material-ui/core";

// import LibraryBooks from "@material-ui/icons/LibraryBooks";
// import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import Person from "@material-ui/icons/Person";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import NotificationsIcon from "@material-ui/icons/Notifications";
// import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
// import MoreIcon from "@material-ui/icons/More";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../Images/logo/cronotimal_orizontal.svg";
import Lang, { Languages } from "../lang";
import { Link, useHistory } from "react-router-dom";
import {
  get,
  post,
  UrlEnum,
  isMobile,
  UserRolesEnum,
  logoutUtils,
  LocalUrlEnum,
} from "../Utils/Utils";
import moment from "moment";
import AddIcon from "@material-ui/icons/Add";
import Notifications from "./Notifications";
import { checkUserRoles } from "../Utils/CheckUserRoles";
import useNoActivity from "../Hooks/useNoActivity";
import MenuIcon from "@material-ui/icons/Menu";
import ConfirmDialog from "./ConfirmDialog";
import theme from "../Theme/Theme";
import CachedDataSingleton from "../cachedDataSingleton";
import {
  IconDocuments,
  IconHumanResources,
  IconProjects,
  IconMore,
} from "../Icons";

// mui make styles hook
const useStyles = makeStyles((theme) => ({
  toolbar: {
    background: theme.palette.header.main,
    overflow: "hidden",
    paddingLeft: 10,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 10,
    },
  },
  normalMenu: {
    marginTop: 25,
    "& > div:nth-child(3)": {
      overflow: "visible",
    },
  },
  docMenuPaper: {
    maxWidth: 900,
    // zIndex: 2000
  },
  docMenuPaperMobile: {
    left: "0 !important",
    maxWidth: 900,
    // zIndex: 2000
  },
  menu: {
    marginTop: 25,
    "& ul": {
      display: "flex",
      flexWrap: "wrap",
      paddingLeft: 5,
      paddingRight: 5,
    },
    "& > div:nth-child(3)": {
      overflow: "visible",
    },
  },
  menuItem: {
    width: "50%",
    minHeight: 95,
    whiteSpace: "normal",
    fontSize: "10pt",
    "& button>span:first-child": {
      marginLeft: -20,
    },
  },
  menuItemMobile: {
    width: "100%",
    whiteSpace: "normal",
    fontSize: "10pt",
    borderBottom: "1px solid #ccc",
    position: "relative",
    padding: 20,
    "& button": {
      position: "absolute",
      right: 5,
      top: 20,
    },
  },
  pointArrow: {
    content: '""',
    display: "block",
    width: "0",
    height: "0",
    position: "absolute",
    borderLeft: "8px solid transparent",
    borderRight: "8px solid transparent",
    borderBottom: "8px solid #fff",
    right: 20,
    top: -8,
  },
  circle: {
    background: "#ffffff22",
    borderRadius: "50% 50% 50% 50%",
    position: "absolute",
    width: 800,
    height: 500,
    top: -20,
    right: -200,
  },
  button: {
    [theme.breakpoints.down("md")]: {
      paddingLeft: 10,
      paddingRight: 10,
    },
  },
  buttonLabel: {
    textTransform: "none",
    [theme.breakpoints.down("sm")]: {
      fontSize: 0,
    },
    "& span": {
      position: "relative",
      top: 2,
    },
    color: theme.palette.textColor.main,
  },
  documentButtonsLabel: {
    textTransform: "none",
    "& span": {
      position: "relative",
      top: 2,
    },
  },
  logo: {
    margin: "5px 0px 0px 15px",
    [theme.breakpoints.down("sm")]: {
      width: 110,
      height: 25,
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  personsMenu: {
    minWidth: 340,
  },
  icon: {
    verticalAlign: "middle",
  },
  fullWidth: {
    width: "100%",
    height: "100%",
    display: "block",
    paddingTop: 5,
  },
  // index2000: {
  //   zIndex: 2000
  // }
}));

let logoutTimeout;

/**
 * Component definition
 */
export default function Header(props) {
  const history = useHistory();
  const classes = useStyles(props);
  const hasActivity = useNoActivity();

  // lang object
  const lang = Lang.getInstance();
  const cachedData = CachedDataSingleton.getInstance();
  const internalClientId = cachedData.get("internalClientId");
  const urlTrial = `${LocalUrlEnum.isTrial}/${internalClientId}`;
  const userName = cachedData.get("userData").userName;
  // state hook
  const [anchors, setAnchorEl] = React.useState({
    documents: null,
    people: null,
    options: null,
    notifications: null,
    more: null,
  });
  const [language, setLanguage] = React.useState(lang.getActive());
  const [resetNotifications, setResetNotifications] = React.useState(false);

  useEffect(() => {
    if (!hasActivity) {
      logoutTimeout = setTimeout(() => {
        logout();
      }, 25 * 60 * 1000);
    } else {
      if (logoutTimeout) {
        clearTimeout(logoutTimeout);
      }
    }
  }, [hasActivity]);

  /**
   * handle click event for dropdown buttons
   * @param {Object} event
   */
  const handleClick = (event) => {
    let currentTarget = event.currentTarget;
    switch (currentTarget.id) {
      case "documents":
        setAnchorEl({
          documents: currentTarget,
          people: null,
          options: null,
          notifications: null,
          more: null,
        });
        break;
      case "people":
        setAnchorEl({
          documents: null,
          people: currentTarget,
          options: null,
          notifications: null,
          more: null,
        });
        break;
      case "options":
        setAnchorEl({
          documents: null,
          people: null,
          options: currentTarget,
          notifications: null,
          more: null,
        });
        break;
      case "notifications":
        setAnchorEl({
          documents: null,
          people: null,
          options: null,
          notifications: currentTarget,
          more: null,
        });
        post(UrlEnum.notificationsDeactivate, {}).then((response) => {
          if (!response.errors) {
            setTimeout(setResetNotifications(true), 5000);
          }
        });
        break;
      case "more":
        setAnchorEl({
          documents: null,
          people: null,
          options: null,
          notifications: null,
          more: currentTarget,
        });
        break;
      default:
        break;
    }
  };

  /**
   * handle close for dropdown buttons
   */
  const handleClose = () => {
    setAnchorEl({
      documents: null,
      people: null,
      options: null,
      notifications: null,
    });
  };

  /**
   * change active language
   */
  const handleChangeLanguage = async (event) => {
    const newLang = event.target.value;
    document.cookie = `lang=${newLang}; expires=${moment()
      .add("days", 365)
      .toString()}; path=/`; //expires in a year
    lang.set(newLang);
    const url = UrlEnum.locale + newLang;
    const response = await get(url);
    if (response) document.location.reload();
  };

  /**
   * change country code to flag
   */
  function countryToFlag(isoCode) {
    return typeof String.fromCodePoint !== "undefined"
      ? isoCode
          .toUpperCase()
          .replace(/./g, (char) =>
            String.fromCodePoint(char.charCodeAt(0) + 127397)
          )
      : isoCode;
  }

  const logout = () => {
    logoutUtils();
  };

  const handleDocClick = (e, link) => {
    e.stopPropagation();
    e.cancelBubble = true;
    history.push(link);
    handleClose();
  };

  /**
   * what happens on no activity confirm result
   */
  const noActivityResult = (result) => {
    if (result) {
      logout();
    } else {
      clearTimeout(logoutTimeout);
    }
  };

  const buttonSize = isMobile() ? "medium" : "large";
  // render
  return (
    <>
      <AppBar position="fixed" style={{ zIndex: 1300 }}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.circle}></div>

          <IconButton theme="dark" onClick={props.menuButtonClick}>
            <MenuIcon
              style={{ color: theme.palette.textColorSecondary?.main }}
            />
          </IconButton>

          <Link to="/">
            <img
              src={logo}
              alt="Cronoptimal"
              height="30"
              className={classes.logo}
            />
          </Link>
          <div align="right" style={{ width: "100%" }}>
            {/** PROJECTS */}
            {"PROJECTS" in props.menuOptions
              ? Object.keys(props.menuOptions).map((key, index) => {
                  if (key === "PROJECTS")
                    return (
                      <Link key={index} to={props.menuOptions[key].link}>
                        <Tooltip title={props.menuOptions[key].label}>
                          <Button
                            classes={{
                              root: classes.button,
                              label: classes.buttonLabel,
                            }}
                            id={props.menuOptions[key].id}
                            size={buttonSize}
                          >
                            <IconProjects
                              color={theme.palette.textColorSecondary?.main}
                              width={25}
                              height={25}
                            />
                            {/* <span style={{ color: theme.palette.textColorSecondary?.main}}>&nbsp;&nbsp;{props.menuOptions[key].label}</span> */}
                          </Button>
                        </Tooltip>
                      </Link>
                    );
                })
              : null}

            {/** DOCUMENTS */}
            {"DOCUMENTS" in props.menuOptions ? (
              <Tooltip title={lang.active.documents}>
                <Button
                  classes={{
                    root: classes.button,
                    label: classes.buttonLabel,
                  }}
                  onClick={handleClick}
                  id="documents"
                  size={buttonSize}
                >
                  <IconDocuments
                    color={theme.palette.textColorSecondary?.main}
                  />
                  {/* <span style={{ color: theme.palette.textColorSecondary?.main}}>&nbsp;&nbsp;{lang.active.documents} &nbsp;</span> */}
                  {/* <ArrowDropDownIcon fontSize="small" style={{ color: theme.palette.textColorSecondary?.main}}/> */}
                </Button>
              </Tooltip>
            ) : null}
            <Menu
              className={classes.menu}
              classes={{
                paper: isMobile()
                  ? classes.docMenuPaperMobile
                  : classes.docMenuPaper,
              }}
              id="customized-menu"
              anchorEl={anchors.documents}
              keepMounted
              open={Boolean(anchors.documents)}
              onClose={handleClose}
            >
              <div className={classes.pointArrow} style={{ right: 250 }}></div>
              {"DOCUMENTS" in props.menuOptions
                ? Object.keys(props.menuOptions.DOCUMENTS).map((key, index) => {
                    return (
                      <MenuItem
                        key={index}
                        id={props.menuOptions.DOCUMENTS[key].id}
                        onClick={(e) =>
                          handleDocClick(
                            e,
                            props.menuOptions.DOCUMENTS[key].link
                          )
                        }
                        className={
                          isMobile() ? classes.menuItemMobile : classes.menuItem
                        }
                      >
                        <ListItemAvatar>
                          {props.menuOptions.DOCUMENTS[key].icon}
                        </ListItemAvatar>
                        <ListItemText
                          primary={props.menuOptions.DOCUMENTS[key].label}
                          secondary={
                            <Tooltip title={lang.get("create")}>
                              <Button
                                onClick={(e) =>
                                  handleDocClick(
                                    e,
                                    props.menuOptions.DOCUMENTS[key].link + "/0"
                                  )
                                }
                                size="small"
                                classes={{
                                  label: classes.documentButtonsLabel,
                                }}
                              >
                                <AddIcon />
                                <span>{lang.get("new")}</span>
                              </Button>
                            </Tooltip>
                          }
                        />
                      </MenuItem>
                    );
                  })
                : null}
            </Menu>

            {/** PEOPLE */}
            {"PEOPLE" in props.menuOptions ? (
              <Tooltip title={lang.get("hr")}>
                <Button
                  classes={{
                    root: classes.button,
                    label: classes.buttonLabel,
                  }}
                  onClick={handleClick}
                  id="people"
                  size={buttonSize}
                >
                  <IconHumanResources
                    color={theme.palette.textColorSecondary?.main}
                  />
                  {/* <ArrowDropDownIcon fontSize="small"style={{ color: theme.palette.textColorSecondary?.main}} /> */}
                </Button>
              </Tooltip>
            ) : null}
            <Menu
              className={classes.menu}
              id="customized-menu"
              anchorEl={anchors.people}
              keepMounted
              open={Boolean(anchors.people)}
              onClose={handleClose}
              classes={{ paper: classes.personsMenu }}
            >
              <div className={classes.pointArrow} style={{ right: 185 }}></div>
              {"PEOPLE" in props.menuOptions
                ? Object.keys(props.menuOptions.PEOPLE).map((key, index) => {
                    return (
                      <Tooltip
                        key={index}
                        title={props.menuOptions.PEOPLE[key].label}
                      >
                        <MenuItem className={classes.menuItem}>
                          <div align="center" className={classes.fullWidth}>
                            <Link
                              to={props.menuOptions.PEOPLE[key].link}
                              className={classes.fullWidth}
                              onClick={handleClose}
                            >
                              {/*<PeopleOutlineIcon className={classes.blueText} />*/}
                              {props.menuOptions.PEOPLE[key].icon}
                              <p>{props.menuOptions.PEOPLE[key].label}</p>
                            </Link>
                          </div>
                        </MenuItem>
                      </Tooltip>
                    );
                  })
                : null}
            </Menu>

            {/** MORE */}
            {"MORE" in props.menuOptions ? (
              <Tooltip
                title={`${lang.get("services")} | ${lang.get(
                  "articles"
                )} | ${lang.get("costs")} | ${lang.get("timekeeping")}`}
              >
                <Button
                  classes={{
                    root: classes.button,
                    label: classes.buttonLabel,
                  }}
                  onClick={handleClick}
                  id="more"
                  size={buttonSize}
                >
                  <IconMore
                    color={theme.palette.textColorSecondary?.main}
                    width={21}
                    height={21}
                  />
                  {/* <ArrowDropDownIcon fontSize="small" style={{ color: theme.palette.textColorSecondary?.main}}/> */}
                </Button>
              </Tooltip>
            ) : null}
            <Menu
              className={classes.menu}
              id="customized-menu"
              anchorEl={anchors.more}
              keepMounted
              open={Boolean(anchors.more)}
              onClose={handleClose}
            >
              <div className={classes.pointArrow} style={{ right: 123 }}></div>
              {"MORE" in props.menuOptions
                ? Object.keys(props.menuOptions.MORE).map((key, index) => {
                    return (
                      <Tooltip
                        key={index}
                        title={props.menuOptions.MORE[key].label}
                      >
                        <MenuItem className={classes.menuItem}>
                          <div align="center" className={classes.fullWidth}>
                            <Link
                              to={props.menuOptions.MORE[key].link}
                              className={classes.fullWidth}
                              onClick={handleClose}
                            >
                              {props.menuOptions.MORE[key].icon}
                              <p>{props.menuOptions.MORE[key].label}</p>
                            </Link>
                          </div>
                        </MenuItem>
                      </Tooltip>
                    );
                  })
                : null}
            </Menu>

            {/**NOTIFICATION */}
            {checkUserRoles([
              UserRolesEnum.ADMIN,
              UserRolesEnum.INVOICING,
              UserRolesEnum.ESTIMATES,
              UserRolesEnum.SUBCONTRACTORS,
              UserRolesEnum.TIMEKEEPING,
              UserRolesEnum.ACCOUNTING,
            ]) ? (
              <Notifications
                notificationsAnchor={anchors.notifications}
                handleClose={handleClose}
                resetNotifications={resetNotifications}
                render={(notifications) => {
                  const active =
                    notifications.filter(
                      (notification) => notification.active
                    ) || [];

                  return (
                    <IconButton
                      size={isMobile() ? "small" : "medium"}
                      color="inherit"
                      onClick={handleClick}
                      id="notifications"
                    >
                      <Badge badgeContent={active.length} color="secondary">
                        <NotificationsIcon
                          style={{
                            color: theme.palette.textColorSecondary?.main,
                          }}
                        />
                      </Badge>
                    </IconButton>
                  );
                }}
              />
            ) : null}

            {/**OPTIONS */}
            {"OPTIONS" in props.menuOptions ? (
              <IconButton
                size={isMobile() ? "small" : "medium"}
                color="inherit"
                onClick={handleClick}
                id="options"
              >
                <Person
                  style={{ color: theme.palette.textColorSecondary?.main }}
                />
              </IconButton>
            ) : null}
            <Menu
              className={classes.normalMenu}
              anchorEl={anchors.options}
              open={Boolean(anchors.options)}
              onClose={handleClose}
            >
              <div className={classes.pointArrow}></div>
              <MenuItem>
                <p style={{ textAlign: "center" }}>
                  {lang.get("username")}: {userName}
                </p>
              </MenuItem>
              <MenuItem>
                <Select
                  fullWidth
                  id="langSelect"
                  value={language}
                  onChange={handleChangeLanguage}
                >
                  {Languages.map((lang) => (
                    <MenuItem key={lang.shortName} value={lang.shortName}>
                      {countryToFlag(lang.shortName)} {lang.name}
                    </MenuItem>
                  ))}
                </Select>
              </MenuItem>
              {"OPTIONS" in props.menuOptions
                ? Object.keys(props.menuOptions.OPTIONS).map((key, index) => {
                    return (
                      <MenuItem key={key}>
                        <Link to={props.menuOptions.OPTIONS[key].link}>
                          <p>
                            {props.menuOptions.OPTIONS[key].icon}
                            &nbsp;
                            {props.menuOptions.OPTIONS[key].label}
                          </p>
                        </Link>
                      </MenuItem>
                    );
                  })
                : null}
              <MenuItem onClick={logout}>
                <p>
                  <ExitToAppIcon className={classes.icon} /> &nbsp;
                  {lang.active.logout}
                </p>
              </MenuItem>
              <MenuItem>
                <a href={urlTrial} style={{ width: "100%" }}>
                  <Button
                    fullWidth
                    style={{
                      background: theme.palette.orangeColor?.main,
                      color: theme.palette.textColorSecondary?.main,
                    }}
                  >
                    {lang.get("upgrade")}
                  </Button>
                </a>
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      {
        <ConfirmDialog
          open={!hasActivity ? true : false}
          text={lang.get("noActivity")}
          onResult={noActivityResult}
        />
      }
    </>
  );
}
