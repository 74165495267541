import React from "react";
import Lang from "../../../lang";
import CachedDataSingleton from "../../../cachedDataSingleton";
import { Grid, InputLabel } from "@material-ui/core";
import { graphColors } from "../../../Utils/Utils";
import { ResponsivePie } from "@nivo/pie";
import WithChartsFilters from "./WithChartsFilters";

type ProfitProps = {
  overview: any;
  startMonth: number;
  endMonth: number;
  selectedStartYear: number;
  selectedEndYear: number;
};

function Profit(props: ProfitProps) {
  const lang = Lang.getInstance();
  const cachedData = CachedDataSingleton.getInstance();
  const overview = props.overview ? props.overview : cachedData.get("overview");
  const invoices = overview.invoices;
  const costs = overview.costs;

  let invoiceData: {
    id: string | number;
    color: string;
    label: string;
    value: number;
  } = {
    id: lang.get("invoices") + " - Euro",
    color: graphColors().colors.invoices,
    label: lang.get("invoices"),
    value: 0
  };
  let costData: {
    id: string | number;
    color: string;
    label: string;
    value: number;
  } = {
    id: lang.get("costs") + " - Euro",
    color: graphColors().colors.costs,
    label: lang.get("costs"),
    value: 0
  };
  let profitData: {
    id: string | number;
    color: string;
    label: string;
    value: number;
  } = {
    id: lang.get("profit") + " - Euro",
    color: graphColors().colors.profit,
    label: lang.get("profit"),
    value: 0
  };

  /**
   *
   * @returns Returns an array with the data formatted for a Pie chart
   */
  function formatChartData() {
    let graphData = [];
    let index = props.startMonth ? props.startMonth : 0;
    const invoicesLength =
      12 * (props.selectedEndYear - props.selectedStartYear) +
      props.endMonth +
      1;

    let invoicesTotal = 0;
    for (index; index < invoicesLength; index++) {
      invoicesTotal +=
        invoices && invoices.months && invoices.months[index]
          ? invoices.months[index]
          : 0;
    }
    invoiceData.value = parseFloat(invoicesTotal.toFixed(2));

    //const costsTotal = costs.total;
    let costsTotal = 0;
    index = props.startMonth ? props.startMonth : 0;
    for (index; index < invoicesLength; index++) {
      costsTotal += costs && costs.months[index] ? costs.months[index] : 0;
    }
    costData.value = parseFloat(costsTotal.toFixed(2));

    //const profitTotal = invoices.total - costs.total;
    let profitTotal = invoicesTotal - costsTotal;
    profitData.value = parseFloat(profitTotal.toFixed(2));

    graphData.push(invoiceData);
    graphData.push(costData);
    graphData.push(profitData);
    return graphData;
  }
  const chartData = formatChartData();
  return (
    <>
      <InputLabel
        style={{
          color: "#000",
          marginBottom: 5,
          fontSize: 15,
          justifyContent: "center",
          alignContent: "center",
          display: "flex"
        }}
      >
        {lang.get("profit")}
      </InputLabel>
      <Grid container spacing={3}>
        <Grid item md={6} sm={12} xs={12} style={{ minWidth: "100%" }}>
          {/** CHART */}
          <div style={{ height: 300, marginBottom: 10 }}>
            <ResponsivePie
              colors={[
                graphColors().colors.invoices,
                graphColors().colors.costs,
                graphColors().colors.profit
              ]}
              data={formatChartData()}
              margin={{ top: 10, right: 110, bottom: 100, left: 70 }}
              innerRadius={0.5}
              padAngle={0.7}
              cornerRadius={3}
              borderWidth={1}
              borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
              legends={[
                {
                  anchor: "bottom",
                  direction: "row",
                  justify: false,
                  translateX: 0,
                  translateY: 56,
                  itemsSpacing: 0,
                  itemWidth: 100,
                  itemHeight: 18,
                  itemTextColor: "#999",
                  itemDirection: "left-to-right",
                  itemOpacity: 1,
                  symbolSize: 18,
                  symbolShape: "circle",
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemTextColor: "#000"
                      }
                    }
                  ]
                }
              ]}
            ></ResponsivePie>
          </div>
        </Grid>
      </Grid>{" "}
    </>
  );
}
export default WithChartsFilters(Profit,"profit");
