import React, { Fragment } from "react";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import { Switch, Route } from "react-router-dom";
import LoginPage from "./Pages/Login";
import Wizard from "./Wizard/Wizzard";
import PillTabs from "./Pages/PillTabs";
import Dashboard from "./Pages/Dashboard";
import Lang from "./lang";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { LocalUrlEnum, readCookie } from "./Utils/Utils";
import OnlineDoc from "./Pages/OnlineDoc/OnlineDoc";
import { MuiThemeProvider } from "@material-ui/core";
import theme from "./Theme/Theme";
import TempRegister from "./Pages/TempRegister";
import ConfirmEmail from "./Pages/ConfirmEmail";
import ConfirmPayment from "./Pages/ConfirmPayment";
import GetPackage from "./Pages/GetPackages/GetPackage";
import PayInvoices from "./Pages/PayInvoices/PayInvoices";

/**
 * @type {{container: React.CSSProperties, root: React.CSSProperties, dashboardBg: React.CSSProperties}}
 *
 * */
const styles = {
  root: {
    marginTop: 200,
  },
  container: {
    background:
      "linear-gradient(135deg, rgb(226, 247, 244) 0.1%, rgb(12, 171, 156) 50%, rgb(253 175 58))",
    position: "absolute",
    width: "100%",
    height: "100%",
  },
  dashboardBg: {
    background: "#EAEDEC",
    position: "absolute",
    width: "100%",
    minHeight: "100%",
  },
};

function App(props) {
  const lang = Lang.getInstance();
  let crtLang = readCookie("lang");
  if (crtLang.length > 0) {
    lang.set(crtLang);
  }

  /**
   * render loading if cached data is not present
   * or the router
   */
  return (
    <Fragment>
      <MuiThemeProvider theme={theme}>
        <Router>
          <Switch>
            <Route exact path="/register">
              {/*  render={()=>(
                 <div style={styles.container}>
                    <RegisterPage history={history}/>
                      </div>)}> */}
              <div style={styles.container}>
                <TempRegister />
              </div>
            </Route>

            <Route path="/wizard">
              <div style={styles.container}>
                <Wizard />
              </div>
            </Route>
            <Route path="/pill">
              <div style={styles.container}>
                <PillTabs />
              </div>
            </Route>
            <Route path="/login">
              <div style={styles.container}>
                <LoginPage />
              </div>
            </Route>
            <Route path="/confirmEmail">
              <div style={styles.container}>
                <ConfirmEmail />
              </div>
            </Route>
            <Route path="/confirmPayment">
              <div style={styles.container}>
                <ConfirmPayment />
              </div>
            </Route>
            <Route path="/getPackage/:id">
              <div style={styles.container}>
                <GetPackage />
              </div>
            </Route>
            <Route path="/onlinedoc/:locale/:enc">
              <div style={styles.container}>
                <PayInvoices />
              </div>
            </Route>
            {/** preview Doc */}
            <Route path={LocalUrlEnum.doc + "/:companyId/:docType/"}>
              <OnlineDoc />
            </Route>
            <Route path="/">
              <div style={styles.dashboardBg}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <Dashboard />
                </MuiPickersUtilsProvider>
              </div>
            </Route>
          </Switch>
        </Router>
      </MuiThemeProvider>
    </Fragment>
  );
}

export default App;
