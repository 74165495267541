import React from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button} from '@material-ui/core';
import Lang from '../lang';
import PropTypes from 'prop-types';
import theme from "../Theme/Theme";
export default function BasicDialog(props) {
    const lang = Lang.getInstance();


    const handleClose = (e) => {
        const result = e.currentTarget.id === "ok" ? true : false;
        props.onResult(result);
    };
  
    return (
      <div>
        <Dialog
          fullWidth={props.fullWidth || true}
          maxWidth={props.maxWidth || "md"}
          open={props.open}
          onClose={handleClose}
          aria-labelledby="confirm"
          aria-describedby="confirm dialog"
        >
          <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
          <DialogContent>
            {props.children}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} id="ok" style={{background:theme.palette.header?.main}}>
              {lang.get("ok")}
            </Button>
            <Button onClick={handleClose} id="cancel" style={{background:theme.palette.errorColor?.main}} autoFocus>
            {lang.get("cancel")}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  BasicDialog.propTypes = {
    title: PropTypes.string.isRequired,
    onResult: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    children: PropTypes.object,
    fullWidth: PropTypes.bool,
    maxWidth: PropTypes.string
  };
  