import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Checkbox,
  FormControlLabel,
  DialogActions,
  Collapse,
} from "@material-ui/core";
import Lang from "../lang";
import { DatePicker } from "@material-ui/pickers";
import moment from "moment";
import Config from "../Utils/Config";
import { Payment } from "../Models/Models";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import TableComponent from "../Components/TableComponent";
import { UrlEnum, handleInputChange } from "../Utils/Utils";
import FormSubmitButton from "../Forms/FormSubmitButton";
import theme from "../Theme/Theme";

const lang = Lang.getInstance();

type InvoicePaymentsProps = {
  open: boolean;
  onClose: (e: any) => void;
  invoiceId: number;
  invoiceAmount: number;
  payments: Payment[];
};

type InvoicePaymentState = {
  model: Payment;
  payments: Payment[];
  error: boolean;
};

class InvoicePayments extends React.Component<
  InvoicePaymentsProps,
  InvoicePaymentState
> {
  state: InvoicePaymentState;
  submitFunction: any;
  maxAmount: number;

  constructor(props: InvoicePaymentsProps) {
    super(props);
    this.state = {
      payments: this.props.payments,
      model: InvoicePayments.initPayment(
        this.props.payments,
        this.props.invoiceAmount,
        this.props.invoiceId
      ),
      error: false,
    };
    // this.maxAmount = parseInt(this.state.model.amount.toString());
    this.maxAmount = this.state.model.amount;

    this.submitFunction = null;
  }
  /**
   *
   * @param momentDate
   */
  onChangeStartDate(momentDate: MaterialUiPickersDate) {
    const md = momentDate as moment.Moment;
    if (md.isAfter(moment())) return false;
    let m = Object.assign({}, this.state.model);
    m.paymentDate = md.format(Config.momentUSDateFormat);
    this.setState({ model: m });
  }
  handleRender(){
    let m = InvoicePayments.initPayment(
      this.props.payments,
      this.props.invoiceAmount,
      this.props.invoiceId
    );
    this.setState({ model: m });
  }

  /**
   *
   * @param event
   */
  handleReceiptChange(event: React.FormEvent<HTMLInputElement>) {
    const target = event.target as HTMLInputElement;
    const model = { ...this.state.model };
    model.isReceipt = target.checked;
    this.setState({ model: model });
  }

  /**
   * edit payment from list
   * @param payment
   */
  handleEdit(payment: Payment) {
    this.setState({ model: payment });
  }

  handleDelete(payment: Payment) {
    let newPayment = Object.assign({}, this.state.model);
    newPayment.amount += parseFloat(payment.amount.toString());
    this.maxAmount += payment.amount;
    this.setState({ model: newPayment });
    let p = this.state.payments.slice();
    const index = p.indexOf(payment);
    p.splice(index, 1);
    this.setState({ payments: p });
  }

  /**
   * init payment with default values
   */
  static initPayment(
    payments: Payment[],
    invoiceAmount: number,
    invoiceId: number
  ) {
    let totalPaid = 0;
    let newPayment = new Payment();
    payments.map((payment) => (totalPaid += payment.amount));
    newPayment.amount = invoiceAmount - totalPaid;
    newPayment.fk_InvoiceId = invoiceId;
    newPayment.paymentDate = moment().format(Config.momentUSDateFormat);
    return newPayment;
  }

  /**
   * save payment callback
   * @param event
   */
  submitCallBack(payment: Payment) {
    payment.amount = parseFloat(payment.amount.toString());
    let p = this.state.payments.slice();
    let index = true;
    p.map((Payment) => {
      if (Payment.id === payment.id) {
        index = false;
        Payment.amount = payment.amount;
        Payment.description = payment.description;
        Payment.paymentDate = payment.paymentDate;
      }
    });
    if (index) p.push(payment);
    this.setState({
      payments: p,
      model: InvoicePayments.initPayment(
        p,
        this.props.invoiceAmount,
        this.props.invoiceId
      ),
    });
    this.maxAmount = this.state.model.amount;
  }

  submit(e: React.FormEvent) {
    e.preventDefault();
    let p = this.state.payments.slice();
    let oldAmount = 0;
    p.map((Payment) => {
      if (Payment.id === this.state.model.id) {
        oldAmount = Payment.amount;
      }
    });
    if (
      this.maxAmount < this.state.model.amount - oldAmount ||
      this.state.model.amount <= 0
    ) {
      this.setState({ error: true });
      return;
    }
    this.setState({ error: false });
    if (this.submitFunction) {
      // this.setState({model: });
      return this.submitFunction(this.state.model);
    }
  }

  render() {
    const columns = [
      { label: lang.get("number"), name: "receiptNo" },
      { label: lang.get("amount"), name: "amount" },
      { label: lang.get("date"), name: "paymentDate" },
    ];
    return (
      <>
      <Dialog
        open={this.props.open}
        onClose={() => this.props.onClose(this.state.payments)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <form name="paymentForm" onSubmit={this.submit.bind(this)}>
          <DialogTitle id="alert-dialog-title">
            {lang.get("payment")}
          </DialogTitle>
          <DialogContent>
            <Collapse in={this.state.model.isReceipt}>
              <TextField
                name="receiptNo"
                required
                fullWidth
                type="number"
                label={lang.get("number")}
                disabled
                value={this.state.model.receiptNo || ""}
              />
              <TextField
                onChange={(event: any) =>
                  this.setState({
                    model: handleInputChange(event, this.state.model),
                  })
                }
                name="description"
                fullWidth
                type="text"
                label={lang.get("description")}
                value={this.state.model.description || ""}
              />
            </Collapse>
            <TextField
              onChange={(event: any) =>
                this.setState({
                  model: handleInputChange(event, this.state.model),
                })
              }
              name="amount"
              required
              fullWidth
              type="number"
              label={lang.get("amount")}
              value={this.state.model.amount}
              error={this.state.error}
            />
            <br />
            <DatePicker
              fullWidth
              disableToolbar
              variant="inline"
              name="startDate"
              label={lang.get("date")}
              value={this.state.model.paymentDate}
              format={Config.momentEUDateFormat}
              onChange={this.onChangeStartDate.bind(this)}
            />
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  style={{ color: theme.palette.header?.main }}
                  checked={this.state.model.isReceipt}
                  onChange={this.handleReceiptChange.bind(this)}
                  name="recurrent"
                />
              }
              label={lang.get("receipt")}
            />
            <p>&nbsp;</p>
            <TableComponent
              url={UrlEnum.payments}
              models={this.state.payments}
              columns={columns}
              editCallback={this.handleEdit.bind(this)}
              deleteCallback={this.handleDelete.bind(this)}
              search={false}
              pagination={false}
              parse={{
                paymentDate: (initialValue: string) =>
                  moment(initialValue).format(Config.momentEUDateFormat),
              }}
            />
          </DialogContent>
          <DialogActions>
            <FormSubmitButton
              getSubmit={(fct) => (this.submitFunction = fct)}
              submitCallback={this.submitCallBack.bind(this)}
              url={UrlEnum.payments}
            />
          </DialogActions>
        </form>
      </Dialog>
      </>

    );
  }
}

export default InvoicePayments;
