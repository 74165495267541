import React, { PureComponent} from 'react';
import {HasTotals} from '../Models/Models';
import {  withStyles, createStyles, Grid, Theme } from '@material-ui/core';
import Lang from '../lang';
import Config from '../Utils/Config';

type DocTotalsProps ={
    doc:HasTotals;
    classes: any;
};

type DocTotalsState = {
};

class DocTotals extends PureComponent<DocTotalsProps, DocTotalsState>{
    lang: any;
    vat: number;
    totalWithVat: number;
    totalNoVat: number;
    discount: number;
    totalAmountWithVat: number;

    constructor(props:DocTotalsProps){
        super(props);
        this.lang = Lang.getInstance();

        const totals = HasTotals.getTotals(this.props.doc);
        this.vat = totals.vat;
        this.totalWithVat = totals.totalWithVat;
        this.totalNoVat = totals.totalNoVat;
        this.discount = totals.discount;
        this.totalAmountWithVat = totals.totalAmountWithVat;
    }


    /**
    * 
    */
    render() {
        const totals = HasTotals.getTotals(this.props.doc);
        this.vat = totals.vat;
        this.totalWithVat = totals.totalWithVat;
        this.totalNoVat = totals.totalNoVat;
        this.discount = totals.discount;
        this.totalAmountWithVat = totals.totalAmountWithVat;

        const {classes} = this.props;
        return (
        <Grid container spacing={0} className={classes.wrapper}>
            <Grid item lg={3} md={3} sm={6} xs={12} className={classes.border} >
                <p>{this.lang.get("totalMerchandisesNoVat")}: <span style={{float: "right"}}>{this.props.doc.totalNoVat.toFixed(Config.noOfDecimals)}</span></p>
                <p>{this.lang.get("discount")} {totals.discountPercent}%: <span style={{float: "right"}}>{this.discount.toFixed(Config.noOfDecimals)}</span></p>
            </Grid>
            <Grid item lg={3} md={3} sm={6} xs={12} className={classes.border}>
                <p>{this.lang.get("totalNoVat")}: <span style={{float: "right"}}>{this.totalNoVat.toFixed(Config.noOfDecimals)}</span></p>
                <p>{this.lang.get("vat")} {totals.vatPercent}%: <span style={{float: "right"}}>{this.vat.toFixed(Config.noOfDecimals)}</span></p>
            </Grid>
            
            <Grid item lg={6} md={6} sm={6} xs={12}>
                <p ><strong> <span style={{float: "right"}}>{this.lang.get("total")}: &nbsp;&nbsp;&nbsp;{this.totalAmountWithVat.toFixed(Config.noOfDecimals)}</span></strong></p>
            </Grid>
        </Grid>
        );
    }
}

const styles = (theme:Theme) => createStyles({
    wrapper:{
        padding: 30,
    },
    border:{
        borderRight: "1px solid #ccc",
        paddingRight:20,
        paddingLeft:20,
    }
});


export default withStyles(styles, { withTheme: true })(DocTotals);