import { useEffect,useState } from "react";
import { get } from "../../Utils/Utils";
import Lang from "../../lang";
import { makeStyles, Grid, Divider } from "@material-ui/core";
import { UrlEnum } from '../../Utils/Utils';

const lang = Lang.getInstance();
const useStyles = makeStyles((theme) =>
    Object.assign({
      payHeader: {
        padding: "8px",
        background: theme.palette.primary?.main,
        color: theme.palette.textColorSecondary?.main,
      },
      text:{
        fontWeight: "normal",
        // display:"inline-block"
      }
    }));
export default function Payments(props){

    const[state, setState]= useState({model:{balance:{available:{amount:"", currency:""},pending:{amount:"", currency:""} }, payouts:[], payments:[]}});
    const classes = useStyles();

    useEffect(() => {
        getPayments()
    }, []);

    function getPayments()
    {
        const url =UrlEnum.stripeAccountBalance;
        get(url).then(response =>{
            if(!response){
              return;
            }
            if(response.errors)
            {
              props.setIsPayment(false);
            }
            else{
                let currModel=state.model;
                currModel.balance.available.amount=response.balance.available.amount;
                currModel.balance.available.currency=response.balance.available.currency;
                currModel.balance.pending.amount=response.balance.pending.amount;
                currModel.balance.pending.currency=response.balance.pending.currency;
                currModel.payments=response.payments;
                currModel.payouts=response.payouts;
                setState({model:currModel})
            }
        })
    }
    function getModelPayments(){
      if(state.model.payments)
        {
          if(state.model.payments.length===0)
            return lang.get("noPayments");
          return state.model.payments.map((payment)=>{
            return <h4 className={classes.text}>{lang.get("clientName")}: {payment.clientName} &nbsp; &nbsp; &nbsp;{lang.get("description")}: {payment.description} &nbsp; &nbsp; &nbsp;{lang.get("amount")}: {payment.amount}</h4>
          })
         
      }
      return "";
    }
    function getModelPayouts(){
      if(state.model.payouts)
      {
        if(state.model.payouts.length===0)
          return lang.get("noPayouts");
        return state.model.payouts.map((payout)=>{
          return <h4 className={classes.text}>{lang.get("dateOfPayment")}: {payout.arrival_date} &nbsp; &nbsp; &nbsp;{lang.get("amount")}: {payout.amount} {payout.currency.toUpperCase()}</h4>
        })
      }
      return "";
    }
    function renderContent() {
        return (
          <div style={{paddingBottom:"5px"}}>
          <div>
          {/* style={{display:"block"}}> */}
          <h3 style={{marginTop:"0px", paddingTop:"10px"}} className={classes.text}> {lang.get("balance")}</h3>
            <h4 className={classes.text}>{lang.get("available")}: {state.model.balance.available.amount} {state.model.balance.available.currency.toUpperCase()}</h4>
            <h4 className={classes.text}>{lang.get("pending")}: {state.model.balance.pending.amount} {state.model.balance.pending.currency.toUpperCase()}</h4>
            </div>
          <Divider style={{ background:"black", opacity: 0.2 }}/>
            <h3 className={classes.text}> {lang.get("payments")}</h3>
            {getModelPayments()}
          <Divider style={{ background:"black", opacity: 0.2 }}/>
          <h3  className={classes.text}> {lang.get("payouts")} </h3>
          {getModelPayouts()}
          </div>
        );}
    return(
    <Grid >
      {renderContent()}
    </Grid>
    );
}