import { TitleEnum, SubscriptionsEnum, Statuses, CostTypeEnum, PaymentMode } from '../Utils/Utils';
import CachedDataSingleton from '../cachedDataSingleton';
import moment from 'moment';
import Config from '../Utils/Config';
import { CurrencyEnum } from '../Utils/Utils';
import Lang from '../lang';

const cachedData = CachedDataSingleton.getInstance();
const lang = Lang.getInstance();

export var LoginModel = {
	username: '',
	password: '',
	g_recaptcha_response: ''

};

/**
 *
 */
export var RegisterModel = {
	mobile: '',
	username: '',
	email: '',
	password: '',
	confirmPassword: '',
};

/**
 *
 */
export const WizardModel = {
	id: 0,
	name: '',
	representativeName: '',
	tva: '',
	vatNo: '',
	address: '',
	city: '',
	email: '',
	code: '',
	fk_countryId: 21,
	termsAndConditions: '1',
	country: 'BE',
	companyRegNo: '',
	bankName: '',
	bankAccount: '',
	bankBic: '',
	description: '',
	mobile: '',
	logo: '',
	page: 0,
	signature: '',
	subscription: SubscriptionsEnum.PROFESSIONAL,
}
export class StripeAccountModel{
   lastName='';
   firstName='';
   personalId='';
   phoneNumber='';
   dateOfBirth=moment().format(Config.momentUSDateFormat);
   country='BELGIUM';
   address='';
   postalCode='';
   city='';
   businessWebsite='';
   industry='7623';
   identityDoc='';
   verifyAddress='';
}

export const noOfDecimals = 2;
export const defaultTradeMargin = 10;

export class Service {
	id = 0;
	name = '';
	description = '';
	materialsCost = 0;
	laborCost = 0;
	tradeMargin = defaultTradeMargin;
	um = 'pce';
	totalServicePrice = 0;
	totalPrice = 0;
	price = 0;
	quantity = 1;
	fk_CategoryId = null;
	laborTime= 0;

	static calculateTotalServicePrice(service: Service) {
		return (
			(parseFloat(service.materialsCost.toString()) + parseFloat(service.laborCost.toString())) *
			(parseFloat(service.tradeMargin.toString()) / 100 + 1)
		).toFixed(noOfDecimals);
	}

	static toDocService(service:Service):DocService{
		let ds = new DocService();
		
        ds.fk_StatusId= 1;
        ds.serviceName= service.name;
		ds.serviceUm= service.um;
        ds.serviceQuantity= 1;
        ds.description= service.description;
        ds.laborTime= service.laborTime;
        ds.materialsCost= service.materialsCost;
        ds.laborCost= service.laborCost;
        ds.initialServiceId= service.id;
        ds.servicePrice= service.price;
        ds.totalServicePrice= service.totalServicePrice;
        ds.tradeMargin= service.tradeMargin;
		return ds;
	}
}

export class DocService {
	id: number = 0;
	serviceName: string = '';
	serviceQuantity: number = 1;
	servicePrice: number = 0;
	serviceUm: string = 'pce';
	description: string = '';
	materialsCost: number = 0;
	laborCost: number = 0;
	tradeMargin: number = 0;
	laborTime: number = 0;
	workloadSubdivision: string = '-';
	serviceCategName: string = 'Services';
	initialServiceId: number = 0;
	finished: boolean = false;
	fk_EstimateId: any = null;
	updated_at: moment.Moment = moment();
	created_at: moment.Moment = moment();
	fk_StatusId: number = 1;
	fk_InvoiceId: any = 1432;
	servicePriceWithTradeMargin: number = 0;
	totalServicePrice: number = 0;
	doc_service_articles: Article[] = [];
	vat: number = 0;
	selected: boolean = false;
	isNew: boolean = false;

	constructor() {
		this.id = 0;
	}

	static recalculatePrice(service: DocService, vatRate: number): void {
		if (service.materialsCost !== 0 || service.laborCost !== 0) {
			service.servicePrice =
				parseFloat(service.materialsCost.toString()) + parseFloat(service.laborCost.toString()) || 0;
			service.servicePrice = (service.servicePrice * service.tradeMargin) / 100 + service.servicePrice;
		}
		// calculate total price
		service.totalServicePrice = service.servicePrice * service.serviceQuantity;
		//calculate vat
		service.vat = parseFloat(((service.totalServicePrice * vatRate) / 100).toFixed(Config.noOfDecimals));
	}
}

export class Article {
	id = 0;
	name = '';
	brand = '';
	groupName = '';
	description = '';
	diameter = '';
	quantity = '';
	price = 0;
	um = 'pce';
	tradeMargin = defaultTradeMargin;
	pricePerUnit = 0;
	itemcode = '';
	image_large = '';
	image_small = '';
	materialsCost = 0;
	fk_CategoryId = null;
}

export class Address {
	address = '';
	city = '';
	code = '';
	country = { name: '' };
	fk_countryId = 21;
	static stringify(address: Address | null | undefined) {
		if(!address) return "";
		return (
			address.address +
			', ' +
			address.code +
			', ' +
			address.city +
			' ' +
			(address.country ? address.country.name : '')
		);
	}
}

export class Client {
	id = 0;
	title = TitleEnum[0];
	name = '';
	vatNo = '';
	tel = '';
	mobile = '';
	fax = '';
	email = '';
	web = '';
	discountActive = '';
	discount = '';
	fk_VATTypeId = 1;
	address: Address = new Address();
	company = '';
	contact_persons = null;
	url = '/clients';
	alertInfo = '';
	badpayee = 0;
	bankAccount = '';
	bankName = '';
}

export class Subcontractor{
	id= 0;
	name= '';
	vatNo= '';
	tel= '';
	mobile= '';
	fax= '';
	emails= '';
	fk_VATTypeId= 1;
	fk_AddressId= '';
	fk_CompanyId= '';
	isEmployee= '';
	address= new Address();
	company= '';
	contact_persons= null;
	url= '/subcontractors';
	alertInfo= '';
	persons=[];
};

export class Employee{
	id:number = 0;
	name:string = '';
	mobile:string = '';
	emails:string = '';
	nationalId:string = '';
	code:string = '';
	active:string = '';
	pricePerHour:number = 10;
	fk_SubcontractorId:number | null = null;
};

export class UserProfile {
	id:number = 0;
	userName:string =  '';
	email:string = '';
	password:string = '';
	confirmPassword:string = '';
	currentPassword:string = '';
	istrial:number = 0;
	user_roles:Array<any> =[];
	mobile:string = '';
	receiveCC = true;
};

export const AccountInformation = () => ({
	timekeeping: 'perHour',
	reminderDelay: 7,
	notificationDelay: 30,
	reminderNotificationMinAmount: 100,
	continuousInvoiceNumbers: 0,
	timeTrackingPause:30,
	tradeMargin:0,
	workInConstruction: false,
});

export class TimeTracking {
	id: number = 0;
	elapsedTime:string = "";
	position:string = "";
	fk_PersonId:number = 0;
	fk_SubcontractorOrderTicketId:number = 0;
	fk_ClientId:number = 0;
	fk_FolderId:number = 0;
	fk_EstimateId: number = 0;
	name:string = "";
	clientName:string = "";
	folderTitle:string  =";";
	folderNo:string  ="";
	orderTicketNo:string  ="";
	orderTicketTitle:string  ="";
	stopTime: moment.Moment | null = null;
}

export class Package{
	id= 0;
	name= '';
	services= [];
};

export const Category = () => ({
	id: 0,
	name: '',
	description: '',
	fk_ParentCategId: null,
});

export class Project {
	id:number = 0;
	folderNo:string = "";
	site_address: Address = new Address();
	client: Client | null = null;
	status = Statuses.Active;
	invoices: Array<Invoice> | null = null;
	estimates: Array<Estimate> | null = null;
	costs: Array<Cost> | null  = [];
	title: string = "";
	description: string = "";
	fileGallery:Array<Attachment> | null = null;
	created_at: any;
}

export class Attachment {
	name: string = "";
    path: string = "";
    isDir: boolean = false;
    thumb: string =  "/images/icons/Generic-icon.png";
	files: Attachment[] | null = null;
}

export class Payment {
	id:number = 0;
	amount:number = 0;
	description:string = '';
	fk_InvoiceId:number = 0;
	paymentDate = '';
	receiptNo = '';
	isReceipt = false;
}

export class HasTotals{
	totalNoVat:number = 0;
	vatRate:number = 21;
	totalWithVat:number = 0;
	discount:number = 0;

	public static getTotals(doc :HasTotals):DocTotals{
		const discount = (+doc.totalNoVat)  * parseFloat(doc.discount ? doc.discount.toString() : "0") / 100;
		const totalNoVat = (+doc.totalNoVat) - discount;

		const vat = totalNoVat * (+doc.vatRate) / 100;

		const totalWithVat = totalNoVat + vat;
		
		return { 
			totalCost: doc.totalNoVat,
			totalNoVat: totalNoVat,
			vat: vat,
        	totalWithVat: totalWithVat,
        	discount: discount,
        	totalAmountWithVat: totalWithVat,
			vatPercent:doc.vatRate,
			discountPercent: doc.discount

		}
	}
	
}

export class Invoice extends HasTotals{
	selected:boolean = false;
	id:number = 0;
	invoiceNo:string = '';
	series:string = new Date().getFullYear().toString();
	invoiceDate: moment.Moment = moment();
	currency:string = CurrencyEnum.EUR;
	vatRate:number = 21;
	totalNoVat:number = 0;
	totalWithVat:number = 0;
	totalPaid:number = 0;
	paymentDue:number = 8;
	reminderDate:any = null;
	totalDownPayment:number = 0; // down payment with invoice services total
	discount:number = 0;
	paymentMode:string = PaymentMode.bankTransfer;
	client:Client = new Client();
	company = undefined;
	status = Statuses.Active;
	folder :Project | null = null;
	type:string = 'invoice';
	vattypes = cachedData.get('vatTypes') || [];
	clients:Array<Client> = cachedData.get('clients') || [];
	fk_StatusId:number = 1;
	fk_ClientId:number = 0;
	payments:Array<Payment> = [];
	recurrent:boolean = false;
	recurrenceInterval:number = 0;
	invoice_services: DocService[] = [];
}

export type PaymentPhase = {
	[index: string]: any,
    text: string,
    percent: number
};

export class Estimate extends HasTotals{
	[index: string]: any;
	selected:boolean = false;
	id:number = 0;
	estimateNo:string = '';
	series:string = new Date().getFullYear().toString();
	startDate: moment.Moment = moment();
	endDate: moment.Moment = moment();
	created_at: moment.Moment = moment();
	discount:number = 0;
	currency:string = CurrencyEnum.EUR;
	vatRate:number = 21;
	description: string = '';
	additionalInfo: string = '';
	title: string = '';
	serviceCategoriesDescriptions: string = '';
	paymentPhases: Array<PaymentPhase> = [
		{text: lang.get("startWork"), percent: 50},
		{text: lang.get("duringWork"), percent: 30},
		{text: lang.get("endWork"), percent: 20}
	];

	totalNoVat:number = 0;
	totalWithVat:number = 0;
	
	client:Client = new Client();
	company = undefined;
	status = Statuses.Active;
	folder: Project | null = null;
	type:string = 'estimate';
	clients:Array<Client> = cachedData.get('clients') || [];
	fk_StatusId:number = 1;
	fk_SubcontractorId:number = 0;
	fk_ClientId:number = 0;
	estimate_services: DocService[] = [];
	site_address: Address = new Address();
}

export class Liability extends HasTotals{

}


export type DocTotals ={
	totalCost:number,
	totalNoVat:number, 
	vat:number, 
	totalWithVat:number, 
	discount:number, 
	totalAmountWithVat:number,
	discountPercent:number,
	vatPercent:number
};

export class Cost {
	[index: string]: any;
	amount = 0;
	clientName = '';
	description = '';
	docDate:string =  moment().format(Config.momentUSDateFormat);
	docNumber = '';
	fk_ClientId:number = 0;
	fk_DocTypeId:number = 3;
	fk_SupplierId:number = 0;
	fk_FolderId = 0;
	folderNo = '';
	id = 0;
	isMonthly = 0;
	type:string = CostTypeEnum.PURCHASE;
	monthlyCostId = null;
	paid = 1;
	paymentDeadline = "";
	supplierName = '';
	userName = '';
	vatRate:number = 21;
	filePath:string | Blob = "";
}

export class Supplier {
	id:number = 0;
	name:string = '';
	fk_CompanyId:number = 0;
}

export class NomDocType{
	id:number = 0;
	name:string = "";
}
