import React from 'react';
import Lang from '../../lang';
import FormComponent from '../../Forms/FormComponent';
import { Grid } from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import CachedDataSingleton from '../../cachedDataSingleton';
import { withStyles, Button, InputAdornment } from '@material-ui/core';
import { post, UrlEnum } from '../../Utils/Utils';
import ConfirmDialog from "../../Components/ConfirmDialog";
import { RemoveRedEye } from '@material-ui/icons';
import { CACHED_DATA_TAG } from "../../Utils/Constants";
import theme from "../../Theme/Theme";

const styles = theme => ({
    fullWidth: {
        width: "100%",
    },
    noPaddingTop: {
        paddingTop: "0 !important"
    },
    paper: theme.mixins.gutters({
    }),
    root: {
        backgroundColor: theme.palette.background.paper,
        width: "100%",
        height: "100%",
    },
    center: {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('md')]: {
            width: '100%',
            flexDirection: 'column',
            alignContent: 'center'
        },
    },
    button: {

        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    },
    eye: {
        cursor: 'pointer',
    },

});


class AccountInfo extends FormComponent {
    Eye
    /**
     * constructor
     * @param {Object} props 
     */
    constructor(props) {
        super(props);
        this.lang = Lang.getInstance();
        this.cachedData = CachedDataSingleton.getInstance();
        this.mapWrapperRef = React.createRef();
        this.editedModel = null;
        this.state={ ...this.state,
            openDisableConfirm: false
        };
    }


    /**
     * Change phone number
     * @param {string} value 
     */
    handlePhoneNoChange(value) {
        let model = Object.assign({}, this.state.model);
        model.mobile = value;
        this.setState({ "model": model });

    }
    /**
     * Change passoword to be visible or not
     */
    togglePasswordMask = () => {
        this.setState(prevState => ({
            passwordIsMasked: !prevState.passwordIsMasked,
        }));
    };

    /**
     * Submit method
     */
    handleSubmit = (event) => {
        this.state.model.userName = this.state.model.email
        this.submit(event, UrlEnum.userUpdate)

    }
    disableAccount = ()=>{
        this.setState({openDisableConfirm: true})
    }
    onConfirmResult = (confirm) => {
        this.setState({openDisableConfirm: false})
        if(!confirm)
            return;
        const url = UrlEnum.disableAccount;
        post(url).then((response)=>{
            if(response.errors){
                this.setState({errors: response.errors})
            }
            else {
            document.cookie = "username=;";
            document.cookie = " userRoles=;";
            document.cookie = "userData=;";
            window.localStorage.removeItem(CACHED_DATA_TAG);
            document.location.href = "/login";
            }
        })
    }

    /**
     * Render method
     */
    render() {

        const classes = this.props.classes;
        const { passwordIsMasked } = this.state;


        return (
            <div className={classes.root}>

                <ValidatorForm onSubmit={this.handleSubmit} >

                    {/** username */}
                    <TextValidator
                        disabled
                        label={this.lang.get("username")}
                        className={classes.fullWidth}
                        name="userName"
                        value={this.state.model.userName}
                        onChange={this.handleInputChange.bind(this)}
                        validators={['required']}
                        errorMessages={[this.lang.get("fieldRequired")]} />

                    {/** curentPassowrd*/}
                    <TextValidator
                        required={true}
                        className={classes.fullWidth}
                        label={this.lang.get("currentPassword")}
                        onChange={this.handleInputChange.bind(this)}
                        name="currentPassword"
                        type={passwordIsMasked ? 'text' : 'password'}
                        validators={['required', 'minStringLength:8', 'matchRegexp:^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9\\,\\.\\;\\:\\/\\]\\[\\{\\}\\|\\"\\>\\<\\=\\_\\+\\-\\!\\`\\@\\#\\$\\%\\^\\&\\\\*\\(\\)\\~]+)$']}
                        errorMessages={[this.lang.get("fieldRequired"), this.lang.get("min8Character"), this.lang.get("minOneLetterAndNumber")]}
                        value={this.state.model.currentPassword || ""}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <RemoveRedEye
                                        className={classes.eye}
                                        onClick={this.togglePasswordMask}
                                    />
                                </InputAdornment>
                            ),
                        }}
                    />

                    {/** password*/}
                    <TextValidator
                        required={true}
                        className={classes.fullWidth}
                        label={this.lang.get("newPassword")}
                        onChange={this.handleInputChange.bind(this)}
                        name="password"
                        type={passwordIsMasked ? 'text' : 'password'}
                        validators={['required', 'minStringLength:8', 'matchRegexp:^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9\\,\\.\\;\\:\\/\\]\\[\\{\\}\\|\\"\\>\\<\\=\\_\\+\\-\\!\\`\\@\\#\\$\\%\\^\\&\\\\*\\(\\)\\~]+)$']}
                        errorMessages={[this.lang.get("fieldRequired"), this.lang.get("min8Character"), this.lang.get("minOneLetterAndNumber")]}
                        value={this.state.model.password || ""}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <RemoveRedEye
                                        className={classes.eye}
                                        onClick={this.togglePasswordMask}
                                    />
                                </InputAdornment>
                            ),
                        }}
                    />

                    {/** repeatPassword */}


                    <TextValidator
                        required={true}
                        name="confirmPassword"
                        className={classes.fullWidth}
                        label={this.lang.get("repeatNewPassword")}
                        onChange={this.handleInputChange.bind(this)}
                        type={passwordIsMasked ? 'text' : 'password'}
                        validators={['required', 'minStringLength:8', 'matchRegexp:^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9\\,\\.\\;\\:\\/\\]\\[\\{\\}\\|\\"\\>\\<\\=\\_\\+\\-\\!\\`\\@\\#\\$\\%\\^\\&\\\\*\\(\\)\\~]+)$']}
                        errorMessages={[this.lang.get("fieldRequired"), this.lang.get("min8Character"), this.lang.get("minOneLetterAndNumber")]}
                        value={this.state.model.confirmPassword || ""}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <RemoveRedEye
                                        className={classes.eye}
                                        onClick={this.togglePasswordMask}
                                    />
                                </InputAdornment>
                            ),
                        }}
                    />

                    <br /><br></br>
                    <div style={{ display: "flex" }}>
                        <Grid container spacing={2} >
                            <Grid item xs={12} sm={6} md={6} className={classes.center}>
                                {super.render()}
                            </Grid>

                            <Grid item xs={12} sm={6} md={6} className={classes.center}>
                                <Button variant="contained" className={classes.button} color="secondary" onClick={this.disableAccount} style={{ marginLeft: "auto", color: theme.palette.textColorSecondary?.main}} >
                                    {this.lang.get("disable") + " " + this.lang.get("account")}
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </ValidatorForm>
                <ConfirmDialog
                    open={this.state.openDisableConfirm}
                    text={this.lang.get("disableAccount")}
                    onResult={this.onConfirmResult.bind(this)}
                />
                        
            </div>
        );
    }
}

export default withStyles(styles)(AccountInfo);