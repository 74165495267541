import React, { PureComponent } from "react";
import { DatePicker } from "@material-ui/pickers";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
  withStyles,
  Select,
  Paper,
  FormControl,
  Modal,
  InputLabel,
  Grid,
  MenuItem,
} from "@material-ui/core";
import { TextValidator } from "react-material-ui-form-validator";
import Lang from "../lang";
import CachedDataSingleton from "../cachedDataSingleton";
import { isMobile, PaymentMode, Currency, UrlEnum } from "../Utils/Utils";
import Slide from "@material-ui/core/Slide";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
// import TextField from "@material-ui/core/TextField";
// import Autocomplete from "@material-ui/lab/Autocomplete";
import IconButton from "@material-ui/core/IconButton";
// import EditIcon from "@material-ui/icons/Edit";
import Typography from "@material-ui/core/Typography";
import { Address, Client, Invoice } from "../Models/Models";
import PropTypes from "prop-types";
// import AddCircleIcon from "@material-ui/icons/AddCircle";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import EditClient from "../Forms/EditClient";
import CloseIcon from "@material-ui/icons/Close";
import Config from "../Utils/Config";
import withWidth from "@material-ui/core/withWidth";
import theme from "../Theme/Theme";
import EditClientModal from "./EditClientModal";
const lang = Lang.getInstance();

class InvoiceGeneralInfo extends PureComponent {
  constructor(props) {
    super(props);
    this.cachedData = CachedDataSingleton.getInstance();
    this.vatTypes = this.cachedData.get("vatTypes");
    this.clients = this.cachedData.get("clients");
    this.checked = false;
    this.state = {
      model: Invoice,
      showRecurrent: props.invoice.recurrenceInterval > 0,
      showText: false,
      activeModal: false,
      editButton: true,
      alert: !props.invoice.recurrenceInterval > 0,
    };
    this.newEditClient = null;
    this.title = "";
    this.defaultRecurrenceInterval = 30;
  }

  /**
   * Show Paper on Modal
   */
  handleChangeRecurrent() {
    let nextRecurrenceSwitchState = !this.state.showRecurrent;
    this.setState({ showRecurrent: nextRecurrenceSwitchState, alert: true });
    this.props.onRecurrentChange(
      nextRecurrenceSwitchState ? this.defaultRecurrenceInterval : 0
    );
  }

  /**
   * Show Alert Dialog message
   */
  handleChangeAlert() {
    this.setState({ alert: !this.state.alert });
  }

  /**
   * Modal handle for edit clients
   */
  handleOpenModalEdit() {
    this.setState({ activeModal: !this.state.activeModal });
    this.newEditClient = this.props.invoice.client;
    this.title = lang.get("edit") + ": " + lang.get("client");
  }
  /**
   * Modal handle for new clients
   */
  handleOpenModalNew() {
    this.setState({ activeModal: !this.state.activeModal });
    this.newEditClient = new Client();
    this.title = lang.get("newClient");
    // this.props.invoice
  }

  /**
   * Close Modal
   */
  handleCloseModal() {
    this.setState({ activeModal: !this.state.activeModal });
  }
  /**
   * Add a new client in chachedData
   */
  handleClientChange(client) {
    let indexEditedModel = this.clients.findIndex((c) => c.id === client.id);
    if (indexEditedModel >= 0) {
      this.clients.splice(indexEditedModel, 1, client);
    } else {
      this.clients.push(client);
    }
    this.cachedData.setKey("clients", this.clients);
    this.props.onClientChange(null, client);
  }
  onChangeDate(md) {
    let m = Object.assign({}, this.state.model);
    m.invoiceDate = md.format(Config.momentUSDateFormat);
    this.setState({ model: m });
    this.props.invoice.invoiceDate = md.format(Config.momentUSDateFormat);
  }

  componentDidMount() {
    if (!this.props.invoice.id) {
      if (!this.clients.length) this.handleOpenModalNew();
    }
  }

  /**
   * Render method
   */
  render() {
    const classes = this.props.classes;
    return (
      <div>
        <Paper className={classes.paper}>
          <Grid container>
            <Grid className={classes.grid} item xs={12} sm={12} md={6}>
              <FormControl required={true} fullWidth={true}>
              <EditClientModal
                    client={this.props.invoice.client}
                    onClientChange={(e, client, reason, details) => this.props.onClientChange(e, client, reason, this.state.sameAsClient ? "sameAsClient" : null)}
                    disabled={false}
                    
                  />
              </FormControl>
              <FormControl required={true} fullWidth={true}>
                <Typography component="div">
                  {this.state.showText ? (
                    <div>
                      <em>
                        <small>
                          {lang.get("vatNo")}:
                          {this.props.invoice.client.vatNo
                            ? this.props.invoice.client.vatNo
                            : ""}
                          <br />
                          {lang.get("address")}:{" "}
                          {Address.stringify(
                            this.props.invoice.client.address
                              ? this.props.invoice.client.address
                              : ""
                          )}
                        </small>
                      </em>
                    </div>
                  ) : (
                    ""
                  )}
                </Typography>
                <br></br>
              </FormControl>
              <Grid container spacing={3}>
                <Grid className={classes.grid} item xs={12} sm={12} md={6}>
                  <TextValidator
                    fullWidth
                    disabled={this.props.invoice.status.id !== 1}
                    required={true}
                    id="series"
                    name="series"
                    label={lang.get("series")}
                    onChange={this.props.onChange}
                    validators={["required"]}
                    value={this.props.invoice.series}
                    errorMessages={lang.get("fieldRequired")}
                  />
                  <TextValidator
                    fullWidth
                    disabled={this.props.invoice.status.id !== 1}
                    required={true}
                    type="number"
                    id="invoiceNo"
                    name="invoiceNo"
                    label={lang.get("number")}
                    onChange={this.props.onChange}
                    validators={["required"]}
                    value={this.props.invoice.invoiceNo}
                    errorMessages={lang.get("fieldRequired")}
                  />
                </Grid>
                <Grid className={classes.paper} item xs={12} sm={12} md={6}>
                  <FormControl required={true} fullWidth={true}>
                    <FormControl required fullWidth>
                      <InputLabel shrink>{lang.get("date")}</InputLabel>
                      <DatePicker
                        disabled={this.props.invoice.status.id !== 1}
                        style={{ marginTop: 16 }}
                        required={true}
                        disableToolbar
                        variant="inline"
                        name="invoiceDate"
                        value={
                          !this.state.model.invoiceDate
                            ? this.props.invoice.invoiceDate
                            : this.state.model.invoiceDate
                        }
                        format={Config.momentEUDateFormat}
                        onChange={this.onChangeDate.bind(this)}
                      />
                    </FormControl>

                    <FormControl required={true} fullWidth={true}>
                      <InputLabel>{lang.get("vatRate")}</InputLabel>
                      <Select
                        disabled={this.props.invoice.status.id !== 1}
                        id="vatRate"
                        name="vatRate"
                        value={this.props.invoice.vatRate === null ? 0 : parseInt(this.props.invoice.vatRate)}
                        onChange={this.props.onChange}
                      >
                        {this.vatTypes.map((vt, index) => (
                          <MenuItem key={index} value={vt.value}>
                            {vt.description}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <Accordion
                expanded={isMobile() ? undefined : true}
                className={classes.panel}
              >
                {isMobile() ? (
                  <AccordionSummary
                    expandIcon={
                      isMobile() ? <ExpandMoreIcon fontSize={"large"} /> : ""
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>{lang.get("options")}</Typography>
                  </AccordionSummary>
                ) : (
                  undefined
                )}
                <Grid className={classes.grid} container spacing={0}>
                  <Grid className={classes.grid} item xs={12} sm={12} md={6}>
                    <TextValidator
                      fullWidth
                      disabled={this.props.invoice.status.id !== 1}
                      required={true}
                      type="number"
                      id="paymentDue"
                      name="paymentDue"
                      onChange={this.props.onChange}
                      label={
                        lang.get("paymentDue") + " (" + lang.get("days") + ")"
                      }
                      validators={["required"]}
                      value={this.props.invoice.paymentDue}
                      errorMessages={lang.get("fieldRequired")}
                    />
                    <TextValidator
                      fullWidth
                      disabled={this.props.invoice.status.id !== 1}
                      id="discount"
                      name="discount"
                      label={lang.get("discount") + " (%)"}
                      value={this.props.invoice.discount}
                      onChange={this.props.onChange}
                      onBlur={this.props.onDiscountBlur}
                      validators={["required", "minNumber:0", "maxNumber:100"]}
                      errorMessages={[
                        lang.get("fieldRequired"),
                        lang.get("value") + ":0-100",
                        lang.get("value") + ":0-100",
                      ]}
                    />
                  </Grid>
                  <Grid className={classes.grid} item xs={12} sm={12} md={6}>
                    <FormControl required={true} fullWidth={true}>
                      <InputLabel>{lang.get("paymentMode")}</InputLabel>
                      <Select
                        disabled={this.props.invoice.status.id !== 1}
                        id="paymentMode"
                        name="paymentMode"
                        value={this.props.invoice.paymentMode}
                        onChange={this.props.onChange}
                      >
                        {Object.keys(PaymentMode).map((key, index) => (
                          <MenuItem key={index} value={PaymentMode[key]}>
                            {lang.get(PaymentMode[key])}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <FormControl required={true} fullWidth={true}>
                      <InputLabel>{lang.get("curency")}</InputLabel>
                      <Select
                        disabled={this.props.invoice.status.id !== 1}
                        id="currency"
                        name="currency"
                        value={this.props.invoice.currency || Currency.EURO}
                        onChange={this.props.onChange}
                      >
                        {Object.keys(Currency).map((key, index) => (
                          <MenuItem key={index} value={Currency[key]}>
                            {Currency[key]}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid className={classes.gridFlex} container spacing={2}>
                    <Grid
                      className={classes.gridFlex}
                      item
                      xs={5}
                      sm={12}
                      md={6}
                    >
                      <FormControlLabel
                        control={
                          <Switch
                            disabled={this.props.invoice.status.id !== 1}
                            checked={this.state.showRecurrent}
                            className={classes.iconButton}
                            onChange={this.handleChangeRecurrent.bind(this)}
                          />
                        }
                        label={lang.get("recurrentInvoice")}
                      />
                    </Grid>
                    <Grid
                      className={classes.gridFle}
                      item
                      xs={6}
                      sm={12}
                      md={6}
                    >
                      {this.state.showRecurrent ? (
                        <div>
                          <Dialog
                            open={this.state.alert}
                            onClose={this.props.onChange}
                            aria-labelledby="confirm"
                            aria-describedby="confirm dialog"
                          >
                            <DialogTitle id="alert-dialog-title">
                              {lang.get("confirm")}
                            </DialogTitle>
                            <DialogContent>
                              <DialogContentText id="alert-dialog-description">
                                {lang.get("createRecurrent")}
                              </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                              <Button
                                onClick={this.handleChangeAlert.bind(this)}
                                id="ok"
                                style={{background:theme.palette.header?.main}}
                              >
                                {lang.get("ok")}
                              </Button>
                              <Button
                                onClick={this.handleChangeRecurrent.bind(this)}
                                id="cancel"
                                style={{background:theme.palette.errorColor?.main}}
                                autoFocus
                              >
                                {lang.get("cancel")}
                              </Button>
                            </DialogActions>
                          </Dialog>
                        </div>
                      ) : (
                        ""
                      )}
                      {this.state.showRecurrent ? (
                        <TextValidator
                          disabled={this.props.invoice.status.id !== 1}
                          fullWidth={true}
                          required={true}
                          type="recurrenceInterval"
                          id="recurrenceInterval"
                          name="recurrenceInterval"
                          label={lang.get("recurrenceInterval")}
                          onChange={this.props.onChange}
                          validators={["required"]}
                          value={this.props.invoice.recurrenceInterval}
                          errorMessages={lang.get("fieldRequired")}
                        />
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Accordion>
            </Grid>
          </Grid>
        </Paper>

        {this.state.activeModal ? (
          <Modal
            className={classes.modalWindow}
            open={true}
            onClose={this.handleCloseModal.bind(this)}
          >
            <Slide
              direction="down"
              in={this.state.activeModal}
              mountOnEnter
              unmountOnExit
            >
              <Paper className={classes.modalPaper}>
                <div
                  style={{
                    position: "relative",
                    padding: 5,
                    borderBottom: "1px solid #ccc",
                  }}
                >
                  <Typography variant="h5" gutterBottom>
                    {this.title}
                  </Typography>
                  <IconButton
                    style={{ position: "absolute", right: 0, top: 0 }}
                    color="inherit"
                    id="addNew"
                    onClick={this.handleCloseModal.bind(this)}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
                <br></br>
                <div className={classes.contentWrapper}>
                  <EditClient
                    url={UrlEnum.clients}
                    model={this.newEditClient}
                    submitCallback={this.handleClientChange.bind(this)}
                    onClose={this.handleCloseModal.bind(this)}
                  />
                </div>
              </Paper>
            </Slide>
          </Modal>
        ) : (
          ""
        )}
      </div>
    );
  }
}
const styles = (theme) => ({
  modalWindow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  panel: {
    boxShadow: "none",
    expanded: true,
    [theme.breakpoints.down("md")]: {
      expanded: false,
      boxShadow: "0px 0px 0px 2px rgba(235,235,235,1)",
      paddingBottom: 10,
    },
  },
  paper: {
    display: "flex",
    padding: 10,
    [theme.breakpoints.down("md")]: {
      display: "inline-block",
    },
  },
  grid: {
    padding: 10,
  },
  gridFlex: {
    display: "flex",
    paddingLeft: 10,
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 10,
    },
  },

  editButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  iconButton: {
    marginTop: 10,
  },
  margin: {
    marginTop: 5,
  },

  paddingSmall: {
    [theme.breakpoints.down("md")]: {
      paddingRight: 10,
      paddingLeft: 10,
    },
  },
  modalPaper: {
    maxWidth: 900,
    maxHeight: 900,
    paddingRight: 10,
    paddingLeft: 10,
    outline: "none",
  },
  contentWrapper: {
    overflowY: "scroll",
    overflowX: "hidden",
    height: 700,
    padding: 10,
  },
});

InvoiceGeneralInfo.propTypes = {
  onDateChange: PropTypes.func,
  onClientChange: PropTypes.func,
  invoice: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  onRecurrentChange: PropTypes.func,
  onDiscountBlur: PropTypes.func,
};
export default withWidth()(
  withStyles(styles, { withTheme: true })(InvoiceGeneralInfo)
);
