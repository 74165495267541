import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Input, TextField, Typography } from '@material-ui/core';
import Lang from '../lang';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { PaymentPhase } from '../Models/Models';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CloseIcon from "@material-ui/icons/Close";
import { UrlEnum } from '../Utils/Utils';
import Config from '../Utils/Config';

type OrderTicketPaymentsProps = {
    payments: Array<PaymentPhase>,
    open: boolean,
    totalWithVat: number,
    onPercentBlur: (e: React.FocusEvent<HTMLInputElement>, index: number) => void,
    onChange: (e: React.ChangeEvent<HTMLInputElement>, index: number) => void,
    onAddPaymentPhase: (e: React.MouseEvent) => void,
    onRemovePaymentPhase: (e: React.MouseEvent, index: number) => void,
    onClose: (e: React.MouseEvent) => void,
    onSave: (e: React.MouseEvent) => void;
};

const lang = Lang.getInstance();


function OrderTicketPayments(props: OrderTicketPaymentsProps) {

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
        >
            <DialogTitle>
                {lang.get("installmentPayments")}
                <IconButton onClick={props.onAddPaymentPhase}>
                    <AddCircleIcon />
                </IconButton>
                <IconButton onClick={props.onClose} style={{ float: "right" }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent style={{ padding: 30 }}>
                <OrderTicketPaymentsPreview
                    payments = {props.payments}
                    totalWithVat = { props.totalWithVat }
                    onChange = {props.onChange}
                    onRemovePaymentPhase = { props.onRemovePaymentPhase }
                    onPercentBlur = { props.onPercentBlur }
                />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="primary"
                    onClick={props.onSave}
                >
                    {lang.get("save")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}


type OrderTicketPaymentsPreviewProps = {
    payments: Array<PaymentPhase> | undefined,
    totalWithVat: number,
    onPercentBlur?: (e: React.FocusEvent<HTMLInputElement>, index: number) => void,
    onChange?: (e: React.ChangeEvent<HTMLInputElement>, index: number) => void,
    onRemovePaymentPhase?: (e: React.MouseEvent, index: number) => void,
    hideButtons?: boolean
};

const styles = {
    wrapper:{
        fontSize:"9pt",
        display: "block"
    },
    container: {width:"100%"},
    row1: {width:"55%", display:'inline-block', margin:7},
    row2: {width:"18%", display:'inline-block', margin:7},
    row3: {width:"13%", display:'inline-block', margin:7},
    row4: {width:"3%", display:'inline-block', margin:7},
    textField: {width:"100%", border:"none", borderBottom:"1px solid #ccc"},
    hide:{
            display: "none"
    }
};


export function OrderTicketPaymentsPreview(props: OrderTicketPaymentsPreviewProps) {
    let totalPercent:number = 0;
    if(!props.payments) return null;

    for(const p of props.payments){
        totalPercent += parseInt(p.percent.toString());
    }
    return (
        <div style={{clear:"both",...styles.wrapper}}>
            {props.payments.map((paymentPhase: PaymentPhase, index: number) => (
                <div style={styles.container} key={index}>
                    <div style={styles.row1}>
                        <input
                            style={styles.textField}
                            name="text"
                            value={paymentPhase.text}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.onChange ? props.onChange(e, index) : null}
                        />
                    </div>
                    <div style={{...styles.row2, position:"relative"}}>
                        <input
                            style={styles.textField}
                            type="number"
                            name="percent"
                            value={paymentPhase.percent}
                            onBlur={(e: React.FocusEvent<HTMLInputElement>) => props.onPercentBlur ? props.onPercentBlur(e, index) : null}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.onChange ? props.onChange(e, index) : null}
                        />
                        <label style={{position:"absolute", right:20}} >%</label>
                    </div>
                    <div style={styles.row3} >
                        <span style={{ marginBottom:10, marginLeft:20 }}>{(props.totalWithVat * paymentPhase.percent / 100).toFixed(Config.noOfDecimals)}</span>
                    </div>
                    <div style={styles.row4}>
                        <IconButton style={props.hideButtons ? styles.hide : {}} onClick={(e: React.MouseEvent) => props.onRemovePaymentPhase ? props.onRemovePaymentPhase(e, index) : null}>
                            <RemoveCircleIcon />
                        </IconButton>
                    </div>
                </div>
            ))}
            {/* TOTAL */}
            <div style={styles.container}>
                <div style={styles.row1}>
                    <span>{lang.get("amountWithVat")}</span>
                </div>
                <div style={styles.row2}>
                    <span>{ totalPercent } %</span>
                </div>
                <div style={styles.row3} >
                    <span style={{ fontWeight: "bold", marginLeft:20}}>{props.totalWithVat.toFixed(Config.noOfDecimals)}</span>
                </div>
                <div style={styles.row4}>
                </div>
            </div>
        </div>
    );
}

export default OrderTicketPayments;