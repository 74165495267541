import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Lang from '../../lang';
import { Paper, CircularProgress } from '@material-ui/core';
import { UrlEnum, get } from '../../Utils/Utils';
import AccountInfo from '../AccountOptions/AccountInfo';
import Options from './Options';
import { UserProfile } from '../../Models/Models';
import { AccountInformation } from '../../Models/Models';



let lang = Lang.getInstance();
function TabPanel(props) {

  const { children, value, index, ...other } = props;
  const useStylesTypography = makeStyles(theme => ({
    root: {
      backgroundColor: theme.palette.background.paper,
      width: "100%",
      height: "100%",
      maxWidth: 900,
      margin: "0 auto"
    },
    tab: {
      fontSize: "",
      [theme.breakpoints.down('md')]: {
        fontSize: 10
      },
    },
  }));


  const styles = useStylesTypography();
  return (
    <Typography
      className={styles.tab}
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
    height: "100%",
    maxWidth: 900,
    margin: "0 auto"
  },
  loading: {
    margin: "150px auto 0 auto",
    display: "block"
  },
  tab: {
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: '10%',
    width: '30%',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    [theme.breakpoints.down('md')]: {
      width: '90%',
    },
  },
  tabColor: {
    backgroundColor: theme.palette.header?.main
  }

}));

const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > div": {

      width: "100%",
      height: '120%',
      backgroundColor: "#fff"
    }
  }
})(props => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

const StyledTab = withStyles(theme => ({
  root: {
    textTransform: "none",
    color: "#fff",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(18),
    wordSpacing: theme.typography.pxToRem(0),
    marginRight: theme.spacing(0),

    [theme.breakpoints.down('md')]: {
      paddingTop: 20,
      fontSize: theme.typography.pxToRem(13),
    },

    "&:focus": {
      opacity: 20,
    },

  }
}))(props => <Tab disableRipple {...props} />);



export default function FullWidthTabs() {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [userProfileModel, setUserProfileModel] = useState( new UserProfile());
  const [accountOptions, setAccountoptions] = useState(AccountInformation());
  const [loading, setLoading] = useState(false);
  /**
   * 
   * @param {*} event 
   * @param {*} newValue 
   */
  const handleChange = (event, newValue) => {
    setValue(newValue);
    getCompanyOptions();
  };

  /**
   * 
   * @param {*} index 
   */
  const handleChangeIndex = index => {
    setValue(index);
  };

  /**
   * Get data dor UserInfo and Company method
   */
  useEffect(() => {
    setLoading(true);
    get(UrlEnum.getUserInfo).then((data) => {
      if (!data) {
        setLoading(false);
        alert(lang.get("notFound"));
        return;
      }
      setUserProfileModel(data);
      setLoading(false);

    }).catch(() => {
      setLoading(false);
    });
    /**
     * Call CompanyOptions method
     */
    getCompanyOptions();
  }, []);

  /**
   * Get data for Option tab
   */
  function getCompanyOptions() {
    get(UrlEnum.companyOptions).then((data) => {
      if (data.errors) {
        setLoading(false);
        alert(lang.get("notFound"));
        return;
      }
      if (data.notificationDelay) {
        setAccountoptions(Object.assign(AccountInformation(),data));
      }
    }).catch(() => {
      setLoading(false);
    })
  }

  /**
   * return for render
   */
  return (
    loading ? <CircularProgress color="secondary" className={classes.loading} /> :
      <Paper className={classes.root}  >
        <AppBar position="static" color="default">
          <StyledTabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            style={{color: theme.palette.textColorSecondary?.main}}
            variant="fullWidth"
            aria-label="full width tabs example"
            className={classes.tabColor}
          >
            <StyledTab label={lang.get("account")} {...a11yProps(0)} />
            <StyledTab label={lang.get("other")} {...a11yProps(1)} />
          </StyledTabs>

        </AppBar>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value}
          onChangeIndex={handleChangeIndex}

        >
          <TabPanel value={value} index={0} >
            <AccountInfo
              url={UrlEnum.userUpdate}
              model={userProfileModel}
            />
          </TabPanel>
          <TabPanel value={value} index={1} >
            <Options
              url={UrlEnum.companyOptions}
              model={accountOptions}
            />
          </TabPanel>
        </SwipeableViews>
      </Paper>

  );
}


