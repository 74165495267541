import React, { PureComponent } from "react";
import { DatePicker } from "@material-ui/pickers";
import {
  withStyles,
  Select,
  Paper,
  FormControl,
  Modal,
  InputLabel,
  Grid,
  createStyles,
  Theme,
  Checkbox,
  MenuItem,
} from "@material-ui/core";
import { TextValidator } from "react-material-ui-form-validator";
import Lang from "../lang";
import CachedDataSingleton from "../cachedDataSingleton";
import { isMobile, Currency, UrlEnum, DocTypes } from "../Utils/Utils";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  AutocompleteChangeReason,
} from "@material-ui/lab/Autocomplete";
import IconButton from "@material-ui/core/IconButton";
// import EditIcon from "@material-ui/icons/Edit";
import Typography from "@material-ui/core/Typography";
import { Address, Client, Estimate } from "../Models/Models";
// import AddCircleIcon from "@material-ui/icons/AddCircle";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import EditClient from "../Forms/EditClient";
import CloseIcon from "@material-ui/icons/Close";
import Config from "../Utils/Config";
import withWidth from "@material-ui/core/withWidth";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import theme from "../Theme/Theme";
import EditClientModal from "./EditClientModal";

const lang = Lang.getInstance();

type EstimateGeneralInfoProps = {
  estimate: Estimate;
  onChange: (e: any, value: any) => void;
  onDateChange: (dateProp: string, momentDate: MaterialUiPickersDate) => void;
  onClientChange: (
    e: any,
    value: Client | null,
    reason: AutocompleteChangeReason,
    details?: any
  ) => void;
  onSubcontractorChange: (
    e: any,
    value: Client | null,
    reason: AutocompleteChangeReason
  ) => void;
  classes: any;
};

type EstimateGeneralInfoState = {
  model: Estimate;
  showText: boolean;
  activeModal: boolean;
  editButton: boolean;
  alert: boolean;
  sameAsClient: boolean;
};

class EstimateGeneralInfo extends PureComponent<
  EstimateGeneralInfoProps,
  EstimateGeneralInfoState
  > {
  cachedData: any;
  clients: any;
  subcontractors: any;
  vatTypes: any;
  checked: boolean;
  newEditClient: Client | null;
  title: string;

  constructor(props: EstimateGeneralInfoProps) {
    super(props);
    this.cachedData = CachedDataSingleton.getInstance();
    this.vatTypes = this.cachedData.get("vatTypes");
    this.clients = this.cachedData.get("clients");
    this.subcontractors = this.cachedData.get("subcontractors");
    this.checked = false;
    this.state = {
      model: new Estimate(),
      showText: false,
      activeModal: false,
      editButton: true,
      alert: false,
      sameAsClient: true
    };
    this.newEditClient = null;
    this.title = "";
  }

  /**
   * Show Alert Dialog message
   */
  handleChangeAlert() {
    this.setState({ alert: !this.state.alert });
  }

  /**
   * Modal handle for edit clients
   */
  handleOpenModalEdit() {
    this.setState({ activeModal: !this.state.activeModal });
    this.newEditClient = this.props.estimate.client;
    this.title = lang.get("edit") + ": " + lang.get("client");
  }
  /**
   * Modal handle for new clients
   */
  handleOpenModalNew() {
    this.setState({ activeModal: !this.state.activeModal });
    this.newEditClient = new Client();
    this.title = lang.get("newClient");
  }

  /**
   * Close Modal
   */
  handleCloseModal() {
    this.setState({ activeModal: !this.state.activeModal });
  }

  /**
   * Add a new client in chachedData
   */
  handleClientChange(client: Client) {
    let indexEditedModel = this.clients.findIndex(
      (c: Client) => c.id === client.id
    );
    if (indexEditedModel >= 0) {
      this.clients.splice(indexEditedModel, 1, client);
    } else {
      this.clients.push(client);
    }
    this.cachedData.setKey("clients", this.clients);
    this.props.onClientChange(null, client, "select-option", this.state.sameAsClient);
  }

  /**
   *
   * @param md
   */
  onChangeDate(prop: string, md: MaterialUiPickersDate) {
    if (!md) return;
    this.props.onDateChange(prop, md);
  }

  componentDidMount() {
    if (!this.props.estimate.id) {
      if (!this.clients.length)
        this.handleOpenModalNew();
    }
  }

  renderSubcontractors() {
    if (this.props.estimate.type !== DocTypes.subcontractorOrderTicket) return null;
    return (
      <Autocomplete
        disabled={this.props.estimate.status.id !== 1}
        fullWidth={true}
        id="subcontractor"
        options={this.subcontractors}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField
            {...params}
            label={lang.get("subcontractor")}
            error={this.props.estimate.fk_SubcontractorId > 0 ? false : true}
          />
        )}
        onChange={(e: any, subcontractor: any | null, reason: AutocompleteChangeReason, details?: any) => this.props.onSubcontractorChange(e, subcontractor, reason)}
        value={this.subcontractors.find((s: any) => s.id === this.props.estimate.fk_SubcontractorId)}
        getOptionSelected={(option) =>
          option.id === this.props.estimate.fk_SubcontractorId
        }
      />
    );
  }

  /**
   * Render method
   */
  render() {
    const classes = this.props.classes;
    const countries = this.cachedData.get("countries");
    return (
      <div>
        <Paper className={classes.paper}>

          <Grid container>
            <Grid className={`${classes.grid} ${classes.borderRight}`} item xs={12} sm={12} md={6}>
              <Grid container>
                {this.renderSubcontractors()}
              </Grid>

              <FormControl required={true} fullWidth={true}>
                <Typography component="div">
                  {this.state.showText ? (
                    <div>
                      <em>
                        <small>
                          {lang.get("vatNo")}:
                          {this.props.estimate.client.vatNo
                            ? this.props.estimate.client.vatNo
                            : ""}
                          <br />
                          {lang.get("address")}:{" "}
                          {Address.stringify(
                            this.props.estimate.client.address
                              ? this.props.estimate.client.address
                              : null
                          )}
                        </small>
                      </em>
                    </div>
                  ) : (
                      ""
                    )}
                </Typography>
                <br></br>
              </FormControl>
              <Grid container spacing={3}>
                <Grid className={classes.grid} item xs={12} sm={12} md={6}>
                  <TextValidator
                    disabled={this.props.estimate.status.id !== 1}
                    fullWidth
                    required={true}
                    type="number"
                    id="estimateNo"
                    name="estimateNo"
                    label={lang.get("number")}
                    onChange={this.props.onChange}
                    validators={["required"]}
                    value={this.props.estimate.estimateNo}
                    errorMessages={lang.get("fieldRequired")}
                  />

                  <FormControl required fullWidth>
                    <InputLabel shrink>{lang.get("startDate")}</InputLabel>
                    <DatePicker
                      disabled={this.props.estimate.status.id !== 1}
                      style={{ marginTop: 16 }}
                      required={true}
                      disableToolbar
                      variant="inline"
                      name="startDate"
                      value={
                        !this.props.estimate.startDate
                          ? this.state.model.startDate
                          : this.props.estimate.startDate
                      }
                      format={Config.momentEUDateFormat}
                      onChange={(md) => this.onChangeDate("startDate", md)}
                    />
                  </FormControl>

                  <FormControl required fullWidth>
                    <InputLabel shrink>{lang.get("endDate")}</InputLabel>
                    <DatePicker
                      disabled={this.props.estimate.status.id !== 1}
                      style={{ marginTop: 16 }}
                      required={true}
                      disableToolbar
                      variant="inline"
                      minDate={this.props.estimate.startDate}
                      name="endDate"
                      value={
                        !this.props.estimate.endDate
                          ? this.state.model.endDate
                          : this.props.estimate.endDate
                      }
                      format={Config.momentEUDateFormat}
                      onChange={(md) => this.onChangeDate("endDate", md)}
                    />
                  </FormControl>

                  <TextValidator
                    disabled={this.props.estimate.status.id !== 1}
                    fullWidth
                    id="title"
                    name="title"
                    label={lang.get("title")}
                    onChange={this.props.onChange}
                    value={this.props.estimate.title || ""}
                  />

                  <TextValidator
                    disabled={this.props.estimate.status.id !== 1}
                    fullWidth
                    id="description"
                    name="description"
                    label={lang.get("description")}
                    onChange={this.props.onChange}
                    value={this.props.estimate.description || ""}
                  />
                </Grid>
                <Grid className={classes.grid} item xs={12} sm={12} md={6}>
                  <TextValidator
                    fullWidth
                    disabled={this.props.estimate.status.id !== 1}
                    name="series"
                    label={lang.get("series")}
                    onChange={this.props.onChange}
                    validators={["required"]}
                    value={this.props.estimate.series}
                    errorMessages={lang.get("fieldRequired")}
                  />
                  <FormControl required={true} fullWidth={true}>
                    <InputLabel>{lang.get("vatRate")}</InputLabel>
                    <Select
                      disabled={this.props.estimate.status.id !== 1}
                      id="vatRate"
                      name="vatRate"
                      value={parseInt(this.props.estimate.vatRate.toString())}
                      onChange={this.props.onChange}
                    >
                      {this.vatTypes.map((vt: any, index: number) => (
                        <MenuItem key={index} value={vt.value}>
                          {vt.description}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <TextValidator
                    fullWidth
                    disabled={this.props.estimate.status.id !== 1}
                    id="discount"
                    name="discount"
                    label={lang.get("discount") + " (%)"}
                    value={this.props.estimate.discount}
                    onChange={this.props.onChange}
                    validators={["required", "minNumber:0", "maxNumber:100"]}
                    errorMessages={[
                      lang.get("fieldRequired"),
                      lang.get("value") + ":0-100",
                      lang.get("value") + ":0-100",
                    ]}
                  />

                  <FormControl required={true} fullWidth={true}>
                    <InputLabel>{lang.get("curency")}</InputLabel>
                    <Select
                      disabled={this.props.estimate.status.id !== 1}
                      id="currency"
                      name="currency"
                      value={
                        this.props.estimate.currency === '0' ? Currency.EURO : this.props.estimate.currency || Currency.EURO
                      }
                      onChange={this.props.onChange}
                    >
                      {Object.keys(Currency).map((key: string) => {
                        const currency: any = Currency;
                        return <MenuItem key={key} value={currency[key]}>{currency[key]}</MenuItem>;
                      }
                      )}
                    </Select>
                  </FormControl>
                  <TextField
                    multiline
                    fullWidth
                    disabled={this.props.estimate.status.id !== 1}
                    id="additionalInfo"
                    name="additionalInfo"
                    label={lang.get("additionalInformation")}
                    value={this.props.estimate.additionalInfo}
                    onChange={(e) => this.props.onChange(e, e.target.value)}
                  />
                </Grid>
              </Grid>

            </Grid>

            <Grid item xs={12} sm={12} md={6}>

              <Accordion
                expanded={isMobile() ? undefined : true}
                className={classes.panel}
              >
                {isMobile() ?
                  <AccordionSummary
                    expandIcon={
                      isMobile() ? <ExpandMoreIcon fontSize={"large"} /> : null
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>{lang.get("client")}</Typography>
                  </AccordionSummary>
                  : null}
                <FormControl required={true} fullWidth={true}>
                <EditClientModal
                    client={this.props.estimate.client}
                    onClientChange={(e, client, reason, details) => this.props.onClientChange(e, client, reason, this.state.sameAsClient ? "sameAsClient" : null)}
                    disabled={false}
                    
                  />
                </FormControl>

                <Grid className={classes.grid} container spacing={3}>
                  <Grid className={classes.grid} item xs={12} sm={12} md={6}>
                    <TextValidator
                      label={lang.get("clientAddress")}
                      value={ this.props.estimate.client ? Address.stringify(
                        this.props.estimate.client.address
                      ): ""}
                      name="client.address"
                      id="client.address"
                      disabled
                      fullWidth
                    />
                    <div>
                      {lang.get("siteAddress")}
                      <Checkbox
                        checked={this.state.sameAsClient}
                        style={{color:theme.palette.header?.main }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const checked = e.target.checked;
                          this.setState({ sameAsClient: checked });
                          if (checked && this.props.estimate.client?.address.address !== this.props.estimate.site_address.address) {
                            this.props.onClientChange(e, this.props.estimate.client, 'select-option', "sameAsClient");
                          }

                        }}
                      />
                      <em>{lang.get("sameAsClient")}</em>
                    </div>
                    <TextValidator
                      disabled={this.props.estimate.status.id !== 1}
                      label={lang.get("address")}
                      value={this.props.estimate.site_address.address || ""}
                      name="site_address.address"
                      id="site_address.address"
                      fullWidth
                      onChange={(event: any, model: any) => {
                        this.setState({ sameAsClient: false });
                        this.props.onChange(event, model);
                      }}
                    />
                    <TextValidator
                      disabled={this.props.estimate.status.id !== 1}
                      label={lang.get("code")}
                      value={this.props.estimate.site_address.code || ""}
                      name="site_address.code"
                      id="site_address.code"
                      fullWidth
                      onChange={this.props.onChange}
                    />
                  </Grid>

                  <Grid className={classes.grid} item xs={12} sm={12} md={6}>

                    <TextValidator
                      disabled={this.props.estimate.status.id !== 1}
                      label={lang.get("vatNo")}
                      value={this.props.estimate.client?this.props.estimate.client.vatNo:""}
                      name="client.vatNo"
                      id="client.vatNo"
                      fullWidth
                      onChange={this.props.onChange}
                    />

                    <TextValidator
                      disabled={this.props.estimate.status.id !== 1}
                      style={{ marginTop: 42 }}
                      label={lang.get("city")}
                      value={this.props.estimate.site_address.city || ""}
                      name="site_address.city"
                      id="site_address.city"
                      fullWidth
                      onChange={this.props.onChange}
                    />

                    {/** country  */}
                    <FormControl fullWidth>
                      <InputLabel >{lang.get("country")}</InputLabel>
                      <Select
                        fullWidth
                        disabled={this.props.estimate.status.id !== 1}
                        id="site_address.country"
                        name="site_address.country"
                        value={this.props.estimate.site_address.fk_countryId}
                        onChange={(e) => this.props.onChange(e, null)}
                      >
                        {countries.map((country: any) =>
                          <MenuItem value={country.id} id="site_address.fk_countryId" key={country.id}>{country.name}</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Accordion>
            </Grid>
          </Grid>
        </Paper>

        {this.state.activeModal ? (
          <Modal
            className={classes.modalWindow}
            open={true}
            onClose={this.handleCloseModal.bind(this)}
          >
            <Slide
              direction="down"
              in={this.state.activeModal}
              mountOnEnter
              unmountOnExit
            >
              <Paper className={classes.modalPaper}>
                <div
                  style={{
                    position: "relative",
                    padding: 5,
                    borderBottom: "1px solid #ccc",
                  }}
                >
                  <Typography variant="h5" gutterBottom>
                    {this.title}
                  </Typography>
                  <IconButton
                    style={{ position: "absolute", right: 0, top: 0 }}
                    color="inherit"
                    id="addNew"
                    onClick={this.handleCloseModal.bind(this)}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
                <br></br>
                <div className={classes.contentWrapper}>
                  <EditClient
                    url={UrlEnum.clients}
                    model={this.newEditClient}
                    submitCallback={this.handleClientChange.bind(this)}
                    onClose={this.handleCloseModal.bind(this)}
                  />
                </div>
              </Paper>
            </Slide>
          </Modal>
        ) : (
            ""
          )}
      </div>
    );
  }
}
const styles = createStyles((theme: Theme) => ({
  modalWindow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  borderRight: {
    borderRight: "1px solid",
    borderColor: theme.palette.text.secondary,
    paddingRight: 20,
    [theme.breakpoints.down("md")]: {
      border: "none"
    },
  },

  panel: {
    boxShadow: "none",
    expanded: true,
    [theme.breakpoints.down("md")]: {
      expanded: false,
      boxShadow: "0px 0px 0px 2px rgba(235,235,235,1)",
      paddingBottom: 10,
    },
  },
  paper: {
    display: "flex",
    padding: 10,

  },
  grid: {
    padding: 10,
  },
  gridFlex: {
    display: "flex",
    paddingLeft: 10,
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 10,
    },
  },

  editButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  iconButton: {
    marginTop: 10,
  },
  margin: {
    marginTop: 5,
  },

  paddingSmall: {
    [theme.breakpoints.down("md")]: {
      paddingRight: 10,
      paddingLeft: 10,
    },
  },
  modalPaper: {
    maxWidth: 900,
    maxHeight: 900,
    paddingRight: 10,
    paddingLeft: 10,
    outline: "none",
  },
  contentWrapper: {
    overflowY: "scroll",
    overflowX: "hidden",
    height: 500,
    padding: 10,
  },
}));

export default withWidth()(
  withStyles(styles, { withTheme: true })(EstimateGeneralInfo)
);
