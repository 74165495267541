import React, { useEffect, useState } from "react";
import Lang from "../lang";
import DrawerLayout from "./DrawerLayout";
import EditClient from "../Forms/EditClient";
import { UrlEnum, ResolutionBreakPoints, get } from "../Utils/Utils";
import { Client } from "../Models/Models";
import ClientsTable from "../Tables/ClientsTable";
import { useRouteMatch } from "react-router-dom";
import { CircularProgress, IconButton } from "@material-ui/core";
import ControlPointIcon from "@material-ui/icons/ControlPoint";
import { isMobile } from "../Utils/Utils";
import { useHistory } from "react-router-dom";
import CachedDataSingleton from "../cachedDataSingleton";

const lang = Lang.getInstance();
const cachedData = CachedDataSingleton.getInstance();

const isMobileView = isMobile();

const docWidth = document.body.offsetWidth;
const docHeight = window.innerHeight;
let maxHeight = docHeight - 225;

const parse = {
  address: (address) => {
    return `${address.address} ${address.code} ${address.city}`;
  },
};

export default function Clients(props) {
  let initialTableColumns = [
    {
      label: lang.get("name"),
      name: "name",
      customRender: (client) => {
        return (
          <div
            style={{ cursor: "pointer" }}
            key={"cel" + client.id}
            onClick={() => {
              openDrawer(client);
              const path = routeMatch.path.substring(
                0,
                routeMatch.path.indexOf(":")
              );
              history.push(path + client.id);
            }}
          >
            {client.name}
          </div>
        );
      },
    },
    { label: lang.get("mobile"), name: "mobile" },
    {
      label: lang.get("email"),
      name: "email",
      customRender: (client) => {
        return (
          <div style={{ wordBreak: "break-all" }}>
            {client.email}
          </div>
        );
      },
    },
  ];
  // medium resolution
  if (docWidth <= ResolutionBreakPoints.md) {
    initialTableColumns = [
      { label: lang.get("name"), name: "name" },
      { label: lang.get("email"), name: "email" },
    ];
    maxHeight = docHeight - 195;
  }

  // small resolution
  if (docWidth <= ResolutionBreakPoints.sm) {
    initialTableColumns = [{ label: lang.get("name"), name: "name" }];
  }

  const routeMatch = useRouteMatch();
  const history = useHistory();
  const [clientModel, setClientModel] = useState(new Client());
  const [loading, setLoading] = useState(false);
  const [tableColumns, setTableColumns] = useState(initialTableColumns);
  const [openEdit, setOpenEdit] = useState(
    !isMobileView && routeMatch.params.id !== undefined
  );
  const clients = cachedData.get("clients");
  const styles = {
    loading: {
      margin: "150px auto 0 auto",
      display: "block",
    },
  };

  /**
   * fetch client if needed
   */
  useEffect(() => {
    const id = routeMatch.params.id ? routeMatch.params.id : undefined;
    if (id && clientModel.id === 0) {
      setLoading(true);
      const url = UrlEnum.clients + "/" + id;
      get(url)
        .then((data) => {
          if (!data || !data.id) {
            alert(lang.get("notFound"));
            setLoading(false);
            return;
          }
          setClientModel(data);
          setLoading(false);
          setOpenEdit(true);
        })
        .catch(() => {
          setClientModel(new Client());
          setLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    let table = document.getElementById("tableDiv");
    if (table) {
      var ro = new ResizeObserver((entries) => getTableWidth(entries));
      ro.observe(table);
    }
  }, [tableColumns]);

  function getTableWidth(entries) {
    let crtTableColumns = tableColumns;
    let existAlready = crtTableColumns.find((element) => {
      return element.name === "address";
    });
    for (let entry of entries) {
      if (entry.contentRect.width > 1200) {
        if (!existAlready) {
          crtTableColumns.push({ label: lang.get("address"), name: "address" });
          setTableColumns(crtTableColumns);
        }
      } else {
        if (existAlready) {
          crtTableColumns = crtTableColumns.filter((e) => e.name !== "address");
          setTableColumns(crtTableColumns);
        }
      }
    }
  }

  /**
   * open Drawer
   */
  const openDrawer = function (model) {
    if (model !== clientModel) {
      setClientModel(model);
      clients.push(model);
      cachedData.setKey("clients", clients);
    }
    if (!openEdit) {
      setOpenEdit(true);
    }
  };

  const addNew = function () {
    setOpenEdit(true);
    setClientModel(new Client());
    const path = routeMatch.path.substring(0, routeMatch.path.indexOf(":"));
    history.push(path);
  };

  let renderEditClient = !loading ? (
    <EditClient
      url={UrlEnum.clients}
      model={clientModel}
      submitCallback={(client) => openDrawer(client)}
      showSmallMessage={props.showSmallMessage}
    />
  ) : (
    <CircularProgress color="secondary" style={styles.loading} />
  );

  const addBtnLabel = `${lang.get("add")} ${lang.get("client")}`;

  /**
   * render method
   */
  return (
    <DrawerLayout
      drawerChildren={renderEditClient}
      open={openEdit}
      handleDrawerClose={() => setOpenEdit(!openEdit)}
    >
      <h3>
        {lang.get("clients")}
        <IconButton
          color="inherit"
          title={addBtnLabel}
          aria-label={addBtnLabel}
          onClick={addNew}
          id="addNew"
        >
          <ControlPointIcon />
        </IconButton>
      </h3>
      <div id="tableDiv">
        <ClientsTable
          editedModels={[clientModel]}
          url={UrlEnum.clients}
          columns={tableColumns}
          parse={parse}
          maxHeight={maxHeight}
          openDrawer={openDrawer}
        />
      </div>
    </DrawerLayout>
  );
}
