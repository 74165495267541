import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import {List, ListItem, ListItemText, Collapse, makeStyles, IconButton} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import theme from "../Theme/Theme";
const useStyles = makeStyles(theme => ({
    listItem:{
        borderBottom: "1px solid #ccc",
        paddingTop:0,
        paddingBottom:0,
        "&:hover #deleteBtn, &:hover #editBtn":{
            opacity:1
        }
    },
    listItemText:{
        fontSize: "10pt",
        textTransform:"capitalize"
    },
    container:{
        background: "#ffffff88",
        padding: "0 10px 10px 10px",
        "& .MuiCollapse-container":{  
            background: "#ffffff",
            padding: "0 10px 10px 10px",
        }
    },
    expand:{
        padding:3,
        border:"1px solid #ccc"
    },
    delete:{
        padding:3,
        opacity:0,
        transition: "opacity 1s",
        marginRight:15
    },
    edit:{
        padding:3,
        opacity:0,
        transition: "opacity 1s",
        marginRight:10
    }
}));

export default function HierarchicalList(props){
    const classes = useStyles();
    const [openModels, setOpenModels] = useState([]);

    /**
     * handle click event
     * @param {*} e 
     * @param {*} model 
     */
    const handleClick = (e, model)=>{
        e.stopPropagation();

        if(openModels.length > 0){
            const last = openModels[openModels.length-1];
            // remove if they exist
            for(let i = 0; i< openModels.length; i++){
                const crtModel = openModels[i];

                // remove all if first model is the one clicked
                if(i===0 && crtModel === model){
                    setOpenModels([]);
                    return;
                }
                // remove the clicked model
                if(crtModel === model){
                    const models = openModels.slice().splice(i-1,1);
                    setOpenModels(models);
                    return;
                }
            }
            // if it's not removed, add it at the end if it's a child
            if(last.id === model[props.foreignKey]){
                if(model[props.childrenLabel].length === 0){
                    return;
                }
                const models = openModels.concat([model]);
                setOpenModels(models);
                return;
            }
        }
        // if it's not a child remove all and add new parent
        setOpenModels([model]);
    };

    function handleDeleteClick(e, model){
       props.onDelete(e, model);
    }

    function handleEditClick(e, model){
        props.onEdit(e, model);
    }

    const renderChildren = (models)=>{
        return models.map((model, index)=>
        <Fragment key={model.id} >
        <ListItem button  className={classes.listItem} >
            {   props.onSelect ? 
                <Fragment><input type="checkbox" checked={model.selected ? model.selected : false} onChange={(e) => props.onSelect(e, model)} style={{color:theme.palette.header?.main}}/> &nbsp;</Fragment>
                : ""
            }
            <span style={{paddingTop:"5px", paddingBottom:"5px", width:"100%"}} onClick={ (e) => props.onClick(e,model) } >
                <ListItemText primary={model.name} lasses={{primary:classes.listItemText}}/>
            </span>
            { props.onEdit ? <IconButton color="secondary" id="editBtn" onClick={(e)=>handleEditClick(e, model)} className={classes.edit}><CreateIcon /></IconButton> : "" }
            { props.onDelete ? <IconButton color="secondary" id="deleteBtn" onClick={(e)=>handleDeleteClick(e, model)} className={classes.delete}><DeleteIcon /></IconButton> : "" }
            {model[props.childrenLabel] && model[props.childrenLabel].length > 0  ?
                <IconButton onClick={(e)=>handleClick(e, model)} className={classes.expand}>{openModels.indexOf(model) >=0 ? <ExpandLess />: <ExpandMore />}</IconButton> : 
                ""
            }
        </ListItem>
        {
            model[props.childrenLabel] ? 
            <Collapse in={openModels.indexOf(model) >=0 } unmountOnExit className={classes.container}>
                <List>{renderChildren(model[props.childrenLabel])}</List>
            </Collapse> : 
            ""
        }
        </Fragment>
        ); 
    }

    return(
        <List>
            { renderChildren(props.models) }
        </List>
    );
}

HierarchicalList.propTypes = {
    models: PropTypes.array.isRequired,
    childrenLabel: PropTypes.string.isRequired,
    foreignKey: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onEdit: PropTypes.func,
    onSelect: PropTypes.func
};