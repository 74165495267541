import React, {useState, Fragment, useEffect} from 'react';
import { Button, makeStyles, Modal, Switch, Fade, Paper, Dialog, DialogTitle, DialogActions, DialogContent, TextField,LinearProgress, CircularProgress} from '@material-ui/core';
import wordIcon from'../Images/icons/Word-icon.png';
import excelIcon from'../Images/icons/Excel-icon.png';
import pdfIcon from'../Images/icons/PDF-icon.png';
import genericIcon from'../Images/icons/Generic-icon.png';
import folderIcon from'../Images/icons/folder.png';
// import noData from '../Images/nodata.png';
import NoDataFound from '../Components/NoDataFound';
import PropTypes from 'prop-types';
import { DropzoneArea } from 'material-ui-dropzone';
import clsx from 'clsx';
import Lang from '../lang';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { post} from '../Utils/Utils';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import theme from '../Theme/Theme';

const useStyles = makeStyles(theme => ({
    "file":{
        "width": 75,
        "margin": "15px 15px 5px 10px",
        "padding": 5,
        "lineHeight": "12pt",
        "& a": {
            "display": "flex",
            flexDirection: "column",
        },
        "& span": {
            "display": "block",
            "overflow": "hidden",
        },
        "& span label": {
            "textTransform": "none",
            "fontSize": "10pt",
            "letterSpacing": "normal",
            "fontWeight": "normal"
        },
        "& img":{
            "maxHeight": 70
        }
    },
    "active":{
        "border": "2px solid #2196f3"
    },
    "header":{
        "borderBottom": "1px solid #ccc",
        "padding":5,
        "marginTop":20,
        "position": "relative",
    },
    "modal": {
        "padding": 10,
        "display": 'flex',
        "alignItems": 'center',
        "justifyContent": 'center',
      },
    "paper":{
        "padding":10,
        "maxWidth":600,
        "& img": {
            "maxWidth":"100%"
        }
    },
    "menu":{
        display:"flex",
        [theme.breakpoints.down('sm')]:{
            display:"block"
        }
    },
    "leftMenu":{
        flexGrow:1,
    }
}));

/**
 * test if file is image
 * @param {Object} file 
 */
function isImage(file){
    const ext = file.name.substring(file.name.lastIndexOf(".")+1).toLowerCase();
    return ext === "jpg" || ext === "jpeg" || ext === "png" || ext === "gif";
}
let parentFiles = [];
let crtPath = "/";

export default function FileExplorerMin(props){

    const [files, setFiles] = useState(props.files);
    const [select, setSelect] = useState(false);
    const [upload, setUpload] = useState(false);
    const [loading, setLoading] = useState(false);
    const [activeImage, setActiveImage] = useState(null);
    const [newUploadedFiles, setNewUploadedFiles] = useState([]);
    const [isPreview, setIsPreview] = useState(false);
    const [previewImages, setPreviewImages]= useState([]);
    const lang = Lang.getInstance();
    const classes = useStyles();
    const [newFolder, setNewFolder] = useState(null);
    const [path, setPath] = useState(lang.get("home"));

    useEffect(() => {
        setFiles(props.files);
    }, [props.files]);

    /**
     * change select to file from default action
     */
    const handleSelectChange = () => {
        setSelect(!select);
    };

    /**
     * enable or disable upload component
     */
    const showUpload = () =>{
        setUpload(!upload);
    };

    /**
     * confirm and delete files
     */
    const deleteFiles= () =>{
        const selected = files.filter((file)=>file.active);
        const notSelected = files.filter((file)=>!file.active);
        const paths = selected.map((file)=>file.path);
        const url = props.deleteUrl || props.url
        post(url, {paths:paths, id: props.id}).then((response)=>{
            if(response.errors){
                alert(lang.get("error"));
                return;
            }
            // remove all active files
            setFiles(notSelected);
        });
    }

    /**
     * what happens when you click a file
     * @param {Object} file 
     */
    const fileClick = (e, file) => {
        if(!isPreview){
            e.preventDefault();
            e.cancelBubble = true;
            if(!select){
                if(file.isDir){
                    parentFiles.push(files);
                    crtPath += "/"+file.name;
                    const newPath = path + "/"+file.name;
                    setPath(newPath);
                    file.files === null ?setFiles([]):setFiles(file.files);
                    return false;
                }

                if(isImage(file)){
                    e.preventDefault();
                    setActiveImage(file);
                    return false;
                }
                let newWindow = window.open();
                newWindow.location = file.path;
                return;
            }

            file.active = file.active ? !file.active : true;
            let newFiles = files.map(el => (el.path === file.path ? Object.assign({}, file) : el));
            setFiles(newFiles);
            return false;
        }
    };


    /**
     * 
     */
    const saveFolder = async (e)=>{
        if(!newFolder || newFolder.length === 0){
            setNewFolder(null);
            return;
        }
        
        const data = {
            id: props.id,
            crtPath: crtPath,
            folderName: newFolder
        };
        const response = await post(props.url + "/newFolder", data);
        if(!response.errors){
            let nf = files.slice();
            nf.push(response);
            setFiles(nf);
            setNewFolder(null);
        }
    }


    /**
     * go up one folder
     */
    const backClick = ()=>{
        const nameDir = crtPath.replaceAll('/','');
        if(parentFiles.length > 0){
            const index = parentFiles[0].findIndex(file => file.name === nameDir);
            if(index>0)
                parentFiles[0][index].files= files;
            setFiles(parentFiles.pop());
            crtPath = crtPath.substring(0, crtPath.lastIndexOf("/"));
            const newPath = path.substring(0, path.lastIndexOf("/"));
            setPath(newPath);
        }
    };

    /**
	 * 
	 * @param {*} files 
	 */
	const handleChangeDropFile = (files) =>{
		setNewUploadedFiles(files);
        let images=[];
        if(files.length!==0)
        files.forEach((file)=>{
            let reader = new FileReader();
            if(file.type.match('image.*')){
            reader.readAsDataURL(file);
            reader.onloadend = function() {
                images.push({path: reader.result, name:file.name, percentage:0, previewDocument:""});
                setPreviewImages(images);
                };
            }
        });
	}


    /**
     * Called when files were selected to be uploaded
     * 
     */
    async function uploadImage (formData,file){

        const index = previewImages.findIndex((image) => image.name === file.name);
        const promise = new Promise((resolve, reject)  =>{
            const request = new XMLHttpRequest();
            const url = props.url + "/" + (props.id ? props.id : "");
            request.responseType= "json";
            request.upload.addEventListener("progress", function(e){
                 let loaded = e.loaded;
                let total = e.total;
                let percentage = (loaded/total)*100;
                const crtImages = previewImages.slice();
                crtImages[index].percentage= parseInt(percentage);
                crtImages[index].previewDocument = renderFile(crtImages[index])
                setPreviewImages(crtImages);
            });
            request.onreadystatechange = function() {
                 if (request.readyState === 4) {
                    if(request.status !== 200)
                        reject();
                    else
                        return resolve(request.response);
                }
              }
            request.open("post",url); 
            request.send(formData);
        });
        return promise;
        
    }
    
    const doneUploadFiles = async()=>{
        
        let promises = [];
        setUpload(false);
        if(newUploadedFiles.length === 0){
            setUpload(false);
            return;
        }
        let prevImg=[];
        let crtImages = previewImages.slice();
        if(previewImages.length !== 0)
        {
            setIsPreview(true);
            prevImg = previewImages.map((image) => {
                return  renderFile(image);
            });
           for(let i=0;i<previewImages.length;i++)
            crtImages[i].previewDocument = prevImg[i];
            setPreviewImages(crtImages);
        }
        console.log(previewImages);
        for(let file of newUploadedFiles){
            const formData = new FormData()
            formData.append('file', file);
            formData.append('crtPath', crtPath);  
            promises.push(await uploadImage(formData,file));
            previewImages.forEach((image) => {
                if(file.name===image.name)
                    image.name = "";
            })

        }
       
        Promise.all(promises).then((responses)=>{
            let newFiles = [];
            setIsPreview(false);
            for(let response of responses){
                if(!response.errors){
                    newFiles.push(response);
                    
                }
            }
            setFiles(files.concat(newFiles));
            setNewUploadedFiles([]);
            setLoading(false);

        });

    };

    /**
     * Render One File type
     * @param {Object} file 
     */
    const renderFile = (file)=>{
        if(!file) return;
        const maxFileNameLength = 20;
        const name = file.name.length > maxFileNameLength ? 
            file.name.substring(0,maxFileNameLength) + ".." + file.name.substring(file.name.lastIndexOf(".")) : 
            file.name;
        const path = file.path;
        let image="";
        image = previewImages.find((image) => file.name===image.name)
        const ext = name.substring(name.lastIndexOf(".")+1).toLowerCase();
        let children = <Fragment><img src={genericIcon} alt="file"/><label>{name}</label>
        </Fragment>;
        switch(ext){
            case 'doc':
            case 'docx':
                children = <Fragment><img src={wordIcon} alt="file"/><label>{name}</label>
                {image? <LinearProgress value={file.percentage}/>: ""}
                </Fragment>;
                break;
            case 'xls':
            case 'xlsx':
                children = <Fragment><img src={excelIcon} alt="file"/><label>{name}</label>
                {image? <LinearProgress value={file.percentage}/>: ""}
                </Fragment>;
                break;
            case 'pdf':
                children = <Fragment><img src={pdfIcon} alt="file"/><label>{name}</label>
                {image? <LinearProgress value={file.percentage}/>: ""}
                </Fragment>;
                break;
            case 'jpg':
            case 'jpeg':
            case 'gif':
            case 'png':
                children = <Fragment><img src={path} alt="file"/><label>{name}</label>
                {image? <LinearProgress  style={{margin:"10px 10px 0px 10px"}} variant="determinate" value={file.percentage}/>: ""}
                </Fragment>;
                break;
            default:
                if(file.isDir){
                    image ? children = <Fragment><img src={image} alt="folder"/><label>{name}</label>
                     <LinearProgress value={file.percentage}/>
                    </Fragment>: 
                    children = <Fragment><img src={folderIcon} alt="folder"/><label>{name}</label>
                    </Fragment>
                }else{
                    children = <Fragment><img src={genericIcon} alt="file"/><label>{name}</label>
                    {image? <LinearProgress value={file.percentage}/>: ""}
                    </Fragment>;
                }
                break;
        }
        return <Button 
            onClick= {(e)=>{ return fileClick(e, file)}} 
            key={name} 
            className={clsx(classes.file, {
                [classes.active]: file.active
            })}>
            <a href={`${document.location.protocol}//${file.path}`} rel="noopener noreferrer" target="_blank">
                {children}
            </a>
            
        </Button>
    };

    /**
     * Render all files 
     */
    const renderFiles = ()=>{
        if((files && files.length > 0 && !upload)){
            return files.map((file)=>{
                return renderFile(file);
            });
        }
        if(files && files.length === 0 && !upload){
            return <div style={{padding:10}}><NoDataFound/></div>

        }
        if(upload){
            return(
                <DropzoneArea
                    maxFileSize={5000000}
                    dropzoneText={lang.get("dragFiles")}
					onChange={handleChangeDropFile.bind(this)} />
            );
        }
    };


    const renderMenu = () =>{
        let render = 
            <div className={classes.header} >
                <div><h3>{lang.get("attachedFiles")}</h3></div>
                <div className={classes.menu}>
                    <span className={classes.leftMenu}>
                        <Button onClick={backClick} disabled={crtPath==='/'}><ArrowBackIcon />{lang.get("back")}</Button>
                    </span>
                    <label>
                        {lang.get("select")}
                        <Switch
                            disabled={!files || files.length === 0}
                            checked={select}
                            onChange={handleSelectChange}
                            style={{color:theme.palette.header?.main}}
                            name="checkedB"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    </label>
                        <Button onClick={showUpload}><CloudUploadIcon />{lang.get("upload")}</Button>
                        <Button 
                            onClick={(e)=>{
                                e.preventDefault(); 
                                setNewFolder("new");
                                return false;
                        }}><CreateNewFolderIcon />
                            {lang.get("create")}
                        </Button>
                        <Button onClick={deleteFiles} disabled={!select || files.length === 0}><DeleteForeverIcon />{lang.get("delete")}</Button>
                </div>  
            </div>;
        if(upload){
            render = <div className={classes.header} align="right"><Button onClick={doneUploadFiles}><CloudUploadIcon />{lang.get("finish")}</Button></div>;
        }
        return render;
    };
    function renderPath(){
        const names = path.split('/');
        if(upload)
            return "";
        return <div style={{fontSize: "10pt",marginLeft:12,marginTop:5,display:"flex"}}>
            {names.map((n, index)=>{
                if(index + 1 === names.length)
                    return <p style={{ margin:3}}>{n}</p>
                return <p style={{ margin:3}}>{n} /</p>
            })}        </div>
    }

    return(
        <div>
            {renderMenu()}
            {renderPath()}
            {renderFiles()}
            {isPreview?
            previewImages.map((prev)=>{return prev.previewDocument}):""}
            {
                activeImage ? 
                <Modal
                    className={classes.modal}
                    open={true}
                    onClose={()=>setActiveImage(null)}
                    aria-labelledby="image-modal"
                    aria-describedby="image-modal"
                    >
                    <Fade in={true}>
                        <Paper className={classes.paper}>
                            <img src={activeImage.path} alt={activeImage.name}/>
                        </Paper>
                    </Fade>
                </Modal>
                : null
            }
            {
                newFolder ? 
                <Dialog
                    open={true}
                    onClose={()=>setNewFolder(null)}
                    aria-labelledby="newFolder-modal"
                    aria-describedby="newFolder-modal"
                    >
                    <DialogTitle>
                        {lang.get("new")}
                    </DialogTitle>
                    <DialogContent>
                        <TextField
                            required={true}
                            type="text"
                            name="folderName"
                            label={lang.get("name")}
                            onChange={(e)=>{setNewFolder(e.target.value)}}
                            validators={['required']}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" style={{color:theme.palette.textColorSecondary?.main, backgroundColor:theme.palette.header?.main}}
                            onClick={saveFolder}
                        >
                            {lang.get("save")}
                        </Button>
                    </DialogActions>
                </Dialog>
                : ""
            }
        {loading? <CircularProgress/>: ""}
        </div>
    );
}

FileExplorerMin.propTypes = {
    "files": PropTypes.array,
    "url": PropTypes.string.isRequired,
    "crtPath": PropTypes.string,
    "deleteUrl": PropTypes.string,
    "id": PropTypes.number.isRequired,
};
