import React, { useState } from "react";
import {
  makeStyles,
  Grid,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Lang from "../../lang";
import StripePersonalDates from "./StripePersonalDates";
import StripePersonalAddress from "./StripePersonalAddress";
import StripeUploadPhoto from "./StripeUploadPhoto";
import moment from "moment";
import Config from "../../Utils/Config";
import { post } from "../../Utils/Utils";
import { StripeAccountModel } from "../../Models/Models";
import { Alert } from "@material-ui/lab";
import { UrlEnum,StatusEnum } from "../../Utils/Utils";
import CachedDataSingleton from "../../cachedDataSingleton";

const lang = Lang.getInstance();
const cachedData = CachedDataSingleton.getInstance();

const useStyles = makeStyles((theme) =>
  Object.assign({
    modalContent: {
      minWidth: 590,
      minHeight: 150,
      paddingRight: 10,
      paddingLeft: 10,
      outline: "none",
    },
    textField: {
      margin: "15px",
    },
    button: {
      background: theme.palette.header?.main,
      color: theme.palette.textColorSecondary?.main,
      paddingTop: 5,
      paddingBottom: 5,
      margin: 10,
      width: "10%",
      display: "flex",
      flexDirection: "row",
      alignContent: "center",
      "&:hover": {
        background: theme.palette.header?.main,
        color: theme.palette.textColorSecondary?.main,
      },
      [theme.breakpoints.down("md")]: {
        width: "100%",
        flexDirection: "column",
        alignContent: "center",
      },
    },
    buttonCancel: {
      background: theme.palette.errorColor?.main,
      color: theme.palette.textColorSecondary?.main,
      paddingTop: 5,
      paddingBottom: 5,
      margin: 10,
      width: "10%",
      display: "flex",
      flexDirection: "row",
      alignContent: "center",
      "&:hover": {
        background: theme.palette.errorColor?.main,
        color: theme.palette.textColorSecondary?.main,
      },
      [theme.breakpoints.down("md")]: {
        width: "100%",
        flexDirection: "column",
        alignContent: "center",
      },
    },
  })
);

let componentRefs = [React.createRef(), React.createRef(), React.createRef()];
function getSteps() {
  return ["", "", ""];
}
export default function StripePaymentsForm(props) {
  const [stripeAccountModel, setStripeAccountModel] = useState(
    new StripeAccountModel()
  );
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const steps = getSteps();
  const [renderButtons, setRenderButtons] = useState(true);
  const [errors, setErrors] = useState(null);
  const classes = useStyles();
  const company = cachedData.get("company");
  const onlinePayments = company.onlinePayments;

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <StripePersonalDates
            ref={componentRefs[0]}
            stripeAccountModel={stripeAccountModel}
          />
        );
      case 1:
        return (
          <StripePersonalAddress
            ref={componentRefs[1]}
            stripeAccountModel={stripeAccountModel}
          />
        );

      case 2:
        return (
          <StripeUploadPhoto
            ref={componentRefs[2]}
            stripeAccountModel={stripeAccountModel}
          />
        );
      default:
        return;
    }
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleSave = async () => {
    if (
      stripeAccountModel.verifyAddress === "" ||
      stripeAccountModel.identityDoc === ""
    ) {
      setErrors(lang.get("fieldRequired"));
      return;
    }
    const formData = new FormData();
    formData.append("lastName", stripeAccountModel.lastName);
    formData.append("firstName", stripeAccountModel.firstName);
    formData.append("personalId", stripeAccountModel.personalId);
    formData.append("phoneNumber", stripeAccountModel.phoneNumber);
    formData.append("dateOfBirth", stripeAccountModel.dateOfBirth);
    formData.append("country", stripeAccountModel.country);
    formData.append("address", stripeAccountModel.address);
    formData.append("postalCode", stripeAccountModel.postalCode);
    formData.append("city", stripeAccountModel.city);
    formData.append("businessWebsite", stripeAccountModel.businessWebsite);
    formData.append("industry", stripeAccountModel.industry);
    formData.append("identityDoc", stripeAccountModel.identityDoc);
    formData.append("verifyAddress", stripeAccountModel.verifyAddress);
    props.setLoading(true);
    post(UrlEnum.onlinePayments, formData).then((response) => {
    props.setLoading(false);
      if (response.errors)
      {
        props.showSmallMessage(lang.get("error"), StatusEnum.ERROR);
      }
      else
      props.showSmallMessage(lang.get("successfullyCreated"));
    });
    props.handleIsModalOpen();
  };
  const hanbleDisable = async ()=>{
    post(UrlEnum.onlinePayments).then((response) => {
      if (response.errors) 
      props.showSmallMessage(lang.get("error"), StatusEnum.ERROR);
      else
      props.showSmallMessage(lang.get("successfullyDisabled"));

    });
    props.handleIsModalOpen();
  }

  const handleNext = async () => {
    if (errors) setErrors("");
    if (!componentRefs[activeStep].current.validate) {
      return;
    }
    let valid = await componentRefs[activeStep].current.validate();
    if (!valid) return;

    if (componentRefs[0].current) {
      let currentDate = new Date(moment().format(Config.momentUSDateFormat));
      let dateOfBirth = new Date(
        componentRefs[0].current.state.model.dateOfBirth
      );
      if (currentDate.getFullYear() - dateOfBirth.getFullYear() < 13) {
        setErrors(lang.get("atLeast13years"));
        return;
      } else if (currentDate.getFullYear() - dateOfBirth.getFullYear() === 13) {
        if (currentDate.getMonth() - dateOfBirth.getMonth() < 0) {
          setErrors(lang.get("atLeast13years"));
          return;
        } else if (currentDate.getMonth() - dateOfBirth.getMonth() === 0)
          if (currentDate.getDate() - dateOfBirth.getDate() < 0) {
            setErrors(lang.get("atLeast13years"));
            return;
          }
      }
    }
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };
  const handleBack = () => {
    if (errors) setErrors("");
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const paymentsButtons = renderButtons ? (
    <Grid container direction="row" justify="center" className={classes.grid}>
      <Button
        disabled={activeStep === 0}
        variant="contained"
        onClick={handleBack}
        className={classes.button}
      >
        {lang.get("back")}
      </Button>
      {activeStep === steps.length - 1 ? (
        <Button
          variant="contained"
          onClick={handleSave}
          className={classes.button}
        >
          {lang.get("save")}
        </Button>
      ) : (
        <Button
          variant="contained"
          disabled={activeStep === steps.length}
          onClick={handleNext}
          className={classes.button}
        >
          {lang.get("next")}
        </Button>
      )}
    </Grid>
  ) : (
    ""
  );
  return (
    <div>
      <Dialog open={props.isModalOpen} onClose={props.handleIsModalOpen}>
        <DialogContent className={classes.modalContent}>
          <div
            style={{
              position: "relative",
              padding: 5,
              // borderBottom: "1px solid #ccc",
            }}
          >
            <Typography
              variant="h5"
              gutterBottom
              style={{ textAlign: "center", margin: "5px" }}
            >
              {onlinePayments
                ?""
                : lang.get("enableOnlinePayments")}
            </Typography>
            <IconButton
              style={{ position: "absolute", right: 0, top: 0 }}
              color="inherit"
              id="addNew"
              onClick={props.handleIsModalOpen}
            >
              <CloseIcon />
            </IconButton>
          </div>
          {onlinePayments ? (
            <>
              <p style={{fontSize: "0.75rem", textAlign:"center", marginTop:"10px"}}> {lang.get("disableAccount")}</p>
            <Grid
              container
              direction="row"
              justify="center"
              style={{marginTop:"15px"}}
              className={classes.grid}
            >
              <Button className={classes.button} onClick={hanbleDisable}>
                {lang.get("yes")}
              </Button>
              <Button
                className={classes.buttonCancel}
                onClick={props.handleIsModalOpen}
              >
                {lang.get("cancel")}
              </Button>
            </Grid>
            </>
          ) : (
            <>
              <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};
                  return (
                    <Step key={label + index} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
              {getStepContent(activeStep)}
              {errors ? <Alert severity="error">{errors}</Alert> : ""}
              <DialogActions>{paymentsButtons}</DialogActions>
            </>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
