import React from "react";
import Lang from "../../lang";
const lang = Lang.getInstance();

type LiabilityFooter = {
  company: any;
};

export default function LiabilityFooter(props: LiabilityFooter) {
  return (
    <div
      id="footer"
    >
      <div>
        {props.company.web ? (
          <span>
            <strong>{lang.get("webPage")}</strong>: {props.company.web}
          </span>
        ) : (
          ""
        )}
        {props.company.mobile ? (
          <span>
            <strong>{lang.get("mobile")}</strong>:{" "}
            {props.company.mobile}
          </span>
        ) : (
          ""
        )}
      </div>
      <div>
        {props.company.bankAccount ? (
          <span>
            <strong>{lang.get("bankAccount")}</strong>:{" "}
            {props.company.bankAccount}
          </span>
        ) : (
          ""
        )}
        {props.company.bankBic ? (
          <span>
            <strong>{lang.get("bankBic")}</strong>:{" "}
            {props.company.bankBic}
          </span>
        ) : (
          ""
        )}
      </div>
      <div>
        {props.company.bankAccountSecondary ? (
          <span>
            <strong>{lang.get("bankAccount")}</strong>:{" "}
            <span>{props.company.bankAccountSecondary}</span>
          </span>
        ) : (
          ""
        )}
        {props.company.bankBicSecondary ? (
          <span>
            <strong>{lang.get("bankBic")}</strong>:{" "}
            {props.company.bankBicSecondary}
          </span>
        ) : (
          ""
        )}
      </div>
      <div>
        <span>
          {lang.get("generatedWith")}:{" "}
          <a
            href="https://cronoptimal.be"
            style={{
              color: "#438ed9"
            }}
          >
            www.cronoptimal.be
          </a>
        </span>
        
      </div>
    </div>
  );
}
