import React, { Fragment } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  IconButton,
  TablePagination,
  TextField,
  Checkbox,
  Button,
} from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import PropTypes from "prop-types";
import {
  get,
  httpDelete,
  renderFullPage,
  uriEncodeObj,
  UrlEnum,
} from "../Utils/Utils";
import Lang from "../lang";
import EditIcon from "@material-ui/icons/Edit";
import SearchIcon from "@material-ui/icons/Search";
import DeleteIcon from "@material-ui/icons/Delete";
import ConfirmDialog from "./ConfirmDialog";
import MessageDialog from "./MessageDialog";
import { makeStyles, ServerStyleSheets } from "@material-ui/core/styles";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import PreviewPDF from "../DocumentComponents/PreviewPDF";
import ReactDOMServer from "react-dom/server";
import theme from "../Theme/Theme";
let styles = {
  root: {
    marginTop: 200,
  },
  td: {
    whiteSpace: "normal",
    // wordBreak: "break-all",
  },
  container: {
    maxHeight: "100%",
  },
  tableOptions: {
    minWidth: 120,
  },
  filterLoadingWrapper: {
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1000,
  },
  filterLoading: {
    width: 20,
    height: 20,
    margin: 20,
  },
  header: {
    display: "flex",
  },
  customHeaderContent: {
    flexGrow: 1,
    minHeight: 50,
    paddingLeft: 10,
  },
  optionBtn: {
    padding: 8,
  },
  hideEdit: {
    display: "none",
    padding: 8,
  },
  "@media (max-width: 768px)": {
    header: {
      display: "block",
    },
    customHeaderContent: {
      height: 50,
      // marginTop: -20,
    },
  },
};

class TableComponent extends React.PureComponent {
  sheets = new ServerStyleSheets();
  /**
   * constructor
   * @param {Object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      models: this.props.models || null,
      total_count: 0,
      loading: !this.props.models,
      filterLoading: false,
      errors: null,
      pagination: {
        page: 0,
        per_page: 25,
        sort: this.props.sort,
        q: "",
        order: this.props.order || "asc",
      },
      openDeleteConfirm: false,
      openMessage: null,
      searchFocus: false,
      html: null,
      prevEdited:null
    };
    this.lang = Lang.getInstance();

    this.searchEnabled =
      this.props.search !== undefined ? this.props.search : true;
    this.paginationEnabled =
      this.props.pagination !== undefined ? this.props.pagination : true;
    styles.container = this.props.maxHeight ? this.props.maxHeight : "100%";
  }

  static addOrEditModel(models, editedModel) {
    if (editedModel.id <= 0) return models;

    const index = models.findIndex((el, index) => el.id === editedModel.id);
    if (index !== null && index !== undefined && index >= 0) {
      // edit
      models.splice(index, 1, { ...models[index], ...editedModel });
    } else {
      // add
      models.unshift(editedModel);
    }
    return models;
  }

  /**
   * props have changed
   * @param {Object} nextProps
   * @param {Object} prevState
   */
   static getDerivedStateFromProps(nextProps, prevState) {
    if ((nextProps.editedModels && nextProps.editedModels.length > 0)
      && nextProps.editedModels !== prevState.prevEdited
    ) {
      if (prevState && prevState.models)
        if (nextProps.models) {
          let models = nextProps.models;
          for (let editedModel of nextProps.editedModels) {
            if (editedModel) {
              models = TableComponent.addOrEditModel(models, editedModel);
            }
          }
          return { models: models, prevEdited:nextProps.editedModels };
        }
        else{
          let models = prevState.models;
          for (let editedModel of nextProps.editedModels) {
            if (editedModel) {
              models = TableComponent.addOrEditModel(models, editedModel);
            }
          }
          return { models: models, prevEdited:nextProps.editedModels  };
        }
    }

    if (nextProps.models) {
      return { models: nextProps.models };
    }

    if (
      nextProps.searchText !== prevState.pagination.q &&
      !prevState.searchFocus
    ) {
      let pagination = prevState.pagination;
      pagination.page = 0;
      if (nextProps.searchText) {
        pagination.q = nextProps.searchText;
      }
      return {
        pagination: pagination,
      };
    }

    if (
      nextProps.sort !== prevState.pagination.sort ||
      nextProps.order !== prevState.pagination.order
    ) {
      let pagination = prevState.pagination;
      if (nextProps.sort) {
        pagination.sort = nextProps.sort;
        pagination.order = nextProps.order || "asc";
      }
      return {
        pagination: pagination,
      };
    }
    return null;
  }

  /**
   *
   */
  componentDidMount() {
    if (!this.state.models) {
      this.setState({ loading: true, filterLoading: true },()=>{
        this.fetchData();
      });
    }
  }

  /**
   * lifetime event
   */
  componentDidUpdate(prevProps) {
    if (prevProps.url !== this.props.url) {
      // when url changes reset pagination
      this.setState(
        { pagination: { ...this.state.pagination, page: 0, loading:true } },
        () => {
          this.fetchData();
        }
      );
    }
    if (
      prevProps.searchText !== this.props.searchText ||
      prevProps.sort !== this.props.sort ||
      prevProps.order !== this.props.order
    ) {
      this.setState({ loading: true},()=>{
        this.fetchData();
      });
    }
  }

  /**
   * get the data from the server
   */
  async fetchData() {
    try {
      let pagination = { ...this.state.pagination };
      pagination.page++;
      if(this.props.isDashboard)
      {
        pagination={
          page: 0,
          per_page: 10,
          sort: this.props.sort,
          q: "",
          order: this.props.order || "asc",
        }
      }
      let url = this.props.url;
      if (this.paginationEnabled) {
        const queryString = uriEncodeObj(pagination);
        url = this.props.url + "?" + queryString;
      }
      const data = await get(url);
      if (this.props.onFetchResult) {
        this.props.onFetchResult(data, this.state.pagination);
      }
      this.setState({
        models: data.items ? data.items : data,
        total_count: Number.isInteger(data.total_count)
          ? data.total_count
          : data.length,
        loading: false,
        filterLoading: false,
      });
    } catch (e) {
      this.setState({ errors: this.lang.get("error") });
    }
  }

  /**
   * get items for next page
   */
  handleChangePage(event, newPage) {
    let pagination = this.state.pagination;
    pagination.page = newPage;
    this.setState({ pagination: pagination, filterLoading: true });
    this.fetchData();
  }

  /**
   * change items per page and re-fetch data
   */
  handleChangeRowsPerPage(event) {
    let pagination = this.state.pagination;
    pagination.per_page = parseInt(event.target.value, 10);
    this.setState({ pagination: pagination, filterLoading: true });
    this.fetchData();
  }

  /**
   * edit item
   */
  handleEditClick(model) {
    if (this.props.history) {
      const path = this.props.routeMatch.path.substring(
        0,
        this.props.routeMatch.path.indexOf(":")
      );
      this.props.history.push(path + model.id);
    }
    if (this.props.editCallback) {
      this.props.editCallback(model);
    }
  }

  /**
   * delete item
   */
  handleDeleteClick(model) {
    //show confirm box
    this.setState({ openDeleteConfirm: true });
    this.deleteModel = model;
  }

  /**
   * if user confirms, delete item
   * @param {boolean} confirm
   */
  onConfirmResult(confirm) {
    this.setState({ openDeleteConfirm: false });
    if (!confirm || !this.deleteModel) return;

    let url = this.props.url + "/" + this.deleteModel.id;
    // if other filtering data exists after base url, ignore it
    const isWithApi = this.props.url.indexOf("/api/") >= 0;
    const indexOfSlash = this.props.url.indexOf("/", isWithApi ? 5 : 1);
    if (indexOfSlash > 0) {
      url =
        this.props.url.substring(0, indexOfSlash) + "/" + this.deleteModel.id;
    }

    if (this.props.baseUrl) {
      url = this.props.baseUrl + "/" + this.deleteModel.id;
    }

    httpDelete(url).then((response) => {
      if (response.errors) {
        // show alert
        let message =
          response.errors instanceof Array && response.errors.length > 0
            ? response.errors.join("<br/>")
            : response.errors;
        this.setState({ openMessage: message });
        return;
      }
      let index = this.state.models.indexOf(this.deleteModel);
      if(index<0)
        index = this.state.models.findIndex((element)=> element.id === this.deleteModel.id)
      let models = this.state.models.slice();
      models.splice(index, 1);
      this.setState({ models: models });
      if (this.props.deleteCallback) {
        this.props.deleteCallback(this.deleteModel);
      }
    });
  }

  /**
   * change the value for the search field
   */
  handleSearchChange(event) {
    let pagination = { ...this.state.pagination };
    pagination.q = event.target.value;
    this.setState({ pagination: pagination });
  }

  handleSearch(event) {
    event.preventDefault();
    this.setState({ filterLoading: true });
    this.fetchData();
    return false;
  }

  onSelect(model) {
    const newModels = this.state.models.slice();
    const sel = newModels.find((m) => m.id === model.id);
    if (sel) {
      sel.selected = sel.selected && sel.selected === true ? false : true;
      this.setState({ models: newModels }, () => {
        this.props.onSelect(this.getSelected());
      });
    }
  }

  /**
   *
   */
  selectAll(e) {
    const newModels = this.state.models.slice();
    for (let m of newModels) {
      m.selected = e.target.checked;
    }
    this.setState({ models: newModels }, () => {
      this.props.onSelect(this.getSelected());
    });
  }

  /**
   *
   */
  getSelected() {
    return this.state.models.filter((el) => el.selected);
  }

  /**
   *
   */
  renderTable(classes, withoutOptions) {
    return (
      <TableContainer className={classes.container}>
        <Table size="small" stickyHeader className={classes.table} >
          <TableHead style={{ display: "table-row-group" }}>
            <TableRow>
              {this.props.select ? (
                <TableCell key="select">
                  <Checkbox
                    style={{color:theme.palette.header?.main }}
                    onChange={this.selectAll.bind(this)}
                  />
                </TableCell>
              ) : null}
              {this.props.columns.map((col) => (
                <TableCell key={col.name}>{col.label}</TableCell>
              ))}

              {this.props.options === null ||
              this.props.options === false ||
              withoutOptions === true ? null : (
                <TableCell className={classes.tableOptions}>
                  {" "}
                  {this.lang.get("options")}
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.models
              ? this.state.models.map((model) => {
                  let rowClass = this.props.rowClassCallback
                    ? this.props.rowClassCallback(model)
                    : "";
                  return (
                    <Fragment key={"row_fragment" + model.id}>
                      <TableRow
                        style={{ pageBreakInside: "avoid" }}
                        key={"row" + model.id}
                        className={rowClass}
                      >
                        {this.props.select ? (
                          <TableCell key={"select" + model.id} >
                            <Checkbox
                              style={{color:theme.palette.header?.main }}
                              checked={model.selected || false}
                              // style={{color:theme.palette.header?.main }}
                              onChange={() => this.onSelect(model)}
                            />
                          </TableCell>
                        ) : null}
                        {this.props.columns.map((col, index) => (
                          <TableCell
                            key={"cel" + model.id + col.name + index}
                            className={classes.td}
                          >
                            {col.customRender
                              ? col.customRender(model, this, this.state.models)
                              : col.children
                              ? col.children(model)
                              : this.props.parse &&
                                this.props.parse.hasOwnProperty(col.name)
                              ? this.props.parse[col.name](model[col.name])
                              : model[col.name]}
                          </TableCell>
                        ))}
                        {withoutOptions === true ? null : (
                          <TableCell >
                            {/** Options */}
                            {this.props.options === null ||
                            this.props.options === false ||
                            withoutOptions === true ? null : this.props
                                .options && this.props.options.customRender ? (
                              this.props.options.customRender(
                                model,
                                this,
                                this.state.models
                              )
                            ) : (
                              <>
                                <IconButton
                                  color="inherit"
                                  className={
                                    !this.props.options
                                      ? classes.optionBtn
                                      : this.props.options.edit
                                      ? classes.optionBtn
                                      : classes.hideEdit
                                  }
                                  onClick={() => {
                                    this.handleEditClick(model);
                                  }}
                                >
                                  <EditIcon />
                                </IconButton>
                                <IconButton
                                  color="inherit"
                                  className={classes.optionBtn}
                                  onClick={() => {
                                    this.handleDeleteClick(model);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </>
                            )}
                          </TableCell>
                        )}
                      </TableRow>
                      {this.props.expadableRow ? (
                        <TableRow key={"row_expanable" + model.id}>
                          <TableCell
                            colSpan={
                              this.props.options === null ||
                              this.props.options === false
                                ? this.props.columns.length
                                : this.props.columns.length + 1
                            }
                          >
                            {this.props.expadableRow(model)}
                          </TableCell>
                        </TableRow>
                      ) : null}
                    </Fragment>
                  );
                })
              : null}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  renderExport(classes) {
    return (
      <>
        <Button onClick={() => this.getHtml(classes)}>
          <SaveAltIcon /> {this.lang.get("export")}
        </Button>
        <PreviewPDF
          open={this.state.html !== null}
          onClose={() => this.setState({ html: null })}
          htmlContent={this.state.html}
          footerHtml={""}
          setSmallMessage={this.props.showSmallMessage}
          emailData={{ to: "", subject: "", message: "" }}
          url={UrlEnum.pdf.replace("{type}", "html").replace("{id}", "0")}
        />
      </>
    );
  }

  getHtml(classes) {
    const elements = this.renderTable(classes, true);
    const htmlString = ReactDOMServer.renderToString(
      this.sheets.collect(elements)
    );
    const css = this.sheets.toString();
    this.setState({ html: renderFullPage(htmlString, css) });
  }

  /**
   * render content
   */
  render() {
    const classes = this.props.classes;

    const filterLoading = this.state.filterLoading ? (
      <div className={classes.filterLoadingWrapper} align="right">
        <CircularProgress color="secondary" className={classes.filterLoading} />
      </div>
    ) : (
      ""
    );
    const view = this.state.loading ? (
      <div className={classes.root} align="center">
        <CircularProgress color="secondary" />
      </div>
    ) : (
      <Paper style={{ position: "relative" ,overflowX: "auto"}}>
        {filterLoading}
        <div className={classes.header}>
          {this.searchEnabled ? (
            <div style={{ padding: 10 }}>
              <form onSubmit={this.handleSearch.bind(this)}>
                <TextField
                  name="search"
                  onChange={this.handleSearchChange.bind(this)}
                  placeholder={
                    this.props.searchTextPlaceHolder
                      ? this.props.searchTextPlaceHolder
                      : this.lang.get("search")
                  }
                  value={this.state.pagination.q}
                  onFocus={() => this.setState({ searchFocus: true })}
                  onBlur={() => this.setState({ searchFocus: false })}
                />
                <IconButton
                  type="submit"
                  style={{color:theme.palette.header?.main,marginLeft: -35, marginTop: -5 }}
                >
                  <SearchIcon />
                </IconButton>
              </form>
            </div>
          ) : null}
          <div className={classes.customHeaderContent}>
            {this.props.customHeaderContent
              ? this.props.customHeaderContent
              : null}
          </div>
          <div className={classes.bulkOptions}></div>
        </div>

        {this.renderTable(classes)}

        {this.renderExport(classes)}

        {this.paginationEnabled ? (
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={this.state.total_count}
            rowsPerPage={this.props.isDashboard ? 10 : this.state.pagination.per_page}
            page={this.state.pagination.page}
            onChangePage={this.handleChangePage.bind(this)}
            onChangeRowsPerPage={this.handleChangeRowsPerPage.bind(this)}
          />
        ) : (
          ""
        )}
        <ConfirmDialog
          open={this.state.openDeleteConfirm}
          text={this.lang.get("deleteItem")}
          onResult={this.onConfirmResult.bind(this)}
        />
        <MessageDialog
          open={this.state.openMessage ? true : false}
          text={this.state.openMessage || ""}
          onResult={() => this.setState({ openMessage: null })}
        />
      </Paper>
    );
    return view;
  }
}

TableComponent.propTypes = {
  models: PropTypes.array,
  editedModels: PropTypes.array,
  url: PropTypes.string,
  columns: PropTypes.array.isRequired,
  parse: PropTypes.object,
  maxHeight: PropTypes.number,
  editCallback: PropTypes.func,
  deleteCallback: PropTypes.func,
  history: PropTypes.object,
  routeMatch: PropTypes.object,
  search: PropTypes.bool,
  searchText: PropTypes.string,
  pagination: PropTypes.bool,
  rowClassCallback: PropTypes.func,
  customHeaderContent: PropTypes.object,
  onFetchResult: PropTypes.func,
  options: PropTypes.object,
  baseUrl: PropTypes.string,
  select: PropTypes.bool,
  onSelect: PropTypes.func,
  sort: PropTypes.string,
  order: PropTypes.string,
  expadableRow: PropTypes.func,
  searchTextPlaceHolder: PropTypes.string,
  styles: PropTypes.object,
  isDashboard:  PropTypes.bool,
};

const HocTable = (props) => {
  const extendedStyles = { ...styles, ...props.styles };
  const classes = makeStyles(() => extendedStyles)();
  return <TableComponent {...props} classes={classes} />;
};

export default HocTable;
