import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	DialogContentText,
} from '@material-ui/core';
import ExcelPage from './EditExcelComponent';
import Lang from '../../lang';
import PropTypes from 'prop-types'

const lang = Lang.getInstance();

class ExcelImport extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showAlert: false,
			alertMessage: "",
			errors: null,
		};
	}


	handleChangeAlert() {
		this.setState({ showAlert: !this.state.showAlert });
	}

	render() {
		return (
			<>
				<div >
					<div>
						<ExcelPage 
						columns={this.props.columns}
						getTableDataFunction={this.props.getData}></ExcelPage>
					</div>
				</div>
				<Dialog
					open={this.state.showAlert}
					onClose={this.props.onChange}
					aria-labelledby="confirm"
					aria-describedby="confirm dialog"
				>
					<DialogTitle id="alert-dialog-title">!!!</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							{this.state.alertMessage}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleChangeAlert.bind(this)} id="ok" color="primary">
							{lang.get("import")}
						</Button>
					</DialogActions>
				</Dialog>
			</>
		);
	}
}


ExcelImport.propTypes = {
	columns: PropTypes.object.isRequired,
	getData: PropTypes.func.isRequired
}

export default ExcelImport;

