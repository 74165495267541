import React, { Fragment } from "react";
import {
  EditorState,
  Editor,
  RichUtils,
  convertToRaw,
  convertFromRaw,
  AtomicBlockUtils,
  Modifier,
} from "draft-js";
import "draft-js/dist/Draft.css";
import { Button, Tooltip, withStyles } from "@material-ui/core";
import FormatBoldIcon from "@material-ui/icons/FormatBold";
import FormatItalicIcon from "@material-ui/icons/FormatItalic";
import FormatUnderlinedIcon from "@material-ui/icons/FormatUnderlined";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import PropTypes from "prop-types";
import Lang from "../lang";
import TagsTextEditor from "../DocumentTemplates/TagsTextEditor";

const styles = () => ({
  editorWrapper: {
    border: "1px solid #ccc",
    padding: 5,
    height: 150,
    overflow: "scroll",
  },
  toolbar: {
    boxShadow: "0 0 3px #555",
    minHeight: 48,
  },
  blockTypes: {
    position: "relative",
    top: -4,
  },
});

const lang = Lang.getInstance();

const ENTITY_TYPE = {
  HORIZONTAL_RULE: "hr",
};

class TextEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editorState: props.initialState
        ? EditorState.createWithContent(convertFromRaw(props.initialState))
        : EditorState.createEmpty(),
      formats: [],
      blockTypes: [],
      initialState: props.initialState,
      docType: props.docType,
    };
    this.onChange = (editorState) => {
      this.setState({ editorState });
    };
    this.classes = props.classes;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // Rarely Used
    if (nextProps.initialState !== prevState.initialState) {
      return {
        editorState: nextProps.initialState
          ? EditorState.createWithContent(
              convertFromRaw(nextProps.initialState)
            )
          : EditorState.createEmpty(),
        formats: [],
        blockTypes: [],
        initialState: nextProps.initialState,
      };
    }
    return null;
  }

  handleKeyCommand(command, editorState) {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChange(newState);
      return "handled";
    }
    return "not-handled";
  }

  handleFormat(event, newFormats) {
    let format;
    if (newFormats.length > this.state.formats.length) {
      // add style
      format = newFormats[newFormats.length - 1];
    } else {
      let nf = this.state.formats.slice();
      // remove style
      for (let f of newFormats) {
        for (let i = 0; i < nf.length; i++) {
          if (f === nf[i]) {
            nf.splice(i, 1);
            break;
          }
        }
      }
      format = nf[0];
    }
    this.setState({ formats: newFormats });
    this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, format));
  }

  handleBlockType(event, newBlockTypes) {
    let blockType;
    if (newBlockTypes.length > this.state.formats.length) {
      // add style
      blockType = newBlockTypes[newBlockTypes.length - 1];
    } else {
      let bt = this.state.blockTypes.slice();
      // remove style
      for (let f of newBlockTypes) {
        for (let i = 0; i < bt.length; i++) {
          if (f === bt[i]) {
            bt.splice(i, 1);
            break;
          }
        }
      }
      blockType = bt[0];
    }
    this.setState({ blockTypes: [blockType] });
    this.onChange(RichUtils.toggleBlockType(this.state.editorState, blockType));
  }

  addHorizontalRule(event) {
    const { editorState } = this.state;

    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      ENTITY_TYPE.HORIZONTAL_RULE,
      "IMMUTABLE",
      {}
    );

    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });
    this.setState({
      editorState: AtomicBlockUtils.insertAtomicBlock(
        newEditorState,
        entityKey,
        " "
      ),
    });
  }

  blockRenderer(contentBlock) {
    const type = contentBlock.getType();
    if (type === "atomic") {
      const { editorState } = this.state;
      const contentState = editorState.getCurrentContent();
      const entityKey = contentBlock.getEntityAt(0);
      const entity = contentState.getEntity(entityKey);
      if (entity && entity.type === ENTITY_TYPE.HORIZONTAL_RULE) {
        return {
          component: DividerComponent,
          editable: false,
        };
      }
    }
  }

  getContent() {
    return convertToRaw(this.state.editorState.getCurrentContent());
  }

  addTags(tags) {
    if (!tags) return;
    const text = " ${" + tags + "} ";

    const editorState = this.state.editorState;
    const contentState = editorState.getCurrentContent();

    let newContentState = contentState.createEntity(
      "unstyled",
      "IMMUTABLE",
      text
    );

    const entityKey = contentState.getLastCreatedEntityKey();
    const selectionState = this.state.editorState.getSelection();
    newContentState = Modifier.insertText(
      newContentState,
      selectionState,
      text,
      "",
      entityKey
    );
    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      "apply-entity"
    );
    // this.setState({editorState: AtomicBlockUtils.insertAtomicBlock(
    //     newEditorState,
    //     entityKey,text)});
    this.setState({ editorState: newEditorState });
  }
  render() {
    return (
      <Fragment>
        <div>
          <ToggleButtonGroup
            value={this.state.formats}
            onChange={this.handleFormat.bind(this)}
          >
            <ToggleButton value="BOLD">
              <FormatBoldIcon />
            </ToggleButton>
            <ToggleButton value="ITALIC">
              <FormatItalicIcon />
            </ToggleButton>
            <ToggleButton value="UNDERLINE">
              <FormatUnderlinedIcon />
            </ToggleButton>
          </ToggleButtonGroup>
          &nbsp;&nbsp;
          <ToggleButtonGroup
            className={this.classes.blockTypes}
            value={this.state.blockTypes}
            onChange={this.handleBlockType.bind(this)}
          >
            <ToggleButton value="header-one">H1</ToggleButton>
            <ToggleButton value="header-two">H2</ToggleButton>
            <ToggleButton value="header-three">H3</ToggleButton>
            <ToggleButton value="unordered-list-item">
              <FormatListBulletedIcon />
            </ToggleButton>
            <ToggleButton value="ordered-list-item">
              <FormatListNumberedIcon />
            </ToggleButton>
          </ToggleButtonGroup>
          <Tooltip title={lang.get("divider")}>
            <Button
              style={{
                padding: "10px 0px 10px 0px",
                marginTop: -14,
                marginLeft: 7,
                minWidth: 50,
              }}
              name="hr"
              variant="outlined"
              onClick={this.addHorizontalRule.bind(this)}
            >
              ---
            </Button>
          </Tooltip>
          <TagsTextEditor
            docType={this.state.docType}
            addTags={this.addTags.bind(this)}
          />
        </div>
        <div
          className={this.classes.editorWrapper}
          style={{ height: this.props.height || "150px", fontSize: this.props.fontSize || "" }}
        >
          <Editor
            editorState={this.state.editorState}
            onChange={this.onChange}
            handleKeyCommand={this.handleKeyCommand.bind(this)}
            blockRendererFn={this.blockRenderer.bind(this)}
          />
        </div>
      </Fragment>
    );
  }
}

const DividerComponent = (props) => {
  return <hr />;
};

TextEditor.protoTypes = {
  initialState: PropTypes.object,
  height: PropTypes.number,
};
export default withStyles(styles, { withTheme: true })(TextEditor);
