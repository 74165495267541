import React, { Fragment } from 'react';
import Lang from '../../lang';
import { withStyles, CircularProgress, Modal, Theme, createStyles} from '@material-ui/core';
import { ResolutionBreakPoints, UrlEnum, LocalUrlEnum, get, Statuses, httpDelete, BlockedStatus, StatusEnum } from '../../Utils/Utils';
import moment from 'moment';
import Config from '../../Utils/Config';
import ProjectList from '../../DocumentComponents/ProjectList';

import Alert from '@material-ui/lab/Alert';
import DocumentsLayout from '../../Components/DocumentsLayout';
import { Project } from '../../Models/Models';
import ConfirmDialog from '../../Components/ConfirmDialog';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import NoDataFound from '../../Components/NoDataFound';

const docWidth = document.body.offsetWidth;

const styles = (theme: Theme) => createStyles({
    loading: {
        display: "block",
        margin: "0 auto",
        marginTop: 50,
        outline: 0
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    totalsValue: {
        float: "right"
    },
    summary: {
        position: "absolute",
        bottom: 90,
        width: "88%"
    },
    summaryTitle: {
        background: theme.palette.header?.main,
        padding: 5,
        color: "#fff",
        borderRadius: 2
    },
    blocked: {
        backgroundColor: theme.palette.errorColor?.main,
        color: theme.palette.textColorSecondary?.main,
    },
    unblocked: {
        backgroundColor: theme.palette.successColor?.main,
        color: theme.palette.textColorSecondary?.main,
    },

});

type ProjectsProps = {
    changeLoadingState: Function
    type: string,
    classes: any,
    showSmallMessage: Function,
    history: any,
    match: any
};

type ProjectsState = {
    projects: Array<Project>;
    errors: string;
    loading: boolean;
    openDeleteConfirm: boolean,
    totalEstimatesCount: number;
    blockedStatus: string;
};

class Projects extends React.Component<ProjectsProps, ProjectsState> {
    crtDate = new Date();
    crtDateString = moment().format(Config.momentUSDateFormat);
    startDate = this.crtDate.getMonth() > 6 ?this.crtDate.getUTCFullYear() + "-01-01": this.crtDate.getUTCFullYear()-1 + "-01-01";
    endDate = this.crtDateString;
    search = "null";
    page = 1;
    lang = Lang.getInstance();
    perPage: number;
    isMobile: boolean;
    deleteModel: Project | null;

    /**
     * constructor
     * @param {Object} props 
     */
    constructor(props: ProjectsProps) {
        super(props);
        this.isMobile = docWidth < ResolutionBreakPoints.md;
        this.perPage = 50;
        this.state = {
            projects: [],
            errors: "",
            loading: true,
            openDeleteConfirm: false,
            totalEstimatesCount: 0,
            blockedStatus: this.props.match.params.blockedStatus || BlockedStatus.unblocked
        };

        this.deleteModel = null;
        if (this.props.match.params.startDate) {
            this.startDate = this.props.match.params.startDate;
            this.endDate = this.props.match.params.endDate;
            this.page = parseInt(this.props.match.params.page) || 1;
            this.perPage = parseInt(this.props.match.params.perPage) || 50;
            this.search = this.props.match.params.q || "null";
        }
    }

    componentDidMount() {
        this.getProjects();
    }

    /**
     * get Estimates from server
     */
    async getProjects(type = "") {
        this.setState({ loading: true });

        const url = `${UrlEnum.projects}/${this.startDate}/${this.endDate}/null/${this.state.blockedStatus}?q=${this.search}&page=${this.page}&per_page=${this.perPage}`;
        const response = await get(url);
        if (response.errors) {
            this.setState({ errors: response.errors, loading: false });
        }

        this.setState({
            projects: response.items,
            loading: false,
            totalEstimatesCount: response.total_count
        });
    }


    async changeProjectState(project: Project) {
        let newProjects = this.state.projects.slice();
        if (project.status !== null) {
            let nextState = project.status;
            if (project.status.id === Statuses.Active.id) {
                nextState = Statuses.Inactive;
            } else {
                nextState = Statuses.Active;
            }

            const url = `${UrlEnum.projects}/changeState/${project.id}/${nextState.id}`;
            const response = await get(url);
            if (response.errors) {
                alert(response.errors);
                return;
            }

            let crtProject = newProjects.find((i) => i.id === project.id);
            if (crtProject) {
                crtProject.status = nextState;
                this.setState({ projects: newProjects });
            }
        }
    }

    async onConfirmDeleteResult(confirmResponse: boolean) {
        if (confirmResponse && this.deleteModel !== null) {
            const url = `${UrlEnum.projects}/${this.deleteModel.id}`;
            const response = await httpDelete(url);
            if (response.errors) {
                let message = response.errors instanceof Array && response.errors.length > 0 ?
                    response.errors.join("<br/>") :
                    response.errors;
                this.props.showSmallMessage(message, StatusEnum.ERROR);
                return;
            }
            let ne = this.state.projects;
            const index = ne.indexOf(this.deleteModel);
            ne.splice(index, 1);
            this.setState({ "openDeleteConfirm": false, "projects": ne });
            return;
        }
        this.setState({ "openDeleteConfirm": false });
    }

    /**
     * 
     * @param event 
     * @param newValue 
     */
    handleBlockedStatus(
        event: React.MouseEvent<HTMLElement>,
        newValue: any) {
        this.setState({ blockedStatus: newValue }, () => {
            this.changeUrl(this.startDate, this.endDate, this.search, this.page, this.perPage);
        });
    }

    /**
     * 
     * @param startDate 
     * @param endDate 
     * @param search 
     * @param page 
     * @param perPage 
     */
    changeUrl(startDate: string, endDate: string, search: string, page: number, perPage = 50) {
        let displayUrlBase = LocalUrlEnum.projects;
        search = search === "" ? "null" : search;
        const displayUrl = `${displayUrlBase}/${startDate}/${endDate}/${page}/${perPage}/${search}/${this.state.blockedStatus}`;
        this.props.history.push(displayUrl);
    }

    /**
     * render method
     */
    render() {
        const classes = this.props.classes;
        if (!this.state.loading) {
            if (this.state.errors) {
                return (
                    <Alert severity="error">
                        {this.state.errors}
                    </Alert>
                );
            }
        }
        let newDocLink = LocalUrlEnum.projects;

        return (
            <DocumentsLayout
                onChange={(startDate, endDate, search, page) => {
                    this.changeUrl(startDate, endDate, search, page);
                }}
                startDate={this.startDate}
                endDate={this.endDate}
                search={this.search === "null" ? "" : this.search}
                page={this.page}
                perPage={this.perPage}
                onChangeRecurrent={() => { }}
                totalDocCount={this.state.totalEstimatesCount}
                isMobile={this.isMobile}
                title={this.lang.get(this.props.type + "s")}
                newDocLink={newDocLink + "/0"}
                type={this.props.type}
                match={this.props.match}
                drawerChildren={
                    <ToggleButtonGroup
                        value={this.state.blockedStatus}
                        exclusive
                        onChange={this.handleBlockedStatus.bind(this)}
                        aria-label="text alignment"
                        size="large"
                    >
                        <ToggleButton
                            value={BlockedStatus.unblocked}
                            aria-label="unblocked"
                            classes={{ root: classes.unblocked }}
                        >
                            {this.lang.get("unblocked")}
                        </ToggleButton>
                        <ToggleButton
                            value={BlockedStatus.blocked}
                            aria-label="blocked"
                            classes={{ root: classes.blocked }}
                        >
                            {this.lang.get("blocked")}
                        </ToggleButton>
                    </ToggleButtonGroup>
                }
            >
                <Fragment>
                    {this.state.projects.length > 0 ?
                        <ProjectList
                            changeProjectState={this.changeProjectState.bind(this)}
                            projects={this.state.projects}
                            isMobile={this.isMobile}
                            onDelete={(prj:Project)=>{
                                this.deleteModel = prj;
                                this.setState({openDeleteConfirm:true})
                            }}
                        />
                        :
                        <NoDataFound />
                    }

                    <Modal
                        open={this.state.loading}
                        className={classes.modal}
                    >
                        <CircularProgress color="secondary" className={classes.loading} />
                    </Modal>
                    <ConfirmDialog open={this.state.openDeleteConfirm} text={`${this.lang.get("deleteItem")}<br/> ${this.lang.get("deleteAssociated")}`} onResult={this.onConfirmDeleteResult.bind(this)} />
                </Fragment>
            </DocumentsLayout >
        );
    }
}

export default withStyles(styles)(Projects);