import React, { Component, Fragment } from "react";
import {
  withStyles,
  Theme,
  createStyles,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import Lang from "../lang";
import { Project, Address } from "../Models/Models";
import DocStatus from "../Components/DocStatus";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Link } from "react-router-dom";
import { LocalUrlEnum } from "../Utils/Utils";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";

const styles = createStyles((theme: Theme) => ({
  header: {
    backgroundColor: theme.palette.header?.main,
    padding: "15px 43px 15px 15px",
    color: "#fff",
    fontWeight: "bold",
    borderRadius: "5px 5px 0 0",
  },
  link: {
    color: theme.palette.textColor?.main,
  },
}));

type ProjectListProps = {
  projects: Array<Project>;
  isMobile: boolean;
  classes: any;
  changeProjectState: Function;
  onDelete: Function;
};

class ProjectList extends Component<ProjectListProps, any> {
  classes: any;
  lang: any;
  isMobile: any;

  constructor(props: ProjectListProps) {
    super(props);

    this.classes = this.props.classes;
    this.lang = Lang.getInstance();
    this.isMobile = props.isMobile;
  }

  renderHeaderCols() {
    return (
      <Grid container className={this.classes.header}>
        <Grid item md={1} xs={12}>
          {this.lang.get("number")}
        </Grid>
        <Grid item md={4} xs={12}>
          {this.lang.get("name")}
        </Grid>
        <Grid item md={4} xs={12}>
          {this.lang.get("siteAddress")}
        </Grid>
        <Grid item md={3} xs={12}>
          {this.lang.get("status")}
        </Grid>
      </Grid>
    );
  }

  /**
   *
   * @param p
   */
  renderCol(p: Project) {
    return (
      <Grid container>
        <Grid item md={1} xs={12} className={this.classes.link}>
          <Link to={LocalUrlEnum.projects + "/" + p.id}>{p.folderNo}</Link>
        </Grid>
        <Grid item md={4} xs={12}>
          <Link to={LocalUrlEnum.projects + "/" + p.id}>{p.client?.name}</Link>
        </Grid>
        <Grid item md={4} xs={12}>
          {Address.stringify(p.site_address)}
        </Grid>
        <Grid item md={3} xs={12}>
          <DocStatus
            size="small"
            fullWidth={true}
            status={p.status}
            onChangeStatus={(e) => {
              e.preventDefault();
              e.stopPropagation();
              this.props.changeProjectState(p);
              return false;
            }}
          />
        </Grid>
      </Grid>
    );
  }

  /**
   *
   * @param project
   */
  onDelete(project: Project): void {
    this.props.onDelete(project);
  }

  renderProjects() {
    return (
      <Fragment>
        {this.renderHeaderCols()}
        {this.props.projects.map((p: Project, index) => {
          const canDelete = index === 0;
          return (
            <Accordion key={p.id}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                {this.renderCol(p)}
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={1}>
                  <Grid item xs={canDelete ? 4 : 2} sm={4} md={1}>
                    <Tooltip title={this.lang.get("edit")} placement="bottom">
                      <IconButton
                        className={this.classes.buttons}
                        aria-label="delete"
                      >
                        <Link to={LocalUrlEnum.projects + "/" + p.id}>
                          <CreateIcon />
                        </Link>
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={canDelete ? 4 : 2} sm={4} md={1}>
                    {canDelete ? (
                      <Tooltip
                        title={this.lang.get("delete")}
                        placement="bottom"
                      >
                        <IconButton
                          className={this.classes.buttons}
                          aria-label="delete"
                          onClick={() => this.onDelete(p)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Fragment>
    );
  }

  render() {
    return <Fragment>{this.renderProjects()}</Fragment>;
  }
}

export default withStyles(styles)(ProjectList);
