import EstimatesNotUsed from "../Components/DashboardComponents/Charts/EstimatesNotUsed";
import OverviewEstimatesOrdersTickets from "../Components/DashboardComponents/Charts/OverviewEstimatesOrdersTickets";
import OverviewInvoicesCostsProfit from "../Components/DashboardComponents/Charts/OverviewInvoicesCostsProfit";
import OverviewVatCosts from "../Components/DashboardComponents/Charts/OverviewVatCosts";
import OverviewVatInvoices from "../Components/DashboardComponents/Charts/OverviewVatInvoices";
import DocumentsOverview from "../Components/DashboardComponents/Overview/DocumentsOverview";
import Profit from "../Components/DashboardComponents/Charts/Profit";

import overview_profit from "../Images/widgets/overview_profit.png";
import overviewImage from "../Images/widgets/overview.png";
import overviewInvoicesCostsImage from "../Images/widgets/overview_invoices_costs.png";
import overviewEstimatesImage from "../Images/widgets/overview_estimates_ots.png";
import overviewEstimatesNotUsedImage from "../Images/widgets/overview_estimates_not_used.png";
import overviewVatInvoicesImage from "../Images/widgets/overview_vat_invoices.png";
import overviewVatCostsImage from "../Images/widgets/overview_vat_costs.png";
import Lang from "../lang";
import { doesUserHaveAccessToOverviewChart } from "./Utils";

/**
 * 
 * @returns an array of objects that contains the property component, name and image.
 */
export function overviewOptionFilteredByRole() {
  const lang = Lang.getInstance();
  const allOverviewOptions = {
    DocumentsOverview: {
      component: <DocumentsOverview />,
      name: lang.get("overview"),
      image: overviewImage
    },
    OverviewInvoicesCostsProfit: {
      component: <OverviewInvoicesCostsProfit />,
      name: `${lang.get("overview")}: ${lang.get("invoices")}, ${lang.get(
        "costs"
      )}, ${lang.get("profit")}`,
      image: overviewInvoicesCostsImage
    },
    Profit: {
      component: <Profit />,
      name: `${lang.get("profit")}`,
      image: overview_profit
    },
    OverviewEstimatesOrdersTickets: {
      component: <OverviewEstimatesOrdersTickets />,
      name: `${lang.get("overview")}: ${lang.get("estimates")}, ${lang.get(
        "orderTickets"
      )}`,
      image: overviewEstimatesImage
    },
    EstimatesNotUsed: {
      component: <EstimatesNotUsed />,
      name: `${lang.get("estimates")}: ${lang.get("notUsed")}`,
      image: overviewEstimatesNotUsedImage
    },
    OverviewVatInvoices: {
      component: <OverviewVatInvoices />,
      name: `${lang.get("VAT")}: ${lang.get("invoices")}`,
      image: overviewVatInvoicesImage
    },
    OverviewVatCosts: {
      component: <OverviewVatCosts />,
      name: `${lang.get("VAT")}: ${lang.get("costs")}`,
      image: overviewVatCostsImage
    }
  };
  let overviewOptions = [];

  overviewOptions.push(allOverviewOptions.DocumentsOverview);
  
  if(doesUserHaveAccessToOverviewChart(0))
    overviewOptions.push(allOverviewOptions.OverviewInvoicesCostsProfit);
  
  if(doesUserHaveAccessToOverviewChart(1))
    overviewOptions.push(allOverviewOptions.Profit)

  if(doesUserHaveAccessToOverviewChart(2))
    overviewOptions.push(allOverviewOptions.OverviewEstimatesOrdersTickets);

  if(doesUserHaveAccessToOverviewChart(3))
    overviewOptions.push(allOverviewOptions.EstimatesNotUsed);

  if(doesUserHaveAccessToOverviewChart(4))
    overviewOptions.push(allOverviewOptions.OverviewVatInvoices);

  if(doesUserHaveAccessToOverviewChart(5))
    overviewOptions.push(allOverviewOptions.OverviewVatCosts);

  return overviewOptions;
}
