import React from "react";
import Lang from "../../lang";
import { Typography } from "@material-ui/core";
import CachedDataSingleton from "../../cachedDataSingleton";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import { Redirect } from "react-router-dom";
import Checkbox from "@material-ui/core/Checkbox";
import { WizardModel } from "../../Models/Models";
import { post, UrlEnum, getCookieUserData } from "../../Utils/Utils";
import scrollGIF from "../../Images/scroll-down.gif";
import swipeGIF from "../../Images/swipe.gif";
import theme from "../../Theme/Theme";

const styles = (theme) => ({
  textWrapper: {
    height: 400,
    overflow: "scroll",
    textAlign: "justify",
    padding: "20px",
    overflowX: "hidden",
    marginBottom:25,
    "& p":{
        marginTop:20
    }
  },
  margin2:{
    marginTop:6,
  },

  photo: {
    height: 200,
    width: 150,
    [theme.breakpoints.down("md")]: {
      height: 0,
      width: 0,
    },
  },
  button:{
    marginLeft:30
  },
  photo2: {
    height: 0,
    width: 0,
    [theme.breakpoints.down("md")]: {
      height: 150,
      width: 100,
    },
  },
});
const userData = getCookieUserData();
class Terms extends React.Component {
  /**
   * constructor
   * @param {Object} props
   */
  constructor(props) {
    super(props);
    this.lang = Lang.getInstance();
    this.cachedData = CachedDataSingleton.getInstance();
    this.mapWrapperRef = React.createRef();
    this.editedModel = null;
    this.crtPath = props.crtPath || "/";
    this.state = {
      accepted: true,
      ariaLabel: true,
      image: true,
      swipeScreen: true,
      validated: false,
    };
  }
  /**Scrol function */

  onScroll = (e) => {
    if (
      e.currentTarget.scrollTop >=
      e.currentTarget.scrollHeight - e.currentTarget.offsetHeight
    ) {
      this.setState({ ariaLabel: false, image: false });
    } else {
      this.setState({ ariaLabel: true, image: true });
    }
  };

  /**Checkbox function */
  clickBox = () => {
    this.setState({ accepted: !this.state.accepted });
  };

  handleSubmit = async () => {
    
    const formData = new FormData();
    /*TO DO  FOR*/
    formData.append("name", WizardModel.name);
    formData.append("representativeName", WizardModel.representativeName);
    formData.append("vatNo", WizardModel.vatNo);
    formData.append("bankAccount", WizardModel.bankAccount);
    formData.append("bankName", WizardModel.bankName);
    formData.append("bankBic", WizardModel.bankBic);
    formData.append("address", WizardModel.address);
    formData.append("city", WizardModel.city);
    formData.append("termsAndConditions", WizardModel.termsAndConditions);
    formData.append("address.country", WizardModel.fk_countryId);
    formData.append("logo", WizardModel.logo);
    formData.append("signature", WizardModel.signature);
    formData.append("code", WizardModel.code);
    formData.append("mobile", WizardModel.mobile);
    formData.append("package", WizardModel.subscription);
    formData.append("email", WizardModel.email);
    formData.append("companyRegNo", WizardModel.companyRegNo);

    post(UrlEnum.wizard, formData).then(
      function (data) {
        if (data.errors) {
          this.props.onError(data.errors);
          return;
        }
        if (userData) {
          let expires = new Date(
            Date.now() + 3600 * 1000 * 24 * 15
          ).toUTCString();
          document.cookie = `userData=${JSON.stringify({
            email: userData.email,
            phone: userData.phone,
            fk_companyid: data.id,
          })}; expires=${expires}; path=/`;
        }
        this.setState({ validated: true });
      }.bind(this)
    );
  };

  /**
   * render method
   */
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        {this.state.validated ? <Redirect to="/"></Redirect> : ""}
        <div onScroll={this.onScroll} className={this.props.classes.textWrapper}>
          <p>
            1. Introduction Ces Termes et Conditions standard du site Web écrits
            sur cette page Web gèrent votre utilisation de ce site Web. Ces
            conditions seront pleinement appliquées et affecteront votre
            utilisation de ce site Web. En utilisant ce site Web, vous acceptez
            d’accepter toutes les modalités et conditions écrites ici. Vous ne
            devez pas utiliser ce site Web si vous n’êtes pas d’accord avec l’un
            de ces termes et conditions. Les mineurs ou les personnes de moins
            de 18 ans ne sont pas autorisés à utiliser ce site Web.
          </p>
          <p>
            2. Droits de propriété intellectuelle Outre le contenu que vous
            possédez, en vertu des présentes Conditions générales, Davany Sprl
            et / ou ses concédants de licence possèdent tous les droits de
            propriété intellectuelle et les éléments contenus dans ce site Web.
            Vous obtenez une licence limitée uniquement pour visionner le
            contenu de ce site Web.
          </p>
          <p>
            3. Restrictions Vous êtes spécifiquement limité de tous les éléments
            suivants publier du matériel sur un site Web dans tout autrgite
            média; vendre, sous-licencier et / ou commercialiser d’une
            quelconque manière tout matériel de site Web; effectuer publiquement
            et / ou montrer du matériel sur le site Web; utiliser ce site de
            quelque manière que ce soit qui pourrait être dommageable pour ce
            site Web; utiliser ce site Web de quelque façon que ce soit ayant un
            impact sur l’accès des utilisateurs à ce site Web; l’utilisation de
            ce site Web en contravention des lois et règlements applicables, ou
            de quelque façon que ce soit, peut causer des dommages au site Web
            ou à toute personne ou entité commerciale; s’engager dans
            l’extraction de données, la collecte de données, l’extraction de
            données ou toute autre activité similaire par rapport à ce site Web;
            utiliser ce site Web pour vous engager dans toute publicité ou
            marketing. Certaines parties de ce site Web ne sont pas accessibles
            par vous et Davany Sprl peut en outre restreindre l’accès de votre
            part à toute partie de ce site Web, à tout moment, à sa discrétion
            absolue. Tout identifiant et mot de passe que vous pouvez avoir pour
            ce site Web est confidentiel et vous devez également maintenir la
            confidentialité.
          </p>
          <p>
            4. Votre contenu Dans les présentes conditions générales du site
            Web, «votre contenu» désigne tout contenu audio, vidéo, image ou
            autre contenu que vous choisissez d’afficher sur ce site Web. En
            affichant Your Content, vous accordez à Davany Sprl une licence non
            exclusive, irrévocable et sous licence mondiale pour l’utiliser, la
            reproduire, l’adapter, la publier, la traduire et la distribuer dans
            tous les médias. Votre contenu doit être le vôtre et ne doit pas
            envahir les droits d’un tiers. Davany Sprl se réserve le droit de
            supprimer tout ou partie de votre contenu de ce site Web à tout
            moment sans préavis.
          </p>
          <p>
            5. Aucune garantie Ce site Web est fourni «en l’état», avec tous les
            défauts, et Davany Sprl n’exprime aucune représentation ou garantie,
            de quelque nature que ce soit, liée à ce site Web ou aux matériaux
            contenus sur ce site Web. En outre, rien sur ce site Web ne doit
            être interprété comme vous conseillant.
          </p>
          <p>
            6. Limitation de responsabilité En aucun cas, Davany Sprl, ni aucun
            de ses dirigeants, administrateurs et employés, ne pourra être tenu
            pour responsable de quelque chose découlant de ou lié de quelque
            manière que ce soit à votre utilisation de ce site Web, que cette
            responsabilité soit contractuelle ou non. Davany Sprl, y compris ses
            dirigeants, administrateurs et employés, ne peut être tenu
            responsable de toute responsabilité indirecte, consécutive ou
            spéciale découlant de ou liée de quelque manière que ce soit à votre
            utilisation de ce site Web.
          </p>
          <p>
            7. Indemnité Vous garantissez Davany Sprl dans toute la mesure
            possible contre et / ou contre tous les engagements, coûts,
            demandes, causes d’action, dommages et dépenses résultant de quelque
            manière que ce soit de votre violation de l’une quelconque des
            dispositions des présentes Conditions.
          </p>
          <p>
            8. Divisibilité Si une disposition de ces Conditions est jugée
            invalide en vertu d’une loi applicable, ces dispositions doivent
            être supprimées sans affecter les dispositions restantes dans les
            présentes.
          </p>
          <p>
            9. Variation des termes Davany Sprl est autorisé à réviser les
            présentes Conditions à tout moment, selon ses besoins, et en
            utilisant ce site Web, vous êtes tenu de les consulter
            régulièrement.
          </p>
          <p>
            10. Affectation Le Davany Sprl est autorisé à céder, transférer et
            sous-traiter ses droits et / ou obligations en vertu des présentes
            Conditions sans aucune notification. Cependant, vous n’êtes pas
            autorisé à céder, transférer ou sous-traiter vos droits et / ou
            obligations en vertu des présentes Conditions.
          </p>
          <p>
            11. Accord complet Ces conditions constituent l’intégralité de
            l’accord entre Davany Sprl et vous en ce qui concerne votre
            utilisation de ce site Web, et remplacent tous les accords et
            accords antérieurs.
          </p>
          <p>
            12. Loi applicable et juridiction Ces Conditions seront régies et
            interprétées conformément aux lois de l’État de Belgique, et vous
            vous soumettez à la juridiction non exclusive des tribunaux fédéraux
            et d’état situés en Belgique pour la résolution de tout litige.`
          </p>
        </div>

        <div style={{ display: "flex" }}>
          <Checkbox
            style={{ width: 50, padding:0 ,color:theme.palette.header?.main }}
            disabled={this.state.ariaLabel}
            onClick={this.clickBox}
          />

          <Typography className={classes.margin2}>
            {this.lang.get("termsAndConditions")}
          </Typography>

          <Button
            className={classes.button}
            onClick={this.handleSubmit}
            disabled={this.state.accepted}
            variant="contained"
            style={{background:theme.palette.header?.main,color:theme.palette.textColorSecondary?.main}}
          >
            {this.lang.get("accept")}
          </Button>
          {this.state.image ? (
            <img
              alt="scroll"
              className={classes.photo}
              src={scrollGIF}
              style={{
                position: "absolute",
                top: "35%",
                right: "20%",
                justifyContent: "center",
                alignItems: "center",
              }}
            />
          ) : (
            ""
          )}
          {this.state.image ? (
            <img
              alt="swipe"
              className={classes.photo2}
              src={swipeGIF}
              style={{
                position: "absolute",
                top: "10%",
                right: "5%",
                justifyContent: "center",
                alignItems: "center",
              }}
            />
          ) : (
            ""
          )}
        </div>
      </React.Fragment>
    );
  }
}

Terms.propTypes = {
  acceptCallback: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};
export default withStyles(styles)(Terms);
