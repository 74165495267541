import Lang from "../lang";
import Config from "../Utils/Config";

const lang = Lang.getInstance();

type AdditionalInfoWrapperProps = {
    totalNoVat: number,
    startDate?: string | null,
    endDate?: string | null,
    additionalInfo?: string | null | undefined
}
function AdditionalInfoWrapper(props: AdditionalInfoWrapperProps) {
    return (
        <>
            <div style={{
                border: "1px solid #ccc",
                padding: 10,
                marginTop: 10,
                fontSize: "11pt",
                position: "relative"
            }}>
                <p><strong>{lang.get("totalNoVat")}:</strong> {props.totalNoVat.toFixed(Config.noOfDecimals)}</p>
                {
                    props.startDate &&
                    <p style={{fontSize:15}}>
                        {
                            props.startDate ?
                                (<span>{lang.get("startDate")}: {props.startDate}</span>) :
                                null
                        }
                &nbsp;&nbsp;&nbsp;
                {
                            props.endDate ?
                                (<span>{lang.get("endDate")}: {props.endDate}</span>) :
                                null
                        }
                    </p>
                }
            </div>
        </>
    );
}

export default AdditionalInfoWrapper;