import * as React from "react";
import { withStyles, FormControl } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Lang from "../../lang";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import VatNo from "../../Components/VatNo";
import { WizardModel } from "../../Models/Models";
import MessageDialog from "../../Components/MessageDialog";
import { CircularProgress } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import ApiLayer from "../../Utils/ApiLayer";

let lang = Lang.getInstance();

class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      model: WizardModel,
      disableField: true,
      messageAlert: false,
      loading: false,
      messageSuccess: false,
      messageError: false,
      textMessage: "",
    };
    this.isVatVerified = 0;
    if (this.state.model.companyRegNo !== "") {
      this.state.disableField = false;
    }
    this.apiLayer = new ApiLayer();
  }

  formRef = React.createRef();
  /**
   *
   */
  handleChange = (event) => {
    const wizardModel = this.state.model;
    wizardModel[event.target.name] = event.target.value;
    this.setState({ model: wizardModel });
  };

  /**
   *
   */
  validate() {
    let form = this.formRef.current;
    return form.isFormValid(false);
  }

  /**
   *
   */
  handleCloseMessage() {
    if (this.isVatVerified === 1) {
      this.setState({
        messageAlert: false,
        loading: false,
        disableField: true,
      });
    } else {
      this.setState({
        messageAlert: false,
        loading: false,
        disableField: false,
      });
    }
  }
  /**
   *
   * @param {*} data
   */
  vatNoBlur(data) {
    const wizardModel = this.state.model;
    wizardModel.vatNo = data;
    this.setState({ model: wizardModel });
    this.getApiLayerData();
  }
  /**
   *
   */
  getApiLayerData() {
    /**
     * Verify if more than 2 request are made or Vat no is shorter than 11
     */
    if (this.isVatVerified > 2 || this.state.model.vatNo.length < 11 || this.state.model.vatNo.length > 14) {
      this.setState({ messageError: true });
      return;
    }
    const renderTimeout = 7 * 1000;
    setTimeout(() => {
      this.setState({ messageSuccess: false, messageError: false });
    }, renderTimeout);

    /**
     * Make loading circle vissible
     */
    this.setState({ loading: true });
    this.apiLayer.getInfoFromApi(
      this.state.model.vatNo,
      function (data) {
        this.isVatVerified++;
        /**
         * Check first if vatNo is write OK and after put second message to complet manually the fields
         */
        if (data.valid === false) {
          this.setState({ messageAlert: true });
          if (this.isVatVerified === 1)
            this.setState({
              textMessage:
                lang.get("vatNo") +
                " " +
                lang.get("invalid") +
                "! " +
                lang.get("tryAgain") +
                ".",
              disableField: false,
            });
          if (this.isVatVerified === 2)
            this.setState({
              textMessage: lang.get("vatNo") + " " + lang.get("valid") + "! ",
            });
          return;
        }
        /**
         * Complete data from Api
         */

        let m = { ...this.state.model };
        m.name = data.company_name ? data.company_name : "";
        let str = data.company_address ? data.company_address : "";
        let sir = str.split("\n");
        const address = sir[0];
        const indexSpace = sir[1].indexOf(" ");
        let code = sir[1].substr(0, indexSpace);
        let city = sir[1].substr(indexSpace).trim();
        m.address = address;
        m.code = code;
        m.city = city;
        /**
         * Succese message for 7 seconds
         */
        this.setState({
          model: m,
          loading: false,
          disableField: false,
          messageSuccess: true,
        });
      }.bind(this),
      function (response) {
        // Check for any error on API
        this.setState({
          loading: false,
          disableField: false,
          textMessage: lang.get("vatNo") + " " + lang.get("valid") + "! ",
          messageAlert: true,
        });
      }.bind(this)
    );
  }

  /**
   * Press Enter Event
   * @param {*} event
   */
  keyPressed(event) {
    if (event.key === "Enter") {
      this.getApiLayerData();
    }
  }

  render() {
    const classes = this.props.classes;
    return (
      <>
        <br />
        <Alert severity="warning">{lang.get("confirmedEmail")}</Alert>
        <div className={classes.container}>
          <Paper className={classes.paper}>
            <h2 className={classes.container}>{lang.get("about")}</h2>
            <ValidatorForm
              ref={this.formRef}
              instantValidate={false}
              onSubmit={() => {}}
            >
              {this.state.loading ? (
                <CircularProgress
                  color="secondary"
                  className={classes.loading}
                />
              ) : (
                <FormControl
                  required={true}
                  fullWidth={true}
                  className={classes.field}
                >
                  <VatNo
                    onBlur={this.vatNoBlur.bind(this)}
                    value={this.state.model.vatNo}
                    onKeyPress={this.keyPressed.bind(this)}
                  ></VatNo>
                </FormControl>
              )}
              <FormControl
                required={true}
                fullWidth={true}
                className={classes.field}
              >
                <TextValidator
                  fullWidth
                  disabled={this.state.disableField}
                  required={true}
                  id="name"
                  label={lang.get("companyName")}
                  onChange={this.handleChange}
                  validators={["required"]}
                  value={this.state.model.name}
                  name="name"
                  errorMessages={lang.get("fieldRequired")}
                />
              </FormControl>

              <FormControl
                required={true}
                fullWidth={true}
                className={classes.field}
              >
                <TextValidator
                  fullWidth
                  disabled={this.state.disableField}
                  required={true}
                  id="representativeName"
                  label={lang.get("representativeName")}
                  onChange={this.handleChange}
                  validators={["required"]}
                  value={this.state.model.representativeName}
                  name="representativeName"
                  errorMessages={lang.get("fieldRequired")}
                />

                <TextValidator
                  fullWidth
                  disabled={this.state.disableField}
                  required={true}
                  id="companyRegNo"
                  label={lang.get("companyRegNo")}
                  onChange={this.handleChange}
                  validators={["required"]}
                  value={this.state.model.companyRegNo}
                  name="companyRegNo"
                  errorMessages={lang.get("fieldRequired")}
                />
              </FormControl>
              {this.state.messageSuccess ? (
                <Alert severity="success">{lang.get("vatNo") + " OK"}</Alert>
              ) : null}
              {this.state.messageError ? (
                <Alert severity="error">{lang.get("vatValidationErr")}</Alert>
              ) : (
                ""
              )}
            </ValidatorForm>
            <MessageDialog
              open={this.state.messageAlert}
              text={this.state.textMessage}
              onResult={this.handleCloseMessage.bind(this)}
            />
          </Paper>
        </div>
      </>
    );
  }
}

const styles = (theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    flexGrow: 1,
    overflow: "hidden",
    backgroundSize: "cover",
    backgroundPosition: "0 400px",
  },
  paper: theme.mixins.gutters({
    paddingTop: 2,
    paddingBottom: 20,
    width: "80%",
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  }),
  field: {
    marginTop: 0,
  },
  country: {
    marginTop: 0,
  },
  loading: {
    maxWidth: "50%",
    display: "block",
    width: "50%",
    alignContent: "center",
  },

  fieldCountry: theme.mixins.gutters({
    width: "90%",
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("md")]: {
      flexDirection: "row",
      width: "90%",
    },
  }),
  actions: theme.mixins.gutters({
    paddingTop: 16,
    paddingBottom: 16,
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
  }),
  button: {
    marginRight: 100,
  },
});

export default withStyles(styles, { withTheme: true })(About);
