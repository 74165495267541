import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Lang from '../lang';
import Config from '../Utils/Config';

const lang = Lang.getInstance();

export default function Items (props) {
    const styles = {
        tr: {
            borderBottom: "1px solid #888"
        },
        td: {
            margin: 0,
            padding: 10,
            fontSize: '11pt',
        },
        th: {
            verticalAlign: "top",
            padding: 7,
            fontSize: '11pt',
        },
        wrapper: {
            width: "100%",
            textAlign: "left",
            borderCollapse: "collapse",
            borderTop: "1px solid #888",
            marginTop: 30
        },
        textRight: {
            textAlign:"middle"
        },
        header: {
            background: props.color ? props.color : "transparent"
        },
        h5: {
            margin: "15px 0px 0px 0px",
            padding: 0,
            fontSize: '11pt',
        }
    };
    function _renderItems (item, index, parentIndex) {
        return (
            <tr key={index} style={styles.tr}>
                <td style={styles.td}>{parentIndex || parentIndex === 0 ? (parentIndex + 1) + "." : null}{index + 1}</td>
                <td style={{ ...styles.th, maxWidth: 220 }}>{item.serviceName}<br />{item.description}</td>
                <td style={{ ...styles.td, ...styles.textRight }}>{item.serviceUm}</td>
                <td style={{ ...styles.td, ...styles.textRight }}>{parseFloat(item.serviceQuantity)}</td>
                {
                    props.hidePrices ? null :
                    <>
                        <td style={{ ...styles.td, ...styles.textRight }}>{parseFloat(item.servicePrice).toFixed(Config.noOfDecimals)}</td>
                        <td style={{ ...styles.td, ...styles.textRight }}>{parseFloat(item.totalServicePrice).toFixed(Config.noOfDecimals)}</td>
                        <td style={{ ...styles.td, ...styles.textRight }}>{parseFloat(item.vat).toFixed(Config.noOfDecimals)}</td>
                    </>
                }
            </tr>
        );
    }

    function _renderCateg (objKeys) {
        const ret = objKeys.map((key, parentIndex) =>
            <>
                <tr><td colSpan="8"><h5 style={styles.h5}>{parentIndex + 1}. {key}</h5></td></tr>
                {
                    props.items[key].map((subitem, index) =>
                        _renderItems(subitem, index, parentIndex)
                    )
                }
            </>
        );
        return ret;
    }

    const objKeys = Object.keys(props.items);

    return (
        <div styles={{ position: "relative", height: "100%" }}>
            <table style={styles.wrapper}>
                <thead>
                    <tr style={styles.header} >
                        <th style={{ ...styles.th, width: 10 }}>{lang.get("number")}</th>
                        <th style={{ ...styles.th, maxWidth: 220 }}>{lang.get("name")}</th>
                        <th style={{ ...styles.th, ...styles.textRight, width: 50 }} >{lang.get("measureUnit")}</th>
                        <th style={{ ...styles.th, ...styles.textRight, width: 50 }}>{lang.get("quantity")}</th>
                        {
                            props.hidePrices ? null :
                            <>
                                <th style={{ ...styles.th, ...styles.textRight }}>{lang.get("unitPrice")}</th>
                                <th style={{ ...styles.th, ...styles.textRight }}>{lang.get("totalNoVat")}</th>
                                <th style={{ ...styles.th, ...styles.textRight }}>{lang.get("vat")}</th>
                            </>
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        objKeys && !(props.items instanceof Array) ?
                            _renderCateg(objKeys)
                            :
                            props.items.map((item, index) =>
                                _renderItems(item, index)
                            )}
                </tbody>
            </table>
        </div>
    );
}


Items.propTypes = {
    items: PropTypes.array.isRequired,
    color: PropTypes.string,
    hidePrices: PropTypes.bool
};