import {
    Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@material-ui/core";
import React, {useState} from "react";
import CloseIcon from "@material-ui/icons/Close";
import { get, UrlEnum } from "../Utils/Utils";
import Lang from "../lang";
import TableComponent from "./TableComponent";
import ConfirmDialog from "./ConfirmDialog";
import DashboardContext from "../Contexts/DashboardContext";
import theme from "../Theme/Theme"
const lang:any = Lang.getInstance();

type ChangeAllServicesPricesDialogProps = {
  open: boolean;
  onClose: (e: React.MouseEvent | any) => void;
};

function ChangeAllServicesPricesDialog(props: ChangeAllServicesPricesDialogProps) {
  const [percentValue, setPercentValue] = useState("");
  const [openConfirm, setOpenConfirm] = useState(false);

  function confirmResult(confirm:boolean, setMessage:(text:string)=>void){
    if(!confirm){
      setOpenConfirm(false);
      return;
    }
    const url = UrlEnum.categories + "/modify/" +percentValue;
    get(url).then(response=>{
      if(response.errors){
        setMessage(
          response.errors instanceof Array ? 
          response.errors.join(" "): 
          response.errors
        );
      }else{
        setOpenConfirm(false);
        setMessage(lang.get("success"));
      }
      props.onClose(null);
    });
  }
  
  return (
    <DashboardContext.Consumer>
									{(context) => (
      <Dialog 
          open={props.open}
          onClose={(e) => props.onClose(e)}
      >
        <DialogTitle>
          {lang.get("modifyPricesByPercent")} 
          <IconButton onClick={props.onClose} style={{float:"right", marginTop:-15, marginRight:-15}}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div>
          <TextField
          type="number"
            name="percent"
            value={percentValue}
            onChange={(e)=>setPercentValue(e.target.value)}
          />%</div>
        </DialogContent>
        <DialogActions>
            <Button variant="contained"
              style={{backgroundColor:theme.palette.successColor?.main}}
              onClick={()=>setOpenConfirm(true)}
            >
                {lang.get("save")}
            </Button>
            <Button variant="contained"
              style={{backgroundColor:theme.palette.errorColor?.main}}
              onClick={props.onClose}
            >
                {lang.get("close")}
            </Button>
        </DialogActions>
        <ConfirmDialog
          open={openConfirm} text={lang.get("modifyPrices")+ "?"} 
          onResult={(result)=>confirmResult(result,context.setSmallMessage)}
        />
      </Dialog>
      )}
    </DashboardContext.Consumer>
  );
}

export default ChangeAllServicesPricesDialog;
