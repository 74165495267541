/* eslint-disable no-undef */
import Lang from "../lang";
import { checkUserRoles } from "./CheckUserRoles";
import Config from "./Config";
import theme from "../Theme/Theme";
import { CACHED_DATA_TAG } from "./Constants";

const lang = Lang.getInstance();
/**
 * post data to http request
 * @param {string} url
 * @param {Object} data
 */
export async function post(url, data, props = null, method = "POST") {
  var properties = {
    method: method,
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };

  if (data instanceof FormData) {
    properties = {
      method: method,
      cache: "no-cache",
      body: data,
    };
  }
  if (props) {
    properties = Object.assign(properties, props);
  }
  const response = await fetch(url, properties);

  return handleHttpResponse(response);
}

export function put(url, data, props = null) {
  return post(url, data, props, "PUT");
}

/**
 * get data from http request
 * @param {string} url
 */
export async function get(url, requestHeaders = null, history = null) {
  const headers = requestHeaders
    ? requestHeaders
    : {
        "Content-Type": "application/json",
        Accept: "*/json",
      };
  const response = await fetch(url, {
    method: "GET",
    cache: "no-cache",
    headers: headers,
  });

  return handleHttpResponse(response, history);
}

/**
 * delete data from http request
 * @param {string} url
 * @param {object} data
 */
export async function httpDelete(url, data = null) {
  const response = await fetch(url, {
    method: "DELETE",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  return handleHttpResponse(response);
}

export async function download(url, fileName, newWindow = true) {
  const response = await fetch(url);
  response.blob().then((blob) => {
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
  });
}

/**
 * Read a cookie
 * @param {String} name
 */
export function readCookie(name) {
  let n = name + "=";
  const index = document.cookie.indexOf(n);
  if (index < 0) {
    return "";
  }
  var cookieValue = document.cookie.substring(index + n.length);
  let end = cookieValue.indexOf(";");
  if (end >= 0) {
    cookieValue = cookieValue.substring(0, end);
  }
  return cookieValue;
}

/**
 *
 */
export function isLoggedIn() {
  let roles = null;
  try {
    roles = JSON.parse(unescape(readCookie("userRoles")));
  } catch (e) {
    return false;
  }

  return readCookie("username").length > 0 && roles.length > 0;
}

export function getCookieUserData() {
  try {
    let userData = readCookie("userData");
    userData = JSON.parse(userData);
    return userData;
  } catch (e) {
    return null;
  }
}

/**
 * handle the respoonse of a http request
 * @param {Object} response
 */
async function handleHttpResponse(response, history) {
  var res = response.clone();
  switch (res.status) {
    case HttpStatusCode.OK:
      // ok
      try {
        let resp = await res.json();
        return resp;
      } catch (e) {
        return await response.text();
      }
    case HttpStatusCode.BadRequest:
    case HttpStatusCode.MethodNotAllowed:
    case HttpStatusCode.InternalServerError:
      try {
        let resp = await res.json();
        return { errors: resp[Object.keys(resp)[0]] };
      } catch (e) {
        try {
          let errText = await response.text();
          if (errText.substring(0, 1) === "<") {
            return { errors: lang.get("error") };
          }
          if (errText && errText.length > 0) {
            return { errors: errText };
          }
        } catch (ex) {
          console.log(ex);
        }
      }
      return { errors: response.statusText };
    case HttpStatusCode.Unauthorized:
      if (document.location.pathname !== LocalUrlEnum.login) {
        document.cookie =
          "username=; userRoles=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/";
        if (history) {
          history.push(LocalUrlEnum.login);
        } else {
          return { errors: lang.get("unauthorized") };
        }
        document.location.href = LocalUrlEnum.login;
      } else {
        try {
          let resp = await res.json();
          return { errors: resp[Object.keys(resp)[0]] };
        } catch (e) {
          try {
            let errText = await response.text();
            if (errText.substring(0, 1) === "<") {
              return { errors: lang.get("error") };
            }
            if (errText && errText.length > 0) {
              return { errors: errText };
            }
          } catch (ex) {
            console.log(ex);
          }
        }
      }
      break;
    case HttpStatusCode.NotFound:
      return { errors: lang.get("notFound") };
    default:
      break;
  }
  return res;
}

/**
 * document types enum
 */
export const DocTypes = {
  notice: "notice",
  liability: "reminder",
  invoice: "invoice",
  proforma: "proforma",
  creditNote: "creditNote",
  estimate: "estimate",
  orderTicket: "orderticket",
  subcontractorOrderTicket: "subcontractororderticket",
  receipt: "receipt",
  folder: "folder",
  cost: "cost",
  generalConditions: "generalConditions",
  project: "project",
  vatAttestation: "vatAttestation",
};

/**
 * urls to static paths in the UI
 */
export var LocalUrlEnum = {
  isTrial: "/getPackage",
  invoices: "/invoices",
  projects: "/projects",
  creditNotes: "/creditnotes",
  proformas: "/proformas",
  estimates: "/estimates",
  orderTickets: "/ordertickets",
  subcontractorordertickets: "/subcontractorordertickets",
  clients: "/clients",
  employees: "/employees",
  subcontractors: "/subcontractors",
  services: "/services",
  companyInfo: "/companyInfo",
  articles: "/articles",
  options: "/options",
  documentTemplates: "/documentTemplates",
  costs: "/costs",
  login: "/login",
  timeTracking: "/timeTracking",
  users: "/users",
  liability: "/liability",
  report: "/report",
  doc: "/doc",
  wizard: "/wizard",
  companyoverview: "/companyoverview",
  confirmPayment: "/confirmPayment",
  budgetEstimate: "/costs/budgetEstimate",
};
/**
 * Enumeration of urls used in the application
 */
var urlEnum = {
  login: "/loginAjax",
  logout: "/logoutAjax",
  register: "/register",
  wizard: "/newAjax",
  cachedData: "/cachedData/{oldHash}",
  clients: "/clients",
  notice: "/clients/notice",
  subcontractors: "/subcontractors",
  subcontractorFiles: "/subcontractors/files",
  subcontractorDeleteFiles: "/subcontractors/fromGallery",
  employees: "/employees",
  persons: "/persons",
  userUpdate: "/updateUserProfile",
  getUserInfo: "/usr",
  companyInfo: "/companyInfo",
  companies: "/companies",
  onlinePayments: "/company/toggleOnlinePayments",
  stripeAccountBalance: "/stripeAccountBalance",
  disableAccount: "/company/disable",
  services: "/services",
  serviceCategories: "/services/category",
  servicePackages: "/servicePackages",
  articles: "/articles",
  companyOptions: "/companyOptions",
  documentTemplates: "/documentTemplates/new",
  invoices: "/invoices",
  categories: "/categories",
  payments: "/payments",
  folderCosts: "/folderCosts",
  pdf: "/compiledDoc/downloadPdf/{type}/{id}",
  compiledDoc: "/compiledDoc",
  sendEmail: "/sendMail",
  estimates: "/estimates",
  orderTickets: "/ordertickets",
  projects: "/folders",
  suppliers: "/suppliers",
  postTempFiles: "/postFiles",
  timeTracking: "/timekeeping",
  notificationsDeactivate: "/notifications/deactivate",
  companyUsers: "/companyUsers",
  timetrackingReport: "/timekeeping/report",
  reports: "/reports",
  importData: "/services/import",
  liability: "/liabilities",
  setBadPayee: "/clients/setBadPayee/",
  overview: "/overview",
  vatOverview: "/overview",
  isEmailConfirmed: "/userEmailConfirmed/",
  verifyCompany: "/verifyCompany",
  countries: "/countries",
  locale: "/locale/",
  sendConfirmationEmail: "/sendEmail/",
  buyPackage: "/buyPackage",
  checkPaymentStatus: "/checkPaymentStatus/",
  newOverview: "/newOverview/",
};

export var UrlEnum = (function () {
  if (process.env.NODE_ENV && process.env.NODE_ENV !== "development") {
    for (let key in urlEnum) {
      urlEnum[key] = "/api" + urlEnum[key];
    }
  }
  return urlEnum;
})();

export var isProduction = function () {
  return process.env.NODE_ENV && process.env.NODE_ENV !== "development";
};

/**
 *
 */
export var PaymentMode = {
  cash: "cash",
  bankTransfer: "bankTransfer",
  creditCard: "creditCard",
  bancontact: "bancontact",
  online: "ONLINE",
};

export var BlockedStatus = {
  blocked: "blocked",
  unblocked: "unblocked",
};
/**
 *
 */
export var Currency = {
  EURO: "EUR",
  RON: "RON",
};

/**
 * user roles enumeration
 */
export var UserRolesEnum = {
  ADMIN: "admin",
  INVOICING: "invoicing",
  ACCOUNTING: "accounting",
  COSTMANAGEMENT: "costManagement",
  ESTIMATES: "estimates",
  SUBCONTRACTORS: "subcontractors",
  COMPANYUSERS: "companyUsers",
  TIMEKEEPING: "timekeeping",
  COMPANYOVERVIEW: "companyoverview",
};

export var CostTypeEnum = {
  PURCHASE: "purchase",
  ADMINISTRATIVE: "administrative",
  WORKFORCE: "workforce",
};
/**
 * company options enumeration
 */
export var CompanyOptionsEnum = {
  TIMEKEEPING: "timekeeping",
  REMINDERDELAY: "reminderDelay",
  NOTIFICATIONDELAY: "notificationDelay",
  REMINDERNOTIFICATIONMINAMOUNT: "reminderNotificationMinAmount",
  CONTINUOUSINVOICENUMBERS: "continuousInvoiceNumbers",
  TIMETRACKINGPAUSE: "timeTrackingPause",
  TRADEMARGIN: "tradeMargin",
  WORKINCONSTRUCTION: "workInConstruction",
};

/**
 * Subscriptions
 */
export var SubscriptionsEnum = {
  FREE: "free",
  MINIMAL: "minimal",
  BASIC: "basic",
  PROFESSIONAL: "professional",
  PROFESSIONAL_PLUS: "professional",
};

export var StatusEnum = {
  SUCCESS: "success",
  ERROR: "error",
  WARNING: "warning",
  INFO: "info",
};

/**
 *
 */
export var Statuses = {
  Active: { id: 1, name: "Active" },
  Inactive: { id: 2, name: "Inactive" },
  Deleted: { id: 3, name: "Deleted" },
  Suspended: { id: 4, name: "Suspended" },
  StandBy: { id: 5, name: "StandBy" },
  Paid: { id: 6, name: "Paid" },
  Optional: { id: 7, name: "Optional" },
};

/**
 * currencies
 */
export var CurrencyEnum = {
  EUR: "EUR",
  RON: "RON",
};

/**
 * Titles enumeration
 */
export var TitleEnum = [
  "",
  "M.",
  "Mme.",
  "M. et Mme.",
  "SPRL",
  "SA",
  "SRL",
  "ACP",
];

/**
 * responsive design resolution breakpoints
 */
export var ResolutionBreakPoints = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
};

/**
 * Http Status Codes enumeration
 */
export var HttpStatusCode = {
  OK: 200,
  Created: 201,
  Accepted: 202,
  BadRequest: 400,
  Unauthorized: 401,
  Forbidden: 403,
  NotFound: 404,
  MethodNotAllowed: 405,
  RequestTimeout: 408,
  InternalServerError: 500,
  NotImplemented: 501,
};

/**
 * check if the email is confirmed
 * @param {*} username
 * @returns
 */
export async function logoutUtils() {
  await get(UrlEnum.logout).then(() => {
    document.cookie = "";
    document.cookie = "username=;";
    document.cookie = " userRoles=;";
    document.cookie = "userData=;";
    window.localStorage.removeItem(CACHED_DATA_TAG);
    document.location.href = LocalUrlEnum.login;
  });
}
export async function checkMailConfirmed(username) {
  if (!username) return false;
  const response = await get(UrlEnum.isEmailConfirmed + username);
  if (response.errors) {
    return false;
  }
  return response;
}
/**
 * encode an object as query string
 * @param {Object} obj
 */
export function uriEncodeObj(obj) {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p) && obj[p]) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
}

/**
 * check if current user has specified role
 * @param {any} roleName
 * @returns {* | boolean}
 * @param {string | any[]} userRoles
 */
export function userHasRole(userRoles, roleName) {
  return userRoles && userRoles.indexOf(roleName) >= 0;
}

/**
 * Handle Input change
 * @param {*} event
 * @param {*} model
 */
export function handleInputChange(event, model, sameModel = false) {
  let name = event.currentTarget.name || event.currentTarget.id;
  if (!name) {
    name = event.target.name || event.target.id;
  }
  const nameParts = name.split(".");
  let newModel = sameModel ? model : Object.assign({}, model);
  let value = event.target.value;
  if (nameParts.length === 1) {
    newModel[nameParts[0]] = value;
  } else {
    let submodel = newModel;
    for (let i = 0; i < nameParts.length - 1; i++) {
      submodel = submodel[nameParts[i]];
    }
    submodel[nameParts[nameParts.length - 1]] = value;
  }
  return newModel;
}

export function isMobile() {
  return document.body.offsetWidth < ResolutionBreakPoints.md;
}

/**
 * change all paths to images to absolute paths
 * @param {*} node
 */
export function absoluteImagePaths(node) {
  let protocol = window.location.href.split("/")[0];
  let images = node.querySelectorAll("img");
  images.forEach((img) => {
    let src = img.getAttribute("src");
    if (src !== null && src.indexOf("http") < 0 && src.indexOf("data:") < 0)
      img.setAttribute(
        "src",
        protocol + "//" + document.location.host + "/" + src
      );
  });
  return node;
}

/**
 * Class for manipulating  google maps
 */
export class GoogleMapsUtils {
  /**
   * @param {{ parent: any; }} options
   */
  constructor(options) {
    if (!options.parent) {
      throw new Error("parent not defined");
    }
    this.options = options;
    this.map = this.getMap();
    // @ts-ignore
    this.geocoder = new google.maps.Geocoder();
  }

  destruct() {
    this.map = null;
  }

  getMap() {
    // @ts-ignore
    var latlng = new google.maps.LatLng(50.513427, 14.875488);
    var gOptions = {
      zoom: 15,
      center: latlng,
    };
    let parent = this.options.parent.current;

    // @ts-ignore
    var map = new google.maps.Map(parent, gOptions);
    return map;
  }

  /**
   * @param {string} address
   */
  geolocateAddress(address) {
    return get(
      "https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyBAfEAPud1Ushe8qqMUCsEoUoH9hzk16ok&address=" +
        address,
      // "&language=en",
      {}
    );
  }

  /**
   * @param {string} lat
   * @param {string} lng
   */
  geocodeLatLng(lat, lng) {
    var latlng = { lat: parseFloat(lat), lng: parseFloat(lng) };
    return new Promise((resolve, reject) => {
      /**
       * @param {any} geocoderResult
       * @param {string} geocoderStatus
       */
      var callback = (geocoderResult, geocoderStatus) => {
        if (geocoderStatus === "OK") {
          // @ts-ignore
          resolve(geocoderResult, geocoderStatus);
        } else {
          // @ts-ignore
          reject(geocoderResult, geocoderStatus);
        }
      };
      this.geocoder.geocode({ location: latlng }, callback);
    });
  }

  /**
   * @param {any} lat
   * @param {any} lng
   */
  addMarker(lat, lng, title = "", label = "") {
    // @ts-ignore
    var latlng = new google.maps.LatLng(lat, lng);
    // @ts-ignore
    var markerOptions = {
      position: latlng,
      map: this.map,
      title: title,
    };
    if (label) {
      markerOptions.label = {
        text: label || " ",
        color: "black",
        fontSize: "18px",
      };
    }
    var marker = new google.maps.Marker(markerOptions);
    return marker;
  }

  /**
   * @param {{ setPosition: (arg0: any) => void; }} marker
   * @param {any} lat
   * @param {any} lng
   */
  moveMarker(marker, lat, lng) {
    // @ts-ignore
    var latlng = new google.maps.LatLng(lat, lng);
    marker.setPosition(latlng);
    return marker;
  }

  getCurrentLocation() {
    if (!navigator.geolocation) {
      throw new Error("Your browser does not support Geo Location.");
    }
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (data) => {
          resolve(data);
        },
        (data) => {
          reject(data);
        }
      );
    });
  }

  getCurrentLocationGAapi() {
    return post(
      "https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyBAfEAPud1Ushe8qqMUCsEoUoH9hzk16ok" +
        "&language=en",
      {}
    );
  }

  /**
   * @param {any} addr
   */
  static doGeocode(addr) {
    // Get geocoder instance
    // @ts-ignore
    var geocoder = new google.maps.Geocoder();
    return new Promise(function (resolve, reject) {
      // Geocode the address
      geocoder.geocode(
        {
          address: addr,
        },
        /**
         * @param {string | any[]} results
         * @param {any} status
         */ function (results, status) {
          // @ts-ignore
          if (
            status === google.maps.GeocoderStatus.OK &&
            results.length > 0 &&
            !results[0].partial_match
          )
            resolve(results);
          else reject(results);
        }
      );
    });
  }
}

export function getPathLocation(path, substring) {
  if (path.includes(substring)) return true;
  return false;
}

export function getLiabilityExpandedTableRowColor(invoiceDate) {
  let elapsedTime = new Date(invoiceDate);
  let curentTime = new Date();
  elapsedTime = curentTime - elapsedTime;
  let days = elapsedTime / 1000 / 60 / 60 / 24;
  if (days < Config.costInvoiceDefaultPaymentDefault) return "defaultColor";
  if (
    days >= Config.costInvoiceDefaultPaymentDefault &&
    days < Config.costInvoiceDefaultPaymentDelay
  )
    return "warningColor";
  if (days >= Config.costInvoiceDefaultPaymentDelay) return "errorColor";
}

export function getLiabilityTableRowColor(invoices) {
  let maxTimeElapsed = 0;
  if (invoices)
    invoices.forEach((element) => {
      let elapsedTime = new Date(element.invoiceDate);
      let curentTime = new Date();
      elapsedTime = curentTime - elapsedTime;
      let days = elapsedTime / 1000 / 60 / 60 / 24;
      if (days > maxTimeElapsed) maxTimeElapsed = days;
    });
  if (maxTimeElapsed < Config.costInvoiceDefaultPaymentDefault)
    return "defaultColor";
  if (
    maxTimeElapsed >= Config.costInvoiceDefaultPaymentDefault &&
    maxTimeElapsed < Config.costInvoiceDefaultPaymentDelay
  )
    return "warningColor";
  if (maxTimeElapsed >= Config.costInvoiceDefaultPaymentDelay)
    return "errorColor";
  return "defaultColor";
}

export function getLiabilityElapsedTime(invoiceDate) {
  let elapsedTime = new Date(invoiceDate);
  let curentTime = new Date();
  elapsedTime = curentTime - elapsedTime;
  let days = elapsedTime / 1000 / 60 / 60 / 24;
  return Math.floor(days);
}

export function macroReplacements(invoices) {
  let totalToPay = 0;
  let totalPayed = 0;
  invoices.forEach((invoice) => {
    totalToPay += invoice.totalWithVat;
    totalPayed += invoice.totalPaid;
  });
  return {
    totalwithVAT: totalToPay.toFixed(2),
    restToPay: (totalToPay - totalPayed).toFixed(2),
  };
}

/**
 *
 * @returns Returns an array with all the name of the months
 */
export function monthsName() {
  return [
    lang.get("January"),
    lang.get("February"),
    lang.get("March"),
    lang.get("April"),
    lang.get("May"),
    lang.get("June"),
    lang.get("July"),
    lang.get("August"),
    lang.get("September"),
    lang.get("October"),
    lang.get("November"),
    lang.get("December"),
  ];
}

export function graphColors() {
  return {
    colors: {
      invoices: theme.palette.greenColor.main,
      costs: theme.palette.pinkColor.main,
      orderTickets: theme.palette.blueColor.main,
      estimates: theme.palette.orangeColor.main,
      profit: theme.palette.yellowColor.main,
    },
    colorsTransparent: {
      invoices: theme.palette.greenColor.main,
      costs: theme.palette.pinkColor.main,
      orderTickets: theme.palette.blueColor.main,
      estimates: theme.palette.orangeColor.main,
      profit: theme.palette.yellowColor.main,
    },
  };
}

export function addHms(hms1, hms2) {
  let s = (parseInt(hms1[2]) + parseInt(hms2[2])) % 60;
  let m =
    (parseInt(hms1[1]) +
      parseInt(hms2[1]) +
      Math.floor((parseInt(hms1[2]) + parseInt(hms2[2])) / 60)) %
    60;
  let h =
    parseInt(hms1[0]) +
    parseInt(hms2[0]) +
    Math.floor((parseInt(hms1[1]) + parseInt(hms2[1])) / 60);
  return [h, m, s];
}
/**
 *
 * @returns Return an array with all the number of the months
 */
export function monthNumber() {
  return [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];
}

/**
 *
 * @param {Object that contains overview details} overview
 * @returns Returns overview object with all props or with some props deleted
 */
export function overviewFilteredByRole(overview) {
  if (overview) {
    if (
      checkUserRoles([
        UserRolesEnum.ADMIN,
        UserRolesEnum.ESTIMATES,
        UserRolesEnum.COSTMANAGEMENT,
        UserRolesEnum.COMPANYUSERS,
      ])
    )
      return overview;
    delete overview.estimates;
    delete overview.orderTickets;
    return overview;
  }
  return {};
}

/**
 * render static html document string
 * @param {*} html
 * @param {*} css
 */
export function renderFullPage(html, css) {
  return `
    <!DOCTYPE html>
    <html>
      <head>
        <title></title>
        <style id="jss-server-side">${css}</style>
      </head>
      <body>
        <div id="root">${html}</div>
      </body>
    </html>
  `;
}

/**
 *  replace values form string with values in object
 * @param template
 * @param values
 */
export function fromStringTemplate(template, values) {
  let t = template.slice();
  for (let key of Object.keys(values)) {
    t = t.replaceAll("${" + key + "}", values[key]);
  }
  return t;
}

/**
 *
 * @param {* graph number starting from 0} graphNumber
 * @returns Returns boolean representing if graph should be rendered
 */
export function doesUserHaveAccessToOverviewChart(graphNumber) {
  switch (graphNumber) {
    case 0:
      if (
        checkUserRoles([
          UserRolesEnum.ADMIN,
          UserRolesEnum.ACCOUNTING,
          UserRolesEnum.INVOICING,
          UserRolesEnum.COSTMANAGEMENT,
          UserRolesEnum.COMPANYUSERS,
        ])
      )
        return true;
      return false;
    case 1:
      if (
        checkUserRoles([
          UserRolesEnum.ADMIN,
          UserRolesEnum.ACCOUNTING,
          UserRolesEnum.INVOICING,
          UserRolesEnum.COSTMANAGEMENT,
          UserRolesEnum.COMPANYUSERS,
        ])
      )
        return true;
      return false;
    case 2:
      if (
        checkUserRoles([
          UserRolesEnum.ADMIN,
          UserRolesEnum.ACCOUNTING,
          UserRolesEnum.ESTIMATES,
          UserRolesEnum.COSTMANAGEMENT,
          UserRolesEnum.COMPANYUSERS,
        ])
      )
        return true;
      return false;
    case 3:
      if (
        checkUserRoles([
          UserRolesEnum.ADMIN,
          UserRolesEnum.ACCOUNTING,
          UserRolesEnum.ESTIMATES,
          UserRolesEnum.COSTMANAGEMENT,
          UserRolesEnum.COMPANYUSERS,
        ])
      )
        return true;
      return false;
    case 4:
      if (
        checkUserRoles([
          UserRolesEnum.ADMIN,
          UserRolesEnum.ACCOUNTING,
          UserRolesEnum.INVOICING,
          UserRolesEnum.COSTMANAGEMENT,
          UserRolesEnum.COMPANYUSERS,
        ])
      )
        return true;
      return false;
    case 5:
      if (
        checkUserRoles([
          UserRolesEnum.ADMIN,
          UserRolesEnum.ACCOUNTING,
          UserRolesEnum.INVOICING,
          UserRolesEnum.COSTMANAGEMENT,
          UserRolesEnum.COMPANYUSERS,
        ])
      )
        return true;
      return false;
    default:
      return true;
  }
}
