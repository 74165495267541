import noData from '../Images/nodata.png';

function NoDataFound () {
    return (
        <div style={{ width: 150, margin: "0 auto" }}>
            <img alt="noData" src={noData} style={{ width: "100%", opacity: 0.75 }} />
        </div>
    );
}

export default NoDataFound;