import { Button, Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import Lang from "../../lang";
import theme from "../../Theme/Theme";
import AddNewTimetrackingModal from "./AddNewTimetrackingModal";

const lang = Lang.getInstance();

export default function AddNewTimetracking(props: any) {
  /**
   *
   */
  /**
   *
   * @returns
   */
  function noActiveEmployees() {
    return (
      <div>
        <h2 style={{ fontWeight: "normal", margin: "0px 0px 30px 0px" }}>
          {lang.get("noEmployees")}
        </h2>
        <div style={{ textAlign: "center" }}>
          <Button
            style={{
              background: theme.palette.primary?.main,
              color: theme.palette.textColorSecondary?.main,
              marginBottom: "20px",
            }}
            onClick={props.onClose}
          >
            {lang.get("ok")}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <Dialog open={props.addNewOpenReport} onClose={props.onClose}>
      <DialogTitle>
        {props.activeEmployees.length > 0
          ? `${lang.get("add")}: ${lang.get("timekeeping")}`
          : ""}
      </DialogTitle>
      <DialogContent>
        {props.activeEmployees.length > 0 ? (
          <AddNewTimetrackingModal
            onSubmit={props.saveTimeTracking}
            employees={props.activeEmployees}
            activeProjects={props.activeProjects}
            activeOrderTickets={props.activeOrderTickets}
            timekeepingOption={props.timekeepingOption}
            onClose={props.onClose}
            showSmallMessage={props.showSmallMessage}
          />
        ) : (
          noActiveEmployees()
        )}
      </DialogContent>
    </Dialog>
  );
}
