import {useRef, useEffect} from 'react';
import { TimeTracking } from '../../Models/Models';
import { GoogleMapsUtils } from '../../Utils/Utils';
import scriptLoader from 'react-async-script-loader';
import Lang from '../../lang';

type EmployeesMapProps = {
    activeTimetrackings: Array<TimeTracking>
};

const lang = Lang.getInstance();

function EmployeesMap (props:EmployeesMapProps){

    const mapWrapperRef = useRef(null);

    useEffect(() => {
        let gmu:GoogleMapsUtils | null = new GoogleMapsUtils({
            parent: mapWrapperRef
        });
        let center = false;

        for( const at of props.activeTimetrackings){
            const positions:Array<{lat:number, lng:number, datetime:number}> = JSON.parse(at.position);
            const lastPosition = positions.length > 0 ? positions[positions.length-1] : null;
            if(lastPosition){
                const title = `${at.name}
                    \n start
                    \n ${lang.get("client")}: ${at.clientName}`;
                gmu?.addMarker(lastPosition.lat, lastPosition.lng, title);
                if(!center){
                    gmu?.map.setCenter({
                        lat:lastPosition.lat, lng:lastPosition.lng,
                    });
                    gmu?.map.setZoom(10);
                    center = true;
                }
            }
        }
        return () => {
            gmu?.destruct();
            gmu = null;
        }
    }, [props.activeTimetrackings])

    return(
        <>
        <h3>{lang.get("map")}</h3>
        <div id="mapsWrapped" ref={mapWrapperRef} style={{height:400, marginBottom:50}} ></div>
        </>
    );
}

export default scriptLoader("https://maps.google.com/maps/api/js?key=AIzaSyBAfEAPud1Ushe8qqMUCsEoUoH9hzk16ok&language=en")(EmployeesMap);