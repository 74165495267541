import React, { useEffect, useRef, useState } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { ExpandLess } from "@material-ui/icons";
import { Button, makeStyles } from "@material-ui/core";
import theme from "../Theme/Theme";
import Lang from "../lang";

const lang = Lang.getInstance();

const useStyle = makeStyles((theme) => ({
  container: {
    position: "relative",
  },
  gradient: {
    height: 50,
    width: "100%",
    background:
      "linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)",
    position: "absolute",
    bottom: 0,
  },
}));

export default function ShowMore(props) {
  const divRef = useRef();
  const classes = useStyle();
  const height = props.height || 500;
  const [showMore, setShowMore] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const buttonStyle = {
    margin: "10px",
    fontSize: "13px",
    background: theme.palette.header?.main,
    color: theme.palette.textColorSecondary?.main,
  };

  function isSmallerThan() {
    const rect = divRef.current.getBoundingClientRect();

    if (rect.height < height) {
      setShowButton(false);
        if (rect.height > 100) return true;
    } else {
      setShowButton(true);
    }
    return false;
  }

  useEffect(() => {
    let timer;
    if (divRef && divRef.current) {
      if (isSmallerThan()) {
        timer = setTimeout(isSmallerThan, 1000);
      }
    }
    return () => clearTimeout(timer);
  }, [divRef]);

  return (
    <>
      <div
        className={classes.container}
        style={
          !showMore && showButton
            ? { height: height, overflow: "hidden" }
            : { height: "auto", overflow: "none" }
        }
      >
        <div ref={divRef}>{props.children}</div>
        <div
          className={classes.gradient}
          style={
            !showMore && showButton ? { display: "block" } : { display: "none" }
          }
        ></div>
      </div>
      {showButton ? (
        !showMore ? (
          <div>
            <Button
              variant="contained"
              startIcon={<ExpandMoreIcon fontSize="large" />}
              onClick={() => {
                setShowMore(!showMore);
              }}
              style={buttonStyle}
            >
              {lang.get("showMore")}
            </Button>
          </div>
        ) : (
          <Button
            color="primary"
            variant="contained"
            startIcon={<ExpandLess fontSize="large" />}
            onClick={() => {
              setShowMore(!showMore);
            }}
            style={buttonStyle}
          >
            {lang.get("showLess")}
          </Button>
        )
      ) : null}
    </>
  );
}
