import { React, useState } from "react";
import { makeStyles, Grid, Button } from "@material-ui/core";
import Lang from "../../lang";
import visa from "../../Images/stripeCards/visa.png";
import MasterCard from "../../Images/stripeCards/MasterCard.png";
import Maestro from "../../Images/stripeCards/Maestro.png";
import MisterCash from "../../Images/stripeCards/MisterCash.png";
import stripeImg from "../../Images/stripeCards/stripe.png";
import CachedDataSingleton from "../../cachedDataSingleton";
import StripePaymentsForm from "../DashboardComponents/StripePaymentsForm";

const lang = Lang.getInstance();
const cachedData = CachedDataSingleton.getInstance();
export default function StripePayments(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const company = cachedData.get("company");
  const onlinePayments = company.onlinePayments;
  const useStyles = makeStyles((theme) =>
    Object.assign({
      payHeader: {
        padding: "5px",
        background: theme.palette.primary?.main,
        color: theme.palette.textColorSecondary?.main,
      },
      imagesDisable: {
        width: "8%",
        margin: "10px 10px 0px 10px",
      },
      stripeImgDisable: {
        width: "12%",
        margin: "20px 0px 0px 10px",
      },
      imagesEnable: {
        width: "11%",
        margin: "10px 10px 0px 10px",
      },
      stripeImgEnable: {
        width: "17%",
        margin: "20px 0px 0px 10px",
      },
      isConnect: {
        display: "inline-block",
      },
      isnotConnect: {
        textAlign: "center",
      },
      enableButton: {
        marginTop: "26px",
        padding: "8px",
        width:"100%",
        background: theme.palette.primary?.main,
        color: theme.palette.textColorSecondary?.main,
        "&:hover": {
          background: theme.palette.primary?.main,
        },
      },
      disableButton: {
        marginTop:10,
        padding: "8px",
        width:"32%",
        background: theme.palette.primary?.main,
        color: theme.palette.textColorSecondary?.main,
        "&:hover": {
          background: theme.palette.primary?.main,
        },
        float:"right"
      },
    })
  );
  const classes = useStyles();
  function handleIsModalOpen() {
    setIsModalOpen(!isModalOpen);
  }

  function getCardImages() {
    return (
      <>
        <img
          src={visa}
          alt="Visa"
          title="Visa"
          className={
            onlinePayments ? classes.imagesDisable : classes.imagesEnable
          }
        />
        <img
          src={MasterCard}
          alt="MasterCard"
          title="MasterCard"
          className={
            onlinePayments ? classes.imagesDisable : classes.imagesEnable
          }
        />
        <img
          src={Maestro}
          alt="Maestro"
          title="Maestro"
          className={
            onlinePayments ? classes.imagesDisable : classes.imagesEnable
          }
        />
        <img
          src={MisterCash}
          alt="MisterCash"
          title="MisterCash"
          className={
            onlinePayments ? classes.imagesDisable : classes.imagesEnable
          }
        />
      </>
    );
  }
  function getStripeImage() {
    return (
      <a href="https://stripe.com/be/pricing">
        <img
          src={stripeImg}
          alt="Stripe"
          title="Stripe"
          className={
            onlinePayments ? classes.stripeImgDisable : classes.stripeImgEnable
          }
        />
      </a>
    );
  }

  function getEnableDisableButton(){
    return <Button
    className={onlinePayments ? classes.disableButton:classes.enableButton}
    onClick={handleIsModalOpen}
    // fullWidth
  >
    {onlinePayments ? lang.get("disable") : lang.get("enable")}
  </Button>
  }
  function renderContent() {
    return (
      <>
        <div className={onlinePayments ? classes.isConnect : classes.isnotConnect}>
          {getCardImages()}
          {!onlinePayments ? (
            <div style={{ textAlign: "center" }}>
              {getStripeImage()}
              {props.isPayment ? "" : <p>{lang.get("reconnect")}</p>}
            </div>
          ) : (
            <> {getStripeImage()}</>
          )}
          {onlinePayments?getEnableDisableButton(): ""}
        </div>
        {!onlinePayments? <div style={{width:"100%"}}> {getEnableDisableButton()} </div>: ""}
      </>
    );
  }

  return (
    <Grid style={{ width: "97%" }}>
      {renderContent()}
      {isModalOpen ? (
        <StripePaymentsForm
          handleIsModalOpen={handleIsModalOpen}
          isModalOpen={isModalOpen}
          showSmallMessage={props.showSmallMessage}
          setLoading={props.setLoading}
        />
      ) : (
        ""
      )}
    </Grid>
  );
}
