import React, { Component  } from 'react';
import XLSX from 'xlsx';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	DialogContentText,
	Select,
	NativeSelect,
	FormHelperText,
	FormControl,
} from '@material-ui/core';
import { IconButton, withStyles } from '@material-ui/core';
//----------------------------------------------------------------
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
// import Fab from '@material-ui/core/Fab';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import Lang from '../../lang';
import PropTypes from 'prop-types'
import theme from "../../Theme/Theme";

const lang = Lang.getInstance();

class ExcelPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			file: {},
			data: [],
			cols: [],
			header: [],
			showAlert: false,
			alertMessage: "",
			items: [],
			columns: [],
			selectedSheetToBeImported: 0,
			selectedRows: [],
			selectedColumns: [],
			renderAfterCheck: false,
			disableSelectedSheetToBeImported: false,
			sheetList: [],
			errorColumnVisible: -1,
			dataIsLoading: false,
		};
		this.handleFile = this.handleFile.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}
	/**
	 * Inporta fisierul
	 * @param {*} e 
	 */
	handleChange (e) {
		const files = e.target.files;
		if (files && files[0]) {
			this.setState({ file: files[0] });
			this.handleFile(files[0]);
		}
	}

	handleChangeAlert () {
		this.setState({ showAlert: !this.state.showAlert });
	}

	/**
	 * Parseaza fisierul
	 * @param {*} files 
	 */
	handleFile (files) {
		this.setState({ dataIsLoading: true });


		/* Boilerplate to set up FileReader */
		const reader = new FileReader();
		setTimeout(() => {
			var rABS = !!reader.readAsBinaryString;
			var extension = files.name.split(".");

			reader.onload = (e) => {
				var header = [];
				var columns = [];
				var counter = 0;
				var data, wb;
				if (extension[extension.length - 1] === "xlsx" || extension[1] === "ods") {
					/* Parse data */
					const bstr = e.target.result;

					try {
						wb = XLSX.read(bstr, { type: 'binary' });
					} catch (e) {
						this.setState({ showAlert: true, alertMessage: lang.get("noData") });
						return;
					}
					/* Get first worksheet */
					const wsname = wb.SheetNames[this.state.selectedSheetToBeImported];
					const ws = wb.Sheets[wsname];
					/* Convert array of arrays */
					data = XLSX.utils.sheet_to_json(ws, { header: 1 });

					// max length
					let maxLength = 0;
					for (const row of data) {
						maxLength = row.length > maxLength ? row.length : maxLength;
					}

					//----------create header----------
					for (counter; counter <= maxLength; counter++) {
						header.push(counter);
						columns[counter] = -1;
					}

					this.state.header.push(header);
					for (counter; counter < data.length; counter++) {
						if (data[counter].length === 0 || data[counter].length === 1) {
							data.splice(counter, 1);
							counter--;
						}
					}

					// fill in the gaps
					let fullData = []
					for (let i = 0; i < data.length; i++) {
						const row = data[i];
						fullData[i] = [];
						for (let j = 0; j < maxLength; j++) {
							fullData[i][j] = row[j] || "";
						}
					}

					/* Update state */
					this.setState({ sheetList: wb.SheetNames, data: fullData });
				}
				else {
					/* Parse data */
					var bstr = e.target.result;
					var splitTable = bstr.split("\n");
					var tableHeader = splitTable[0].split(",");

					for (counter = 0; counter <= tableHeader.length; counter++) {
						header.push(counter);
						columns[counter] = -1;
					}

					this.state.header.push(header);
					bstr = splitTable[0] + "\n" + bstr;
					try {
						wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA: true });
					} catch (e) {
						this.setState({ showAlert: true, alertMessage: lang.get("noData") });
						return;
					}
					/* Get first worksheet */
					const wsname = wb.SheetNames[0];
					const ws = wb.Sheets[wsname];

					/* Convert array of arrays */
					data = XLSX.utils.sheet_to_json(ws, { header: header });

					for (counter = 0; counter < data.length; counter++) {
						if (data[counter].length === 0 || data[counter].length === 1) {
							data.splice(counter, 1);
							counter--;
						}
					}

					/* Update state */
					this.setState({ data: data });

					let head = JSON.stringify(this.state.data[0]).split(/[:,]/);
					for (let i = 0; i <= 20; i += 2) {
						let sir = head.splice(i, 1)
						console.log(sir)
					}
				}

				var tableColumns = [];
				var tableColumns2 = [];
				tableColumns2 = this.getTableColumnsArray2(this.props.columns);
				tableColumns = this.getTableColumnsArray(this.props.columns);

				this.setState({ items: tableColumns2, columns: tableColumns, selectedColumns: columns, dataIsLoading: false });

			};


			if (rABS) {
				if (files != null) {
					reader.readAsBinaryString(files);
				} else {
					this.setState({ showAlert: !this.state.showAlert, alertMessage: lang.get("noData") });
				}
			} else {
				reader.readAsArrayBuffer(files);
			}
		}, 1000);
	}

	getTableColumnsArray2 (tableColumns) {
		var array = [];
		Object.entries(tableColumns).map(([key, value]) => (
			array.push({
				id: `${key}`,
				primary: `${value}`,
			})
		));
		return array;
	}

	getTableColumnsArray (tableColumns) {
		var array = [];
		Object.entries(tableColumns).map(([key, value]) => (
			array.push({
				label: `${value}`,
				name: `${value}`,
			})
		));
		return array;
	}

	/**
	* Face schimbarea paginii care trebuie importata
	* @param {*} event 
	*/
	handleChangeImportedSheet = (event) => {

		this.setState({ selectedSheetToBeImported: event.target.value });
		this.handleFile(this.state.file);
	};

	/**
	 * Deletes and adds selected rows in an array; needs the index of the row
	 * @param {*} key 
	 */
	handleClick (key) {
		var found = this.state.selectedRows.indexOf(key);
		if (found === -1)
			this.state.selectedRows.push(key);
		else {
			var index = this.state.selectedRows.indexOf(key);
			if (index !== -1)
				this.state.selectedRows.splice(index, 1);
		}
		this.setState({ renderAfterCheck: !this.state.renderAfterCheck });
	}

	/**
	 * Sterge datele din tabel
	 */
	deleteUnwantedRows () {
		try {
			if (this.state.selectedRows.length > 0) {
				var selectedRowsCopy = this.state.selectedRows.sort(function (a, b) { return b - a });
				selectedRowsCopy.forEach(element => {
					this.state.data.splice(element, 1);
				});
				this.setState({ renderAfterCheck: !this.state.renderAfterCheck, selectedRows: [] });
			}
		} catch (err) { }
	}

	/**
	 * Verifica daca randul care trebuie randat este selectat
	 * @param {} key 
	 */
	isRowSelected (key) {
		var found = this.state.selectedRows.indexOf(key);
		if (found === -1)
			return false;
		return true;
	}
	/**
	 * se selecteaza toate randurile
	 * @param {*} event 
	 */
	selectAll (){
		this.state.data.map((d,index)=>{
			return this.handleClick(index);
		})
	}
	/**
	 * se selecteaza sau schimba numele coloanelor
	 * @param {*} event 
	 */
	handleChangeColumnName = (event) => {
		var key = event.target.id;
		var value = event.target.value;
		var check = this.state.selectedColumns.indexOf(value);
		var columns = this.state.selectedColumns;
		columns[key] = value;
		if (check >= 0)
			columns[check] = -1;
		this.setState({ selectedColumn: columns, errorColumnVisible: -1 });
		//this.setState({ errorColumnVisible: key });
	}

	/**
	 * trimite datele la wizard pentru a se face importarea, 
	 * se apeleaza doar cand se apasa pe Save
	 */
	sendDataToWizard () {
		var tableData = [];
		var tableDataRow = {};
		for(let index of this.state.selectedRows){
			const row = this.state.data[index];
			for (let j = 0; j < this.state.selectedColumns.length; j++) {
				const selected = this.state.selectedColumns[j];
				if(selected < 0) continue;
				const value = row[j];
				tableDataRow[selected] = value;
			}
			tableData.push(tableDataRow);
			tableDataRow = {};
		}
		var data = { data: tableData, selectedColumns: this.state.selectedColumns, header: this.state.header };
		this.props.getTableDataFunction(data);
	}


	render () {

		const SheetJSFT = ['xlsx', 'xlsb', 'xlsm', 'xls', 'csv', 'txt', 'ods']
			.map(function (x) {
				return '.' + x;
			})
			.join(',');

		const classes = this.props.classes;

		return (
			<div>
				<div>
					{this.state.selectedRows.length > 0 ?
						<Tooltip title="Delete">
							<IconButton
								variant="contained" color="primary"
								onClick={() => this.deleteUnwantedRows()}>
								<DeleteIcon variant="contained" />
							</IconButton>
						</Tooltip> : null}
					<Button style={{ marginRight: 5 ,backgroundColor: theme.palette.primary?.main, color: theme.palette.textColorSecondary?.main}} variant="contained" >
						<input
							type="file"
							className="form-control"
							id="file"
							accept={SheetJSFT}
							onChange={this.handleChange}
						/>
					</Button>
					<FormControl >
						<NativeSelect
							native
							value={this.state.selectedSheetToBeImported}
							onChange={this.handleChangeImportedSheet}
							name="name"
							style={{ marginLeft: 10 }}
						>
							{this.state.sheetList.map((row, key) =>
								<option value={key}>{row}</option>
							)}

						</NativeSelect>
						<FormHelperText>{lang.get("page")}</FormHelperText>
					</FormControl>
					<Button
						style={{ marginLeft: 10 }}
						variant="contained"
						color="primary"
						onClick={() => this.sendDataToWizard()}
						className={classes.button}
					>
						{lang.get("import")}
					</Button>
				</div>

				<div style={{ marginTop: 20, marginRight: 20, paddingRight: 20 }}>
					<Table >
						<TableBody>
							<TableRow>
							{this.state.data[0] !== undefined?
							<Checkbox
									onClick={this.selectAll.bind(this)}
									style={{color:theme.palette.header?.main, marginLeft:"4px", marginTop:"10px"}}
							/>:""}

								{/* <TableCell padding="checkbox" style={{ width: 20 }}><Button style={{display: "inline-block"}}>Select all</Button></TableCell> */}
								{this.state.data[0] !== undefined ? Object.entries(this.state.data[0]).map(([key, value], index) => (
									<TableCell style={{padding:10}}>
										<Select
											fullWidth
											native
											value={this.state.selectedColumns[index]}
											id={key}
											onChange={this.handleChangeColumnName}
										>
											<option aria-label="None" value={0} >{lang.get("select")}</option>
											{this.state.items.map((item, index) => (
												<option value={item.id}>{item.primary}</option>
											))}
										</Select>
										{this.state.errorColumnVisible === key ?
											<Alert severity="error" className={classes.toolBar}>
												{lang.get("alreadySelected")}
											</Alert> : null}
									</TableCell>
								)) : null}
							</TableRow>
							{this.state.data.map((row, key) => (
								<TableRow
									hover
									onClick={() => this.handleClick(key)}
									role="checkbox"
									tabIndex={-1}
									key={key}
								>
									<TableCell padding="checkbox" style={{ width: 20 }}>
										<Checkbox
											checked={this.isRowSelected(key)}
											style={{color:theme.palette.header?.main }}
										/>
									</TableCell>
									{Object.entries(row).map(([key, value]) => (

										<TableCell classes={{ root: classes.cell }} >{value}</TableCell>
									))}
								</TableRow>
							))}
						</TableBody>
					</Table>

					{this.state.dataIsLoading ?
						<div style={{
							alignContent: 'center',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}>
							<CircularProgress style={{
								marginBottom: 20,
								padding: 10,
								marginRight: 20,
							}} />
							<FormHelperText >{lang.get("loading")}</FormHelperText>
						</div> : null}
				</div>
				<Dialog
					open={this.state.showAlert}
					onClose={this.props.onChange}
					aria-labelledby="confirm"
					aria-describedby="confirm dialog"
				>
					<DialogTitle id="alert-dialog-title">{lang.get("warning")}</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							{this.state.alertMessage}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleChangeAlert.bind(this)} id="ok" color="primary">
							"OK"
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}
/**
 *
 * Page style
 */
const styles = theme => ({
	cell: {
		padding: 5,
		borderRight:"1px solid #ddd"
	},
	root: {
		width: '100%',
	},
	paper: {
		paddingTop: 26,
		paddingBottom: 26,
		marginTop: '5%',
		marginLeft: '3%',
		width: '90%',
		display: 'flex',
		flexDirection: 'column',
		alignContent: 'center',
		[theme.breakpoints.down('md')]: {
			marginLeft: 0,
			width: '90%',
			maxWidth: '100%',
		},
	},
	toolBar: {
		paddingRight: 20,
		paddingLeft: 20,
		alignItems: "alignItems: 'flex-end'",
		background: '#E4E4E4',
		overflow: 'hidden',
		[theme.breakpoints.down('sm')]: {
			paddingLeft: 0,
			paddingRight: 0,
			paddingTop: 10,
		},
	},
	container: {
		display: 'flex',
		justifyContent: 'center',
		flexGrow: 1,
		overflow: 'hidden',
		backgroundSize: 'cover',
		backgroundPosition: '0 400px',
	},
	button: {
		marginRight: theme.spacing(1),
		color:theme.palette.textColorSecondary?.main
	},
	instructions: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
});

ExcelPage.propTypes = {
	columns: PropTypes.object.isRequired
}

export default withStyles(styles, { withTheme: true })(ExcelPage);