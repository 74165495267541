import React from 'react';
import { Modal, Paper, withStyles, TextField } from '@material-ui/core';
import FormComponent from '../Forms/FormComponent';
import PropTypes from 'prop-types';
import Lang from '../lang';
import { Fade } from '@material-ui/core';
import HierarchicalList from '../Components/HierarchicalList';
import { Category } from '../Models/Models';

const styles = () => ({
    modal: {
        "padding": 10,
        "display": 'flex',
        "alignItems": 'center',
        "justifyContent": 'center',
    },
    paper: {
        display: "block",
        padding: 20,
        minWidth: 300,
        width: 600,
    },
    field: {
        display: "block"
    },
    categories: {
        maxHeight: 500,
        overflow: "scroll"
    }
});

class NewCategory extends FormComponent {

    constructor(props) {
        super(props);
        this.state.categories = props.categories.slice();
        this.state.categories.forEach((categ)=>categ.selected = false);
    }

    categorySelect (e, category) {
        this.selectedCategory = category;
        let newModel = Object.assign({}, this.state.model);

        let categs = this.state.categories.slice();
        let categ = this.props.findCateg(categs, category.id);
        categ.selected = categ.selected ? false : true;
        newModel.fk_ParentCategId = categ.id;

        this.setState({ categories: categs, model: newModel });
    }


    render () {
        const classes = this.props.classes;
        const lang = Lang.getInstance();

        if (!this.props.open) {
            return null;
        }
        // if(this.state.model.id)
        // this.props.onClose();
        return (
            <Modal
                className={classes.modal}
                open={this.props.open}
                onClose={() => this.props.onClose()}
                aria-labelledby="modal"
                aria-describedby="modal"
            >
                <Fade in={this.props.open}>
                    <Paper className={classes.paper}>
                        <h3>{lang.get("category")}</h3>
                        <form onSubmit={this.submit.bind(this)}>
                            <TextField
                                name="name"
                                value={this.state.model.name}
                                onChange={this.handleInputChange.bind(this)}
                                required
                                fullWidth
                                label={lang.get("name")}
                                className={classes.field}
                            />
                            <TextField
                                name="description"
                                value={this.state.model.description}
                                onChange={this.handleInputChange.bind(this)}
                                multiline
                                fullWidth
                                label={lang.get("description")}
                                className={classes.field}
                            />
                            <br />
                            {this.state.model.id === 0 ?
                                <>
                                    <label>{lang.get("parent")}</label>
                                    <div className={classes.categories}>
                                        <HierarchicalList
                                            models={this.state.categories}
                                            childrenLabel="children_categ"
                                            foreignKey="fk_ParentCategId"
                                            onClick={() => { }}
                                            onSelect={this.categorySelect.bind(this)} />
                                    </div>
                                </> : 
                                null
                            }

                            <br />
                            {super.render()}
                        </form>
                    </Paper>
                </Fade>
            </Modal>
        );
    }
}

NewCategory.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    categories: PropTypes.array,
    findCateg: PropTypes.func
}

export default withStyles(styles)(NewCategory);