import React from "react";
import CachedDataSingleton from "../../../cachedDataSingleton";
import Lang from "../../../lang";
import {
  AccountBalanceOutlined,
  BuildOutlined,
  BusinessCenterOutlined,
  HelpOutlineOutlined,
  Input
} from "@material-ui/icons";
import { Grid, InputLabel, makeStyles, Typography } from "@material-ui/core";
import { monthsName, overviewFilteredByRole } from "../../../Utils/Utils";
import DashboardOverview from "./DashboardOverview";
import theme from "../../../Theme/Theme";
type DocumentsOverviewProps = {};

const useStyles = makeStyles((theme)=>({
  icon: {
    marginRight: 20,
    marginBottom: 10,
    marginLeft: "auto",
    display: "flex",
    justifyContent: "right",
    alignItems: "right",
    color: "#ffff"
  },
  
  wrapper: { 
    display: "flex",
    width:"100%",
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    } 
  },
  componentTitle: { fontSize: 20 }
}));

const styles={
  select: { marginLeft: 10, marginRight: 30, color: "#ffff" },
  box: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingRight: 10,
    paddingLeft: 10,
    margin: "0 20px 0 20px 0",
    justifyContent: "flex-start",
    border: "1px solid",
    borderRadius: 5,
    borderColor: "#bbb",
    minWidth: "100%"
  },
  label: {
    marginTop: 10,
    marginLeft: "auto",
    marginRight: 20,
    justifyContent: "right",
    alignItems: "right",
    display: "flex",
    color: "#ffff"
  },
  innerLabel: {
    marginTop: 10,
    marginLeft: 20,
    justifyContent: "left",
    alignItems: "left",
    display: "flex",
    color: "#ffff",
  },
  titleLabel: {
    marginTop: 10,
    marginLeft: 20,
    color: "#ffff",
    fontSize:"12pt"
  },
  div:{
    display: "flex",
  },
  euroIcon: { fontSize: "small", marginLeft: 5 }
};

const lang = Lang.getInstance();

export default function DocumentsOverview(props: DocumentsOverviewProps) {
  const classes = useStyles();
  const cachedData = CachedDataSingleton.getInstance();
  const overview = cachedData?.cachedData?.overview
    ? cachedData.cachedData.overview
    : [];

  /**
   *
   * @param option
   * @returns Returns a icon
   */
  function getIcon(option: string) {
    switch (option) {
      case "estimates":
        return <BusinessCenterOutlined className={classes.icon} />;
      case "costs":
        return <AccountBalanceOutlined className={classes.icon} />;
      case "invoices":
        return <Input className={classes.icon} />;
      case "orderTickets":
        return <BuildOutlined className={classes.icon} />;
      default:
        return <HelpOutlineOutlined className={classes.icon} />;
    }
  }

  /**
   * Return a color depending on the option given
   * or a default color if there is no corresponding option.
   */
  function getBackgroundColor(option: string) {
    switch (option) {
      case "estimates":
        return theme.palette.orangeColor?.main;
      case "costs":
        return theme.palette.pinkColor?.main;
      case "invoices":
        return theme.palette.greenColor?.main;
      case "orderTickets":
        return theme.palette.blueColor?.main;
      default:
        return theme.palette.errorColor?.main;
    }
  }

  /**
   *
   */
  function renderComponents() {
    const newOverview = overviewFilteredByRole(overview);
    const o = Object.keys(newOverview).map(function (key, index) {
      if (typeof newOverview[key] === "object" && lang.get(key) !== undefined) {
        return (
          <Grid
            key={index}
            item
            lg={3}
            classes={{root:classes.wrapper}}
            
          >
            <DashboardOverview
              title={lang.get(key)}
              months={(newOverview[key] as any).months}
              totalValue={(newOverview[key] as any).total}
              icon={() => {
                return getIcon(key);
              }}
              style={{
                titleLabel: {...styles.titleLabel},
                select: { ...styles.select },
                box: {
                  ...styles.box,
                  backgroundColor: getBackgroundColor(key)
                },
                label: { ...styles.label },
                innerLabel: { ...styles.innerLabel },
                icon: { ...styles.euroIcon },
                div: { ...styles.div },
                optionColor: { backgroundColor: getBackgroundColor(key) }
              }}
            />
          </Grid>
        );
      } else {
        return null;
      }
    });
    return o;
  }

  return (
    <>
      <Typography className = {classes.componentTitle}>
        {`${lang.get("overview")} 
          ${lang.get("from")} 
          ${lang.get("january")} 
          ${lang.get("secondTo")}
          ${lang.get(
          monthsName()[
          overview.invoices ? overview.invoices?.months.length - 1 : 0
          ]
        )}:`}
      </Typography>
      <Grid container spacing={2}>
        {renderComponents()}
      </Grid>
    </>
  );
}
