import React from 'react';
import PropTypes from 'prop-types';
import Lang from '../lang';
import { Address } from '../Models/Models';

const lang = Lang.getInstance();

/** document styles **/
const style1 = {
    headerWrapper: {
        marginRight: -20,
        fontSize: "9pt"
    },
    invoiceText: {
        fontSize: "22px",
        margin: 0,
        textTransform: "uppercase"
    },
    logoWrapper: {
        width: "48%",
        display: "inline-table",
        marginLeft: 10,
        verticalAlign: "top"
    },
    companyWrapper: {
        width: "48%",
        display: "inline-table",
        marginRight: 15,
        verticalAlign: "top",
        fontSize:"11pt"
    },
    docInvoiceInfoWrapper: {
        width: "48%",
        display: "inline-table",
        marginLeft: 5,
        verticalAlign: "top"
    },
    clientWrapper: {
        width: "48%",
        display: "inline-table",
        marginRight: -10,
        verticalAlign: "top"
    },
    logo: {
        maxHeight: 100
    },
    clear: {
        clear: "both",
        borderBottom: "10px solid #ccc",
        marginBottom: 20,
        marginTop: 15,
        marginRight: 15
    }
};


export default function SimpleHeader (props) {
    const company = props.company;
    let styles = style1;
    const protocol = window.location.href.split("/")[0];

    return (
        <div style={styles.headerWrapper}>

            <div style={styles.logoWrapper}>
                {
                     (company.logo && company.logo!=='undefined') ?
                        <img src={`${protocol}//${document.location.host}/${company.logo}`} alt="logo" style={styles.logo} /> :
                        <p style={styles.invoiceText}>{company.name || ""}</p>
                }
            </div>
            <div style={styles.companyWrapper}>
                <p><strong>{company.name || ""}</strong><br />
                    <strong>{lang.get("vatNo")}</strong>: {company.vatNo ? company.vatNo:""}<br />
                    <strong>{lang.get("companyRegNo")}</strong>: {company.companyRegNo || ""}<br />
                    <strong>{lang.get("address")}</strong>: {Address.stringify(company.address)}<br />
                    <strong>{lang.get("bankName")}</strong>: {company.bankName}<br />
                    <strong>{lang.get("bankAccount")}</strong>: {company.bankAccount}</p>
            </div>
            <div style={{ ...styles.clear, borderColor: props.color || "#ccc" }}></div>
        </div>
    );
}

SimpleHeader.propTypes = {
    company: PropTypes.object.isRequired,
    color: PropTypes.string,
}