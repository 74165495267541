import React,{Component} from 'react';
import Lang from '../../lang';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import PropTypes from 'prop-types';

class BankInfo extends Component{

    /**
     * constructor
     * @param {Object} props 
     */
    constructor(props){
        super(props);
        this.lang = Lang.getInstance();
    }
       
    /**
     * render method
     */
    render(){
        
        return(
            <div style={{padding:20}}>
                <ValidatorForm onSubmit={()=>{}}>
                    {/** bankAccount  */}
                    <TextValidator
                    label = {this.lang.get("bankAccount")}
                    fullWidth
                    name="bankAccount"
                    value={this.props.model.bankAccount}
                    onChange={this.props.onChange}
                    validators={['required']} 
                    errorMessages={[this.lang.get("fieldRequired")]} />

                    {/** bankName */}
                    <TextValidator
                    label = {this.lang.get("bankName")}
                    fullWidth
                    name="bankName"
                    value={this.props.model.bankName}
                    onChange={this.props.onChange}
                    validators={['required']} 
                    errorMessages={[this.lang.get("fieldRequired")]} />

                        {/** bankBic */}
                        <TextValidator
                    label = {this.lang.get("bankBic")}
                    fullWidth
                    name="bankBic"
                    value={this.props.model.bankBic}
                    onChange={this.props.onChange}
                    validators={['required']} 
                    errorMessages={[this.lang.get("fieldRequired")]} />

                    {/** bankAccount 2  */}
                    <TextValidator
                    label = {this.lang.get("bankAccount")+" 2"}
                    fullWidth
                    name="bankAccountSecondary"
                    value={this.props.model.bankAccountSecondary}
                    onChange={this.props.onChange}
                    validators={['required']} 
                    errorMessages={[this.lang.get("fieldRequired")]} />

                    {/** bankName 2 */}
                    <TextValidator
                    label = {this.lang.get("bankName")+" 2"}
                    fullWidth
                    name="bankNameSecondary"
                    value={this.props.model.bankNameSecondary}
                    onChange={this.props.onChange}
                    validators={['required']} 
                    errorMessages={[this.lang.get("fieldRequired")]} />

                    {/** bankBic2 */}
                    <TextValidator
                    label = {this.lang.get("bankBic")+" 2"}
                    fullWidth
                    name="bankBicSecondary"
                    value={this.props.model.bankBicSecondary}
                    onChange={this.props.onChange}
                    validators={['required']} 
                    errorMessages={[this.lang.get("fieldRequired")]} />
                </ValidatorForm>
            </div>
        );
    }
}

BankInfo.propTypes = {
    model: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired
};


export default BankInfo;