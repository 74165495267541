import {
  Grid,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Paper,
  Toolbar,
  Tooltip,
} from "@material-ui/core";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import clsx from "clsx";
import Lang from "../../lang";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { useState } from "react";
import WidgetsDialog from "./WidgetsDialog";
import ZoomOutMapIcon from "@material-ui/icons/ZoomOutMap";

export type ContainerProps = {
  width: any;
  children?: any;
  edit?: boolean;
  style?: object;
  sections?: any;
  removeContainer: Function;
  id?: string;
  changeSize: (size: number) => void;
  changeWidget: (widget: JSX.Element) => void;
};

const lang = Lang.getInstance();

const useStyles = makeStyles((theme) => ({
  menu: {
    position: "absolute",
    top: -10,
    left: "47%",
    width: 80,
    opacity: 0,
    transition: "opacity .5s ease-out;",
  },
  toolBarPaper: {
    padding: 0,
    zIndex: 100,
    background: "#444",
    "&:hover": {
      zIndex: 110,
    },
  },
  container: {
    position: "relative",
  },
  containerOnlinePayments:{
      paddingRight:"0px !important",
  },
  containerEdit: {
    "&:hover": {
      boxShadow: "0 0 5px #555",
    },
    "&:hover > #menu": {
      opacity: 1,
    },
  },
}));

export default function Container(props: ContainerProps) {
  const classes = useStyles();
  const [widgetDialogOpen, setWidgetModalOpen] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState<any>(null);

  /**
   *
   * @param widget
   */
  const closeWidgetModal = (widget: JSX.Element | null) => {
    setWidgetModalOpen(false);
    if (widget) {
      props.changeWidget(widget);
    }
  };

  return (
    
      <Grid
        item
        md={props.width}
        sm={12}
        xs={12}
        id="container"
        className={clsx(
          classes.container,
          { [classes.containerEdit]: props.edit },
        )}
        style={props.style || {}}
      >
        {props.children}
        {props.edit ? (
          <>
            <Toolbar
              className={clsx({ [classes.menu]: props.edit })}
              id="menu"
              disableGutters
              variant="dense"
            >
              <Paper elevation={3} classes={{ root: classes.toolBarPaper }}>
                <Tooltip title={lang.get("size")}>
                  <IconButton
                    size="small"
                    onClick={(e) => setMenuAnchor(e.target)}
                  >
                    <ZoomOutMapIcon style={{ color: "#ccc" }} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Widgets">
                  <IconButton
                    size="small"
                    onClick={() => setWidgetModalOpen(true)}
                  >
                    <ViewModuleIcon style={{ color: "#ccc" }} />
                  </IconButton>
                </Tooltip>
                <Tooltip title={lang.get("remove")}>
                  <IconButton
                    size="small"
                    onClick={(e) => props.removeContainer()}
                  >
                    <HighlightOffIcon color="secondary" />
                  </IconButton>
                </Tooltip>
              </Paper>
              <Menu
                anchorEl={menuAnchor}
                keepMounted
                open={Boolean(menuAnchor)}
                onClose={() => setMenuAnchor(null)}
              >
                <MenuItem
                  onClick={() => {
                    props.changeSize(12);
                    setMenuAnchor(null);
                  }}
                >
                  1
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    props.changeSize(8);
                    setMenuAnchor(null);
                  }}
                >
                  3/4
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    props.changeSize(6);
                    setMenuAnchor(null);
                  }}
                >
                  1/2
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    props.changeSize(4);
                    setMenuAnchor(null);
                  }}
                >
                  1/3
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    props.changeSize(3);
                    setMenuAnchor(null);
                  }}
                >
                  1/4
                </MenuItem>
              </Menu>
            </Toolbar>

            <WidgetsDialog
              open={widgetDialogOpen}
              onClose={(selectedWidget: JSX.Element | null) =>
                closeWidgetModal(selectedWidget)
              }
            />
          </>
        ) : null}
      </Grid>
  );
}
