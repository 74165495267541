import { useState } from 'react';
import { AccordionSummary, Button, Grid, makeStyles, Paper } from '@material-ui/core';
import { TimeTracking } from '../../Models/Models';
import Lang from "../../lang";
import moment from 'moment';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import Config from '../../Utils/Config';
import { TimeTrakingOptionEnum } from '../../Pages/TimeTrackingComponent';
import DeleteIcon from '@material-ui/icons/Delete';
import ConfirmDialog from '../ConfirmDialog';
import { Link } from 'react-router-dom';
import { LocalUrlEnum, addHms } from '../../Utils/Utils';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import NoteOutlinedIcon from '@material-ui/icons/NoteOutlined';

const lang = Lang.getInstance();

const useStyles = makeStyles(theme=>({
    employeeLink:{
        color:theme.palette.header?.main
    },
    summary:{
        padding:"0px"
    }
}));

type ActiveTimekeepingProps = {
    activeTimetrackings: Array<TimeTracking>;
    employees: Array<any>;
    handleStopTimeChange: (at: TimeTracking, date: MaterialUiPickersDate) => void;
    stopTimeTracking: (at: TimeTracking) => void;
    timekeepingOption: string;
    onDelete?: (at: TimeTracking) => void;
};

const ActiveTimetrackings = (props: ActiveTimekeepingProps) => {
    const [confirmDelete, setConfirmDelete] = useState(null as any);
    // const [nrTotalDeOre, setNrTotalDeOre] = useState({hour:0, minutes:0,seconds:0});
    let nrTotalDeOre={
        hour:0, 
        minutes:0,
        seconds:0
    };
    let pretTotal=0;
    const classes = useStyles();

    /**
     * 
     * @param result 
     */
    function onConfirmResult(confirm: boolean) {
        if (confirm) {
            if (props.onDelete) {
                props.onDelete(confirmDelete);
                setConfirmDelete(null as any);
            }
        } else {
            setConfirmDelete(null as any);
        }
    }

    /**
     * 
     * @param at 
     * @param start 
     * @param end 
     */
    function renderPerHour(at: TimeTracking, start: string, end: string | null) {
        const employee = props.employees.find((emp)=> emp.id === at.fk_PersonId);
        return (
            <div key={at.id} style={{ borderBottom: "1px solid #ccc", paddingTop: 15, paddingBottom: 15 }}>
                <Grid container spacing={4}>
                    <Grid item md={5}>
                        {employee ? 
                        <Link 
                            to={employee.isEmployee? `${LocalUrlEnum.employees}/${employee.fk_SubcontractorId}`:`${LocalUrlEnum.subcontractors}/${employee.fk_SubcontractorId}`}
                            className={classes.employeeLink}
                        >
                                {at.name}
                            </Link>  :
                         at.name 
                        }
                        <em>({at.clientName}) 
                            {at.folderTitle ? <strong> &nbsp; <FolderOpenIcon style={{top:6, position:"relative"}}/> - {at.folderNo} {at.folderTitle} </strong> : null}
                            {at.orderTicketTitle ? <strong> &nbsp; <NoteOutlinedIcon style={{top:6, position:"relative"}}/> - {at.orderTicketNo} {at.orderTicketTitle} </strong> : null}
                        </em>
                        
                    </Grid>
                    <Grid item md={2}>
                        {lang.get("start")}: {start}
                    </Grid>
                    <Grid item md={2}>
                        {lang.get("end")}: {end}
                    </Grid>
                    <Grid item md={3}>
                        <div style={{ display: "flex" }}>
                            {end ? null :
                                <>
                                    <KeyboardDateTimePicker
                                        variant="inline"
                                        ampm={false}
                                        label={lang.get("stop")}
                                        value={at.stopTime ? at.stopTime : moment()}
                                        onChange={(muiDate: MaterialUiPickersDate) => props.handleStopTimeChange(at, muiDate)}
                                        format={Config.momentEUDateFormatWithTime}
                                    />
                                    <Button
                                        variant="outlined"
                                        onClick={() => props.stopTimeTracking(at)}
                                    >
                                        {lang.get("stop")}
                                    </Button>
                                </>
                            }
                        </div>
                    </Grid>
                </Grid>
            </div>
        );
    }

    function renderPerDay(at: TimeTracking, date: string) {
        return (
            <div key={at.id} style={{ borderBottom: "1px solid #ccc", paddingTop: 15, paddingBottom: 15 }}>
                <Grid container spacing={4}>
                    <Grid item md={7}>
                        {at.name} <em>({at.clientName}) {at.folderTitle ? <strong> - {at.folderTitle} </strong> : null}</em>
                    </Grid>
                    <Grid item md={3}>
                        {lang.get("date")}: {date}
                    </Grid>
                    <Grid item md={2}>
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Button variant="outlined" style={{ padding: 10 }}
                                onClick={() => setConfirmDelete(at)}
                            >
                                <DeleteIcon /> &nbsp;
                            {lang.get("delete")}
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            </div>
        );
    }
    function renderSumnary()
    {
        props.activeTimetrackings.forEach((at:TimeTracking)=>{
            if(at.elapsedTime)
            { 
                const date = at.elapsedTime.split(':');
                [nrTotalDeOre.hour,nrTotalDeOre.minutes,nrTotalDeOre.seconds ] = addHms([nrTotalDeOre.hour,nrTotalDeOre.minutes,nrTotalDeOre.seconds ],date);
                // nrTotalDeOre.hour =nrTotalDeOre.hour+parseInt(date[0]);
                // nrTotalDeOre.minutes=nrTotalDeOre.minutes +parseInt(date[1]);
                // nrTotalDeOre.seconds=nrTotalDeOre.seconds+parseInt(date[2]);
                const employee = props.employees.find((employee) => employee.id === at.fk_PersonId)
                pretTotal = pretTotal+ employee.pricePerHour * parseInt(date[0]);

            }
        })
        const showSumnary = <div style={{fontSize:"13px"}}> 
            <p>{lang.get("totalHours")}:  {nrTotalDeOre.hour}:{nrTotalDeOre.minutes}:{nrTotalDeOre.seconds}</p>
            <p>{lang.get("totalPrice")} / {lang.get("perDay")}:  {pretTotal}</p>
        </div>
        return showSumnary;
    }
    return (
        <Paper style={{ padding: 30 }}>
            {props.activeTimetrackings.map((at: TimeTracking) => {
                const positions = JSON.parse(at.position);
                if (positions.length === 0) return null;

                if(!positions || !positions[0] ||  !positions[0].datetime) return null;

                const start = moment.utc(positions[0].datetime*1000)
                    .format(Config.momentEUDateFormatWithTime);

                const crtDate = moment
                    .unix(positions[0].datetime)
                    .format(Config.momentEUDateFormat);
                let end = null;
                if (positions.length % 2 === 0) {
                    end = moment.utc(positions[positions.length - 1].datetime*1000)
                        .format(Config.momentEUDateFormatWithTime);
                }
                if (props.timekeepingOption === TimeTrakingOptionEnum.perHour) {
                    return renderPerHour(at, start, end);
                }
                return renderPerDay(at, crtDate);
            }
            )}
            <AccordionSummary className={classes.summary} >{renderSumnary()}</AccordionSummary>
            <ConfirmDialog open={confirmDelete ? true : false} text={lang.get("deleteItem")} onResult={onConfirmResult} />
        </Paper>
    );
};

export default ActiveTimetrackings;