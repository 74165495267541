import React, { Fragment } from 'react';
import {
	createStyles,
	Theme,
	AppBar,
	Tabs,
	Tab,
	withStyles,
	Paper,
	Modal,
	DialogActions,
	Button
} from '@material-ui/core';
import Lang from '../lang';
import SwipeableViews from 'react-swipeable-views';
import TabPanel from '../Components/TabPanel';
import SearchBar from '../Components/SearchBar';
import { UrlEnum, get } from '../Utils/Utils';
import { Package, Service, Article } from '../Models/Models';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import NoDataFound from '../Components/NoDataFound';
import theme from '../Theme/Theme';

const styles = (theme: Theme) =>
	createStyles({
		modal: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			zIndex: 1000
		},
		paperWrapper: {
			height: 800,
			width: 800,
			position: "relative",
			maxWidth: 800,
			[theme.breakpoints.down('md')]: {
				maxWidth: 1000,
				width: 800,
				height: '85%',
			},
			[theme.breakpoints.down('sm')]: {
				maxWidth: 1000,
				width: '100%',
				height: '100%',
			},
		},
		swipeable: {
			height: 'calc(100% - 95px)',
			overflow: 'auto',
			'& >div': {
				height: '100%',
			},
		},
		packages: {
			marginTop: 0,
			paddingLeft: 0,
			'& li': {
				listStyle: 'none'
			},
			'& li a': {
				display: 'block',
				width: '100%',
				height: '100%',
				padding: 10,
				borderBottom: '1px solid #ccc',
				transition: "background 0.5s ease",
			},
			'& li a:hover': {
				background: "#f5f5f5"
			},
		},
		item: {
			opacity: 1,
			transition: "opacity 200ms ease-in !important"
		},
		itemClicked: {
			opacity: 0.01,
			transition: "opacity 200ms ease-in !important"
		},
	});

const lang = Lang.getInstance();

type SelectServicesProps = {
	theme: Theme;
	open: boolean;
	onClose: (event: any) => void;
	classes: any;
	onSelectPackage: (event: React.MouseEvent, pkg: Package) => void;
	onSelectService: (event: React.MouseEvent, service: Service) => void;
	onSelectArticle: (event: React.MouseEvent, article: Article) => void;
};

type SelectServicesState = {
	selectedTab: number;
	serviceSearch: string;
	articleSearch: string;
	packages: Array<Package>;
	services: Array<Service>;
	articles: Array<Article>;
};

class SelectServices extends React.Component<SelectServicesProps, SelectServicesState> {
	theme: Theme;

	constructor(props: SelectServicesProps) {
		super(props);
		this.state = {
			selectedTab: 0,
			serviceSearch: '',
			articleSearch: '',
			packages: [],
			services: [],
			articles: [],
		};

		this.theme = this.props.theme;
	}

	componentDidMount() {
		this.getServiceServices();
		this.getServicePackages();
	}

	/**
	 * get packages from server
	 */
	private async getServicePackages() {
		const response = await get(UrlEnum.servicePackages + '/0/1');
		if (response.errors) return;
		this.setState({ packages: response });
	}
	private async getServiceServices() {
		const response = await get(UrlEnum.services + '/latest');
		if (response.errors) return;
		this.setState({ services: response});
	}

	/**
	 *
	 * @param event
	 * @param newValue
	 */
	private handleTabChange(event: any, newValue: any) {
		this.setState({ selectedTab: newValue });
	}

	/**
	 * change search service
	 * @param event React.ChangeEvent<HTMLInputElement>
	 */
	private async onServiceSearchChange(event: React.ChangeEvent<HTMLInputElement>) {
		const val = event.target.value;
		this.setState({ serviceSearch: val });
		if (val.length > 2) {
			const response = await get(UrlEnum.services + '/' + val + '/0');
			if (!response.errors && response instanceof Array) {
				this.setState({ services: response });
			}
		}
	}

	/**
	 * change search service
	 * @param event React.ChangeEvent<HTMLInputElement>
	 */
	private async onArticleSearchChange(event: React.ChangeEvent<HTMLInputElement>) {
		const val = event.target.value;
		this.setState({ articleSearch: val });
		if (val.length > 2) {
			const response = await get(UrlEnum.articles + '/filtered/' + val);
			if (!response.errors && response instanceof Array) {
				this.setState({ articles: response });
			}
		}
	}

	render() {
		const { classes } = this.props;
		return (
			<Fragment>
				<Modal
					aria-labelledby="selectServicesModal"
					open={this.props.open}
					onClose={this.props.onClose}
					className={classes.modal}
				>
					<Paper className={classes.paperWrapper} classes={{ root: classes.paperRoot }}>
						<AppBar position="static" color="default">
							<Tabs
								value={this.state.selectedTab}
								onChange={this.handleTabChange.bind(this)}
								indicatorColor="primary"
								textColor="primary"
								variant="fullWidth"
								aria-label="full width tabs example"
							>
								<Tab label={lang.get('services')} aria-controls="full-width-tabpanel-serivces" />
								<Tab label={lang.get('articles')} aria-controls="full-width-tabpanel-articles" />
								<Tab label={lang.get('packages')} aria-controls="full-width-tabpanel-packages" />
							</Tabs>
						</AppBar>
						<SwipeableViews
							axis={this.theme.direction === 'rtl' ? 'x-reverse' : 'x'}
							index={this.state.selectedTab}
							className={classes.swipeable}
							
						>
							<TabPanel value={0} index={0} dir={this.theme.direction} >
								<div>
									<SearchBar
										onChange={this.onServiceSearchChange.bind(this)}
										value={this.state.serviceSearch}
										onSubmit={(e) => { e.preventDefault(); return false; }}
										styles={{
											inputRoot: {
												width: '100%',
											},
											inputInput: {
												borderBottom: '1px solid #ccc',
												paddingLeft: 40,
												marginBottom: '5px'
											},
										}}
									/>
									{this.state.services.length ?
										<ul className={classes.packages}>
											<TransitionGroup>
												{this.state.services.map((service, index) => (
													<CSSTransition
														key={index}
														timeout={300}
														classNames="fade"
													>
														<li key={index} >
															<a className={classes.item}
																href="#none"
																onClick={(event: React.MouseEvent) => {
																	const servicesWithouClicked = this.state.services.filter(item => item.id !== service.id);
																	this.setState({ services: servicesWithouClicked });
																	this.props.onSelectService(event, service)
																}
																}
															>
																{service.name}
															</a>
														</li>
													</CSSTransition>
												))}
											</TransitionGroup>
										</ul>
										: <NoDataFound />
									}
								</div>
							</TabPanel>
							<TabPanel value={1} index={1} dir={this.theme.direction}>

								<div>
									<SearchBar
										onChange={this.onArticleSearchChange.bind(this)}
										value={this.state.articleSearch}
										onSubmit={(e) => { e.preventDefault(); return false; }}
										styles={{
											inputRoot: {
												width: '100%',
											},
											inputInput: {
												borderBottom: '1px solid #ccc',
												paddingLeft: 40,
											},
										}}
									/>
									{this.state.articles.length ?
										<ul className={classes.packages}>
											<TransitionGroup>
												{this.state.articles.map((article, index) => (
													<CSSTransition
														key={index}
														timeout={300}
														classNames="fade"
													>
														<li key={index} >
															<a className={classes.item}
																href="#none"
																onClick={(event: React.MouseEvent) => {
																	const articlesWithoutClicked = this.state.articles.filter(item => item.id !== article.id);
																	this.setState({ articles: articlesWithoutClicked });
																	this.props.onSelectArticle(event, article)
																}
																}
															>
																{article.name}
															</a>
														</li>
													</CSSTransition>
												))}
											</TransitionGroup>
										</ul>
										: <NoDataFound />
									}
								</div>

							</TabPanel>
							<TabPanel value={2} index={2} dir={this.theme.direction}>
								<div className={classes.packagesWrapper}>
									<ul className={classes.packages}>
										{this.state.packages.map((pkg, index) => (
											<li key={index}>
												<a
													href="/#"
													onClick={(event: React.MouseEvent) =>
														this.props.onSelectPackage(event, pkg)
													}
												>
													{pkg.name}
												</a>
											</li>
										))}
									</ul>
								</div>
							</TabPanel>
						</SwipeableViews>
						<DialogActions style={{ position: 'absolute', width: "100%", bottom: 0}}>
							<Button
								variant="contained"
								style={{background:theme.palette.primary?.main, color:theme.palette.textColorSecondary?.main}}
								onClick={this.props.onClose}
							>
								{lang.get("ok")}
							</Button>
						</DialogActions>
					</Paper>
				</Modal>
			</Fragment>
		);
	}
}

export default withStyles(styles, { withTheme: true })(SelectServices);
