import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { ExcelImport } from ".";
import Lang from '../../lang';
import PropTypes from 'prop-types';
import theme from "../../Theme/Theme";
const lang = Lang.getInstance();

function ImportExcelDialog({ open, onClose, getData }) {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="xl"
        >
            <DialogTitle>{lang.get("import")}</DialogTitle>
            <DialogContent>
                <ExcelImport
                    columns={{
                        name: lang.get("name"),
                        price: lang.get("price"),
                        quantity: lang.get("quantity"),
                        um: lang.get("measureUnit")
                    }}
                    getData={getData}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="secondary" style ={{color: theme.palette.textColorSecondary?.main}} onClick={onClose}>{lang.get("close")}</Button>
            </DialogActions>
        </Dialog>
    );
}

ImportExcelDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    getData:PropTypes.func.isRequired
};

export default ImportExcelDialog;