import React, { Component, Fragment } from "react";
import { withStyles, Checkbox } from "@material-ui/core";
import InvoiceItem from "./InvoiceItem";
import PropTypes from "prop-types";
import Lang from "../lang";
import InvoicesContext from "../Contexts/InvoicesContext";
import t from "../Theme/Theme";
const styles = (theme) => ({
  row: {
    display: "flex",
    width: "100%",
  },
  col: {
    padding: "0 10px",
    display: "inline-block",
  },
  clientName: {
    flexGrow: 0.97,
    // width: "calc(100% - 510px)",
    [theme.breakpoints.down("xs")]: {
      width: "auto",
    },
    [theme.breakpoints.down("sm")]: {
      width: "auto",
    },
  },
  clientN: {
    flexGrow: 0.79,
    // width: "calc(100% - 440px)",
  },
  priceProject: {
    paddingRight: "15px",
  },
  price: {
    minWidth: 100,
    textAlign: "center",
    paddingRight: 20,
    // paddingLeft: 30,
  },
  dateProject: {
    paddingRight: 10,
  },
  date: {
    paddingRight: 40,
  },
  dashboardDate: {
    paddingRight: 60,
  },
  dashboardNumber: {
    paddingLeft: 10,
  },
  dashboardTotalPayment: {
    paddingRight: 10,
  },
  dashboardPaid: {
    paddingRight: 60,
  },
  dashboardStatus: {
    paddingRight: 70,
  },
  header: {
    backgroundColor: theme.palette.header.main,
    padding: "15px 43px 15px 10px",
    color: theme.palette.textColorSecondary.main,
    // fontWeight: "bold",
    borderRadius: "5px 5px 0 0",
  },
  status: {
    minWidth: 50,
    textAlign: "right",
    paddingLeft: 20,
  },
  checkbox: {
    color: t.palette.textColorSecondary?.main,
    padding: "0px",
  },
});

class InvoiceList extends Component {
  constructor(props) {
    super(props);
    this.classes = Object.assign(
      this.props.classes,
      this.props.classesOverride
    );
    this.lang = Lang.getInstance();
    this.isMobile = props.isMobile;
    this.isProject = props.isProject;
    this.isDashboard = props.isDashboard;
    this.invoices = props.invoices;
  }

  renderHeaderCols() {
    if (this.isMobile) {
      return (
        <div className={`${this.classes.row} ${this.classes.header}`}>
          {this.props.select && (
            <Checkbox
              className={this.classes.checkbox}
              onClick={this.props.selectAllInvoices}
            />
          )}
          <div className={this.classes.col}>{this.lang.get("number")}</div>
          <div className={`${this.classes.col} ${this.classes.clientName}`}>
            {this.lang.get("clientName")}
          </div>
        </div>
      );
    } else if (this.isProject) {
      return (
        <div
          className={`${this.classes.row} ${this.classes.header}`}
          style={{ marginTop: "15px" }}
        >
          <div className={this.classes.col}>{this.lang.get("number")}</div>
          <div className={`${this.classes.col} ${this.classes.clientN}`}>
            {this.lang.get("clientName")}
          </div>
          <div className={`${this.classes.col} ${this.classes.dateProject}`}>
            {this.lang.get("date")}
          </div>
          <div className={`${this.classes.col} ${this.classes.priceProject}`}>
            {this.lang.get("totalPayment")}
          </div>
          <div className={`${this.classes.col} ${this.classes.priceProject}`}>
            {this.lang.get("paid")}
          </div>
          <div className={`${this.classes.col}  ${this.classes.status}`}>
            {this.lang.get("status")}
          </div>
        </div>
      );
    } else {
      return (
        <div
          className={`${this.classes.row} ${this.classes.header}`}
          style={{ marginTop: "15px" }}
        >
          {this.props.select && (
            <Checkbox
              className={this.classes.checkbox}
              onClick={this.props.selectAllInvoices}
            />
          )}
          <div
            className={
              this.isDashboard ? this.classes.dashboardNumber : this.classes.col
            }
          >
            {this.lang.get("number")}
          </div>
          <div className={`${this.classes.col} ${this.classes.clientName}`}>
            {this.lang.get("clientName")}
          </div>
          <div
            className={
              this.isDashboard
                ? `${this.classes.col} ${this.classes.dashboardDate}`
                : `${this.classes.col} ${this.classes.date}`
            }
          >
            {this.lang.get("date")}
          </div>
          <div
            className={
              this.isDashboard
                ? `${this.classes.col} ${this.classes.dashboardTotalPayment}`
                : `${this.classes.col} ${this.classes.price}`
            }
          >
            {this.lang.get("totalPayment")}
          </div>
          <div
            className={
              this.isDashboard
                ? `${this.classes.col} ${this.classes.dashboardPaid}`
                : `${this.classes.col} ${this.classes.price}`
            }
          >
            {this.lang.get("paid")}
          </div>
          <div
            className={
              this.isDashboard
                ? `${this.classes.col}  ${this.classes.dashboardStatus}`
                : `${this.classes.col}  ${this.classes.status}`
            }
          >
            {this.lang.get("status")}
          </div>
        </div>
      );
    }
  }

  renderInvoices() {
    return (
      <Fragment>
        {this.renderHeaderCols()}
        <InvoicesContext.Consumer>
          {(context) =>
            context.invoices.map((invoice, index) => (
              <InvoiceItem
                isProject={this.isProject}
                isDashboard={this.isDashboard}
                select={this.props.select}
                key={invoice.id}
                invoice={invoice}
                canDelete={index === 0}
                isMobile={this.isMobile}
                changeInvoiceState={context.changeInvoiceState}
                onDelete={context.onDelete}
                onDuplicate={context.onDuplicate}
                onSelect={context.onSelect}
                classesOverride={this.props.classesOverride}
              />
            ))
          }
        </InvoicesContext.Consumer>
      </Fragment>
    );
  }

  render() {
    return <Fragment>{this.renderInvoices()}</Fragment>;
  }
}

InvoiceList.propTypes = {
  isProject: PropTypes.bool,
  isDashboard: PropTypes.bool,
  select: PropTypes.bool,
  isMobile: PropTypes.bool.isRequired,
  invoices: PropTypes.array,
  classesOverride: PropTypes.object,
  selectAllInvoices: PropTypes.func,
};

export default withStyles(styles)(InvoiceList);
