import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import Lang from "../lang";
import React from "react";
import AddIcon from "@material-ui/icons/Add";
import theme from "../Theme/Theme";

const lang = Lang.getInstance();

type AddProjectInvoiceProps = {
  open: boolean;
  data: Array<any> | null;
  setChecked: (e: React.MouseEvent, el: any) => void;
  onClose: () => void;
  onAdd: () => void;
  onAddNew: ()=>void;
};
export default function AddProjectDoc(props: AddProjectInvoiceProps) {
  const docType = props.data && props.data.length > 0 ? props.data[0].type : "";

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>
        {lang.get("add")} {lang.get(docType)}
      </DialogTitle>
      <DialogContent>
        {!props.data || props.data.length === 0 ? (
          lang.get("notFound")
        ) : (
            <ul style={{ padding: 0 }}>
              {props.data.map((el) => {
                return (
                  <li key={el.id} style={{ listStyle: "none", padding: 0 }}>
                    <Checkbox
                    style={{color:theme.palette.header?.main }}
                      checked={el.selected}
                      inputProps={{ "aria-label": "secondary checkbox" }}
                      onClick={(e) => {
                        props.setChecked(e, el);
                      }}
                    />
                    <strong>{lang.get("number")}</strong>: {el.docNo} &nbsp;
                    <strong>{lang.get("date")}</strong>: {el.docDate} &nbsp;
                    <strong>{lang.get("totalWithVat")}</strong>: {el.totalWithVat}
                  </li>
                );
              })}
            </ul>
          )}
      </DialogContent>
      <DialogActions>
        
        <Button variant="contained" style={{background:theme.palette.header?.main}} onClick={props.onAdd}>
          {lang.get("addSelected")}
        </Button>
        <Button variant="contained" style={{background:theme.palette.header?.main}} onClick={props.onAddNew}>
            <AddIcon /> {lang.get("add")} {lang.get("new")}
          </Button>
      </DialogActions>
    </Dialog>
  );
}
