import React, { useEffect, useState } from 'react';
import TableComponent from '../../../Components/TableComponent';
import Lang from '../../../lang';
import { UrlEnum } from '../../../Utils/Utils';
import usePost from '../../../Utils/usePost';
import { CircularProgress } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { noOfDecimals } from '../../../Models/Models';
import moment from 'moment';
import Config from '../../../Utils/Config';

const lang = Lang.getInstance();
type ResultTableProps = {
    columns: Array<any>
    filterValues: any,
    expadableRow: ((data: any) => void) | null
};

function ResultTable(props: ResultTableProps) {
    const { columns, filterValues } = props;
    filterValues.startDate = moment(filterValues.startDate).format(Config.momentUSDateFormat);
    filterValues.endDate = moment(filterValues.endDate).format(Config.momentUSDateFormat);

    const { data, loading, error }: any = usePost(UrlEnum.reports, filterValues);

    const [totals, setTotals] = useState({
        totalNoVat: 0,
        totalWithVat: 0,
        totalPaid: 0
    })

    useEffect(() => {
        if (data) {
            setTotals(data.totals);
        }
    }, [data]);

    // loading
    if (loading) {
        return (
            <div className="loadingWrapper">
                <CircularProgress color="secondary"></CircularProgress>
            </div>
        );
    }
    //error
    if (error) {
        return (<Alert severity="error">{lang.get("")}!</Alert>);
    }

    return (
        <>
            {
                totals ?
                    <Alert variant="outlined" severity="success" icon={<span />} >
                        <div style={{marginBottom:10}}>
                            <strong>{lang.get("totalNoVat")}</strong>: {totals.totalNoVat}
                        </div>
                        <div style={{marginBottom:10}}>
                            <strong>{lang.get("totalWithVat")}</strong>: {totals.totalWithVat}
                        </div>
                        <div style={{marginBottom:10}}>
                            <strong>{lang.get("vat")}</strong>: {(totals.totalWithVat-totals.totalNoVat).toFixed(noOfDecimals)}
                        </div>
                        {
                            totals.totalPaid ?
                                <div >
                                    <strong>{lang.get(props.filterValues.docType)} {lang.get("paid")}</strong>: {totals.totalWithVat}
                                </div> : 
                            null
                        }
                    </Alert>
                    : null
            }
            {data ?
                <TableComponent
                    columns={columns}
                    models={data?.items}
                    options={null}
                    expadableRow={props.expadableRow}
                />
                : null}
        </>
    );
}

export default ResultTable;