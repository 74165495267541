import React, { useState, useEffect } from "react";
import MessageDialog from "../Components/MessageDialog";
import Lang from "../lang";
import {
  FormControl,
  RadioGroup,
  FormLabel,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import { Project } from "../Models/Models";
import { UrlEnum, get } from "../Utils/Utils";

const lang = Lang.getInstance();

type GenerateInProjectProps = {
  open: boolean,
  onClose: any,
  onResult: Function,
  docType: string | null,
};

type GenerateInProjectState = {
  projects: Array<Project> | null,
  projectType: string,
  selectedProjectId: number,
};

export default function GenerateInProject(props: GenerateInProjectProps) {
  const [projects, setProjects] = useState(new Array<Project>());
  const [projectType, setprojectType] = useState("none");
  const [selectedProjectId, setselectedProjectId] = useState(0);
  useEffect(() => {
    get(UrlEnum.projects + "/get/minimal/-/-").then((response) => {
      if (response.errors) {
        const errors =
          response.errors instanceof Array
            ? response.errors.join("<br/>")
            : response.errors;
        alert(errors);
      }
      setProjects(response);
      // setselectedProjectId(response.length!==0? response[0].id: null);
    });
  }, []);

  /**
   * 
   * @param event 
   */
  function handleRadioChange(event: React.ChangeEvent<HTMLInputElement>) {
    setprojectType(event.target.value);
  }

  /**
   * 
   * @param event 
   */
  function handleProjectChange (event: any){
    setselectedProjectId(parseInt(event.target.value));
  };

  return (
    <MessageDialog
      title={lang.get("folder")}
      open={props.open}
      onClose={props.onClose}
      showButton={true}
      onResult={() => props.onResult(props.docType, projectType, selectedProjectId)}
    >
      <FormControl component="fieldset">
        <FormLabel component="legend">{lang.get("project")}</FormLabel>
        <RadioGroup
          onChange={handleRadioChange}
        row
          aria-label="position"
          name="position"
          defaultValue="top"
          value={projectType}
        >
          <FormControlLabel
            value="none"
            control={<Radio color="primary" />}
            label={lang.get("none")}
            labelPlacement="top"
          />
          <FormControlLabel
            value="new"
            control={<Radio color="primary" />}
            label={lang.get("new")}
            labelPlacement="top"
          />
          <FormControlLabel
            value="existing"
            control={<Radio color="primary" />}
            label={lang.get("existing")}
            labelPlacement="top"
          />
        </RadioGroup>
        {projectType === "existing" && projects.length > 0 ? (
          <FormControl>
            <InputLabel>{lang.get("folders")}</InputLabel>
            <Select 
              value={selectedProjectId}
              onChange={handleProjectChange}
            >
              {projects.map((prj: Project) => (
                <MenuItem value={prj.id} key={prj.id}>
                  {prj.folderNo + "-" + prj.client?.name }
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : null}
      </FormControl>
    </MessageDialog>
  );
}
