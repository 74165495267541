import { createMuiTheme, createStyles } from "@material-ui/core";
import "@material-ui/core/styles";

declare module "@material-ui/core/styles/createPalette" {
  interface Palette {
    header?: Palette["primary"];
    dashboard?: Palette["primary"];
    successColor?: Palette["primary"];
    errorColor?: Palette["primary"];
    textColor?: Palette["primary"];
    textColorSecondary?: Palette["primary"];
    blueColor?: Palette["primary"];
    greenColor?: Palette["primary"];
    pinkColor?: Palette["primary"];
    yellowColor?: Palette["primary"];
    orangeColor?: Palette["primary"];
    iconBlue?: Palette["primary"];
    iconDefault?: Palette["primary"];
  }
  interface PaletteOptions {
    header?: PaletteOptions["primary"];
    dashboard?: PaletteOptions["primary"];
    successColor?: PaletteOptions["primary"];
    errorColor?: PaletteOptions["primary"];
    textColor?: PaletteOptions["primary"];
    textColorSecondary?: PaletteOptions["primary"];
    blueColor?: PaletteOptions["primary"];
    greenColor?: PaletteOptions["primary"];
    pinkColor?: PaletteOptions["primary"];
    orangeColor?: PaletteOptions["primary"];
    yellowColor?: PaletteOptions["primary"];
    iconBlue?: PaletteOptions["primary"];
    iconDefault?: PaletteOptions["primary"];
  }
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#0cab9c",
    },
    dashboard: {
      main: "#f9f9f9",
    },
    secondary: {
      main: "#e57373",
    },
    header: {
      main: "#0cab9c",
    },
    successColor: {
      main: "#81c784",
    },
    errorColor: {
      main: "#e57373",
    },
    textColor: {
      main: "black",
    },
    textColorSecondary: {
      main: "white",
    },
    iconDefault: {
      main: "#455a64",
    },
    iconBlue: {
      main: "#2196f3",
    },
    
    blueColor: {
      main: "#238de1",
    },
    greenColor: {
      main: "#16b78e",
    },
    pinkColor: {
      main: "#f56666",
    },
    yellowColor: {
      main: "#F5C33B",
    },
    orangeColor:{
      main:"#ff5e00",
    }
  },
  typography: {
    fontSize: 12,
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: 12,
      },
    },
    MuiCssBaseline: {
      "@global": {
        body: {
          // fontSize: 9,
        },
      },
    },
    MuiDrawer: createStyles({
      paper: {
        zIndex: 12,
      },
    }),
    MuiButton: {
      root: {
        "&:hover": {
          backgroundColor: "#5d817a",
        },
      }
    }
  },
});

export default theme;