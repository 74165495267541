import * as React from "react";
import {
  withStyles,
  FormControl,
  Button,
  InputAdornment,
  CircularProgress,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Lang from "../lang";
import { Link } from "react-router-dom";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Grid from "@material-ui/core/Grid";
import { RemoveRedEye } from "@material-ui/icons";
import CssBaseline from "@material-ui/core/CssBaseline";
import logo from "../Images/logo/cronotimal.svg";
import { post, UrlEnum, isLoggedIn,checkMailConfirmed } from "../Utils/Utils";
import { RegisterModel } from "../Models/Models";
import { Typography } from "@material-ui/core";
import MuiPhoneInput from "material-ui-phone-number";
import Config from "../Utils/Config";
import CachedDataSingleton from "../cachedDataSingleton";
import { CACHED_DATA_TAG } from "../Utils/Constants";
import theme from "../Theme/Theme";
let lang = Lang.getInstance();

let storage = window.localStorage;
const cachedDataJSON = JSON.parse(storage.getItem(CACHED_DATA_TAG));
const cachedData = CachedDataSingleton.getInstance();
cachedData.set(cachedDataJSON);

const disposableEmailList = [
  "yopmail.com",
  "tempmail.org",
  "temp-mail.io",
  "mail.tm",
  "cryptogmail.com",
  "emailondeck.com",
  "guerrillamail.com",
  "mailpoof.com",
];
class RegisterPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      model: RegisterModel,
      submitted: false,
      passwordIsMasked: true,
      errors: null,
      loading:false,
    };
  }

  /**
   * Validation of same password in both cases
   */
  async componentDidMount() {
    // redirect if logged in  but no company
    const loggedIn = isLoggedIn();

    if (loggedIn && !cachedData.get("company")) {
      const confirmed =  await checkMailConfirmed(this.state.model.email);
      if (!confirmed) {
        document.location.href = "/confirmEmail";
        return;
      }else
        document.location.href = "/wizard";
      return;
    }
    // redirect to home if logged in with company
    if (loggedIn && cachedData.get("company")) {
      document.location.href = "/";
      return;
    }

    // custom rule will have name 'isPasswordMatch'
    ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
      const { model } = this.state;
      if (value !== model.password) {
        return false;
      }
      return true;
    });
    ValidatorForm.addValidationRule("isDisposableEmail", (email) => {
      if (this.isDisposableEmail(this.state.model.email)) {
        this.setState({ errors: lang.get("noDisposableEmail") });
        return false;
      } else {
        if (this.state.errors) {
          this.setState({ errors: null });
        }
      }
      return true;
    });
  }

  /**
   *
   */
  handleChange = (event) => {
    const registerModel = Object.assign({}, this.state.model);
    registerModel[event.target.name] = event.target.value;
    this.setState({ model: registerModel });
  };

  /**
   *
   * @param {string} val
   */
  handleOnChangeMobile = (val) => {
    const registerModel = this.state.model;
    registerModel.mobile = val;
    this.setState({ mobile: registerModel });
  };

  /**
   *
   * @param {*} email
   */
  isDisposableEmail(email) {
    const index = email.indexOf("@");
    if (index < 0) return false;
    const domain = email.substring(email.indexOf("@") + 1);
    if (!domain) return false;
    if (disposableEmailList.indexOf(domain) >= 0) return true;
    return false;
  }

  /**
   *
   */
  handleSubmit = () => {
    this.setState({loading:true});
    post(UrlEnum.register, this.state.model).then(
      function (data) {
        if (data.errors) {
          this.setState({ errors: data.errors });
          document.cookie = " userRoles=;";
          document.cookie = "userData=;";
          document.cookie =
            "username=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/";
            this.setState({loading:false});
          return;
        }

        // login
        const loginData = {
          username: this.state.model.email,
          password: this.state.model.password,
        };
        this.setState({ submitted: true }, () => {
          //send data to server
          post(UrlEnum.login, loginData).then(
            function (response) {
              this.setState({ submitted: false });
              if (response.errors) {
                this.setState({ errors: lang.get("error") });
                return;
              }

              let expires = new Date(
                Date.now() + 3600 * 1000 * 24 * 15
              ).toUTCString();
              // set cookie user
              document.cookie = `username=${response.userName}; expires=${expires}; path=/;`;
              // set cookie user roles
              document.cookie = `userRoles=${JSON.stringify(
                response.user_roles
              )}; expires=${expires}; path=/`;
              document.cookie = `userData=${JSON.stringify({
                email: response.email,
                phone: response.mobile,
              })}; expires=${expires}; path=/`;
              this.props.history.push({pathname:'/confirmEmail', state:this.state.model});
              this.setState({loading:false});
            }.bind(this)
          );
        });
      }.bind(this)
    );
  };

  togglePasswordMask = () => {
    this.setState((prevState) => ({
      passwordIsMasked: !prevState.passwordIsMasked,
    }));
  };

  /**
   * Render this view
   */
  render() {
    const classes = this.props.classes;
    const { passwordIsMasked } = this.state;
    return (
      <React.Fragment>
        <CssBaseline />
        <div className={classes.container}>
          <Paper className={classes.paper}>
            <FormControl fullWidth={true} className={classes.grid}>
              <div className={classes.logo}>
                <img style={{ width: "120px" }} src={logo} alt="logo" />
                <h2 className={classes.container}>{lang.get("register")}</h2>
              </div>
            </FormControl>
            <ValidatorForm onSubmit={this.handleSubmit} instantValidate={true}>
              <FormControl
                required={true}
                fullWidth={true}
                className={classes.field}
              >
                <MuiPhoneInput
                  autoFormat={false}
                  defaultCountry="be"
                  required={true}
                  enableLongNumbers={true}
                  id="mobile"
                  onlyCountries={Config.phonePrefixCountries}
                  label={lang.get("mobile")}
                  onChange={this.handleOnChangeMobile}
                  name="mobile"
                  value={this.state.model.mobile}
                  regions={"europe"}
                  validators={["minNumber:5"]}
                  // errorMessages={[lang.get("fieldRequired")]}
                ></MuiPhoneInput>
                <TextValidator
                  required={true}
                  fullWidth
                  id="email"
                  label={lang.get("email")}
                  onChange={this.handleChange}
                  name="email"
                  value={this.state.model.email}
                  validators={["isDisposableEmail", "required", "isEmail"]}
                  errorMessages={[
                    lang.get("fieldRequired"),
                    lang.get("emailNotValid"),
                  ]}
                />
                <TextValidator
                  required={true}
                  fullWidth
                  id="password"
                  label={lang.get("password")}
                  onChange={this.handleChange}
                  name="password"
                  type={passwordIsMasked ? "password" : "text"}
                  validators={[
                    "required",
                    "minStringLength:8",
                    'matchRegexp:^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9\\,\\.\\;\\:\\/\\]\\[\\{\\}\\|\\"\\>\\<\\=\\_\\+\\-\\!\\`\\@\\#\\$\\%\\^\\&\\\\*\\(\\)\\~]+)$',
                  ]}
                  errorMessages={[
                    lang.get("fieldRequired"),
                    lang.get("min8Character"),
                    lang.get("minOneLetterAndNumber"),
                  ]}
                  value={this.state.model.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <RemoveRedEye
                          className={classes.eye}
                          onClick={this.togglePasswordMask}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextValidator
                  required={true}
                  fullWidth
                  label={lang.get("repeatPassword")}
                  onChange={this.handleChange}
                  name="confirmPassword"
                  type={passwordIsMasked ? "password" : "text"}
                  validators={["isPasswordMatch", "required"]}
                  errorMessages={[
                    lang.get("passwordMismatch"),
                    lang.get("fieldRequired"),
                  ]}
                  value={this.state.model.confirmPassword}
                  id="confirmPassword"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <RemoveRedEye
                          className={classes.eye}
                          onClick={this.togglePasswordMask}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
              <Typography color={"error"}>{this.state.errors}</Typography>
              <Grid
                container
                direction="row"
                justify="center"
                className={classes.grid}
              >
                <Grid className={classes.grid}>
                  <Button
                    disabled={this.state.submitted}
                    style={{
                      background: theme.palette.header?.main,
                      color: theme.palette.textColorSecondary?.main,
                    }}
                    variant="contained"
                    className={classes.button}
                    type="submit"
                    // disabled={submitted}
                  >
                    {lang.get("register")}
                  </Button>
                </Grid>
                <Grid className={classes.grid}>
                  <Link
                    to="/"
                    style={{ color: "inherit", textDecoration: "inherit" }}
                  >
                    <Button
                      variant="contained"
                      type="submit"
                      style={{
                        background: theme.palette.header?.main,
                        color: theme.palette.textColorSecondary?.main,
                      }}
                      className={classes.button}
                    >
                      {lang.get("cancel")}
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </ValidatorForm>
          </Paper>
          {this.state.loading?<CircularProgress
            color="secondary"
            style={{
            position:'absolute',
            display: "block",
            margin: "0 auto",
            top:"38%"
            }}
          />: ""}
        </div>
      </React.Fragment>
    );
  }
}

const styles = (theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
  },
  paper: theme.mixins.gutters({
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: "10%",
    width: "30%",
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  }),
  grid: theme.mixins.gutters({
    flexDirection: "row",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      justifyContent: "center",
    },
  }),
  eye: {
    cursor: "pointer",
  },
  field: {
    marginTop: 1,
    paddingTop: 16,
    paddingBottom: 16,

    buttonsField: {
      justifyContent: "center",
      display: "flex",
    },
  },
  button: theme.mixins.gutters({
    paddingTop: 5,
    paddingBottom: 5,
    marginTop: 10,
    [theme.breakpoints.down("md")]: {
      width: "100%",
      flexDirection: "column",
      alignContent: "center",
    },
  }),
});

export default withStyles(styles, { withTheme: true })(RegisterPage);
