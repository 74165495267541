import PropTypes from 'prop-types';
import Lang from '../lang';
import Config from '../Utils/Config';

const lang = Lang.getInstance();

export default function Totals(props) {
    const styles = {
        tr:{
            borderBottom: "1px solid #888"
        },
        td: {
            margin: 0,
            padding: 10,
            fontSize:"12pt"
        },
        th: {
            verticalAlign: "top",
            padding: 10,
            fontSize:11
        },
        wrapper: {
            width: "100%",
            textAlign: "left",
            borderCollapse: "collapse",
            borderTop: "1px solid #888",
            margin: 0,
            fontSize:"12pt"
        },
        textRight: {
            textAlign: "right"
        },
        header: {
            background: props.color ? props.color : "transparent"
        },
        totalsText:{
            margin:10,
        },

    };
    

    return (
        <table style={styles.wrapper}>
            <tbody>
                <tr>
                    <td>
                        <p style={styles.totalsText}>{lang.get("totalMerchandisesNoVat")}: <span style={{ float: "right" }}>{props.totals.totalCost.toFixed(Config.noOfDecimals) || 0}</span></p>
                        <p style={styles.totalsText}>{lang.get("discount")} <em>({props.totals.discountPercent}%)</em>: <span style={{ float: "right" }}>{props.totals.discount.toFixed(Config.noOfDecimals) || 0}</span></p>
                    </td>
                    <td>
                    <p style={styles.totalsText}>{lang.get("totalNoVat")}: <span style={{ float: "right" }}>{props.totals.totalNoVat.toFixed(Config.noOfDecimals) || 0}</span></p>
                        <p style={styles.totalsText}>{lang.get("vat")} <em>({props.totals.vatPercent}%)</em>: <span style={{ float: "right" }}>{props.totals.vat.toFixed(Config.noOfDecimals) || 0}</span></p>
                    </td>
                    <td style={{...styles.textRight, verticalAlign:"top"}}>
                        <p style={styles.totalsText}>
                        <big>
                            <strong>
                                {props.totals.vatPercent === 0 ? lang.get("total"): lang.get("amountWithVat")}: &nbsp; 
                                <span style={{ float: "right" }}>{props.totals.totalAmountWithVat.toFixed(Config.noOfDecimals) || 0}  {props.currency?props.currency:'EUR'}</span></strong>
                        </big></p>
                    </td>
                </tr>
            </tbody>
        </table>
    );
}


Totals.propTypes = {
    color: PropTypes.string,
    totals: PropTypes.object.isRequired,
    currency: PropTypes.string
};