import { IconButton, Grid, Paper, Toolbar, Menu, MenuItem, makeStyles, Tooltip } from '@material-ui/core';
import React, { useState } from 'react';
import Lang from '../../lang';
import clsx from 'clsx';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ViewDayIcon from '@material-ui/icons/ViewDay';

const lang = Lang.getInstance();

export type SectionProps = {
    children?: Array<JSX.Element>;
    edit?: boolean;
    removeSection: Function,
    addContainer: Function,
    addSection: Function
};

const useStyles = makeStyles((theme) => ({
    menu: {
        position: "absolute",
        top: -10,
        left: "50%",
        width: 150,
        opacity: 0,
        transition: "opacity .5s ease-out;"
    },
    section: {
        position: "relative",
        minHeight: 100
    },
    sectionEdit: {
        "&:hover > #sectionMenu": {
            opacity: 1
        }
    },
    toolBarPaper: {
        padding: 0,
        zIndex: 100,
        "&:hover": {
            zIndex: 110
        }
    }
}));

function Section(props: SectionProps) {
    const classes = useStyles();
    const [menuAnchor, setMenuAnchor] = useState<any>(null);

    const addNewSection = () => {

    };

    return (
        <Grid container spacing={6} id="section"
            className={clsx(classes.section, { [classes.sectionEdit]: props.edit })}>
            {props.edit ?
                <Toolbar
                    id="sectionMenu"
                    className={clsx({ [classes.menu]: props.edit })}
                    disableGutters
                    variant="dense">
                    <Paper elevation={3} classes={{ root: classes.toolBarPaper }}>
                        <Tooltip title={`${lang.get("add")} ${lang.get("section")}`}>
                            <IconButton
                                size="small"
                                onClick={() => props.addSection()} >
                                <ViewDayIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={`${lang.get("add")} ${lang.get("container")}`}>
                            <IconButton
                                size="small"
                                onClick={(e) => setMenuAnchor(e.target)} >
                                <DashboardIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={lang.get("remove")}>
                            <IconButton
                                size="small"
                                onClick={() => props.removeSection()} >
                                <HighlightOffIcon color="secondary" />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            anchorEl={menuAnchor}
                            keepMounted
                            open={Boolean(menuAnchor)}
                            onClose={() => setMenuAnchor(null)}
                        >
                            <MenuItem onClick={() => {
                                props.addContainer(12);
                                setMenuAnchor(null);
                            }}>1</MenuItem>
                            <MenuItem onClick={() => {
                                props.addContainer(6);
                                setMenuAnchor(null);
                            }}>1/2</MenuItem>
                            <MenuItem onClick={() => {
                                props.addContainer(4);
                                setMenuAnchor(null);
                            }}>1/3</MenuItem>
                        </Menu>
                    </Paper>
                </Toolbar>
                : null}
            {props.children}

        </Grid>
    )
}

export default Section;