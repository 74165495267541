import React from "react";
import EstimatesNotUsed from "../Components/DashboardComponents/Charts/EstimatesNotUsed";
import OverviewEstimatesOrdersTickets from "../Components/DashboardComponents/Charts/OverviewEstimatesOrdersTickets";
import OverviewInvoicesCostsProfit from "../Components/DashboardComponents/Charts/OverviewInvoicesCostsProfit";
import OverviewVatCosts from "../Components/DashboardComponents/Charts/OverviewVatCosts";
import OverviewVatInvoices from "../Components/DashboardComponents/Charts/OverviewVatInvoices";
import Profit from "../Components/DashboardComponents/Charts/Profit";
import WithChartsFilters from "../Components/DashboardComponents/Charts/WithChartsFilters";
import WithVatChartsFilters from "../Components/DashboardComponents/Charts/WithVatChartsFilters";
import DocumentsOverview from "../Components/DashboardComponents/Overview/DocumentsOverview";
import { Grid,Box } from "@material-ui/core";

export default function CompanyOverview() {
  return (
    <div style={{height:"100%", overflowY:"scroll"}}>
      <Box style={{margin:"0 15px", padding:"0 20px"}}>
      <Grid container spacing={2} >
      <Grid item xs={12}>
          <DocumentsOverview />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <EstimatesNotUsed />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <OverviewEstimatesOrdersTickets />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <OverviewVatInvoices />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <OverviewVatCosts />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <OverviewInvoicesCostsProfit />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Profit />
        </Grid>
        <Grid itemlg={6} md={6} sm={6} xs={12}>
          <WithChartsFilters />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <WithVatChartsFilters />
        </Grid>
      </Grid>
      </Box>
    </div>
  );
}
