import React, {useEffect, useState, Fragment} from 'react';
import Lang from '../lang';
import {UrlEnum,  get } from '../Utils/Utils';
import {CircularProgress, Grid, AppBar, makeStyles, fade, Paper, Button, Toolbar} from '@material-ui/core';
import ArticlesExpandableList from '../Components/ArticlesExpandableList';
import MessageDialog from '../Components/MessageDialog';
import {Article} from '../Models/Models'
import DeleteIconBtn from '../Components/DeleteIconBtn';
import Pagination from '@material-ui/lab/Pagination';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import theme from "../Theme/Theme"

const useStyles = makeStyles(theme => ({
    loading:{
        margin:"150px auto 0 auto",
        display:"block"
    },
    container:{
        padding:10,
        overflow:"hidden",
        [theme.breakpoints.down('sm')]: {
            padding:0
          },
    },
    menuButton: {
        marginRight: theme.spacing(2),
      },
    title: {
        flexGrow: 1,
        display: 'none',
        [theme.breakpoints.up('sm')]: {
          display: 'block',
        },
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '12ch',
        '&:focus': {
          width: '20ch',
        },
      }
    },
    articlesToolBar:{
        minHeight:50,
        [theme.breakpoints.down('sm')]: {
            "& .MuiButton-label":{
                fontSize:0
            }
          },
    }
    
}));

const lang = Lang.getInstance();
let selectedArticles= [];
let allArticles = [];
const per_page = 50;
let count=0;
let selectedId=[];



/**
 * Articles component
 * @param {*} props 
 */
export default function ArticlesEdit(props){
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [buttonsLoading, setButtonsLoading] = useState(false);
    const [articles, setArticles] = useState([]);
    const [message, setMessage] = useState(null)
    const [pag, setPage] = React.useState(1);
    const [activeCategory, setActiveCategory] = useState(null);
    const [searchArticles, setSearchArticles] = useState("");

    useEffect(()=>{
        // get initial articles once
        if(searchArticles)
        {
          getSearchArticles();
        }else{
        getArticles(); 
        }
    },[pag]);

    /**
     * add new Article
     * @param {SyntheticEvent} e 
     */
    const addNewArticles = (e)=>{
    
        e.preventDefault();
        let ns = articles.slice();
        let s = new Article();
        s.fk_CategoryId = activeCategory ? activeCategory.id : null;
        // open by default new service
        ns.unshift(s);
        setArticles(ns);
        return false;
    };

  
    /**
     * removes selected articles from an NEW array of articles
     */
    const removeSelectedArticles = (articles)=>{
      for (const article of selectedArticles) {
          const index = articles.indexOf(article);
          articles.splice(index, 1);  
      }
      return articles;
  };

    /**
     * change page
     * @param {*} event 
     * @param {*} value 
     */
    function changePage(event, value){
        setPage(value);
        if(pag!==value)
        setLoading(true);
       
    }
    const deleteSuccess = (data, response) => {
      selectedId = selectedArticles.map((el)=>el.id);
        let newArticles = articles.slice();

        removeSelectedArticles(newArticles);
        //remove from all, to show when there is no search
        removeSelectedArticles(allArticles);
        setArticles(newArticles);
    };
    
    /**
     * get articles from server
     */
    function getArticles(){
       
        const url = UrlEnum.articles + "?page=" + pag + "&per_page=" + per_page; 

        get(url).then((response) => {
            if(response.errors){
                setMessage(response.errors);
                setLoading(false);
                return false;
            }
            count=Math.round(parseInt(response.total_count)/per_page);
            setArticles(response.items);
            setLoading(false);
            
            
            
        });
    }
    function getSearchWord(event){
      let value = event.target.value;
      setSearchArticles(value)
      
    }
    
    

    /**
     * get search articles from server
     */
    function getSearchArticles(){
     

      const per_pag=50;
      if(pag===1){
        const url = UrlEnum.articles +"?q="+searchArticles+ "&?page=" + pag + "&per_page=" + per_pag;  
      get(url).then((response) => {
          if(response.errors){
              setMessage(response.errors);
              setLoading(false);
              return false;
          }
          count=Math.ceil(parseInt(response.total_count)/per_page);
            setArticles(response.items);
            setLoading(false);
           
          
          
      });
      }else{
        const url = UrlEnum.articles + "?page=" + pag + "&per_page=" + per_pag+"&q="+searchArticles;
      
      get(url).then((response) => {
          if(response.errors){
              setMessage(response.errors);
              setLoading(false);
              return false;
          }
          count=Math.ceil(parseInt(response.total_count)/per_page);
            setArticles(response.items);
            setLoading(false);
            
            
          
          
      });
      
    }
  }
  /**
   * Press Enter Event
   * @param {*} event 
   */
   function keyPressed(event) {
    if (event.key === "Enter") {
      setPage(1);
      getSearchArticles();
    }
  }

    /**
     * render method
     */
    return(
        <div className={classes.container}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} >
                    <Paper>
                    <AppBar position="static" style={{background:theme.palette.header?.main, color:theme.palette.textColorSecondary?.main, }}>{/* position:"fixed" */}
                        <Toolbar  className={classes.articlesToolBar}>
                            <h4 className={classes.title}>{lang.get("articles")}</h4>
                            {buttonsLoading ? <CircularProgress color="secondary"/> : "" }
                            <div className={classes.search}>
                            <div className={classes.searchIcon}>
                             <SearchIcon />
                            </div>
                            <InputBase
                            
                                placeholder={lang.get("search")}
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                                inputProps={{ 'aria-label': 'search' }}
                                value={searchArticles}
                                onChange={getSearchWord}
                                name="search"
                                onKeyPress={keyPressed}
                               
                              
                            />
                            </div>
                            <Button disabled={buttonsLoading} color="inherit"  onClick={addNewArticles}><AddIcon/> &nbsp;{lang.get("new")}</Button>
                            <DeleteIconBtn 
                                disabled={articles.length === 0 || buttonsLoading} 
                                url={UrlEnum.articles}
                                onSuccess = {deleteSuccess}
                                data={() =>{ return {"ids":selectedArticles.map((el)=>el.id)} }}></DeleteIconBtn>
                        </Toolbar>
                    </AppBar>
                    {
                        loading ? 
                        <CircularProgress color="secondary" className={classes.loading}/> : 
                        <Fragment>
                            <ArticlesExpandableList
                                getSelected={(selected)=>{selectedArticles = selected}}
                                articles={articles} /> 
                            <Pagination count={count} page={pag}  onChange={changePage} />
                        </Fragment>
                    }
                    </Paper>
                </Grid>
                <MessageDialog open={message ? true : false} text={message || ""} onResult={()=>setMessage(null)} />
            </Grid>
        </div>
    );
}