import React, { Fragment } from "react";
import Lang from "../../lang";
import Config from "../../Utils/Config";
import { createStyles, makeStyles } from "@material-ui/core";
import { Theme } from "@nivo/core";
import moment from "moment";

const lang = Lang.getInstance();

const useStyles = makeStyles((theme: Theme) => ({
  tr: {
    borderBottom: "1px solid #888"
  },
  td: {
    margin: 0,
    padding: 10,
    fontSize: "11pt"
  },
  th: {
    verticalAlign: "top",
    padding: 7,
    fontSize: '11pt',
  },
  wrapper: {
    width: "100%",
    textAlign: "left",
    borderCollapse: "collapse",
    borderTop: "1px solid #888",
    marginTop: 30
  },
  textRight: {
    textAlign: "right"
  }
}));

type LiabilityItemsProps = {
  color: string;
  items: Array<any>;
};

export default function LiabilityItems(props: LiabilityItemsProps) {
  const { color, items } = props;
  const classes = useStyles(props);

  function _renderItems(item: any, index: number) {
    return (
      <tr
        key={index}
        style={{
          borderBottom: "1px solid #888",
          fontSize: '11pt',
        }}
      >
        <td
          style={{
            borderBottom: "1px solid #888",
            fontSize: '11pt',
          }}
        >
          {item.invoiceNo ? item.invoiceNo : "-"}
        </td>
        <td
          style={{
            borderBottom: "1px solid #888",
            fontSize: '11pt',
          }}
        >
          {item.invoiceDate
            ? moment(item.invoiceDate).format("DD-MM-yyyy")
            : "-"}
        </td>
        <td
          style={{
            width: 200,
            margin: 0,
            padding: 10,
            textAlign: "center",
            fontSize: '11pt',
          }}
        >
          {item.totalWithVat ? item.totalWithVat : "-"}
        </td>
        <td
          style={{
            width: 200,
            margin: 0,
            padding: 10,
            fontSize: "11pt",
            textAlign: "center",
          }}
        >
          {item.totalPaid ? item.totalPaid : "0"}
        </td>
        <td
          style={{
            width: 200,
            margin: 0,
            padding: 10,
            fontSize: '11pt',
            textAlign: "center"
          }}
        >
          {item.reminderDate ? item.reminderDate : "-"}
        </td>
        <td
          style={{
            width: 200,
            margin: 0,
            padding: 10,
            fontSize: '11pt',
            textAlign: "center"
          }}
        >
          {item.elapsedTime ? item.elapsedTime : "-"}
        </td>
      </tr>
    );
  }

  return (
    <div style={{ position: "relative", marginBottom: 20 }}>
      <table
        style={{
          width: "100%",
          textAlign: "left",
          borderCollapse: "collapse",
          borderTop: "1px solid #888",
          marginTop: 30
        }}
      >
        <thead>
          <tr
            style={{
              background: color ? color : "transparent",
              fontSize: '11pt',
            }}
          >
            <th
              style={{
                verticalAlign: "top",
                padding: 7,
                fontSize: '11pt',
                width: 20
              }}
            >
              {lang.get("number")}
            </th>
            <th
              style={{
                width: 200,
                margin: 0,
                padding: 10,
                fontSize: '11pt',
                textAlign: "center"
              }}
            >
              {lang.get("date")}
            </th>
            <th
              style={{
                width: 200,
                margin: 0,
                padding: 10,
                fontSize: '11pt',
                textAlign: "center"
              }}
            >
              {lang.get("amount")}
            </th>
            <th
              style={{
                width: 200,
                margin: 0,
                padding: 10,
                fontSize: '11pt',
                textAlign: "center"
              }}
            >
              {lang.get("paid")}
            </th>
            <th
              style={{
                width: 200,
                margin: 0,
                padding: 10,
                fontSize: '11pt',
                textAlign: "center"
              }}
            >
              {lang.get("reminder")}
            </th>
            <th
              style={{
                width: 200,
                margin: 0,
                padding: 10,
                fontSize: '11pt',
                textAlign: "center"
              }}
            >
              {lang.get("elapsedTime")}
            </th>
          </tr>
        </thead>
        <tbody>
          {items
            ? items.map((item: any, index: number) => _renderItems(item, index))
            : null}
        </tbody>
      </table>
    </div>
  );
}
