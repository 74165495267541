import React, { PureComponent } from "react";
import {
	Theme,
	createStyles,
	withStyles,
	FormControlLabel,
	Checkbox,
} from '@material-ui/core';
import { convertFromRaw } from 'draft-js';
import Lang from '../lang';
import InvoiceHeader from '../DocumentTemplates/InvoiceHeader';
import Document from '../DocumentTemplates/Document';
import Items from '../DocumentTemplates/Items';
import CachedDataSingleton from '../cachedDataSingleton';
import { Address, Client, DocService, DocTotals, PaymentPhase } from '../Models/Models';
import InvoiceFooter from '../DocumentTemplates/InvoiceFooter';
import { UrlEnum, get, post, DocTypes, absoluteImagePaths, fromStringTemplate } from '../Utils/Utils';
import ReactDOMServer from 'react-dom/server';
import { stateToHTML } from 'draft-js-export-html';
import EstimateHeader from '../DocumentTemplates/EstimateHeader';
import EstimateFooter from '../DocumentTemplates/EstimateFooter';
import Totals from '../DocumentTemplates/Totals';
import AdditionalInfoWrapper from '../DocumentTemplates/EstimateAdditionalInfo';
import { OrderTicketPaymentsPreview } from './OrderTicketPayments';
import PreviewPDF from './PreviewPDF';
import { replaceMacrosFromText } from "../Pages/Liability/ReplaceMacrosFromText";
import theme from "../Theme/Theme";
import Config from "../Utils/Config";
import moment from "moment";

type PreviewDocData = {
	series: string,
	no: string,
	date: string,
	vatRate?: number,
	worksiteAddress?: string,
	startDate?: string | null,
	endDate?: string | null,
	paymentMode?: string,
	paymentDate?: string,
	title?: string | null | undefined
	description?: string | null | undefined
	additionalInfo?: string | null | undefined,
	client: Client,
	folder?: any,
	totalNoVat?: any,
}
type PreviewDocumentProps = {
	open: boolean,
	onClose: (emailSent?: boolean) => void;
	type: string,
	id: number,
	services: [DocService[]];
	totals: [DocTotals],
	setOpenMessage: (message: string) => void,
	setSmallMessage: (message: string) => void,
	classes: any,
	docData: Array<PreviewDocData>,
	children: [React.ReactNode],
	showTVA?: any,
	paymentPhases?: Array<Array<PaymentPhase>>,
	currency?: string;
};

type PreviewDocumentState = {
	htmlContent: string,
	footerHtml: string,
	emailMessage: string,
	hidePrices: boolean
};

const styles = (theme: Theme) =>
	createStyles({
		loadingWrapper: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			width: "100%",
			height: "100%"
		},
		actionsWrapper: {
			background: "#fff",
			zIndex: 100
		},
		overflowHidden: {
			overflow: "hidden"
		}
	});
const lang = Lang.getInstance();
const cachedData = CachedDataSingleton.getInstance();

class PreviewDocument extends PureComponent<PreviewDocumentProps, PreviewDocumentState> {
	type: string;
	company: any;

	constructor(props: PreviewDocumentProps) {
		super(props);
		this.state = {
			htmlContent: "",
			footerHtml: "",
			emailMessage: "",
			hidePrices: false
		};

		this.type = this.props.type;
		switch (this.type) {
			case DocTypes.invoice:
			case DocTypes.proforma:
			case DocTypes.creditNote:
				this.type = DocTypes.invoice;
		};
		this.company = cachedData.get('company');
	}

	componentDidMount() {
		this.getTemplateData();
		this.getEmailSignature();
	}

	/**
	 * getEmailSignature
	 */
	private async getEmailSignature() {
		if (!this.props.docData.length) {
			throw new Error("docData must have at least one element");
		}
		const response = await post(
			UrlEnum.compiledDoc + '/' + this.type + 'EmailSignature/0',
			{ values: { docDate: this.props.docData[0].date } }
		);
		if (response.errors) {
			this.props.setSmallMessage(
				response.errors instanceof Array ? response.errors.join('<br/>') : response.errors
			);
		}
		this.setState({ emailMessage: response });
	}

	/**
	 * 
	 */
	private renderHeader(docData: PreviewDocData, totals: DocTotals, color: string, appearance: number) {
		switch (this.props.type) {
			case DocTypes.invoice:
			case DocTypes.proforma:
			case DocTypes.creditNote:
				return (
					<InvoiceHeader
						appearance={appearance}
						company={this.company}
						client={docData.client}
						color={color}
						series={docData.series}
						number={docData.no}
						date={docData.date}
						vatRate={docData.vatRate}
						paymentMode={docData.paymentMode}
						paymentDate={docData.paymentDate}
						docType={this.props.type}
					/>
				);
			case DocTypes.estimate:
				return (
					<>
						<EstimateHeader
							appearance={appearance}
							company={this.company}
							client={docData.client}
							color={color}
							series={docData.series}
							number={docData.no}
							date={docData.date}
							docType={this.props.type}
							title={docData.title}
							description={docData.description}
							additionalInfo={docData.additionalInfo}
							workSiteAddress={docData.worksiteAddress}
						/>
						{this.state.hidePrices ? null :
							<AdditionalInfoWrapper
								totalNoVat={totals.totalNoVat}
								additionalInfo={docData.additionalInfo}
							/>
						}
					</>
				);
			case DocTypes.orderTicket:
				return (
					<>
						<EstimateHeader
							appearance={appearance}
							company={this.company}
							client={docData.client}
							color={color}
							series={docData.series}
							number={docData.no}
							date={docData.date}
							docType={this.props.type}
							title={docData.title}
							description={docData.description}
							additionalInfo={docData.additionalInfo}
							workSiteAddress={docData.worksiteAddress}
						/>
						{this.state.hidePrices ? null :
							<AdditionalInfoWrapper
								totalNoVat={totals.totalNoVat}
								startDate={docData.startDate}
								endDate={docData.endDate}

							/>
						}
					</>
				);
			default: return null;
		}
	}

	/**
	 * 
	 */
	renderFooter() {
		switch (this.props.type) {
			case DocTypes.invoice:
			case DocTypes.proforma:
			case DocTypes.creditNote:
				return (
					<InvoiceFooter company={this.company} />
				);
			case DocTypes.estimate:
				return (
					<EstimateFooter company={this.company} />
				);
			case DocTypes.orderTicket:
				return (
					<EstimateFooter company={this.company} />
				);
			default: return null;
		}
	}

	getCustomHTML(docData : any, customHtml: any){
		return  fromStringTemplate(customHtml, {
			companyName: this.company?.name ? this.company?.name : "",
			companyAddress: this.company?.address? Address.stringify(this.company?.address): "",
			clientName: docData.client?.name ? docData.client?.name: "",
			clientAddress: docData.client?.address ? Address.stringify(docData.client?.address): "",
			clientVatNo: docData.client?.vatNo? docData.client?.vatNo :"",
			siteAddress: docData?.worksiteAddress ? docData?.worksiteAddress: "",
			date: moment().format(Config.momentEUDateFormat),
			projectNo: docData?.folder ?  docData?.folder.folderNo : "",
			projectDate: docData?.folder? moment(docData?.folder.created_at).format(Config.momentEUDateFormat) : "",
			orderTicketStartDate: this.type === DocTypes.orderTicket?  moment(docData?.startDate).format(Config.momentEUDateFormat) : "",
			orderTicketEndDate: this.type === DocTypes.orderTicket? moment(docData?.endDate).format(Config.momentEUDateFormat) : "",
			orderTicketNo:this.type === DocTypes.orderTicket? docData?.no : "",
			orderTicketTotalNoVat: this.type === DocTypes.orderTicket?docData.totalNoVat :"",
			orderTicketDate: this.type === DocTypes.orderTicket? moment(docData?.date).format(Config.momentEUDateFormat) : "",
			estimateStartDate:this.type === DocTypes.estimate?  moment(docData?.startDate).format(Config.momentEUDateFormat) : "",
			estimateEndDate:this.type === DocTypes.estimate? moment(docData?.endDate).format(Config.momentEUDateFormat) : "",
			estimateNo: this.type === DocTypes.estimate? docData?.no : "",
			estimateDate:this.type === DocTypes.estimate? moment(docData?.date).format(Config.momentEUDateFormat) : "",
			estimateTotalNoVat:this.type === DocTypes.estimate?docData.totalNoVat :"",
		  });
	}
	/**
	 * get template data from server
	 */
	private async getTemplateData() {
		const response = await get(UrlEnum.documentTemplates + '/' + this.type);
		if (response.errors) {
			this.props.setOpenMessage(
				response.errors instanceof Array ? response.errors.join('<br/>') : response.errors
			);
		}

		const appearance: number = response.appearance;
		const color: string = response.color;
		let contentJson = JSON.parse(response.customText);

		setTimeout(async () => {

			let content: string = "";
			let totals: any = null;
			for (let i = 0; i < this.props.docData.length; i++) {
				const docData = this.props.docData[i];

				contentJson.blocks = replaceMacrosFromText(contentJson.blocks, { reference: docData.series + "/" + docData.no });
				const contentState = convertFromRaw(contentJson);
				let customHtml = stateToHTML(contentState);
				customHtml = this.getCustomHTML(docData,customHtml);
				let contentStateTop: any = null;
				let customHtmlTop: any = null;
				if (response.customTextTop) {
					try {
						contentStateTop = convertFromRaw(JSON.parse(response.customTextTop));
						customHtmlTop = stateToHTML(contentStateTop);
						customHtmlTop = this.getCustomHTML(docData,customHtmlTop);
					} catch (e) { }
				}

				let paymentPhases = null;
				if (this.props.paymentPhases) {
					paymentPhases = (<>
						<h3>{lang.get("payments")}</h3>
						<OrderTicketPaymentsPreview
							payments={this.props.paymentPhases[i]}
							totalWithVat={this.props.totals[i].totalWithVat}
							hideButtons={true}
						/>
					</>
					);
				}

				totals = this.state.hidePrices ? "" :
					<div style={{ marginTop: 5 }}>
						<Totals
							color={color}
							totals={this.props.totals[i]}
							currency={this.props.currency}
						/>
					</div>;

				content += ReactDOMServer.renderToString(
					<Document style={{ paddingLeft: 10, paddingRight: 10 }}>
						{this.renderHeader(docData, this.props.totals[i], color, appearance)}
						{
							customHtmlTop ?
								<div>
									<br />
									<div style={{ fontSize: "11pt" }} dangerouslySetInnerHTML={{ __html: customHtmlTop }}></div>
									<br />
								</div> :
								null
						}

						<Items
							items={this.props.services[i]}
							color={color}
							hidePrices={this.state.hidePrices}
						/>
						{this.props.children ? this.props.children[i] : null}
						<div style={{ pageBreakAfter: 'auto', marginTop: 5, fontSize: '11pt' }}>{this.props.showTVA}</div>
						{
							this.props.docData.length > 1 &&
							<div style={{ pageBreakAfter: 'always', marginTop: 5 }}>
								<br /><br />
								{totals}
							</div>
						}
						{paymentPhases && !this.state.hidePrices ? paymentPhases : null}
						
						<br />
						<div style={{ fontSize: "11pt" }} dangerouslySetInnerHTML={{ __html: customHtml }}></div>
					</Document>
				);
			}

			if (this.props.docData.length === 1) {
				totals = ReactDOMServer.renderToString(totals);
				totals = totals.replaceAll("\"", "'");
			} else {
				totals = "<span></span>";
			}


			let footer = ReactDOMServer.renderToString(
				<div>
					{this.renderFooter()}
				</div>
			);
			footer = `<html>
			<head>
			<meta charset='UTF-8'/>
			<script>
				var test = function() {
					var vars = {};
					var x = document.location.search.substring(1).split('&');
					for (var i in x) {
						var z = x[i].split('=', 2);
						vars[z[0]] = unescape(z[1]);
					}
					if (vars['page'] == vars['topage']) {
						document.getElementById("extra").innerHTML = "${totals}";
					}else{
						var footer = document.getElementById("footer");
						footer.style.setProperty("position","relative");
						footer.style.setProperty("top","50px");
					}
					document.getElementById("pageNum").innerHTML = vars['page'] + "/" + vars['topage'];
				};
			</script>
			</head><body onload="test()" ><div id="extra" ></div>${footer}</body></html>`;

			let htmlContent: any = document.createElement("div");
			htmlContent.innerHTML = content;
			htmlContent = absoluteImagePaths(htmlContent);

			const htmlContentString = `<html>
				<head>
					<meta charset='UTF-8'/>
					<style>
						@import url('https://fonts.googleapis.com/css?family=Roboto:ital,wght@0,400;0,700;1,400;1,700&display=swap');
						html{
							font-family:Roboto,Arial, sans-serif;
						},
					</style>
				</head>
				<body>${htmlContent.innerHTML}</body></html>`;
			this.setState({
				htmlContent: htmlContentString,
				footerHtml: footer
			});


		});
	}

	render() {
		const emailData = {
			to: this.props.docData[0].client.email,
			subject: `${lang.get(this.props.type)} ${this.props.docData[0].no}/${this.props.docData[0].series}`,
			message: this.state.emailMessage,
			docType: this.type,
			docId: this.props.id
		};
		let url = UrlEnum.pdf.replace('{type}', this.props.type);
		url = url.replace('{id}', this.props.id.toString());

		if (!this.state.emailMessage)
			return null;
		return (
			<PreviewPDF
				key={this.state.htmlContent.length}
				open={this.props.open}
				onClose={this.props.onClose}
				htmlContent={this.state.htmlContent}
				footerHtml={this.state.footerHtml}
				setSmallMessage={this.props.setSmallMessage}
				emailData={emailData}
				url={url}
				customTopMenu={this.type !== DocTypes.invoice && this.type !== DocTypes.estimate ?
					<FormControlLabel
						control={
							<Checkbox
								style={{ color: theme.palette.header?.main }}
								checked={this.state.hidePrices}
								onChange={(e) => {
									this.setState({ hidePrices: e.target.checked }, () => this.getTemplateData())
								}}
								name="hidePrices" />}
						label={lang.get("hidePrices")}
					/>
					: null}
			/>
		);
	}
}

export default withStyles(styles, { withTheme: true })(PreviewDocument);
