import * as React from 'react';
import { withStyles, FormControl, Select} from '@material-ui/core';
import Lang from '../lang';
import {TextValidator } from 'react-material-ui-form-validator';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';


let lang = Lang.getInstance();

const countries = [
    { 
      value: '',
      label: '-',
    },
    {
      value: 'BE',
      label: 'BE',
    },
    {
      value: 'NL',
      label: 'NL',
    },
    {
      value: 'DE',
      label: 'DE',
    },
    {
      value: 'FR',
      label: 'FR',
    },
    {
      value: 'RO',
      label: 'RO',
    },
  ];

class VatNo extends React.Component{
    
    // form ref
    formRef = React.createRef();
    static tva = "";
    static country = "BE";
    static prevVatNo = null;
    /**
     * constructor
     * @param {Object} props 
     */
    constructor(props) {
        super(props);

         this.state ={
            vatNo:'',
            tva: '',
            country:''
        };
    };
    
    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.value !== VatNo.prevVatNo){
          if(nextProps.value && nextProps.value.substring(0,1).match("A-Z") >=0){
            VatNo.country = nextProps.value.substring(0,2);
            VatNo.tva = nextProps.value.substring(2).trim();
          }else{
            VatNo.country = "BE";
            VatNo.tva = nextProps.value;
          }
          VatNo.prevVatNo = nextProps.value;
        }
        return {
          country: VatNo.country,
          tva: VatNo.tva
        };
    }

    /**
     * input change handler
     * 
     */
    handleChange = (event) => {
      const vatNoModel= Object.assign({},this.state);
      let label = event.target.name || event.currentTarget.id;
      
      if(label === "tva"){
        vatNoModel.tva = event.target.value;
        VatNo.tva = vatNoModel.tva;
      }else{
        vatNoModel.country = event.target.value;
        VatNo.country = vatNoModel.country;
      }

      vatNoModel.vatNo=VatNo.country.concat(VatNo.tva);
      this.setState(vatNoModel);
    } 

    /**
     * render component
     */
    render(){
        const classes = this.props.classes;
        return (
            <div className={classes.wrapper}>
                <FormControl className={classes.country}>
                    <Select
                        style={{maxWidth:100}}
                        id="country"
                        value={VatNo.country}
                        onChange={this.handleChange}
                        onBlur = { () => this.props.onBlur(this.state.vatNo) }
                    >
                    {countries.map((option)=>
                        <MenuItem value={option.value} id="country" key={option.value}>{option.label}</MenuItem>    
                    )}
                    </Select>
                </FormControl>
                &nbsp;&nbsp;
                <div className={classes.vatNoWrapper}>
                <TextValidator 
                    className={classes.vatNo}
                    type="number"
                    id="vatNo"
                    fullWidth
                    label={lang.get("vatNo")}
                    name="tva"
                    onChange={this.handleChange}
                    validators={this.props.required === false ? [] : ['required']}
                    value={VatNo.tva || ""}
                    errorMessages={lang.get("fieldRequired")}
                    onBlur = { () => this.props.onBlur(this.state.vatNo) }
                /> 
                </div>
            </div>
           
        );
    }

}

/**
 * default props
 */
VatNo.protoTypes = {
    onBlur: PropTypes.func.isRequired,
    onKeyPress: PropTypes.func,
    value: PropTypes.string.isRequired,
    required: PropTypes.bool
};

/**
 * 
 * @param {Object} theme 
 */
const styles = (theme) => ({
   wrapper:{
       display:"flex"
   },
   country:{
       marginTop:16,
   },
   vatNoWrapper:{
      flexGrow:1
   }
});

export default withStyles(styles, { withTheme: true })(VatNo);

