import React, { useEffect, useRef, useState } from "react";
import {
  Theme,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  CircularProgress,
  Slide,
  makeStyles,
} from "@material-ui/core";
import Lang from "../lang";
import { post, isMobile } from "../Utils/Utils";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import { pdfjs, Document as PdfDocument, Page } from "react-pdf";
import GetAppIcon from "@material-ui/icons/GetApp";
import EmailIcon from "@material-ui/icons/Email";
import EmailForm from "../Components/EmailForm";
import PrintIcon from "@material-ui/icons/Print";
import GestureIcon from "@material-ui/icons/Gesture";
import ImageOutlinedIcon from "@material-ui/icons/ImageOutlined";
import CachedDataSingleton from "../cachedDataSingleton";
import Draggable from "../Components/Draggable";
import { ResizableBox } from "react-resizable";
import DoneIcon from "@material-ui/icons/Done";
import { DropzoneArea } from "material-ui-dropzone";
import theme from "../Theme/Theme";
pdfjs.GlobalWorkerOptions.workerSrc = `/pdf.worker.min.js`;

type PreviewDocumentProps = {
  open: boolean;
  onClose: (emailSent: boolean) => void;
  htmlContent: string;
  headerHtml?: string;
  footerHtml?: string;
  setSmallMessage: Function;
  emailData?: {
    to: string;
    subject: string;
    message: string;
    docType?: string;
    docId?: number;
  };
  url?: string;
  customTopMenu?: JSX.Element | null | undefined;
};

const cachedData: any = CachedDataSingleton.getInstance();

const useStyles = makeStyles((theme: Theme) => ({
  loadingWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  wrapper: {
    position: "absolute",
    width: "100%",
    height: 360,
    bottom: 0,
    left: 0,
    padding: "20px 35px 20px 20px",
    background: "#f0f0f0",
    [theme.breakpoints.down("md")]: {
      height: "100%",
      boxShadow: "none",
    },
  },
  dropWrapper: {
    position: "absolute",
    width: "100%",
    height: 300,
    bottom: 0,
    left: 0,
    padding: "20px 35px 20px 20px",
    background: "#f0f0f0",
    [theme.breakpoints.down("md")]: {
      height: "100%",
      boxShadow: "none",
    },
  },
  actionsWrapper: {
    background: "#fff",
    zIndex: 100,
  },
  overflowHidden: {
    overflow: "hidden",
  },
  dropzoneArea: {
    minHeight: "120px !important",
    // maxWidth: "500px !important",
  },
  dropzoneAreaText: {
    fontSize: "18px",
    margin: 0,
  },
  okBtn: {
    background: theme.palette.primary?.main,
    color: theme.palette.textColorSecondary?.main,
    padding: "5px",
    margin: "15px 15px 15px 0px",
  },
}));

const lang = Lang.getInstance();

function PreviewPDF(props: PreviewDocumentProps) {
  const {
    url,
    htmlContent,
    footerHtml,
    headerHtml,
    open,
    onClose,
    emailData,
    setSmallMessage,
  } = props;
  const classes = useStyles(props);

  const contentRef = useRef(null);
  let draggableRef = useRef();

  let scaleAmount: number = 0.5;
  const embeddedPdfRef = useRef(null);
  const [state, setState] = useState({
    numPages: 0,
    pdfPath: "",
    templateLoaded: 0,
    pageNumber: 1,
    scale: 1.75,
    pdfLink: "#",
    showEmail: false,
    emailSent: false,
    addSignature: false,
    addImage: false,
  });
  const [size, setSize] = useState({ width: 100, height: 100 });
  const [image, setImage] = useState({ path: "", name: "" });
  const [content, setContent] = useState(htmlContent);

  useEffect(() => {
    if (url && content) {
      post(url, { html: content, footer: footerHtml, header: headerHtml }).then(
        (response) => {
          if (response.errors) return;
          setState({
            ...state,
            pdfPath: response.substring(response.indexOf("/appDocs")),
            pdfLink: response,
            templateLoaded: 1,
          });
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  useEffect(() => {
    if (!content) {
      setContent(htmlContent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [htmlContent]);
  useEffect(() => {}, []);

  /**
   *
   * @param data
   */
  function onDocumentLoadSuccess(data: { numPages: any }) {
    const { numPages } = data;
    setState({ ...state, numPages });
  }

  /**
   *
   */
  // function prevPage() {
  //   let next: number = state.pageNumber;
  //   setState({ ...state, pageNumber: --next });
  // }

  // /**
  //  *
  //  */
  // function nextPage() {
  //   let next: number = state.pageNumber;
  //   setState({ ...state, pageNumber: ++next });
  // }

  /**
   *
   */
  function zoomIn() {
    setState({ ...state, scale: state.scale + scaleAmount });
  }

  /**
   *
   */
  function zoomOut() {
    setState({ ...state, scale: state.scale - scaleAmount });
  }

  /**
   * show email popup
   */
  function showEmail() {
    setState({ ...state, showEmail: !state.showEmail });
  }

  /**
   *
   */
  function printPages() {
    let pages: Array<any> = [];
    for (let i = 0; i < state.numPages; i++) {
      pages.push(<Page key={i} scale={state.scale} pageNumber={i + 1} />);
    }
    return pages;
  }

  /**
   * print pdf
   */
  function printPdf() {
    if (embeddedPdfRef.current) {
      const embeddedPdf: any = embeddedPdfRef.current;
      embeddedPdf.contentWindow.print();
    }
  }

  /**
   * onChange drop zone area => transform blob
   */

  function handleChangeIdentifyDocFile(files: any) {
    if (files.length !== 0) {
      let reader = new FileReader();
      if (files[0].type.match("image.*")) {
        reader.readAsDataURL(files[0]);
        reader.onloadend = function () {
          setImage({
            path: reader.result ? reader.result.toString() : "",
            name: files[0].name,
          });
        };
      }
    } else setImage({ path: "", name: "" });
  }

  const onResize = (e:any, resizeObj:any) => {
    e.cancelBubble = true;
    e.stopPropagation();
    e.preventDefault();
    const {size} = resizeObj;
    setSize({ width: size.width, height: size.height });
    return false;
  };

  /**
   * add existing image on top of pdf
   */
  function renderImage(isSignature: string) {
    const company = cachedData.get("company");
    if (!company.signature) {
      alert(lang.get("saveSignature") + "!");
      return null;
    }
    // @ts-ignore
    const rect = contentRef.current.getBoundingClientRect();
    const srcImage =
      isSignature === "signature" ? company.signature : image.path;
    return (
      <Draggable
        ref={draggableRef}
        width={size.width}
        height={size.height}
        initialPos={{
          x: parseInt((rect.width / 2).toString()),
          y: parseInt((rect.height / 2).toString()),
        }}
        parentOffset={{
          x: rect.left,
          y: rect.top / 2,
        }}
      >
        <ResizableBox height={size.height} width={size.width} onResize={onResize}>
          <div>
            <img
            style={{ width: "100%", height: "100%" }}
            src={srcImage}
            alt="signature"
          />
          </div>
        </ResizableBox>
      </Draggable>
    );
  }

  /**
   * add signature to html and regenerate pdf
   *
   */
  function acceptImage(isSignature: string) {
    let showImage = true;
    if (isSignature === "Image" && image.path === "") showImage = false;
    if (draggableRef && showImage) {
      // @ts-ignore
      const { position } = draggableRef.current.getPosition();
      const company = cachedData.get("company");
      const index = content.indexOf("</body>");
      let protocol = window.location.href.split("/")[0];
      // @ts-ignore
      const scrollTop = contentRef.current.scrollTop;
      const pages = parseInt((scrollTop / 1473).toString());
      console.log("y -- " + pages);
      let top;
      if (scrollTop > 2500) top = scrollTop / 1.174 + position.y;
      else top = scrollTop + position.y - 130 - 80 * 2 * pages;
      const left = position.x - 45 * state.scale;
      let src;
      if (isSignature === "Signature")
        src = `${protocol}//${document.location.host}${company.signature}`;
      else src = image.path;
      const img = `<img height=${size.height} width=${size.width} style="position:absolute; top:${top}px; left:${left}px" src="${src}" alt="image" />`;
      const c = content.slice(0, index) + img + content.slice(index);
      setContent(c);
    }
    if (isSignature === "Image") setState({ ...state, addImage: false });
    else setState({ ...state, addSignature: false });
  }

  return (
    <Dialog
      fullScreen={isMobile()}
      style={{ zIndex: 2000 }}
      maxWidth="xl"
      classes={{ paper: classes.overflowHidden }}
      open={open}
      onClose={() => onClose(state.emailSent)}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        <div style={{ display: "flex" }}>
          <div style={{ flexGrow: 2 }}>{lang.get("preview")}</div>
          <div>{props.customTopMenu && props.customTopMenu}</div>
        </div>
      </DialogTitle>
      <DialogContent ref={contentRef} style={{ background: "#eeeeee" }}>
        {htmlContent ? (
          <>
            {!state.templateLoaded ? (
              <div className={classes.loadingWrapper}>
                <CircularProgress color="secondary"></CircularProgress>
              </div>
            ) : (
              <PdfDocument
                file={state.pdfPath}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                {printPages()}
              </PdfDocument>
            )}
            {emailData ? (
              <Slide direction="up" in={state.showEmail}>
                <EmailForm
                  {...emailData}
                  docAttachmentPath={state.pdfLink}
                  onEmailSent={() =>
                    setState({ ...state, showEmail: false, emailSent: true })
                  }
                  setSmallMessage={setSmallMessage}
                />
              </Slide>
            ) : null}
          </>
        ) : (
          lang.get("loading") + "..."
        )}
        {state.addSignature ? renderImage("signature") : null}
        {image.path !== "" && state.addImage ? renderImage("image") : null}
        {state.addImage ? (
          <div className={classes.dropWrapper}>
            <DropzoneArea
              dropzoneClass={classes.dropzoneArea}
              dropzoneParagraphClass={classes.dropzoneAreaText}
              maxFileSize={5000000}
              dropzoneText={lang.get("dragFiles")}
              onChange={handleChangeIdentifyDocFile}
            />
          </div>
        ) : (
          ""
        )}
      </DialogContent>
      <DialogActions classes={{ root: classes.actionsWrapper }}>
        {state.addSignature ? (
          ""
        ) : (
          <IconButton
            disabled={state.addImage}
            size={isMobile() ? "small" : "medium"}
            onClick={() => setState({ ...state, addImage: true })}
          >
            <ImageOutlinedIcon />
          </IconButton>
        )}
        {state.addImage ? (
          <IconButton
            size={isMobile() ? "small" : "medium"}
            onClick={() => acceptImage("Image")}
          >
            <DoneIcon />
          </IconButton>
        ) : (
          <>
            <IconButton
              disabled={state.addSignature}
              size={isMobile() ? "small" : "medium"}
              onClick={() => setState({ ...state, addSignature: true })}
            >
              <GestureIcon />
            </IconButton>
            {state.addSignature ? (
              <IconButton
                size={isMobile() ? "small" : "medium"}
                onClick={() => acceptImage("Signature")}
              >
                <DoneIcon />
              </IconButton>
            ) : (
              <>
                {state.pdfPath ? (
                  <>
                    <iframe
                      style={{ display: "none" }}
                      src={`${state.pdfLink}`}
                      title="printPdf"
                      ref={embeddedPdfRef}
                    ></iframe>
                    <IconButton
                      size={isMobile() ? "small" : "medium"}
                      onClick={printPdf}
                    >
                      <PrintIcon />
                    </IconButton>
                  </>
                ) : null}
                <IconButton
                  size={isMobile() ? "small" : "medium"}
                  onClick={showEmail}
                >
                  <EmailIcon />
                </IconButton>
                <IconButton size={isMobile() ? "small" : "medium"}>
                  <a
                    href={state.pdfLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    title={lang.get("download")}
                  >
                    <GetAppIcon />
                  </a>
                </IconButton>
                <IconButton
                  size={isMobile() ? "small" : "medium"}
                  disabled={state.scale <= 0.3}
                  onClick={zoomOut}
                >
                  <ZoomOutIcon />
                </IconButton>
                <IconButton
                  size={isMobile() ? "small" : "medium"}
                  disabled={state.scale >= 2}
                  onClick={zoomIn}
                >
                  <ZoomInIcon />
                </IconButton>
              </>
            )}{" "}
          </>
        )}

        {/* <Divider orientation="vertical" />
					<IconButton size={isMobile() ? "small" : "medium"} disabled={this.state.pageNumber === 1} onClick={this.prevPage.bind(this)}>
						<ArrowBackIosIcon />
					</IconButton>
					{this.state.pageNumber} - {this.state.numPages}
					<IconButton
						disabled={this.state.pageNumber === this.state.numPages}
						onClick={this.nextPage.bind(this)}
						size={isMobile() ? "small" : "medium"}
					>
						<ArrowForwardIosIcon />
					</IconButton>*/}
        <Button
          onClick={() => props.onClose(state.emailSent)}
          style={{ background: theme.palette.errorColor?.main }}
        >
          {lang.get("close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PreviewPDF;

// function renderSignature() {
//   const company = cachedData.get("company");
//   if (!company.signature) {
//     alert(lang.get("saveSignature") + "!");
//     return null;
//   }

//   // @ts-ignore
//   const rect = contentRef.current.getBoundingClientRect();
//   return (
//     <Draggable
//       ref={draggableRef}
//       width={100}
//       height={100}
//       initialPos={{
//         x: parseInt((rect.width / 2).toString()),
//         y: parseInt((rect.height / 2).toString()),
//       }}
//       parentOffset={{
//         x: rect.left,
//         y: rect.top / 2,
//       }}
//     >
//       <img
//         style={{ width: "100px", height: "100px" }}
//         src={company.signature}
//         alt="signature"
//       />
//     </Draggable>
//   );
// }
