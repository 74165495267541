import React, { Component } from 'react';
import Lang from '../../lang';
import { Grid, Typography, Button, Paper, IconButton } from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import { DropzoneArea } from 'material-ui-dropzone';
import Canvas from '../../Wizard/components/Signature';
import Modal from '@material-ui/core/Modal';
import PropTypes from 'prop-types';
import Config from '../../Utils/Config';
import CloseIcon from "@material-ui/icons/Close";
// import noImage from '../../Images/no-image.png';
import theme from "../../Theme/Theme";
const styles = theme => ({
    logo:{
        maxWidth: 200,
        display:"block",
        margin: "0 auto",
        marginBottom:20
    },
    dropzoneAreaText:{
        fontSize:"18px",
        margin:0,
    },
    dropzoneArea:{
        marginLeft: "15px",
        minHeight: "150px !important",
      },
    
});

class OtherInfo extends Component {

    /**
     * constructor
     * @param {Object} props 
     */
    constructor(props) {
        super(props);
        this.state={
            open:false
        };
        this.lang = Lang.getInstance();
        this.sign = this.props.model.signature;
        this.logo = this.props.model.logo;
    }

    /**
     * 
     * @param {*} nextProps 
     * @param {*} nextState 
     */
    shouldComponentUpdate(nextProps, nextState){
        this.logo = nextProps.model.logo instanceof File ? Config.noImage : nextProps.model.logo;
        return true;
    }

    /**
     * 
     * @param {*} signature 
     */
    closeDialog(signature) {
        this.sign = signature.dataImage;
        this.props.onSetSignature(signature);
        this.setState({ "open": false });
        
    }


    /**
     * 
     */
    openDialog() {
        this.setState({ "open": true });
    }

    /**
     * render method
     */
    render() {
        const { classes } = this.props;
        const defaultRender = (
            <div style={{ padding: 20 }}>
                <ValidatorForm onSubmit={()=>{}}>
                    {/** description */}
                    <TextValidator
                        label={this.lang.get("description")}
                        fullWidth
                        name="description"
                        value={this.props.model.description}
                        onChange={this.props.onChange}
                        validators={['required']}
                        errorMessages={[this.lang.get("fieldRequired")]} />
                    <br /><br />
                    {/** signature*/}
                    <Typography>{this.lang.get("signature")}</Typography>
                    <Grid container direction="column" justify="center" className={classes.grid}>
                        <Button style={{background:theme.palette.header?.main}} variant="contained" onClick={this.openDialog.bind(this)}>
                            {this.lang.get("pressToSign")}
                        </Button>
                    </Grid>

                    <Grid container direction="column" justify="center" className={classes.grid}>
                        <div style={{textAlign:"center"}}>
                            {/* {this.sign ? <img alt="signature" src={this.sign} /> : <img  alt="signature" src={this.props.model.signature || noImage} />} */}
                            {(this.sign && this.sign!=='undefined') ? <img alt="signature" src={this.sign} style={{height:"100px",marginTop:"5px"}} /> : ""}
                        </div>
                    </Grid>
                    <Grid >


                    </Grid>
                    <br />
                    <Divider></Divider>
                    {/** logo */}
                    <br />
                    <Typography>{this.lang.get("logo")}</Typography>
                    <Grid container direction="column" justify="center" className={classes.grid} >
                        {/* {this.logo ? <img alt="logo" src={this.logo} className={classes.logo} /> : <img alt="logo" src={Config.noImage} className={classes.logo}  />} */}
                        {(this.logo && this.logo!=='undefined')? <img alt="logo" src={this.logo} className={classes.logo} style={{height:"100px"}}/> : "" }
                            <DropzoneArea dropzoneParagraphClass={classes.dropzoneAreaText} dropzoneClass={classes.dropzoneArea} onChange={this.props.onFileDrop} dropzoneText={this.lang.get("dragFiles")} filesLimit={1} acceptedFiles={['image/*']}></DropzoneArea>
                        
                    </Grid>
                    <br />
                </ValidatorForm>
            </div>
        );
        const canvas = (
            <Modal open={this.state.open}
            onClose={this.closeDialog.bind(this)}
                style={{
                    flex: 1,
                    flexDirection: 'row',
                    alignItems: 'center',
                    alignContent: 'center',
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <Paper>
                <IconButton onClick={this.closeDialog.bind(this)} style={{float:"right"}}>
                    <CloseIcon />
                </IconButton>
                    <Typography style={{
                        flex: 1,
                        flexDirection: 'row',
                        alignItems: 'center',
                        alignContent: 'center',
                        display: 'flex',
                        justifyContent: 'center'
                    }}>{this.lang.get("signBelow")}
                    </Typography>
                    <div>
                        <Canvas doneCallback={this.closeDialog.bind(this)} ></Canvas>
                    </div>
                    <br></br>
                </Paper>
            </Modal>
        );

        return (
            <div className={classes.container}>
                {defaultRender}
                {canvas}
            </div>
        )
    }

}

OtherInfo.propTypes = {
    model: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onFileDrop: PropTypes.func.isRequired,
    onSetSignature: PropTypes.func.isRequired
};


export default withStyles(styles)(OtherInfo);

