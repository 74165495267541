import React from "react";
import Lang from "../../lang";
import {
  withStyles,
  CircularProgress,
  Modal,
  Theme,
  createStyles,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
} from "@material-ui/core";
import {
  ResolutionBreakPoints,
  UrlEnum,
  LocalUrlEnum,
  get,
  put,
  StatusEnum,
  DocTypes,
} from "../../Utils/Utils";
import moment from "moment";
import Config from "../../Utils/Config";

import Alert from "@material-ui/lab/Alert";
import DocumentsLayout from "../../Components/DocumentsLayout";
import { Estimate,DocService } from "../../Models/Models";
import EstimateListComplete from "../../DocumentComponents/EstimateListComplete";
import { Link } from "react-router-dom";
import AssessmentIcon from "@material-ui/icons/Assessment";
import pdfIcon from "../../Images/icons/PDF-icon.png";
import DashboardContext from "../../Contexts/DashboardContext";
import PreviewDocument from "../../DocumentComponents/PreviewDocument";
import Receipt from "../../DocumentTemplates/Receipt";


const docWidth = document.body.offsetWidth;
const lang = Lang.getInstance();

const styles = (theme: Theme) =>
  createStyles({
    loading: {
      display: "block",
      margin: "0 auto",
      marginTop: 50,
      outline: 0,
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    totalsValue: {
      float: "right",
    },
    summary: {
      position: "absolute",
      bottom: 90,
      width: "88%",
    },
    summaryTitle: {
      background: theme.palette.header?.main,
      padding: 5,
      color: "#fff",
      borderRadius: 2,
    },
    generatePDFbtn: {
      fontSize: "13px",
      fontWeight: "normal",
      width: "100%",
      backgroundColor: "transparent",
    },
    generatePDFimg: {
      width: "23px",
    },
  });

type EstimatesProps = {
  changeLoadingState: Function;
  type: string;
  classes: any;
  match: any;
  history: any;
  showSmallMessage: Function;
};

type EstimatesState = {
  estimates: Array<Estimate>;
  totalNoVat: number;
  errors: string;
  loading: boolean;
  openDeleteConfirm: boolean;
  pdfDocData: any;

};

class Estimates extends React.Component<EstimatesProps, EstimatesState> {
  lang = Lang.getInstance();
  totalEstimatesCount = 0;
  isMobile: boolean;
  deleteModel: Estimate | null;

  startDate: string;
  endDate: string;
  search: string;
  page: number;
  perPage: number;

  /**
   * constructor
   * @param {Object} props
   */
  constructor(props: EstimatesProps) {
    super(props);
    this.isMobile = docWidth < ResolutionBreakPoints.md;
    this.state = {
      estimates: [],
      totalNoVat: 0,
      errors: "",
      loading: true,
      openDeleteConfirm: false,
      pdfDocData: null
    };

    this.deleteModel = null;

    const crtDate = new Date();
    const crtDateString = moment().format(Config.momentUSDateFormat);

    this.startDate = crtDate.getMonth() > 6 ?crtDate.getUTCFullYear() + "-01-01": crtDate.getUTCFullYear()-1 + "-01-01";

    this.endDate = crtDateString;
    this.page = 1;
    this.perPage = 50;
    this.search = "";
    if (this.props.match.params.startDate) {
      this.startDate = this.props.match.params.startDate;
      this.endDate = this.props.match.params.endDate;
      this.page = parseInt(this.props.match.params.page) || 1;
      this.perPage = parseInt(this.props.match.params.perPage) || 50;
      this.search = this.props.match.params.q || "";
    }
  }

  shouldComponentUpdate(nextProps: EstimatesProps, nextState: EstimatesState) {
    if (nextProps.type !== this.props.type) {
      this.getEstimates(nextProps.type);
      return false;
    }
    return true;
  }

  componentDidMount() {
    this.getEstimates();
  }

  /**
   * change crt url
   */
  changeUrl(
    startDate: string,
    endDate: string,
    search: string,
    page: number,
    perPage = 50
  ) {
    let displayUrlBase = LocalUrlEnum.estimates;
    switch (this.props.type) {
      case DocTypes.orderTicket:
        displayUrlBase = LocalUrlEnum.orderTickets;
        break;
      case DocTypes.subcontractorOrderTicket:
        displayUrlBase = LocalUrlEnum.subcontractorordertickets;
        break;
    }
    const displayUrl = `${displayUrlBase}/${startDate}/${endDate}/${page}/${perPage}/${search}`;
    this.props.history.push(displayUrl);
  }

  /**
   * get Estimates from server
   */
  async getEstimates(type = "") {
    if (!this.startDate || !this.endDate) return;
    this.setState({ loading: true });

    const docType = type ? type : this.props.type;
    const url = `${UrlEnum.estimates}/${docType.toLowerCase()}/${
      this.startDate
    }/${this.endDate}?q=${this.search}&page=${this.page}&per_page=${
      this.perPage
    }`;

    const response = await get(url);
    if (response.errors) {
      this.setState({ errors: response.errors, loading: false });
    }
    this.totalEstimatesCount = response.total_count;
    const totalNoVat: number = parseFloat(
      parseFloat(response.totalNoVat).toFixed(Config.noOfDecimals)
    );
    this.setState({
      estimates: response.items,
      totalNoVat: totalNoVat,
      loading: false,
    });
  }
  /**
   *
   * get ids for those items that are checked
   */
   getSelectedIds() {
    if (this.state.estimates) {
      const selected = this.getSelected();
      return selected.map((estimate) => estimate.id);
    }
  }
  getSelected() {
    if (this.state.estimates && this.state.estimates.length) {
      return this.state.estimates.filter(
        (estimate) => estimate.selected
      );
    }
    return [];
  }

  /**
   *  pdf generation for those items that are checked
   */
   async generatePDF() {
    let selected = this.getSelected();
    if(!selected || !selected.length){
      this.props.showSmallMessage(lang.get("nothingSelected"), StatusEnum.ERROR);
      return;
    }
    const requests = selected.map(({id}) => get(UrlEnum.estimates+"/"+id));
    Promise.all(requests).then((data)=>{
      let pdfDocData = [];
    // get all selected invoices by id
    let categServ = [];
    let totals = [];
    for (let estimate of data) {
      pdfDocData.push({
        series: estimate.series.toString(),
        no: estimate.estimateNo,
        date: moment(estimate.created_at, Config.momentEUDateFormat).format(Config.momentEUDateFormat),
        vatRate: estimate.vatRate,
        client:estimate.client
      });
      categServ.push(this.servicesByCategs(estimate));
      totals.push(Estimate.getTotals(estimate));
    }

    this.setState({
      pdfDocData: {
        docData: pdfDocData,
        categServ:categServ,
        totals: totals,
      }
    })
    });
  }
  private servicesByCategs(estimate:Estimate) {
		let categsServ: any = {};
    let invoiceTotalNoVat = 0;
		for (const service of estimate.estimate_services) {
			service.servicePrice = service.servicePrice || 0;
      invoiceTotalNoVat += parseFloat(service.servicePrice.toString()) * service.serviceQuantity;
			DocService.recalculatePrice(service, estimate.vatRate);
			
			if (!categsServ[service.serviceCategName]) {
  				categsServ[service.serviceCategName] = [service];
			} else {
				categsServ[service.serviceCategName].push(service);
			}
		}
		
		estimate.totalNoVat = invoiceTotalNoVat;
		estimate.totalWithVat = Estimate.getTotals(estimate).totalWithVat;
		return categsServ;
	}

  /**
   * render method
   */
  render() {
    const classes = this.props.classes;
    if (!this.state.loading) {
      if (this.state.errors) {
        return <Alert severity="error">{this.state.errors}</Alert>;
      }
    }
    let newDocLink = LocalUrlEnum.estimates;
    switch (this.props.type) {
      case DocTypes.orderTicket:
        newDocLink = LocalUrlEnum.orderTickets;
        break;
      case DocTypes.subcontractorOrderTicket:
        newDocLink = LocalUrlEnum.subcontractorordertickets;
        break;
    }

    return (
      <DocumentsLayout
        onChange={(startDate, endDate, search, page) => {
          this.changeUrl(startDate, endDate, search, page);
        }}
        startDate={this.startDate}
        endDate={this.endDate}
        search={this.search}
        page={this.page}
        perPage={this.perPage}
        onChangeRecurrent={() => {}}
        totalDocCount={this.totalEstimatesCount}
        isMobile={this.isMobile}
        title={this.lang.get(this.props.type + "s")}
        newDocLink={newDocLink + "/0"}
        type={this.props.type}
        match={this.props.match}
        drawerChildren={
          <List>
            <Link to="/estimates/report" className={classes.fullWidth}>
              <ListItem button>
                <ListItemIcon>
                  <AssessmentIcon />{" "}
                </ListItemIcon>
                <ListItemText primary={this.lang.get("report")} />
              </ListItem>
            </Link>
            <ListItem
              button
              id={this.lang.get("generatePdf")}
              className={classes.generatePDFbtn}
              onClick={() => this.generatePDF()}
            >
              <ListItemIcon>
                <img
                  className={classes.generatePDFimg}
                  src={pdfIcon}
                  alt="file"
                />
              </ListItemIcon>
              <ListItemText>{this.lang.get("generatePdf")}</ListItemText>
            </ListItem>
          </List>
        }
      >
        <EstimateListComplete
          select={true}
          isProject={false}
          estimates={this.state.estimates}
          showSmallMessage={this.props.showSmallMessage}
          type={this.props.type}
          key={this.state.estimates.length}
        />

        <Modal open={this.state.loading} className={classes.modal}>
          <CircularProgress color="secondary" className={classes.loading} />
        </Modal>
        {
          this.state.pdfDocData &&
          <DashboardContext.Consumer>
            {(context) =>
            (
              <PreviewDocument
                open={this.state.pdfDocData !== null}
                onClose={() => {
                  this.setState({ pdfDocData: null });
                }}
                type={this.state.estimates[0].type}
                services={this.state.pdfDocData.categServ}
                setOpenMessage={context.setOpenMessage}
                setSmallMessage={context.setSmallMessage}
                totals={this.state.pdfDocData.totals}
                id={this.state.estimates[0].id}
                docData={this.state.pdfDocData.docData}
                children={this.state.pdfDocData.receipts}
              />
            )}
          </DashboardContext.Consumer>
        }
      </DocumentsLayout>
    );
  }
}

export default withStyles(styles)(Estimates);
