import React, { useEffect, useState } from 'react';
import { Package } from '../Models/Models';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import FormSubmitButton from '../Forms/FormSubmitButton';
import { UrlEnum } from '../Utils/Utils';
import { Collapse, makeStyles, ListItemText, IconButton, List, ListItem, Typography } from '@material-ui/core';
import Lang from '../lang';
import PropTypes from 'prop-types';
import DeleteIconBtn from './DeleteIconBtn';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const lang = Lang.getInstance();

const useStyles = makeStyles((theme) => ({
    listItemText: {
        fontSize: "10pt",
        textTransform: "capitalize",
        marginLeft: 10,
        marginTop: 10,
        marginBottom: 10,
        display: "block",
        flexGrow: 0
    },
    listItemTextWrapper: {
        display: "flex",
        width: "100%",
    },
    listItem: {
        borderBottom: "1px solid #ccc",
        paddingTop: 0,
        paddingBottom: 0,
        alignItems: "center !important"
    },
    submitButton: {
        marginLeft: 20,
        height: 40,
        marginTop: 10
    },
    iconBtn: {
        padding: 10
    },

}));
let submitFunction = null;
export default function EditPackage (props) {
    const classes = useStyles();
    const [pack, setPackage] = useState(props.pack || new Package());
    const [openPack, setOpenPack] = useState(props.open || false);

    useEffect(() =>{
        setPackage(props.pack)
    },[props.pack])

    /**
     * extend package
     * @param {*} e 
     */
    function handleOpenPackage (e) {
        e.preventDefault();
        const open = !openPack;
        setOpenPack(open);
        if (open && props.getActive) {
            props.getActive(pack);
        }
        return false;
    }

    /**
     * handle name change
     * @param {*} e 
     */
    function handleInputChange (e) {
        let p = Object.assign({}, pack);
        p.name = e.target.value;
        setPackage(p);
    }

    /**
     * save package
     * @param {*} e 
     */
    function submitPackage (e) {
        e.preventDefault();
        if (submitFunction) {
            submitFunction(pack);
        }
        return false;
    }

    /**
    * delete service
    * @param {*} data
    */
    function deleteServiceSuccess (data) {
        for (let i = 0; i < pack.services.length; i++) {
            let service = pack.services[i];
            if (service.id === data.serviceIds[0]) {
                pack.services.splice(i, 1);
                const newPack = Object.assign({}, pack);
                setPackage(newPack);
                return;
            }
        }
    }

    function renderService (service, index) {
        return (
            <ListItem key={service.id + index} className={classes.listItem}>
                <ListItemText>
                    {service.name}
                </ListItemText>
                <DeleteIconBtn
                    url={UrlEnum.servicePackages + "/services/" + pack.id}
                    onSuccess={deleteServiceSuccess}
                    data={{ serviceIds: [service.id] }}
                />
            </ListItem>
        );
    }

    return (
        <div style={{ flexGrow: 1 }} >
            <div
                className={classes.listItemTextWrapper}
            >
                <ListItemText
                    onClick={handleOpenPackage}
                    primary={pack.name}
                    classes={{ primary: classes.listItemText }}
                    style={{ marginTop: 0 }}
                />
                <IconButton
                    className={classes.iconBtn}
                    onClick={handleOpenPackage}
                >
                    {openPack ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
            </div>
            <Collapse unmountOnExit in={openPack} style={{ paddingLeft: 10 }} >
                <ValidatorForm onSubmit={submitPackage}
                    instantValidate={true}
                >
                    <div style={{ display: "flex" }}>
                        <TextValidator
                            label={lang.get("name")}
                            name="name"
                            value={pack.name}
                            onChange={handleInputChange}
                            style={{ flexGrow: 1 }}
                            validators={["required"]}
                            errorMessages={lang.get("fieldRequired")}
                        />

                        <FormSubmitButton
                            classes={{ root: classes.submitButton }}
                            getSubmit={(fct) => submitFunction = fct}
                            url={UrlEnum.servicePackages}
                            submitCallback={props.onEdit ? props.onEdit : undefined}
                        />
                    </div>
                    <br />
                    {
                        pack.services && pack.services.length > 0 ?
                            <Typography >{lang.get("services")}</Typography>
                            : ""
                    }
                    <List>
                        {pack.services.map((service, index) => renderService(service, index))}
                    </List>
                </ValidatorForm>
            </Collapse>
        </div>
    );
}

EditPackage.propTypes = {
    pack: PropTypes.object.isRequired,
    getActive: PropTypes.func,
    open: PropTypes.bool,
    onEdit: PropTypes.func
};