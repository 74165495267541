import React from "react";
import { SvgIcon } from "@material-ui/core";

export default function IconDocuments(props)
{
    return(
    <SvgIcon viewBox="0 0 124 124" style={{ width: props.width ? props.width:20, height: props.height?props.height:18, marginTop:"4px"}}>
	    <path fill={props.color} id="Path 0" fillRule="evenodd" d="M4.91 0L101 0L106 5L106 34.5C106 50.72 105.77 64.67 105.5 65.5C105.22 66.32 104.21 67.01 103.25 67.02C102.29 67.03 100.93 66.46 100.23 65.77C99.27 64.8 98.97 57.64 99 7L7 7L7 116L33.64 116.25C57.2 116.47 60.41 116.69 61.39 118.16C62.24 119.43 62.14 120.21 61 121.46C59.68 122.89 56.21 123.08 5 123L0 118L0.09 4.91L4.91 0ZM52.25 34.02C77.65 34 80.08 34.15 80.98 35.75C81.69 37.04 81.49 37.96 80.23 39.24C78.66 40.81 75.94 40.95 22.5 40.5L22.19 38C22.02 36.62 22.47 35.17 23.19 34.77C23.91 34.36 36.99 34.02 52.25 34.02ZM52.25 54.08C77.69 54.01 80.08 54.15 80.98 55.75C81.69 57.04 81.49 57.96 80.23 59.22C78.68 60.77 75.72 60.95 51.75 60.97C37.04 60.99 24.32 60.77 23.5 60.5C22.67 60.22 21.91 58.91 21.8 57.58C21.69 56.23 22.24 54.95 23.05 54.66C23.85 54.38 36.99 54.12 52.25 54.08ZM52.25 73.02C77.65 73 80.08 73.15 80.98 74.75C81.69 76.04 81.49 76.96 80.23 78.24C78.66 79.81 75.94 79.95 22.5 79.5L22.19 77C22.02 75.62 22.47 74.17 23.19 73.77C23.91 73.36 36.99 73.02 52.25 73.02ZM94.91 75.01C95.79 75.01 99.09 77.04 102.25 79.51C105.42 81.99 107.99 84.78 107.97 85.75C107.95 86.71 106.83 88.74 103 93L96.06 87.75C92.24 84.86 89.09 82.16 89.06 81.75C89.02 81.34 89.97 79.65 91.16 78C92.36 76.35 94.04 75 94.91 75.01ZM86.19 84.14L92.3 88.82C95.66 91.39 99.04 93.95 99.82 94.5C100.98 95.31 100.01 97.11 94.76 104C91.19 108.67 87.64 112.98 86.88 113.58C86.12 114.17 83.02 115.59 80 116.73C76.97 117.87 73.71 119.07 72.75 119.4C71.24 119.92 71.01 119.35 71.07 115.25C71.11 112.64 71.45 108.6 71.82 106.28C72.32 103.2 74.35 99.63 79.35 93.1L86.19 84.14ZM73.7 116C73.89 116.27 76.29 115.37 79.04 114L84.04 111.5C79.75 107.99 77.76 106.53 76.85 105.99C75.44 105.15 75.07 105.76 74.29 110.26C73.79 113.14 73.52 115.72 73.7 116ZM46.11 91C68.57 91 69.94 91.1 69.02 92.75C68.48 93.71 67.46 95.28 66.77 96.23C65.62 97.78 63.34 97.93 43.61 97.73C24.47 97.53 21.58 97.29 20.61 95.84C19.77 94.59 19.84 93.78 20.86 92.59C22.04 91.22 25.53 91 46.11 91Z" />
    </SvgIcon>
    );
}