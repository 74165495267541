import React from "react";
import {
  Grid,
  InputLabel,
  withStyles,
} from "@material-ui/core";
import Lang from "../../lang";
import moment from "moment";
import Config from "../../Utils/Config";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import MuiPhoneInput from 'material-ui-phone-number';

const lang = Lang.getInstance();

const useStyles = ((theme) =>
  Object.assign({
    textField: {
      margin: "15px",
    },
  })
);
class StripePersonalDates extends React.Component{
  constructor(props)
  {
    super(props);
    this.state={
      model: props.stripeAccountModel,
      currentDate : moment().format(Config.momentUSDateFormat),
    }
  }
  formRef = React.createRef();

   handleInputChange(event) {
    // console.log(formRef);
    const currModel=this.state.model;
    let name = event.currentTarget.name;
    let value = event.currentTarget.value;
    currModel[name]= value;
    this.setState({"model":currModel});
  }
  handleOnChangeMobile(val)
  {
    const currModel=this.state.model;
    currModel.phoneNumber= val;
    this.setState({"model":currModel});
  }
   onChangeDate(momentDate) {
    const currModel=this.state.model;
    if (momentDate === null) return false;
    let md = momentDate;
    if (md.isAfter(moment(this.currentDate))) return false;
    md = md.format(Config.momentUSDateFormat);
    currModel.dateOfBirth = md;
    this.setState({model:currModel});

  }
  validate () {
    let form = this.formRef.current;
    return form.isFormValid(false);
}
   renderModalComponent() {
    const classes =this.props.classes;
    return (
      <ValidatorForm ref={this.formRef} instantValidate={false} onSubmit={() => {}}>
        <Grid style={{ width: "90%", margin: "0 auto", padding: "20px" }}>
          <TextValidator
            name="lastName"
            label={lang.get("familyName")}
            size="small"
            variant="outlined"
            value={this.state.model.lastName}
            className={classes.textField}
            onChange={(event) => this.handleInputChange(event)}
            fullWidth
            required={true}
            validators={['required']}
            errorMessages={lang.get("fieldRequired")}
          />
          <TextValidator
            name="firstName"
            label={lang.get("firstName")}
            size="small"
            variant="outlined"
            value={this.state.model.firstName}
            className={classes.textField}
            onChange={(event) => this.handleInputChange(event)}
            fullWidth
            required={true}
            validators={['required']}
            errorMessages={lang.get("fieldRequired")}
          />
          <TextValidator
            name="personalId"
            label={lang.get("personalIdNumber")}
            size="small"
            variant="outlined"
            value={this.state.model.personalId}
            className={classes.textField}
            onChange={(event) => this.handleInputChange(event)}
            type="number"
            fullWidth
            required={true}
            validators={['required']}
            errorMessages={lang.get("fieldRequired")}
          />
          <MuiPhoneInput
            name="phoneNumber"
            label={lang.get("mobile")}
            autoFormat={false}
            defaultCountry='be'
            enableLongNumbers={true}
            onlyCountries={Config.phonePrefixCountries}
            size="small"
            variant="outlined"
            value={this.state.model.phoneNumber}
            className={classes.textField}
            onChange={(event) => this.handleOnChangeMobile(event)}
            fullWidth
            required={true}
            regions={'europe'}
            validators={['required']}
            errorMessages={lang.get("fieldRequired")}
          />
          <InputLabel style={{ margin: "0 0 5px 15px" }}>
            {lang.get("dateOfBirth")}
          </InputLabel>
          <KeyboardDatePicker
            autoOk={true}
            allowKeyboardControl
            disableToolbar
            variant="dialog"
            name="dateOfBirth"
            fullWidth
            className={classes.textField}
            style={{ marginTop: 0 }}
            value={this.state.model.dateOfBirth}
            onChange={(event) => this.onChangeDate(event)}
            format={Config.momentEUDateFormat}
          ></KeyboardDatePicker>
        </Grid>
      </ValidatorForm>
    );
  }
  render(){
  return <>{this.renderModalComponent()} </>;
  }
}
export default withStyles(useStyles, { withTheme: true })(StripePersonalDates);
