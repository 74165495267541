import React from "react";
import Lang from "../../lang";
import { Address } from "../../Models/Models";
import { createStyles, Divider, makeStyles } from "@material-ui/core";
import { Theme } from "@nivo/core";

const lang = Lang.getInstance();

/** document styles **/
export const allStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerWrapper: {
      marginRight: -20,
      fontSize: "11pt"
    },
    invoiceText: {
      fontSize: "30px",
      margin: 0,
      textTransform: "uppercase"
    },
    logoWrapper: {
      width: "48%",
      display: "inline-table",
      marginLeft: 10,
      verticalAlign: "top"
    },
    companyWrapper: {
      width: "48%",
      display: "inline-table",
      marginRight: 15,
      verticalAlign: "top",
      fontSize: '11pt',
    },
    docInvoiceInfoWrapper: {
      width: "48%",
      display: "inline-table",
      marginLeft: 5,
      verticalAlign: "top"
    },
    clientWrapper: {
      width: "48%",
      display: "inline-table",
      marginRight: -10,
      verticalAlign: "top",
      fontSize: '11pt',
    },
    logo: {
      maxHeight: 100
    },
    clear: {
      clear: "both",
      borderBottom: "10px solid #ccc",
      marginBottom: 20,
      marginTop: 15,
      marginRight: 15
    }
  })
);

export default function NoticeHeader(props: Props) {
  const company = props.company;
  const client = props.client;

  return (
    <div
      style={{
        marginRight: -20,
        fontSize: "11pt",
        marginBottom: 20
      }}
    >
      <div
        style={{
          width: "48%",
          display: "inline-table",
          marginLeft: 10,
          verticalAlign: "top",
          fontSize: '11pt',
        }}
      >
        { (company.logo && company.logo!=='undefined') ? (
          <img
            src={company.logo}
            alt="logo"
            style={{
              maxHeight: 100
            }}
          />
        ) : (
          <p
            style={{
              fontSize: "30px",
              margin: 0,
              textTransform: "uppercase"
            }}
          >
            {company.name}
          </p>
        )}
      </div>
      <div
        style={{
          width: "48%",
          display: "inline-table",
          marginLeft: 5,
          verticalAlign: "top",
          textAlign: "center",
          fontSize: '11pt',
        }}
      >
        {/* <h1
          style={{
            margin: 0,
            color: props.color ? props.color : "#000",
            fontSize: "25pt",
            textTransform: "uppercase",
            textAlign: "center"
          }}
        >
          {"DAVANY SPRL"}
        </h1>
        <p>{"Entreprise générale de bâtiment"}</p>
        <br /> */}
      </div>
      <Divider />
      {props.appearance !== 1 ? (
        <div
          style={{
            clear: "both",
            borderBottom: "10px solid #ccc",
            marginBottom: 20,
            marginTop: 15,
            marginRight: 15,
            borderColor: props.color
          }}
        ></div>
      ) : ""}
      <h2
        style={{
          display: "flex",
          verticalAlign: "top",
          justifyContent: "center",
          alignContent: "center",
          textAlign: "center",
          fontSize: '15pt',
        }}
      >
        {lang.get("notice")}
      </h2>
      <div
        style={{
          width: "48%",
          display: "inline-table",
          marginRight: 15,
          verticalAlign: "top",fontSize: '11pt',
        }}
      >
        <h3 style={{ margin: 0,fontSize: '11pt', }}>{lang.get("supplier")}</h3>

        <p>
          <strong>{company.name || ""}</strong>
          <br />
          <strong>{lang.get("vatNo")}</strong>: {company.vatNo !== 'null' ? company.vatNo :""}
          <br />
          <strong>{lang.get("companyRegNo")}</strong>: {company.companyRegNo || ""}
          <br />
          <strong>{lang.get("address")}</strong>:{" "}
          {Address.stringify(company.address)}
          <br />
          <strong>{lang.get("bankName")}</strong>: {company.bankName}
          <br />
          <strong>{lang.get("bankAccount")}</strong>: {company.bankAccount}
        </p>
      </div>

      <div
        style={{
          width: "48%",
          display: "inline-table",
          marginRight: -10,
          verticalAlign: "top",fontSize: '11pt',
        }}
      >
        <h3 style={{ margin: 0,fontSize: '11pt', }}>{lang.get("client")}</h3>

        <p>
        <strong>{client.title}</strong>{" "}<strong>{client.name}</strong>
          <br />
          <strong>{lang.get("vatNo")}</strong>: {client.vatNo !== 'null' ?client.vatNo:""}
          <br />
          <strong>{lang.get("address")}</strong>:{" "}
          {Address.stringify(client.address)}
        </p>
      </div>
    </div>
  );
}

type Props = {
  company: any;
  series?: string;
  number?: string;
  date?: string;
  vatRate?: number;
  client: any;
  appearance?: number;
  color?: string;
};
