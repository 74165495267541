import React, { useState } from "react";
// import DocumentsOverview from '../DashboardComponents/Overview/DocumentsOverview';
import Container, { ContainerProps } from "./Cotainer";
import Section from "./Section";
// import { FormControlLabel, IconButton, Switch, Tooltip } from '@material-ui/core';
// import Lang from "../../lang";
import { v4 as uuidv4 } from "uuid";
// import ViewDayIcon from '@material-ui/icons/ViewDay';
import MenuIcons from "../DashboardComponents/MenuIcons";
import OnlinePayments from "../DashboardComponents/OnlinePayments";
// import CachedDataSingleton from "../../cachedDataSingleton";
import DocumentsTables from "./DocumentsTables";
import { readCookie} from '../../Utils/Utils';
import NotificationsDashboard from "../DashboardComponents/NotificationsDashboard";

// const lang = Lang.getInstance();

type FlexibleDashboardProps = {
  showSmallMessage:Function,
  setLoading:Function,
};


function FlexibleDashboard(props: FlexibleDashboardProps) {
 
  // const cachedData = CachedDataSingleton.getInstance();
  const cookie= readCookie('userRoles');
  const userRoles= JSON.parse(cookie);
  const defaultConf = {
    sections: [
      {
        id: uuidv4(),
        containers: [
          {
            id: uuidv4(),
            width: 12,
            children: <MenuIcons />,
          },
        ],
      },
      {
        id: uuidv4(),
        containers: [
          {
            id: uuidv4(),
            width: 6,
            children:<OnlinePayments showSmallMessage={props.showSmallMessage} setLoading={props.setLoading} />
          },
          {
            id: uuidv4(),
            width: 6,
            children:<NotificationsDashboard/>,
          },
        ],
      },
      {
        id: uuidv4(),
        containers: [
          {
            id: uuidv4(),
            width: 12,
            children:   <DocumentsTables
            userRoles={userRoles}
            showSmallMessage={props.showSmallMessage}
          />
          },
        ],
      },
    ],
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [edit, setEdit] = useState(false);
  const [components, setComponents] = useState(defaultConf);

  /**
   *
   * @param id find container or section by id
   * @param parent
   */
  const findById = (id: string, parent: any) => {
    for (const component of parent) {
      if (component.id === id) {
        return [component, parent];
      }
      if (component.containers) {
        const c: any = findById(id, component.containers);
        if (c) {
          return c;
        }
      }
      if (component.sections) {
        const c: any = findById(id, component.sections);
        if (c) {
          return c;
        }
      }
    }
    return null;
  };

  /**
   * remove container or section by id
   * @param id
   */
  const remove = (id: string) => {
    const component = findById(id, components.sections);
    if (component) {
      let [c, parent] = component;
      const index = parent.findIndex((item: any) => item === c);
      if (index >= 0) {
        parent.splice(index, 1);
        setComponents({ ...components });
      }
    }
  };

  /**
   * add container to section
   * @param parentId
   * @param width
   */
  const addContainer = (parentId: string, width: number = 12) => {
    const item = findById(parentId, components.sections);
    if (item) {
      let [c] = item;
      if (!c.containers) {
        c.containers = [];
      }
      c.containers.push({
        id: uuidv4(),
        width: width,
        children: <div>.</div>,
      });
      setComponents({ ...components });
    }
  };

  /**
   *
   * @param parentId
   */
  const addSection = (parentId?: string | undefined) => {
    if (parentId) {
      const item = findById(parentId, components.sections);
      if (item) {
        let [section, parent] = item;
        const index = parent.findIndex((item: any) => item.id === section.id);
        if (index >= 0) {
          parent.splice(index + 1, 0, {
            id: uuidv4(),
            containers: [],
          });
          setComponents({ ...components });
        }
      }
    } else {
      components.sections.push({
        id: uuidv4(),
        containers: [],
      });
    }
  };

  /**
   * change a property in a container
   * @param id
   * @param key
   * @param value
   */
  const changeContainerProp = (id: string, key: string, value: any) => {
    const item = findById(id, components.sections);
    if (item) {
      let [c] = item;

      c[key] = value;
      setComponents({ ...components });
    }
  };

  /**
   * change container size
   */
  const changeContainerSize = (id: string, width: number) => {
    changeContainerProp(id, "width", width);
  };

  const changeContainerWidget = (id: string, widget: JSX.Element) => {
    changeContainerProp(id, "children", widget);
  };

  /**
   * render a container
   * and sub sections
   * @param container
   */
  const renderContainer = (container: ContainerProps, key: string) => {
    return (
      <Container
        style={{
          paddingRight:0
        }}
        width={container.width}
        key={key}
        edit={edit}
        removeContainer={() => {
          remove(container.id as string);
        }}
        changeSize={(width: number) =>
          changeContainerSize(container.id as string, width)
        }
        changeWidget={(widget: JSX.Element) =>
          changeContainerWidget(container.id as string, widget)
        }
      >
        {container.children ? container.children : null}
        {container.sections
          ? container.sections.map((section: any, index: number) =>
              renderSection(section, "subsection" + index)
            )
          : null}
      </Container>
    );
  };

  /**
   * render a section
   * @param section
   */
  const renderSection = (section: any, key: string) => {
    return (
      <Section
        edit={edit}
        key={key}
        removeSection={() => remove(section.id as string)}
        addContainer={(width: number) =>
          addContainer(section.id as string, width)
        }
        addSection={() => addSection(section.id as string)}
      >
        {section.containers
          ? section.containers.map((container: ContainerProps, index: number) =>
          renderContainer(container, "container" + index))
          : null}
      </Section>
    );
  };

  return (
    <div style={{marginRight:"25px"}}>
      <div style={{ position: "absolute", top: 70, right: 10, zIndex: 10 }}>
        <>
          {/* <FormControlLabel
                        control={
                            <Switch
                                checked={edit}
                                onChange={() => setEdit(!edit)}
                                name="checkedB"
                            />
                        }
                        label={lang.get("edit")}
                    />
                     {edit && <Tooltip title={`${lang.get("add")} ${lang.get("section")}`}>
                        <IconButton
                            size="small"
                            onClick={() => addSection()} >
                            <ViewDayIcon />
                        </IconButton>
                    </Tooltip>
                    } */}
        </>
      </div>
      {components.sections.map((section: any, index: number) =>
        renderSection(section, "section" + index)
      )}
    </div>
  );
}

export default FlexibleDashboard;
