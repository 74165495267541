import React from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import {
  CheckCircleOutline,
  Close,
  NotificationImportant,
  Visibility
} from "@material-ui/icons";
import Lang from "../../lang";
import NotificationPreview from "./NotificationPreview";

const lang: any = Lang.getInstance();
type CustomOptionsProps = {
  model: any;
  style: any;
  previewColor: any;
  updateColor: any;
  notificationColor: any;
  defaultColor: any;
  handleEditClickPreview: (model: any) => void;
  handleClickUpdateReminderDate: (model: any) => void;
  handleClickSetBadPayer: (model: any) => void;
  handleEditClickNotificationPreview: (model: any) => void;
};

export default function CustomOptions(props: CustomOptionsProps) {
  const {
    model,
    style,
    previewColor,
    updateColor,
    notificationColor,
    defaultColor,
    handleEditClickPreview,
    handleClickUpdateReminderDate,
    handleClickSetBadPayer,
    handleEditClickNotificationPreview
  } = props;
  return (
    <div className={style}>
      <Tooltip title={lang.get("reminder") + " " + lang.get("preview")}>
        <IconButton
          color="inherit"
          className={previewColor}
          onClick={() => {
            handleEditClickPreview(model);
          }}
        >
          <Visibility></Visibility>
        </IconButton>
      </Tooltip>
      <Tooltip
        title={
          lang.get("update") +
          " " +
          lang.get("reminder") +
          " " +
          lang.get("date")
        }
      >
        <IconButton
          color="inherit"
          className={updateColor}
          onClick={() => {
            handleClickUpdateReminderDate(model);
          }}
        >
          <CheckCircleOutline></CheckCircleOutline>
        </IconButton>
      </Tooltip>

      <Tooltip title={lang.get("notification")}>
        <IconButton
          color="inherit"
          className={notificationColor}
          onClick={() => {
            handleEditClickNotificationPreview(model);
          }}
        >
          <NotificationImportant></NotificationImportant>
        </IconButton>
      </Tooltip>

      <Tooltip title={lang.get("badpayee")}>
        <IconButton
          className={defaultColor}
          color="inherit"
          onClick={() => {
            handleClickSetBadPayer(model);
          }}
        >
          <Close></Close>
        </IconButton>
      </Tooltip>
    </div>
  );
}
