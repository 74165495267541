import React from "react";
import {
  Grid,
  withStyles,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from "@material-ui/core";
import Lang from "../../lang";
import { ValidatorForm } from "react-material-ui-form-validator";
import { DropzoneArea } from "material-ui-dropzone";
import {mccValues} from './stripeMcc';

const lang = Lang.getInstance();
const useStyles = ((theme) =>
  Object.assign({
    textField: {
      margin: "15px",
    },
    dropzoneArea:{
      marginLeft: "15px",
      minHeight: "50px !important",
    },
    dropzoneAreaText:{
      fontSize:"18px",
      margin:0,
    }

  })
);
class StripeUploadPhoto extends React.Component {
  constructor(props)
  {
    super(props);
    this.state={
      model: props.stripeAccountModel,
      industries: mccValues,
    }
  }
  formRef = React.createRef();
  

  handleSelectIndustries(event)
  {
    const currModel=this.state.model;
    let value = event.target.value;
    currModel.industry= value;
    this.setState({model:currModel});
  }

  handleChangeIdentifyDocFile(files){
    let currModel = this.state.model;
    if(files.length!==0)
      currModel.identityDoc = files instanceof Array ? files[0]:files;
		this.setState({model: currModel});
	}
  handleChangeVerifyDocFile(files){
    let currModel = this.state.model;
    if(files.length!==0)
    currModel.verifyAddress = files instanceof Array ? files[0]:files;
		this.setState({model: currModel});
	}
  validate () {
    let form = this.formRef.current;
    return form.isFormValid(false);
  }

  renderModalComponent() {
    const classes =this.props.classes;
    return (
      <ValidatorForm ref={this.formRef} instantValidate={false} onSubmit={() => {}}>
        <Grid style={{ width: "90%", margin: "0 auto", padding: "20px" }}>
          <FormControl fullWidth className={classes.textField}>
            <InputLabel>{lang.get("industry")}</InputLabel>
            <Select
              fullWidth
              name="industries"
              id="industry"
              required
              value={this.state.model.industry}
              onChange={(e) => this.handleSelectIndustries(e)}
            >
              {this.state.industries.map((industry) => (
                <MenuItem
                  value={industry.code}
                  id="industry_fk_id"
                  key={industry.code}
                >
                  {industry.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
            <InputLabel className={classes.textField}>{lang.get("verifyIdentityDocument")}</InputLabel>
            <DropzoneArea
                    dropzoneClass={classes.dropzoneArea}
                    dropzoneParagraphClass={classes.dropzoneAreaText}
                    value={this.state.model.identityDoc}
                    maxFileSize={5000000}
                    dropzoneText={lang.get("dragFiles")}
					          onChange={this.handleChangeIdentifyDocFile.bind(this)} 
                    required={true}
            />
            <InputLabel className={classes.textField}>{lang.get("verifyHomeAddress")}</InputLabel>
            <DropzoneArea
                    dropzoneClass={classes.dropzoneArea}
                    dropzoneParagraphClass={classes.dropzoneAreaText}
                    value={this.state.model.verifyAddress}
                    maxFileSize={5000000}
                    dropzoneText={lang.get("dragFiles")}
					          onChange={this.handleChangeVerifyDocFile.bind(this)} 
                    required={true}
            />
         
        </Grid>
      </ValidatorForm>
    );
  }

  render(){
    
    return <>{this.renderModalComponent()} </>;
  }
}
export default withStyles(useStyles, { withTheme: true })(StripeUploadPhoto);
