import React from "react";
import { InputLabel, OutlinedInput } from "@material-ui/core";
import Lang from "../../lang";
import Config from "../../Utils/Config";
type Colors = {
  defaultColor: any;
  warningColor: any;
  errorColor: any;
};
export function LiabilityLegend(props: Colors) {
  const { defaultColor, warningColor, errorColor } = props;
  const lang = Lang.getInstance();
  return (
    <div style={{ marginBottom: 20, display: "flex" }}>
      <OutlinedInput
        className={defaultColor}
        style={{
          width: 40,
          height: 30,
          marginRight: 10
        }}
        disabled
      />
      <InputLabel
        style={{
          marginRight: 20,
          justifyContent: "center",
          alignItems: "center",
          display: "flex"
        }}
      >
        {"< " + Config.costInvoiceDefaultPaymentDefault + " "}
        {lang.get("days")}
      </InputLabel>
      <OutlinedInput
        className={warningColor}
        style={{
          width: 40,
          height: 30,
          marginRight: 10
        }}
        disabled
      />
      <InputLabel
        style={{
          marginRight: 20,
          justifyContent: "center",
          alignItems: "center",
          display: "flex"
        }}
      >
        {Config.costInvoiceDefaultPaymentDefault +
          " > " +
          Config.costInvoiceDefaultPaymentDelay +
          " "}
        {lang.get("days")}
      </InputLabel>
      <OutlinedInput
        className={errorColor}
        style={{
          width: 40,
          height: 30,
          marginRight: 10,
          border: 5
        }}
        disabled
      />
      <InputLabel
        style={{
          marginRight: 20,
          justifyContent: "center",
          alignItems: "center",
          display: "flex"
        }}
      >
        {">= " + Config.costInvoiceDefaultPaymentDelay + " "}
        {lang.get("days")}
      </InputLabel>
    </div>
  );
}
