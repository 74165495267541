import React, { useState, useEffect, Fragment } from 'react';
import { List, ListItem, makeStyles} from '@material-ui/core';
import EditArticle from './EditArticle';
import { UrlEnum } from '../Utils/Utils';
import PropTypes from 'prop-types';
import theme from '../Theme/Theme';

const useStyles = makeStyles(theme => ({
    listItem: {
        borderBottom: "1px solid #ccc",
        paddingTop: 0,
        paddingBottom: 0,
        alignItems:"start !important"
    },

}));

export default function ArticlesExpandableList(props) {
    const [articles, setArticles] = useState(props.articles);    
    const classes = useStyles(props);

    //change articles on props change
    useEffect(() => {
        setArticles(props.articles);
    }, [props.articles]);

    useEffect(() => {
        if(props.getSelected){
            props.getSelected(getSelected());
        }
    });

    /**
     * change the selected value of the article
     * @param {SyntheticEvent} e 
     * @param {Object} article
     */
    const changeSelection = (e, article) => {
        article.selected = article.selected ? !article.selected : true;
        const newArticles = articles.slice();
        setArticles(newArticles);
    };
    
    /**
     * return selected articles
     */
    const getSelected = () =>{
        let f = articles.filter((el)=>el.selected);
        return f;
    };

  
    /**
     * render articles
     */
    const renderArticles = () => {
        return articles.map((article, index) =>
            <Fragment key={article.id || index} >
                <ListItem button className={classes.listItem} >
                    <input type="checkbox" onChange={(e) => changeSelection(e, article)} style={{color:theme.palette.header?.main, marginTop:"15px"}}/>
                    <EditArticle model={article} open={article.id && article.id > 0 ? false : true } url={UrlEnum.articles} />
                   
                </ListItem>
            </Fragment>
        );
    };

    return (
        <Fragment>
            <List>
                 {renderArticles()} 
            </List>
        </Fragment>
    );
}

ArticlesExpandableList.propTypes = {
    articles: PropTypes.array,
    getSelected:PropTypes.func
};