import React from "react";
import Lang from "../lang";
import FormComponent from "./FormComponent";
import { Button, Grid, TextField } from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import CachedDataSingleton from "../cachedDataSingleton";
import MuiPhoneInput from "material-ui-phone-number";
import { withStyles } from "@material-ui/core/styles";
import Config from "../Utils/Config";
import { v4 as uuidv4 } from "uuid";


const styles = (theme) => ({
  fullWidth: {
    width: "100%",
  },
  generateBtn:{ 
    background: theme.palette.header?.main,
    color: theme.palette.textColorSecondary?.main
  },
  divGenerateBtn:{
    width:"18%",
    display:"inline-block", 
    position:"relative", 
    top:"20px",
    textAlign:"right", 
    marginLeft:"15px"
  },
  txtCode:{ 
    width:"80%",
    display:"inline-block"
  },
  noMargin: {
    margin: 0,
  },
  noPaddingTop: {
    paddingTop: "0 !important",
  },
  gMap: {
    height: 400,
  },
});
class EditEmployees extends FormComponent {
  /**
   * constructor
   * @param {Object} props
   */
  constructor(props) {
    super(props);
    this.lang = Lang.getInstance();
    this.cachedData = CachedDataSingleton.getInstance();
    this.mapWrapperRef = React.createRef();
    this.editedModel = null;
  }

  /**
   * props have changed
   * @param {Object} nextProps
   * @param {Object} prevState
   */
  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.model && nextProps.model.id !== prevState.model.id) {
      return { model: nextProps.model };
    }
    return null;
  }

  /**
   * Change phone number
   * @param {string} value
   */
  handlePhoneNoChange(value) {
    let model = Object.assign({}, this.state.model);
    model.mobile = value;
    this.setState({ model: model });
  }
  generateCode(){
    let code = uuidv4();
    let currModel =Object.assign({}, this.state.model);
    currModel.imei = code.substring(0, 10);
    this.setState({model: currModel});
  }

  /**
   * render method
   */
  render() {
    const { classes } = this.props;
    return (
      <>
        <h3 style={{ marginLeft: 20 }}>
          {this.lang.get("edit")} {this.lang.get("employee")}
        </h3>
        <div style={{ padding: "0 20px 20px 20px" }}>
          <ValidatorForm onSubmit={this.submit.bind(this)}>
            <Grid container spacing={1}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                className={classes.noPaddingTop}
              >
                {/** name  */}
                <TextValidator
                  label={this.lang.get("name")}
                  className={classes.fullWidth}
                  name="name"
                  value={this.state.model.name}
                  onChange={this.handleInputChange.bind(this)}
                  validators={["required"]}
                  errorMessages={[this.lang.get("fieldRequired")]}
                />

                {/** phone number  */}
                <MuiPhoneInput
                  className={classes.fullWidth}
                  onlyCountries={Config.phonePrefixCountries}
                  defaultCountry="be"
                  required={true}
                  id="mobile"
                  enableLongNumbers
                  label={this.lang.get("mobile")}
                  onChange={this.handlePhoneNoChange.bind(this)}
                  name="mobile"
                  value={this.state.model.mobile}
                  regions={"europe"}
                  validators={["minNumber:5"]}
                />
                <div >
                {/** code  */}
                <div  className={classes.txtCode} >
                <TextValidator
                  name="imei"
                  label={this.lang.get("code")}
                  className={classes.fullWidth}
                  value={this.state.model.imei || ""}
                  validators={["required"]}
                  errorMessages={[this.lang.get("fieldRequired")]}
                  disabled={true}
                />
                </div>
                <div className={classes.divGenerateBtn}>
                  <Button fullWidth  className={classes.generateBtn} onClick={this.generateCode.bind(this)}>
                  {this.lang.get("generate")}
                  </Button>
                </div>
                </div>
                {/** Personal ID Number*/}
                <TextValidator
                  name="nationalId"
                  onChange={this.handleInputChange.bind(this)}
                  label={this.lang.get("personalIdNumber")}
                  className={classes.fullWidth}
                  value={this.state.model.nationalId || ""}
                  validators={["required"]}
                  errorMessages={[this.lang.get("fieldRequired")]}
                />

                {/** Personal ID Number*/}
                <TextField
                  type="number"
                  name="pricePerHour"
                  onChange={this.handleInputChange.bind(this)}
                  label={this.lang.get("pricePerHour")}
                  className={classes.fullWidth}
                  value={this.state.model.pricePerHour || ""}
                />
              </Grid>
            </Grid>
            <br />
            <br />
            {super.render()}
          </ValidatorForm>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(EditEmployees);
