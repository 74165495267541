import { List, ListItem, ListItemText, Divider } from "@material-ui/core";
import CachedDataSingleton from "../cachedDataSingleton";
import Lang from "../lang";
import moment from "moment";
import Config from "../Utils/Config";

const lang = Lang.getInstance();
const cachedData = CachedDataSingleton.getInstance();
export default function NotificationsList() {
  const notifications = cachedData.get("notifications");

  return (
    <List>
      {notifications.length === 0 ? (
        <ListItem style={{ textAlign: "center" }}>
          <ListItemText>
            {lang.get("noNewNotifications").toUpperCase()}
          </ListItemText>
        </ListItem>
      ) : (
        notifications.map((notification) => (
          <>
            <ListItem key={notification.id}>
              <ListItemText
                primary={
                  notification.active ? (
                    <strong>{notification.notification}</strong>
                  ) : (
                    notification.notification
                  )
                }
                secondary={moment(notification.updated_at).format(
                  Config.momentEUDateFormatWithTime
                )}
              />
            </ListItem>
            <Divider style={{ background: "black", opacity: 0.2 }} />
          </>
        ))
      )}
    </List>
  );
}
