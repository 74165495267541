import React, { Component } from 'react';
import { DropzoneArea } from 'material-ui-dropzone';
import { withStyles, FormControl, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Canvas from './Signature';
import Lang from '../../lang';
import Button from '@material-ui/core/Button';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { WizardModel } from '../../Models/Models';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import noImage from '../../Images/no-image.png';
import theme from "../../Theme/Theme"

let lang = Lang.getInstance();

class DropzoneAreaField extends Component {

  constructor(props) {
    super(props);
    this.state = {
      model: WizardModel,
      open: false,
      sign: null
    };
    this.dialogRef = React.createRef();
  };

  /**
   * 
   */
  handleClose() {
    this.setState({
      open: false
    });
  }
  formRef = React.createRef();

  /**
   * 
   * @param {*} files 
   */
  handleSave(files) {
    this.setState({
      files: files,
      open: false,

    });
    console.log(files)
  }

  /**
   * 
   */
  handleOpen() {
    this.setState({
      open: true,
    });
  }


  /**
   * 
   */
  handleChange = (event) => {
    const wizardModel = this.state.model;
    wizardModel[event.target.id] = event.target.value;
    this.setState({ "model": wizardModel });
  }

  /**
   * 
   * @param {*} logo 
   */
  handleChangeDropFile(logo) {
    let m = this.state.model;
    m.logo = logo instanceof Array ? logo[0] : logo;
    this.setState({ "model": m })
  }


  /**
   * 
   */
  openDialog() {
    this.setState({ open: true });
    if (this.props.doneCallback) {
      this.props.doneCallback(false);
    }
  }

  /**
   * 
   * @param {Object} signature 
   */
  closeDialog(signature) {
    let m = this.state.model;
    m.signature = signature.blobSignature;
    this.setState({ "open": false, "sign": signature.dataImage, "model": m });
    if (this.props.doneCallback) {
      this.props.doneCallback(true);
    }
  }

  /**
   * 
   */
  validate() {
    let formRef = this.formRef.current;
    return formRef.isFormValid(false);
  }

  /**
   * 
   */
  render() {
    const classes = this.props.classes;
    const defaultRender = (
      <Paper className={classes.paper}>
        <ValidatorForm 
          ref={this.formRef} 
          instantValidate={false}
          onSubmit={()=>{}}>

          <Grid container direction="column" justify="center" className={classes.grid} >
          <Typography>{lang.get("signature")} </Typography>
          <Grid container direction="column" justify="center" className={classes.grid}>
            <Button className={classes.grid} style={{background:theme.palette.header?.main, color:theme.palette.textColorSecondary?.main}} variant="contained" onClick={this.openDialog.bind(this)}>
              {lang.get("pressToSign")}
            </Button>
          </Grid>
          <Grid className={classes.grid}>
              <div align="center">
                <img style={{maxHeight:100}} alt="signature" src={this.state.sign || noImage} />
              </div>
            </Grid>
          </Grid>

          <Grid container direction="column" justify="center" className={classes.grid} >
            <FormControl required={true} fullWidth={true} className={classes.drop}>
              <Typography >{lang.get("logo")}</Typography>
              <DropzoneArea
                dropzoneText={lang.get("dragFiles")}
                filesLimit={1}
                clearOnUnmount={false}
                acceptedFiles={['image/*']}
                onChange={this.handleChangeDropFile.bind(this)}></DropzoneArea>
            </FormControl>
          </Grid>

        </ValidatorForm>
      </Paper>
    );

    const canvas = (
      <div>
        <Typography style={{
          flex: 1,
          flexDirection: 'row',
          alignItems: 'center',
          alignContent: 'center',
          display: 'flex',
          justifyContent: 'center'
        }}>{lang.get("signBelow")}
        </Typography>
        <Box display="flex" justifyContent="center" className={classes.box}>
          <Box border={0} >
            <Canvas doneCallback={this.closeDialog.bind(this)} ></Canvas>
          </Box>
        </Box>
      </div>
    );


    const r = !this.state.open ? defaultRender : canvas;

    return (
      <div className={classes.container}>
        {r}
      </div>

    )
  }
}

const styles = (theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexGrow: 1,
    overflow: 'hidden',
    backgroundSize: 'cover',
    backgroundPosition: '0 400px',
  },
  paper: theme.mixins.gutters({
    paddingTop: 2,
    paddingBottom: 2,
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  }),
  grid: theme.mixins.gutters({

    justifyContent: 'center',
    alignContent: 'center',
    [theme.breakpoints.down('md')]: {
      //  width: '100%',
      flexDirection: 'column',
      alignContent: 'center',
      justifyContent: 'center',
    },
  }),
  grid2: theme.mixins.gutters({

    flexDirection: 'column',
    justifyContent: 'center',
    width: '60%',
    height: '60%',
    alignContent: 'center',
    [theme.breakpoints.down('md')]: {
      width: '80%',
      height: '80%',
      flexDirection: 'column',
      alignContent: 'center',
      justifyContent: 'center',
    },
  }),
  image: {
    flex: 1,
    width: '50%',
    height: '50%',
    flexDirection: 'row',
    resizeMode: 'cover',
    justifyContent: 'center',
    alignContent: 'center',
    [theme.breakpoints.down('md')]: {
      width: '80%',
      height: '80%',
      flexDirection: 'column',
      justifyContent: 'center',
      alignContent: 'center',
    },
  },

  divProp: {
    // marginTop:10,

    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      flexDirection: 'column',
      alignContent: 'center',
      justifyContent: 'center',
    },
  },
  field: {
    marginTop: 0,
    paddingBottom: 10,
  },
  drop: {
    width: '100%',
    paddingBottom: 16,
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    [theme.breakpoints.down('md')]: {
      paddingBottom: 16,
      width: '100%',
      height: '10%',
      flexDirection: 'column',
      alignContent: 'center',
      justifyContent: 'center',
    },
  },
  actions: theme.mixins.gutters({
    paddingTop: 16,
    paddingBottom: 16,
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center'
  }),
  button: theme.mixins.gutters({
    paddingTop: 5,
    paddingBottom: 5,
    marginTop: 5,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      flexDirection: 'column',
      alignContent: 'center'
    },
  })
});

DropzoneAreaField.propTypes = {
  doneCallback: PropTypes.func.isRequired
};
export default withStyles(styles, { withTheme: true })(DropzoneAreaField);
