import React from "react";
import Lang from "../../lang";
import {
  withStyles,
  CircularProgress,
  Modal,
  Theme,
  createStyles,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  // Button,
} from "@material-ui/core";
import {
  ResolutionBreakPoints,
  UrlEnum,
  LocalUrlEnum,
  get,
  DocTypes,
  StatusEnum,
  // getPathLocation,
} from "../../Utils/Utils";
import moment from "moment";
import Config from "../../Utils/Config";
import Alert from "@material-ui/lab/Alert";
import DocumentsLayout from "../../Components/DocumentsLayout";
import { DocService, Invoice } from "../../Models/Models";
import InvoiceListComplete from "../../DocumentComponents/InvoiceListComplete";
import ToggleButton from "@material-ui/lab/ToggleButton";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import AssessmentIcon from "@material-ui/icons/Assessment";
import { Link } from "react-router-dom";
import { Euro } from "@material-ui/icons";
import pdfIcon from "../../Images/icons/PDF-icon.png";
import PreviewDocument from "../../DocumentComponents/PreviewDocument";
import DashboardContext from "../../Contexts/DashboardContext";
import Receipt from "../../DocumentTemplates/Receipt";

const docWidth = document.body.offsetWidth;
const lang = Lang.getInstance();

const styles = (theme: Theme) =>
  createStyles({
    loading: {
      display: "block",
      margin: "0 auto",
      marginTop: 50,
      outline: 0,
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    totalsValue: {
      float: "right",
    },
    summary: {
      position: "fixed",
      bottom: 90,
      width: 250,
      "@media (max-height: 500px)": {
        display: "none",
      },
    },
    summaryTitle: {
      background: theme.palette.header?.main,
      padding: 5,
      color: "#fff",
      borderRadius: 2,
    },
    unpaidBtn: {
      backgroundColor: theme.palette.error.light,
      color: theme.palette.textColorSecondary?.main,
    },
    generatePDFbtn: {
      fontSize: "13px",
      fontWeight: "normal",
      width: "100%",
      backgroundColor: "transparent",
      justifyContent: "start !important",
    },
    generatePDFimg: {
      width: "23px",
    },
  });

type InvoicesProps = {
  changeLoadingState: Function;
  showSmallMessage: Function;
  type: string;
  classes: any;
  history: any;
  match: any;
};

type InvoicesState = {
  invoices: Array<Invoice>;
  totalNoVat: number;
  totalCredit: number;
  totalPaid: number;
  totalLeftToPay: number;
  errors: string;
  loading: boolean;
  openDeleteConfirm: boolean;
  unpaid: boolean;
  pdfDocData: any;
};

class Invoices extends React.Component<InvoicesProps, InvoicesState> {
  recurrent = false;
  crtDate = new Date();
  crtDateString = moment().format(Config.momentUSDateFormat);
  startDate =
    this.crtDate.getMonth() > 0
      ? this.crtDate.getUTCFullYear() + "-01-01"
      : this.crtDate.getUTCFullYear() - 1 + "-01-01";
  endDate = this.crtDateString;
  search = "";
  page = 1;
  lang = Lang.getInstance();
  totalInvoiceCount = 0;
  perPage: number;
  isMobile: boolean;
  deleteModel: Invoice | null;

  /**
   * constructor
   * @param {Object} props
   */
  constructor(props: InvoicesProps) {
    super(props);
    this.isMobile = docWidth < ResolutionBreakPoints.md;
    this.perPage = 50;
    this.state = {
      invoices: [],
      totalNoVat: 0,
      totalCredit: 0,
      totalPaid: 0,
      totalLeftToPay: 0,
      errors: "",
      loading: true,
      openDeleteConfirm: false,
      unpaid: false,
      pdfDocData: null,
    };

    this.deleteModel = null;
    if (this.props.match.params.startDate) {
      this.startDate = this.props.match.params.startDate;
      this.endDate = this.props.match.params.endDate;
      this.page = parseInt(this.props.match.params.page) || 1;
      this.perPage = parseInt(this.props.match.params.perPage) || 50;
      this.search = this.props.match.params.q || "";
    }
  }

  shouldComponentUpdate(nextProps: InvoicesProps, nextState: InvoicesState) {
    if (nextProps.type !== this.props.type) {
      this.getInvoices(nextProps.type);
      return false;
    }
    return true;
  }

  componentDidMount() {
    this.getInvoices();
  }

  /**
   * get invoices from server
   */
  async getInvoices(type = "") {
    this.setState({ loading: true });

    const docType = type ? type : this.props.type;
    const recurrent = this.recurrent ? "/recurrent" : "";
    const unpaid = this.state.unpaid
      ? this.recurrent
        ? "/unpaid"
        : "/null/unpaid"
      : "";
    const url = `${UrlEnum.invoices}/${docType}/${this.startDate}/${this.endDate}${recurrent}${unpaid}?q=${this.search}&page=${this.page}&per_page=${this.perPage}`;
    const response = await get(url);
    if (response.errors) {
      this.setState({ errors: response.errors, loading: false });
    }
    this.totalInvoiceCount = response.total_count;
    const totalNoVat: number = parseFloat(
      parseFloat(response.totalNoVat).toFixed(Config.noOfDecimals)
    );
    const totalPaid: number = parseFloat(
      parseFloat(response.totalPaid).toFixed(Config.noOfDecimals)
    );
    let invoices: Array<Invoice> = [];
    //invoices = response.items.map((i:any)=>Object.assign(new Invoice(),i));
    this.setState({
      invoices: response.items,
      totalNoVat: totalNoVat,
      totalCredit: parseFloat(
        parseFloat(response.totalCredit).toPrecision(Config.noOfDecimals)
      ),
      totalPaid: totalPaid,
      totalLeftToPay: parseFloat(
        (totalNoVat - totalPaid).toFixed(Config.noOfDecimals)
      ),
      loading: false,
    });
  }

  /**
   * change crt url
   */
  changeUrl(
    startDate: string,
    endDate: string,
    search: string,
    page: number,
    perPage = 50
  ) {
    let displayUrlBase = LocalUrlEnum.invoices;
    switch (this.props.type) {
      case DocTypes.proforma:
        displayUrlBase = LocalUrlEnum.proformas;
        break;
      case DocTypes.creditNote:
        displayUrlBase = LocalUrlEnum.creditNotes;
        break;
    }
    const displayUrl = `${displayUrlBase}/${startDate}/${endDate}/${page}/${perPage}/${search}`;
    this.props.history.push(displayUrl);
    console.log();
  }

  /**
   *
   * @param event
   */
  handleClickLiability = (event: any) => {
    // let displayUrlBase = "/liability";
    //this.props.history.location.pathname+"/liability";
    // this.props.history.push(displayUrlBase);
  };
  /**
   *
   * get ids for those items that are checked
   */
  getSelectedIds() {
    if (this.state.invoices) {
      const selected = this.getSelected();
      return selected.map((invoice) => invoice.id);
    }
  }

  getSelected() {
    if (this.state.invoices && this.state.invoices.length) {
      return this.state.invoices.filter((invoice) => invoice.selected);
    }
    return [];
  }

  /**
   *  pdf generation for those items that are checked
   */
  async generatePDF() {
    let selected = this.getSelected();
    if (!selected || !selected.length) {
      this.props.showSmallMessage(
        lang.get("nothingSelected"),
        StatusEnum.ERROR
      );
      return;
    }
    const requests = selected.map(({ id }) => get(UrlEnum.invoices + "/" + id));
    Promise.all(requests).then((data) => {
      let pdfDocData = [];
      // get all selected invoices by id
      let categServ = [];
      let totals = [];
      let receipts = [];
      for (let invoice of data) {
        pdfDocData.push({
          series: invoice.series.toString(),
          no: invoice.invoiceNo,
          date: moment(invoice.invoiceDate, Config.momentEUDateFormat).format(
            Config.momentEUDateFormat
          ),
          vatRate: invoice.vatRate,
          paymentMode: invoice.paymentMode,
          paymentDate: invoice.paymentDue.toString(),
          client: invoice.client,
        });
        categServ.push(this.servicesByCategs(invoice));
        totals.push(Invoice.getTotals(invoice));
        receipts.push(this.getReceipt(invoice));
      }

      this.setState({
        pdfDocData: {
          docData: pdfDocData,
          categServ: categServ,
          receipts: receipts,
          totals: totals,
        },
      });
    });
  }

  /**
   *
   * @param invoice
   */
  private servicesByCategs(invoice: Invoice) {
    let categsServ: any = {};
    let invoiceTotalNoVat = 0;
    for (const service of invoice.invoice_services) {
      service.servicePrice = service.servicePrice || 0;
      invoiceTotalNoVat +=
        parseFloat(service.servicePrice.toString()) * service.serviceQuantity;
      DocService.recalculatePrice(service, invoice.vatRate);

      if (!categsServ[service.serviceCategName]) {
        categsServ[service.serviceCategName] = [service];
      } else {
        categsServ[service.serviceCategName].push(service);
      }
    }

    invoice.totalNoVat = invoiceTotalNoVat;
    invoice.totalWithVat = Invoice.getTotals(invoice).totalWithVat;
    return categsServ;
  }

  /**
   *
   * @param invoice
   */
  getReceipt(invoice: Invoice) {
    const receipt = invoice.payments.reverse().find((p) => p.receiptNo);
    return receipt ? (
      <Receipt
        number={receipt.receiptNo}
        date={receipt.paymentDate}
        total={receipt.amount}
      />
    ) : null;
  }

  /**
   * render method
   */
  render() {
    const classes = this.props.classes;
    if (!this.state.loading) {
      if (this.state.errors) {
        return <Alert severity="error">{this.state.errors}</Alert>;
      }
    }
    let newDocLink = LocalUrlEnum.invoices;
    switch (this.props.type) {
      case DocTypes.proforma:
        newDocLink = LocalUrlEnum.proformas;
        break;
      case DocTypes.creditNote:
        newDocLink = LocalUrlEnum.creditNotes;
        break;
    }

    return (
      <DocumentsLayout
        onChange={(startDate, endDate, search, page) => {
          this.changeUrl(startDate, endDate, search, page);
        }}
        startDate={this.startDate}
        endDate={this.endDate}
        search={this.search}
        page={this.page}
        perPage={this.perPage}
        onChangeRecurrent={(recurrent) => {
          this.recurrent = recurrent;
          this.getInvoices();
        }}
        totalDocCount={this.totalInvoiceCount}
        isMobile={this.isMobile}
        title={this.lang.get(this.props.type + "s")}
        newDocLink={newDocLink + "/0"}
        type={this.props.type}
        drawerChildren={
          <>
            <ToggleButton
              selected={this.state.unpaid}
              onChange={() => {
                this.setState({ unpaid: !this.state.unpaid }, () =>
                  this.getInvoices()
                );
              }}
              value="unpaid"
              classes={{ root: classes.unpaidBtn }}
            >
              <MoneyOffIcon />
              {this.lang.get("unpaid")}
            </ToggleButton>
            <br />
            <Divider />

            <List>
              <Link to="/invoices/report" className={classes.fullWidth}>
                <ListItem button>
                  <ListItemIcon>
                    <AssessmentIcon />{" "}
                  </ListItemIcon>
                  <ListItemText primary={this.lang.get("report")} />
                </ListItem>
              </Link>

              <Link to="/invoices/liability" className={classes.fullWidth}>
                <ListItem
                  button
                  id={this.lang.get("liability")}
                  onClick={(event: any) => this.handleClickLiability(event)}
                >
                  <ListItemIcon>
                    <Euro />{" "}
                  </ListItemIcon>
                  <ListItemText primary={this.lang.get("liability")} />
                </ListItem>
              </Link>
              <ListItem
                button
                id={this.lang.get("generatePdf")}
                className={classes.generatePDFbtn}
                onClick={() => this.generatePDF()}
              >
                <ListItemIcon>
                  <img
                    className={classes.generatePDFimg}
                    src={pdfIcon}
                    alt="file"
                  />
                </ListItemIcon>
                <ListItemText>{this.lang.get("generatePdf")}</ListItemText>
              </ListItem>
            </List>
            <div className={classes.summary}>
              <div className={classes.summaryTitle}>
                {this.lang.get("summary")}
              </div>
              {this.state.totalNoVat ? (
                <p>
                  {this.lang.get("totalWithVat")}:{" "}
                  <em className={classes.totalsValue}>
                    {this.state.totalNoVat}
                  </em>
                </p>
              ) : null}
              {this.state.totalCredit ? (
                <p>
                  {this.lang.get("creditNotes")}:{" "}
                  <em className={classes.totalsValue}>
                    {this.state.totalCredit}
                  </em>
                </p>
              ) : null}
              {this.state.totalLeftToPay ? (
                <p>
                  {this.lang.get("leftToPay")}:{" "}
                  <em className={classes.totalsValue}>
                    {this.state.totalLeftToPay}
                  </em>
                </p>
              ) : null}
              {this.state.totalPaid ? (
                <p>
                  {this.lang.get("paid")}:{" "}
                  <em className={classes.totalsValue}>
                    {this.state.totalPaid}
                  </em>
                </p>
              ) : null}
            </div>
          </>
        }
        match={this.props.match}
      >
        <InvoiceListComplete
          select={true}
          isProject={false}
          invoices={this.state.invoices}
          showSmallMessage={this.props.showSmallMessage}
          type={this.props.type}
          key={this.state.invoices.length}
        />
        <Modal open={this.state.loading} className={classes.modal}>
          <CircularProgress color="secondary" className={classes.loading} />
        </Modal>
        {this.state.pdfDocData && (
          <DashboardContext.Consumer>
            {(context) => (
              <PreviewDocument
                open={this.state.pdfDocData !== null}
                onClose={() => {
                  this.setState({ pdfDocData: null });
                }}
                type={this.state.invoices[0].type}
                services={this.state.pdfDocData.categServ}
                setOpenMessage={context.setOpenMessage}
                setSmallMessage={context.setSmallMessage}
                totals={this.state.pdfDocData.totals}
                id={this.state.invoices[0].id}
                docData={this.state.pdfDocData.docData}
                children={this.state.pdfDocData.receipts}
              />
            )}
          </DashboardContext.Consumer>
        )}
      </DocumentsLayout>
    );
  }
}

export default withStyles(styles)(Invoices);
