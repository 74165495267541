import { useState, useEffect, useReducer } from "react";
import {
  Button,
  DialogActions,
  FormControl,
  makeStyles,
  TextField,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  KeyboardDatePicker,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import moment from "moment";
import { ChangeEvent } from "react";
import Lang from "../../lang";
import { Client, Estimate, Project } from "../../Models/Models";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import CachedDataSingleton from "../../cachedDataSingleton";
import Config from "../../Utils/Config";
import { TimeTrakingOptionEnum } from "../../Pages/TimeTrackingComponent";
import theme from "../../Theme/Theme";
import { TextValidator } from "react-material-ui-form-validator";
import { StatusEnum } from "../../Utils/Utils";
const lang = Lang.getInstance();
const cachedData = CachedDataSingleton.getInstance();

const useStyles = makeStyles((theme: any) => ({
  spacing: {
    margin: 20,
  },
}));

export const TimeTrackingTypeEnum = {
  project: "project",
  orderTicket: "orderTicket",
  client: "client",
  moving: "moving",
};

type NewManualTimetrackingProps = {
  employees: any;
  activeProjects: any;
  activeOrderTickets: any;
  timekeepingOption: string;
  onSubmit: (
    personId: number,
    folderId: number,
    orderTicketId: number,
    clientId: number,
    time: string
  ) => void;
  onClose: () => void;
  showSmallMessage: Function;
};

export type TimeTrackingTypeValue = {
  client: Client | null;
  project: Project | null;
  orderTicket: Estimate | null;
  moving?: Boolean | null;
};

export enum TIMETRAKING_TYPES {
  PROJECT = "project",
  ORDER_TICKET = "orderTicket",
  CLIENT = "client",
  MOVING = "moving",
}

const NewManualTimetracking = (props: NewManualTimetrackingProps) => {
  const clients = cachedData.get("clients");
  const classes = useStyles(props);

  const [timeTrackingType, setTypeTrackingType] = useState(
    TimeTrackingTypeEnum.project
  );
  const [selectedEmployee, setSelectedEmployee] = useState(props.employees[0]);
  const [disabled, setDisabled] = useState(false);

  const initialState: TimeTrackingTypeValue = {
    client: null,
    project: null,
    orderTicket: null,
    moving: null,
  };

  useEffect(() => {
    if (props.employees.length > 0) {
    if (selectedEmployee.id !== props.employees[0].id) {
        setSelectedEmployee(props.employees[0]);
        setDisabled(false);
      }
    }
  }, [props.employees]);

  const reducer = (
    state: TimeTrackingTypeValue,
    action: { type: TIMETRAKING_TYPES; value: any }
  ): TimeTrackingTypeValue => {
    switch (action.type) {
      case TIMETRAKING_TYPES.PROJECT:
        return { client: null, project: action.value, orderTicket: null };
      case TIMETRAKING_TYPES.ORDER_TICKET:
        return { client: null, project: null, orderTicket: action.value };
      case TIMETRAKING_TYPES.CLIENT:
        return { client: action.value, project: null, orderTicket: null };
      case TIMETRAKING_TYPES.MOVING:
        return { client: null, project: null, orderTicket: null, moving: true };
      default:
        return { client: null, project: null, orderTicket: null };
    }
  };
  const [timeTrackingTypeValues, dispatch] = useReducer(reducer, initialState);
  const [startTime, setStartTime] = useState(moment());

  /**
   * handle type change
   * @param event
   */
  const handleChangeTimeTrackingType = (event: any, value: string) => {
    setTypeTrackingType(value);
  };

  function renderByType() {
    switch (timeTrackingType) {
      case TimeTrackingTypeEnum.project:
        return (
          <Autocomplete
            fullWidth
            id="project"
            options={props.activeProjects}
            value={timeTrackingTypeValues.project}
            getOptionLabel={(project: any) =>
              `${project.folderNo} ${project.clientName} - ${project.title || ""
              }`
            }
            renderInput={(params) => (
              <TextField {...params} label={lang.get("folder")} required />
            )}
            onChange={(event: ChangeEvent<{}>, value: any) => {
              if (value === null) return;
              dispatch({ type: TIMETRAKING_TYPES.PROJECT, value: value });
            }}
          />
        );

      case TimeTrackingTypeEnum.orderTicket:
        return (
          <Autocomplete
            fullWidth
            id="orderTicket"
            options={props.activeOrderTickets}
            value={timeTrackingTypeValues.orderTicket}
            getOptionLabel={(ot: Estimate) =>
              `${ot.estimateNo} ${ot.clientName} - ${ot.title ? ot.title : ot.description || ""
              }`
            }
            renderInput={(params) => (
              <TextField {...params} label={lang.get("orderTicket")} />
            )}
            onChange={(event: ChangeEvent<{}>, value: any) => {
              if (value === null) return;
              dispatch({ type: TIMETRAKING_TYPES.ORDER_TICKET, value: value });
            }}
          />
        );
      case TimeTrackingTypeEnum.client:
        return (
          <Autocomplete
            fullWidth
            id="client"
            options={clients}
            value={timeTrackingTypeValues.client}
            getOptionLabel={(client: Client) => client.name}
            renderInput={(params) => (
              <TextField {...params} label={lang.get("client")} />
            )}
            onChange={(event: ChangeEvent<{}>, value: any) => {
              if (value === null) return;
              dispatch({ type: TIMETRAKING_TYPES.CLIENT, value: value });
            }}
          />
        );
      default:
        return null;
    }
  }

  function render_option() {
    if (props.timekeepingOption === TimeTrakingOptionEnum.perHour) {
      return (
        <KeyboardDateTimePicker
          style={{ marginTop: 30, marginBottom: 30 }}
          fullWidth
          label={lang.get("startTime")}
          placeholder={lang.get("startTime")}
          format={Config.momentEUDateFormatWithTime}
          ampm={false}
          value={startTime}
          onChange={(date) => setStartTime(date as moment.Moment)}
        />
      );
    }

    return (
      <KeyboardDatePicker
        style={{ marginTop: 30, marginBottom: 30 }}
        fullWidth
        label={lang.get("date")}
        value={startTime}
        onChange={(date) => {
          if (!date) return false;
          setStartTime(date as moment.Moment);
        }}
        format={Config.momentEUDateFormat}
      />
    );
  }

  function addManualTimetracking() {
    if (timeTrackingType !== TIMETRAKING_TYPES.MOVING) {
      if (
        !timeTrackingTypeValues.project &&
        !timeTrackingTypeValues.orderTicket &&
        !timeTrackingTypeValues.client
      ) {
        props.showSmallMessage(lang.get("nothingSelected"), StatusEnum.ERROR)
        return false;
      }
    }

    setDisabled(true);
    if (timeTrackingType !== TimeTrackingTypeEnum.moving) {
      if (
        timeTrackingTypeValues.project ||
        timeTrackingTypeValues.orderTicket ||
        timeTrackingTypeValues.client
      ) {
        props.onSubmit(
          selectedEmployee?.id,
          timeTrackingTypeValues.project
            ? timeTrackingTypeValues.project.id
            : 0,
          timeTrackingTypeValues.orderTicket
            ? timeTrackingTypeValues.orderTicket.id
            : 0,
          timeTrackingTypeValues.client
            ? timeTrackingTypeValues.client.id
            : 0,
          startTime.format(Config.momentUSDateFormatWithTime)
        );
      }
    } else if (timeTrackingType === TimeTrackingTypeEnum.moving) {
      props.onSubmit(
        selectedEmployee?.id,
        timeTrackingTypeValues.project
          ? timeTrackingTypeValues.project.id
          : 0,
        timeTrackingTypeValues.orderTicket
          ? timeTrackingTypeValues.orderTicket.id
          : 0,
        timeTrackingTypeValues.client
          ? timeTrackingTypeValues.client.id
          : 0,
        startTime.format(Config.momentUSDateFormatWithTime)
      );
    }
    props.onClose();
  }

  return (
    <>
      <Autocomplete
        fullWidth
        id="employee"
        options={props.employees}
        value={selectedEmployee}
        getOptionLabel={(employee: any) => employee.name}
        renderInput={(params) => (
          <TextField {...params} label={lang.get("employee")} />
        )}
        onChange={(event: ChangeEvent<{}>, value: any) => {
          if (value === null) return;
          setSelectedEmployee(value);
        }}
      />

      <div className={classes.spacing}>
        <ToggleButtonGroup
          value={timeTrackingType}
          exclusive
          onChange={handleChangeTimeTrackingType}
          aria-label="text alignment"
        >
          <ToggleButton
            value={TimeTrackingTypeEnum.project}
            aria-label="left aligned"
          >
            {lang.get("folder")}
          </ToggleButton>
          <ToggleButton
            value={TimeTrackingTypeEnum.orderTicket}
            aria-label="left aligned"
          >
            {lang.get("orderTicket")}
          </ToggleButton>
          <ToggleButton
            value={TimeTrackingTypeEnum.client}
            aria-label="left aligned"
          >
            {lang.get("client")}
          </ToggleButton>
          <ToggleButton
            value={TimeTrackingTypeEnum.moving}
            aria-label="left aligned"
          >
            {lang.get("moving")}
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      {renderByType()}

      {render_option()}

      <DialogActions>
        <Button
          variant="contained"
          style={{ background: theme.palette.header?.main }}
          size="large"
          disabled={disabled}
          onClick={addManualTimetracking}
        >
          {lang.get("add")}
        </Button>
        <Button
          variant="contained"
          style={{ background: theme.palette.errorColor?.main }}
          onClick={props.onClose}
        >
          {lang.get("close")}
        </Button>
      </DialogActions>
    </>
  );
};

export default NewManualTimetracking;
