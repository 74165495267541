import {
  Button,
  createStyles,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  withStyles,
  Theme,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Paper,
  Divider,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Lang from "../lang";
import {
  UrlEnum,
  get,
  isMobile,
  post,
  StatusEnum,
  httpDelete,
  LocalUrlEnum,
  renderFullPage,
  uriEncodeObj,
} from "../Utils/Utils";
import DrawerLayout from "./DrawerLayout";
import AssessmentIcon from "@material-ui/icons/Assessment";
import NewManualTimetracking from "../Components/Timetracking/NewManualTimetracking";
import ActiveTimetrackings from "../Components/Timetracking/ActiveTimetrackings";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { TimeTracking } from "../Models/Models";
import AddIcon from "@material-ui/icons/Add";
import Config from "../Utils/Config";
import moment from "moment";
import EmployeesMap from "../Components/Timetracking/EmployeesMap";
import CachedDataSingleton from "../cachedDataSingleton";
import TimetrackingReport from "./Reports/TimetrackingRepors";
import {
  Switch,
  Route,
  Link,
  useRouteMatch,
  useLocation,
} from "react-router-dom";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import NoDataFound from "../Components/NoDataFound";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import { ServerStyleSheets, jssPreset } from "@material-ui/core/styles";
import { create } from "jss";
import ReactDOMServer from "react-dom/server";
import PreviewPDF from "../DocumentComponents/PreviewPDF";
import TableComponent from "../Components/TableComponent";
import SimpleHeader from "../DocumentTemplates/SimpleHeader";
import theme from "../Theme/Theme";
import AddNewTimetracking from "../Components/Timetracking/AddNewTimetracking";

const lang = Lang.getInstance();

type TimeTrackingProps = {
  showSmallMessage: (message: string, status?: string) => void;
  history: any;
  classes: any;
  onChangeLoading: (loading: boolean) => void;
};

function TimeTrackingComponent(props: TimeTrackingProps) {
  const location = useLocation();
  const jss = create().setup({ ...jssPreset(), Renderer: null });
  const sheets = new ServerStyleSheets({ jss });

  const cachedData = CachedDataSingleton.getInstance();
  const timekeepingOption = cachedData.get("timekeepingOption");

  const tkUrlPart = "/manualStartStop";
  const [employees, setEmployees] = useState([]);
  const [activeProjects, setActiveProjects] = useState([]);
  const [reportUrl, setUrlReport] = useState<string | undefined>(undefined);
  const [activeOrderTickets, setActiveOrderTickets] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(!isMobile());
  const [addNewOpen, setAddNewOpen] = useState(false);
  const [addNewOpenReport, setAddNewOpenReport] = useState(false);
  const at_initialValue: Array<TimeTracking> = [];
  const [activeTimetrackings, setActiveTimetrackings] = useState(
    at_initialValue
  );
  const [htmlString, setHtmlString] = useState("");
  const [reportData, setReportData] = useState<any>(null);
  const { path, url } = useRouteMatch();

  useEffect(() => {
    getTodayTimeTracking();
    const unlisten = props.history.listen((location: any, action: any) => {
      if (LocalUrlEnum.timeTracking === location.pathname) {
        getTodayTimeTracking();
      }
    });
    return unlisten;
  }, []);

  /**
   * fetch data from server
   */
  const getTodayTimeTracking = () => {
    get(UrlEnum.timeTracking + "/activePersonsAndFolders").then(
      (response: any) => {
        if (response.errors) {
          props.showSmallMessage(lang.get("error"), StatusEnum.ERROR);
          return;
        }
        setEmployees(response.persons || []);
        setActiveProjects(response.activeFolders || []);
        setActiveOrderTickets(response.activeOrderTickets || []);
        setActiveTimetrackings(response.activeTimekeepings || []);
      }
    );
  };

  /**
   *
   * @param date
   */
  const handleStopTimeChange = (
    at: TimeTracking,
    date: MaterialUiPickersDate
  ) => {
    at.stopTime = date as moment.Moment;
    const newAts: Array<TimeTracking> = activeTimetrackings.slice();
    const index = newAts.findIndex((t: TimeTracking) => t.id === at.id);
    newAts.splice(index, 1, { ...at } as never);
    setActiveTimetrackings(newAts);
  };

  const startStopTk = async (
    personId: number,
    folderId: number,
    orderTicketId: number,
    clientId: number,
    time: string,
    id?: number
  ) => {
    const url = UrlEnum.timeTracking + tkUrlPart;
    let data: { [k: string]: any } = {
      // string keys
      personId: personId,
      folderId: folderId,
      orderTicketId: orderTicketId,
      clientId: clientId,
      time: time,
    };

    if (id) {
      data.id = id;
    }

    const response: any = await post(url, data);
    if (response.errors) {
      props.showSmallMessage(lang.get("error"), StatusEnum.ERROR);
      return;
    }
    const newAts = activeTimetrackings.slice();
    if (newAts) {
      if (!id) {
        newAts.push(response);
      } else {
        const index = newAts.findIndex(
          (t: TimeTracking) => t.id === response.id
        );
        newAts.splice(index, 1, response as never);
      }
      setActiveTimetrackings(newAts);
      props.showSmallMessage(lang.get("success"));
    }
    return response;
  };

  /**
   *
   * @param personId
   * @param folderId
   * @param orderTicketId
   * @param clientId
   * @param time
   */
  const startStopPerDayTk = async (
    personId: number,
    folderId: number,
    orderTicketId: number,
    clientId: number,
    time: string
  ) => {
    const url = UrlEnum.timeTracking + tkUrlPart;
    let data: { [k: string]: any } = {
      // string keys
      personId: personId,
      folderId: folderId,
      orderTicketId: orderTicketId,
      clientId: clientId,
      time: time,
    };

    const response: any = await post(url, data);
    if (response.errors) {
      props.showSmallMessage(lang.get("error"), StatusEnum.ERROR);
      return;
    }

    // send stop
    const date = moment(time, Config.momentUSDateFormat);
    date.set({
      hour: 8,
      minute: 30,
      second: 0,
    });
    data.id = response.id;
    data.time = date.format(Config.momentUSDateFormatWithTime);
    const response2: any = await post(url, data);

    if (response.errors) {
      props.showSmallMessage(lang.get("error"), StatusEnum.ERROR);
      return;
    }

    const newAts = activeTimetrackings.slice();
    newAts.push(response2);
    setActiveTimetrackings(newAts);
    props.showSmallMessage(lang.get("success"));
  };

  /**
   *
   * @param at
   */
  const stopTimeTracking = (at: TimeTracking) => {
    if (!at.stopTime) {
      handleStopTimeChange(at, moment());
    }
    startStopTk(
      at.fk_PersonId,
      at.fk_FolderId,
      at.fk_EstimateId,
      at.fk_ClientId,
      at.stopTime?.format(Config.momentUSDateFormatWithTime) as string,
      at.id
    );
  };

  const startTimeTracking = (
    personId: number,
    folderId: number,
    orderTicketId: number,
    clientId: number,
    time: string
  ) => {
    if (timekeepingOption === TimeTrakingOptionEnum.perHour) {
      startStopTk(personId, folderId, orderTicketId, clientId, time);
    } else {
      const date = moment(time, Config.momentUSDateFormatWithTime);
      startStopPerDayTk(
        personId,
        folderId,
        orderTicketId,
        clientId,
        date.format(Config.momentUSDateFormat)
      );
    }
  };

  async function saveTimeTracking(
    personId: number,
    folderId: number,
    orderTicketId: number,
    clientId: number,
    startDate: any,
    endDate: any,
    startHour: string,
    endHour: string
  ) {
    props.onChangeLoading(true);
    const startDay = +startDate.format("D");
    const endDay = +endDate.format("D");
    let startD = startDate.clone();
    let endD = endDate.clone();
    for (let i = startDay; i <= endDay; i++) {
      startD = startD.set("date", i);
      endD = endD.set("date", i);
      const start = startD.format(Config.momentUSDateFormat) + " " + startHour;
      const end = endD.format(Config.momentUSDateFormat) + " " + endHour;
      const timeTracking = (await startStopTk(
        personId,
        folderId,
        orderTicketId,
        clientId,
        start
      )) as any;
      await startStopTk(
        personId,
        folderId,
        orderTicketId,
        clientId,
        end,
        timeTracking.id
      );
    }
    setAddNewOpenReport(false);
    props.onChangeLoading(false);
    const data = {
      startDate: startDate.format(Config.momentUSDateFormat),
      endDate: endDate.format(Config.momentUSDateFormat),
      pause: "30",
      employee: personId,
    };
    onSubmit(data);
  }

  /**
   *
   * @param at
   */
  const onDeleteTk = async (at: TimeTracking) => {
    const url = UrlEnum.timeTracking + "/" + at.id;
    const response = await httpDelete(url);
    if (response.errors) {
      props.showSmallMessage(lang.get("error"), StatusEnum.ERROR);
      return;
    }
    const index = activeTimetrackings.findIndex(
      (a: TimeTracking) => a.id === at.id
    );
    const newAts = activeTimetrackings.slice();
    newAts.splice(index, 1);

    setActiveTimetrackings(newAts as never[]);
  };

  /**
   *
   */
  const getEmployeesWithoutStartedTimetrackings = () => {
    const activeEmployees = employees.filter((emp: any) => {
      for (let at of activeTimetrackings) {
        if (at.fk_PersonId === emp.id) {
          const position = JSON.parse(at.position);
          if (position.length % 2 !== 0) {
            return false;
          }
        }
      }
      return true;
    });
    return activeEmployees;
  };
  function noActiveEmployees() {
    return (
      <div>
        <h2 style={{ fontWeight: "normal", margin: "0px 0px 30px 0px" }}>
          {lang.get("noEmployees")}
        </h2>
        <div style={{ textAlign: "center" }}>
          <Button
            style={{
              background: theme.palette.primary?.main,
              color: theme.palette.textColorSecondary?.main,
              marginBottom: "20px",
            }}
            onClick={() => setAddNewOpen(false)}
          >
            {lang.get("ok")}
          </Button>
        </div>
      </div>
    );
  }

  /**
   * return the timetracking components
   */
  const renderActiveTimetracking = () => {
    const activeEmployees = getEmployeesWithoutStartedTimetrackings();
    return (
      <>
        {activeTimetrackings.length > 0 ? (
          <ActiveTimetrackings
            activeTimetrackings={activeTimetrackings}
            employees={employees}
            handleStopTimeChange={handleStopTimeChange}
            stopTimeTracking={stopTimeTracking}
            timekeepingOption={timekeepingOption}
            onDelete={onDeleteTk}
          />
        ) : (
          <NoDataFound />
        )}

        {employees.length > 0 ? (
          <Dialog open={addNewOpen} onClose={() => setAddNewOpen(false)}>
            <DialogTitle>
              {activeEmployees.length > 0
                ? `${lang.get("add")}: ${lang.get("timekeeping")}`
                : ""}
            </DialogTitle>
            <DialogContent>
              {activeEmployees.length > 0 ? (
                <NewManualTimetracking
                  onSubmit={startTimeTracking}
                  employees={getEmployeesWithoutStartedTimetrackings()}
                  activeProjects={activeProjects}
                  activeOrderTickets={activeOrderTickets}
                  timekeepingOption={timekeepingOption}
                  onClose={() => setAddNewOpen(false)}
                  showSmallMessage={props.showSmallMessage}
                />
              ) : (
                noActiveEmployees()
              )}
            </DialogContent>
          </Dialog>
        ) : null}

        {activeTimetrackings.length > 0 ? (
          <EmployeesMap activeTimetrackings={activeTimetrackings} />
        ) : null}
      </>
    );
  };

  /**
   *
   * @param data
   */
  function onSubmit(data: any) {
    const url = UrlEnum.timetrackingReport + "?" + uriEncodeObj(data);
    setUrlReport(url);
  }
  /**
   * return the report components
   */
  const renderReport = () => {
    return (
      <TimetrackingReport
        showSmallMessage={props.showSmallMessage}
        onFetchResult={(data: any) => setReportData(data)}
        onSubmit={onSubmit}
        reportUrl={reportUrl}
      />
    );
  };

  const getHtml = () => {
    const columns = [
      { label: lang.get("employee"), name: "subcontractorPersonName" },
      { label: lang.get("subcontractor"), name: "subcontractorName" },
      { label: lang.get("client"), name: "clientName" },
      { label: lang.get("siteAddress"), name: "siteAddress" },
      { label: lang.get("start"), name: "startWork" },
      { label: lang.get("end"), name: "endWork" },
      { label: lang.get("timeElapsed"), name: "elapsedTime" },
      { label: lang.get("withBreak"), name: "elapsedTimeWithPause" },
    ];
    const elements = (
      <div>
        <SimpleHeader company={cachedData.get("company")} />
        <h2 style={{ textAlign: "center" }}>{lang.get("timekeeping")}</h2>
        {reportData?.items && (
          <div>
            <div>
              <div style={{ display: "inline-block", width: "25%" }}>
                <strong>
                  <big>{lang.get("total")}:</big>
                </strong>
              </div>
              <div style={{ display: "inline-block", width: "25%" }}>
                <strong>{lang.get("timeElapsed")}</strong>:{" "}
                {reportData?.totalTime}
              </div>
              <div style={{ display: "inline-block", width: "25%" }}>
                <strong>{lang.get("withBreak")}</strong>:{" "}
                {reportData?.totalTimeWithPause}
              </div>
              <div style={{ display: "inline-block", width: "25%" }}>
                <strong>{lang.get("laborCost")}</strong>:{" "}
                {reportData?.totalPrice}
              </div>
            </div>
            <br />
            <TableComponent
              models={reportData.items}
              columns={columns}
              pagination={false}
              options={null}
              search={false}
              styles={{
                table: {
                  "& tr >td": { fontSize: "9pt !important" },
                },
                root: {
                  marginTop: 0,
                },
              }}
            />
          </div>
        )}
      </div>
    );
    const htmlString = ReactDOMServer.renderToString(sheets.collect(elements));
    return htmlString;
  };

  /**
   * get html and make if pdf
   * @param event
   */
  const previewReport = () => {
    const htmlString = getHtml();
    const css = sheets.toString();
    setHtmlString(renderFullPage(htmlString, css));
  };

  let menu = (
    <Button
      color="inherit"
      onClick={() => {
        setAddNewOpen(true);
        if (employees.length < 1)
          props.showSmallMessage(lang.get("noEmployees"), StatusEnum.ERROR);
      }}
    >
      <AddIcon />
      {lang.get("add")}
    </Button>
  );
  let title = lang.get("timekeeping");
  let customMenuReport;
  if (location.pathname.indexOf("report") >= 0) {
    menu = (
      <Button
        color="inherit"
        onClick={() => {
          setAddNewOpenReport(true);
          if (employees.length < 1)
            props.showSmallMessage(lang.get("noEmployees"), StatusEnum.ERROR);
        }}
      >
        <AddIcon />
        {lang.get("add")}
      </Button>
    );
    title = lang.get("report") + ": " + lang.get("timekeeping");
    customMenuReport = (
      <>
        <ListItem button onClick={previewReport}>
          <ListItemIcon>
            <PictureAsPdfIcon />
          </ListItemIcon>
          <ListItemText primary="Pdf" />
        </ListItem>
        <Divider />
      </>
    );
  }

  function getTimeTrackingForReports() {
    const activeEmployees = getEmployeesWithoutStartedTimetrackings();
    return (
      <AddNewTimetracking
        saveTimeTracking={saveTimeTracking}
        employees={getEmployeesWithoutStartedTimetrackings}
        addNewOpenReport={addNewOpenReport}
        onClose={() => setAddNewOpenReport(false)}
        activeProjects={activeProjects}
        activeOrderTickets={activeOrderTickets}
        timekeepingOption={timekeepingOption}
        showSmallMessage={props.showSmallMessage}
        activeEmployees={activeEmployees}
      />
    );
  }
  return (
    <>
      <DrawerLayout
        open={drawerOpen}
        drawerWidth={!isMobile() ? 300 : "100%"}
        handleDrawerClose={() => setDrawerOpen(!drawerOpen)}
        title={title}
        menu={menu}
        drawerChildren={
          <List>
            <Link to={`${url}/report`}>
              <ListItem button>
                <ListItemIcon>
                  <AssessmentIcon />
                </ListItemIcon>
                <ListItemText primary={lang.get("reports")} />
              </ListItem>
            </Link>
            {customMenuReport}
            <Link to={LocalUrlEnum.timeTracking}>
              <ListItem button>
                <ListItemIcon>
                  <AccessTimeIcon />
                </ListItemIcon>
                <ListItemText primary={lang.get("timekeeping")} />
              </ListItem>
            </Link>
          </List>
        }
      >
        <Switch>
          <Route exact path={`${path}`}>
            {renderActiveTimetracking()}
          </Route>
          <Route exact path={`${path}/report`}>
            {renderReport()}
          </Route>
        </Switch>
      </DrawerLayout>
      <PreviewPDF
        open={htmlString !== ""}
        onClose={() => setHtmlString("")}
        htmlContent={htmlString}
        footerHtml={""}
        setSmallMessage={props.showSmallMessage}
        emailData={{ to: "", subject: "", message: "" }}
        url={UrlEnum.pdf.replace("{type}", "html").replace("{id}", "0")}
      />
      {getTimeTrackingForReports()}
    </>
  );
}

const styles = (theme: Theme) => createStyles({});

export const TimeTrakingOptionEnum = {
  perDay: "perDay",
  perHour: "perHour",
};

export default withStyles(styles, { withTheme: true })(TimeTrackingComponent);
