import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, ListItem, ListItemIcon, ListItemText, Select } from "@material-ui/core";
import { Client, Address } from "../Models/Models";
import Lang from "../lang";
import PostAddIcon from "@material-ui/icons/PostAdd";
import { useState } from "react";
import { convertFromRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { DocTypes, get, UrlEnum, fromStringTemplate } from "../Utils/Utils";
import PreviewPDF from "./PreviewPDF";
import theme from "../Theme/Theme";

const lang = Lang.getInstance();

type VatAttestationProps = {
    company: any,
    client: Client | null,
    docData: {
        series: string;
        no: string;
        worksiteAddress: string;
        date: string;
    },
    setSmallMessage: Function,
    docType: string
};

function VatAttestation(props: VatAttestationProps) {

    const [htmlContent, setHtmlContent] = useState("");
    const [dialogOpen, setDialogOpen] = useState(false);
    const [clientType, setClientType] = useState(lang.get("owner"));

    const generateVatAttestation = async () => {
        const html = await renderVatAttestation(
            props.company,
            props.client,
            clientType,
            props.docData,
            lang.get("project")
        );
        const htmlContentString = `<html>
				<head>
					<meta charset='UTF-8'/>
					<style>
                        @import url('https://fonts.googleapis.com/css?family=Roboto:ital,wght@0,400;0,700;1,400;1,700&display=swap');
                        html{
                            font-family:Roboto,Arial, sans-serif;
                        },
					</style>
				</head>
				<body>${html}</body></html>`;
        setHtmlContent(htmlContentString);
    }

    /**
 *
 */
    async function renderVatAttestation(
        company: { name: string; id: number },
        client: Client | null,
        clientType: string,
        docData: {
            series: string;
            no: string;
            worksiteAddress: string;
            date: string;
        },
        docType: string
    ) {
        const response = await get(
            `${UrlEnum.documentTemplates}/${DocTypes.vatAttestation}/${company.id}`
        );
        if (response.errors) return "";
        let contentJson = JSON.parse(response.customText);
        const contentState = convertFromRaw(contentJson);
        let customHtml = stateToHTML(contentState);

        customHtml = fromStringTemplate(customHtml, {
            companyName: company.name,
            clientName: client?.name,
            clientType: clientType,
            clientAddress: Address.stringify(client?.address),
            docType: docType,
            docNo: docData?.series + "/" + docData?.no,
            workSiteAddress: docData?.worksiteAddress || "",
            date: docData?.date || "",
        });
        return customHtml;
    }

    let url = UrlEnum.pdf.replace("{type}", "vatAttestation");
    url = url.replace("{id}", "0");
    return (
        <>
            {
                (props.company.address.fk_countryId === 21 &&
                    props.client?.fk_VATTypeId === 3) &&
                <ListItem button onClick={() => setDialogOpen(true)}>
                    <ListItemIcon>
                        <PostAddIcon />
                    </ListItemIcon>
                    <ListItemText primary={lang.get("vatAttestation")} />
                </ListItem>
            }
            {
                htmlContent &&
                <PreviewPDF
                    open={htmlContent.length > 0}
                    onClose={() => setHtmlContent("")}
                    htmlContent={htmlContent}
                    footerHtml={""}
                    url={url}
                    setSmallMessage={props.setSmallMessage}
                    emailData={{ to: "", subject: "", message: "" }}
                />
            }

            {
                dialogOpen &&
                <Dialog
                    open={dialogOpen}
                >
                    <DialogTitle>{lang.get("clientType")}</DialogTitle>
                    <DialogContent>
                        <FormControl>
                            <Select
                                onChange={(event) => setClientType(event.target.value)}
                                value={clientType}
                            >
                                <option value={lang.get("owner")}>{lang.get("owner")}</option>
                                <option value={lang.get("tenant")}>{lang.get("tenant")}</option>
                                <option value={lang.get("usufructuary")}>{lang.get("usufructuary")}</option>
                                <option value={lang.get("manager")}>{lang.get("manager")}</option>
                            </Select>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="contained"
                            style={{background:theme.palette.header?.main}}
                            onClick={() => {
                                setDialogOpen(false);
                                generateVatAttestation();
                            }
                            }
                        >OK</Button>
                        <Button
                            variant="contained"
                            style={{background:theme.palette.errorColor?.main}}
                            onClick={() => setDialogOpen(false)}
                        >{lang.get("cancel")}</Button>
                    </DialogActions>
                </Dialog>
            }

        </>
    )
}

export default VatAttestation;