import React, {Component} from 'react';
import Lang from '../../lang';
import { TextField, Divider } from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import MuiPhoneInput from 'material-ui-phone-number';
import PropTypes from 'prop-types';
import VatNo from '../../Components/VatNo'
import Config from '../../Utils/Config';

class GeneralInfo extends Component{

    /**
     * constructor
     * @param {Object} props 
     */
    constructor(props){
        super(props);
        this.lang = Lang.getInstance();
        this.mapWrapperRef = React.createRef();
        this.editedModel = null;
    }

    vatNoBlur(data) {
        const m  = this.props.model;
        m.vatNo = data;
        this.setState({ "model": m});  
        // console.log(this.props.model.vatNo) 
    }

  
   
    /**
     * render method
     */
    render(){
        return(
            
            <div style={{padding:20}}>
            <ValidatorForm onSubmit={()=>{}}>
                {/** name  */}
                <TextValidator
                label = {this.lang.get("name")}
                fullWidth
                name="name"
                value={this.props.model.name}
                onChange={this.props.onChange}
                validators={['required']} 
                errorMessages={[this.lang.get("fieldRequired")]} />

                {/** representativeName */}
                <TextValidator
                label = {this.lang.get("representativeName")}
                fullWidth
                name="representativeName"
                value={this.props.model.representativeName}
                onChange={this.props.onChange}
                validators={['required']} 
                errorMessages={[this.lang.get("fieldRequired")]} />

                {/** vatNo*/}
                <VatNo name={"vatNo"} onBlur={this.vatNoBlur.bind(this)} value={this.props.model.vatNo}></VatNo>

                    {/** companyRegNo*/}
                    <TextField
                name="companyRegNo"
                onChange={this.props.onChange}
                value={this.props.model.companyRegNo}
                label = {this.lang.get("companyRegNo")}
                fullWidth/>

                {/** mobile*/}
                <MuiPhoneInput
                enableLongNumbers={true}
                onlyCountries={Config.phonePrefixCountries}
                fullWidth
                defaultCountry='be'
                required={true}
                id="mobile"
                value={this.props.model.mobile}
                label={this.lang.get("mobile")}
                onChange={this.props.phoneNoChange}
                name="mobile"
                regions={'europe'}
                validators={['minNumber:5']}
                />

                {/** webPage */}
                <TextField
                name="web"
                onChange={this.props.onChange}
                label = {this.lang.get("webPage")}
                value={this.props.model.web}
                fullWidth
    
                />
                    {/** email  */}
                    <TextValidator
                fullWidth
                label={this.lang.get("email")}
                onChange={this.props.onChange}
                name="emails" 
                value={this.props.model.emails}
                validators={['required', 'isEmail']}
                errorMessages={[this.lang.get("fieldRequired"), this.lang.get("emailNotValid")]}
                />
                </ValidatorForm>
                <Divider></Divider>
            </div>
        );
    }
}

GeneralInfo.propTypes = {
    phoneNoChange: PropTypes.func.isRequired,
    model: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired
};


export default GeneralInfo;