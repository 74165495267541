import React, { useState,useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { FormControl } from "@material-ui/core";
import DropzoneAreaField from "./components/DragLogo";
import About from "./components/About";
import Bank from "./components/Bank";
import Contact from "./components/Contact";
import Grid from "@material-ui/core/Grid";
import logo from "../Images/logo/cronotimal.svg";
import Lang from "../lang";
import Terms from "./components/Terms";
import { Typography } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { isLoggedIn, readCookie } from "../Utils/Utils";
import { CACHED_DATA_TAG } from "../Utils/Constants";
import theme from "../Theme/Theme";
import { post, get,UrlEnum,checkMailConfirmed } from "../Utils/Utils";
// import { format } from "url";

let lang = Lang.getInstance();

let componentRefs = [
  React.createRef(),
  React.createRef(),
  React.createRef(),
  React.createRef(),
];

let currentComponent;

function getSteps() {
  return ["", "", "", ""];
}

export default function HorizontalLinearStepper(props) {
  if (!isLoggedIn()) {
    document.cookie = "";
    document.location.href = "/login";
    window.localStorage.removeItem(CACHED_DATA_TAG);
  }

  const classes = useStyles(props);
  const [countries,setCountries] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const steps = getSteps();
  const [renderButtons, setRenderButtons] = useState(true);
  currentComponent = componentRefs[activeStep];
  const [errors, setErrors] = useState(null);
  const username = readCookie('username');
  const changeRenderBtns = (value) => {
    setRenderButtons(value);
  };

  useEffect(()=>{
    isEmailConfirmed();
  },[])
  async function isEmailConfirmed(){
    if(username)
    {const confirmed =  await checkMailConfirmed(username);
      if (!confirmed) {
        document.location.href = "/confirmEmail";
      }
    } else{
      window.location.href= '/login';
    }
  }

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <About ref={componentRefs[0]} />;
      case 1:
        if (errors) setErrors(null);
        if(componentRefs[0].current)checkIfClientExist(componentRefs[0]);
        return <Bank ref={componentRefs[1]} />;
      case 2:
        if (errors) setErrors(null);
        return <Contact ref={componentRefs[2]} countries={countries}/>;
      case 3:
        if (errors) setErrors(null);
        return (
          <DropzoneAreaField
            doneCallback={changeRenderBtns}
            ref={componentRefs[3]}
          />
        );
      default:
        return null;
    }
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleErrors = (err) => {
      setActiveStep(0);
      setErrors(err);
  };

  const handleNext = async () => {
    if (!currentComponent.current.validate) {
      return;
    }
    let valid = await currentComponent.current.validate();
    if (!valid) return;

    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };
  useEffect(() => {
    const tempcountries = getCountries();
    setCountries(tempcountries);
}, []);


  async function getCountries()
    {
        const response = await get(UrlEnum.countries);
        if(response.errors)
        {
             ///errors
        }
        let tempCountries =[];
        for(let i=0;i<response.length;i++)
        {
            tempCountries[i]=response[i];
        }
       setCountries(tempCountries);
    }

 function checkIfClientExist(data) {
    if (data.current.state !== null) {
      const form = new FormData();
      form.append("name", data.current.state.model.name);
      form.append(
        "representativeName",
        data.current.state.model.representativeName
      );
      form.append("vatNo", data.current.state.model.vatNo);
      form.append("bankAccount", "");
      form.append("bankName", "");
      form.append("bankBic", "");
      form.append("address", "");
      form.append("city", "");
      form.append("termsAndConditions", "");
      form.append("address.country", "");
      form.append("logo", "");
      form.append("signature", "");
      form.append("code", "");
      form.append("mobile", "");
      form.append("package", "");
      form.append("email", "");
      post(UrlEnum.verifyCompany, form).then(
        function (data) {
          if (data.errors) {
            setActiveStep(0);
            setErrors(data.errors);
          }
    })
  }
}

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // const handleReset = () => {
  //   setActiveStep(0);
  // };

  const wizzardButtons = renderButtons ? (
    <Grid container direction="row" justify="center" className={classes.grid}>
      <Grid className={classes.grid}>
        <Button
          variant="contained"
          style={{
            background: theme.palette.header?.main,
            color: theme.palette.textColorSecondary?.main,
          }}
          onClick={handleNext}
          className={classes.button}
        >
          {activeStep === steps.length - 1
            ? lang.get("next")
            : lang.get("next")}
        </Button>
      </Grid>
      <Grid className={classes.grid}>
        <Button
          disabled={activeStep === 0}
          variant="contained"
          style={{
            background: theme.palette.header?.main,
            color: theme.palette.textColorSecondary?.main,
          }}
          onClick={handleBack}
          className={classes.button}
        >
          {lang.get("back")}
        </Button>
      </Grid>
    </Grid>
  ) : (
    ""
  );

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Paper className={classes.paper}>
          <FormControl fullWidth={true} className={classes.grid}>
            <div className={classes.logo}>
              <img style={{width:"120px"}} src={logo} alt="logo" />
            </div>
          </FormControl>

          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={label + index} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {activeStep === steps.length ? (
            <div>
              <Typography className={classes.container}>
                TERMS AND CONDITIONS
              </Typography>

              <Terms
                onError={handleErrors}
                acceptCallback={() => {
                  console.log("accepted");
                }}
              />
            </div>
          ) : (
            <div>
              {getStepContent(activeStep)}
              {errors ? <Alert severity="error">{errors}</Alert> : ""}
              {wizzardButtons}
            </div>
          )}
        </Paper>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  container: {
    paddingBottom: 5,
    display: "flex",
    justifyContent: "center",
    flexGrow: 1,
    overflow: "hidden",
    backgroundSize: "cover",
    backgroundPosition: "0 400px",
  },

  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  paper: theme.mixins.gutters({
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: "10%",
    width: "40%",
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    [theme.breakpoints.down("md")]: {
      width: "80%",
    },
  }),
  grid: theme.mixins.gutters({
    flexDirection: "row",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      justifyContent: "center",
    },
  }),
  terms: theme.mixins.gutters({
    width: 500,
    height: 500,
    [theme.breakpoints.down("md")]: {
      height: 50,
      width: "100%",
      justifyContent: "center",
    },
  }),
  field: {
    marginTop: 20,
    paddingTop: 16,
    paddingBottom: 16,

    buttonsField: {
      justifyContent: "center",
      display: "flex",
    },
  },
  button: theme.mixins.gutters({
    paddingTop: 5,
    paddingBottom: 5,
    marginTop: 10,
    width: "80%",
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      flexDirection: "column",
      alignContent: "center",
    },
  }),
}));
