import React, { useState } from "react";
import PreviewPDF from "../../DocumentComponents/PreviewPDF";
import { UrlEnum } from "../../Utils/Utils";
import CachedDataSingleton from "../../cachedDataSingleton";
import { NotificationFooter } from "./NotificationPDF/NotificationFooter";
import { NotificationContent } from "./NotificationPDF/NotificationContent";
import Lang from "../../lang";

type notificationPreviewProps = {
  renderPreview: boolean;
  model: any;
  closePreview: () => void;
  hasLiability: () => void;
};
const cachedData = CachedDataSingleton.getInstance();

export default function NotificationPreview(props: notificationPreviewProps) {
  const [HTMLContent, setHTMLContent] = useState("");
  const { closePreview, renderPreview, model } = props;
  const lang = Lang.getInstance();

  let url = UrlEnum.pdf.replace("{type}", "notice");
  url = url.replace("{id}", "0");

  NotificationContent(model).then(response => {
    if (response) setHTMLContent(response);
  });

  return (
    <PreviewPDF
      open={renderPreview}
      onClose={closePreview}
      htmlContent={HTMLContent}
      footerHtml={NotificationFooter()}
      setSmallMessage={() => {}}
      url={url}
      emailData={{
        to: model.email ? model.email : "",
        subject: lang.get("notice"),
        message: ""
      }}
    />
  );
}
