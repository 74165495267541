import React, { Fragment, useState, useEffect } from "react";
import Lang from "../lang";
import DrawerLayout from "./DrawerLayout";
import MessageDialog from "../Components/MessageDialog";
import {
  ListItem,
  List,
  makeStyles,
  CircularProgress,
  Grid,
  ListItemIcon,
  ListItemText,
  Divider,
  Button,
  Tooltip,
} from "@material-ui/core";
import InvoiceHeader from "../DocumentTemplates/InvoiceHeader";
import Document from "../DocumentTemplates/Document";
import CachedDataSingleton from "../cachedDataSingleton";
import { Client, DocService } from "../Models/Models";
import Items from "../DocumentTemplates/Items";
import InvoiceFooter from "../DocumentTemplates/InvoiceFooter";
import clsx from "clsx";
import TextEditor from "../Components/TextEditor";
import {
  UrlEnum,
  get,
  DocTypes,
  isMobile,
  LocalUrlEnum,
  SubscriptionsEnum,
  readCookie,
  UserRolesEnum,
} from "../Utils/Utils";
import FormSubmitButton from "../Forms/FormSubmitButton";
import InsertDriveFile from "@material-ui/icons/InsertDriveFile";
import Receipt from "../DocumentTemplates/Receipt";
import NoteIcon from "@material-ui/icons/Note";
import EstimateHeader from "../DocumentTemplates/EstimateHeader";
import EstimateFooter from "../DocumentTemplates/EstimateFooter";
import Totals from "../DocumentTemplates/Totals";
import AdditionalInfoWrapper from "../DocumentTemplates/EstimateAdditionalInfo";
import Config from "../Utils/Config";
import moment from "moment";
import LiabilityHeader from "../DocumentTemplates/Liabilitys/LiabilityHeader";
import LiabilityFooter from "../DocumentTemplates/Liabilitys/LiabilityFooter";
import NoticeHeader from "../DocumentTemplates/Notices/NoticeHeader";
import LiabilityItems from "../DocumentTemplates/Liabilitys/LiabilityItems";
import SubjectIcon from "@material-ui/icons/Subject";
import SimpleHeader from "../DocumentTemplates/SimpleHeader";
import CodeIcon from "@material-ui/icons/Code";

const colors = {
  blue: "#b7c3f7",
  green: "#9af5a2",
  orange: "#ffd778",
  purple: "#ffa8d8",
  gray: "#c9c9c9",
};

const useStyles = makeStyles((theme) => ({
  activeModel: {
    border: "2px solid " + theme.palette.header?.main,
  },
  listItemText: {
    fontSize: "10pt",
    textTransform: "capitalize",
  },
  swatch: {
    display: "inline-block",
    width: 25,
    height: 25,
    border: "1px solid #ccc",
    margin: 5,
    "& a": {
      display: "block",
      width: "100%",
      height: "100%",
    },
  },
  loading: {
    display: "block",
    margin: "0 auto",
    marginTop: 20,
  },
  color1: {
    background: colors.blue,
  },
  color2: {
    background: colors.green,
  },
  color3: {
    background: colors.orange,
  },
  color4: {
    background: colors.purple,
  },
  color5: {
    background: colors.gray,
  },
  styleImage: {
    maxWidth: 120,
    boxShadow: "2px 2px 4px #555",
    display: "block",
    margin: "0 auto",
  },
  li: { display: "block" },
  docWrapper: {
    boxShadow: "0px 0px 8px #999",
  },
  appBar: {
    left: 340,
    top: 64,
    padding: 0,
    transition: theme.transitions.create("left, width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: "calc(100% - 300px)",
    [theme.breakpoints.down("md")]: {
      top: 56,
    },
  },
  appBarShift: {
    transition: theme.transitions.create("left, width", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    left: 0,
    width: "100%",
  },
}));

const lang = Lang.getInstance();

const cachedData = CachedDataSingleton.getInstance();
let submitFunction = null;

export default function DocumentTemplates() {
  const [message, setMessage] = useState(null);
  const [appearance, setAppearance] = useState(0);
  const [color, setColor] = useState(colors.orange);
  const [docType, setDocType] = useState(DocTypes.invoice);
  const [drawerOpen, setDrawerOpen] = useState(!isMobile());

  const [customTextTop, setCustomTextTop] = useState(null);
  const [customText, setCustomText] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isLinkCopied, setIsLinkCopied] = useState(false);
  const classes = useStyles();
  const textEditorRefTop = React.createRef();
  const textEditorRef = React.createRef();

  const company = cachedData.get("company");
  // const subscription = cachedData.get("subscription");
  const cookie = readCookie("userRoles");
  const userRoles = JSON.parse(cookie);

  useEffect(() => {
    get(UrlEnum.documentTemplates + "/" + docType).then((response) => {
      if (response.errors) {
        alert(response.errors);
        setLoading(false);
        return;
      }
      if (response) {
        if (response.customText) {
          setColor(response.color);
          setAppearance(response.appearance);
          setCustomText(JSON.parse(response.customText));
        } else {
          setCustomText(null);
        }
        if (response.customTextTop) {
          setCustomTextTop(JSON.parse(response.customTextTop));
        } else {
          setCustomTextTop(null);
        }
      }
      setLoading(false);
    });
  }, [docType]);

  /**
   *
   */
  function _changeAppearance (e, val) {
    e.preventDefault();
    setAppearance(val);
    return false;
  }

  /**
   *
   */
  function _changeColor (e, val) {
    e.preventDefault();
    const keys = Object.keys(colors);
    setColor(colors[keys[val]]);
    return false;
  }

  /**
   *
   */
  function submit (e) {
    e.preventDefault();
    if (submitFunction) {
      let data = {
        appearance: appearance,
        color: color,
        customText: textEditorRef.current
          ? JSON.stringify(textEditorRef.current.getContent())
          : ""
      };
      if (textEditorRefTop.current) {
        data.customTextTop = textEditorRefTop.current
          ? JSON.stringify(textEditorRefTop.current.getContent())
          : "";
      }
      submitFunction(data);
    }
    return false;
  }

  /**
   *
   */
  function _renderDocPreview () {
    switch (docType) {
      case DocTypes.invoice:
        return (
          <Grid container>
            <Grid item sm={6}>
              <a onClick={e => _changeAppearance(e, 0)} href="/">
                <img
                  alt="model0"
                  src="/images/model1.jpg"
                  className={clsx(
                    classes.styleImage,
                    appearance === 0 && classes.activeModel
                  )}
                />
              </a>
              <br />
              <a onClick={(e) => _changeAppearance(e, 2)} href="/">
                <img
                  alt="model1"
                  src="/images/model3.jpg"
                  className={clsx(
                    classes.styleImage,
                    appearance === 2 && classes.activeModel
                  )}
                />
              </a>
            </Grid>
            <Grid item sm={6}>
              <a onClick={(e) => _changeAppearance(e, 1)} href="/">
                <img
                  alt="model2"
                  src="/images/model2.jpg"
                  className={clsx(
                    classes.styleImage,
                    appearance === 1 && classes.activeModel
                  )}
                />
              </a>
              <br />
              <a onClick={(e) => _changeAppearance(e, 3)} href="/">
                <img
                  alt="model3"
                  src="/images/model4.jpg"
                  className={clsx(
                    classes.styleImage,
                    appearance === 3 && classes.activeModel
                  )}
                />
              </a>
            </Grid>
          </Grid>
        );
      case DocTypes.estimate:
        return (
          <Grid container>
            <Grid item sm={6}>
              <a onClick={(e) => _changeAppearance(e, 0)} href="/">
                <img
                  alt="model0"
                  src="/images/modelEstimate1.png"
                  className={clsx(
                    classes.styleImage,
                    appearance === 0 && classes.activeModel
                  )}
                />
              </a>
              <br />
              <a onClick={(e) => _changeAppearance(e, 2)} href="/">
                <img
                  alt="model1"
                  src="/images/modelEstimate3.png"
                  className={clsx(
                    classes.styleImage,
                    appearance === 2 && classes.activeModel
                  )}
                />
              </a>
            </Grid>
            <Grid item sm={6}>
              <a onClick={(e) => _changeAppearance(e, 1)} href="/">
                <img
                  alt="model2"
                  src="/images/modelEstimate2.png"
                  className={clsx(
                    classes.styleImage,
                    appearance === 1 && classes.activeModel
                  )}
                />
              </a>
              <br />
              <a onClick={(e) => _changeAppearance(e, 3)} href="/">
                <img
                  alt="model4"
                  src="/images/modelEstimate4.jpg"
                  className={clsx(
                    classes.styleImage,
                    appearance === 3 && classes.activeModel
                  )}
                />
              </a>
            </Grid>
          </Grid>
        );
      case DocTypes.orderTicket:
        return (
          <Grid container>
            <Grid item sm={6}>
              <a onClick={(e) => _changeAppearance(e, 0)} href="/">
                <img
                  alt="model0"
                  src="/images/modelEstimate1.png"
                  className={clsx(
                    classes.styleImage,
                    appearance === 0 && classes.activeModel
                  )}
                />
              </a>
              <br />
              <a onClick={(e) => _changeAppearance(e, 2)} href="/">
                <img
                  alt="model1"
                  src="/images/modelEstimate3.png"
                  className={clsx(
                    classes.styleImage,
                    appearance === 2 && classes.activeModel
                  )}
                />
              </a>
            </Grid>
            <Grid item sm={6}>
              <a onClick={(e) => _changeAppearance(e, 1)} href="/">
                <img
                  alt="model2"
                  src="/images/modelEstimate2.png"
                  className={clsx(
                    classes.styleImage,
                    appearance === 1 && classes.activeModel
                  )}
                />
              </a>
              <br />
              <a onClick={(e) => _changeAppearance(e, 3)} href="/">
                <img
                  alt="model4"
                  src="/images/modelEstimate4.jpg"
                  className={clsx(
                    classes.styleImage,
                    appearance === 3 && classes.activeModel
                  )}
                />
              </a>
            </Grid>
          </Grid>
        );
      case DocTypes.receipt:
      default:
        return null;
    }
  }

  /**
   * render links
   */
  function _renderOptions() {
    const invoicingRole = userRoles.find(
      (role) => role.role === UserRolesEnum.INVOICING
    );
    const estimatesRole = userRoles.find(
      (role) => role.role === UserRolesEnum.ESTIMATES
    );
    const adminRole = userRoles.find(
      (role) => role.role === UserRolesEnum.ADMIN
    );
    let role;
    if (adminRole) role = UserRolesEnum.ADMIN;
    else {
      if (estimatesRole) role = UserRolesEnum.ESTIMATES;
      else {
        if (invoicingRole) role = UserRolesEnum.INVOICING;
        else role = "";
      }
    }
    switch (role) {
      case UserRolesEnum.ADMIN:
        return (
          <div>
            <List>
              <ListItem
                button
                onClick={(event) => setDocType(DocTypes.invoice)}
              >
                <ListItemIcon>
                  <InsertDriveFile />
                </ListItemIcon>
                <ListItemText primary={lang.get("invoice")} />
              </ListItem>
              <ListItem
                button
                onClick={(event) => setDocType(DocTypes.liability)}
              >
                <ListItemIcon>
                  <InsertDriveFile />
                </ListItemIcon>
                <ListItemText primary={lang.get("liability")} />
              </ListItem>

              <ListItem
                button
                onClick={(event) => setDocType(DocTypes.receipt)}
              >
                <ListItemIcon>
                  <InsertDriveFile />
                </ListItemIcon>
                <ListItemText primary={lang.get("receipt")} />
              </ListItem>

              <ListItem button onClick={(event) => setDocType(DocTypes.notice)}>
                <ListItemIcon>
                  <InsertDriveFile />
                </ListItemIcon>
                <ListItemText primary={lang.get("notice")} />
              </ListItem>

              <ListItem
                button
                onClick={(event) => setDocType(DocTypes.estimate)}
              >
                <ListItemIcon>
                  <NoteIcon />
                </ListItemIcon>
                <ListItemText primary={lang.get("estimate")} />
              </ListItem>

              <ListItem
                button
                onClick={(event) => setDocType(DocTypes.orderTicket)}
              >
                <ListItemIcon>
                  <NoteIcon />
                </ListItemIcon>
                <ListItemText primary={lang.get("orderTicket")} />
              </ListItem>

              <ListItem
                button
                onClick={(event) => setDocType(DocTypes.liability)}
              >
                <ListItemIcon>
                  <InsertDriveFile />
                </ListItemIcon>
                <ListItemText primary={lang.get("reminderEmailSignature")} />
              </ListItem>

              <ListItem
                button
                onClick={(event) => setDocType(DocTypes.generalConditions)}
              >
                <ListItemIcon>
                  <SubjectIcon />
                </ListItemIcon>
                <ListItemText primary={lang.get("generalConditions")} />
              </ListItem>

              <ListItem
                button
                onClick={(event) => setDocType(DocTypes.vatAttestation)}
              >
                <ListItemIcon>
                  <SubjectIcon />
                </ListItemIcon>
                <ListItemText primary={lang.get("vatAttestation")} />
              </ListItem>

              <ListItem
                button
                onClick={(event) => setDocType("companyContract")}
              >
                <ListItemIcon>
                  <SubjectIcon />
                </ListItemIcon>
                <ListItemText primary={lang.get("contract")} />
              </ListItem>
            </List>
            <Divider />
            <p style={{ marginLeft: 20 }}>{lang.get("design")}</p>
            {_renderDocPreview()}
            <List>
              <ListItem>{lang.get("color")}</ListItem>
              <ListItem>
                <div>
                  <span className={`${classes.color1} ${classes.swatch}`}>
                    <a href="/" onClick={(e) => _changeColor(e, 0)}>
                      &nbsp;
                    </a>
                  </span>
                  <span className={`${classes.color2} ${classes.swatch}`}>
                    <a href="/" onClick={(e) => _changeColor(e, 1)}>
                      &nbsp;
                    </a>
                  </span>
                  <span className={`${classes.color3} ${classes.swatch}`}>
                    <a href="/" onClick={(e) => _changeColor(e, 2)}>
                      &nbsp;
                    </a>
                  </span>
                  <span className={`${classes.color4} ${classes.swatch}`}>
                    <a href="/" onClick={(e) => _changeColor(e, 3)}>
                      &nbsp;
                    </a>
                  </span>
                  <span className={`${classes.color5} ${classes.swatch}`}>
                    <a href="/" onClick={(e) => _changeColor(e, 4)}>
                      &nbsp;
                    </a>
                  </span>
                </div>
              </ListItem>
              <ListItem>
                <form
                  onSubmit={submit}
                  style={{ display: "block", width: "100%" }}
                >
                  <FormSubmitButton
                    key={docType}
                    fullWidth
                    getSubmit={(fct) => (submitFunction = fct)}
                    url={UrlEnum.documentTemplates + "/" + docType}
                  />
                </form>
              </ListItem>
            </List>
          </div>
        );
      case UserRolesEnum.INVOICING:
        return (
          <div>
            <List>
              <ListItem
                button
                onClick={(event) => setDocType(DocTypes.invoice)}
              >
                <ListItemIcon>
                  <InsertDriveFile />
                </ListItemIcon>
                <ListItemText primary={lang.get("invoice")} />
              </ListItem>
              <ListItem
                button
                onClick={(event) => setDocType(DocTypes.liability)}
              >
                <ListItemIcon>
                  <InsertDriveFile />
                </ListItemIcon>
                <ListItemText primary={lang.get("liability")} />
              </ListItem>

              <ListItem
                button
                onClick={(event) => setDocType(DocTypes.receipt)}
              >
                <ListItemIcon>
                  <InsertDriveFile />
                </ListItemIcon>
                <ListItemText primary={lang.get("receipt")} />
              </ListItem>

              <ListItem button onClick={(event) => setDocType(DocTypes.notice)}>
                <ListItemIcon>
                  <InsertDriveFile />
                </ListItemIcon>
                <ListItemText primary={lang.get("notice")} />
              </ListItem>

              <ListItem
                button
                onClick={(event) => setDocType(DocTypes.liability)}
              >
                <ListItemIcon>
                  <InsertDriveFile />
                </ListItemIcon>
                <ListItemText primary={lang.get("reminderEmailSignature")} />
              </ListItem>

              <ListItem
                button
                onClick={(event) => setDocType(DocTypes.generalConditions)}
              >
                <ListItemIcon>
                  <SubjectIcon />
                </ListItemIcon>
                <ListItemText primary={lang.get("generalConditions")} />
              </ListItem>

              <ListItem
                button
                onClick={(event) => setDocType(DocTypes.vatAttestation)}
              >
                <ListItemIcon>
                  <SubjectIcon />
                </ListItemIcon>
                <ListItemText primary={lang.get("vatAttestation")} />
              </ListItem>
            </List>
            <Divider />
            <p style={{ marginLeft: 20 }}>{lang.get("design")}</p>
            {_renderDocPreview()}
            <List>
              <ListItem>{lang.get("color")}</ListItem>
              <ListItem>
                <div>
                  <span className={`${classes.color1} ${classes.swatch}`}>
                    <a href="/" onClick={(e) => _changeColor(e, 0)}>
                      &nbsp;
                    </a>
                  </span>
                  <span className={`${classes.color2} ${classes.swatch}`}>
                    <a href="/" onClick={(e) => _changeColor(e, 1)}>
                      &nbsp;
                    </a>
                  </span>
                  <span className={`${classes.color3} ${classes.swatch}`}>
                    <a href="/" onClick={(e) => _changeColor(e, 2)}>
                      &nbsp;
                    </a>
                  </span>
                  <span className={`${classes.color4} ${classes.swatch}`}>
                    <a href="/" onClick={(e) => _changeColor(e, 3)}>
                      &nbsp;
                    </a>
                  </span>
                  <span className={`${classes.color5} ${classes.swatch}`}>
                    <a href="/" onClick={(e) => _changeColor(e, 4)}>
                      &nbsp;
                    </a>
                  </span>
                </div>
              </ListItem>
              <ListItem>
                <form
                  onSubmit={submit}
                  style={{ display: "block", width: "100%" }}
                >
                  <FormSubmitButton
                    key={docType}
                    fullWidth
                    getSubmit={(fct) => (submitFunction = fct)}
                    url={UrlEnum.documentTemplates + "/" + docType}
                  />
                </form>
              </ListItem>
            </List>
          </div>
        );
      case UserRolesEnum.ESTIMATES:
        return (
          <div>
            <List>
              <ListItem
                button
                onClick={(event) => setDocType(DocTypes.invoice)}
              >
                <ListItemIcon>
                  <InsertDriveFile />
                </ListItemIcon>
                <ListItemText primary={lang.get("invoice")} />
              </ListItem>
              <ListItem
                button
                onClick={(event) => setDocType(DocTypes.liability)}
              >
                <ListItemIcon>
                  <InsertDriveFile />
                </ListItemIcon>
                <ListItemText primary={lang.get("liability")} />
              </ListItem>

              <ListItem
                button
                onClick={(event) => setDocType(DocTypes.receipt)}
              >
                <ListItemIcon>
                  <InsertDriveFile />
                </ListItemIcon>
                <ListItemText primary={lang.get("receipt")} />
              </ListItem>

              <ListItem button onClick={(event) => setDocType(DocTypes.notice)}>
                <ListItemIcon>
                  <InsertDriveFile />
                </ListItemIcon>
                <ListItemText primary={lang.get("notice")} />
              </ListItem>

              <ListItem
                button
                onClick={(event) => setDocType(DocTypes.estimate)}
              >
                <ListItemIcon>
                  <NoteIcon />
                </ListItemIcon>
                <ListItemText primary={lang.get("estimate")} />
              </ListItem>

              <ListItem
                button
                onClick={(event) => setDocType(DocTypes.orderTicket)}
              >
                <ListItemIcon>
                  <NoteIcon />
                </ListItemIcon>
                <ListItemText primary={lang.get("orderTicket")} />
              </ListItem>

              <ListItem
                button
                onClick={(event) => setDocType(DocTypes.liability)}
              >
                <ListItemIcon>
                  <InsertDriveFile />
                </ListItemIcon>
                <ListItemText primary={lang.get("reminderEmailSignature")} />
              </ListItem>

              <ListItem
                button
                onClick={(event) => setDocType(DocTypes.generalConditions)}
              >
                <ListItemIcon>
                  <SubjectIcon />
                </ListItemIcon>
                <ListItemText primary={lang.get("generalConditions")} />
              </ListItem>

              <ListItem
                button
                onClick={(event) => setDocType(DocTypes.vatAttestation)}
              >
                <ListItemIcon>
                  <SubjectIcon />
                </ListItemIcon>
                <ListItemText primary={lang.get("vatAttestation")} />
              </ListItem>
            </List>
            <Divider />
            <p style={{ marginLeft: 20 }}>{lang.get("design")}</p>
            {_renderDocPreview()}
            <List>
              <ListItem>{lang.get("color")}</ListItem>
              <ListItem>
                <div>
                  <span className={`${classes.color1} ${classes.swatch}`}>
                    <a href="/" onClick={(e) => _changeColor(e, 0)}>
                      &nbsp;
                    </a>
                  </span>
                  <span className={`${classes.color2} ${classes.swatch}`}>
                    <a href="/" onClick={(e) => _changeColor(e, 1)}>
                      &nbsp;
                    </a>
                  </span>
                  <span className={`${classes.color3} ${classes.swatch}`}>
                    <a href="/" onClick={(e) => _changeColor(e, 2)}>
                      &nbsp;
                    </a>
                  </span>
                  <span className={`${classes.color4} ${classes.swatch}`}>
                    <a href="/" onClick={(e) => _changeColor(e, 3)}>
                      &nbsp;
                    </a>
                  </span>
                  <span className={`${classes.color5} ${classes.swatch}`}>
                    <a href="/" onClick={(e) => _changeColor(e, 4)}>
                      &nbsp;
                    </a>
                  </span>
                </div>
              </ListItem>
              <ListItem>
                <form
                  onSubmit={submit}
                  style={{ display: "block", width: "100%" }}
                >
                  <FormSubmitButton
                    key={docType}
                    fullWidth
                    getSubmit={(fct) => (submitFunction = fct)}
                    url={UrlEnum.documentTemplates + "/" + docType}
                  />
                </form>
              </ListItem>
            </List>
          </div>
        );
      default:
        return null;
    }
  }

  /**
   * render template links
   */
  function _renderDrawerChildren() {
    return <Fragment>{_renderOptions()}</Fragment>;
  }

  /**
   *
   */
  function _renderDocument () {
    switch (docType) {
      case DocTypes.invoice:
        return renderInvoiceTemplate();
      case DocTypes.liability:
        return renderLiabilityTemplate();
      case DocTypes.receipt:
        return renderReceipt();
      case DocTypes.estimate:
        return renderEstimateTemplate();
      case DocTypes.orderTicket:
        return renderOrderTicketTemplate();
      case DocTypes.notice:
        return renderNoticeTemplate();
      case DocTypes.generalConditions:
        return renderGeneralConditions();
      case DocTypes.vatAttestation:
          return renderVatAttestation();
      case "companyContract":
        return renderContract();
      default:
        return null;
    }
  }

  function renderReceipt() {
    return (
      <Document className={classes.docWrapper}>
        <Receipt number={0} date={new Date()} total={0} />
      </Document>
    );
  }

  /**
   * render invoice template
   */
  function renderInvoiceTemplate() {
    return (
      <Document className={classes.docWrapper}>
        <InvoiceHeader
          appearance={appearance}
          company={company}
          client={new Client()}
          color={color}
          docType={docType}
        />
        <Items items={[new DocService()]} color={color} />
        <br />
        <br />
        <Totals
          totals={{
            totalNoVat: 0,
            vat: 0,
            totalWithVat: 0,
            discount: 0,
            totalAmountWithVat: 0,
            totalCost: 0,
          }}
          color={color}
        />
        <br />
        <br />
        <TextEditor
          docType={DocTypes.invoice}
          initialState={customText}
          ref={textEditorRef}
        />

        <InvoiceFooter company={company} />
      </Document>
    );
  }

  function renderLiabilityTemplate() {
    return (
      <Document className={classes.docWrapper}>
        <LiabilityHeader
          appearance={appearance}
          company={company}
          client={new Client()}
          color={color}
        />
        <br />
        <TextEditor
          docType={DocTypes.liability}
          initialState={customTextTop}
          ref={textEditorRefTop}
        />
        <br />
        {<LiabilityItems items={[new DocService()]} color={color} />}
        <br />
        <br />
        <TextEditor
          docType={DocTypes.liability}
          initialState={customText}
          ref={textEditorRef}
        />
        <br />
        <LiabilityFooter company={company} />
      </Document>
    );
  }

  function renderNoticeTemplate() {
    return (
      <Document className={classes.docWrapper}>
        <NoticeHeader
          appearance={appearance}
          company={company}
          client={new Client()}
          color={color}
        />
        <br />
        <TextEditor
          docType={DocTypes.notice}
          initialState={customTextTop}
          ref={textEditorRefTop}
        />
        <br />
        {<Items items={[new DocService()]} color={color} />}
        <br />
        <br />
        <TextEditor
          docType={DocTypes.notice}
          initialState={customText}
          ref={textEditorRef}
        />
        <br />
        <LiabilityFooter company={company} />
      </Document>
    );
  }

  /**
   * render estimate template
   */
  function renderEstimateTemplate() {
    return (
      <Document className={classes.docWrapper}>
        <EstimateHeader
          appearance={appearance}
          company={company}
          client={new Client()}
          color={color}
          workSiteAddress={lang.get("siteAddress")}
          title={lang.get("title")}
          description={lang.get("description")}
          additionalInfo={lang.get("additionalInfo")}
          docType={docType}
        />
        <AdditionalInfoWrapper totalNoVat={0} />
        <br />
        <TextEditor
          docType={DocTypes.estimate}
          initialState={customTextTop}
          ref={textEditorRefTop}
        />
        <br />
        <Items items={[new DocService()]} color={color} />
        <br />
        <br />
        <Totals
          color={color}
          totals={{
            totalNoVat: 0,
            vat: 0,
            totalWithVat: 0,
            discount: 0,
            totalAmountWithVat: 0,
            totalCost: 0,
          }}
        />
        <br />
        <br />
        <TextEditor
          docType={DocTypes.estimate}
          initialState={customText}
          ref={textEditorRef}
        />

        <EstimateFooter company={company} />
      </Document>
    );
  }

  /**
   * render estimate template
   */
  function renderOrderTicketTemplate() {
    return (
      <Document className={classes.docWrapper}>
        <EstimateHeader
          appearance={appearance}
          company={company}
          client={new Client()}
          color={color}
          workSiteAddress={lang.get("siteAddress")}
          title={lang.get("title")}
          docType={docType}
        />
        <AdditionalInfoWrapper
          totalNoVat={0}
          startDate={moment().format(Config.momentEUDateFormat)}
          endDate={moment().format(Config.momentEUDateFormat)}
        />
        <br />
        <TextEditor
          docType={DocTypes.orderTicket}
          initialState={customTextTop}
          ref={textEditorRefTop}
        />
        <br />
        <Items items={[new DocService()]} color={color} />
        <br />
        <br />
        <Totals
          color={color}
          totals={{
            totalNoVat: 0,
            vat: 0,
            totalWithVat: 0,
            discount: 0,
            totalAmountWithVat: 0,
            totalCost: 0,
          }}
        />
        <br />
        <br />
        <TextEditor
          docType={DocTypes.orderTicket}
          initialState={customText}
          ref={textEditorRef}
        />

        <EstimateFooter company={company} />
      </Document>
    );
  }

  /**
   *
   */
  function copyTextToClipboard(link) {
    navigator.clipboard.writeText(link);
    setIsLinkCopied(true);
  }
  function renderGeneralConditions() {
    const embedLink = `${document.location.protocol}//${document.location.host}${LocalUrlEnum.doc}/${company.id}/${docType}`;
    return (
      <>
        <div
          style={{
            width: 400,
            margin: "-20px auto 20px auto",
            border: "1px solid #ccc",
            borderRadius: 5,
          }}
        >
          <Tooltip
            title={isLinkCopied ? lang.get("linkCopied") : lang.get("copy")}
          >
            <Button onClick={() => copyTextToClipboard(embedLink)}>
              <CodeIcon /> {lang.get("embed")}
            </Button>
          </Tooltip>
          &nbsp;&nbsp;{" "}
          <a href={embedLink} target="_blank" rel="noreferrer">
            {embedLink}
          </a>
        </div>
        <Document className={classes.docWrapper}>
          <SimpleHeader
            appearance={appearance}
            company={company}
            color={color}
          />
          <TextEditor
            docType={DocTypes.generalConditions}
            height={850}
            initialState={customText}
            ref={textEditorRef}
          />
        </Document>
      </>
    );
  }

  /**
   *
   */
  function renderVatAttestation() {
    return (
      <>
        <Document className={classes.docWrapper}>
          <SimpleHeader
            appearance={appearance}
            company={company}
            color={color}
          />
          <TextEditor
            docType={DocTypes.vatAttestation}
            height={850}
            initialState={customText}
            ref={textEditorRef}
          />
        </Document>
      </>
    );
  }

  /**
   *
   */
  function renderContract() {
    return (
      <>
        <Document className={classes.docWrapper}>
          <SimpleHeader
            appearance={appearance}
            company={company}
            color={color}
          />
          <TextEditor
            docType="companyContract"
            height={850}
            initialState={customText}
            ref={textEditorRef}
          />
        </Document>
      </>
    );
  }

  return (
    <Fragment>
      <DrawerLayout
        drawerChildren={_renderDrawerChildren()}
        open={drawerOpen}
        handleDrawerClose={() => setDrawerOpen(!drawerOpen)}
        drawerWidth={300}
      >
        {loading ? (
          <CircularProgress
            className={classes.loading}
            color="secondary"
          ></CircularProgress>
        ) : (
          <Fragment>
            {/* <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                  [classes.appBarShift]: !drawerOpen
                })}
              >
                <Toolbar variant="dense">
                  <Button
                    color="inherit"
                    aria-label="menu"
                    onClick={() => setDrawerOpen(!drawerOpen)}
                  >
                    <MenuIcon />
                  </Button>
                </Toolbar>
              </AppBar> */}
            <br />
            {_renderDocument()}
          </Fragment>
        )}
      </DrawerLayout>
      <MessageDialog
        open={message ? true : false}
        text={message || ""}
        onResult={() => setMessage(null)}
      />
    </Fragment>
  );
}
