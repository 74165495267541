import React, { useState, Fragment } from 'react';
import Lang from '../lang';
import EditEmployees from '../Forms/EditEmployees';
import { UrlEnum, ResolutionBreakPoints, post } from '../Utils/Utils';
import { Switch, IconButton } from '@material-ui/core';
import TableComponent from '../Components/TableComponent';
import { Employee } from '../Models/Models';
import PersonAdd from '@material-ui/icons/PersonAdd';
import { useHistory, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import theme from "../Theme/Theme";
const lang = Lang.getInstance();
const docWidth = document.body.offsetWidth;
const docHeight = window.innerHeight;
let maxHeight = docHeight - 225;
let saving = false; // used to disable functionality while saving

export default function SubcontractorEmployees (props) {
    const routeMatch = useRouteMatch();
    const history = useHistory();
    let emptyEmployee = new Employee();
    emptyEmployee.fk_SubcontractorId = props.subcontractor.id;
    const [editedEmployeeModel, setEditedEmployeeModel] = useState(emptyEmployee);
    const [tableEmployeeModel, setTableEmployeeModel] = useState(emptyEmployee);
    const [model, setModel]= useState(props.subcontractor.persons);
    const [openEdit, setOpenEdit] = useState(null);

    let tableColumns = [
        { "label": lang.get("name"), "name": "name" },
        { "label": lang.get("mobile"), "name": "mobile" },
        {
            "label": lang.get("status"), "name": "active",
            "children": (model) => <Switch
                checked={!!+model.active}
                onChange={(e) => { changeModelActive(model) }}
                name="status"
                style={{color:theme.palette.header?.main}}
            />
        }

    ];

    // medium resolution
    if (docWidth <= ResolutionBreakPoints.md) {
        tableColumns = [
            { "label": lang.get("name"), "name": "name" },
            { "label": lang.get("mobile"), "name": "mobile" },
            {
                "label": lang.get("status"), "name": "active",
                "children": (model) => <Switch
                    checked={!!+model.active}
                    onChange={(e) => { changeModelActive(model) }}
                    name="status"
                    style={{color:theme.palette.header?.main}}
                />
            }
        ];
        maxHeight = docHeight - 195;
    }

    // small resolution
    if (docWidth <= ResolutionBreakPoints.sm) {
        tableColumns = [
            { "label": lang.get("name"), "name": "name" },
            {
                "label": lang.get("status"), "name": "active",
                "children": (model) => <Switch
                    checked={!!+model.active}
                    onChange={(e) => { changeModelActive(model) }}
                    name="status"
                    style={{color:theme.palette.header?.main}}
                />
            },
        ];
    }

    /**
    * open Drawer
    */
    const setEditedEmployees = function (model) {
        if (model !== editedEmployeeModel) {
            setEditedEmployeeModel(model);
        }
        if (!openEdit) {
            setOpenEdit(true);
        }
    };


    /**
     * change active property of model
     * @param {Object} model 
     */
    const changeModelActive = function (model) {
        if (saving) return;
        saving = true;
        const active = model.active === 1 ? 0 : 1;
        const url = `${UrlEnum.persons}/changeState/${model.id}/${active}`;
        post(url, {}).then((data) => {
            let m = Object.assign({}, model);
            m.active = data.active;
            setTableEmployeeModel(m);
            // disable change for one second
            setTimeout(() => {
                saving = false;
            }, 500);
        }).catch(() => {
            saving = false;
            alert(lang.get("error"));
        });
    }


    const editSubmitCallback = function (model) {
        if (model !== tableEmployeeModel) {
            setTableEmployeeModel(model);
        }
    };
    const addNew = function () {
        setEditedEmployeeModel(emptyEmployee);
    };
    // const deleteCallback = function(deleteModel) {
    // let index = props.subcontractor.persons.indexOf(deleteModel);
    //   if(index<0)
    //     index = props.subcontractor.persons.findIndex((element)=> element.id === deleteModel.id);
    //   let models = props.subcontractor.persons.slice();
    //   models.splice(index, 1);
    //  setModel(models);
        
    // }

    /**
     * render method
     */
    return (
        <Fragment>
            <IconButton color="inherit" onClick={addNew} id="addNew">
                <PersonAdd fontSize={"large"} />
            </IconButton>
            <EditEmployees
                url={UrlEnum.persons}
                routeMatch={routeMatch}
                model={editedEmployeeModel}
                submitCallback={editSubmitCallback} />

            <TableComponent
                editedModels={[tableEmployeeModel]}
                url={UrlEnum.persons}
                models={model}
                columns={tableColumns}
                maxHeight={maxHeight}
                routeMatch={routeMatch}
                editCallback={setEditedEmployees}
                // deleteCallback={deleteCallback} 
            />
        </Fragment>
    );
}

SubcontractorEmployees.propTypes = {
    subcontractor: PropTypes.object.isRequired
}