import * as React from 'react';
import { withStyles, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Lang from '../../lang';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { WizardModel } from '../../Models/Models';
import MuiPhoneInput from 'material-ui-phone-number';
import Config from '../../Utils/Config';
import { getCookieUserData, readCookie } from '../../Utils/Utils';


let lang = Lang.getInstance();

class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            model: WizardModel,
        };
    };
    formRef = React.createRef();
    handleChange = (event) => {
        const wizardModel = this.state.model;
        wizardModel[event.target.id] = event.target.value;
        this.setState({ "model": WizardModel });
    }

    validate () {
        let formRef = this.formRef.current;
        return formRef.isFormValid(false);
    }
    handleOnChangeMobile = (val) => {
        const wizModel = this.state.model;
        wizModel.mobile = val;
        this.setState({ "model": wizModel });
    }
    handleSelectCountries(event)
    {
      const currModel=this.state.model;
      let value = event.target.value;
    //   let country = this.state.countries.find((country)=> country.name === value )
      currModel.fk_countryId = value;
      this.setState({model:currModel});
    }
    componentDidMount () {
        const userData = getCookieUserData();
        if (!userData) return;
        const wizardModel = this.state.model;
        wizardModel["mobile"] = userData.phone;
        wizardModel["email"] = userData.email;
        this.setState({ model: wizardModel });
    }


    render () {
        const classes = this.props.classes;
        return (
            <div className={classes.container}>
                <Paper className={classes.paper}>
                    <h2 className={classes.container} >{lang.get("contact")}</h2>
                    <ValidatorForm ref={this.formRef} instantValidate={false} onSubmit={() => { }} >
                        <FormControl required={true} fullWidth={true} className={classes.field}>
                            <MuiPhoneInput
                                autoFormat={false}
                                defaultCountry='be'
                                required={true}
                                id="mobile"
                                onlyCountries={Config.phonePrefixCountries}
                                label={lang.get("mobile")}
                                onChange={this.handleOnChangeMobile}
                                name="mobile"
                                value={this.state.model.mobile}
                                regions={'europe'}
                                validators={['minNumber:5']}
                                enableLongNumbers >
                            </MuiPhoneInput>

                            <TextValidator
                                fullWidth
                                required={true}
                                id="email"
                                label={lang.get("email")}
                                onChange={this.handleChange}
                                name="email"
                                value={this.state.model.email}
                                validators={['required', 'isEmail']}
                                errorMessages={[lang.get("fieldRequired"), lang.get("emailNotValid")]}
                            />
                        </FormControl>
                        <FormControl required={true} fullWidth={true} className={classes.field}>
                            <TextValidator
                                fullWidth
                                required={true}
                                id="address"
                                name="address"
                                label={lang.get("address")}
                                onChange={this.handleChange}
                                validators={['required']}
                                value={this.state.model.address}
                                errorMessages={lang.get("fieldRequired")}
                            />
                        </FormControl>
                        <FormControl required={true} fullWidth={true} className={classes.field}>
                            <TextValidator
                                fullWidth
                                required={true}
                                id="city"
                                name="city"
                                label={lang.get("city")}
                                onChange={this.handleChange}
                                validators={['required']}
                                value={this.state.model.city}
                                errorMessages={[lang.get("fieldRequired")]}
                            />
                        </FormControl>
                        <FormControl fullWidth className={classes.textField} style={{marginTop:0}}>
                            <InputLabel>{lang.get("country")}</InputLabel>
                            <Select
                            fullWidth
                            id="site_address.country"
                            validators={['required']}
                            errorMessages={lang.get("fieldRequired")}
                            required={true}
                            value={this.state.model.fk_countryId}
                            onChange={(e) => this.handleSelectCountries(e)}
                            >
                            {this.props.countries.map((country) => (
                                <MenuItem
                                value={country.id}
                                id="site_address.fk_countryId"
                                key={country.id}
                                >
                                {country.name}
                                </MenuItem>
                            ))}
                            </Select>
                        </FormControl>
                        <FormControl required={true} fullWidth={true} className={classes.field}>
                            <TextValidator
                                fullWidth
                                type="number"
                                required={true}
                                name="code"
                                id="code"
                                label={lang.get("code")}
                                onChange={this.handleChange}
                                validators={['required']}
                                value={this.state.model.code}
                                errorMessages={lang.get("fieldRequired")}
                            />
                        </FormControl>
                    </ValidatorForm>
                </Paper>
            </div>

        );

    }

}

const styles = (theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        flexGrow: 1,
        overflow: 'hidden',
        backgroundSize: 'cover',
        backgroundPosition: '0 400px',


    },
    paper: theme.mixins.gutters({
        paddingTop: 2,
        paddingBottom: 20,
        width: '80%',
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    }),
    field: {
        marginTop: 0,
    },
    actions: theme.mixins.gutters({
        paddingTop: 16,
        paddingBottom: 16,
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'center'
    }),
    button: {
        marginRight: 100,

    },
});

export default withStyles(styles, { withTheme: true })(Contact);

