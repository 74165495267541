import React, { Component, Fragment } from "react";
import { withStyles, Theme, createStyles,Checkbox } from "@material-ui/core";
import EstimateItem from "./EstimateItem";
import Lang from "../lang";
import EstimatesContext from "../Contexts/EstimatesContext";
import { Estimate } from "../Models/Models";
import theme from "../Theme/Theme";
import { DocTypes } from "../Utils/Utils";

const styles = createStyles((theme: Theme) => ({
  row: {
    display: "flex",
    width: "100%",
  },
  col: {
    padding: "0 10px",
    display: "inline-block",
  },
  clientName: {
    flexGrow: 1,
    // width:'calc(100% - 200px)'
  },
  clientNameProject: {
    flexGrow: 0.79,
    // width:'calc(100% - 380px)'
  },
  statusProject: {
    minWidth: 80,
    textAlign: "left",
    paddingRight: 20,
    paddingLeft: 20,
  },
  totalProject: {
    minWidth: 125,
    textAlign: "left",
    paddingRight: 20,
    paddingLeft: 30,
  },
  total: {
    minWidth: 125,
    textAlign: "left",
    paddingRight: 20,
  },
  status: {
    minWidth: 100,
    textAlign: "left",
    paddingRight: 20,
  },
  header: {
    backgroundColor: theme.palette.header?.main,
    padding: "15px 43px 15px 10px",
    color: "#fff",
    borderRadius: "5px 5px 0 0",
  },
  dashboardNumber: {
    paddingLeft: 10,
  },
  dashboardDate: {
    paddingRight: 70,
  },
  dashboardTotal: {
    paddingRight: 10,
  },
  dashboardStatus: {
    paddingRight: 100,
  },
}));

type EstimateListProps = {
  estimates: Array<Estimate>;
  isMobile: boolean;
  classes: any;
  classesOverride?: any;
  select?: boolean;
  isProject?: boolean;
  isDashboard?: boolean;
  selectAllEstimates: Function;
};

class EstimateList extends Component<EstimateListProps, any> {
  classes: any;
  lang: any;
  isMobile: any;
  isProject: any;
  isDashboard: any;

  constructor(props: EstimateListProps) {
    super(props);
    this.classes = Object.assign(
      this.props.classes,
      this.props.classesOverride
    );
    this.lang = Lang.getInstance();
    this.isMobile = props.isMobile;
    this.isProject = props.isProject;
    this.isDashboard = props.isDashboard;
  }

  renderHeaderCols() {
    if (this.isMobile) {
      return (
        <div className={`${this.classes.row} ${this.classes.header}`}>
          <div className={this.classes.col}>{this.lang.get("number")}</div>
          <EstimatesContext.Consumer>
            {(context) => {
              let name =
                context.type ===  DocTypes.estimate || context.type ===DocTypes.orderTicket
                  ? this.lang.get("clientName")
                  : this.lang.get("subcontractor");
              return (
                <div
                  className={`${this.classes.col} ${this.classes.clientName}`}
                >
                  {name}
                </div>
              );
            }}
          </EstimatesContext.Consumer>
        </div>
      );
    } else if (this.isProject) {
      return (
        <div
          className={`${this.classes.row} ${this.classes.header}`}
          style={{ marginTop: "15px" }}
        >
          <div className={this.classes.col}>{this.lang.get("number")}</div>
          <EstimatesContext.Consumer>
            {(context) => {
              let name =
                context.type ===  DocTypes.estimate || context.type ===DocTypes.orderTicket
                  ? this.lang.get("clientName")
                  : this.lang.get("subcontractor");
              return (
                <div
                  className={`${this.classes.col} ${this.classes.clientNameProject}`}
                >
                  {name}
                </div>
              );
            }}
          </EstimatesContext.Consumer>
          <div className={this.classes.col}>{this.lang.get("date")}</div>
          <div className={`${this.classes.col} ${this.classes.totalProject}`}>
            {this.lang.get("totalWithVat")}
          </div>
          <div className={`${this.classes.col}  ${this.classes.statusProject}`}>
            {this.lang.get("status")}
          </div>
        </div>
      );
    } else {
      return (
        <div
          className={`${this.classes.row} ${this.classes.header}`}
          style={{ marginTop: "15px" }}
        >
          <Checkbox
            style={{ color: theme.palette.textColorSecondary?.main, paddingTop:"0px" }}
            onClick={(e)=>this.props.selectAllEstimates(e)}
          />
          <div
            className={
              this.isDashboard ? this.classes.dashboardNumber : this.classes.col
            }
          >
            {this.lang.get("number")}
          </div>
          <EstimatesContext.Consumer>
            {(context) => {
              let name =
                context.type === DocTypes.estimate || context.type ===DocTypes.orderTicket
                  ? this.lang.get("clientName")
                  : this.lang.get("subcontractor");
              return (
                <div
                  className={`${this.classes.col} ${this.classes.clientName}`}
                >
                  {name}
                </div>
              );
            }}
          </EstimatesContext.Consumer>
          <div
            className={
              this.isDashboard ? this.classes.dashboardDate : this.classes.col
            }
          >
            {this.lang.get("date")}
          </div>
          <div
            className={
              this.isDashboard
                ? `${this.classes.col} ${this.classes.dashboardTotal}`
                : `${this.classes.col} ${this.classes.total}`
            }
          >
            {this.lang.get("totalWithVat")}
          </div>
          <div
            className={
              this.isDashboard
                ? `${this.classes.col}  ${this.classes.dashboardStatus}`
                : `${this.classes.col}  ${this.classes.status}`
            }
          >
            {this.lang.get("status")}
          </div>
        </div>
      );
    }
  }

  renderEstimates() {
    return (
      <Fragment>
        {this.renderHeaderCols()}
        <EstimatesContext.Consumer>
          {(context) =>
            context.estimates.map((estimate: Estimate, index: number) => (
              <EstimateItem
                select={this.props.select}
                key={estimate.id}
                estimate={estimate}
                canDelete={true}
                isMobile={this.isMobile}
                changeEstimateState={context.changeEstimateState}
                onDelete={context.onDelete}
                onDuplicate={context.onDuplicate}
                onSelect={context.onSelect}
                classesOverride={this.props.classesOverride}
                isProject={this.props.isProject}
                isDashboard={this.props.isDashboard}
              />
            ))
          }
        </EstimatesContext.Consumer>
      </Fragment>
    );
  }

  render() {
    return <Fragment>{this.renderEstimates()}</Fragment>;
  }
}

export default withStyles(styles)(EstimateList);
