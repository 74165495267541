import {
  Button,
  Card,
  CardActions,
  CardContent,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Lang from "../../lang";

const useStyles = makeStyles((theme) => ({
  cardContent: {
    backgroundColor: "#555",
    color: "white",
    textAlign: "center",
  },
  checkIcon: {
    color: "#61ce70",
    marginRight: 10,
  },
  cancelIcon: {
    color: "#f16060",
    marginRight: 10,
  },
  button: {
    backgroundColor: "#61ce70",
    color: "white",
    fontSize: 15,
  },
}));

export default function BasicPackage(props) {
  const classes = useStyles();
  const lang = Lang.getInstance();
  const price = props.price.split(".");
  const selectedPackage = {
    name: props.name,
    amount: props.price,
    description: props.title,
    period: 30,
  };

  function getList() {
    return props.properties.map((property, index) => {
      return (
        <ListItem>
          {props.active[index] ? (
            <CheckCircleIcon className={classes.checkIcon} />
          ) : (
            <CancelIcon className={classes.cancelIcon} />
          )}

          <ListItemText>{property}</ListItemText>
        </ListItem>
      );
    });
  }
  return (
    <Card>
      <CardContent className={classes.cardContent}>
        <Typography gutterBottom variant="h4" component="div">
          {props.title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {props.description}
        </Typography>
      </CardContent>
      <CardContent>
        <div style={{ textAlign: "center" }}>
          <Typography
            gutterBottom
            variant="h3"
            component="div"
            style={{ marginBottom: 0 }}
          >
            <sup style={{ fontSize: 20 }}>€</sup> {price[0]}
            <sup style={{ fontSize: 20 }}>{price[1]}</sup>
          </Typography>
          <Typography
            gutterBottom
            variant="h6"
            component="div"
            style={{ marginTop: 0 }}
          >
           {lang.get("monthly")}
          </Typography>
        </div>
        <List
          sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
        >
          {getList()}
        </List>
      </CardContent>
      <CardActions>
        <div style={{ textAlign: "center", width: "100%" }}>
          <Button
            size="large"
            className={classes.button}
            onClick={() => props.handleShowPackage(selectedPackage)}
          >
            {lang.get("choosePlan")}
          </Button>
        </div>
      </CardActions>
    </Card>
  );
}
