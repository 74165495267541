import React, { useCallback, useEffect, useRef, useState, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Lang from '../../lang';
import theme from "../../Theme/Theme";
let lang = Lang.getInstance();


const Canvas = ({ width, height, doneCallback }) => {
    const canvasRef = useRef(null);
    const [isPainting, setIsPainting] = useState(false);
    const [mousePosition, setMousePosition] = useState(undefined);
    var p = false;
    var mp;

    const startPaint = useCallback((event) => {
        const coordinates = getCoordinates(event);
        if (coordinates) {
            setMousePosition(coordinates);
            setIsPainting(true);
            // eslint-disable-next-line react-hooks/exhaustive-deps
            p = true;
            // eslint-disable-next-line react-hooks/exhaustive-deps
            mp = coordinates;
        }
        /**
         * 
         */
        const canvas = canvasRef.current;
        canvas.addEventListener('mousemove', paint);
        canvas.addEventListener("touchmove", paint);

        canvas.removeEventListener('mousedown', startPaint);
        canvas.removeEventListener('touchmove', startPaint);

        canvas.addEventListener('mouseup', exitPaint);
        canvas.addEventListener('mouseleave', exitPaint);

        canvas.addEventListener('touchend', exitPaint);

    },
        []
    );

    /**
     *
     */
    useEffect(() => {
        if (!canvasRef.current) {
            return;
        }
        const canvas = canvasRef.current;
        canvas.addEventListener('mousedown', startPaint);
        canvas.addEventListener("touchmove", startPaint);
        return () => {
            canvas.removeEventListener('mousedown', startPaint);
            canvas.removeEventListener('touchmove', startPaint);
        };
    },
        [startPaint]
    );

    /**
     * 
     */
    const paint = useCallback(
        (event) => {
            if (p) {
                const newMousePosition = getCoordinates(event);
                if (mp && newMousePosition) {
                    drawLine(mp, newMousePosition);
                    setMousePosition(newMousePosition);
                    // eslint-disable-next-line react-hooks/exhaustive-deps
                    mp = newMousePosition;
                }
            }
            event.preventDefault();
        },
        [isPainting, mousePosition]
    );

    /**
     * 
     */
    const exitPaint = useCallback(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        p = false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
        mp = undefined;
        const canvas = canvasRef.current;
        canvas.addEventListener('mousedown', startPaint);
        canvas.addEventListener("touchmove", startPaint);
        setIsPainting(false);
        setMousePosition(undefined);
    }, []);

    /**
     * 
     * @param {Object} event 
     */
    const getCoordinates = (event) => {
        if (!canvasRef.current) {
            return;
        }

        const canvas = canvasRef.current;

        if (event.touches) {
            const pos = { x: event.touches[0].pageX - canvas.offsetLeft, y: event.touches[0].pageY - canvas.offsetTop };
            return pos;
        }
            
        return { x: event.pageX - canvas.offsetLeft, y: event.pageY - canvas.offsetTop };
    };

    /**
     * 
     */
    const clearSign = () => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        context.clearRect(0, 0, canvas.width, canvas.height);
    }

    /**
     * 
     */
    const saveSign = () => {
        const canvas = canvasRef.current;
        const signature = canvas.toDataURL();
        canvas.toBlob((blob) => {
            doneCallback({ "dataImage": signature, "blobSignature": blob });
        });
    }



    var canvas = document.getElementById('canvas');


    /**
     * 
     * @param {*} originalMousePosition 
     * @param {*} newMousePosition 
     */
    const drawLine = (originalMousePosition, newMousePosition) => {
        if (!canvasRef.current) {
            return;
        }
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        if (context) {
            context.strokeStyle = 'black';
            context.lineJoin = 'round';
            context.lineWidth = 2;
            context.beginPath();
            context.moveTo(originalMousePosition.x, originalMousePosition.y);
            context.lineTo(newMousePosition.x, newMousePosition.y);
            context.closePath();
            context.stroke();
        }

    };


    return (
        <Fragment>
            <canvas ref={canvasRef} height={height} width={width} />
            <Container style={{
                flex: 1,
                flexDirection: 'row',
                alignItems: 'center',
                alignContent: 'center',
                display: 'flex',
                justifyContent: 'center'
            }}>
                <Grid container direction="row" justify="center" spacing={1} >
                    <Grid item xs={12}  >
                        <Button
                            fullWidth={true}
                            onClick={saveSign.bind(this)}
                            style={{background:theme.palette.header?.main, color:theme.palette.textColorSecondary?.main}}
                            variant="contained">
                            {lang.get("saveSignature")}
                        </Button>
                    </Grid>
                    <Grid item xs={12} >
                        <Button
                            fullWidth={true}
                            onClick={clearSign.bind(this)}
                            style={{background:theme.palette.errorColor?.main, color:theme.palette.textColorSecondary?.main}}
                            variant="contained"
                            type="submit">
                            {lang.get("erase")}
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </Fragment>
    );
};
Canvas.defaultProps = {
    width: 500,
    height: 300
};
Canvas.propTypes = {
    doneCallback: PropTypes.func.isRequired
};

export default Canvas;
