import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { Client, Address, Estimate, Project } from "../Models/Models";
import Lang from "../lang";
import PostAddIcon from "@material-ui/icons/PostAdd";
import React, { useEffect, useState } from "react";
import { convertFromRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import {
  get,
  UrlEnum,
  fromStringTemplate,
  StatusEnum,
  isMobile,
} from "../Utils/Utils";
import PreviewPDF from "./PreviewPDF";
import Config from "../Utils/Config";
import InvoiceFooter from "../DocumentTemplates/InvoiceFooter";
import CachedDataSingleton from "../cachedDataSingleton";
import ReactDOMServer from "react-dom/server";
import moment from "moment";
import TextEditor from "../Components/TextEditor";
import FormSubmitButton from "../Forms/FormSubmitButton";

const lang = Lang.getInstance();
const cachedData = CachedDataSingleton.getInstance();

type CompanyContractProps = {
  company: any;
  client: Client | null;
  orderTicket: Estimate | null;
  setSmallMessage: Function;
  project: Project;
};

let submitFunction: any = null;

function CompanyContract(props: CompanyContractProps) {
  const [htmlContent, setHtmlContent] = useState("");
  const [customText, setCustomText] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const textEditorRef: any = React.createRef();
  let url = UrlEnum.pdf.replace("{type}", "companyContract");
  url = url.replace("{id}", "0");

  useEffect(() => {
    get(UrlEnum.documentTemplates + "/companyContract").then((response) => {
      if (response.errors) {
        alert(response.errors);
        setLoading(false);
        return;
      }
      if (response) {
        if (response.customText) {
          setCustomText(JSON.parse(response.customText));
        } else {
          setCustomText(null);
        }
      }
      setLoading(false);
    });
  }, []);

  async function submit(e: any) {
    e.preventDefault();
    setOpen(false);
    if (submitFunction) {
      let data = {
        customText: textEditorRef.current
          ? JSON.stringify(textEditorRef.current.getContent())
          : "",
      };
      setCustomText(
        textEditorRef.current ? textEditorRef.current.getContent() : null
      );
      await submitFunction(data);
    }
    generateCompanyContract();
    return false;
  }

  let footer = ReactDOMServer.renderToString(<div>{renderFooter()}</div>);
  footer = `<html>
			<head>
			<meta charset='UTF-8'/>
			<script>
				var test = function() {
					var vars = {};
					var x = document.location.search.substring(1).split('&');
					for (var i in x) {
						var z = x[i].split('=', 2);
						vars[z[0]] = unescape(z[1]);
					}
					if (vars['page'] == vars['topage']) {
						document.getElementById("extra").innerHTML = ;
					}else{
						var footer = document.getElementById("footer");
						footer.style.setProperty("position","relative");
						footer.style.setProperty("top","50px");
					}
					document.getElementById("pageNum").innerHTML = vars['page'] + "/" + vars['topage'];
				};
			</script>
			</head><body onload="test()" ><div id="extra" ></div>${footer}</body></html>`;

  const generateCompanyContract = async () => {
    const html = await renderCompanyContract(
      props.company,
      props.client,
      props.orderTicket,
      props.project
    );
    if (html !== undefined) {
      const htmlContentString = `<html>
				<head>
					<meta charset='UTF-8'/>
					<style>
            @import url('https://fonts.googleapis.com/css?family=Roboto:ital,wght@0,400;0,700;1,400;1,700&display=swap');
            html{
              font-family:Roboto,Arial, sans-serif;
            },
					</style>
				</head>
				<body>${html}</body></html>`;
      setHtmlContent(htmlContentString);
    }
  };

  /**
   *
   */
  function renderFooter() {
    const company = cachedData.get("company");
    return <InvoiceFooter company={company}></InvoiceFooter>;
  }
  async function renderCompanyContract(
    company: { name: string; id: number; address: any },
    client: Client | null,
    orderTicket: Estimate | null,
    project: Project
  ) {
    const response = await get(
      `${UrlEnum.documentTemplates}/companyContract/${company.id}`
    );
    if (response.errors) return "";
    if (Object.keys(response).length === 0) {
      props.setSmallMessage(lang.get("confirmContract"), StatusEnum.ERROR);
      return;
    }
    let contentJson = JSON.parse(response.customText);
    const contentState = convertFromRaw(contentJson);
    let customHtml = stateToHTML(contentState);

    customHtml = fromStringTemplate(customHtml, {
      companyName: company?.name ? company?.name : "",
      companyAddress: company?.address
        ? Address.stringify(company?.address)
        : "",
      clientName: client?.name ? client?.name : "",
      clientAddress: client?.address ? Address.stringify(client?.address) : "",
      clientVatNo: client?.vatNo ? client?.vatNo : "",
      siteAddress: project?.site_address
        ? Address.stringify(project?.site_address)
        : "",
      date: moment().format(Config.momentEUDateFormat),
      projectNo: project?.folderNo ? project?.folderNo : "",
      projectDate: project?.created_at
        ? moment(project.created_at).format(Config.momentEUDateFormat)
        : "",
      orderTicketStartDate: orderTicket?.startDate
        ? moment(orderTicket?.startDate).format(Config.momentEUDateFormat)
        : "",
      orderTicketEndDate: orderTicket?.endDate
        ? moment(orderTicket?.endDate).format(Config.momentEUDateFormat)
        : "",
      orderTicketNo: orderTicket?.estimateNo ? orderTicket?.estimateNo : "",
      orderTicketTotalNoVat: orderTicket ? orderTicket?.totalNoVat : "",
      orderTicketDate: orderTicket?.created_at
        ? moment(orderTicket?.created_at).format(Config.momentEUDateFormat)
        : "",
    });
    return customHtml;
  }

  return (
    <>
      <ListItem button onClick={() => setOpen(true)}>
        <ListItemIcon>
          <PostAddIcon />
        </ListItemIcon>
        <ListItemText primary={lang.get("contract")} />
      </ListItem>
      {loading ? (
        <CircularProgress color="secondary"></CircularProgress>
      ) : (
        <>
          <Dialog
            fullScreen={isMobile()}
            style={{ zIndex: 2000 }}
            maxWidth="xl"
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              <div style={{ flexGrow: 2 }}>{lang.get("preview")}</div>
            </DialogTitle>
            <DialogContent style={{}}>
              <TextEditor
                height={450}
                fontSize={15}
                docType="companyContract"
                initialState={customText}
                ref={textEditorRef}
              />
            </DialogContent>
            <DialogActions>
              <Grid xs={12} md={1} style={{ margin: 13 }}>
                <form
                  onSubmit={submit}
                  style={{ display: "block", width: "100%" }}
                >
                  <FormSubmitButton
                    key={"companyContract"}
                    fullWidth
                    getSubmit={(fct) => (submitFunction = fct)}
                    url={UrlEnum.documentTemplates + "/" + "companyContract"}
                  />
                </form>
              </Grid>
            </DialogActions>
          </Dialog>
          {htmlContent && (
            <PreviewPDF
              open={htmlContent.length > 0}
              onClose={() => setHtmlContent("")}
              htmlContent={htmlContent}
              footerHtml={footer}
              url={url}
              setSmallMessage={props.setSmallMessage}
              emailData={{ to: "", subject: "", message: "" }}
            />
          )}
        </>
      )}
    </>
  );
}

export default CompanyContract;
