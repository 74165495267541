import React, { Fragment } from "react";
import { Button, makeStyles, Tooltip } from "@material-ui/core";
import LockOutlined from "@material-ui/icons/LockOutlined";
import LockOpen from "@material-ui/icons/LockOpen";
import Lang from "../lang";
import { Statuses } from "../Utils/Utils";
import theme from "../Theme/Theme";
const lang = Lang.getInstance();

type DocStatusProps = {
  status: { id: number; name: string };
  onChangeStatus: (event: React.MouseEvent<HTMLButtonElement>) => void;
  className?: any;
  size?: "medium" | "large" | "small" | undefined;
  fullWidth?: boolean;
};

const useStyles = makeStyles((theme) => ({
  default: {},
  blocked: {
    background: theme.palette.errorColor?.main,
    color: theme.palette.textColor?.main,
  },
  unblocked: {
    background: theme.palette.successColor?.main,
    color: theme.palette.textColor?.main,
  },
}));

export default function DocStatus(props: DocStatusProps) {
  const classes = useStyles();
  const activeClass = props.status.id === Statuses.Active.id ? classes.unblocked : classes.blocked;
  const isBlocked = props.status.id === Statuses.Active.id ? false : true;
  return (
    <Fragment>
      {isBlocked? (
         <Tooltip title={lang.get("blocked")}>
        <Button
          fullWidth={props.fullWidth}
          className={props.className}
          style={{color:theme.palette.textColorSecondary?.main}}
          size="small"
          onClick={props.onChangeStatus}
          variant="contained"
          classes={{
            root: `${classes.default}  ${activeClass} ${props.className}`,
          }}
        >
          <LockOutlined style={{padding:"0px"}}/>
        </Button>
        </Tooltip>
      ) : (
        <Tooltip title={lang.get("unblocked")}>
        <Button
        fullWidth={props.fullWidth}
        className={props.className}
        style={{color:theme.palette.textColorSecondary?.main,}}
        size="small"
        onClick={props.onChangeStatus}
        variant="contained"
        classes={{
          root: `${classes.default}  ${activeClass} ${props.className}`,
        }}
      >
        <LockOpen style={{padding:"0px"}}/>
      </Button>
      </Tooltip>
      )}
    </Fragment>
  );
}
