import {
  Box,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import React from "react";

type Props = {
  fullModel: any;
  model: any;
  openColapsedRow: number;
  expandedColumns: Array<any>;
  parse?: any;
  rowClassCallback?: any;
};

export function ExpandedTable(props: Props) {
  return (
        <Collapse
          in={props.openColapsedRow === props.fullModel.id ? true : false}
          timeout="auto"
          unmountOnExit
        >
          <Box margin={1}>
            <Table size="small" aria-label="purchases">
              <TableHead>
                <TableRow>
                  {props.expandedColumns.map((col: any,index) => (
                    <TableCell key={col.name+index}>{col.label}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {props.model
                  ? props.model.map((modelInvoice: any) => {
                      let rowClass = props.rowClassCallback
                        ? props.rowClassCallback(modelInvoice)
                        : "";
                      if(modelInvoice.type === 'invoice')
                        return (
                          <TableRow
                            key={"row" + modelInvoice.id}
                            className={rowClass}
                          >
                            {props.expandedColumns.map(
                              (col: any, index: number) => (
                                <TableCell
                                  key={"cel" + modelInvoice.id + col.name + index}
                                >
                                  {col.customRender
                                    ? col.customRender(modelInvoice, props.fullModel)
                                    : col.children
                                    ? col.children(modelInvoice)
                                    : props.parse &&
                                      props.parse.hasOwnProperty(col.name)
                                    ? props.parse[col.name](
                                        modelInvoice[col.name]
                                      )
                                    : modelInvoice[col.name]}
                                </TableCell>
                              )
                            )}
                          </TableRow>
                        );
                      else return (
                        <TableRow
                          key={"row" + modelInvoice.id}
                          className={rowClass}
                        >
                          {props.expandedColumns.map(
                            (col: any, index: number) => (
                              <TableCell
                                key={"cel" + modelInvoice.id + col.name + index}
                              >
                                {col.customRender
                                  ? col.customRender(modelInvoice, props.fullModel)
                                  : col.children
                                  ? col.children(modelInvoice)
                                  : props.parse &&
                                    props.parse.hasOwnProperty(col.name)
                                  ? props.parse[col.name](
                                      modelInvoice[col.name]
                                    )
                                  : col.name === 'totalWithVat' ?-modelInvoice[col.name]:modelInvoice[col.name]}
                              </TableCell>
                            )
                          )}
                        </TableRow>
                      );
                    })
                  : null}
              </TableBody>
            </Table>
          </Box>
        </Collapse>
  );
}
