import React, { Component, Fragment } from 'react';
import {Estimate} from '../Models/Models';
import { httpDelete, isMobile, StatusEnum, Statuses, UrlEnum } from '../Utils/Utils';
import {get} from '../Utils/Utils';
import Lang from '../lang';
import ConfirmDialog from '../Components/ConfirmDialog';
import EstimateList from './EstimateList';
import EstimatesContext from '../Contexts/EstimatesContext';
import NoDataFound from '../Components/NoDataFound';

type EstimateListCompleteProps= {
    estimates: Estimate[] | null,
    showSmallMessage: Function
    type:string,
    classes?: {header?:{}, extensionPanel?:{}},
    select?: boolean
    isProject?: boolean
    isDashboard?:boolean
};

type EstimateListCompleteState= {
    estimates:Estimate[] | null;
    openDeleteConfirm:boolean;
};
class EstimateListComplete extends Component<EstimateListCompleteProps, EstimateListCompleteState> {
    deleteModel: Estimate | null;
    lang = Lang.getInstance();

    constructor(props:EstimateListCompleteProps){
        super(props);
        this.state = {
            estimates: this.props.estimates,
            openDeleteConfirm:false
        };

        this.deleteModel = null;
    }

    componentDidUpdate(prev: any){
        if(this.props.estimates && this.props.estimates?.length !== 0)
        if(prev.estimates[0].type!== this.props.estimates[0].type)
            this.setState({estimates: this.props.estimates})
    }

    async changeEstimateState(estimate:Estimate) {
        if(!this.state.estimates) return;

        let newEstimates = this.state.estimates?.slice();
        if(estimate.status !== null){
            let nextState = estimate.status;
            if (estimate.status.id === Statuses.Active.id) {
                nextState = Statuses.Inactive;
            } else {
                nextState = Statuses.Active;
            }
    
            const url = `${UrlEnum.estimates}/changeState/${estimate.id}/${nextState.id}`;
            const response = await get(url);
            if (response.errors) {
                alert(response.errors);
                return;
            }

            let crtEstimate = newEstimates.find((i) => i.id === estimate.id);
            if(crtEstimate){
                crtEstimate.status = nextState;
                this.setState({ estimates: newEstimates });
            }
        }
    }

    async onConfirmDeleteResult(confirmResponse:boolean){
        if(!this.state.estimates) return;

        if(confirmResponse && this.deleteModel !== null){
            const url = `${UrlEnum.estimates}/${this.deleteModel.id}`;
            const response = await httpDelete(url);
            if(response.errors){
                let message = response.errors instanceof Array && response.errors.length > 0 ? 
                response.errors.join("<br/>") : 
                response.errors;
                this.props.showSmallMessage(message, StatusEnum.ERROR);
                return;
            }
            let ne = this.state.estimates;
            const index = ne.indexOf(this.deleteModel);
            ne.splice(index, 1);
            this.setState({"openDeleteConfirm":false, "estimates": ne});
            return;
        }
        this.setState({"openDeleteConfirm":false});
    }

    async duplicateEstimate(estimate: Estimate){
        if(!this.state.estimates) return;

        const url = `${UrlEnum.estimates}/duplicate/${estimate.id}/0`;
        const response = await get(url);
        if(response.errors){
            let message = response.errors instanceof Array && response.errors.length > 0 ? 
            response.errors.join("<br/>") : 
            response.errors;
            this.props.showSmallMessage(message, StatusEnum.ERROR);
            return;
        }
        let ne = this.state.estimates;
        response.payments = [];
        ne.unshift(response);
        this.setState({"estimates": ne});
    }
    selectAllEstimates(event: any){
        const crtEstimates= this.state.estimates?.slice() || [];
        if(crtEstimates){
            crtEstimates.forEach((estimate)=> {
                estimate.selected = event.target.checked;
          })
        }
        this.setState({estimates: crtEstimates});
    }
   

    render(){
        return(
            <Fragment>
                    {this.state.estimates && this.state.estimates.length > 0 ?
                        <EstimatesContext.Provider value={{
                            estimates: this.state.estimates,
                            type:this.props.type,
                            changeEstimateState: (estimate: Estimate) => {
                                this.changeEstimateState(estimate);
                            },
                            onDelete:(estimate: Estimate)=>{
                                this.setState({"openDeleteConfirm":true});
                                this.deleteModel = estimate;
                            },
                            onDuplicate:(estimate: Estimate)=>{
                                this.duplicateEstimate(estimate);
                            },
                            onSelect: (invoice: Estimate, selected: boolean) => {
                                const newEstimates = this.state.estimates?.slice();
                                if (newEstimates) {
                                    let crtInvoice = newEstimates.find((i) => i.id === invoice.id);
                                    if (crtInvoice) {
                                        crtInvoice.selected = selected;
                                        this.setState({ estimates: newEstimates });
                                    }
                                }
                            }
                        }}>
                            <EstimateList
                                estimates={this.state.estimates}
                                isMobile={isMobile()}
                                classesOverride = {this.props.classes}
                                select={this.props.select}
                                isProject={this.props.isProject}
                                isDashboard={this.props.isDashboard}
                                selectAllEstimates={(e: any) =>this.selectAllEstimates(e)}


                            />
                        </EstimatesContext.Provider>
                        :
                        <NoDataFound/>
                    }
                    <ConfirmDialog open={this.state.openDeleteConfirm} text={this.lang.get("deleteItem")} onResult={this.onConfirmDeleteResult.bind(this)} />
                </Fragment>
        );
    }
}

export default EstimateListComplete;