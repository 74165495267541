import {
  Grid,
  Paper,
  makeStyles,
} from "@material-ui/core";
import Lang from "../../lang";
import NotificationsIcon from "@material-ui/icons/Notifications";
import NotificationsList from "../NotificationsList";

const lang = Lang.getInstance();

const useStyles = makeStyles((theme) =>
  Object.assign({
    payHeader: {
      padding: "5px",
      // display:"inline-block",
      background: theme.palette.primary?.main,
      color: theme.palette.textColorSecondary?.main,
    },
    textHeader: {
      fontSize: "0.75rem",
      fontWeight: "normal",
      display: "inline-block",
      position: "absolute",
      marginTop: "8px",
    },
  })
);

export default function NotificationsDashboard() {
  const classes = useStyles();

  function renderHeader() {
    return (
      <div item className={classes.payHeader}>
        <NotificationsIcon fontSize="medium" style={{ margin: "7px 0px 7px 7px" }} />
        <h2 className={classes.textHeader}>
          {lang.get("notifications").toUpperCase()}
        </h2>
      </div>
    );
  }
  function renderContent() {
    return (
      <Grid style={{ minHeight:"225px",maxHeight: "257px", overflowY: "scroll" }}>
        <NotificationsList/>
      </Grid>
    );
  }
  return (
    <Grid>
      <Paper>
        {renderHeader()}
        {renderContent()}
      </Paper>
    </Grid>
  );
}
