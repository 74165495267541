import { readCookie } from "./Utils";

export function checkUserRoles(roles: Array<string>) {
  const cookie = unescape(readCookie("userRoles"));
  if (!cookie) return false;
  const userRoles = JSON.parse(cookie);
  let roleFound = false;

  for(const currentRole of roles){
    for(const userRole of userRoles){
      if (userRole.role === currentRole){
        roleFound = true;
        break;
      } 
    }
    if(roleFound) break;
  }
  return roleFound;
}