import CachedDataSingleton from "../../../cachedDataSingleton";
import ReactDOMServer from "react-dom/server";
import LiabilityHeader from "../../../DocumentTemplates/Liabilitys/LiabilityHeader";
import { Client } from "../../../Models/Models";
import {
  get,
  getLiabilityElapsedTime,
  absoluteImagePaths,
  UrlEnum,
  fromStringTemplate,
} from "../../../Utils/Utils";
import LiabilityItems from "../../../DocumentTemplates/Liabilitys/LiabilityItems";
import { stateToHTML } from "draft-js-export-html";
import { convertFromRaw } from "draft-js";

const cachedData = CachedDataSingleton.getInstance();

export async function reminderContent(model: any) {
  /**
   *
   * @param customTextTop
   * @param company
   * @param client
   * @returns
   */
  function getCustomHTML(
    customTextTop: any,
    company: any,
    client: any,
    totalWithVat?: any,
    restToPay?: any
  ) {
    return fromStringTemplate(customTextTop, {
      clientName: client.name ? client.name : "",
      clientAddress: client.address
        ? `${client.address.address}, ${client.address.city}`
        : "",
      clientVatNo: client.vatNo ? client.vatNo : "",
      companyName: company.name ? company.name : "",
      companyAddress: company.address
        ? `${company.address.address}, ${company.address.city}`
        : "",
      totalWithVat: totalWithVat ? totalWithVat : "",
      restToPay: restToPay ? restToPay : "",
    });
  }

  let response = await get(UrlEnum.documentTemplates + "/reminder");
  if (response.errors) return "";
  else {
    //----------------------------------------------start Header-----------------
    const company = cachedData.get("company");
    let client = new Client();
    client.name = model.name;
    client.vatNo = model.vatNo;
    client.address.address = model.address;
    client.address.city = model.city;
    client.address.code = model.code;
    let header = ReactDOMServer.renderToString(
      <div>
        <LiabilityHeader
          company={company}
          client={client}
          color={response.color ? response.color : "RGB(170,170,170)"}
        />
      </div>
    );
    let headerNode = document.createElement("div");
    headerNode.innerHTML = header;
    headerNode = absoluteImagePaths(headerNode);
    header = headerNode.innerHTML;

    //----------------------------------------------start Table text-----------------
    const invoices = model.invoices;
    let customTextTop = JSON.parse(response.customTextTop);
    let contentState = convertFromRaw(customTextTop);
    customTextTop = stateToHTML(contentState);
    customTextTop = getCustomHTML(customTextTop, company, client);

    //----------------------------------------------start Table -----------------
    let totalWithVat = 0;
    let restToPay = 0;
    //calculeaza elapsed time
    invoices.forEach((invoice: any) => {
      invoice.elapsedTime = getLiabilityElapsedTime(invoice.invoiceDate);
      totalWithVat += invoice.totalWithVat;
      restToPay += invoice.totalWithVat - invoice.totalPaid;
      //invoice.reminderDate = moment(new Date(invoice.reminderDate)).format("DD-MM-yyyy");
    });

    let content = ReactDOMServer.renderToString(
      <div>
        <LiabilityItems
          items={invoices}
          color={response.color ? response.color : "RGB(170,170,170)"}
        />
      </div>
    );

    //-----------------------------------------------start Custom text and macro replacements------------
    let customText = JSON.parse(response.customText);
    contentState = convertFromRaw(customText);
    customText = stateToHTML(contentState);
    customText = getCustomHTML(
      customText,
      company,
      client,
      totalWithVat,
      restToPay
    );
    const html = `<html><head><meta charset='UTF-8'/></head><body>${header}${customTextTop}${content}${customText}</body></html>`;
    return html;
  }
}
