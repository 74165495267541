import * as React from "react";

function SvgMicrosoftExcel(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
      <path
        d="M41 10H25v28h16c.555 0 1-.445 1-1V11c0-.555-.445-1-1-1z"
        fill="#4CAF50"
      />
      <path
        d="M32 15h7v3h-7zM32 25h7v3h-7zM32 30h7v3h-7zM32 20h7v3h-7zM25 15h5v3h-5zM25 25h5v3h-5zM25 30h5v3h-5zM25 20h5v3h-5z"
        fill="#FFF"
      />
      <path d="M27 42L6 38V10l21-4z" fill="#2E7D32" />
      <path
        d="M19.129 31l-2.41-4.563c-.094-.167-.188-.48-.285-.937h-.036c-.046.215-.156.543-.324.98L13.652 31H9.895l4.46-7-4.082-7h3.836l2.004 4.195c.157.332.293.727.418 1.18h.04c.077-.27.226-.68.44-1.219L19.239 17h3.516l-4.2 6.938 4.313 7.058H19.13z"
        fill="#FFF"
      />
    </svg>
  );
}

export default SvgMicrosoftExcel;
