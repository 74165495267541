import React from 'react';
import CachedDataSingleton from '../cachedDataSingleton';
import {makeStyles, Popover } from '@material-ui/core';
// import Lang from '../../src/lang';
import NotificationsList from './NotificationsList';

// let lang = Lang.getInstance();


type NotificationsType = {
    classes: any,
    notificationsAnchor: any,
    handleClose: (e: {}) => void,
    render: (notifications: any) => void,
    resetNotifications: boolean
};

const cachedData = CachedDataSingleton.getInstance();

function Notifications(props: NotificationsType) {
    const notifications = cachedData.get("notifications");
    const classes = useStyles();
    
    if(props.resetNotifications){
        for(let notification of notifications){
            notification.active = 0;
            cachedData.setKey("notifications",notifications);
        }
    }

    return (
        <>
            {
                props.render ?
                    props.render(notifications) :
                    null
            }
            <Popover
                style={{ marginTop: 9 }}
                className={classes.normalMenu}
                anchorEl={props.notificationsAnchor}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                open={Boolean(props.notificationsAnchor)}
                onClose={props.handleClose}>
                <div className={classes.pointArrow} style={{ right: 70 }}></div>
                <NotificationsList/>
            </Popover>
        </>
    );
}

const useStyles = makeStyles(theme => ({
    normalMenu: {
        marginTop: 25,
        '& > div:nth-child(3)': {
            overflow: "visible",
        }
    },
    pointArrow: {
        content: '""',
        display: 'block',
        width: '0',
        height: '0',
        position: 'absolute',
        borderLeft: '8px solid transparent',
        borderRight: '8px solid transparent',
        borderBottom: '8px solid #fff',
        right: 20,
        top: -8
    },
}));

export default Notifications;