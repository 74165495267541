import { Button, CssBaseline, makeStyles, Paper } from "@material-ui/core";
import React from "react";
import Lang from "../lang";
import logo from "../Images/logo/cronotimal_orizontal.svg";
import { logoutUtils } from "../Utils/Utils";
import { useLocation } from "react-router-dom";

let lang = Lang.getInstance();
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  container: {
    display: "flex",
    overflow: "hidden",
    flexGrow: 1,
    backgroundSize: "cover",
    justifyContent: "center",
    backgroundPosition: "0px 400px",
  },
  paper: {
    width: "50%",
    marginTop: "5%",
    padding: "26px 50px 26px 50px",
    textAlign: "center",
  },
  text: {
    fontSize: "27px",
    fontWeight: "normal",
    textShadow: "1px 1px #ccc",
  },
  resend: {
    fontSize: "22px",
    fontWeight: "normal",
    textShadow: "1px 1px #ccc",
    cursor: "pointer",
  },
  image: {
    height: "20px",
    marginRight: "10px",
  },
  mail: {
    textAlign: "center",
    marginTop: "20px",
    marginRight: "0px",
  },
  button: {
    background: "white",
    fontSize: "15px",
    margin: "10px 20px",
    padding: "10px 20px",
    backgroundImage: "linear-gradient(#efeeee  40%, #b7b5b5)",
    filter: "drop-shadow(5px 3px 3px #918f8f)",
  },
}));

export default function ConfirmEmail() {
  const classes = useStyles();
  const location = useLocation();
  function redirect() {
    logoutUtils();
  }
  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.root}>
        <div className={classes.container}>
          <div className={classes.paper}>
            <img
              src={logo}
              alt="logo"
              style={{ width: 350, margin: "10px 0px" }}
            />

            <Paper style={{width:"50%", margin:"auto", padding:20, marginTop:25, backgroundColor:"#0000002b"}}>
              <h1 style={{ fontWeight: "normal", marginBottom: 30, color:"white"}}>
                {location.state ? location.state.title : ""}
              </h1>
              <div
                style={{
                  width: "45%",
                  margin: "auto",
                }}
              >
                <Button
                  onClick={redirect}
                  size="large"
                  fullWidth
                  style={{
                    backgroundColor: "#ff5e00",
                    color: "white",
                    fontSize: 15,
                  }}
                >
                  {lang.get("login")}
                </Button>
              </div>
            </Paper>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
