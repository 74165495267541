import { Grid, Paper,makeStyles } from "@material-ui/core";
import Payments from "./Payments";
import StripePayments from "./StripePayments";
import CachedDataSingleton from "../../cachedDataSingleton";
import Lang from "../../lang";
import CreditCardIcon  from '@material-ui/icons/CreditCard';
import {useState} from "react";

const lang = Lang.getInstance();
const cachedData = CachedDataSingleton.getInstance();

  const useStyles = makeStyles((theme) =>
    Object.assign({
      payHeader: {
        padding: "5px",
        // display:"inline-block",
        background: theme.palette.primary?.main,
        color: theme.palette.textColorSecondary?.main,
      },
      stripePaymentsDisable:{
        // display:"inline-block", 
        margin:"0px 10px 0px 5px",
        padding:"10px",
        width:"100%"
      },
      stripePaymentsEnable:{
        display:"inline-block", 
        margin:"10px",
        padding:"13px",
        width:"100%",

      },
    }));
export default function OnlinePayments(props){

    const company = cachedData.get('company');
    const onlinePayments = company.onlinePayments;
    const [isPayment, setIsPayment]=useState(true);
    const classes = useStyles();
    
    function renderHeader() {
        return (
          <div item className={classes.payHeader}>
              <CreditCardIcon fontSize="large" style={{marginTop:"5px"}}/>
            <h2 style={{fontSize: "0.75rem", fontWeight:"normal",display:"inline-block",position:"absolute"}}> {lang.get("onlinePayments").toUpperCase()}</h2>
          </div>
        );
      }
    return(
        <Grid>
            <Paper>
            {renderHeader()}
            <div style={onlinePayments && isPayment ? {height:225, overflowY:"scroll", overflowX:"hidden"}: {}}>
            <div className={onlinePayments && isPayment?classes.stripePaymentsDisable:classes.stripePaymentsEnable}>
            <StripePayments showSmallMessage={props.showSmallMessage} isPayment={isPayment} setLoading={props.setLoading}/>
            </div>

            <div className={onlinePayments && isPayment?classes.stripePaymentsDisable:""} style={{paddingTop:0}}>
            {onlinePayments && isPayment?<Payments showSmallMessage={props.showSmallMessage} setIsPayment={setIsPayment}/>:""}
            </div>
            </div>
            </Paper>
        </Grid>
    );
}