import {
  CssBaseline,
  Grid,
  Tooltip,
  makeStyles,
  Button,
} from "@material-ui/core";
import React from "react";
import Lang from "../lang";
import logo from "../Images/logo/cronotimal.svg";
import gmail from "../Images/confirmEmail/gmail.png";
import yahoo from "../Images/confirmEmail/yahoo.png";
import { UrlEnum,getCookieUserData, get } from "../Utils/Utils";

let lang = Lang.getInstance();
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  container: {
    display: "flex",
    overflow: "hidden",
    flexGrow: 1,
    backgroundSize: "cover",
    justifyContent: "center",
    backgroundPosition: "0px 400px",
  },
  paper: {
    width: "45%",
    display: "flex",
    marginTop: "5%",
    padding: "26px 50px 26px 50px",
    flexDirection: "column",
  },
  text: {
    fontSize: "27px",
    fontWeight: "normal",
    textShadow: "1px 1px #ccc",
  },
  resend: {
    fontSize: "22px",
    fontWeight: "normal",
    textShadow: "1px 1px #ccc",
    cursor:"pointer",
  },
  image: {
    height: "20px",
    marginRight: "10px",
  },
  mail: {
    textAlign: "center",
    marginTop: "20px",
    marginRight: "0px",
  },
  button: {
    background: "white",
    fontSize: "15px",
    margin: "10px 20px",
    padding: "10px 20px",
    backgroundImage: "linear-gradient(#efeeee  40%, #b7b5b5)",
    filter: "drop-shadow(5px 3px 3px #918f8f)",
  },
}));

export default function ConfirmEmail() {
  const classes = useStyles();
  const cookie = getCookieUserData();

  function handleSendEmail() {
    if(cookie && cookie.email)
    {get(UrlEnum.sendConfirmationEmail + cookie.email).then((response) => {
      if (response.errors) console.log("errors");
      console.log(response);
    }).catch(error =>{
      console.log(error);
    });
  }else
  {
    window.location.href="/login";
  }
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.root}>
        <div className={classes.container}>
          <div className={classes.paper}>
            <div>
              <div style={{ textAlign: "center" }}>
                <img style={{ width: "100px" }} src={logo} alt="logo" />
                <h1 className={classes.text} style={{ marginTop: "20px" }}>
                  {lang.get("pleaseConfirmEmail")}
                </h1>
                <h1 className={classes.text}>{lang.get("confirmEmail")}.</h1>
                  <h2 onClick={handleSendEmail} className={classes.resend}>{lang.get("resendEmail")}</h2>
              </div>
            </div>
            <Grid className={classes.mail}>
              <Tooltip title="Gmail">
                <a href="https://mail.google.com">
                  <Button className={classes.button}>
                    <img src={gmail} alt="Gmail" className={classes.image} />
                    Gmail
                  </Button>
                </a>
              </Tooltip>
              <Tooltip title="Yahoo">
                <a href="https://login.yahoo.com">
                  <Button className={classes.button}>
                    <img src={yahoo} alt="Yahoo" className={classes.image} />
                    Yahoo
                  </Button>
                </a>
              </Tooltip>
            </Grid>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
