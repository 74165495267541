import React from "react";
import { SvgIcon } from "@material-ui/core";

export default function IconProjectsOutline(props)
{
    return(
    <SvgIcon viewBox="0 0 122 120" style={{ width: props.width ? props.width:37, height: props.height?props.height:37, marginTop:"3px"}}>
	    <path fill={props.color} fillRule="evenodd" d="M58.5 0.5L59.5 8.5L86.5 8.5C101.35 8.5 114.06 8.84 114.75 9.25C115.51 9.7 116 12.17 116 15.5C116 20.94 116.03 21 119 21C121.86 21 122 21.2 122.01 25.25C122.01 27.59 120.19 43 117.97 59.5C114.9 82.31 113.5 90.1 110.28 94.5L10.72 94.5L8.85 92C7.37 90.01 6.35 83.83 3.83 61.5C2.08 46.1 0.62 30.8 0.5 21.5L3.75 21.25C6.98 21 7 20.96 7.25 15.25C7.49 9.71 7.62 9.46 10.76 8.5C13.59 7.63 14 7.09 13.84 4.42C13.75 2.72 14.05 1.02 14.52 0.65C14.99 0.27 25.07 0.08 58.5 0.5ZM18.81 8.75C18.53 12.19 18.23 12.52 15.25 12.75C12.13 12.99 12 13.17 12 17.25L11.99 21.5C41.06 20.34 63.34 20 80.25 20L111 20L111 13L54.5 12.5L54 5L19.11 5L18.81 8.75ZM5.67 29.75C5.91 31.81 7.36 45.65 8.89 60.5C10.41 75.35 11.96 88.06 12.33 88.75C12.85 89.72 23.53 90 60.5 90C91.17 90 108.18 89.65 108.5 89C108.77 88.45 110.8 74.95 113 59C115.2 43.05 117 29.1 117 28C117 26.02 116.33 26 61.11 26L5.22 26L5.67 29.75Z" />
    </SvgIcon>
    );
}
