import React, { Fragment, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  makeStyles,
  AccordionDetails,
  Button,
  IconButton,
  Tooltip,
  Checkbox,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import Lang from "../lang";
import { DocTypes, LocalUrlEnum } from "../Utils/Utils";
import { Link } from "react-router-dom";
import Config from "../Utils/Config";
import moment from "moment";
import DocStatus from "../Components/DocStatus";
import InvoicePayments from "./InvoicePayments";
import InvoicesContext from "../Contexts/InvoicesContext";
import theme from "../Theme/Theme";
const useStyles = makeStyles((theme) => ({
  secondaryHeading: {
    fontSize: 10,
  },
  row: {
    display: "flex",
    width: "100%",
  },
  col: {
    padding: "0 10px",
    display: "inline-block",
  },
  clientName: {
    flexGrow: 0.95,
    maxWidth: "1730px",
    minWidth: "200px",
    [theme.breakpoints.down("xs")]: {
      width: "auto",
    },
    [theme.breakpoints.down("sm")]: {
      width: "auto",
    },
  },
  clientNameProject: {
    flexGrow: 1,
    // width: 'calc(100% - 390px)',
  },
  dashboardClientName: {
    flexGrow: 0.9,
    width: 35,
  },
  dashboardDate: {
    marginLeft: "100px",
    paddingRight: 50,
  },
  priceProject: {
    minWidth: "80px",
    textAlign: "center",
  },
  price: {
    minWidth: 100,
    textAlign: "center",
    paddingTop: 2.5,
  },
  dashboardPrice: {
    minWidth: 100,
    textAlign: "left",
    paddingTop: 2.5,
  },
  details: {
    display: "block",
  },
  paymentBtn: {
    alignContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      flexDirection: "column",
      alignContent: "center",
    },
  },
  paymentBtnBlocked: {
    marginLeft: "10px",
    alignContent: "center",
    minWidth: "45px",
    padding: "5px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      flexDirection: "column",
      alignContent: "center",
    },
  },
  paymentsBtn: {
    // marginLeft: "30px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "0px",
      flexDirection: "column",
      alignContent: "center",
    },
  },
  success: {
    background: theme.palette.successColor.main,
    color: "#ffffff",
    minWidth: "60px",
    textAlign: "center",
    margin: "0 auto",
    padding: "4px",
    height: "30.56px",
  },
  buttons: {
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "33%",
  },
  grid2: {
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      alignContent: "center",
    },
  },
  error: {
    background: theme.palette.errorColor.main,
    color: "#ffffff",
    minWidth: "60px",
    textAlign: "center",
    margin: "0 auto",
    padding: " 4px",
    height: "30.56px",
  },
  link: {
    color: theme.palette.textColor.main,
  },
  checkbox: {
    // marginLeft: -35,
    marginTop: -10,
    padding: 3,
    color: theme.palette.header?.main
  },
  checkboxProject: {
    marginLeft: -35,
    marginTop: -10,
    padding: 3,
    color: theme.palette.header?.main
  },
  status: {
    minWidth: 50,
    textAlign: "left",
    // paddingLeft: 40,
  },
  statusProject: {
    minWidth: 50,
    textAlign: "left",
    paddingLeft: 10,
  },
  projectTable: {
    display: "inline-flex",
  },
}));

const lang = Lang.getInstance();

export default function InvoiceItem(props) {
  let classes = useStyles();
  classes = Object.assign(classes, props.classesOverride || {});

  const isMobile = props.isMobile;
  const isProject = props.isProject;
  const isDashboard = props.isDashboard;
  const [expanded, setExpanded] = useState(false);
  const [paymentsOpen, setPaymentsOpen] = useState(false);

  /**
   * render invoice columns for mobile and desktop
   */
  function _renderCols() {
    let checkBoxStyle = isProject ? classes.checkboxProject : classes.checkbox;
    // getIds();
    if (isMobile) {
      return (
        <div className={classes.row}>
          {props.select && (
            <Checkbox
              classes={{ root: checkBoxStyle }}
              checked={props.invoice.selected ? props.invoice.selected : false}
              onChange={(e) => {
                props.onSelect(props.invoice, e.target.checked);
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
          )}
          <div className={classes.col}>
            <Link to={LocalUrlEnum.invoices + "/" + props.invoice.id}>
              {props.invoice.invoiceNo}
            </Link>
          </div>
          <div className={`${classes.col} ${classes.clientName}`}>
            <Link to={LocalUrlEnum.invoices + "/" + props.invoice.id}>
              {props.invoice.client ? props.invoice.client.name : ""}
            </Link>
          </div>
        </div>
      );
    } else {
      const paidBkgClass =
        parseFloat(props.invoice.totalPaid?.toFixed(2)) >=
        parseFloat(props.invoice.totalWithVat?.toFixed(2))
          ? classes.success
          : classes.error;
      return (
        <div className={classes.row}>
          <InvoicesContext.Consumer>
            {(context) => {
              let link = LocalUrlEnum.invoices;
              switch (context.type) {
                case "proforma":
                  link = LocalUrlEnum.proformas;
                  break;
                case "creditNote":
                  link = LocalUrlEnum.creditNotes;
                  break;
                default:
                  break;
              }
              return (
                <>
                  {props.select && (
                    <Checkbox
                      classes={{ root: checkBoxStyle }}
                      onChange={(e) => {
                        props.onSelect(props.invoice, e.target.checked);
                      }}
                      checked={
                        props.invoice.selected ? props.invoice.selected : false
                      }
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    />
                  )}
                  <div className={`${classes.col} ${classes.link}`}>
                    <Link to={link + "/" + props.invoice.id}>
                      {props.invoice.invoiceNo}
                    </Link>
                  </div>
                  {isProject ? (
                    <div
                      className={`${classes.col} ${classes.clientNameProject}`}
                    >
                      <Link to={LocalUrlEnum.invoices + "/" + props.invoice.id}>
                        {props.invoice.client ? props.invoice.client.name : ""}
                      </Link>
                    </div>
                  ) : (
                    <div
                      className={
                        isDashboard
                          ? `${classes.col} ${classes.dashboardClientName}`
                          : `${classes.col} ${classes.clientName}`
                      }
                    >
                      <Link to={LocalUrlEnum.invoices + "/" + props.invoice.id}>
                        {props.invoice.client ? props.invoice.client.name : ""}
                      </Link>
                    </div>
                  )}
                </>
              );
            }}
          </InvoicesContext.Consumer>
          {isProject ? (
            <>
              <div className={`${classes.col} ${classes.date}`}>
                {moment(props.invoice.invoiceDate).format(
                  Config.momentEUDateFormat
                )}
              </div>
              <div className={`${classes.col} ${classes.priceProject}`}>
                {parseFloat(props.invoice.totalWithVat?.toString()).toFixed(
                  Config.noOfDecimals
                )}
              </div>
              <div className={`${classes.col} ${paidBkgClass}`} dates>
                {props.invoice.totalPaid}
              </div>
              <div className={`${classes.col} ${classes.statusProject}`}>
                <DocStatus
                  className={classes.paymentBtnBlocked}
                  status={props.invoice.status}
                  onChangeStatus={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    props.changeInvoiceState(props.invoice);
                    return false;
                  }}
                />
              </div>
            </>
          ) : (
            <>
              <div
                className={
                  isDashboard
                    ? `${classes.col} ${classes.dashboardDate}`
                    : classes.col
                }
              >
                {moment(props.invoice.invoiceDate).format(
                  Config.momentEUDateFormat
                )}
              </div>
              <div
                className={
                  isDashboard
                    ? `${classes.col} ${classes.dashboardPrice}`
                    : `${classes.col} ${classes.price}`
                }
              >
                {parseFloat(props.invoice.totalWithVat?.toString()).toFixed(
                  Config.noOfDecimals
                )}
              </div>
              <div
                className={`${classes.col} ${classes.price} ${paidBkgClass}`}
              >
                {props.invoice.totalPaid}
              </div>
              <div className={`${classes.col} ${classes.status}`}>
                <DocStatus
                  className={classes.paymentBtn}
                  status={props.invoice.status}
                  onChangeStatus={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    props.changeInvoiceState(props.invoice);
                    return false;
                  }}
                />
              </div>
            </>
          )}
        </div>
      );
    }
  }

  function _onExpand() {
    setExpanded(!expanded);
  }

  function handlePaymentsClose(payments) {
    let totalPaid = 0;
    payments.map((p) => (totalPaid += p.amount));
    props.invoice.totalPaid = totalPaid;
    setPaymentsOpen(!paymentsOpen);
  }

  return (
    <Fragment>
      <Accordion
        className={classes.extensionPanel}
        expanded={expanded}
        onChange={_onExpand}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          classes={{ root: isProject ? `${classes.projectTable}` : "" }}
        >
          {_renderCols()}
        </AccordionSummary>
        <AccordionDetails classes={{ root: classes.details }}>
          {isMobile ? (
            <Fragment>
              <div>
                <label>
                  <strong>{lang.get("date")}: </strong>
                </label>
                {moment(props.invoice.invoiceDate).format(
                  Config.momentEUDateFormat
                )}
              </div>

              <div>
                <label>
                  <strong>{lang.get("totalPayment")}: </strong>
                </label>
                {parseFloat(props.invoice.totalWithVat.toString()).toFixed(
                  Config.noOfDecimals
                )}
              </div>
            </Fragment>
          ) : (
            ""
          )}

          <Grid container spacing={1} className={classes.grid}>
            <Grid item xs={props.canDelete ? 4 : 6} sm={4} md={1}>
              <Tooltip title={lang.get("edit")} placement="bottom">
                <IconButton className={classes.buttons} aria-label="delete">
                  <Link to={LocalUrlEnum.invoices + "/" + props.invoice.id}>
                    <CreateIcon />
                  </Link>
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs={props.canDelete ? 4 : 6} sm={4} md={1}>
              <Tooltip title={lang.get("duplicate")} placement="bottom">
                <IconButton
                  className={classes.buttons}
                  onClick={() => props.onDuplicate(props.invoice)}
                  aria-label="duplicate"
                >
                  <FileCopyIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs={props.canDelete ? 4 : 6} sm={4} md={1}>
              {props.canDelete ? (
                <>
                  {isProject && props.invoice.type === DocTypes.invoice ? (
                    ""
                  ) : (
                    <Tooltip title={lang.get("delete")} placement="bottom">
                      <IconButton
                        className={classes.buttons}
                        aria-label="delete"
                        onClick={() => props.onDelete(props.invoice)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </>
              ) : (
                ""
              )}
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={2} className={classes.paymentsBtn}>
              <Button
                className={classes.paymentBtn}
                style={{
                  background: theme.palette.header?.main,
                  color: theme.palette.textColorSecondary?.main,
                }}
                variant="contained"
                onClick={() => setPaymentsOpen(!paymentsOpen)}
              >
                {lang.get("payments")}
              </Button>
            </Grid>
          </Grid>
          {props.invoice.payments ? (
            <InvoicePayments
              open={paymentsOpen}
              onClose={handlePaymentsClose}
              invoiceId={props.invoice.id}
              payments={props.invoice.payments}
              invoiceAmount={props.invoice.totalWithVat}
            />
          ) : null}
        </AccordionDetails>
      </Accordion>
    </Fragment>
  );
}

InvoiceItem.propTypes = {
  isProject: PropTypes.bool,
  isDashboard: PropTypes.bool,
  select: PropTypes.bool,
  invoice: PropTypes.object.isRequired,
  canDelete: PropTypes.bool,
  isMobile: PropTypes.bool.isRequired,
  changeInvoiceState: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  onDuplicate: PropTypes.func,
  onSelect: PropTypes.func,
  classesOverride: PropTypes.object,
};
