export default class CachedDataSingleton{
    static instance;
    constructor(){
        this.cachedData = null;
    }

    get(key){
        if(!this.cachedData) return null;
        return this.cachedData[key];
    }

    setKey(key, value){
        return this.cachedData[key] = value;
    }

    set(cachedData){
        this.cachedData = cachedData;
    }

    static getInstance(){

        return CachedDataSingleton.instance ? CachedDataSingleton.instance : CachedDataSingleton.instance = new CachedDataSingleton();
    }
}
