import React from 'react';
import {useStripe, useElements, IbanElement} from '@stripe/react-stripe-js';
import Sepa from './Sepa';
import Lang from '../../lang';
import { StatusEnum } from '../../Utils/Utils';

export default function CheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();
  const lang = Lang.getInstance(); 
  const handleSubmit = async (event) => {
    
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    const iban = elements.getElement(IbanElement);
    const sourceData = {
      type: 'sepa_debit',
      currency: 'eur',
      owner: {
          name: props.client.name,
          email: props.client.email,
      },
      mandate: {
          notification_method: 'email',
      },
      metadata:{
          "internalClientId": props.client.id,
          "subscription": props.package.name
      }
  };
    stripe.createSource(iban,sourceData).then( (result)=>{
      if(result.error)
      props.showSmallMessage(lang.get("error"), StatusEnum.ERROR);
      else
        props.handleSubmitSepa(result.source);
    })

  };

  return (
    <Sepa onSubmit={handleSubmit} disabled={!stripe} client={props.client}/>
  );
}