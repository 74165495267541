import React, {Component} from 'react';
import Lang from '../../lang';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Select, FormControl, InputLabel } from '@material-ui/core';

const styles = theme => ({     
});
class AddressInfo extends Component{

    /**
     * constructor
     * @param {Object} props 
     */
    constructor(props){
        super(props);
        this.lang = Lang.getInstance();
    }
    
    /**
     * render method
     */
    render(){
        const {classes}= this.props;  
        return(
            <div style={{padding:20}}>
                <ValidatorForm onSubmit={()=>{}}>
                    {/** address  */}
                    <TextValidator
                    label = {this.lang.get("address")}
                    fullWidth
                    name="address.address"
                    value={this.props.model.address.address}
                    onChange={this.props.onChange}
                    validators={['required']} 
                    errorMessages={[this.lang.get("fieldRequired")]} />

                    {/** code */}
                    <TextValidator
                    type="number"
                    fullWidth
                    label = {this.lang.get("code")}
                    name="address.code"
                    value={this.props.model.address.code}
                    onChange={this.props.onChange}
                    validators={['required']} 
                    errorMessages={[this.lang.get("fieldRequired")]} />

                        {/** city */}
                        <TextValidator
                    label = {this.lang.get("city")}
                    fullWidth
                    name="address.city"
                    value={this.props.model.address.city}
                    onChange={this.props.onChange}
                    validators={['required']} 
                    errorMessages={[this.lang.get("fieldRequired")]} />

                    {/** country  */}
                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel>{this.lang.get("country")}</InputLabel>
                        <Select
                            native
                            id="address.fk_countryId" 
                            value={this.props.model.address.fk_countryId > 0 ? this.props.model.address.fk_countryId : 21}
                            onChange={this.props.onChange}
                            >
                            {this.props.countries.map((country)=>
                                <option key={country.id} value={country.id}>{country.name}</option>
                            )}
                        </Select>
                    </FormControl>
                </ValidatorForm>
            </div>
        );
    }
}


AddressInfo.propTypes = {
    model: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    countries: PropTypes.array.isRequired
};

export default withStyles(styles)(AddressInfo);