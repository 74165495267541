import { KeyboardDatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import React, { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Lang from "../../../lang";
import Config from "../../../Utils/Config";
import moment from "moment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CachedDataSingleton from "../../../cachedDataSingleton";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { Client } from "../../../Models/Models";
import theme from "../../../Theme/Theme";

const lang = Lang.getInstance();
const cachedData = CachedDataSingleton.getInstance();

type DcumentReportFormProps = {
  onSearch: (data: DocumentReportState) => void;
  docTypes: Array<string>;
  showPaid: boolean;
};

type DocumentReportState = {
  startDate: moment.Moment | string;
  endDate: moment.Moment | string;
  client: Client | null;
  docType: string;
  minValue: number;
  maxValue: number;
  paid: boolean;
  unpaid: boolean;
};


function DcumentReportForm(props: DcumentReportFormProps) {
  const clients = cachedData.get("clients");

  const initialState: DocumentReportState = {
    startDate: moment(
      "01-01-" + new Date().getUTCFullYear().toString(),
      Config.momentEUDateFormat
    ),
    endDate: moment(new Date()),
    client: null,
    docType: props.docTypes[0],
    minValue: 0,
    maxValue: 0,
    paid: false,
    unpaid: false,
  };

  const [state, setState] = useState(initialState);

  useEffect(() => {
    props.onSearch(state);
  }, []);

  function onChangeStartDate(date: MaterialUiPickersDate) {
    if (date === null) return false;
    if (date.isAfter(moment())) return false;

    const md = date as moment.Moment;
    if (md.isAfter(moment(state.endDate))) return false;

    let newState = Object.assign({}, state);
    newState.startDate = md.format(Config.momentUSDateFormat);
    setState(newState);
  }

  function onChangeEndDate(date: MaterialUiPickersDate) {
    if (date === null) return false;
    if (date.isAfter(moment())) return false;

    const md = date as moment.Moment;
    if (md.isBefore(moment(state.startDate))) return false;

    let newState = Object.assign({}, state);
    newState.endDate = md.format(Config.momentUSDateFormat);
    setState(newState);
  }

  return (
    <ValidatorForm onSubmit={() => props.onSearch(state)}>
      <Grid container spacing={4}>
        <Grid item md={6} sm={6} xs={12}>
          <KeyboardDatePicker
            fullWidth
            autoOk={true}
            allowKeyboardControl
            disableToolbar
            variant="inline"
            name="startDate"
            label={lang.get("startDate")}
            value={state.startDate}
            format={Config.momentEUDateFormat}
            onChange={(date: MaterialUiPickersDate) => onChangeStartDate(date)}
          />
          <KeyboardDatePicker
            fullWidth
            autoOk={true}
            allowKeyboardControl
            disableToolbar
            variant="inline"
            name="endDate"
            label={lang.get("endDate")}
            value={state.endDate}
            format={Config.momentEUDateFormat}
            onChange={(date: MaterialUiPickersDate) => onChangeEndDate(date)}
          />
          {props.showPaid ? (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.paid}
                    onChange={(e) => {
                      setState({ ...state, paid: e.target.checked });
                    }}
                    name="paid"
                    style={{ color: theme.palette.header?.main }}
                  />
                }
                label={lang.get("paid")}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.unpaid}
                    onChange={(e) => {
                        setState({ ...state, unpaid: e.target.checked });
                    }}
                    name="paid"
                    style={{ color: theme.palette.header?.main }}
                  />
                }
                label={lang.get("unpaid")}
              />
            </>
          ) : null}

          <br />
          <br />
          <Button
            style={{ background: theme.palette.header?.main }}
            type="submit"
            variant="contained"
          >
            {lang.get("search")}
          </Button>
        </Grid>

        <Grid item md={6} sm={6} xs={12}>
          <FormControl fullWidth={true}>
            <InputLabel>{lang.get("docType")}</InputLabel>
            <Select
              value={state.docType}
              onChange={(e) => {
                setState({ ...state, docType: e.target.value as string });
              }}
            >
              {props.docTypes.map((key: string) => {
                return (
                  <MenuItem key={key} value={key}>
                    {lang.get(key)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <Autocomplete
            fullWidth={true}
            id="clientName"
            options={clients}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField {...params} label={lang.get("clientName")} />
            )}
            onChange={(e: React.ChangeEvent<{}>, client: Client | null) => {
              setState({ ...state, client: client });
            }}
            value={state.client}
            getOptionSelected={(option) => option.id === state.client?.id}
          />
          <TextValidator
            label={lang.get("minValue")}
            name="minValue"
            type="text"
            validators={["required", "matchRegexp:^-*([0-9])*$"]}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => 
              setState({ ...state, minValue: parseFloat(e.target.value) })
            }
            fullWidth
            value={state.minValue}
          />

          <TextValidator
            label={lang.get("maxValue")}
            name="maxValue"
            type="text"
            validators={["required", "matchRegexp:^-*([0-9])*$"]}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setState({ ...state, maxValue: parseFloat(e.target.value) })
            }
            fullWidth
            value={state.maxValue}
          />
        </Grid>
      </Grid>
    </ValidatorForm>
  );
}

export default DcumentReportForm;
