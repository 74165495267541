import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Document from '../../DocumentTemplates/Document';
import SimpleHeader from '../../DocumentTemplates/SimpleHeader';
import { DocTypes, get, UrlEnum } from '../../Utils/Utils';
import { convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { CircularProgress } from '@material-ui/core';

type OnlineDocProps = {

};

export default function OnlineDoc(props: OnlineDocProps) {
    const params: { docType: string, companyId: string } = useParams();
    const docType: string = params.docType;
    const [content, setContent] = useState<Element | null>(null);

    const renderGeneralConditions = (color: string, company: any, customHTML: string) => (
        <div style={{background:"#ddd", padding:30}}>
        <Document style={{boxShadow:"0 0 10px #999", maxHeight:"auto"}}>
            <SimpleHeader
                company={company}
                color={color}
            />
            <div dangerouslySetInnerHTML={{ __html: customHTML }}></div>
        </Document>
        </div>
    )

    const getGeneralConditionsData = () => {
        get(UrlEnum.companies + "/" + params.companyId).then((company)=>{
            get(`${UrlEnum.documentTemplates}/${docType}/${params.companyId}`).then(response => {
                if(!response || !response.customText){
                    const c:any = <></>;
                    setContent(c)
                    return;
                }
                try{
                    const color: string = response.color;
                    let contentJson = JSON.parse(response.customText);
                    const contentState = convertFromRaw(contentJson);
                    const customHtml = stateToHTML(contentState);
                    const c:any = renderGeneralConditions(color, company, customHtml);
                    setContent(c);
                }catch(e){
                    const c:any = <></>;
                    setContent(c)
                }
            });
        });
    }

    if(!content){
        switch (docType) {
            case DocTypes.generalConditions:
                getGeneralConditionsData();
        }
    }

    return (
       <>
        {
            content? 
            <div>{content}</div>:
                <div style={{ margin: "0 auto", width: 80 }}>
                    <CircularProgress />
                </div>
        }
       </>
    );
}