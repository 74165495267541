import React, { Fragment, useState } from 'react';
import {httpDelete} from '../Utils/Utils';
import { IconButton } from '@material-ui/core';
import ConfirmDialog from './ConfirmDialog';
import MessageDialog from './MessageDialog';
import DeleteIcon from '@material-ui/icons/Delete';
import PropTypes from 'prop-types';
import Lang from '../lang';

export default function DeleteIconBtn(props){
    const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
    const [openMessage, setOpenMessage] = useState(null);
    const lang = Lang.getInstance();

    /**
     * triggered when the delete button is pressed
     * @param {*} e 
     */
    const handleDeleteItem = (e) => {
        setOpenDeleteConfirm(true); 
    }

    /**
     * do on confirm result
     * @param {*} confirm 
     */
    const onConfirmResult = (confirm) => {
        setOpenDeleteConfirm(false);
        if(!confirm) return;
        
        let data = props.data;
        if(props.data.call){
            data = props.data();
        }
       
        if(data.id === 0){
            if(props.onSuccess){
                props.onSuccess(data, null);
            }
            return;
        }

        httpDelete(props.url, data).then((response)=>{
            if(response.errors){
                // show alert
                let message = response.errors instanceof Array && response.errors.length > 0 ? 
                    response.errors.join("<br/>") : 
                    response.errors;
                    setOpenMessage(message);
                    // failure callback
                    if(props.onFailure){
                        props.onFailure(data, response);
                    }
                return;
            }

            if(props.onSuccess){
                props.onSuccess(data, response);
            }
        });
    };


    return (
        <Fragment>
            <IconButton 
                classes={props.classes ? props.classes : {}} 
                disabled={props.disabled} color="inherit" 
                onClick={handleDeleteItem}><DeleteIcon/>
            </IconButton>
            <ConfirmDialog open={openDeleteConfirm} text={lang.get("deleteItem")} onResult={onConfirmResult} />
            <MessageDialog open={openMessage ? true : false} text={openMessage || ""} onResult={()=>setOpenMessage(null)} />
        </Fragment>
    )
}

DeleteIconBtn.propTypes = {
    data: PropTypes.any,
    url: PropTypes.string.isRequired,
    onSuccess: PropTypes.func,
    onFailure: PropTypes.func,
    disabled: PropTypes.bool,
    classes: PropTypes.object
}