import { useState } from "react";
import { Button, Paper, Typography, makeStyles } from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CachedDataSingleton from "../cachedDataSingleton";
import Lang from "../lang";
import { LocalUrlEnum } from "../Utils/Utils";
import theme from "../Theme/Theme";

const lang = Lang.getInstance();

const useStyles = makeStyles((theme) => ({
  popupTrial: {
    background: theme.palette.orangeColor?.main,
    position: "absolute",
    bottom: "50px",
    right: "50px",
    width: "300px",
    zIndex:1500,
  },
  popupTrialMobile: {
    background: theme.palette.orangeColor?.main,
    position: "absolute",
    bottom: "0px",
    right: "10px",
    left:"10px",
    zIndex:"1500",
    width: "auto",
  },
}));
export default function Trial(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const cachedData = CachedDataSingleton.getInstance();
  const isTrial = cachedData.get("istrial");
  const trialLeft = cachedData.get("trialLeft");
  const internalClientId = cachedData.get("internalClientId");
  const urlTrial = `${LocalUrlEnum.isTrial}/${internalClientId}`;
  const handleClose = () => {
    setOpen(false);
  };
  return isTrial && open ? (

    <Paper className={props.isMobile ? classes.popupTrialMobile: classes.popupTrial}>
      <div style={{ textAlign: "right" }}>
        <Button
          style={{
            margin: "5px 0px 0px 0px",
            background: "transparent",
          }}
          onClick={handleClose}
        >
          <HighlightOffIcon
            fontSize="medium"
            style={{
              color: theme.palette.textColorSecondary?.main,
            }}
          />
        </Button>
      </div>
      <Typography
        style={{
          padding: "0px 15px 15px 15px",
          textAlign: "center",
          color: theme.palette.textColorSecondary?.main,
        }}
        sx={{ p: 2 }}
      >
        {lang.get("trial")} - {trialLeft} {lang.get("days")}
      </Typography>
      <div style={{ textAlign: "center", padding: "0px 15px 15px 15px" }}>
        <a href={urlTrial}>
          <Button
            onClick={handleClose}
            style={{
              background: theme.palette.primary?.main,
              color: theme.palette.textColorSecondary?.main,
            }}
            fullWidth
          >
            {lang.get("getSubscription")}
          </Button>
        </a>
      </div>
    </Paper>
  ) : (
    ""
  );
}
