import { useCallback, useEffect, useState } from "react";
import { post } from "./Utils";

function usePost (url, payload = null) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);

    const memoisedFetch = useCallback(
        (mutationData) => {
            post(url, mutationData)
                .then((response) => {
                    if (response.errors) {
                        setError(response.errors);
                        setLoading(false);
                    } else {
                        setLoading(false);
                        setData(response);
                    }
                })
                .catch((e) => {
                    setError(e.message);
                    setLoading(false);
                });
        },
        [url],
    );

    useEffect(() => {
        if(payload){
            memoisedFetch(payload);
        }
    }, [payload, memoisedFetch]);

    return { data, loading, error, request:memoisedFetch };
}

export default usePost;