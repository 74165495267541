import React, { Fragment } from "react";
import {
  UrlEnum,
  isMobile,
  DocTypes,
  post,
  CostTypeEnum,
  LocalUrlEnum,
} from "../Utils/Utils";
import Lang from "../lang";
import {
  Button,
  withStyles,
  createStyles,
  Theme,
  Select,
  InputLabel,
  MenuItem,
} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import Config from "../Utils/Config";
import TableComponent from "../Components/TableComponent";
import EditCosts from "../DocumentComponents/EditCosts";
import { Cost } from "../Models/Models";
import { Link } from "react-router-dom";
import moment from "moment";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import DocumentsLayout from "../Components/DocumentsLayout";
import MonthlyCostsDialog from "../Components/MonthlyCostsDialog";
import ResizeObserver from "resize-observer-polyfill";

const styles = (theme: Theme) =>
  createStyles({
    rightPikers: {
      display: "flex",
      color: "inherit",
      alignContent: "center",
      flexDirection: "row",
      paddingRight: 10,
      justifyContent: "center",

      [theme.breakpoints.up("sm")]: {
        display: "flex",
        alignContent: "center",
        flexDirection: "row",
        justifyContent: "center",
        flexWrap: "wrap",
      },
    },

    componentTable: {
      paddingTop: 10,

      [theme.breakpoints.down("sm")]: {
        top: 55,
      },
    },

    picker: {
      paddingRight: 5,
      "& label": {
        color: "#fff",
      },
      "& input": {
        color: "#fff",
      },
      "& .MuiInput-underline:before": {
        borderColor: "#fff",
      },
      margin: theme.spacing(0.5),
    },

    button: {
      border: "1px solid rgba(28,110,164,0.31)",
      display: "flex",
      color: "inherit",
      alignContent: "center",
      marginLeft: 15,
      flexDirection: "row",
      justifyContent: "center",
    },

    date: {
      padding: 5,
      display: "flex",
      color: "inherit",
      [theme.breakpoints.down("md")]: {
        display: "inline-block",
        flexDirection: "row",
        alignContent: "center",
        justifyContent: "center",
        paddingBottom: "none",
      },
    },
    unpaid: {
      backgroundColor: theme.palette.error.light,
      color: theme.palette.textColorSecondary?.main,
    },
    paid: {
      backgroundColor: theme.palette.success.light,
      color: theme.palette.textColorSecondary?.main,
    },
    monthly: {
      "&>td:first-child": {
        borderLeft: "5px solid",
        borderLeftColor: theme.palette.warning.light,
      },
    },
    workforceLabel: {
      backgroundColor: theme.palette.info.light,
      color: theme.palette.textColorSecondary?.main,
    },
    workforce: {
      "&>td:first-child": {
        borderLeft: "5px solid",
        borderLeftColor: theme.palette.info.light,
      },
    },
    purchaseLabel: {
      backgroundColor: theme.palette.header?.main,
      color: theme.palette.textColorSecondary?.main,
    },
    purchase: {
      "&>td:first-child": {
        borderLeft: "5px solid",
        borderLeftColor: theme.palette.header?.main,
      },
    },
    administrativeLabel: {
      borderStyle: "solid",
      borderBottomWidth: 0,
      borderLeftWidth: 0,
      borderWidth: 0.5,
      borderColor: "#DCDCDC",
      flexGrow: 0.2,
    },
    unpaidBtn: {
      backgroundColor: theme.palette.error.light,
      color: theme.palette.textColorSecondary?.main,
      "&:hover": {
        backgroundColor: "#5d817a",
      },
    },
    monthlyBtn: {
      backgroundColor: theme.palette.warning.light,
      color: theme.palette.textColorSecondary?.main,
      "&:hover": {
        backgroundColor: "#5d817a",
      },
    },
    infoLabel: {
      padding: 10,
      position: "relative",
      top: 15,
    },
    infoLabelMobile: {
      padding: "10px 5px",
      position: "relative",
      top: 15,
      fontSize:"10px"
    },
    summary: {
      position: "fixed",
      bottom: 90,
      width: 255,
    },
    summaryTitle: {
      background: theme.palette.header?.main,
      padding: 5,
      color: "#fff",
      borderRadius: 2,
    },
    totalsValue: {
      float: "right",
    },
  });

const lang: any = Lang.getInstance();

type CostsProps = {
  className?: any;
  trigger?: boolean;
  theme?: any;
  classes: any;
  showSmallMessage: Function;
  history: any;
  match: any;
  openMenu: boolean;
};
type CostsState = {
  modifiedCosts: Array<Cost | null> | null;
  editedModel: Cost | null;
  tableCostsModel: Cost;
  startDate: string;
  endDate: string;
  page: number;
  perPage: number;
  monthly: boolean;
  onMonth: any;
  unpaid: boolean;
  type: string;
  onUnpaid: any;
  chips: any;
  userNameIsPressed: boolean;
  monthlyOrUpaid: any;
  searchText: string;
  totalAmount: number;
  totalUnpaid: number;
  manageMonthlyCosts: boolean;
  sort: string;
  order: string;
  refetchData: number | null;
  tableColumns: Array<any>;
};

class Costs extends React.Component<CostsProps, CostsState> {
  crtDate: Date;
  initialTableColumns = [
    { label: lang.get("supplier"), name: "supplierName" },
    { label: lang.get("client"), name: "clientName" },
    { label: lang.get("amount"), name: "amount" },
    { label: lang.get("description"), name: "description" },
    // {
    //   label: lang.get("date"),
    //   name: "docDate",
    //   customRender: (cost: Cost) => {
    //     return moment(cost.docDate, Config.momentUSDateFormatWithTime).format(
    //       Config.momentEUDateFormat
    //     );
    //   },
    // },
    {
      label: lang.get("number"),
      name: "docNumber",
    },
  ];

  /**
   *
   * @param {Object} props
   */
  constructor(props: CostsProps) {
    super(props);
    this.crtDate = new Date();
    const crtDateString = moment().format(Config.momentUSDateFormat);

    this.state = {
      editedModel: null,
      modifiedCosts: null,
      tableCostsModel: new Cost(),
      startDate:
        this.props.match.params.startDate ? this.props.match.params.startDate :
        this.crtDate.getMonth() > 6
          ? this.crtDate.getUTCFullYear() + "-01-01"
          : this.crtDate.getUTCFullYear() - 1 + "-01-01",
      endDate: this.props.match.params.endDate || crtDateString,
      page: this.props.match.params.page || 1,
      perPage: this.props.match.params.perPage || 50,
      searchText: this.props.match.params.q || "",
      monthly: false,
      unpaid: false,
      type: "",
      onMonth: 0,
      onUnpaid: 0,
      chips: {
        client: true,
        supplier: true,
        amount: false,
        number: false,
        description: false,
      },
      userNameIsPressed: true,
      monthlyOrUpaid: "",
      totalAmount: 0,
      totalUnpaid: 0,
      manageMonthlyCosts: false,
      sort: "docDate",
      order: "desc",
      refetchData: null,
      tableColumns: this.initialTableColumns,
    };
  }

  /**
   *
   * @param model
   */
  editSubmitCallback(model: Cost | null) {
    const dateStr = moment(model?.docDate).format(
      Config.momentUSDateFormatWithTime
    );
    if (model) {
      model.docDate = dateStr;
    }
    this.setState({ editedModel: model, modifiedCosts: [model] });
  }

  getTableWidth(entries: any) {
    let crtTableColumns = this.state.tableColumns;
    let existAlready = crtTableColumns.find(
      (element: any) => element.name === "folderNo"
    );
    for (let entry of entries) {
      if (entry.contentRect.width > 1200) {
        if (!existAlready) {
          crtTableColumns.push({
            label: lang.get("folderNo"),
            name: "folderNo",
            customRender: (cost: Cost) => {
              return (
                <small>
                  <Link
                    className="link"
                    to={LocalUrlEnum.projects + "/" + cost?.folderId}
                  >
                    {cost?.folderNo}
                  </Link>
                </small>
              );
            },
          });
          crtTableColumns.push({
            label: lang.get("username"),
            name: "userName",
          });
          this.setState({ tableColumns: crtTableColumns });
        }
      } else {
        crtTableColumns = crtTableColumns.filter(
          (e) => e.name !== "folderNo" && e.name !== "userName"
        );
        this.setState({ tableColumns: crtTableColumns });
      }
    }
  }

  componentDidMount() {
    let table = document.getElementById("tableDiv");
    if (table) {
      var ro = new ResizeObserver((entries) => this.getTableWidth(entries));
      ro.observe(table);
    }
  }

  /**
   *
   * @param startDate
   * @param endDate
   * @param search
   * @param page
   * @param perPage
   */
  changeUrl(
    startDate: string,
    endDate: string,
    search: string,
    page: number,
    perPage = 50
  ) {
    let displayUrlBase = LocalUrlEnum.costs;
    const displayUrl = `${displayUrlBase}/${startDate}/${endDate}/${page}/${perPage}/${search}`;
    this.props.history.push(displayUrl);
  }

  /**
   *
   * @param event
   * @param newValue
   */
  handleCheckMonthlyUnpaid(
    event: React.MouseEvent<HTMLElement>,
    newValue: any
  ) {
    const valueString = event.currentTarget.getAttribute("value");
    this.setState({
      monthlyOrUpaid:
        this.state.monthlyOrUpaid === valueString ? "" : valueString,
      monthly: valueString === "monthly" ? !this.state.monthly : false,
      unpaid: valueString !== "monthly" ? !this.state.unpaid : false,
      modifiedCosts: null,
    });
  }

  /**
   *
   * @param p
   */
  handleChipClick(p: string) {
    let newChip = { ...this.state.chips };
    newChip[p] = !newChip[p];
    this.setState({ chips: newChip });
  }

  /**
   *
   * @param costModel
   */
  showEditCost(costModel: Cost) {
    this.setState({ editedModel: costModel });
  }

  /**
   *
   * @param cost
   */
  setPaid(cost: Cost) {
    const nextState = cost.paid > 0 ? false : true;
    post(
      UrlEnum.folderCosts + "/setPaid/" + cost.id + "/" + nextState,
      {}
    ).then((response) => {
      if (response.errors) return;
      cost.paid = nextState ? 1 : 0;
      this.setState({ modifiedCosts: response.paid });
    });
  }

  /**
   * render cost type options
   */
  renderCostTypeOptions() {
    const cte: any = CostTypeEnum;
    let options = [
      <option key="0" value="">
        {lang.get("selectAValue")}
      </option>,
    ];
    for (const value in cte) {
      options.push(
        <option key={cte[value]} value={cte[value]}>
          {lang.get(cte[value])}
        </option>
      );
    }
    return options;
  }

  /**
   * render the contents of the Drawer
   */
  renderDrawerChildren(classes: any) {
    return (
      <Fragment>
        <ToggleButtonGroup
          value={this.state.monthlyOrUpaid}
          onChange={this.handleCheckMonthlyUnpaid.bind(this)}
          size="large"
          exclusive
        >
          <ToggleButton value="unpaid" classes={{ root: classes.unpaidBtn }}>
            <MoneyOffIcon />
            {lang.get("unpaid")}
          </ToggleButton>
          <ToggleButton value="monthly" classes={{ root: classes.monthlyBtn }}>
            <EventAvailableIcon />
            {lang.get("monthly")}
          </ToggleButton>
        </ToggleButtonGroup>
        <p>&nbsp;</p>
        <InputLabel>{lang.get("type")}</InputLabel>
        <Select
          native
          id="type"
          value={this.state.type !== "" ? this.state.type : ""}
          onChange={(event: any) => {
            this.setState({ type: event.target.value });
          }}
        >
          {this.renderCostTypeOptions()}
        </Select>
        <div className={classes.summary}>
          <div className={classes.summaryTitle}>{lang.get("summary")}</div>
          <p>
            {lang.get("total")}:{" "}
            <span className={classes.totalsValue}>
              {this.state.totalAmount}
            </span>
          </p>
          <p>
            {lang.get("unpaid")}:{" "}
            <span className={classes.totalsValue}>
              {this.state.totalUnpaid}
            </span>
          </p>
        </div>
        <p>&nbsp;</p>
        <Button
          variant="contained"
          onClick={() => this.setState({ manageMonthlyCosts: true })}
        >
          {lang.get("costManagement")} <em>({lang.get("monthly")})</em>
        </Button>
        <Link to={LocalUrlEnum.budgetEstimate }>
        <Button
          variant="contained"
          fullWidth
          style={{margin:"20px 0px"}}
        >
          {lang.get("budgetEstimate")}
        </Button>
        </Link>
        <MonthlyCostsDialog
          submitCallback={this.editSubmitCallback.bind(this)}
          open={this.state.manageMonthlyCosts}
          onClose={() => this.setState({ manageMonthlyCosts: false })}
          showSmallMessage={this.props.showSmallMessage}
        />
      </Fragment>
    );
  }

  componentWillUnmount() {
    console.log("unmount");
  }
  renderCosts(classes: any, tableComponentUrl: any){
    return <>
    {isMobile() ? (
      <div>
        <Chip
          id="description"
          className={classes.picker}
          size="small"
          icon={<AssignmentIndIcon />}
          clickable
          onClick={() => this.handleChipClick("description")}
          label={lang.get("description")}
          color={this.state.chips.description ? "primary" : "default"}
        />
        <Chip
          id="client"
          className={classes.picker}
          size="small"
          icon={<AssignmentIndIcon />}
          clickable
          onClick={() => this.handleChipClick("client")}
          label={lang.get("client")}
          color={this.state.chips.client ? "primary" : "default"}
        />
        <Chip
          id="supplier"
          className={classes.picker}
          size="small"
          icon={<SupervisedUserCircleIcon />}
          clickable
          onClick={() => this.handleChipClick("supplier")}
          label={lang.get("supplier")}
          color={this.state.chips.supplier ? "primary" : "default"}
        />

        <Chip
          id="amount"
          className={classes.picker}
          size="small"
          icon={<AccountBalanceWalletIcon />}
          clickable
          onClick={() => this.handleChipClick("amount")}
          label={lang.get("amount")}
          color={this.state.chips.amount ? "primary" : "default"}
        />

        <Chip
          id="number"
          size="small"
          className={classes.picker}
          icon={<AccountTreeIcon />}
          clickable
          onClick={() => this.handleChipClick("number")}
          label={lang.get("number")}
          color={this.state.chips.number ? "primary" : "default"}
        />
      </div>
    ) : null}

    <div id="tableDiv" className={classes.componentTable}>
      <TableComponent
        editedModels={this.state.modifiedCosts}
        url={tableComponentUrl}
        baseUrl={UrlEnum.folderCosts}
        columns={this.state.tableColumns}
        editCallback={this.showEditCost.bind(this)}
        search={false}
        searchText={this.state.searchText}
        sort={this.state.sort}
        order={this.state.order}
        rowClassCallback={(cost: Cost) => {
          let crtClasses = "";
          if (
            cost.paymentDeadline &&
            moment(
              cost.paymentDeadline,
              Config.momentUSDateFormat
            ).isBefore(moment()) &&
            cost.paid < 1
          ) {
            crtClasses += classes.unpaid;
          } else if (
            (cost.isMonthly && cost.isMonthly > 0) ||
            cost.monthlyCostId
          ) {
            crtClasses += ` ${classes.monthly}`;
          } else if (cost.type === CostTypeEnum.PURCHASE) {
            crtClasses += ` ${classes.purchase}`;
          } else if (cost.type === CostTypeEnum.WORKFORCE) {
            crtClasses += ` ${classes.workforce}`;
          }
          return crtClasses;
        }}
        customHeaderContent={
          isMobile() ? (
            <div style={{ display: "flex", padding: 10 }}>
              <div style={{ flexGrow: 1, display: "flex" }}>
                <div>
                  <label
                    className={`${classes.unpaidBtn} ${classes.infoLabelMobile}`}
                  >
                    {lang.get("unpaid")}
                  </label>
                  <label
                    className={`${classes.workforceLabel} ${classes.infoLabelMobile}`}
                  >
                    {lang.get("workforce")}
                  </label>
                  <label
                    className={`${classes.monthlyBtn} ${classes.infoLabelMobile}`}
                  >
                    {lang.get("monthly")}
                  </label>
                  <label
                    className={`${classes.purchaseLabel} ${classes.infoLabelMobile}`}
                  >
                    {lang.get("purchase")}
                  </label>
                  <label
                    className={`${classes.administrativeLabel} ${classes.infoLabelMobile}`}
                  >
                    {lang.get("administrative")}
                  </label>
                </div>
              </div>
            </div>
          ) : (
            <div style={{ display: "flex", padding: 10 }}>
              <div style={{ flexGrow: 1, display: "flex" }}>
                <div>
                  <label
                    className={`${classes.unpaidBtn} ${classes.infoLabel}`}
                  >
                    {lang.get("unpaid")}
                  </label>
                  <label
                    className={`${classes.workforceLabel} ${classes.infoLabel}`}
                  >
                    {lang.get("workforce")}
                  </label>
                  <label
                    className={`${classes.monthlyBtn} ${classes.infoLabel}`}
                  >
                    {lang.get("monthly")}
                  </label>
                  <label
                    className={`${classes.purchaseLabel} ${classes.infoLabel}`}
                  >
                    {lang.get("purchase")}
                  </label>
                  <label
                    className={`${classes.administrativeLabel} ${classes.infoLabel}`}
                  >
                    {lang.get("administrative")}
                  </label>
                </div>
              </div>
              {/* select sort */}
              <div style={{ paddingTop: 10, display: "flex" }}>
                <InputLabel style={{ marginTop: 10, marginRight: 10 }}>
                  {lang.get("sort")}
                </InputLabel>
                <Select
                  style={{ width: 200 }}
                  id="sort"
                  value={this.state.sort + "_" + this.state.order}
                  onChange={(event: any) => {
                    const [sort, order] = event.target.value.split("_");

                    this.setState({ sort: sort, order: order });
                  }}
                >
                  <MenuItem key="0" value="docDate_asc">
                    {lang.get("date")} {lang.get("ascending")}
                  </MenuItem>
                  <MenuItem key="1" value="docDate_desc">
                    {lang.get("date")} {lang.get("descending")}
                  </MenuItem>
                  <MenuItem key="2" value="paymentDeadline_asc">
                    {lang.get("paymentDeadline")} {lang.get("ascending")}
                  </MenuItem>
                  <MenuItem key="3" value="paymentDeadline_desc">
                    {lang.get("paymentDeadline")} {lang.get("descending")}
                  </MenuItem>
                  <MenuItem key="4" value="amount_asc">
                    {lang.get("amount")} {lang.get("ascending")}
                  </MenuItem>
                  <MenuItem key="5" value="amount_desc">
                    {lang.get("amount")} {lang.get("descending")}
                  </MenuItem>
                </Select>
              </div>
            </div>
          )
        }
        onFetchResult={(data: any, pagination: any) => {
          this.setState({
            totalAmount: data.totalAmount,
            totalUnpaid: data.totalUnpaid,
          });
        }}
      />
    </div>
    {this.state.editedModel ? (
      <EditCosts
        model={this.state.editedModel}
        open={this.state.editedModel !== null}
        onClose={() => {
          this.setState({
            editedModel: null,
            refetchData: new Date().getTime(),
          });
        }}
        submitCallback={this.editSubmitCallback.bind(this)}
        showSmallMessage={this.props.showSmallMessage}
      ></EditCosts>
    ) : null}
    </>
  }

  /**
   *
   */
  render() {
    let existPaid = this.state.tableColumns.find(
      (element: any) => element.name === "paid"
    );
    if (!existPaid) {
      this.state.tableColumns.push({
        label: lang.get("paid"),
        name: "paid",
        customRender: (cost: Cost) => {
          return (
            <div>
              <Button
                className={cost.paid < 1 ? classes.unpaid : classes.paid}
                onClick={() => this.setPaid(cost)}
              >
                {cost.paid < 1 ? lang.get("unpaid") : lang.get("paid")}
              </Button>
            </div>
          );
        },
      });
    }
    if (
      this.state.unpaid &&
      !this.state.tableColumns.find((tc) => tc.name === "paymentDeadline")
    ) {
      this.state.tableColumns.push({
        label: lang.get("paymentDeadline"),
        name: "paymentDeadline",
      });
    }
    if (!this.state.unpaid) {
      const index = this.state.tableColumns.findIndex(
        (tc) => tc.name === "paymentDeadline"
      );
      if (index > 0 || index === 0) {
        this.state.tableColumns.splice(index, 1);
      }
    }

    if (isMobile()) {
      if (!this.state.chips.supplier) {
        let indexSupplier = this.state.tableColumns
          .map(function (e) {
            return e.name;
          })
          .indexOf("supplierName");
        if (indexSupplier !== -1)
          this.state.tableColumns.splice(indexSupplier, 1);
      } else {
        let indexFolder = this.state.tableColumns
          .map(function (e) {
            return e.name;
          })
          .indexOf("supplierName");
        if (indexFolder === -1)
          this.state.tableColumns.splice(1, 0, {
            label: lang.get("supplier"),
            name: "supplierName",
          });
      }

      if (!this.state.chips.client) {
        let indexClient = this.state.tableColumns
          .map(function (e) {
            return e.name;
          })
          .indexOf("clientName");
        if (indexClient !== -1) this.state.tableColumns.splice(indexClient, 1);
      } else {
        let indexFolder = this.state.tableColumns
          .map(function (e) {
            return e.name;
          })
          .indexOf("clientName");
        if (indexFolder === -1)
          this.state.tableColumns.splice(1, 0, {
            label: lang.get("client"),
            name: "clientName",
          });
      }

      if (!this.state.chips.amount) {
        let indexAmount = this.state.tableColumns
          .map(function (e) {
            return e.name;
          })
          .indexOf("amount");
        if (indexAmount !== -1) this.state.tableColumns.splice(indexAmount, 1);
      } else {
        let indexFolder = this.state.tableColumns
          .map(function (e) {
            return e.name;
          })
          .indexOf("amount");
        if (indexFolder === -1)
          this.state.tableColumns.splice(1, 0, {
            label: lang.get("amount"),
            name: "amount",
          });
      }

      if (!this.state.chips.number) {
        let indexFolder = this.state.tableColumns
          .map(function (e) {
            return e.name;
          })
          .indexOf("docNumber");
        if (indexFolder !== -1) this.state.tableColumns.splice(indexFolder, 1);
      } else {
        let indexFolder = this.state.tableColumns
          .map(function (e) {
            return e.name;
          })
          .indexOf("docNumber");
        if (indexFolder === -1)
          this.state.tableColumns.splice(1, 0, {
            label: lang.get("number"),
            name: "docNumber",
          });
      }

      if (!this.state.chips.description) {
        let indexFolder = this.state.tableColumns
          .map(function (e) {
            return e.name;
          })
          .indexOf("description");
        if (indexFolder !== -1) this.state.tableColumns.splice(indexFolder, 1);
      } else {
        let indexFolder = this.state.tableColumns
          .map(function (e) {
            return e.name;
          })
          .indexOf("description");
        if (indexFolder === -1)
          this.state.tableColumns.splice(1, 0, {
            label: lang.get("description"),
            name: "description",
          });
      }
    }
    const { classes } = this.props;
    let tableComponentUrl = `${UrlEnum.folderCosts}/${this.state.startDate}/${
      this.state.endDate
    }/${+this.state.unpaid}/${+this.state.monthly}`;
    if (this.state.type !== "") {
      tableComponentUrl += `/${this.state.type}`;
    }

    if (this.state.refetchData) {
      tableComponentUrl += "?refetch=" + this.state.refetchData;
    }
    // this.setTableColumns(tableColumns);
    return (
      <DocumentsLayout
        onChange={(startDate, endDate, search, page) => {
          this.setState({
            startDate: startDate,
            endDate: endDate,
            searchText: search,
            page: page,
          }, ()=>{
            this.changeUrl(startDate, endDate, search, page);
          });
        }}
        startDate={this.state.startDate}
        endDate={this.state.endDate}
        search={this.state.searchText}
        page={this.state.page}
        perPage={this.state.perPage}
        isMobile={isMobile()}
        title={lang.get("costs")}
        type={DocTypes.cost}
        pagination={false}
        newDocLink="#"
        newDocClick={() => {
          this.setState({ editedModel: new Cost() });
        }}
        drawerChildren={this.renderDrawerChildren(classes)}
        match={this.props.match}
      >
        <Fragment>
          {this.renderCosts(classes,tableComponentUrl)}
        </Fragment>
      </DocumentsLayout>
    );
  }
}

export default withStyles(styles)(Costs);
