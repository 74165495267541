import {
    Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@material-ui/core";
import React, { useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { post, StatusEnum, UrlEnum } from "../Utils/Utils";
import Lang from "../lang";
import TableComponent from "../Components/TableComponent";
import { Cost } from "../Models/Models";
import FormSubmitButton from '../Forms/FormSubmitButton';
import theme from "../Theme/Theme";
const lang:any = Lang.getInstance();

type MonthlyCostsDialogProps = {
  open: boolean;
  onClose: (e: React.MouseEvent | any) => void;
  submitCallback:(model:Cost | null) => void;
  showSmallMessage: Function, 
};

var selectedCosts:Array<Cost> = [];

function MonthlyCostsDialog(props: MonthlyCostsDialogProps) {
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);

  let tableColumns = [
    { label: lang.get("description"), name: "description" },
    { label: lang.get("supplier"), name: "supplierName",
      customRender:(model:any)=>{ return model.supplier? model.supplier.name : " "}
    },
    { label: lang.get("amount"), name: "amount",
      customRender:(model:any, context:any, models:Array<Cost>)=>{
        return <TextField
          name="amount"
          value={model.amount?model.amount: 0}
          onChange={(e)=>{
            var modelsCopy = models.slice();
            var editedModel = modelsCopy.find((m:any)=>m.id === model.id);
            if(editedModel){
              editedModel.amount = parseFloat(e.target.value);
              context.setState({models:modelsCopy});
            }
            let t = 0;
            for(let c of modelsCopy){
              t += c.amount; 
            }
            setTotal(t);
          }}
        />
      }
    },
  ];


  function getSelectedCosts(costs:Array<Cost>){
    selectedCosts = costs;
  }

  async function submit(){
    setLoading(true);
    if(selectedCosts.length === 0){
      props.showSmallMessage(lang.get("selectError"), StatusEnum.ERROR);
      setLoading(false);
      return;
    }
    const url = UrlEnum.folderCosts+"/monthly";
    const response = await post(url, {costs:selectedCosts});
    // handler errors
    if(response.errors){
      setErrors(response.errors instanceof Array ? 
      response.errors.join("<br/>") : response.errors);
      setLoading(false);
      return;
    }
    setLoading(false);
    for(const cost of response){
      props.submitCallback(cost);
    }
    props.submitCallback(null);
    selectedCosts=[];
    props.onClose(null);
  }

 
  return (
    <Dialog 
        open={props.open}
        onClose={(e) => props.onClose(e)}
    >
      <DialogTitle>
        {lang.get("costManagement")} <em>({lang.get("monthly")})</em>
        <IconButton onClick={props.onClose} style={{float:"right"}}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TableComponent
          url={UrlEnum.folderCosts + "/monthly"}
          columns={tableColumns}
          search={false}
          options={{edit:false}}
          select={true}
          onSelect={getSelectedCosts}
        />
      </DialogContent>
      <DialogActions>
          <h3 style={{position:"absolute", left:30}}>
            {lang.get("total")}: <strong>{total}</strong>
          </h3>
          <FormSubmitButton 
            loading={loading}
            errors={errors}
            onClick={submit}
          >
              {lang.get("add")}
          </FormSubmitButton>
          <Button variant="contained"
            style={{background:theme.palette.errorColor?.main,color: theme.palette.textColorSecondary?.main, }}
            onClick={props.onClose}
          >
              {lang.get("close")}
          </Button>
      </DialogActions>
    </Dialog>
  );
}

export default MonthlyCostsDialog;
