import React, { useState, Fragment, useEffect } from "react";
import Lang from "../lang";
import { UrlEnum, get, post, httpDelete } from "../Utils/Utils";
import {
  CircularProgress,
  Grid,
  AppBar,
  useTheme,
  makeStyles,
  Paper,
  Button,
  Toolbar,
  Collapse,
  Tooltip,
  Backdrop,
} from "@material-ui/core";
import ServicesExpandableList from "../Components/ServicesExpandableList";
import HierarchicalList from "../Components/HierarchicalList";
import CachedDataSingleton from "../cachedDataSingleton";
import MessageDialog from "../Components/MessageDialog";
import RedoIcon from "@material-ui/icons/Redo";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import AddIcon from "@material-ui/icons/Add";
import { Service } from "../Models/Models";
import { ResolutionBreakPoints } from "../Utils/Utils";
import DeleteIconBtn from "../Components/DeleteIconBtn";
import SearchBar from "../Components/SearchBar";
import Packages from "./Packages";
import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";
import ConfirmDialog from "../Components/ConfirmDialog";
import NewCategory from "../Components/NewCategory";
import { Category } from "../Models/Models";
import TabPanel from "../Components/TabPanel";
import EuroIcon from "@material-ui/icons/Euro";
import ChangeAllServicesPricesDialog from "../Components/ChangeAllServicesPricesDialog";

const useStyles = makeStyles((theme) => ({
  loading: {
    margin: "150px auto 0 auto",
    display: "block",
  },
  container: {
    padding: 10,
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  title: {
    flexGrow: 1,
  },
  servicesToolBar: {
    minHeight: 50,
    [theme.breakpoints.down("sm")]: {
      "& .MuiButton-label": {
        fontSize: 0,
      },
    },
  },
  fullHeight: {
    height: "calc(100vh - 140px )",
    overflowY: "scroll",
  },
  categories:{
    padding:"10px",
  }
}));

const lang = Lang.getInstance();
const docWidth = document.body.offsetWidth;
const isMobile = docWidth < ResolutionBreakPoints.md;

let lastSelectedCategory = null;
let allServices = [];
let deleteModel = null;

/**
 * Services component
 * @param {*} props
 */
export default function Services(props) {
  const classes = useStyles();
  const theme = useTheme();

  const [loading, setLoading] = useState(false);
  const [buttonsLoading, setButtonsLoading] = useState(false);
  const [services, setServices] = useState([]);
  const [tabValue, setTabValue] = React.useState(0);
  const [message, setMessage] = useState(null);
  const cachedData = CachedDataSingleton.getInstance();
  const [activeCategory, setActiveCategory] = useState(null);
  const [categories, setCategories] = useState(
    cachedData.get("serviceCategories")
  );
  const [categoriesCollapsed, setCategoriesCollapsed] = useState(!isMobile);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [editCategory, setEditCategory] = useState(null);
  const [openChangeAllPrices, setOpenchangeAllPrices] = useState(false);
  const [packagesVisible, setPackagesVisible] = useState(false);
  const [selectedServices, setSelectedServices] = useState([]);

  let addNewPackageFct = null;
  let addSelectedFct = null;

  /**
   * change Tab Value
   * @param {SyntheticEvent} event
   * @param {*} newValue
   */
  const handleChangeTabValue = (event, newValue) => {
    if (tabValue !== newValue) {
      setTabValue(newValue);
      setCategoriesCollapsed(true);
      return;
    }
    setCategoriesCollapsed(!categoriesCollapsed);
  };

  /**
   * click on the bar, but not on the tabs
   * @param {*} event
   */
  const handleOutsideTabClick = (event) => {
    if (
      isMobile &&
      event.target.className !== "MuiTab-wrapper" &&
      event.target.className !== "MuiButton-label"
    ) {
      setCategoriesCollapsed(!categoriesCollapsed);
    }
  };

  /**
   * get all services from a category on click
   * @param {SyntheticEvent} e
   * @param {Object} model
   */
  const categoryClick = (e, category) => {
    setActiveCategory(category);
    if (isMobile) {
      setCategoriesCollapsed(false);
    }
    const url = UrlEnum.serviceCategories + "/" + category.id + "/" + 1;
    e.preventDefault();
    setLoading(true);

    get(url).then((response) => {
      if (response.errors) {
        setMessage(response.errors);
        setLoading(false);
        return false;
      }

      // for each service set totalServicePrice
      for (let s of response) {
        s.totalServicePrice = Service.calculateTotalServicePrice(s);
      }
      allServices = response;
      setServices(response);
      setLoading(false);
    });
    return false;
  };

  /**
   * remove category from list
   */
  const deleteCategory = (categories, categId) => {
    for (let i = 0; i < categories.length; i++) {
      if (categories[i].id === categId) {
        categories.splice(i, 1);
        return;
      }
      if (
        categories[i].children_categ &&
        categories[i].children_categ.length > 0
      ) {
        deleteCategory(categories[i].children_categ, categId);
      }
    }
  };

  /**
   * delete category click
   */
  const categoryDelete = (e, model) => {
    setOpenDeleteConfirm(true);
    deleteModel = model;
  };

  const categoryEdit = (e, model) => {
    setEditCategory(model);
  };

  const onConfirmResult = (confirm) => {
    setOpenDeleteConfirm(false);
    if (!confirm || !deleteModel) return;

    httpDelete(UrlEnum.categories + "/" + deleteModel.id)
      .then((response) => {
        if (!response.errors) {
          let catgsNew = categories.slice();
          deleteCategory(catgsNew, deleteModel.id);
          setCategories(catgsNew);
        }
      })
      .catch(() => {});
  };

  /**
   * add new category in list
   */
  function addOrEditNewCategory(category) {
    const categs = categories.slice();
    const parentCateg = findCateg(categs, category.fk_ParentCategId);
    if (parentCateg) {
      if (!parentCateg.children_categ) {
        parentCateg.children_categ = [];
      }
      const index = parentCateg.children_categ.findIndex(
        (cc) => cc.id === category.id
      );
      if (index >= 0) {
        parentCateg.children_categ.splice(index, 1, category);
      } else {
        parentCateg.children_categ.push(category);
      }
    } else {
      const index = categs.findIndex((c) => c.id === category.id);
      if (index >= 0) {
        categs.splice(index, 1, category);
      } else {
        categs.push(category);
      }
    }
    setCategories(categs);
    cachedData.setKey("serviceCategories", categs);
  }

  /**
   *
   * @param {*} categs
   * @param {*} id
   */
  function findCateg(categs, id) {
    if (categs && categs.length > 0) {
      for (let categ of categs) {
        if (categ.id === id) {
          return categ;
        }
        if (categ.children_categ) {
          const c = findCateg(categ.children_categ, id);
          if (c) return c;
        }
      }
    }
  }

  /**
   * find categ by callback
   */
  function findCategBy(categs, callback) {
    let results = [];
    const f = function (categs, callback) {
      if (categs && categs.length > 0) {
        for (let categ of categs) {
          if (callback(categ)) {
            results.push(categ);
          }
          if (categ.children_categ) {
            f(categ.children_categ, callback);
          }
        }
      }
    };
    f(categs, callback);
    return results;
  }

  /**
   * add new Service
   * @param {SyntheticEvent} e
   */
  const addNewService = (e) => {
    e.preventDefault();
    let ns = services.slice();
    let s = new Service();
    s.fk_CategoryId = activeCategory ? activeCategory.id : null;
    // open by default new service
    ns.unshift(s);
    setServices(ns);
    return false;
  };

  /**
   * duplicate services
   * @param {SyntheticEvent} e
   */
  const duplicateService = (e) => {
    e.preventDefault();

    setButtonsLoading(true);
    // get selected services
    let selecteIds = selectedServices.map((el) => el.id);
    let url = UrlEnum.services + "/duplicate";
    post(url, { ids: selecteIds }).then((response) => {
      if (response.errors) {
        setMessage(
          response.errors instanceof Array
            ? response.errors.join(",")
            : response.errors
        );
        return;
      }
      let newServices = services.slice();
      newServices.unshift(...response);
      setButtonsLoading(false);
      setServices(newServices);
    });
    return false;
  };

  /**
   * change category selection
   * @param {SyntheticEvent} e
   * @param {Object} category
   */
  const categorySelect = (e, category) => {
    let categs = categories.slice();
    let categ = findCateg(categs, category.id);
    categ.selected = categ.selected ? false : true;
    lastSelectedCategory = categ.selected ? categ : null;
    setCategories(categs);
  };
  useEffect(( )=>{
    categoryClick(new MouseEvent("click"),categories[0]);
},[]);
  /**
   * move services to selected category
   * @param {*} e
   */
  const moveServices = (e) => {
    // check if there is a selected category
    if (!lastSelectedCategory) {
      const selected = findCategBy(categories, (categ) => categ.selected);
      if (selected.length === 1) {
        lastSelectedCategory = selected[0];
      } else {
        //if not show warning message
        setMessage(
          lang.get("selectParentCategory") + " (" + lang.get("onlyOne") + ")"
        );
        return;
      }
    }

    //else check if there are selected services
    if (selectedServices.length === 0) {
      setMessage(`${lang.get("pleaseSelect")} ${lang.get("services")}`);
      return;
    }


    setButtonsLoading(true);
    //send request to server to move
    let selecteIds = selectedServices.map((el) => el.id);
    let url = UrlEnum.services + "/move/" + lastSelectedCategory.id;
    post(url, { ids: selecteIds }).then((response) => {
      if (response.errors) {
        setMessage(
          response.errors instanceof Array
            ? response.errors.join(",")
            : response.errors
        );
        return;
      }

      let newServices = services.slice();
      // remove from current services, including search
      removeSelectedServices(newServices);

      //remove from all, to show when there is no search
      removeSelectedServices(allServices);
      setServices(newServices);
      setButtonsLoading(false);
    });
  };

  /**
   * remove selected on delete success
   * @param {*} data
   * @param {*} response
   */
  const deleteSuccess = (data, response) => {
    let newServices = services.slice();
    // remove from current services, including search
    removeSelectedServices(newServices);

    //remove from all, to show when there is no search
    removeSelectedServices(allServices);
    setServices(newServices);
  };

  /**
   * removes selected services from an NEW array of services
   */
  const removeSelectedServices = (services) => {
    // remove from current category
    for (const service of selectedServices) {
      const index = services.indexOf(service);
      services.splice(index, 1);
    }
    return services;
  };

  /**
   *
   * @param {*} e
   */
  const onSearchChange = (e) => {
    const val = e.target.value;
    if (val.length < 3) {
      if (services.length < allServices.length) {
        setServices(allServices);
      }
      return;
    }
    setTimeout(() => {
      const regex = new RegExp(`.*${val}.*`, "gi");
      const newServices = allServices.filter((el) => el.name.match(regex));
      setServices(newServices);
    }, 0);
  };

  function addNewPackage() {
    if (addNewPackageFct) {
      addNewPackageFct();
    }
  }

  function addServicesToPackage() {
    if (addSelectedFct) {
      let result = addSelectedFct(selectedServices);
      if (result === false) {
        setMessage(lang.get("pleaseSelectServicesAndPackage"));
      } else {
        // deselect
        let newServices = services.slice();
        newServices.forEach((service) => (service.selected = false));
        setServices(newServices);
      }
    }
  }

  /**
   * render method
   */
  return (
    <div className={classes.container}>
      <Grid container>
        <Grid item xs={12} sm={12} md={packagesVisible ? 4 : 6} style={{padding:"15px"}}>
          <Paper>
            <AppBar position="static" onClick={handleOutsideTabClick} style={{background:theme.palette.header?.main,color:theme.palette.textColorSecondary?.main}}>
              <Toolbar className={classes.servicesToolBar}>
                <h4 className={classes.title}>
                  {lang.get("categories").toUpperCase()}
                </h4>
                <Fragment>
                  <Button
                    color="inherit"
                    label={lang.get("packages")}
                    onClick={() => setPackagesVisible(!packagesVisible)}
                  >
                    {lang.get("packages")}
                  </Button>
                  <Tooltip
                    title={lang.get("new") + ": " + lang.get("category")}
                    aria-label="add category"
                  >
                    <Button
                      color="inherit"
                      onClick={() => setEditCategory(Category())}
                    >
                      <AddIcon /> &nbsp;{lang.get("category")}
                    </Button>
                  </Tooltip>
                  <Tooltip
                    title={lang.get("changeAllServicePrices")}
                    aria-label="change all service prices"
                  >
                    <Button
                      color="inherit"
                      onClick={() => setOpenchangeAllPrices(true)}
                    >
                      <EuroIcon /> &nbsp;{lang.get("modifyPrices")}
                    </Button>
                  </Tooltip>
                </Fragment>
              </Toolbar>
            </AppBar>
            <Collapse in={categoriesCollapsed}>
              <TabPanel
                value={tabValue}
                index={0}
                className={classes.fullHeight}
              >
                <HierarchicalList
                  models={categories}
                  childrenLabel="children_categ"
                  foreignKey="fk_ParentCategId"
                  onClick={categoryClick}
                  onDelete={categoryDelete}
                  onEdit={categoryEdit}
                  onSelect={categorySelect}
                />
              </TabPanel>
            </Collapse>
          </Paper>
        </Grid>

        {/** packages */}
        {packagesVisible ? (
          <Grid item xs={12} sm={12} md={4} style={{padding:"15px"}}>
            <Paper>
              <AppBar position="static"style={{background:theme.palette.header?.main,color:theme.palette.textColorSecondary?.main}}>
                <Toolbar className={classes.servicesToolBar}>
                  <h4 className={classes.title}>
                    {lang.get("packages").toUpperCase()}
                  </h4>
                  <Fragment>
                    <Tooltip
                      title={lang.get("new") + ": " + lang.get("package")}
                      aria-label="add package"
                    >
                      <Button color="inherit" onClick={addNewPackage}>
                        <AddIcon /> &nbsp;{lang.get("new")}
                      </Button>
                    </Tooltip>
                    <Tooltip
                      title={lang.get("addServicesInSelectedPackage")}
                      aria-label="add services in selected package"
                    >
                      <Button
                        disabled={services.length === 0 || buttonsLoading}
                        color="inherit"
                        onClick={addServicesToPackage}
                      >
                        <AddToPhotosIcon /> &nbsp;{lang.get("addSelected")}
                      </Button>
                    </Tooltip>
                  </Fragment>
                </Toolbar>
              </AppBar>
              <div className={classes.fullHeight}>
                <Packages
                  addNewPackage={(fct) => (addNewPackageFct = fct)}
                  addSelected={(fct) => (addSelectedFct = fct)}
                />
              </div>
            </Paper>
          </Grid>
        ) : null}

        <Grid item xs={12} sm={12} md={packagesVisible ? 4 : 6} style={{padding:"15px"}}>
          <Paper>
            <AppBar position="static" style={{background:theme.palette.header?.main, color:theme.palette.textColorSecondary?.main}}>
              <Toolbar className={classes.servicesToolBar}>
                <h4 className={classes.title}>
                  {lang.get("services").toUpperCase()}
                </h4>
                {buttonsLoading ? <CircularProgress color="secondary" /> : ""}
                <SearchBar
                  disabled={services.length === 0 || buttonsLoading}
                  dropdown
                  onChange={onSearchChange}
                  onSubmit={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                />

                <Tooltip
                  title={lang.get("new") + ": " + lang.get("service")}
                  aria-label="add service"
                >
                  <div>
                    <Button
                      disabled={buttonsLoading}
                      color="inherit"
                      onClick={addNewService}
                    >
                      <AddIcon /> &nbsp;{lang.get("new")}
                    </Button>
                  </div>
                </Tooltip>
                <Tooltip
                  title={lang.get("duplicate") + ": " + lang.get("service")}
                  aria-label="duplicate service"
                >
                  <div>
                    <Button
                      disabled={
                        !selectedServices.length ||
                        !services.length ||
                        buttonsLoading
                      }
                      color="inherit"
                      onClick={duplicateService}
                    >
                      <FileCopyIcon /> &nbsp;{lang.get("duplicate")}
                    </Button>
                  </div>
                </Tooltip>
                <Tooltip
                  title={lang.get("moveServicesInNewCategory")}
                  aria-label="move service in category"
                >
                  <div>
                    <Button
                      disabled={
                        !selectedServices.length ||
                        !services.length ||
                        buttonsLoading
                      }
                      color="inherit"
                      onClick={moveServices}
                    >
                      <RedoIcon /> &nbsp;{lang.get("move")}
                    </Button>
                  </div>
                </Tooltip>
                <Tooltip title={lang.get("delete")} aria-label="delete">
                  <div>
                    <DeleteIconBtn
                      disabled={
                        !selectedServices.length ||
                        !services.length ||
                        buttonsLoading
                      }
                      url={UrlEnum.services}
                      onSuccess={deleteSuccess}
                      data={() => {
                        return { ids: selectedServices.map((el) => el.id) };
                      }}
                    ></DeleteIconBtn>
                  </div>
                </Tooltip>
              </Toolbar>
            </AppBar>
            <div className={classes.fullHeight}>
              {loading ? (
                <CircularProgress
                  color="secondary"
                  className={classes.loading}
                />
              ) : (
                <ServicesExpandableList
                  getSelected={(selected) => {
                    setSelectedServices(selected);
                  }}
                  services={services}
                  setServices={setServices}
                />
              )}
            </div>
          </Paper>
        </Grid>
        <MessageDialog
          open={message ? true : false}
          text={message || ""}
          onResult={() => setMessage(null)}
        />
        <ConfirmDialog
          open={openDeleteConfirm}
          text={lang.get("deleteItem")}
          onResult={onConfirmResult}
        />
        {editCategory != null ? (
          <NewCategory
            open={editCategory != null}
            model={editCategory}
            url={UrlEnum.categories}
            onClose={() => {
              setEditCategory(null);
            }}
            categories={categories}
            submitCallback={addOrEditNewCategory}
            findCateg={findCateg}
          />
        ) : null}
      </Grid>
      <ChangeAllServicesPricesDialog
        open={openChangeAllPrices}
        onClose={() => setOpenchangeAllPrices(false)}
      />
    </div>
  );
}
