import React from "react";
import { ResponsiveBar } from "@nivo/bar";
import Lang from "../../../lang";
import CachedDataSingleton from "../../../cachedDataSingleton";
import { Grid, InputLabel } from "@material-ui/core";
import { graphColors, monthsName } from "../../../Utils/Utils";
import WithChartsFilters from "./WithChartsFilters";

type OverviewEstimatesOrdersTicketsProps = {
  overview: any;
  startMonth: number;
  endMonth: number;
  selectedStartYear: number;
  selectedEndYear: number;
};

function OverviewEstimatesOrdersTickets(
  props: OverviewEstimatesOrdersTicketsProps
) {
  const lang = Lang.getInstance();
  const cachedData = CachedDataSingleton.getInstance();
  const overview = props.overview ? props.overview : cachedData.get("overview");
  const estimates = overview.estimates;
  const orderTickets = overview.orderTickets;

  /**
   *
   * @returns Returns an array with the data formatted for a Bar chart
   */
  function formatChartData() {
    let graphData = [];
    let index = props.startMonth ? props.startMonth : 0;
    const estimatesLength =
      12 * (props.selectedEndYear - props.selectedStartYear) +
      props.endMonth +
      1;

    for (index; index < estimatesLength; index++) {
      const coords = {
        month:
          monthsName()[
            index < 12 ? index : index < 24 ? index - 12 : index % 12
          ] +
          " " +
          (index < 12
            ? props.selectedStartYear
            : props.selectedStartYear + Math.floor(index / 12)),
        estimates:
          estimates.months && estimates.months[index]
            ? estimates.months[index]
            : null,
        estimatesColor: graphColors().colorsTransparent.estimates,
        orderTicket:
          orderTickets && orderTickets.months && orderTickets.months[index]
            ? orderTickets.months[index]
            : null,
        orderTicketColor: graphColors().colorsTransparent.orderTickets
      };
      graphData.push(coords);
    }
    return graphData;
  }

  const chartData = formatChartData();
  return (
    <>
      <InputLabel
        style={{
          color: "#000",
          marginBottom: 5,
          fontSize: 15,
          justifyContent: "center",
          alignContent: "center",
          display: "flex"
        }}
      >
        {lang.get("overview") +
          " - " +
          lang.get("estimates") +
          ", " +
          lang.get("orderTicket")}
      </InputLabel>
      <Grid container spacing={3}>
        <Grid item md={6} sm={12} xs={12} style={{ minWidth: "100%" }}>
          {/** CHART */}
          <div style={{ height: 300, marginBottom: 10 }}>
            <ResponsiveBar
              data={chartData}
              colors={[
                graphColors().colorsTransparent.estimates,
                graphColors().colorsTransparent.orderTickets
              ]}
              keys={["estimates", "orderTicket"]}
              indexBy="month"
              margin={{ top: 10, right: 110, bottom: 100, left: 70 }}
              groupMode="grouped"
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickRotation: 45,
                legend: lang.get("date"),
                legendOffset: 90,
                legendPosition: "middle"
              }}
              labelTextColor="rgb(255,255,255,0)"
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: lang.get("amount"),
                legendOffset: -60,
                legendPosition: "middle"
              }}
              legends={[
                {
                  dataFrom: "keys",
                  anchor: "bottom-right",
                  direction: "column",
                  justify: false,
                  translateX: 120,
                  translateY: 0,
                  itemsSpacing: 2,
                  itemWidth: 100,
                  itemHeight: 20,
                  itemDirection: "left-to-right",
                  itemOpacity: 0.85,
                  symbolSize: 20,
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemOpacity: 1
                      }
                    }
                  ]
                }
              ]}
            ></ResponsiveBar>
          </div>
        </Grid>
      </Grid>
    </>
  );
}
export default WithChartsFilters(OverviewEstimatesOrdersTickets,"orderTickets");
