import React from "react";
import { SvgIcon } from "@material-ui/core";

export default function IconProjects(props)
{
    return(
    <SvgIcon viewBox="0 0 25 25" style={{ width: props.width ? props.width:37, height: props.height?props.height:37, marginTop:"3px"}}>
	    <path fill={props.color} id="Path 0" fillRule="evenodd" d="M10 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2h-8l-2-2z"/>
    </SvgIcon>
    );
}
