import React, { PureComponent } from 'react';
import { withStyles, Theme, createStyles, CircularProgress, Button, Tooltip } from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Lang from '../lang';
import { handleInputChange, post, StatusEnum, UrlEnum } from '../Utils/Utils';
import Alert from '@material-ui/lab/Alert';
import ChatIcon from '@material-ui/icons/Chat';
import theme from "../Theme/Theme";
const lang = Lang.getInstance();

type EmailFormProps = {
	to: string;
	subject: string;
	message: string;
	docAttachmentPath: string;
	classes?: any;
	docType?: string;
	docId?: number;
	onEmailSent: Function;
	setSmallMessage : Function;
};

type EmailFormState = {
	to: string;
	subject: string;
	message: string;
	loading: boolean;
	success: any;
};

class EmailForm extends PureComponent<EmailFormProps, EmailFormState> {
	contentRef:any;

	constructor(props: EmailFormProps) {
		super(props);
		this.state = {
			to: props.to || "",
			subject: props.subject || "",
			message: props.message || "",
			loading: false,
			success: null
		};
		this.contentRef = React.createRef();
	}

	/**
	 * 
	 */
	sendEmail() {
		this.setState({loading: true});
		post(UrlEnum.sendEmail,{
			to: this.state.to,
			subject: this.state.subject,
			message: this.state.message,
			attachment:	this.props.docAttachmentPath,
			docType:	this.props.docType,
			docId:	this.props.docId
		}).then((response)=>{
			if(response.errors){
				this.setState({loading: false, success: false});
			}else{
				this.setState({loading: false, success: true});
			}
			// call callback after a timeout
			setTimeout(this.props.onEmailSent, 3000);
		});
	}

	/**
	 * 
	 */
	handleChange(event: React.ChangeEvent<HTMLInputElement>) {
		const newState: EmailFormState = handleInputChange(event, this.state);
		this.setState(newState);
	}

	/**
	 * 
	 * @param event 
	 */
	handleBlurContentEditable(event: React.FocusEvent<HTMLInputElement>){
		const newMessage = event.currentTarget.innerHTML;
		this.setState({message: newMessage});
	}

	async saveMessageAsTemplate(){
		if(!this.contentRef.current) return false;
		const content = this.contentRef.current.innerHTML;
		// TODO
		const url = UrlEnum.documentTemplates+"/signature/"+this.props.docType+"EmailSignature";
		const response = await post(url, {template:content});
		if(response.errors){
			this.props.setSmallMessage(response.errors, StatusEnum.ERROR);
			return;
		}
		this.props.setSmallMessage(lang.get("saved"));
	}

	/**
	 * 
	 */
	render() {
		const attachmentName = this.props.docAttachmentPath.substring(this.props.docAttachmentPath.lastIndexOf("/")+1);
		const classes = this.props.classes;
		
		let success;
		if(this.state.success !== null){
			success = (
				<Alert style={{float: 'right'}} severity={this.state.success ? "success" : "error"} className={classes.alert}>
					{this.state.success ? lang.get("success") : lang.get("error")}
				</Alert>
			);
		}

		return (
			<div className={classes.wrapper}>
				<ValidatorForm onSubmit={this.sendEmail.bind(this)}>
					<TextValidator
						required={true}
						fullWidth
						id="to"
						label={lang.get('to')}
						onChange={this.handleChange.bind(this)}
						name="to"
						value={this.state.to}
						validators={['required']}
						errorMessages={[lang.get('fieldRequired')]}
                    />
                    <TextValidator
						required={true}
						id="subject"
						fullWidth
						label={lang.get('subject')}
						onChange={this.handleChange.bind(this)}
						name="subject"
						value={this.state.subject}
						validators={['required']}
						errorMessages={[lang.get('fieldRequired')]}
                    />
					<div 
						id="message" 
						ref={this.contentRef}
						className={classes.message} 
						contentEditable={true} 
						dangerouslySetInnerHTML={{__html: this.state.message}} 
						onBlur={this.handleBlurContentEditable.bind(this)}>
					</div>
					<span>
						{lang.get("attachment")}: &nbsp;
						<a 
							target="_blank"
							rel="noopener noreferrer"
							className="link"
							href={this.props.docAttachmentPath} 
							title={this.props.subject}
						>
							{attachmentName}
						</a>
					</span>
					<br/><br/>
					<Tooltip title={lang.get("saveMessageAsTemplate")} aria-label="save message">
						<Button
							variant="outlined"
							onClick={this.saveMessageAsTemplate.bind(this)}
						>
							<ChatIcon/>
							{`${lang.get("save")} ${lang.get("message")}`}
						</Button>
					</Tooltip>
					{success}

					<Button
						classes={{root:classes.sendBtn}}
						style={{color:theme.palette.header?.main}}
						variant="contained"
						type="submit">
						{lang.get("send")}
						{this.state.loading ?
							<CircularProgress color="secondary" classes={{root:classes.loading}} />
							: ""
						}
					</Button>
				</ValidatorForm>
			</div>
		);
	}
}


const styles = (theme: Theme) => createStyles({
	wrapper:{
		position: 'absolute',
		width:"100%",
		height:460,
		bottom:0,
		left:0,
		padding: 20,
		background:"#f0f0f0",
	[theme.breakpoints.down('md')]:{
			height:"100%",
			boxShadow:"none"
		}
	},
	loading: {
        width: "20px !important",
        height: "20px !important",
        marginLeft: 10
	},
	message:{
		height:180,
		overflowY: "scroll",
		background:"rgb(255,255,255,0.7)",
		padding:10,
		marginTop:10,
		marginBottom:10,
		[theme.breakpoints.down('md')]:{
			maxHeight: 250,
		}
	},
	sendBtn:{
		float:"right"
	},
	alert: {
        padding:4
    },
	
});

export default withStyles(styles,{ withTheme: true})(EmailForm);
