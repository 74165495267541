import React from 'react';
import TableComponent from '../Components/TableComponent';
import {useHistory, useRouteMatch} from 'react-router-dom';


export default function ClientsTable(props){

    const routeMatch = useRouteMatch();
    const history = useHistory();

    const onEdit = (model)=>{
        if(props.openDrawer){
            props.openDrawer(model);
        }
    };

    return (
        <TableComponent {...props} history={history} routeMatch={routeMatch} editCallback={onEdit} />
    );
    
}

