import * as React from 'react';
import { withStyles, FormControl } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
// import { Redirect } from 'react-router';
import Lang from '../../lang';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { WizardModel } from '../../Models/Models';

let lang = Lang.getInstance();

class Bank extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            model: WizardModel
        };
    };

    formRef = React.createRef();

    handleChange = (event) => {
        const wizardModel = this.state.model;
        wizardModel[event.target.id] = event.target.value;
        this.setState({ "model": wizardModel });
    }

    validate () {
        let formRef = this.formRef.current;
        return formRef.isFormValid(false);
    }

    render () {
        const classes = this.props.classes;
        return (
            <div className={classes.container}>
                <Paper className={classes.paper}>
                    <h2 className={classes.container}>{lang.get("bank")}</h2>
                    <ValidatorForm ref={this.formRef} instantValidate={false} onSubmit={() => { }}>
                        <FormControl required={true} fullWidth={true} className={classes.field}>
                            <TextValidator
                                fullWidth
                                required={true}
                                id="bankName"
                                name="bankName"
                                label={lang.get("bankName")}
                                onChange={this.handleChange}
                                validators={['required']}
                                value={this.state.model.bankName}
                                errorMessages={lang.get("fieldRequired")}
                            />
                        </FormControl>
                        <FormControl required={true} fullWidth={true} className={classes.field}>
                            <TextValidator
                                fullWidth
                                required={true}
                                id="bankAccount"
                                name="bankAccount"
                                label={lang.get("bankAccount")}
                                onChange={this.handleChange}
                                validators={['required']}
                                value={this.state.model.bankAccount}
                                errorMessages={lang.get("fieldRequired")}
                            />
                        </FormControl>
                        <FormControl required={true} fullWidth={true} className={classes.field}>
                            <TextValidator
                                fullWidth
                                required={true}
                                id="bankBic"
                                name="bankBic"
                                label={lang.get("bankBic")}
                                onChange={this.handleChange}
                                validators={['required']}
                                value={this.state.model.bankBic}
                                errorMessages={lang.get("fieldRequired")}
                            />
                        </FormControl>
                    </ValidatorForm>
                </Paper>
            </div>

        );
    }

}

const styles = (theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        flexGrow: 1,
        overflow: 'hidden',
        backgroundSize: 'cover',
        backgroundPosition: '0 400px',


    },
    paper: theme.mixins.gutters({
        paddingTop: 2,
        paddingBottom: 20,
        width: '80%',
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    }),
    field: {
        marginTop: 0,
    },
    actions: theme.mixins.gutters({
        paddingTop: 16,
        paddingBottom: 16,
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'center'
    }),
    button: {
        marginRight: 100,

    },
});

export default withStyles(styles, { withTheme: true })(Bank);

