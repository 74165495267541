import React, { Component, Fragment } from "react";
import { Invoice } from "../Models/Models";
import InvoicesContext from "../Contexts/InvoicesContext";
import {
  httpDelete,
  isMobile,
  renderFullPage,
  StatusEnum,
  Statuses,
  UrlEnum,
} from "../Utils/Utils";
import { get } from "../Utils/Utils";
import InvoiceList from "./InvoiceList";
import Lang from "../lang";
import ConfirmDialog from "../Components/ConfirmDialog";
import NoDataFound from "../Components/NoDataFound";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import { Button } from "@material-ui/core";
import PreviewPDF from "./PreviewPDF";
import ReactDOMServer from "react-dom/server";
import { BrowserRouter } from "react-router-dom";
import theme from '../Theme/Theme';
import {
  ServerStyleSheets,
  ThemeProvider,
} from "@material-ui/core/styles";

type InvoiceListCompleteProps = {
  invoices: Invoice[] | null;
  showSmallMessage: Function;
  type: string;
  classes?: { header?: {}; extensionPanel?: {} };
  select?: boolean;
  isProject?: boolean;
  isDashboard?:boolean;
};

type InvoiceListCompleteState = {
  invoices: Invoice[] | null;
  openDeleteConfirm: boolean;
  html: string | null;
};
class InvoiceListComplete extends Component<
  InvoiceListCompleteProps,
  InvoiceListCompleteState
> {
  deleteModel: Invoice | null;
  lang = Lang.getInstance();
  sheets = new ServerStyleSheets();

  constructor(props: InvoiceListCompleteProps) {
    super(props);
    this.state = {
      invoices: this.props.invoices,
      openDeleteConfirm: false,
      html: null,
    };

    this.deleteModel = null;
  }

  componentDidUpdate(prev: any){
    if(this.props.invoices && this.props.invoices?.length !== 0)
    if(prev.invoices[0].type!== this.props.invoices[0].type)
        this.setState({invoices: this.props.invoices})
}
  /**
   *
   * @param invoice
   */
  async changeInvoiceState(invoice: Invoice) {
    if (!this.state.invoices) return;
    let newInvoices = this.state.invoices.slice();
    if (invoice.status !== null) {
      let nextState = invoice.status;
      if (invoice.status.id === Statuses.Active.id) {
        nextState = Statuses.Inactive;
      } else {
        nextState = Statuses.Active;
      }

      const url = `${UrlEnum.invoices}/changeState/${invoice.id}/${nextState.id}`;
      const response = await get(url);
      if (response.errors) {
        alert(response.errors);
        return;
      }

      let crtInvoice = newInvoices.find((i) => i.id === invoice.id);
      if (crtInvoice) {
        crtInvoice.status = nextState;
        this.setState({ invoices: newInvoices });
      }
    }
  }

  /**
   *
   * @param confirmResponse
   */
  async onConfirmDeleteResult(confirmResponse: boolean) {
    if (confirmResponse && this.deleteModel !== null) {
      const url = `${UrlEnum.invoices}/${this.deleteModel.id}`;
      const response = await httpDelete(url);
      if (response.errors) {
        let message =
          response.errors instanceof Array && response.errors.length > 0
            ? response.errors.join("<br/>")
            : response.errors;
        this.props.showSmallMessage(message, StatusEnum.ERROR);
        return;
      }
      let ni = this.state.invoices;
      if (!ni) return;
      const index = ni.indexOf(this.deleteModel);
      ni.splice(index, 1);
      this.setState({ openDeleteConfirm: false, invoices: ni });
      return;
    }
    this.setState({ openDeleteConfirm: false });
  }

  /**
   *
   * @param invoice
   */
  async duplicateInvoice(invoice: Invoice) {
    const url = `${UrlEnum.invoices}/duplicate/${invoice.id}/1`;
    const response = await get(url);
    if (response.errors) {
      let message =
        response.errors instanceof Array && response.errors.length > 0
          ? response.errors.join("<br/>")
          : response.errors;
      this.props.showSmallMessage(message, StatusEnum.ERROR);
      return;
    }

    let ni = this.state.invoices;
    if (!ni) return;
    response.payments = [];
    ni.unshift(response);
    this.setState({ invoices: ni });
  }
  /**
   *
   * get ids for generate pdf
   */

  async getIds() {
    if (this.state.invoices) {
      this.state.invoices.map((invoice) => {
        if (invoice.selected === true) 
            console.log();
      });
    }
  }
  /**
   *
   */
   selectAllInvoices(event: any){
    const crtInvoices = this.state.invoices?.slice() || [];
    if(crtInvoices){
      crtInvoices.forEach((invoice)=> {
         invoice.selected = event.target.checked;
      })
    }
    this.setState({invoices: crtInvoices});
   }
  renderInvoices() {
    return (
      <InvoicesContext.Provider
        value={{
          invoices: this.state.invoices,
          type: this.props.type,
          changeInvoiceState: (invoice: Invoice) => {
            this.changeInvoiceState(invoice);
          },
          onDelete: (invoice: Invoice) => {
            this.setState({ openDeleteConfirm: true });
            this.deleteModel = invoice;
          },
          onDuplicate: (invoice: Invoice) => {
            this.duplicateInvoice(invoice);
          },
          onSelect: (invoice: Invoice, selected: boolean) => {
            const newInvoices = this.state.invoices?.slice();
            if (newInvoices) {
              let crtInvoice = newInvoices.find((i) => i.id === invoice.id);
              if (crtInvoice) {
                crtInvoice.selected = selected;
                this.setState({ invoices: newInvoices });
                this.getIds();
              }
            }
          },
        }}
      >
        <InvoiceList
          isProject={this.props.isProject}
          isDashboard={this.props.isDashboard}
          select={this.props.select}
          invoices={this.state.invoices}
          isMobile={isMobile()}
          classesOverride={this.props.classes}
          selectAllInvoices={(e) =>this.selectAllInvoices(e)}
        />
      </InvoicesContext.Provider>
    );
  }

  /**
   *
   */
  renderInvoicesToString() {
    return (
      <ThemeProvider theme={theme}>
        <BrowserRouter>{this.renderInvoices()}</BrowserRouter>
      </ThemeProvider>
    );
  }

  /**
   * return the rendered elements as html
   */
  getHtml() {
    const elements = this.renderInvoicesToString();
    const htmlString = ReactDOMServer.renderToString(
      this.sheets.collect(elements)
    );
    const css = this.sheets.toString();
    this.setState({ html: renderFullPage(htmlString, css) });
  }

  /**
   *
   */
  render() {
    return (
      <Fragment>
        {this.state.invoices && this.state.invoices.length > 0 ? (
          <>
            {this.renderInvoices()}
            <Button onClick={() => this.getHtml()}>
              <SaveAltIcon /> {this.lang.get("export")}
            </Button>
            <PreviewPDF
              open={this.state.html !== null}
              onClose={() => this.setState({ html: null })}
              htmlContent={this.state.html as string}
              footerHtml={""}
              setSmallMessage={this.props.showSmallMessage}
              emailData={{ to: "", subject: "", message: "" }}
              url={UrlEnum.pdf.replace("{type}", "html").replace("{id}", "0")}
            />
          </>
        ) : (
          <NoDataFound />
        )}
        <ConfirmDialog
          open={this.state.openDeleteConfirm}
          text={this.lang.get("deleteItem")}
          onResult={this.onConfirmDeleteResult.bind(this)}
        />
      </Fragment>
    );
  }
}

export default InvoiceListComplete;
