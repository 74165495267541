import PermContactCalendarIcon from "@material-ui/icons/PermContactCalendar";
import SettingsIcon from "@material-ui/icons/Settings";
import TabUnselectedIcon from "@material-ui/icons/TabUnselected";
import PeopleIcon from "@material-ui/icons/People";
import { LocalUrlEnum, SubscriptionsEnum, UserRolesEnum, readCookie } from "./Utils";
import Lang from "../lang";
import InsertChartOutlined from "@material-ui/icons/InsertChartOutlined";
import CachedDataSingleton from "../cachedDataSingleton";
import theme from "../Theme/Theme";
import {
  IconArticles,
  IconClients,
  IconCosts,
  IconCreditNotes,
  IconEmployees,
  IconEstimates,
  IconInvoices,
  IconOrderTickets,
  IconProFormas,
  IconProjects,
  IconServices,
  IconSubcontractorOrderTickets,
  IconSubcontractors,
  IconTimeTraking} from "../Icons";
import IconProjectsOutline from "../Icons/IconProjectsOutlilne";

export function menuOptionFilteredByRole() {
  const lang = Lang.getInstance();
  let menuOptions:any = {};
  let documents: any={};
  let people: any={};
  let more: any={};
  const defaultWidth=25;
  const defaultHeight=25;
  const bigIconSize = {
    width:50,
    height:50
  };
  const allMenu = {
    PROJECTS: {
      id: "projects",
      link: LocalUrlEnum.projects,
      label: lang.active.folders,
      color: theme.palette.dashboard?.main,
      icon: <IconProjects
          color={theme.palette.iconDefault?.main
        }/>,
      iconDashboard: <IconProjectsOutline
      color="#fff" width={bigIconSize.width} height={bigIconSize.height}
    />,
    },
    DOCUMENTS: {
      invoices: {
        id: "invoices",
        color: theme.palette.dashboard?.main,
        link: LocalUrlEnum.invoices,
        label: lang.get("invoices"),
        icon: <IconInvoices 
        color={theme.palette.iconDefault?.main}
       />,
       iconDashboard: <IconInvoices
        color="#fff" width={bigIconSize.width} height={bigIconSize.height}
      />,
        buttonColor: "purpleBkg"
      },
      proformas: {
        id: "proformas",
        color: theme.palette.dashboard?.main,
        link: LocalUrlEnum.proformas,
        label: lang.get("proformas"),
        icon: <IconProFormas 
        color={theme.palette.iconDefault?.main}
        />,
        iconDashboard: <IconProFormas
        color="#fff" width={defaultWidth} height={defaultHeight}
      />,
        buttonColor: "purpleBkg"
      },
      creditNotes: {
        id: "creditNotes",
        color: theme.palette.dashboard?.main,
        link: LocalUrlEnum.creditNotes,
        label: lang.get("creditNotes"),
        icon: <IconCreditNotes 
          color={theme.palette.iconDefault?.main}
          />,
        iconDashboard: <IconCreditNotes
          color="#fff" width={defaultWidth} height={defaultHeight}
        />,
        buttonColor: "purpleBkg"
      },
      estimates: {
        id: "estimates",
        color: theme.palette.dashboard?.main,
        link: LocalUrlEnum.estimates,
        label: lang.get("estimates"),
        icon: <IconEstimates 
          color={theme.palette.iconDefault?.main}
        />,
        iconDashboard: <IconEstimates
          color="#fff" width={bigIconSize.width} height={bigIconSize.height}
        />,
        buttonColor: "orangeBkg"
      },
      orderTickets: {
        id: "orderTickets",
        color: theme.palette.dashboard?.main,
        link: LocalUrlEnum.orderTickets,
        label: lang.get("orderTickets"),
        icon: <IconOrderTickets color={theme.palette.iconDefault?.main}/>,
        iconDashboard: <IconOrderTickets
        color="#fff" width={defaultWidth} height={defaultHeight}
      />,
        buttonColor: "orangeBkg"
      },
      subcontractorOrderTickets: {
        id: "subcontractorordertickets",
        color: theme.palette.dashboard?.main,
        link: LocalUrlEnum.subcontractorordertickets,
        label: lang.get("subcontractorOrderTickets"),
        icon: <IconSubcontractorOrderTickets color={theme.palette.iconDefault?.main}/>,
        iconDashboard: <IconSubcontractorOrderTickets
        color="#fff" width={defaultWidth} height={defaultHeight}
      />,
        buttonColor: "orangeBkg"
      }
    },
    PEOPLE: {
      clients: {
        link: "/clients",
        color:theme.palette.dashboard?.main,
        label: lang.active.clients,
        icon: (
          <IconClients color = {theme.palette.iconBlue?.main} />
        ),
        iconDashboard: <IconClients
        color="#fff" width={defaultWidth} height={defaultHeight}
      />,
      },
      subcontractors: {
        link: "/subcontractors",
        color: theme.palette.dashboard?.main,
        label: lang.active.subcontractors,
        icon: (
          <IconSubcontractors
          color={theme.palette.iconBlue?.main}
          />
        ),
        iconDashboard: <IconSubcontractors
        color="#fff" width={defaultWidth} height={defaultHeight}
      />,
      },
      employees: {
        link: "/employees",
        color: theme.palette.dashboard?.main,
        label: lang.active.employees,
        icon: (
          <IconEmployees
            color={theme.palette.iconBlue?.main}
          />
        ),
        iconDashboard: <IconEmployees
        color="#fff" width={defaultWidth} height={defaultHeight}
      />,
      }
    },
    MORE: {
      services: {
        link: "/services",
        color: theme.palette.dashboard?.main,
        label: lang.active.services,
        icon: (
          <IconServices
          color={theme.palette.iconDefault?.main}
          />
        ),
        iconDashboard: <IconServices
        color="#fff" width={defaultWidth} height={defaultHeight}
      />,
      },
      articles: {
        link: "/articles",
        color: theme.palette.dashboard?.main,
        label: lang.active.articles,
        icon: (
          <IconArticles color = {theme.palette.iconDefault?.main} />
        ),
        iconDashboard: <IconArticles
        color="#fff" width={defaultWidth} height={defaultHeight}
      />,
      },
      costs: {
        link: "/costs",
        color: theme.palette.dashboard?.main,
        label: lang.active.costs,
        icon: (
          <IconCosts
          color={theme.palette.iconDefault?.main}
          />
        ),
        iconDashboard: <IconCosts
        color="#fff" width={defaultWidth} height={defaultHeight}
      />,
      },
      timeTracking: {
        link: "/timeTracking",
        color: theme.palette.dashboard?.main,
        label: lang.active.timekeeping,
        icon: (
          <IconTimeTraking
          color={theme.palette.iconDefault?.main}
          />
        ),
        iconDashboard: <IconTimeTraking
        color="#fff" width={defaultWidth} height={defaultHeight}
      />,
      }
    },
    OPTIONS: {
      options: {
        link: "/options",
        color: "#fff",
        label: lang.active.options,
        icon: <PermContactCalendarIcon fontSize="large" 
        style={{ verticalAlign: "middle",color: theme.palette.iconDefault?.main
      }} />
      },
      companyInfo: {
        link: "/companyInfo",
        color: "#fff",
        label: lang.active.companyInfo,
        icon: <SettingsIcon fontSize="large" 
        style={{ verticalAlign: "middle" ,color: theme.palette.iconDefault?.main
      }} />
      },
      documentTemplates: {
        link: "/documentTemplates",
        color: "#fff",
        label: lang.active.documentTemplates,
        icon: <TabUnselectedIcon fontSize="large" 
        style={{ verticalAlign: "middle",color: theme.palette.iconDefault?.main
      }} />
      },
      users: {
        link: LocalUrlEnum.users,
        color: "#fff",
        label: lang.active.companyUsers,
        icon: <PeopleIcon fontSize="large" 
        style={{ verticalAlign: "middle", color: theme.palette.iconDefault?.main
      }} />
      }
    },
    COMPANYOVERVIEW: {
      companyOverview: {
        link: "/companyoverview",
        color: theme.palette.textColor?.main,
        label: lang.active.companyOverview,
        icon: <InsertChartOutlined fontSize="large" 
        style={{ verticalAlign: "middle",color: theme.palette.iconDefault?.main
      }} />,
      iconDashboard:<InsertChartOutlined 
      style={{ verticalAlign: "middle",color: theme.palette.iconDefault?.main, fontSize:"32px",
    }} />
      },
    }
  };

  const cookie = unescape(readCookie("userRoles"));
  if (!cookie) return false;
  const userRoles = JSON.parse(cookie);

  userRoles.forEach(function (userRole: { role: string }) {
    switch (userRole.role) {
      case UserRolesEnum.ADMIN:
        const subscription = CachedDataSingleton.getInstance().subscription;
        subscription === SubscriptionsEnum.PROFESSIONAL
          ? (menuOptions = {
            PROJECTS: allMenu.PROJECTS,
            DOCUMENTS: allMenu.DOCUMENTS,
            PEOPLE: allMenu.PEOPLE,
            MORE: allMenu.MORE,
            OPTIONS: allMenu.OPTIONS,
            COMPANYOVERVIEW: allMenu.COMPANYOVERVIEW,
          })
          : (menuOptions = {
            PROJECTS: allMenu.PROJECTS,
            DOCUMENTS: allMenu.DOCUMENTS,
            PEOPLE: allMenu.PEOPLE,
            MORE: allMenu.MORE,
            OPTIONS: allMenu.OPTIONS,
            COMPANYOVERVIEW: allMenu.COMPANYOVERVIEW,
          });
        break;
      case UserRolesEnum.INVOICING:
        menuOptions = {
          DOCUMENTS: {...documents,
            invoices: allMenu.DOCUMENTS.invoices,
            proformas: allMenu.DOCUMENTS.proformas,
            creditNotes: allMenu.DOCUMENTS.creditNotes
          },
          PEOPLE: { clients: allMenu.PEOPLE.clients },
          MORE: {
            services: allMenu.MORE.services,
            articles: allMenu.MORE.articles,
            timeTracking: allMenu.MORE.timeTracking,
            costs: allMenu.MORE.costs
          },
          OPTIONS: {
            options: allMenu.OPTIONS.options,
            companyInfo: allMenu.OPTIONS.companyInfo,
            documentTemplates: allMenu.OPTIONS.documentTemplates,
          }
        };
        documents={...documents,
          invoices: allMenu.DOCUMENTS.invoices,
          proformas: allMenu.DOCUMENTS.proformas,
          creditNotes: allMenu.DOCUMENTS.creditNotes
        };
        people ={...people, clients: allMenu.PEOPLE.clients  };
        more ={...more, services: allMenu.MORE.services,
          articles: allMenu.MORE.articles,
          timeTracking: allMenu.MORE.timeTracking,
          costs: allMenu.MORE.costs};
        break;
      case UserRolesEnum.ACCOUNTING:
        menuOptions = {
          DOCUMENTS: {...documents,
            invoices: allMenu.DOCUMENTS.invoices,
            proformas: allMenu.DOCUMENTS.proformas,
            creditNotes: allMenu.DOCUMENTS.creditNotes
          },
          PEOPLE: { clients: allMenu.PEOPLE.clients },
          MORE: {
            services: allMenu.MORE.services,
            articles: allMenu.MORE.articles,
            costs: allMenu.MORE.costs
          },
          OPTIONS: {
            options: allMenu.OPTIONS.options,
            companyInfo: allMenu.OPTIONS.companyInfo
          }
        };
        documents={...documents,
          invoices: allMenu.DOCUMENTS.invoices,
          proformas: allMenu.DOCUMENTS.proformas,
          creditNotes: allMenu.DOCUMENTS.creditNotes
        };
        people ={...people, clients: allMenu.PEOPLE.clients  };
        more ={...more, services: allMenu.MORE.services,
          articles: allMenu.MORE.articles,
          costs: allMenu.MORE.costs};
        break;
      case UserRolesEnum.ESTIMATES:
        menuOptions = {
          PROJECTS: allMenu.PROJECTS,
          DOCUMENTS: {...documents,
            estimates: allMenu.DOCUMENTS.estimates,
            subcontractorOrderTickets:
              allMenu.DOCUMENTS.subcontractorOrderTickets,
            orderTickets: allMenu.DOCUMENTS.orderTickets
          },
          PEOPLE: { clients: allMenu.PEOPLE.clients },
          MORE: {
            services: allMenu.MORE.services,
            articles: allMenu.MORE.articles,
            costs: allMenu.MORE.costs
          },
          OPTIONS: {
            options: allMenu.OPTIONS.options,
            companyInfo: allMenu.OPTIONS.companyInfo,
            documentTemplates: allMenu.OPTIONS.documentTemplates,
          }
        };
        documents={...documents,
          estimates: allMenu.DOCUMENTS.estimates,
          subcontractorOrderTickets:
            allMenu.DOCUMENTS.subcontractorOrderTickets,
          orderTickets: allMenu.DOCUMENTS.orderTickets
        };
        people ={...people, clients: allMenu.PEOPLE.clients  };
        more ={...more, services: allMenu.MORE.services,
          articles: allMenu.MORE.articles,
          costs: allMenu.MORE.costs};
        break;
      case UserRolesEnum.SUBCONTRACTORS:
        menuOptions = { 
          DOCUMENTS: {...documents},
          PEOPLE: {
            clients: allMenu.PEOPLE.clients,
            employees: allMenu.PEOPLE.employees,
            subcontractors: allMenu.PEOPLE.subcontractors
          },
          MORE: {
            services: allMenu.MORE.services,
            articles: allMenu.MORE.articles,
            costs: allMenu.MORE.costs
          },
          OPTIONS: {
            options: allMenu.OPTIONS.options,
            companyInfo: allMenu.OPTIONS.companyInfo
          }
        };
        people ={...people,
          clients: allMenu.PEOPLE.clients,
          employees: allMenu.PEOPLE.employees,
          subcontractors: allMenu.PEOPLE.subcontractors};
        more ={...more, services: allMenu.MORE.services,
          articles: allMenu.MORE.articles,
          costs: allMenu.MORE.costs};
        break;
      case UserRolesEnum.TIMEKEEPING:
        menuOptions = {
          DOCUMENTS: {...documents},
          PEOPLE: {
            clients: allMenu.PEOPLE.clients
          },
          MORE: {
            services: allMenu.MORE.services,
            articles: allMenu.MORE.articles,
            costs: allMenu.MORE.costs
          },
          OPTIONS: {
            options: allMenu.OPTIONS.options,
            companyInfo: allMenu.OPTIONS.companyInfo
          }
        };
        people ={...people, clients: allMenu.PEOPLE.clients  };
        more ={...more, services: allMenu.MORE.services,
          articles: allMenu.MORE.articles,
          costs: allMenu.MORE.costs};
        break;
      case UserRolesEnum.COMPANYUSERS:
        menuOptions = {
          DOCUMENTS: {...documents},
          PEOPLE: {
            clients: allMenu.PEOPLE.clients
          },
          MORE: {
            services: allMenu.MORE.services,
            articles: allMenu.MORE.articles,
            costs: allMenu.MORE.costs
          },
          OPTIONS: allMenu.OPTIONS
        };
        people ={...people, clients: allMenu.PEOPLE.clients  };
        more ={...more, services: allMenu.MORE.services,
          articles: allMenu.MORE.articles,
          costs: allMenu.MORE.costs};
        break;
      case UserRolesEnum.COSTMANAGEMENT:
        menuOptions = {
          DOCUMENTS: {...documents},
          PEOPLE: {
            clients: allMenu.PEOPLE.clients
          },
          MORE: {
            services: allMenu.MORE.services,
            articles: allMenu.MORE.articles,
            costs: allMenu.MORE.costs
          },
          OPTIONS: {
            options: allMenu.OPTIONS.options,
            companyInfo: allMenu.OPTIONS.companyInfo,
            documentTemplates: allMenu.OPTIONS.documentTemplates,
          }
        };
        people ={...people, clients: allMenu.PEOPLE.clients  };
        more ={...more, services: allMenu.MORE.services,
          articles: allMenu.MORE.articles,
          costs: allMenu.MORE.costs};
        break;
      case UserRolesEnum.COMPANYOVERVIEW:
          menuOptions = {
            COMPANYOVERVIEW: {
              companyOverview: allMenu.COMPANYOVERVIEW.companyOverview
            },
    }
  }
  });
  menuOptions.icons = {
    PROJECTS:{
      label: lang.active.folders,
      icon: <IconProjects
      color={theme.palette.iconDefault?.main
    }/>,
      iconDashboard: <IconProjectsOutline
      color="#fff" width={bigIconSize.width} height={bigIconSize.height}
    />
    },
    DOCUMENTS:{
      invoices: {
        label: lang.get("invoices"),
        icon: <IconInvoices 
        color={theme.palette.iconDefault?.main}
       />,
       iconDashboard: <IconInvoices
        color="#fff" width={bigIconSize.width} height={bigIconSize.height}
      />
      },
      proformas: {
        label: lang.get("proformas"),
        icon: <IconProFormas 
        color={theme.palette.iconDefault?.main}
        />,
        iconDashboard: <IconProFormas
        color="#fff" width={defaultWidth} height={defaultHeight}
      />
      },
      creditNotes: {
        label: lang.get("creditNotes"),
        icon: <IconCreditNotes 
          color={theme.palette.iconDefault?.main}
          />,
        iconDashboard: <IconCreditNotes
          color="#fff" width={defaultWidth} height={defaultHeight}
        />
      },
      estimates: {
        label: lang.get("estimates"),
        icon: <IconEstimates 
          color={theme.palette.iconDefault?.main}
        />,
        iconDashboard: <IconEstimates
          color="#fff" width={bigIconSize.width} height={bigIconSize.height}
        />
      },
      orderTickets: {
        label: lang.get("orderTickets"),
        icon: <IconOrderTickets color={theme.palette.iconDefault?.main}/>,
        iconDashboard: <IconOrderTickets
        color="#fff" width={defaultWidth} height={defaultHeight}
      />
      },
      subcontractorOrderTickets: {
        label: lang.get("subcontractorOrderTickets"),
        icon: <IconSubcontractorOrderTickets color={theme.palette.iconDefault?.main}/>,
        iconDashboard: <IconSubcontractorOrderTickets
        color="#fff" width={defaultWidth} height={defaultHeight}
      />
      }
    },
    PEOPLE:{
      clients: {
        label: lang.active.clients,
        icon: (
          <IconClients color = {theme.palette.iconBlue?.main} />
        ),
        iconDashboard: <IconClients
        color="#fff" width={defaultWidth} height={defaultHeight}
      />,
      },
      subcontractors: {
        label: lang.active.subcontractors,
        icon: (
          <IconSubcontractors
          color={theme.palette.iconBlue?.main}
          />
        ),
        iconDashboard: <IconSubcontractors
        color="#fff" width={defaultWidth} height={defaultHeight}
      />,
      },
      employees: {
        label: lang.active.employees,
        icon: (
          <IconEmployees
            color={theme.palette.iconBlue?.main}
          />
        ),
        iconDashboard: <IconEmployees
        color="#fff" width={defaultWidth} height={defaultHeight}
      />,
      }
    }
  }
  return menuOptions;
}
