import { useState } from 'react';
import { DateTimePicker } from "@material-ui/pickers";
import Lang from "../../lang";
import Config from '../../Utils/Config';
import { put, StatusEnum, UrlEnum } from '../../Utils/Utils';
import { InputAdornment, IconButton, Dialog, DialogTitle, DialogActions, Button, DialogContent } from '@material-ui/core';
import AlarmIcon from '@material-ui/icons/Alarm';
import SnoozeIcon from "@material-ui/icons/Snooze";
import moment from 'moment';
import theme from "../../Theme/Theme";
const lang = Lang.getInstance();

type EditTimetrackingProps = {
    timeTrackingReportVal: any;
    onUpdateSuccess: (timetrackingReportVal: any) => void;
    onClose: () => void;
    open: boolean,
    setSmallMessage: (message: string, type?: string) => void;
};

function EditTimetracking(props: EditTimetrackingProps) {
    const [startWork, setStartWork] = useState(moment(props.timeTrackingReportVal.startWork, Config.momentEUDateFormatWithTime));
    const [endWork, setEndWork] = useState(moment(props.timeTrackingReportVal.endWork, Config.momentEUDateFormatWithTime));

    /**
     * update tk
     */
    const updateTk = async () => {
        let data = { ...props.timeTrackingReportVal };
        data.startWork = startWork.format(Config.momentEUDateFormatWithTime+":ss");
        data.stopWork = endWork.format(Config.momentEUDateFormatWithTime+":ss");
        const url = UrlEnum.timeTracking + "/update/" + data.id;
        const response = await put(url, data);
        if (response.errors) {
            // show error
            props.setSmallMessage(lang.get("error"), StatusEnum.ERROR);
            return false;
        }
        // show success
        
        data.endWork = data.stopWork;
        data.elapsedTime = response.elapsedTime;
        
        props.setSmallMessage(lang.get("success"));
        props.onUpdateSuccess(data);
        props.onClose();
    };
    const closeTk = async () =>{
        props.onClose();

    }

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
        >
            <DialogTitle>{lang.get("edit")}: {lang.get("timekeeping")}</DialogTitle>
            <DialogContent>
                <DateTimePicker
                    disableFuture
                    hideTabs
                    fullWidth
                    ampm={false}
                    value={startWork}
                    onChange={(date) =>
                        setStartWork(date as moment.Moment)
                    }
                    format={Config.momentEUDateFormatWithTime}
                    allowKeyboardControl={false}
                    helperText={lang.get("startWork")}
                    leftArrowIcon={<AlarmIcon />}
                    rightArrowIcon={<SnoozeIcon />}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton>
                                    <AlarmIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />

                <DateTimePicker
                    disableFuture
                    fullWidth
                    hideTabs
                    ampm={false}
                    value={endWork}
                    onChange={(date) =>
                        setEndWork(date as moment.Moment)
                    }
                    format={Config.momentEUDateFormatWithTime}
                    allowKeyboardControl={false}
                    helperText={lang.get("endWork")}
                    leftArrowIcon={<AlarmIcon />}
                    rightArrowIcon={<SnoozeIcon />}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton>
                                    <AlarmIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" style={{background:theme.palette.header?.main}}
                    onClick={updateTk}
                >
                    {lang.get("save")}
                </Button>
                <Button variant="contained" style={{background:theme.palette.errorColor?.main}}
                    onClick={closeTk}>
                    {lang.get("cancel")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default EditTimetracking;