import React, { Fragment, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  makeStyles,
  AccordionDetails,
  IconButton,
  Tooltip,
  Checkbox,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { Grid } from "@material-ui/core";
import Lang from "../lang";
import { LocalUrlEnum, DocTypes } from "../Utils/Utils";
import { Link } from "react-router-dom";
import Config from "../Utils/Config";
import moment from "moment";
import DocStatus from "../Components/DocStatus";
import EstimatesContext from "../Contexts/EstimatesContext";
import { Estimate } from "../Models/Models";
import theme from "../Theme/Theme";

const useStyles = makeStyles((theme) => ({
  secondaryHeading: {
    fontSize: 10,
  },
  row: {
    display: "flex",
    width: "100%",
  },
  col: {
    padding: "0 10px",
  },
  clientName: {
    flexGrow: 1,
    minWidth:"200px",

  },
  total: {
    minWidth: 100,
    textAlign: "left",
    paddingRight: 20,
  },
  status: {
    minWidth: 50,
    textAlign: "right",
    paddingRight: 20,
  },
  details: {
    display: "block",
  },
  paymentBtn: {
    display: "flex",
    width: "100%",
    alignContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      flexDirection: "column",
      alignContent: "center",
    },
  },
  paymentBtnBlocked: {
    marginLeft: "10px",
    alignContent: "center",
    minWidth: "45px",
    padding: "5px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      flexDirection: "column",
      alignContent: "center",
    },
  },
  success: {
    background: theme.palette.success.dark,
    color: "#ffffff",
    height: "24.5px",
  },
  buttons: {
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "33%",
  },
  grid2: {
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      alignContent: "center",
    },
  },
  error: {
    background: theme.palette.error.dark,
    color: "#ffffff",
    height: "24.5px",
  },
  link: {
    color: theme.palette.textColor?.main,
  },
  extensionPanel: {},
  checkbox: {
    marginTop: -10,
    padding: 3,
  },
  checkboxProject: {
    marginLeft: -35,
    marginTop: -10,
    padding: 3,
  },
  projectTable: {
    display: "inline-flex",
    // display: "flex",
  },
  dashboardClientName: {
    flexGrow:0.9,
    width:35
  },
  dashboardDate:
  {
    marginLeft:"100px",
    paddingRight: 30,
  },
}));

const lang = Lang.getInstance();

type EstimateItemProps = {
  estimate: Estimate;
  canDelete: boolean;
  isMobile: boolean;
  changeEstimateState: (estimate: Estimate) => {};
  onDelete: Function;
  onDuplicate: Function;
  classesOverride: { extensionPanel: any };
  select?: boolean;
  onSelect?: Function;
  isProject?: boolean;
  isDashboard?: boolean;
};

export default function EstimateItem(props: EstimateItemProps) {
  let classes = useStyles();
  classes = Object.assign(classes, props.classesOverride || {});
  const isMobile = props.isMobile;
  const isProject = props.isProject;
  const isDashboard = props.isDashboard;
  const [expanded, setExpanded] = useState(false);
  let checkBoxStyle = isProject ? classes.checkboxProject : classes.checkbox;
  // const [paymentsOpen, setPaymentsOpen] = useState(false);
  /**
   * render estimates columns for mobile and desktop
   */
  //  let checkBoxStyle= isProject? classes.checkboxProject: classes.checkbox;
  function _renderCols() {
    if (isMobile) {
      return (
        <div className={classes.row}>
          <EstimatesContext.Consumer>
            {(context) => {
              let link = LocalUrlEnum.estimates;
              let name = props.estimate.client
                ? props.estimate.client.name
                : "";
              switch (context.type) {
                case DocTypes.orderTicket:
                  link = LocalUrlEnum.orderTickets;
                  break;
                case DocTypes.subcontractorOrderTicket:
                  link = LocalUrlEnum.subcontractorordertickets;
                  name = props.estimate.subcontractor
                    ? props.estimate.subcontractor.name
                    : "";
                  break;
                default:
                  break;
              }
              return (
                <Fragment>
                  {props.select && (
                    <Checkbox
                      classes={{ root: checkBoxStyle }}
                      checked={
                        props.estimate.selected
                          ? props.estimate.selected
                          : false
                      }
                      style={{ color: theme.palette.header?.main }}
                      onChange={(e) =>
                        props.onSelect &&
                        props.onSelect(props.estimate, e.target.checked)
                      }
                    />
                  )}
                  <div className={`${classes.col} ${classes.link}`}>
                    <Link to={link + "/" + props.estimate.id}>
                      {props.estimate.estimateNo}
                    </Link>
                  </div>
                  <div className={`${classes.col} ${classes.clientName}`}>
                    <Link to={link + "/" + props.estimate.id}>{name}</Link>
                  </div>
                </Fragment>
              );
            }}
          </EstimatesContext.Consumer>
        </div>
      );
    } else {
      return (
        <div className={classes.row}>
          <EstimatesContext.Consumer>
            {(context) => {
              let link = LocalUrlEnum.estimates;
              let name = props.estimate.client
                ? props.estimate.client.name
                : "";
              switch (context.type) {
                case DocTypes.orderTicket:
                  link = LocalUrlEnum.orderTickets;
                  break;
                case DocTypes.subcontractorOrderTicket:
                  link = LocalUrlEnum.subcontractorordertickets;
                  name = props.estimate.subcontractor
                    ? props.estimate.subcontractor.name
                    : "";
                  break;
                default:
                  break;
              }
              return (
                <Fragment>
                  {props.select && (
                    <Checkbox
                      classes={{ root: checkBoxStyle }}
                      checked={
                        props.estimate.selected
                          ? props.estimate.selected
                          : false
                      }
                      style={{ color: theme.palette.header?.main }}
                      onChange={(e) =>
                        props.onSelect &&
                        props.onSelect(props.estimate, e.target.checked)
                      }
                      onClick={(e)=>{
                        e.stopPropagation();
                      }}
                    />
                  )}
                  <div className={`${classes.col} ${classes.link}`}>
                    <Link to={link + "/" + props.estimate.id}>
                      {props.estimate.estimateNo}
                    </Link>
                  </div>

                  <div
                    className={
                      isDashboard
                        ? `${classes.col} ${classes.dashboardClientName}`
                        :
                        `${classes.col} ${classes.clientName}`
                    }
                  >
                    <Link to={link + "/" + props.estimate.id}>{name}</Link>
                  </div>
                </Fragment>
              );
            }}
          </EstimatesContext.Consumer>
          <div className={isDashboard ? classes.dashboardDate: classes.col}>
            {moment(props.estimate.created_at).format(
              Config.momentEUDateFormat
            )}
          </div>
          <div className={`${classes.col} ${classes.total}`}>
            {parseFloat(props.estimate.totalWithVat?.toString()).toFixed(
              Config.noOfDecimals
            )}
          </div>
          <div className={`${classes.col} ${classes.status}`}>
            <DocStatus
              size="small"
              className={classes.paymentBtn}
              status={props.estimate.status}
              onChangeStatus={(e) => {
                e.preventDefault();
                e.stopPropagation();
                props.changeEstimateState(props.estimate);
                return false;
              }}
            />
          </div>
        </div>
      );
    }
  }

  function _onExpand() {
    setExpanded(!expanded);
  }
  return (
    <Fragment>
      <Accordion
        className={classes.extensionPanel}
        expanded={expanded}
        onChange={_onExpand}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          classes={{ root: isProject ? `${classes.projectTable}` : "" }}
        >
          {_renderCols()}
        </AccordionSummary>
        <AccordionDetails classes={{ root: classes.details }}>
          {isMobile ? (
            <Grid container>
              <Grid item>
                <div>
                  <label>
                    <strong>{lang.get("date")}: </strong>
                  </label>
                  {moment(props.estimate.created_at).format(
                    Config.momentEUDateFormat
                  )}
                </div>
                <div>
                  <label>
                    <strong>{lang.get("total")}: </strong>
                  </label>
                  {parseFloat(props.estimate.totalWithVat?.toString()).toFixed(
                    Config.noOfDecimals
                  )}
                </div>
              </Grid>
              <Grid item>
                <div className={`${classes.col} ${classes.status}`}>
                  <DocStatus
                    size="small"
                    className={classes.paymentBtn}
                    status={props.estimate.status}
                    onChangeStatus={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      props.changeEstimateState(props.estimate);
                      return false;
                    }}
                  />
                </div>
              </Grid>
            </Grid>
          ) : null}

          <Grid container spacing={1}>
            <Grid item xs={props.canDelete ? 4 : 6} sm={4} md={1}>
              <Tooltip title={lang.get("edit")} placement="bottom">
                <IconButton className={classes.buttons} aria-label="delete">
                  <EstimatesContext.Consumer>
                    {(context) => {
                      let link = LocalUrlEnum.estimates;
                      if (context.type === DocTypes.orderTicket)
                        link = LocalUrlEnum.orderTickets;
                      if (context.type === DocTypes.subcontractorOrderTicket)
                        link = LocalUrlEnum.subcontractorordertickets;
                      return (
                        <Link to={link + "/" + props.estimate.id}>
                          <CreateIcon />
                        </Link>
                      );
                    }}
                  </EstimatesContext.Consumer>
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs={props.canDelete ? 4 : 6} sm={4} md={1}>
              <Tooltip title={lang.get("duplicate")} placement="bottom">
                <IconButton
                  className={classes.buttons}
                  aria-label="duplicate"
                  onClick={() => props.onDuplicate(props.estimate)}
                >
                  <FileCopyIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs={props.canDelete ? 4 : 6} sm={4} md={1}>
              {props.canDelete ? (
                <Tooltip title={lang.get("delete")} placement="bottom">
                  <IconButton
                    className={classes.buttons}
                    aria-label="delete"
                    onClick={() => props.onDelete(props.estimate)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Fragment>
  );
}
