import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  Button,
  CircularProgress,
  FormControlLabel,
  makeStyles,
  Paper,
  Radio,
  RadioGroup,
  Slide,
} from "@material-ui/core";
import {
  UrlEnum,
  LocalUrlEnum,
  get,
  post,
  StatusEnum,
} from "../../Utils/Utils";
import StripeCheckout from "react-stripe-checkout";
import logo from "../../Images/logo/cronotimal_orizontal.svg";
import logo_stripe from "../../Images/logo/cronoptimal_icon.svg";
import Visa from "../../Images/stripeCards/Visa.png";
import MasterCard from "../../Images/stripeCards/MasterCard_Logo.png";
import Maestro from "../../Images/stripeCards/Maestro.png";
import Sepa from "../../Images/stripeCards/bancontact.png";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Lang from "../../lang";
import parse from "html-react-parser";
import PayWithBancontact from "./PayWithBancontact";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  container: {
    height: "100vh",
    overflowY: "scroll",
  },

  button: {
    background: "white",
    fontSize: "15px",
    margin: "10px 20px",
    padding: "10px 20px",
    backgroundImage: "linear-gradient(#efeeee  40%, #b7b5b5)",
    filter: "drop-shadow(5px 3px 3px #918f8f)",
  },
  paper: {
    paddingTop: 26,
    paddingBottom: 26,
    margin: "auto",
    marginTop: "50px",
    width: "30%",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  smallMessage: {
    position: "fixed",
    top: 70,
    right: 20,
    zIndex: 2000,
    background: theme.palette.errorColor?.main,
    color: theme.palette.textColorSecondary?.main,
    minWidth: 260,
    padding: "10px 15px 10px 15px",
  },
  success: {
    background: theme.palette.successColor?.main,
  },
  error: {
    background: theme.palette.errorColor?.main,
  },
}));
let TEST_KEY = "pk_test_jrWj0oEHBAsJb14VMRM7Kiw5";
if (document.location.hostname.indexOf("cronoptimal") >= 0 
  || document.location.hostname.indexOf("davany") >= 0)
  TEST_KEY = "pk_live_t9rsQxudLDd401ApvnISTIgL";

export default function PayInvoices() {
  const [state, setState] = useState({
    client: null,
    invoice: null,
    company: null,
    html: "",
    isPaid: false,
  });
  const [source, setSource] = useState(null);
  const [loading, setLoading] = useState(false);
  const [smallMessage, setSmallMessage] = useState(null);
  const [isCardChecked, setIsCardChecked] = useState("card");

  const stripePromise = loadStripe(TEST_KEY);
  const smallMessageTimeout = 5000;
  const classes = useStyles();
  const history = useHistory();
  const { locale, enc } = useParams();
  const lang = Lang.getInstance();
  if (lang.locale !== locale) lang.set(locale);

  useEffect(() => {
    const index = document.location.href.indexOf("source");
    if (index >= 0) {
      const s = document.location.href.substring(
        index + 7,
        document.location.href.length
      );
      setSource(s);
    }
    const url = `/onlineDoc/${locale}/${enc}`;
    get(url)
      .then((response) => {
        if (response.errors) console.log(response.errors);
        else {
          setState(response);
          lang.set(locale);
        }
      })
      .catch((error) => console.log(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showSmallMessage = (message, status = StatusEnum.SUCCESS) => {
    setSmallMessage({ message: message, status: status });
    // hide after timeout
    setTimeout(() => {
      setSmallMessage(null);
    }, smallMessageTimeout);
  };
  async function handleToken(token) {
    const description = `${state.company?.name} - ${lang.get("invoice")} ${
      state.invoice?.invoiceNo
    }`;
    setLoading(true);
    const form = new FormData();
    form.append("cardId", token ? token.card.id : null);
    form.append("tokenId", token ? token.id : null);
    form.append("description", description);
    form.append("amount", Math.round(state.invoice?.totalWithVat * 100));
    form.append("clientEmail", state.client.email);
    form.append("invoiceId", state.invoice.id);
    form.append("country", "US");

    const response = await post('/payInvoice', form);
    if (response.errors) {
      showSmallMessage(lang.get("error"), StatusEnum.ERROR);
      setLoading(false);
    } else {
      checkPaymentStatus(token.card.id, "card");
    }
  }

  function checkPaymentStatus(token, type) {
    const url = `${UrlEnum.checkPaymentStatus}false/${token}`;
    get(url).then((res) => {
      if (res === "Paid") {
        setLoading(false);
        history.push({
          pathname: LocalUrlEnum.confirmPayment,
          state: { title: "Successfully paid!" },
        });
      } else {
        var startTime = new Date().getTime();
        const interval = setInterval(() => {
          get(url).then((res) => {
            if (res === "Paid") {
              setLoading(false);
              history.push({
                pathname: LocalUrlEnum.confirmPayment,
                state: { title: "Successfully paid!" },
              });
              clearInterval(interval);
            } else {
              if (new Date().getTime() - startTime > 60000) {
                if (type === "bancontact") {
                  history.push({
                    pathname: LocalUrlEnum.confirmPayment,
                    state: { title: "Payment processing!" },
                  });
                } else {
                  clearInterval(interval);
                  showSmallMessage(lang.get("error"), StatusEnum.ERROR);
                  setLoading(false);
                  return;
                }
              }
            }
          });
        }, 3000);
      }
    });
  }
  function handleCardChange(event) {
    setIsCardChecked(event.target.value);
  }

  function getPaymentMethod() {
    return (
      <>
        {state.isPaid ? (
          <div style={{ textAlign: "center" }}>
            <h1 style={{ color: "white" }}>{lang.get("paid")}! </h1>
          </div>
        ) : (
          <div>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="card"
              name="radio-buttons-group"
              onChange={handleCardChange}
            >
              <div
                style={{
                  display: "flex",
                  margin: 10,
                  justifyContent: "center",
                }}
              >
                <FormControlLabel
                  value="card"
                  control={<Radio />}
                  style={{ margin: "20px 10px 10px 10px" }}
                />
                <img
                  src={Visa}
                  alt="visa"
                  style={{
                    width: 90,
                    height: 35,
                    margin: "20px 10px 0px 10px",
                  }}
                />
                <img
                  src={MasterCard}
                  alt="MasterCard"
                  style={{
                    width: 100,
                    height: 55,
                    margin: "10px 10px 0px 10px",
                  }}
                />
                <img
                  src={Maestro}
                  alt="Maestro"
                  style={{
                    width: 90,
                    height: 53,
                    margin: "13px 10px 0px 10px",
                  }}
                />
                <div style={{ display: "flex", margin: 10 }}>
                  <FormControlLabel
                    value="bancontact"
                    control={<Radio />}
                    style={{ margin: 10 }}
                  />
                  <img
                    src={Sepa}
                    alt="Maestro"
                    style={{ width: 100, margin: 10 }}
                  />
                </div>
              </div>

              <div style={{ width: 150, margin: "auto", marginTop: "30px" }}>
                {pay()}
              </div>
            </RadioGroup>
          </div>
        )}
        <Slide direction="left" in={smallMessage !== null}>
          <Paper
            className={`${classes.smallMessage} ${
              classes[smallMessage?.status]
            }`}
          >
            <p>{smallMessage?.message || ""}</p>
          </Paper>
        </Slide>
        {loading ? (
          <CircularProgress
            color="secondary"
            style={{
              position: "absolute",
              display: "block",
              margin: "0 auto",
              top: "39%",
              left: "49%",
            }}
          />
        ) : (
          ""
        )}
      </>
    );
  }
  function pay() {
    const description = `${state.company?.name} - ${lang.get("invoice")} ${
      state.invoice?.invoiceNo
    }`;
    if (isCardChecked === "card") {
      return (
        <StripeCheckout
          stripeKey={TEST_KEY}
          token={handleToken}
          amount={state.invoice?.totalWithVat * 100}
          name="Cronoptimal"
          description={description}
          image={logo_stripe}
          email={state.client ? state.client.email : ""}
        >
          <Button
            size="large"
            fullWidth
            style={{
              backgroundColor: "#ff5e00",
              color: "white",
              fontSize: 15,
            }}
          >
            {lang.get("pay")}
          </Button>
        </StripeCheckout>
      );
    } else {
      return (
        <Elements stripe={stripePromise}>
          <PayWithBancontact
            company={state.company}
            client={state.client}
            invoice={state.invoice}
          />
        </Elements>
      );
    }
  }
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div style={{ width: "100%", textAlign: "center", marginTop: "70px" }}>
          <img src={logo} alt="logo" style={{ width: 350, margin: 10 }} />
        </div>
        {source ? checkPaymentStatus(source, "bancontact") : getPaymentMethod()}
        <div style={{ margin: "20px 0px" }}>{parse(state.html)}</div>
      </div>
    </div>
  );
}
