import React, { useState, useEffect, Fragment } from 'react';
import { List, ListItem, makeStyles } from '@material-ui/core';
import { UrlEnum } from '../Utils/Utils';
import EditService from './EditService';
import PropTypes from 'prop-types';
import theme from "../Theme/Theme";
const useStyles = makeStyles(theme => ({
    listItem: {
        borderBottom: "1px solid #ccc",
        paddingTop: 0,
        paddingBottom: 0,
        alignItems:"start !important"
    },

}));

function ServicesExpandableList(props) {
    const [services, setServices] = useState(props.services);    
    const classes = useStyles(props);
    const [selected, setSelected] = useState([]);

    //change services on props change
    useEffect(() => {
        setServices(props.services);
    }, [props.services]);

    useEffect(() => {
        if(props.getSelected){
            props.getSelected(selected);
        }
    }, [selected]);

    /**
     * change the selected value of the service
     * @param {SyntheticEvent} e 
     * @param {Object} service 
     */
    const changeSelection = (e, service) => {
        service.selected = service.selected ? !service.selected : true;
        const newServices = services.slice();
        setServices(newServices);
        setSelected(getSelected());
    };
    
    /**
     * return selected services
     */
    const getSelected = () =>{
        let f = services.filter((el)=>el.selected);
        return f;
    };


    const onEditService = (model, index) =>{
        const newServices = services.slice();
        newServices.splice(index,1,model);
        setServices(newServices);
        props.setServices(newServices);
    }

   
    /**
     * render services
     */
    const renderServices = () => {
        return services.map((service, index) =>
            <Fragment key={service.id || index}>
                <ListItem button className={classes.listItem} >
                    <input style={{marginTop:"15px",background:theme.palette.header?.main}} type="checkbox" onChange={(e) => changeSelection(e, service)} checked={!service.selected ? false : true} />
                    <EditService  
                        onEdit={(model) => onEditService(model,index)}
                        model={service} 
                        open={service.id && service.id > 0 ? false : true } 
                        url={UrlEnum.services} 
                    />
                </ListItem>
            </Fragment>
        );
    };

    return (
        <Fragment>
            <List>
                {renderServices()}
            </List>
        </Fragment>
    );
}

ServicesExpandableList.propTypes = {
    services: PropTypes.array,
    getSelected:PropTypes.func
};

export default ServicesExpandableList;
