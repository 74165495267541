import { Typography } from '@material-ui/core';
import noData from '../Images/page_not_found.png';

function PageNotFound () {
    return (
        <div style={{ width: 400, margin: "150px auto 0 auto", textAlign:"center" }}>
            <img alt="noData" src={noData} style={{ width: "100%", opacity: 0.75 }} />
            <Typography >Ooops! Sorry, but the page you are looking for was not found. </Typography>
        </div>
    );
}

export default PageNotFound;