import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Lang from '../lang';
import moment from 'moment';
import Config from '../Utils/Config';
const lang = Lang.getInstance();

/** document styles **/
const style = {
    doc: {
        width: 764,
        minHeight: 1170,
        maxHeight: 1170,
        background: "#ffffff",
        padding: "40px 30px",
        margin: "0 auto",
        position: "relative"
    },
};


export default function Receipt(props) {
    const padding = 10;

    return (
        <Fragment>
            <hr/>
            <p><strong>{lang.get("receipt")}</strong></p>
            <table style={{ border: "1px solid #ccc" }}>
                <tbody>
                    
                    <tr >
                        <td style={{ borderBottom: "1px solid #ccc", padding:padding }}> 
                            <strong>{lang.get("number")}:</strong>
                        </td>
                        <td style={{ borderBottom: "1px solid #ccc", padding:padding }}>
                            {props.number}
                        </td>
                        <td style={{ borderBottom: "1px solid #ccc", padding:padding }}>
                            <strong>{lang.get("date")}</strong>:
                        </td>
                        <td style={{ borderBottom: "1px solid #ccc", padding:padding }}>
                            {moment(props.date, Config.momentUSDateFormat).format(Config.momentEUDateFormat)}
                        </td>
                    </tr>
                    <tr >
                        <td colSpan="4" style={{ borderBottom: "1px solid #ccc", padding:padding }}>
                            <strong>{lang.get("sumInCash")}</strong>
                        </td>
                    </tr>
                    <tr >
                        <td style={{ padding:padding }}>{lang.get("total")} {lang.get("receipt")}:</td>
                        <td style={{ padding:padding }}>{props.total}</td>
                        <td> </td>
                        <td> </td>
                    </tr>
                </tbody>
            </table>
        </Fragment>
    );
}

Document.propTypes = {
    number: PropTypes.number.isRequired,
    date: PropTypes.object.isRequired,
    total: PropTypes.number.isRequired
};