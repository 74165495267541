import React from 'react';
import PropTypes from 'prop-types';

/** document styles **/
const style = {
    doc:{
        width: 764,
        minHeight: 1170,
        maxHeight: 1170,
        background: "#ffffff",
        padding: "40px 30px",
        margin:"0 auto",
        position:"relative"
    },
};


export default function Document(props){
    //const classes = style1();

    return(
        <div style={{...style.doc, ...props.style}} className={props.className ? props.className : ""}>
           {props.children}
        </div>
    );
}

Document.propTypes = {
    className: PropTypes.string,
    children:PropTypes.any,
    style:PropTypes.object
};