import Lang from '../../lang';
import React, { useState } from 'react';
import BasicDialog from '../BasicDialog';
import { Card, CardContent, CardMedia, Grid, makeStyles, Typography } from '@material-ui/core';
import { overviewOptionFilteredByRole } from '../../Utils/OverviewOptionsFilteredByRole';

type WidgetsModalProps = {
    open: boolean;
    onClose: (selectedWidget: JSX.Element | null) => void
};

const lang = Lang.getInstance();

const useStyles = makeStyles((theme) => ({
    cardMedia: {
        height: 100,
        backgroundSize: "contain"
    }
}));

function WidgetsDialog(props: WidgetsModalProps) {
    const [selectedWidget, setSelectedWidget] = useState<number | undefined>(undefined);

    const classes = useStyles();
    const widgetList = overviewOptionFilteredByRole();

    /**
     * called when dialog closes to send result to parent
     * @param result 
     */
    const onResult = (result: boolean) => {
        if (result) {
            props.onClose(
                selectedWidget ?
                    widgetList[selectedWidget].component :
                    null);
        } else {
            props.onClose(null);
        }
    };

    /**
     * select the clicked widget
     * @param index 
     */
    const selectWidget = (index: number) => {
        setSelectedWidget(index);
    };

    return (
        <BasicDialog
            title={lang.get("add")}
            onResult={onResult}
            open={props.open}
        >
            <Grid container spacing={4}>
                {
                    widgetList.map((widget, index) =>
                        <Grid key={index} item md={4} sm={6} xs={6} >
                            <Card
                                style={(index && index === selectedWidget) ? { border: "2px solid #ccc" } : {}}
                                onClick={() => selectWidget(index)}
                            >
                                <CardMedia
                                    classes={{ root: classes.cardMedia }}
                                    image={widget.image}
                                    title={widget.name}
                                />
                                <CardContent>
                                    <Typography component="p">
                                        {widget.name}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    )
                }
            </Grid>
        </BasicDialog>
    );
}

export default WidgetsDialog;