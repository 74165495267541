import React, { Fragment } from "react";
import { DocTypes, isMobile } from "../../../Utils/Utils";
import Lang from "../../../lang";
import Config from "../../../Utils/Config";
import { useState } from "react";
import ResultTable from "./ResultTable";
import DocumentReportForm from "./DocumentReportForm";
import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper
} from "@material-ui/core";
import DrawerLayout from "../../DrawerLayout";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { Invoice } from "../../../Models/Models";
import moment from "moment";
import { Link } from "react-router-dom";
import AssessmentIcon from "@material-ui/icons/Assessment";

const lang = Lang.getInstance();

type DocumentReportProps = {
  type: string;
  menuOptions: any;
};

const useStyles = makeStyles((theme: any) => ({
  unpaid: {
    background: theme.palette.error.light,
    display: "block",
    padding: 10
  },
  paid: {
    background: theme.palette.success.light,
    display: "block",
    padding: 10
  },
  subitem: {
    background: "#eee",
    padding: "5px 30px 30px 80px"
  }
}));

function DocumentsReport(props: DocumentReportProps) {
  const { type } = props;
  const classes = useStyles();
  const [filterValues, setFilterValues] = useState(null);
  const [open, setOpen] = useState(!isMobile());

  /**
   * on search
   * @param data
   */
  const onSearch = async (data: any) => {
    setFilterValues(data);
  };

  let columns: Array<any> = [];
  let docTypes: Array<string> = [];
  let showPaid = true;
  switch (type) {
    case DocTypes.invoice:
    case DocTypes.proforma:
    case DocTypes.creditNote:
      columns = [
        {
          label: lang.get("type"),
          customRender: (doc: any) => lang.get(doc.type)
        },
        { label: lang.get("number"), name: "invoiceNo" },
        {
          label: lang.get("client"),
          customRender: (doc: any) => doc.client.name
        },
        { label: lang.get("date"), name: "invoiceDate" },
        { label: lang.get("totalNoVat"), name: "totalNoVat" },
        { label: lang.get("totalWithVat"), name: "totalWithVat" },
        {
          label: lang.get("paid"),
          customRender: ({ totalPaid }: any) => (
            <span className={clsx({ [classes.paid]: totalPaid })}>
              {totalPaid}
            </span>
          )
        },
        {
          label: lang.get("unpaid"),
          customRender: ({ totalWithVat, totalPaid }: any) => {
            const unpaid = totalWithVat - totalPaid;
            return (
              <span className={clsx({ [classes.unpaid]: unpaid })}>
                {unpaid.toFixed(Config.noOfDecimals)}
              </span>
            );
          }
        }
      ];
      docTypes = [DocTypes.invoice, DocTypes.proforma, DocTypes.creditNote];
      showPaid = true;
      break;
    case DocTypes.estimate:
    case DocTypes.orderTicket:
    case DocTypes.subcontractorOrderTicket:
      columns = [
        {
          label: lang.get("type"),
          customRender: (doc: any) => lang.get(doc.type)
        },
        { label: lang.get("number"), name: "estimateNo" },
        {
          label: lang.get("client"),
          customRender: (doc: any) => doc.client.name
        },
        { label: lang.get("date"), name: "startDate" },
        { label: lang.get("vatRate"), name: "vatRate" },
        { label: lang.get("totalNoVat"), name: "totalNoVat" },
        { label: lang.get("totalWithVat"), name: "totalWithVat" }
      ];
      docTypes = [
        DocTypes.estimate,
        DocTypes.orderTicket,
        DocTypes.subcontractorOrderTicket
      ];
      showPaid = false;
      break;
  }

  const expandInvoiceRow = (invoice: Invoice) => {
    if (!invoice || !invoice.payments) return null;
    if (!invoice.payments.length) return null;

    return (
      <div className={classes.subitem}>
        {invoice.payments.map((payment: any) => {
          return (
            <div key={payment.id}>
              {lang.get("payment")}: {payment.description} -{" "}
              {lang.get("amount")} {payment.amount} -{" "}
              {moment(payment.paymentDate).format(Config.momentEUDateFormat)}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <DrawerLayout
      open={open}
      handleDrawerClose={() => setOpen(!open)}
      drawerWidth={299}
      drawerChildren={
        <Fragment>
          <List>
            <Link to={"/invoices/report"}>
              <ListItem button selected={type === "invoice"}>
                <ListItemIcon>
                  <AssessmentIcon />
                </ListItemIcon>
                <ListItemText
                  primary={`${lang.get("report")}: ${lang.get(
                    "invoices"
                  )}, ${lang.get("proformas")}`}
                />
              </ListItem>
            </Link>
            <Link to={"/estimates/report"}>
              <ListItem button selected={type === "estimate"}>
                <ListItemIcon>
                  <AssessmentIcon />
                </ListItemIcon>
                <ListItemText
                  primary={`${lang.get("report")}: ${lang.get(
                    "estimates"
                  )}, ${lang.get("orderTickets")}`}
                />
              </ListItem>
            </Link>
            <Divider />
            {Object.keys(props.menuOptions.DOCUMENTS).map((key, value) => {
              return (
                <Link to={props.menuOptions.DOCUMENTS[key].link}>
                  <ListItem button>
                    <ListItemIcon>
                      {props.menuOptions.DOCUMENTS[key].icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={props.menuOptions.DOCUMENTS[key].label}
                    />
                  </ListItem>
                </Link>
              );
            })}
          </List>
          <Divider />
        </Fragment>
      }
    >
      <Paper style={{ padding: 20 }}>
        <DocumentReportForm
          key={type}
          docTypes={docTypes}
          onSearch={onSearch}
          showPaid={showPaid}
        />
      </Paper>
      {filterValues ? (
        <ResultTable
          key={type}
          columns={columns}
          filterValues={filterValues}
          expadableRow={showPaid ? expandInvoiceRow : null}
        />
      ) : null}
    </DrawerLayout>
  );
}

export default DocumentsReport;
