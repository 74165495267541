import React from "react";
import Lang from "../../lang";
import { Address } from "../../Models/Models";
import moment from "moment";
import PropTypes from 'prop-types';

const lang = Lang.getInstance();

/** document styles **/
export const styles = {
    headerWrapper: {
      marginRight: -20,
      fontSize: "9pt"
    },
    invoiceText: {
      fontSize: "25px",
      margin: 0,
      textTransform: "uppercase"
    },
    logoWrapper: {
      width: "48%",
      display: "inline-table",
      marginLeft: 10,
      verticalAlign: "top"
    },
    companyWrapper: {
      width: "48%",
      display: "inline-table",
      marginRight: 15,
      verticalAlign: "top"
    },
    docInvoiceInfoWrapper: {
      width: "48%",
      display: "inline-table",
      marginLeft: 5,
      verticalAlign: "top"
    },
    clientWrapper: {
      fontSize: '11pt',
      width: "48%",
      display: "inline-table",
      marginRight: -10,
      verticalAlign: "top"
    },
    logo: {
      maxHeight: 100
    },
    clear: {
      clear: "both",
      borderBottom: "10px solid #ccc",
      marginBottom: 20,
      marginTop: 15,
      marginRight: 15
    }
  };

export default function LiabilityHeader(props) {
  const company = props.company;
  const client = props.client;

  return (
    <div
      style={styles.headerWrapper}
    >
      <div
        style={styles.logoWrapper}
      >
        {
          (company.logo && company.logo!=='undefined') ?
            <img src={company.logo} alt="logo" style={styles.logo} /> :
            <p style={styles.invoiceText}>{company.name}</p>
        }
      </div>
      <div
        style={styles.companyWrapper}
      >
        <p>
          <strong style={styles.invoiceText}>{company.name}</strong><br />
          {company.description !== 'null' ?company.description:""}
        </p>
        <br />
      </div>
      <div
        style={{
          clear: "both",
          borderBottom: "10px solid #ccc",
          marginBottom: 20,
          marginTop: 15,
          marginRight: 15,
          borderColor: props.color
        }}
      ></div>
      <h2
        style={{
          verticalAlign: "top",
          justifyContent: "center",
          alignContent: "center",
          textAlign: "center",
          fontSize: '15pt',
        }}
      >
        {lang.get("reminder")}
      </h2>
      <div
       style={styles.clientWrapper}
      >
        <h3 style={{ margin: 0,fontSize: '11pt', }}>{lang.get("supplier")}</h3>

        <p>
          <strong>{company.name}</strong>
          <br />
          <strong>{lang.get("vatNo")}</strong>: {company.vatNo !== 'null' ?company.vatNo:""}
          <br />
          <strong>{lang.get("companyRegNo")}</strong>: {company.companyRegNo !== 'null'?company.companyRegNo:""}
          <br />
          <strong>{lang.get("address")}</strong>:{" "}
          {Address.stringify(company.address)}
          <br />
          <strong>{lang.get("bankName")}</strong>: {company.bankName}
          <br />
          <strong>{lang.get("bankAccount")}</strong>: {company.bankAccount}
        </p>
      </div>

      <div
        style={{
          width: "48%",
          display: "inline-table",
          marginRight: -10,
          verticalAlign: "top",
          fontSize: '11pt',
        }}
      >
        <h3 style={{ margin: 0,fontSize: '11pt', }}>{lang.get("client")}</h3>

        <p>
        <strong>{client.title}</strong>{" "}<strong>{client.name}</strong>
          <br />
          <strong>{lang.get("vatNo")}</strong>: {client.vatNo !== 'null' ?client.vatNo:""}
          <br />
          <strong>{lang.get("address")}</strong>:{" "}
          {Address.stringify(client.address)}
          <br />
          <strong>{lang.get("date")}</strong>: {moment().format("DD-MM-yyyy")}
        </p>
      </div>
    </div>
  );
}

LiabilityHeader.propTypes = {
  company: PropTypes.string.isRequired,
  series: PropTypes.string,
  number: PropTypes.string,
  date: PropTypes.string,
  vatRate: PropTypes.number,
  client: PropTypes.object,
  color: PropTypes.string
};
