import React, { useState, Fragment, useEffect } from 'react';
import EditPackage from '../Components/EditPackage';
import { CircularProgress, makeStyles, ListItem } from '@material-ui/core';
import { get, post } from '../Utils/Utils';
import { UrlEnum } from '../Utils/Utils';
import DeleteIconBtn from '../Components/DeleteIconBtn';
import { Package } from '../Models/Models';
import PropTypes from 'prop-types';
import grey from '@material-ui/core/colors/grey';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    loading: {
        margin: "0 auto"
    },
    listItem: {
        borderBottom: "1px solid #ccc",
        paddingTop: 0,
        paddingBottom: 0,
        // alignItems: "center !important",
        alignItems:"start !important"
    },
    iconBtn: {
        padding: 10
    },
    selectedPackage: {
        backgroundColor: grey[100],
        paddingLeft: 10,
        paddingRight: 10,
        borderLeft: "2px solid",
        borderCollor: theme.palette.header?.main
    }
})
);


/**
 * Packages component
 * @param {*} props 
 */
export default function Packages (props) {
    const [packages, setPackages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [lastActivePackage, setLastActivePackage] = useState(null);
    const classes = useStyles();

    useEffect(() => {
        // return functions to parent
        props.addNewPackage(addNew);
        props.addSelected(addSelectedServices);

    })

    useEffect(() => {
        if (packages.length > 0) return;

        // get all packages from server
        get(UrlEnum.servicePackages).then((response) => {
            if (response.errors) {
                alert(response.errors instanceof Array ?
                    response.errors.join("\n<br/>") :
                    response.errors);
                setLoading(false);
                return false;
            }
            setPackages(response);
            setLoading(false);
        });
    }, []);

    /**
     * delete package success
     * @param {*} deletedPackage 
     */
    function deleteSuccess (deletedPackage) {
        let newPackages = packages.slice();
        for (let i = 0; i < packages.length; i++) {
            let p = packages[i];
            if (p.id === deletedPackage.id) {
                newPackages.splice(i, 1);
                setPackages(newPackages);
                return;
            }
        }
    }

    /**
     * add new package to list
     */
    function addNew () {
        // if new package exists don't add another
        let newExists = packages.find((p) => p.id === 0);
        if (newExists) {
            return false;
        }
        let newPackages = packages.slice();
        newPackages.unshift(new Package());
        setPackages(newPackages);
        return false;
    }

    /**
     * 
     * @param {*} selected 
     */
    function addSelectedServices (selected) {
        if (!lastActivePackage || !selected || !selected.length > 0) {
            return false;
        }
        const newServiceIds = selected.map((s) => s.id);
        post(UrlEnum.servicePackages + "/services/" + lastActivePackage.id, { newServiceIds })
            .then((response) => {
                if (response.errors) {
                    alert(response.errors);
                    return;
                }
                // render
                let pack = packages.find((p) => p.id === lastActivePackage.id);
                if (pack) {
                    pack.services = pack.services.concat(selected);
                }
                setPackages(packages.slice());
            });
        return true;
    }

    /**
     * 
     * @param {*} index 
     * @param {*} pack 
     */
    function onEditPackage (index, pack) {
        let newPackages = packages.slice();
        newPackages.splice(index, 1, pack);
        setPackages(newPackages);
    }

    return (
        <Fragment>
            {
                loading ?
                    <CircularProgress classes={{ root: classes.loading }} color="secondary" ></CircularProgress> :
                    packages.map((pack, index) =>
                        <ListItem button key={index + pack.id}
                            className={clsx(classes.listItem, {
                                [classes.selectedPackage]: pack.id === lastActivePackage?.id
                            })}
                        >
                            <EditPackage pack={pack}
                                getActive={(p) => setLastActivePackage(p)}
                                open={pack.id === 0 ? true : false}
                                onEdit={(pack) => onEditPackage(index, pack)}
                            />
                            <DeleteIconBtn
                                classes={{ root: classes.iconBtn }}
                                url={UrlEnum.servicePackages + "/" + pack.id}
                                onSuccess={deleteSuccess}
                                data={pack}
                            />
                        </ListItem>
                    )
            }
        </Fragment>
    );
};

Packages.propTypes = {
    addNewPackage: PropTypes.func.isRequired,
    addSelected: PropTypes.func.isRequired
};