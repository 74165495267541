import { Box, Divider, InputLabel, Select } from "@material-ui/core";
import { Euro } from "@material-ui/icons";
import React, { useState } from "react";
import Lang from "../../../lang";
import { monthsName } from "../../../Utils/Utils";

type OverviewProps = {
  title: string;
  icon: () => void;
  months: Array<number>;
  totalValue: number;
  style: {
    box: any;
    innerLabel: any;
    select: any;
    label: any;
    icon: any;
    div:any;
    optionColor:any;
    titleLabel:any;
  };
};

export default function DashboardOverview(props: OverviewProps) {
  const { title, icon, months, totalValue, style } = props;
  const lang = Lang.getInstance();
  const [state, setState] = useState({
    selectedMonth: months ? months.length - 1 : 0
  });

  return (
    <Box style={style.box}>
      <div style={style.div}>
        <InputLabel style={style.titleLabel}>{title}</InputLabel>
        {icon()}
      </div>
      <Divider style={{ background: "#ffff" }}/>
      <div style={style.div}>
        <InputLabel style={style.innerLabel}>
          {lang.get("totalWithVat")}
        </InputLabel>
        <Select
          native
          id={"month"}
          value={monthsName()[state.selectedMonth]}
          style={style.select}
          onChange={event => {
            setState({ selectedMonth: (event.target as any).selectedIndex });
          }}
        >
          {monthsName().map((value: any, key: any) => {
            return (
              <option value={value} key={key} style={style.optionColor}>
                {value}
              </option>
            );
          })}
        </Select>

        <InputLabel style={style.label}>
          {months[state.selectedMonth]
            ? months[state.selectedMonth].toFixed(2)
            : "-"}{" "}
          <Euro style={style.icon} />
        </InputLabel>
      </div>
      <div style={style.div}>
        <InputLabel style={style.innerLabel}>
          {lang.get("totalWithVat")}
        </InputLabel>
        <InputLabel style={style.label}>
          {totalValue.toFixed(2)} <Euro style={style.icon} />
        </InputLabel>
      </div>
    </Box>
  );
}
