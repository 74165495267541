
import React, { useState } from "react";
import {
  makeStyles,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Lang from '../../lang';
import { AccountInformation } from '../../Models/Models';
import { UrlEnum, get } from '../../Utils/Utils';
import Options from "./Options";

let lang = Lang.getInstance();
const useStyles = makeStyles((theme) =>
  Object.assign({
    modalContent: {
      minWidth: 600,
      paddingRight: 10,
      paddingLeft: 10,
      outline: "none",
    },}));
export default function CompanyOptions(){
    const classes = useStyles();
  const [accountOptions, setAccountoptions] = useState(AccountInformation());
  const [isModalOpen, setIsModalOpen]= useState(true);

  function handleCloseModal()
  {
    // this.submit.bind(this);
    setIsModalOpen(false)
  }
 return (   <div>
    <Dialog open={isModalOpen} onClose={handleCloseModal}>
      <DialogContent className={classes.modalContent}>
        <div
          style={{
            position: "relative",
            padding: 5,
            // borderBottom: "1px solid #ccc",
          }}
        >
            <Typography
              variant="h5"
              gutterBottom
              style={{ textAlign: "center", margin: "5px" }}
            >
              {lang.get("companyDetails")}
            </Typography>
            <IconButton
              style={{ position: "absolute", right: 0, top: 0 }}
              color="inherit"
              id="addNew"
              onClick={handleCloseModal}
            >
              <CloseIcon />
            </IconButton>
          </div>
        <Options
        url={UrlEnum.companyOptions}
        model={accountOptions}
        isModal={true}
        closeModal={handleCloseModal}/>
        <DialogActions></DialogActions>
    </DialogContent>
    </Dialog>
    </div>);
}