import React from "react";
import PropTypes from "prop-types";
import Lang from "../lang";
import { Address } from "../Models/Models";
import moment from "moment";
import Config from "../Utils/Config";
const lang = Lang.getInstance();

/** document styles **/
const style1 = {
  headerWrapper: {
    marginRight: -20,
    fontSize: "9pt",
  },
  invoiceText: {
    fontSize: "30px",
    margin: 0,
    textTransform: "uppercase",
  },
  logoWrapper: {
    width: "48%",
    display: "inline-table",
    marginLeft: 10,
    verticalAlign: "top",
  },
  companyWrapper: {
    width: "48%",
    display: "inline-table",
    marginRight: 15,
    verticalAlign: "top",
    fontSize: "11pt",
  },
  docInvoiceInfoWrapper: {
    width: "48%",
    display: "inline-table",
    marginLeft: 5,
    verticalAlign: "top",
  },
  clientWrapper: {
    width: "48%",
    display: "inline-table",
    marginRight: -10,
    verticalAlign: "top",
    fontSize: "11pt",
  },
  logo: {
    maxHeight: 100,
  },
  clear: {
    clear: "both",
    borderBottom: "10px solid #ccc",
    marginBottom: 20,
    marginTop: 15,
    marginRight: 15,
  },
};

const style2 = {
  headerWrapper: {
    marginRight: -20,
    fontSize: "10pt",
  },
  invoiceText: {
    fontSize: "30px",
    margin: 0,
    color: "#000000",
  },
  logoWrapper: {
    width: "100%",
    flexGrow: 3,
    marginBottom: 20,
  },
  companyWrapper: {
    width: "30%",
    marginLeft: 0,
    marginRight: 15,
    display: "inline-table",
    fontSize: "11pt",
    verticalAlign: "top",
  },
  docInvoiceInfoWrapper: {
    width: "30%",
    border: "1px solid #000",
    display: "inline-table",
    padding: 10,
    marginRight: 20,
    fontSize: 10,
    verticalAlign: "top",
  },
  clientWrapper: {
    width: "30%",
    display: "inline-table",
    marginLeft: 15,
    marginRight: -10,
    fontSize: "11pt",
    verticalAlign: "top",
  },
  logo: {
    maxHeight: 100,
    display: "block",
    margin: "0 auto",
  },
};

const style3 = {
  headerWrapper: {
    marginRight: -20,
    fontSize: "10pt",
  },
  invoiceText: {
    fontSize: "30px",
    margin: 0,
    color: "#000000",
    textTransform: "uppercase",
  },
  logoWrapper: {
    width: "48%",
    display: "inline-table",
    marginLeft: 10,
    float: "right",
    verticalAlign: "top",
  },
  docInvoiceInfoWrapper: {
    width: "48%",
    display: "inline-table",
    marginLeft: 5,
    float: "left",
    verticalAlign: "top",
  },
  companyWrapper: {
    width: "48%",
    display: "inline-table",
    marginRight: 15,
    verticalAlign: "top",
    fontSize: "11pt",
  },
  clientWrapper: {
    width: "48%",
    display: "inline-table",
    marginRight: -10,
    fontSize: "11pt",
    verticalAlign: "top",
  },
  logo: {
    maxHeight: 100,
    width: "auto",
    marginRight: 40,
    marginLeft: "auto",
    display: "block",
  },
  clear: {
    clear: "both",
    borderBottom: "10px solid #ccc",
    marginBottom: 20,
    marginTop: 20,
    marginRight: 20,
  },
};

const style4 = {
  invoiceText: {
    fontSize: "30px",
  },
  col30: {
    display: "inline-block",
    width: "30%",
    marginLeft: 5,
    marginRight: 5,
    height: 80,
    verticalAlign: "top",
    fontSize: "11pt",
  },
  col70: {
    display: "inline-block",
    width: "65%",
    marginLeft: 5,
    marginRight: 5,
    verticalAlign: "top",
    fontSize: "11pt",
  },
  col49: {
    display: "inline-block",
    width: "48%",
    marginLeft: 5,
    fontSize: "11pt",
  },
  box: {
    background: "#ddd",
    border: "1px solid #000",
    padding: 10,
    textAlign: "center",
  },
  logo: {
    maxWidth: 100,
    display: "block",
  },
  title: {
    marginBottom: 35,
    marginTop: 35,
    textAlign: "center",
  },
  table: {
    width: "100%",
    background: "#ddd",
    marginTop: 30,
    border: "1px solid #000",
  },
  tr: {
    padding: 0,
  },
  td: {
    borderLeft: "1px solid #000",
    padding: 10,
    textAlign: "center",
  },
};

export default function InvoiceHeader(props) {
  const company = props.company;
  const client = props.client;
  let styles = style1;

  const renderStyle3Header = () => {
    let paymentDate = "";
    if (props.paymentDate) {
      paymentDate = moment(props.date, Config.momentEUDateFormat);
      paymentDate.add(props.paymentDate, "days");
      paymentDate = paymentDate.format(Config.momentEUDateFormat);
    }
    return (
      <div>
        <div style={styles.col49}>
          {company.logo && company.logo !== "undefined" ? (
            <img src={company.logo} alt="logo" style={styles.logo} />
          ) : (
            <p style={styles.invoiceText}>{company.name}</p>
          )}
          {company.description !== "null" ? (
            <>
              <small>{company.description}</small>
              <br />
            </>
          ) : (
            ""
          )}
        </div>

        <div style={styles.col49}>
          <strong>{company.name}</strong>
          <br />
          <em>
            {lang.get("address")}:
            <small>
              {" "}
              {Address.stringify(company.address ? company.address : "")}
            </small>
          </em>
          <br />
          <small>
            <strong>{lang.get("bankName")}</strong>
          </small>
          :<small>{company.bankName ? company.bankName : ""}</small>
          <br />
          <small>
            <strong>{lang.get("bankAccount")}</strong>
          </small>
          : <small>{company.bankAccount ? company.bankAccount : ""}</small>
          <br />
          <small>
            <strong>{lang.get("vatNo")}</strong>
          </small>
          : <small>{company.vatNo !== "null" ? company.vatNo : ""}</small>
          <br />
        </div>

        <h1 style={{ ...styles.title, color: "#000", fontSize: "30px" }}>
          {lang.get(props.docType || "invoice")}
        </h1>

        <div style={{ width: "100%" }}>
          <div style={styles.col49}>
            {lang.get("series")}: {props.series || ""} {lang.get("number")}:{" "}
            {props.number || ""}
            <br />
            {lang.get("date")}: {props.date || ""}
          </div>
          <div style={{ ...styles.col49, ...styles.box }}>
            <p>
              <strong>{client.title}</strong>{" "}
              <strong>{client.name}</strong>
              <br />
              {client.vatNo && client.vatNo.trim().length > 3 ? (
                <>
                  <em>
                    {lang.get("vatNo")}:{" "}
                    {client.vatNo !== "null" ? client.vatNo : ""}
                  </em>
                  <br />
                </>
              ) : (
                ""
              )}
              {lang.get("address")}: {Address.stringify(client.address)}
            </p>
          </div>
        </div>

        <table style={styles.table} cellPadding={0} cellSpacing={0}>
          <thead>
            <tr style={styles.tr}>
              <th style={styles.td}>{lang.get("reference")}</th>
              <th style={styles.td}>{lang.get("paymentMode")}</th>
              <th style={styles.td}>{lang.get("paymentDate")}</th>
              <th style={styles.td}>{lang.get("clientNo")}</th>
            </tr>
          </thead>
          <tbody>
            <tr style={styles.tr}>
              <td style={styles.td}>
                {props.series || ""}/{props.number || ""}
              </td>
              <td style={styles.td}>{lang.get(props.paymentMode || "")}</td>
              <td style={styles.td}>{paymentDate}</td>
              <td style={styles.td}>{client.id}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  if (props.appearance === 3) {
    styles = style4;
    return renderStyle3Header();
  }

  if (props.appearance) {
    switch (props.appearance) {
      case 0:
        styles = style1;
        break;
      case 1:
        styles = style2;
        break;
      case 2:
        styles = style3;
        break;
      default:
        styles = style1;
        break;
    }
  }

  return (
    <div style={styles.headerWrapper}>
      <div style={styles.logoWrapper}>
        {company.logo && company.logo !== "undefined" ? (
          <img src={company.logo} alt="logo" style={styles.logo} />
        ) : (
          <p style={styles.invoiceText}>{company.name}</p>
        )}
      </div>
      <div style={styles.docInvoiceInfoWrapper}>
        <h1 style={{ margin: 0, color: "#000", ...styles.invoiceText }}>
          {lang.get(props.docType || "invoice")}
        </h1>
        <p>
          {lang.get("series")}: {props.series || ""} {lang.get("number")}:{" "}
          {props.number || ""}
          <br />
          {lang.get("date")}: {props.date || ""}
          <br />
        </p>
      </div>
      {props.appearance !== 1 ? (
        <div style={{ ...styles.clear, borderColor: props.color }}></div>
      ) : (
        ""
      )}
      <div style={styles.companyWrapper}>
        <h3 style={{ margin: 0, fontSize: "11pt" }}>{lang.get("supplier")}</h3>

        <p>
          <strong>{company.name}</strong>
          <br />
          <strong>{lang.get("vatNo")}</strong>:{" "}
          {company.vatNo !== "null" ? company.vatNo : ""}
          <br />
          <strong>{lang.get("companyRegNo")}</strong>:{" "}
          {company.companyRegNo || ""}
          <br />
          <strong>{lang.get("address")}</strong>:{" "}
          {Address.stringify(company.address)}
          <br />
          <strong>{lang.get("bankName")}</strong>: {company.bankName}
          <br />
          <strong>{lang.get("bankAccount")}</strong>: {company.bankAccount}
        </p>
      </div>

      <div style={styles.clientWrapper}>
        <h3 style={{ margin: 0, fontSize: "11pt" }}>{lang.get("client")}</h3>

        <p>
          <strong>{client.title || ""}</strong>{" "}
          <strong>{client.name || ""}</strong>
          <br />
          <strong>{lang.get("vatNo")}</strong>:{" "}
          {client.vatNo !== "null" ? client.vatNo : ""}
          <br />
          <strong>{lang.get("address")}</strong>:{" "}
          {Address.stringify(client.address)}
          <br />
          <strong>{lang.get("bankName")}</strong>: {client.bankName}
          <br />
          <strong>{lang.get("bankAccount")}</strong>: {client.bankAccount}
        </p>
      </div>
    </div>
  );
}

InvoiceHeader.propTypes = {
  company: PropTypes.object.isRequired,
  series: PropTypes.string,
  number: PropTypes.string,
  date: PropTypes.string,
  vatRate: PropTypes.number,
  client: PropTypes.object.isRequired,
  appearance: PropTypes.number,
  color: PropTypes.string,
  paymentMode: PropTypes.string,
  paymentDate: PropTypes.string,
  docType: PropTypes.string,
};
