import React, { Fragment, useState, useEffect } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { post } from '../Utils/Utils';
import PropTypes from 'prop-types';
import Lang from '../lang';
import theme from "../Theme/Theme"
const styles = {
    alert: {
        marginTop: 10,
        padding:4,
    },
    loading: {
        width: 20,
        height: 20,
        marginLeft: 10
    }
};

let messageTimeout = null;
export default function FormSubmitButton(props){
    const [state, setState] = useState({
        loading: props.loading || false,
        errors: props.errors || null,
        renderMessages:null,
        success:false
    });
    const lang = Lang.getInstance();

    /**
     * return the submit function
     */
    useEffect(() => {
        if(props.getSubmit){
            props.getSubmit(submit);
        }
        return ()=>{
            if(messageTimeout){
                clearTimeout(messageTimeout);
            }
        }
    },[]);

    useEffect(() => {
        setState({loading:props.loading, errors:props.errors})
    },[props.loading, props.errors]);

     /**
     * send data to server
     */
    const submit = async (model) => {
        let newState = Object.assign({},state);
        newState.loading= true;

        let url = !isNaN(model.id) ? `${props.url}/${model.id}` : props.url;

        let data = await post(url, model);

        // hide messages after delay
        const renderTimeout = 7 * 1000;
        messageTimeout = setTimeout(() => {
            let newState = Object.assign({},state);
            newState.renderMessages = false;
            setState(newState);
        }, renderTimeout);

        if (data.errors) {
            newState.errors = data.errors instanceof Array ? data.errors.join("<br/>") : data.errors;
            newState.loading = false;
            newState.renderMessages = true;
            setState(newState);
            return;
        }

        newState.success = true;
        newState.renderMessages = true;
        newState.loading = false;

        setState(newState);

        // update model values with new values from server
        for(const key in model){
            if(data[key] && model[key] !== data[key]){
                if(typeof model[key] === "number"){
                    model[key] = parseFloat(data[key]);
                }else{
                    model[key] = data[key];
                }
            }
        }
        if(props.submitCallback){
            props.submitCallback(model);
        }
    }

    let message = (
        <Button
            fullWidth = {props.fullWidth ? props.fullWidth : false }
            classes={props.classes ? props.classes : {}}
            style={{background:theme.palette.header?.main,color: theme.palette.textColorSecondary?.main,}}
            variant="contained"
            disabled={state.loading}
            type="submit"
            onClick = {props.onClick ? props.onClick : null}
            >
            {props.children ? props.children : lang.get("save")}
            {state.loading ?
                <CircularProgress color="secondary" style={styles.loading} />
                : ""
            }
        </Button>
    );
    if (state.renderMessages) {
        if (state.success) {
            message = (
                <Fragment>
                    {message}
                    <Alert severity="success" style={styles.alert}>
                        {lang.get("success")}
                    </Alert>
                </Fragment>
            );
        }
        if (state.errors) {
            message = (
                <Fragment>
                    {message}
                    <Alert severity="error" style={styles.alert}>
                        {state.errors}
                    </Alert>
                </Fragment>
            );
        }
    }
    return message;
}


FormSubmitButton.propTypes={
    url: PropTypes.string,
    submitCallback:PropTypes.func,
    getSubmit: PropTypes.func,
    classes:PropTypes.object,
    fullWidth:PropTypes.bool,
    onClick: PropTypes.func,
    loading: PropTypes.bool,
    errors: PropTypes.object,
    children: PropTypes.any
};