import React, {useState, useEffect} from 'react';
import {makeStyles, fade, InputBase, IconButton, Fade} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import Lang from '../lang';
import PropTypes from 'prop-types';
import clsx from 'clsx';
// lang
const lang = Lang.getInstance();

// styles
  
export default function SearchBar(props) {
    const useStyles = makeStyles((theme)=>(Object.assign({
      searchIcon:{
        position:"absolute",
        top:-7,
        left:-5
      },
      searchIconDropdown:{
        position:"inherit",
      },
      search: {
          position: 'relative',
          marginRight: theme.spacing(1),
          marginLeft: 0,
          [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
          }
        },
        inputRoot: {
          color: 'inherit',
          borderRadius: theme.shape.borderRadius,
          backgroundColor: fade(theme.palette.common.white, 0.15),
          '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
          },
          boxShadow: "1px 1px 2px #777",
          marginBottom:5
        },
        inputRootDropdown: {
          backgroundColor: "#ffffff",
          opacity:"100%",
          top:43,
          color:theme.palette.text.primary,
          borderRadius: theme.shape.borderRadius,
          boxShadow: "2px 2px 5px #555",
          position:"absolute",
          width:250,
          zIndex:1
        },
        inputInput: {
          padding: theme.spacing(1, 1, 1, 0),
          // vertical padding + font size from searchIcon
          paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
          transition: theme.transitions.create('width'),
          width: '100%',
          [theme.breakpoints.up('md')]: {
            width: '20ch',
          },
        },
        inputInputDropdown:{
          padding: theme.spacing(1, 1, 1, 1),
          transition: theme.transitions.create('width'),
          maxWidth:200,
          top:50
        }
      }, props.styles)));
    const classes = useStyles(props);
    const [open, setOpen] = useState(props.dropdown ? false : true);
    const inputRef = React.createRef();

    useEffect(() => {
      if(open){
        inputRef.current.focus();
      }
    }, [open]);
    /**
     * open searchbox
     * @param {*} e 
     */
    const openSearchBox = (e)=>{
      setOpen(!open);
    };
    
    return(
        <form onSubmit={props.onSubmit}>
          <div className={classes.search}>
              <div className={clsx({[classes.searchIcon]:!props.dropdown, [classes.searchIconDropdown]:props.dropdown})}>
                <IconButton disabled={props.disabled} color="inherit" onClick={openSearchBox}><SearchIcon /></IconButton>
              </div>
              {
              <Fade in={open}>
                <InputBase
                  inputRef={inputRef}
                  placeholder={lang.get("search")}
                  onChange={props.onChange}
                  value={props.value}
                  classes={{
                    root: props.dropdown? classes.inputRootDropdown : classes.inputRoot,
                    input: props.dropdown? classes.inputInputDropdown : classes.inputInput,
                  }}
                  inputProps={{ 'aria-label': 'search' }}
                />
              </Fade> 
              }
            </div>
          </form>
    );
}

SearchBar.propTypes = {
    dropdown: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    onSubmit: PropTypes.func,
    value: PropTypes.string,
    styles: PropTypes.object,
};