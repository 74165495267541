import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Button, IconButton } from '@material-ui/core';
import CloseIcon from "@material-ui/icons/Close";
import Lang from '../lang';
import PropTypes from 'prop-types';
import theme from "../Theme/Theme";
export default function MessageDialog(props) {
  const lang = Lang.getInstance();


  const handleClose = (e) => {
      props.onResult();
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.onClose?props.onClose:handleClose}
        aria-labelledby="message"
        aria-describedby="message dialog"
      >
        <DialogTitle id="alert-dialog-title"><p>{ props.title ? props.title : lang.get("alert")}</p></DialogTitle>
        {props.showButton ? <IconButton
              style={{ position: "absolute", right: 0, top: 0, margin:15 }}
              color="inherit"
              id="exit"
              onClick={props.onClose}
            >
              <CloseIcon />
            </IconButton>: null}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.text}
          </DialogContentText>
          {props.children}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} id="ok" style={{color: theme.palette.header?.main}}>
            {lang.get("ok")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

MessageDialog.propTypes = {
  title:PropTypes.string,
  text: PropTypes.string,
  onResult: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  showButton: PropTypes.bool,
  children: PropTypes.element
};
