import React from "react";
import { ResponsivePie } from "@nivo/pie";
import Lang from "../../../lang";
import CachedDataSingleton from "../../../cachedDataSingleton";
import { Grid, InputLabel } from "@material-ui/core";
import { graphColors } from "../../../Utils/Utils";
import WithChartsFilters from "./WithChartsFilters";

type EstimatesNotUsedProps = {
  overview: any;
  startMonth: number;
  endMonth: number;
  selectedStartYear: number;
  selectedEndYear: number;
};

function EstimatesNotUsed(props: EstimatesNotUsedProps) {
  const lang = Lang.getInstance();
  const cachedData = CachedDataSingleton.getInstance();
  const overview = props.overview ? props.overview : cachedData.get("overview");
  const estimates = overview.estimates;
  const orderTickets = overview.orderTickets;

  let estimatesData: {
    id: string | number;
    color: string | undefined;
    label: string;
    value: number;
  } = {
    id: lang.get("estimates") + " - Euro",
    color: graphColors().colorsTransparent.estimates,
    label: lang.get("estimates"),
    value: 0
  };
  let orderTicketData: {
    id: string | number;
    color: string | undefined;
    label: string;
    value: number;
  } = {
    id: lang.get("orderTicket") + " - Euro",
    color: graphColors().colorsTransparent.orderTickets,
    label: lang.get("orderTicket"),
    value: 0
  };

  /**
   * 
   * @returns Returns an array with the data formatted for a Pie chart
   */
  function fetchChartData() {
    let graphData = [];

    let index = props.startMonth ? props.startMonth : 0;
    const estimatesLength =
      12 * (props.selectedEndYear - props.selectedStartYear) +
      props.endMonth +
      1;

    let orderTicketsTotal = 0;
    for (index; index < estimatesLength; index++) {
      orderTicketsTotal +=
        orderTickets.months && orderTickets.months[index]
          ? orderTickets.months[index]
          : 0;
    }
    let estimatesTotal = 0;
    index = props.startMonth ? props.startMonth : 0;
    for (index; index < estimatesLength; index++) {
      estimatesTotal += estimates.months[index] ? estimates.months[index] : 0;
    }
    const unusedEstimates =
      Math.abs(estimatesTotal) - Math.abs(orderTicketsTotal);
    const orderTicketsValue =
    estimatesTotal === 0? 0:(Math.abs(orderTicketsTotal) * 100) / estimatesTotal;
    const estimatesValue =  estimatesTotal === 0? 0:(Math.abs(unusedEstimates) * 100) / estimatesTotal;

    estimatesData.value = parseFloat(estimatesValue.toFixed(2));
    orderTicketData.value = parseFloat(orderTicketsValue.toFixed(2));

    graphData.push(estimatesData);
    graphData.push(orderTicketData);
    return graphData;
  }

  const chartData = fetchChartData();
  return (
    <>
      <InputLabel
        style={{
          color: "#000",
          marginBottom: 5,
          fontSize: 15,
          justifyContent: "center",
          alignContent: "center",
          display: "flex"
        }}
      >
        {lang.get("estimates") + " " + lang.get("notUsed")}
      </InputLabel>
      <Grid container spacing={3}>
        <Grid item md={6} sm={12} xs={12} style={{ minWidth: "100%" }}>
          {/** CHART */}
          <div style={{ height: 300, marginBottom: 10 }}>
            <ResponsivePie
              colors={[
                graphColors().colorsTransparent.estimates,
                graphColors().colorsTransparent.orderTickets
              ]}
              data={chartData}
              margin={{ top: 20, right: 110, bottom: 100, left: 70 }}
              innerRadius={0.5}
              padAngle={0.7}
              cornerRadius={3}
              borderWidth={1}
              borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
              legends={[
                {
                  anchor: "bottom",
                  direction: "row",
                  justify: false,
                  translateX: 0,
                  translateY: 56,
                  itemsSpacing: 0,
                  itemWidth: 100,
                  itemHeight: 18,
                  itemTextColor: "#999",
                  itemDirection: "left-to-right",
                  itemOpacity: 1,
                  symbolSize: 18,
                  symbolShape: "circle",
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemTextColor: "#000"
                      }
                    }
                  ]
                }
              ]}
            ></ResponsivePie>
          </div>
        </Grid>
      </Grid>{" "}
    </>
  );
}
export default WithChartsFilters(EstimatesNotUsed,"estimates");
