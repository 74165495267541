import React, { Component } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import PropTypes from 'prop-types';
import { TextField, Collapse, withStyles, ListItemText } from '@material-ui/core';
import Lang from '../lang';
import FormSubmitButton from '../Forms/FormSubmitButton';
import { UrlEnum } from '../Utils/Utils';

class EditArticle extends Component {

    constructor(props){
        super(props);
        this.state={
            model: props.model,
            openArticle: props.open || false
        }
        this.submitFunction = null;
        this.lang = Lang.getInstance();
    }

     /**
     * click on the body of the article
     * @param {*} e 
     */
    handleOpenArticle(e){
        e.preventDefault();
        this.setState({"openArticle":!this.state.openArticle});
        return false;
    }

    /**
     * 
     * @param {*} event 
     */
    handleInputChange(event){
        const name = event.currentTarget.name || event.currentTarget.id;
        let model = Object.assign({}, this.state.model);
        let value = event.target.value;
        model[name] = value;
         
        // just because it exists on the server
        // model.price = model.totalPrice;
        this.setState({ "model": model });
        
        return false;
    }

    /**
     * 
     * @param {*} article 
     */
    submitCallBack(article){

    }

    submit(){
        if(this.submitFunction){
            return this.submitFunction(this.state.model);
        }
    }

    render() {
        const {classes} = this.props;
        return (
            <div style={{flexGrow:1, marginTop:12}}>
                <div 
                    className={classes.listItemTextWrapper}
                    onClick={this.handleOpenArticle.bind(this)}>
                    <ListItemText
                        primary={this.state.model.name}
                        classes={{ primary: classes.listItemText }}
                        style={{marginTop:0}}
                    />
                </div>
                <Collapse
                    in={this.state.openArticle}
                    unmountOnExit
                    className={classes.articleFormWrapper}>
                    <ValidatorForm onSubmit={this.submit.bind(this)}>
                        <TextValidator
                            label={this.lang.get("name")}
                            fullWidth
                            name="name"
                            value={this.state.model.name}
                            onChange={this.handleInputChange.bind(this)}
                        />

                        <TextField
                            name="description"
                            onChange={this.handleInputChange.bind(this)}
                            label={this.lang.get("description")}
                            fullWidth
                            value={this.state.model.description}
                        />

                        {/** brand  */}
                        <TextField
                            name="brand"
                            onChange={this.handleInputChange.bind(this)}
                            label={this.lang.get("brand")}
                            fullWidth
                            value={this.state.model.brand}
                        />

                        {/** price */}
                        <TextField
                        type="number"
                            required
                            name="price"
                            onChange={this.handleInputChange.bind(this)}
                            label={this.lang.get("price")}
                            fullWidth
                            value={this.state.model.price}
                        />

                        {/** groupName  */}
                        <TextField
                    
                            name="groupName"
                            required
                            onChange={this.handleInputChange.bind(this)}
                            label={this.lang.get("groupName")}
                            fullWidth
                            value={this.state.model.groupName}
                        />


                        {/** tradeMargin  */}
                        <TextField
                            type="number"
                            name="tradeMargin"
                            required
                            onChange={this.handleInputChange.bind(this)}
                            label={this.lang.get("tradeMargin")}
                            fullWidth
                            value={this.state.model.tradeMargin}
                        />
                        

                        {/** um  */}
                        <TextField
                            name="um"
                            required
                            onChange={this.handleInputChange.bind(this)}
                            label={this.lang.get("measureUnit")}
                            fullWidth
                            value={this.state.model.um}
                        />

                         {/** itemcode */}
                         <TextField
                            name="itemcode"
                            required
                            onChange={this.handleInputChange.bind(this)}
                            label={this.lang.get("code")}
                            fullWidth
                            value={this.state.model.itemcode}
                        />
                        <br/><br/>
                        <FormSubmitButton 
                            getSubmit={(fct)=>this.submitFunction = fct}
                            submitCallback={this.submitCallBack.bind(this)}
                            url={UrlEnum.articles}
                            /> 
                    </ValidatorForm>
                </Collapse>
            </div>
        );
    }
}

const styles = (theme) => ({
    listItemText: {
        fontSize: "10pt",
        textTransform: "capitalize",
        marginLeft: 10,
        marginTop:0,
        marginBottom:10,
        display: "block",
        flexGrow: 0
    },
    listItemTextWrapper:{
        display: "flex",
        width:"100%"
    },
    articleFormWrapper:{
        // backgroundColor:"#e8eef5",
        padding:15
    },
    price:{
        marginLeft:10,
        marginRight:10,
        textAlign:"right"
    }
});

EditArticle.propTypes = {
    "article": PropTypes.object,
    "open": PropTypes.bool
};

export default withStyles(styles, { withTheme: true })(EditArticle);