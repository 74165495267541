import React, { Component } from 'react';
import { DocService } from '../Models/Models';
import { TextField, Collapse, withStyles, createStyles, Grid, Button, Theme } from '@material-ui/core';
import Lang from '../lang';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import clsx from 'clsx';
import Config from '../Utils/Config';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import theme from "../Theme/Theme";
type DocServiceProps = {
    model: DocService;
    key: number;
    classes: any;
    width: any;
    vatRate: number;
    handleSelectChange: (event: React.ChangeEvent<HTMLInputElement>, docService: DocService) => void;
    handleInputChange: (event: React.ChangeEvent<HTMLInputElement>, docService: DocService) => void;
    handleChangeOpen: (indexService: number) => void;
    disabled?: boolean;
    indexService:number;
};

type DocServiceState = {
    open: boolean;
    model: DocService;
};

class EditDocService extends Component<DocServiceProps, DocServiceState>{
    submitFunction: null;
    lang: any;
    disabled: boolean | undefined;

    constructor(props: DocServiceProps) {
        super(props);
        this.state = {
            open: false,
            model: props.model
        }
        this.submitFunction = null;
        this.lang = Lang.getInstance();
        this.disabled = props.disabled;
    }

    shouldComponentUpdate(nextProps: DocServiceProps, nextState: DocServiceState) {
        if (nextState.open !== this.state.open) return true;
        if (nextProps.model.selected !== this.props.model.selected) return true;
        if (nextProps.vatRate !== this.props.vatRate) return true;
        if (this.disabled !== nextProps.disabled) {
            this.disabled = nextProps.disabled;
            return true;
        }
        if (nextProps.model === this.props.model) return false;

        return true;
    }

    /**
    * click on the body of the service
    * @param {*} e 
    */
    private handleOpenService(e: React.MouseEvent) {
        e.preventDefault();
        this.setState({ "open": !this.state.open });
        return false;
    }

    /**
     * 
     * @param nextProps 
     */
    componentWillReceiveProps(nextProps: DocServiceProps) {
        if (nextProps.model.selected !== this.props.model.selected) {
            this.setState({
                model: nextProps.model
            });
        };
    }

    /**
     * render the top info of a service
     */
    private renderLine() {
        if (this.props.width === "xs" ||
            this.props.width === "sm") {
            return (
                <Grid container classes={{ root: "docServiceWrapper" }}>
                    <Grid item  >
                        <input
                            type="checkbox"
                            disabled={this.props.disabled}
                            style={{ marginRight: 20 ,color:theme.palette.header?.main}}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => { this.props.handleSelectChange(event, this.props.model); }}
                            checked={this.state.model.selected || false}
                        />
                    </Grid>
                    <Grid item md={10} sm={10} xs={9}>
                        {/** service name */}
                        <TextField
                            disabled={this.props.disabled}
                            fullWidth
                            multiline
                            name="serviceName"
                            value={this.props.model.serviceName}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.props.handleInputChange(event, this.props.model)}
                        />
                    </Grid>
                    <Grid item  >
                        <Button disabled={this.props.disabled} onClick={this.handleOpenService.bind(this)}><MoreVertIcon /></Button>
                    </Grid>
                </Grid>
            )
        }
        return (
            <ValidatorForm onSubmit={()=>{}}>
                <Grid container classes={{ root: "docServiceWrapper" }}>
                    <Grid item  >
                        <input
                            disabled={this.props.disabled}
                            type="checkbox"
                            style={{ marginLeft: 25,marginTop:7 ,color:theme.palette.header?.main}}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => { this.props.handleSelectChange(event, this.props.model); }}
                            checked={this.props.model.selected || false}
                        />
                    </Grid>
                    <Grid item lg={5} md={4} sm={3} xs={6}>
                        {/** service name */}
                        <TextField
                            disabled={this.props.disabled}
                            fullWidth
                            multiline
                            name="serviceName"
                            value={this.props.model.serviceName}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.props.handleInputChange(event, this.props.model)}
                        />
                    </Grid>
                    <Grid item md={1} xs={1}>
                        {/** measure unit */}
                        <TextField
                            disabled={this.props.disabled}
                            name="serviceUm"
                            required
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.props.handleInputChange(event, this.props.model)}
                            fullWidth
                            value={this.props.model.serviceUm}
                        />
                    </Grid>
                    <Grid item md={1} xs={1}>
                        {/** quantity */}
                        <TextValidator
                            disabled={this.props.disabled}
                            name="serviceQuantity"
                            type="text"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.props.handleInputChange(event, this.props.model)}
                            fullWidth
                            value={this.props.model.serviceQuantity}
                        />
                    </Grid>
                    <Grid item md={1} xs={1}>
                        {/** price */}
                        <TextValidator
                            disabled={this.props.disabled}
                            name="servicePrice"
                            type="text"
                            validators={['required', 'matchRegexp:^(-?\\d*\\.{0,1})*$']}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.props.handleInputChange(event, this.props.model)}
                            fullWidth
                            value={this.props.model.servicePrice}
                        />
                    </Grid>
                    <Grid item md={1} xs={1}>
                        {/** total price */}
                        <label>{parseFloat(this.props.model.totalServicePrice.toFixed(Config.noOfDecimals))}</label>
                    </Grid>
                    <Grid item md={1} xs={1}>
                        {/** vat */}
                        <label>{this.props.model.vat}</label>
                    </Grid>
                    <Grid item  >
                        <Button disabled={this.props.disabled} onClick={this.handleOpenService.bind(this)}><MoreVertIcon /></Button>
                    </Grid>
                </Grid>
            </ValidatorForm>);

    }

    /**
     * render what we see in details
     */
    renderDetails() {
        if (this.props.width === "xs" ||
            this.props.width === "sm") {
            return (
                <div>
                    {/** measure unit */}
                    <TextField
                        name="serviceUm"
                        required
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.props.handleInputChange(event, this.props.model)}
                        fullWidth
                        value={this.props.model.serviceUm}
                        label={this.lang.get("measureUnit")}
                    />

                    {/** quantity */}
                    <TextField
                        name="serviceQuantity"
                        type="number"
                        inputProps={{step:"any"}}
                        required
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.props.handleInputChange(event, this.props.model)}
                        fullWidth
                        value={this.props.model.serviceQuantity}
                        label={this.lang.get("quantity")}
                    />

                    {/** price */}
                    <TextField
                        name="servicePrice"
                        type="number"
                        required
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.props.handleInputChange(event, this.props.model)}
                        fullWidth
                        value={this.props.model.servicePrice}
                        label={this.lang.get("unitPrice")}
                    />

                    {/** total price */}
                    <TextField
                        name="totalServicePrice"
                        disabled
                        fullWidth
                        value={this.props.model.totalServicePrice}
                        label={this.lang.get("value")}
                    />
                    {/** vat */}
                    <TextField
                        name="vat"
                        disabled
                        fullWidth
                        value={this.props.model.vat}
                        label={this.lang.get("vat")}
                    />
                </div>
            );
        }
        return (
            <Grid container spacing={3} classes={{ root: "docServiceExpand" }}>
                <Grid item md={6} sm={6} xs={6}>
                    {/** materialsCost  */}
                    <TextField
                        type="number"
                        name="materialsCost"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.props.handleInputChange(event, this.props.model)}
                        label={this.lang.get("materialsCost")}
                        fullWidth
                        value={this.props.model.materialsCost}
                    />

                    {/** laborCost  */}
                    <TextField
                        type="number"
                        required
                        name="laborCost"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.props.handleInputChange(event, this.props.model)}
                        label={this.lang.get("laborCost")}
                        fullWidth
                        value={this.props.model.laborCost}
                    />
                </Grid>
                <Grid item md={6} sm={6} xs={6} >
                    {/** tradeMargin  */}
                    <TextField
                        type="number"
                        name="tradeMargin"
                        required
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.props.handleInputChange(event, this.props.model)}
                        label={this.lang.get("tradeMargin")}
                        fullWidth
                        value={this.props.model.tradeMargin}
                    />

                    <TextField
                        name="description"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.props.handleInputChange(event, this.props.model)}
                        label={this.lang.get("description")}
                        fullWidth
                        value={this.props.model.description}
                    />
                </Grid>
                <Grid item >
                        <Button 
                        disabled={this.props.disabled} 
                        style={{background: theme.palette.primary?.main, color:"white", padding:5, minWidth:46}}
                        onClick={() => this.props.handleChangeOpen(this.props.indexService)}
                        >{this.lang.get("save")}</Button>
                    </Grid>
            </Grid>
        );
    }

    /**
     * 
     */
    render() {
        const { classes } = this.props;
        return (
            <div className={clsx({ [classes.open]: this.state.open })}>
                {this.renderLine()}

                <Collapse
                    in={this.state.open}
                    unmountOnExit
                    timeout={100}
                    className={classes.serviceFormWrapper}>
                    {this.renderDetails()}
                </Collapse>
            </div>
        );
    }
}

const styles = (theme: Theme) => createStyles({
    listItemText: {
        fontSize: "10pt",
        textTransform: "capitalize",
        marginLeft: 10,
        marginTop: 0,
        marginBottom: 10,
        display: "block",
        flexGrow: 0
    },
    listItemTextWrapper: {
        display: "flex",
        width: "100%"
    },
    serviceFormWrapper: {
        // backgroundColor:"#e8eef5",
        padding: 15
    },
    price: {
        marginLeft: 10,
        marginRight: 10,
        textAlign: "right"
    },
    open: {
        background: "#f8fbff",
        boxShadow: "0px 0px 5px #ccc",
    }
});


export default withStyles(styles, { withTheme: true })(EditDocService);