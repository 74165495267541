import { useState, useEffect } from "react";
import {
  Checkbox,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  makeStyles,
  Button,
  Tooltip,
} from "@material-ui/core";
import TableComponent from "../Components/TableComponent";
import Lang from "../lang";
import { get, post, UrlEnum, handleInputChange } from "../Utils/Utils";
import { UserProfile } from "../Models/Models";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import DeleteIcon from "@material-ui/icons/Delete";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ControlPointIcon from "@material-ui/icons/ControlPoint";
import FormSubmitButton from "../Forms/FormSubmitButton";
import theme from "../Theme/Theme";

const lang = Lang.getInstance();

const useStyle = makeStyles((theme) => ({
  paper: {
    padding: 30,
  },
}));

type CompanyUsersProps = {
  showSmallMessage: (message: string) => void;
  setOpenMessage: (message: string) => void;
};

const CompanyUsers = (props: CompanyUsersProps) => {
  const [editedUser, setEditedUser] = useState(new UserProfile());
  const [editedTableModel, setEditedTableModel] = useState(null);
  const classes = useStyle();
  const tableColumns = [
    { label: lang.get("name"), name: "userName" },
    { label: lang.get("email"), name: "email" },
    {
      label: lang.get("role"),
      name: "user_roles",
      customRender: (u: any) => {
        if (u.user_roles.length !== 0) {
          let userRole = "";
          for (let i = 0; i < u.user_roles.length; i++)
            userRole += u.user_roles[i].role + " ";
          return userRole;
        }
      },
    },
    {
      label: lang.get("receiveCC"),
      name: "receiveCC",
      customRender: (u: any) => {
        return (
          <Checkbox
            name="receiveCC"
            checked={u.receiveCC}
            onChange={(e) => onChangeAddInCC(e, u)}
            style={{ color: theme.palette.header?.main }}
          />
        );
      },
    },
  ];

  /**
   * add roles that user does not already have
   * @param roles
   */
  const addUserRoles = (roles: Array<Role>, databaseRoles: Array<Role>) => {
    if (!editedUser) return;
    const eu = { ...editedUser };
    let extractRoles: Array<any> = [];
    roles.forEach((role) => {
      const ids = role.id.toString().split(",");
      for (let i = 0; i < ids.length; i++) {
        const find = databaseRoles.find(
          (r) => r.id.toString() === ids[i].toString().trim()
        );
        if (find) extractRoles.push(find);
      }
    });

    // const newRoles = extractRoles.filter(
    //   (role: Role) =>
    //     eu.user_roles.find((r: Role) => r.id === role.id) === undefined
    // );

    setEditedUser({ ...eu, user_roles: extractRoles });
  };

  /**
   * remove a role from the user
   * @param role
   */
  const removeUserRole = (role: Role) => {
    if (!editedUser) return;
    editedUser.user_roles.splice(editedUser.user_roles.indexOf(role), 1);
    setEditedUser({ ...editedUser });
  };

  /**
   *
   * @param e
   */
  const onChangeUser = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newUser = handleInputChange(e, editedUser);
    setEditedUser(newUser);
  };

  /**
   *
   * @param event
   * @param user
   */
  const onChangeAddInCC = (
    event: React.ChangeEvent<HTMLInputElement>,
    user: any
  ) => {
    const newUser = Object.assign({}, user);
    newUser.receiveCC = event.target.checked;
    post(`${UrlEnum.companyUsers}/receiveCC/${newUser.id}`, {
      receiveCC: newUser.receiveCC,
    }).then((response) => {
      if (response.error) console.log(response.error);
      else {
        setEditedTableModel(newUser);
      }
    });
  };

  /**
   * if user exists in list, update,
   * else update
   * @param user
   */
  const addOrUpdate = (user: any) => {
    setEditedTableModel(user);
  };

  return (
    <div style={{ padding: "0px 30px 0px 30px" }}>
      <h2>
        {lang.get("addNewUser")}
        <Tooltip title={lang.get("new")}>
          <IconButton
            color="inherit"
            title={lang.get("new")}
            aria-label={lang.get("new")}
            id="addNew"
            onClick={(e: React.MouseEvent) => setEditedUser(new UserProfile())}
          >
            <ControlPointIcon />
          </IconButton>
        </Tooltip>
      </h2>
      <Grid container spacing={5}>
        <Grid item md={4} sm={4} xs={12}>
          <Paper className={classes.paper}>
            <EditUser
              user={editedUser}
              addSelectedRoles={addUserRoles}
              removeRole={removeUserRole}
              onChange={onChangeUser}
              setOpenMessage={props.setOpenMessage}
              addOrUpdate={addOrUpdate}
            />
          </Paper>
        </Grid>
        <Grid item md={8} sm={8} xs={12}>
          <TableComponent
            url={UrlEnum.companyUsers}
            columns={tableColumns}
            search={true}
            editCallback={(user: any) => setEditedUser(user)}
            editedModels={[editedTableModel]}
          />
        </Grid>
      </Grid>
    </div>
  );
};

type EditUserProps = {
  user: UserProfile;
  addSelectedRoles: (roles: Array<Role>, databaseRoles: Array<Role>) => void;
  removeRole: (role: Role) => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setOpenMessage: (message: string) => void;
  addOrUpdate: (user: UserProfile) => void;
};

type Role = {
  id: number;
  role: string;
  checked: boolean;
};

let submitFunction: any = null;

const EditUser = (props: EditUserProps) => {
  const [databaseRoles, setDatabaseRoles] = useState([]);
  const [roles, setRoles] = useState([] as Array<any>);

  /**
   * effect
   */
  useEffect(() => {
    // get roles from server
    const url = UrlEnum.companyUsers + "/userRoles";
    get(url).then((response) => {
      if (!response.errors) {
        setDatabaseRoles(response);
        if (response.length !== 0) {
          const showRoles = [
            response[0],
            {
              id: `${response[1].id}, ${response[2].id}, ${response[3].id}`,
              role: `${response[1].role}, ${response[2].role}, ${response[3].role}`,
            },
            {
              id: `${response[4].id}, ${response[5].id}`,
              role: `${response[4].role}, ${response[5].role}`,
            },
            response[6],
            response[7],
          ];
          setRoles(showRoles);
        }
      }
    });
  }, []);

  let onSubmit = () => {
    if (props.user.user_roles.length <= 0) {
      props.setOpenMessage(lang.get("add") + ": " + lang.get("userRoles"));
      return;
    }
    if (submitFunction) {
      submitFunction(props.user);
    }
  };

  /**
   * add roles
   */
  const addSelectedRoles = () => {
    const selectedRoles = roles.filter((role: Role) => role.checked);
    props.addSelectedRoles(selectedRoles, databaseRoles);
  };

  /**
   * change selection
   * @param role
   */
  const handleToggleChange = (role: Role) => {
    role.checked = !role.checked ? true : !role.checked;
    setRoles([...roles]);
  };

  return (
    <>
      <ValidatorForm instantValidate={true} onSubmit={onSubmit}>
        <TextValidator
          fullWidth
          label={lang.get("userName")}
          name="userName"
          value={props.user.userName}
          validators={["required"]}
          errorMessages={[lang.get("fieldRequired")]}
          onChange={props.onChange}
        />
        <TextValidator
          fullWidth
          label={lang.get("mobile")}
          name="mobile"
          value={props.user.mobile}
          onChange={props.onChange}
          validators={["required"]}
          errorMessages={[lang.get("fieldRequired")]}
        />
        <TextValidator
          fullWidth
          label={lang.get("email")}
          name="email"
          value={props.user.email}
          validators={["required", "isEmail"]}
          errorMessages={[lang.get("fieldRequired"), lang.get("emailNotValid")]}
          onChange={props.onChange}
        />
        <TextValidator
          fullWidth
          label={lang.get("password")}
          name="password"
          value={props.user.password}
          type="password"
          onChange={props.onChange}
          validators={["required"]}
          errorMessages={[lang.get("fieldRequired")]}
        />
        <TextValidator
          fullWidth
          label={lang.get("confirm")}
          name="confirmPassword"
          value={props.user.confirmPassword}
          type="password"
          onChange={props.onChange}
          validators={["required"]}
          errorMessages={[lang.get("fieldRequired")]}
        />
        <h3>{lang.get("userRoles")}</h3>
        <Grid container spacing={3}>
          <Grid item md={5}>
            <List dense>
              {props.user.user_roles.map((ur) => (
                <ListItem>
                  <ListItemText primary={ur.role} />
                  <ListItemSecondaryAction>
                    {/* <IconButton onClick={() => props.removeRole(ur)}>
                      <DeleteIcon />
                    </IconButton> */}
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid item md={2}>
            <Button
              variant="contained"
              onClick={addSelectedRoles}
              style={{ position: "relative", top: "45%" }}
            >
              <ArrowBackIosIcon />
            </Button>
          </Grid>
          <Grid item md={5}>
            <h1>{lang.get("roles")}</h1>
            <List dense>
              {roles
                ? roles.map((role: Role, index: number) => (
                    <ListItem key={index}>
                      <ListItemText primary={role.role} />
                      <ListItemSecondaryAction>
                        <Checkbox
                          edge="end"
                          onChange={() => handleToggleChange(role)}
                          checked={role.checked ? true : false}
                          style={{
                            color:
                              index === 3 || index === 4
                                ? roles[0].checked ||
                                  roles[1].checked ||
                                  roles[2].checked
                                  ? theme.palette.header?.main
                                  : "gray"
                                : theme.palette.header?.main,
                          }}
                          inputProps={{ "aria-labelledby": index.toString() }}
                          disabled={
                            index === 3 || index === 4
                              ? roles[0].checked ||
                                roles[1].checked ||
                                roles[2].checked
                                ? false
                                : true
                              : false
                          }
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))
                : ""}
            </List>
          </Grid>
        </Grid>
        <div
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            marginTop: 20,
          }}
        >
          {/* <Button type="submit" variant="contained">{lang.get("save")}</Button> */}
          <FormSubmitButton
            url={UrlEnum.companyUsers}
            getSubmit={(submit) => {
              submitFunction = submit;
            }}
            submitCallback={(response) => props.addOrUpdate(response)}
          />
        </div>
      </ValidatorForm>
    </>
  );
};

export default CompanyUsers;
