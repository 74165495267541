import React from 'react';
import Lang from '../lang';
import FormComponent from './FormComponent';
import { InputLabel, MenuItem, Select, FormControl, Grid, TextField, Divider } from '@material-ui/core';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import CachedDataSingleton from '../cachedDataSingleton';
import MuiPhoneInput from 'material-ui-phone-number';
import { withStyles } from '@material-ui/core/styles';
import VatNo from '../Components/VatNo';
import { GoogleMapsUtils, StatusEnum, TitleEnum } from '../Utils/Utils';
import scriptLoader from 'react-async-script-loader';
import { Address } from '../Models/Models';
import ApiLayer from '../Utils/ApiLayer';
import MessageDialog from '../Components/MessageDialog';
import Alert from '@material-ui/lab/Alert';
import Config from '../Utils/Config';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import '../App.css';

const styles = theme => ({
    fullWidth: {
        width: "100%",
    },
    noMargin: {
        margin: 0
    },
    noPaddingTop: {
        paddingTop: "0 !important"
    },
    gMap: {
        height: 400
    }

});
class EditClient extends FormComponent {

    /**
     * constructor
     * @param {Object} props 
     */
    constructor(props) {
        super(props);
        this.lang = Lang.getInstance();
        this.cachedData = CachedDataSingleton.getInstance();
        this.mapWrapperRef = React.createRef();
        this.editedModel = null;
        this.state = {
            messageAlert: false,
            loading: false,
            messageSucces: false,
            textMessage: "",
            model: props.model
        };
        this.isVatVerified = 0;
        this.apiLayer = new ApiLayer();
    }

    /**
     * props have changed
     * @param {Object} nextProps 
     * @param {Object} prevState 
     */
    static getDerivedStateFromProps (nextProps, prevState) {
        if (!prevState.model) {
            return { model: nextProps.model };
        } else {
            if (nextProps.model.id !== prevState.model.id) {
                return { model: nextProps.model };
            }
        }
        return null;
    }
    /**
     * 
     */
    handleCloseMessage () {
        if (this.isVatVerified === 1) {
            this.setState({ "messageAlert": false, "loading": false });
        } else {
            this.setState({ "messageAlert": false, "loading": false });
        }
    }

    /**
     * update vat no
     * @param {Object} data 
     */
    vatNoBlur (data) {
        let model = Object.assign({}, this.state.model);
        model.vatNo = data;
        this.setState({ "model": model }, () => {
            this.getApiLayerData();
        });
        this.getApiLayerData();
    }

    /**
       * 
       */
    getApiLayerData () {
        /**
         * Verify if more than 2 request are made or Vat no is shorter than 11
         */
        if (this.state.model.id || this.isVatVerified > 2 || this.state.model.vatNo.length < 11) {
            return;
        }
        /**
         * Make loading circle vissible
         */
        this.setState({ "loading": true });
        this.apiLayer.getInfoFromApi(this.state.model.vatNo, function (data) {
            this.isVatVerified++;
            /**
             * Check first if vatNo is write OK and after put second message to complet manually the fields
             */
            if (data.valid === false) {
                this.setState({ "messageAlert": true });
                if (this.isVatVerified === 1)
                    this.setState({ "textMessage": this.lang.get("vatNo") + " " + this.lang.get("invalid") + "! " + this.lang.get("tryAgain") + "." });
                if (this.isVatVerified === 2)
                    this.setState({ "textMessage": this.lang.get("insertCompanyInfo") });
                return;
            }
            /**
             * Complete data from Api
             */

            let m = this.state.model;
            m.name = data.company_name ? data.company_name : "";
            let str = data.company_address ? data.company_address : "";
            let sir = str.split('\n');
            const address = sir[0];
            const indexSpace = sir[1].indexOf(' ');
            let code = sir[1].substr(0, indexSpace);
            let city = sir[1].substr(indexSpace).trim();
            m.address.address = address;
            m.address.code = code;
            m.address.city = city;
            /**
             * Succese message for 7 seconds
             */
            this.setState({ "loading": false, "messageSucces": true });
            const renderTimeout = 7 * 1000;
            setTimeout(() => {
                this.setState({ "messageSucces": false });
            }, renderTimeout);
        }.bind(this), function (response) {
            /**
             * Check for any error on API
             */

            this.setState({ "loading": false, "textMessage": this.lang.get("insertCompanyInfo"), "messageAlert": true });

        }.bind(this));
    }

    /**
     * Change phone number
     * @param {string} value 
     */
    handlePhoneNoChange (value) {
        let model = Object.assign({}, this.state.model);
        model.mobile = value;
        this.setState({ "model": model });
    }


    handleAddressBlur (event) {
        this.locate();
    }

    /**
     * get current location
     */
    locate () {
        if (this.gmu) {
            var that = this;
            if (this.state.model.id === 0 && this.state.model.address.address === "") {
                this.gmu.getCurrentLocation()
                    .catch(() => {
                        this.gmu.getCurrentLocationGAapi().then((args) => {
                            if (args && args.location) {
                                that.setLocatedAddress(args.location.lat, args.location.lng, true);
                            }
                        });
                    })
                    .then((position) => {
                        if (position && position.coords) {
                            that.setLocatedAddress(position.coords.latitude, position.coords.longitude, true);
                        }
                    });
            } else {
                const addressStr = Address.stringify(this.state.model.address);
                this.gmu.geolocateAddress(addressStr).then((data) => {
                    if (data && data.results.length > 0) {
                        if (data.results[0].geometry.location_type !== "APPROXIMATE") {
                            // that.setLocatedAddress(data.results[0].geometry.location.lat, data.results[0].geometry.location.lng, true);
                            this.addMapsMarker(data.results[0].geometry.location.lat, data.results[0].geometry.location.lng);
                            that.setLocatedAddressComponents(data.results);
                        } else {
                            this.props.showSmallMessage(this.lang.get("addressNotFound"), StatusEnum.ERROR);
                        }
                    }
                });
            }
        }
    }

    /**
     * reverse geolocate address from latlng, save the located address for further ussage
     * @param lat
     * @param lng
     */
    setLocatedAddress (lat, lng, setCenter) {
        this.addMapsMarker(lat, lng, setCenter);
        this.gmu.geocodeLatLng(lat, lng)
            .catch((args) => {
                console.log(args);
            })
            .then((results) => {
                if (!results) return
                if (results.length < 1) {
                    this.setState({ "errors": this.lang.get("address") + " " + this.lang.get("notFound") });
                    return;
                }
                this.setLocatedAddressComponents(results);
            });
    }

    setLocatedAddressComponents (results) {
        let address = {};
        for (let opt of results[0].address_components) {
            switch (opt.types[0]) {
                case 'street_number':
                    if (!address.address) {
                        address.address = opt.long_name || "";
                    } else {
                        address.address += " " + opt.long_name || "";
                    }
                    break;
                case 'route':
                    if (!address.address) {
                        address.address = opt.long_name || "";
                    } else {
                        address.address = opt.long_name + " " + address.address || "";
                    }
                    break;
                case 'locality':
                    address.city = opt.long_name || "";
                    break;
                case 'country':
                    let country = this.cachedData.get("countries").find((country) => country.iso.toUpperCase() === opt.short_name.toUpperCase());
                    if (country) {
                        address.fk_countryId = country.id;
                    }
                    break;
                case 'postal_code':
                    address.code = opt.long_name || "";
                    break;
                default:
                    break;
            }
        }
        if (!address.code) {
            address.code = "";
        }
        let model = {...this.state.model};
        model.address = address;
        this.setState({ "model": model });
    }

    /**
     * add maps marker if we find the address or show warning
     * @param {number} lat
     * @param {number} lng
     * @param {boolean} setCenter
     */
    addMapsMarker (lat, lng, setCenter) {
        var that = this;
        if (this.marker) {
            this.marker.setMap(this.gmu.map);
            this.gmu.moveMarker(this.marker, lat, lng);
        } else {
            this.marker = this.gmu.addMarker(lat, lng);
        }
        // @ts-ignore
        // eslint-disable-next-line no-undef
        google.maps.event.clearListeners(this.gmu.map, 'click');
        this.gmu.map.addListener('click', function (event) {
            let position = {
                lat: event.latLng.lat(),
                lng: event.latLng.lng()
            };
            that.marker.setPosition(position);
            that.setLocatedAddress(position.lat, position.lng, false);
        });

        if (setCenter === undefined || (setCenter && setCenter === true)) {
            this.gmu.map.setCenter(this.marker.getPosition());
        }
        return this.marker;
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        if (prevState.model.id !== this.state.model.id) {
            this.locate();
        }
    }

    /**
     * render method
     */
    render () {
        if (this.props.isScriptLoaded) {
            if (!this.gmu) {
                this.gmu = new GoogleMapsUtils({
                    parent: this.mapWrapperRef
                });
                this.locate();
            }
        }
        if(this.state.success)
            if(this.props.onClose) 
                this.props.onClose();
        const { classes } = this.props;
        const vatRates = this.cachedData.get("vatTypes");
        const countries = this.cachedData.get("countries");
        return (
            <>
                <h3 style={{ marginLeft: 20 }}>{this.lang.get("edit")} {this.lang.get("client")}</h3>
                <div style={{ padding: 20 }}>
                    <ValidatorForm onSubmit={this.submit.bind(this)}>
                        <Grid container spacing={6} >

                            <Grid item xs={12} sm={12} md={6} className={classes.noPaddingTop}>
                                {/** vat no  */}
                                <VatNo required={false} onBlur={this.vatNoBlur.bind(this)} value={this.state.model.vatNo}></VatNo>
                                {/** title  */}
                                <FormControl className={classes.fullWidth}>
                                    <InputLabel >{this.lang.get("title")}</InputLabel>
                                    <Select
                                        value={this.state.model.title || ""}
                                        onChange={this.handleSelectChange.bind(this)}
                                    >
                                        {TitleEnum.map((val) =>
                                            <MenuItem value={val} id="title" key={val}>{val}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>

                                {/** name  */}
                                <TextValidator
                                    label={this.lang.get("name")}
                                    className={classes.fullWidth}
                                    name="name"
                                    value={this.state.model.name}
                                    onChange={this.handleInputChange.bind(this)}
                                    validators={['required']}
                                    errorMessages={[this.lang.get("fieldRequired")]} />

                                {/** phone number  */}
                                <MuiPhoneInput
                                    className={classes.fullWidth}
                                    onlyCountries={Config.phonePrefixCountries}
                                    defaultCountry='be'
                                    required={true}
                                    id="mobile"
                                    enableLongNumbers
                                    label={this.lang.get("mobile")}
                                    onChange={this.handlePhoneNoChange.bind(this)}
                                    name="mobile"
                                    value={this.state.model.mobile}
                                    regions={'europe'}
                                    validators={['minNumber:5']}
                                />
                                <br />
                                <a
                                    style={{ display: "block", marginTop: 5, marginBottom: 15 }}
                                    href={`tel:${this.state.model.mobile}`}
                                    className="link">
                                    <PhoneIphoneIcon style={{ position: 'relative', top: 5 }} /> {this.state.model.mobile}
                                </a>

                                {/** email  */}
                                <TextValidator
                                    className={classes.fullWidth}
                                    label={this.lang.get("email")}
                                    onChange={this.handleInputChange.bind(this)}
                                    name="email"
                                    value={this.state.model.email}
                                    validators={['required']}
                                    errorMessages={[this.lang.get("fieldRequired"), this.lang.get("emailNotValid")]}
                                />


                            </Grid>

                            <Grid item xs={12} sm={12} md={6} className={classes.noPaddingTop}>


                                {/** vat rate  */}
                                <FormControl className={classes.fullWidth}>
                                    <InputLabel >{this.lang.get("vatRate")}</InputLabel>
                                    <Select
                                        native
                                        id="fk_VATTypeId"
                                        value={this.state.model.fk_VATTypeId}
                                        onChange={this.handleSelectChange.bind(this)}
                                    >
                                        {vatRates.map((val) =>
                                            <option value={val.id} key={val.id} id="fk_VATTypeId">{val.description}</option>
                                        )}
                                    </Select>
                                </FormControl>

                                {/** discount  */}
                                <TextValidator
                                    label={`${this.lang.get("discount")} %`}
                                    className={classes.fullWidth}
                                    name="discount"
                                    value={this.state.model.discount || ""}
                                    onChange={this.handleInputChange.bind(this)}
                                    validators={['minNumber:0', 'maxNumber:100']} errorMessages={[this.lang.get("number")]} />


                                <TextField
                                    name="bankAccount"
                                    onChange={this.handleInputChange.bind(this)}
                                    label={this.lang.get("bankAccount")}
                                    className={classes.fullWidth}
                                    value={this.state.model.bankAccount || ""}
                                />

                                <TextField
                                    name="bankName"
                                    onChange={this.handleInputChange.bind(this)}
                                    label={this.lang.get("bankName")}
                                    className={classes.fullWidth}
                                    value={this.state.model.bankName || ""}
                                />

                                <TextField
                                    variant="filled"
                                    name="alertInfo"
                                    onChange={this.handleInputChange.bind(this)}
                                    label={this.lang.get("alertInfo")}
                                    className={classes.fullWidth}
                                    value={this.state.model.alertInfo || ""}
                                />
                                

                            </Grid>
                        </Grid>
                        <br /><br />
                        <Divider />
                        <br /><br />
                        <Grid container spacing={6} >
                            <Grid item xs={12} sm={12} md={6} className={classes.noPaddingTop}>
                                {/** address  */}
                                <TextValidator
                                    name="address.address"
                                    onBlur={this.handleAddressBlur.bind(this)}
                                    onChange={this.handleInputChange.bind(this)}
                                    label={this.lang.get("address")}
                                    className={classes.fullWidth}
                                    value={this.state.model.address.address || ""}
                                    validators={['required']}
                                    errorMessages={[this.lang.get("fieldRequired")]}
                                />

                                {/** city  */}
                                <TextValidator
                                    onBlur={this.handleAddressBlur.bind(this)}
                                    name="address.city"
                                    onChange={this.handleInputChange.bind(this)}
                                    label={this.lang.get("city")}
                                    className={classes.fullWidth}
                                    value={this.state.model.address.city || ""}
                                    validators={['required']}
                                    errorMessages={[this.lang.get("fieldRequired")]}
                                />

                                {/** code  */}
                                <TextField
                                    name="address.code"
                                    onBlur={this.handleAddressBlur.bind(this)}
                                    onChange={this.handleInputChange.bind(this)}
                                    label={this.lang.get("code")}
                                    className={classes.fullWidth}
                                    value={this.state.model.address.code || ""}
                                />

                                {/** country  */}
                                <FormControl className={classes.fullWidth}>
                                    <InputLabel >{this.lang.get("country")}</InputLabel>
                                    <Select
                                        native
                                        onBlur={this.handleAddressBlur.bind(this)}
                                        id="address.country"
                                        value={this.state.model.address.fk_countryId}
                                        onChange={this.handleSelectChange.bind(this)}
                                    >
                                        {countries.map((country) =>
                                            <option selected={this.state.model.address.fk_countryId === country.id} value={country.id} id="address.fk_countryId" key={country.id}>{country.name}</option>
                                        )}
                                    </Select>
                                </FormControl>
                                {
                                    this.state.messageSucces ?
                                        <Alert severity="success">
                                            {this.lang.get("vatNo") + " OK"}
                                        </Alert> : ""
                                }

                                <p>&nbsp;</p>
                                {super.render()}
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} className={classes.noPaddingTop}>
                                <div id="mapsWrapped" ref={this.mapWrapperRef} className={classes.gMap}></div>
                            </Grid>
                        </Grid>

                        <MessageDialog open={this.state.messageAlert} text={this.state.textMessage} onResult={this.handleCloseMessage.bind(this)} />
                    </ValidatorForm>
                </div>
            </>
        );
    }
}

export default withStyles(styles)(scriptLoader("https://maps.google.com/maps/api/js?key=AIzaSyBAfEAPud1Ushe8qqMUCsEoUoH9hzk16ok&language=en")(EditClient));