import { InputLabel, Paper } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import moment from "moment";
import React, { useEffect, useState } from "react";
import CachedDataSingleton from "../../../cachedDataSingleton";
import Lang from "../../../lang";
import { get, monthNumber, UrlEnum } from "../../../Utils/Utils";
import { useHistory } from "react-router-dom";

const WithChartsFilters = (WrappedComponent: any, type: string) => {
  function WithChartsFilters(props: any) {
    const lang = Lang.getInstance();
    const history = useHistory();
    require("moment/locale/" + lang.get("language"));
    const cachedData = CachedDataSingleton.getInstance();
    const [state, setState] = useState(getState());

    useEffect(() => {
      let copyHistory = history.location.state as any;
      if (!copyHistory) 
        copyHistory = [];
      if(!copyHistory[type])
      { 
        copyHistory[type] ={
          selectedYear: new Date().getFullYear(),
          selectedStartYear: new Date().getFullYear(),
          selectedEndYear: new Date().getFullYear(),
          overview: cachedData.get("overview"),
          years: overviewYears(),
          startMonth: 0,
          endMonth: 11,
        };
      }
      history.replace({ state: copyHistory });
    }, []);

    function getState(){
      const copyHistory = history.location.state as any;
      if(copyHistory)
        return copyHistory[type];
      return {
        selectedYear: new Date().getFullYear(),
        selectedStartYear: new Date().getFullYear(),
        selectedEndYear: new Date().getFullYear(),
        overview: cachedData.get("overview"),
        years: overviewYears(),
        startMonth: 0,
        endMonth: 11,
      }
    }
    /**
     * Return a array with all years from 2015 to the current year
     * @returns
     */
    function overviewYears() {
      const currentYear = new Date().getFullYear();
      let startYear = 2015;
      let years = [2015];
      while (startYear < currentYear) {
        startYear++;
        years.push(startYear);
      }
      return years;
    }

    /**
     *
     * @param year
     */
    async function getOverviewFromYear(
      startMonth: string | null,
      startYear: string | null,
      endMonth: string | null,
      endYear: string | null
    ) {
      const selectedStartMonth =
        startMonth !== null ? startMonth : state.startMonth;
      const selectedStartYear =
        startYear !== null ? startYear : state.selectedStartYear;
      const selectedEndMonth = endMonth !== null ? endMonth : state.endMonth;
      const selectedEndYear =
        endYear !== null ? endYear : state.selectedEndYear;
      let response = await get(
        UrlEnum.newOverview + selectedStartYear + "/" + selectedEndYear
      );
      if (response.error) return;
      setState({
        ...state,
        ...{
          startMonth: parseInt(selectedStartMonth.toString()),
          selectedStartYear: parseInt(selectedStartYear.toString()),
          endMonth: parseInt(selectedEndMonth.toString()),
          selectedEndYear: parseInt(selectedEndYear.toString()),
          overview: response
        }
      });
      const copyHistory = history.location.state as any;
        if(copyHistory)
        {
           const newDate = {
            selectedYear: new Date().getFullYear(),
            selectedStartYear: parseInt(selectedStartYear.toString()),
            selectedEndYear:parseInt(selectedEndYear.toString()),
            overview: response,
            years: overviewYears(),
            startMonth: parseInt(selectedStartMonth.toString()),
            endMonth: parseInt(selectedEndMonth.toString()),
          };
          copyHistory[type] = newDate;
          history.replace({ state: copyHistory });
        }
    }
    /**
     *
     */
    function handleChangeDateAndMonth(
      startDate: any | null,
      endDate: any | null
    ) {
      if (!startDate && !endDate) return;
      if (
        endDate?.isBefore(
          moment(
            monthNumber()[state.startMonth] + "-01-" + state.selectedStartYear,
            "MM-DD-YYYY"
          )
        )
      )
        return;
      if (
        startDate?.isAfter(
          moment(
            monthNumber()[state.endMonth] + "-01-" + state.selectedEndYear,
            "MM-DD-YYYY"
          )
        )
      )
        return;
      if (startDate) {
        const md = startDate;
        const value = md.toDate();
        const year = value.getFullYear();
        const month = value.getMonth();
        setState({
          ...state,
          ...{
            selectedStartYear: year,
            startMonth: month
          }
        });
        getOverviewFromYear(month, year, null, null);
      }
      if (endDate) {
        const md = startDate;
        const value = endDate.toDate();
        const year = value.getFullYear();
        const month = value.getMonth();
        setState({
          ...state,
          ...{
            selectedEndYear: year,
            endMonth: month
          }
        });
        getOverviewFromYear(null, null, month, year);
      }
    }

    return (
      <Paper variant="outlined" style={{padding:20, width:"100%"}}>
        <div style={{ display:"flex", marginBottom: 15 }}>
          <DatePicker
            variant="inline"
            openTo="year"
            views={["year", "month"]}
            label={lang.get("startDate")}
            style={{ marginLeft: 10 }}
            value={moment(
              state.selectedStartYear + "-" + (state.startMonth + 1) + "-01",
              "YYYY-MM-DD"
            )}
            onChange={event => {
              handleChangeDateAndMonth(event, null);
            }}
          />
          <InputLabel style={{ paddingTop: 23 }}>{lang.get("to")}</InputLabel>
          <DatePicker
            variant="inline"
            openTo="year"
            views={["year", "month"]}
            label={lang.get("endDate")}
            style={{ marginLeft: 10 }}
            value={moment(
              state.selectedEndYear + "-" + (state.endMonth + 1) + "-01",
              "YYYY-MM-DD"
            )}
            onChange={event => handleChangeDateAndMonth(null, event)}
          />
        </div>
        <WrappedComponent {...state} />
      </Paper>
    );
  }
  return WithChartsFilters;
};

export default WithChartsFilters;
