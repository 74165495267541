const Config = {
    apiUrl: "/api",
    noImage: "/images/noImage.jpg",
    apiLayerKey:["6f1592a620affbea9b30a1fb9c35da34","fa2d5a4390abfb903ee418a3dc23d670","9e0694b09d2a2ce289e198d2ddd4f721","174f1d0ef2b212b3fdceba6116f3f38b","8407c74f11b075c6a90ca04f19ebfc52"],
    apiLayerUrl:"http://apilayer.net/api/validate",
    momentUSDateFormat:'YYYY-MM-DD',
    momentUSDateFormatWithTime:'YYYY-MM-DD kk:mm',
    momentEUDateFormat:'DD-MM-YYYY',
    momentEUDateFormatWithTime:'DD-MM-YYYY kk:mm',
    momentEUDateFormatForHour:"kk:mm",
    noOfDecimals:2,
    defaultVatRate:21,
    phonePrefixCountries: ["be","de","nl","fr","es","ro","gb","it","pl"],
    autoSaveDocTimeout: 60*2*1000, // 2 min
    costInvoiceDefaultPaymentDelay:30,
    costInvoiceDefaultPaymentDefault:15,
};

export default Config;