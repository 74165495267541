/* eslint-disable no-undef */
import React from "react";
import Lang from "../../lang";
import FormComponent from "../../Forms/FormComponent";
import {
  Grid,
  Typography,
  Divider,
  TextField,
  Checkbox,
} from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";
import { withStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Slider from "@material-ui/core/Slider";
import CachedDataSingleton from "../../cachedDataSingleton";
import theme from "../../Theme/Theme";

const iOSBoxShadow =
  "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";

const marks = [
  {
    value: 0,
  },
  {
    value: 25,
  },
  {
    value: 50,
  },
  {
    value: 75,
  },
  {
    value: 100,
  },
];

const marksCurency = [
  {
    value: 0,
  },
  {
    value: 75,
  },
  {
    value: 150,
  },
  {
    value: 225,
  },
  {
    value: 300,
  },
];

const styles = (theme) => ({
  fullWidth: {
    width: "100%",
  },

  noPaddingTop: {
    paddingTop: "0 !important",
  },

  center: {
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      flexDirection: "column",
      alignContent: "center",
    },
  },
  group: {
    color: "#3880ff",
  },
  fontsize1: {
    fontsize: 100,
  },
});
const AntSwitch = withStyles((theme) => ({
  root: {
    width: 50,
    height: 28,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 3,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(22px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 22,
    height: 22,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 30 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

const IOSSlider = withStyles({
  root: {
    color: "#3880ff",
    height: 2,
    padding: "15px 0",
  },
  thumb: {
    height: 28,
    width: 28,
    backgroundColor: "#fff",
    boxShadow: iOSBoxShadow,
    marginTop: -14,
    marginLeft: -14,
    "&:focus, &:hover, &$active": {
      boxShadow:
        "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        boxShadow: iOSBoxShadow,
      },
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 12px)",
    top: -22,
    "& *": {
      background: "white",
      color: "#000",
    },
  },
  track: {
    height: 2,
  },
  rail: {
    height: 2,
    opacity: 0.5,
    backgroundColor: "#bfbfbf",
  },
  mark: {
    backgroundColor: "#bfbfbf",
    height: 8,
    width: 1,
    marginTop: -3,
  },
  markActive: {
    opacity: 1,
    backgroundColor: "currentColor",
  },
})(Slider);

class OtherAccountInfo extends FormComponent {
  /**
   * constructor
   * @param {Object} props
   */
  constructor(props) {
    super(props);
    this.lang = Lang.getInstance();
    this.cachedDataSingleton = CachedDataSingleton.getInstance();
    this.mapWrapperRef = React.createRef();
    this.value = parseInt(this.state.model.continuousInvoiceNumbers);
    if (this.value === 0) {
      this.checked = true;
    } else {
      this.checked = false;
    }
  }
  /**
   *
   */
  componentWillUnmount() {
    if (this.props.isModal) {
      // eslint-disable-next-line no-restricted-globals
      this.submit(new SubmitEvent("submit"));
    }
  }
  handleChangeNotificationDelay = (event, newValue) => {
    const m = Object.assign({}, this.state.model);
    m.notificationDelay = newValue;
    this.setState({ model: m });
  };

  /**
   *
   */
  handleChangeReminderDelay = (event, newValue) => {
    const m = Object.assign({}, this.state.model);
    m.reminderDelay = newValue;
    this.setState({ model: m });
  };

  /**
   * change timetracking pause
   * @param {*} event
   * @param {*} newValue
   */
  handleChangeTimeTrackingPause = (event, newValue) => {
    const m = Object.assign({}, this.state.model);
    m.timeTrackingPause = newValue;
    this.setState({ model: m });
  };

  /**
   *
   */
  handleChangeReminderNotificationAmount = (event, newValue) => {
    const m = Object.assign({}, this.state.model);
    m.reminderNotificationMinAmount = newValue;
    this.setState({ model: m });
  };
  handleChangeTradeMargin = (event) => {
    const m = Object.assign({}, this.state.model);
    m.tradeMargin = event.target.value;
    this.setState({ model: m });
  };
  handleChangeCheckBoxConstruction(event) {
    const m = Object.assign({}, this.state.model);
    m.workInConstruction = event.target.checked;
    this.setState({ model: m });
  }
  /*
   *
   */
  handleChangeTimekeeping = (event, newValue) => {
    const m = Object.assign({}, this.state.model);
    m.timekeeping = newValue;
    this.setState({ model: m });
  };
  /**
   *
   */
  handleChangeContinuousInvoiceNumbers = (event, newValue) => {
    const m = Object.assign({}, this.state.model);
    if (newValue) {
      m.continuousInvoiceNumbers = 0;
      this.checked = true;
    } else {
      m.continuousInvoiceNumbers = 1;
      this.checked = false;
    }
    this.setState({ model: m });
  };
  submitOptions(e){
    this.submit(e);
    this.props.closeModal();
  }

  /**
   * render method
   */
  render() {
    const { classes } = this.props;
    return (
      <div style={{ padding: 20 }}>
        <ValidatorForm onSubmit={this.submitOptions.bind(this)}>
          <Typography>{this.lang.get("timekeeping")}</Typography>
          <br></br>
          <br></br>
          <Grid container spacing={6}>
            <Grid item xs={6} sm={6} md={4} className={classes.noPaddingTop}>
              <RadioGroup
                aria-label="Gender"
                name="timetracking"
                defaultValue={this.props.model.timekeeping}
                onChange={this.handleChangeTimekeeping.bind(this)}
              >
                <FormControlLabel
                  value={"perHour"}
                  control={<Radio className={classes.group} />}
                  label={this.lang.get("perHour")}
                />
                <FormControlLabel
                  value={"perDay"}
                  control={<Radio className={classes.group} />}
                  label={this.lang.get("perDay")}
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={6} sm={6} md={8} className={classes.noPaddingTop}>
              <Typography>
                {this.lang.get("dailyBreak")}{" "}
                <em>({this.lang.get("minutes")})</em>
              </Typography>
              <IOSSlider
                aria-label="pause slider"
                onChange={this.handleChangeTimeTrackingPause.bind(this)}
                defaultValue={parseInt(this.props.model.timeTrackingPause) | 30}
                marks={marks}
                valueLabelDisplay="on"
              />
            </Grid>
          </Grid>
          <Divider></Divider>
          <br></br>
          <Typography>{this.lang.get("continuousInvoiceNumbers")}</Typography>
          <br></br>
          <Grid component="label" container alignItems="center" spacing={1}>
            <Grid item>
              <Typography>{this.lang.get("no")}</Typography>
            </Grid>
            <Grid item>
              <AntSwitch
                checked={this.checked}
                onChange={this.handleChangeContinuousInvoiceNumbers}
              />
            </Grid>
            <Grid item>
              <Typography>{this.lang.get("yes")}</Typography>
            </Grid>
          </Grid>
          <br></br>
          <Typography>
            {this.lang.get("notificationDelay") +
              " (" +
              this.lang.get("days") +
              ")"}
          </Typography>
          <IOSSlider
            aria-label="ios slider"
            onChange={this.handleChangeNotificationDelay.bind(this)}
            defaultValue={parseInt(this.props.model.notificationDelay)}
            marks={marks}
            valueLabelDisplay="on"
          />
          <br></br>
          <Divider></Divider>
          <br></br>
          <Typography>
            {this.lang.get("reminderDelay") +
              " (" +
              this.lang.get("days") +
              ")"}
          </Typography>
          <IOSSlider
            name="notificationDelay"
            onChange={this.handleChangeReminderDelay.bind(this)}
            aria-label="ios slider"
            defaultValue={parseInt(this.props.model.reminderDelay)}
            marks={marks}
            valueLabelDisplay="on"
          />
          <br></br>
          <Divider></Divider>
          <br></br>
          <Divider></Divider>
          <br></br>
          <Typography>
            {this.lang.get("reminderNotificationMinAmount") +
              " (" +
              this.lang.get("curency") +
              ")"}
          </Typography>
          <IOSSlider
            aria-label="ios slider"
            max={300}
            onChange={this.handleChangeReminderNotificationAmount.bind(this)}
            defaultValue={parseInt(
              this.props.model.reminderNotificationMinAmount
            )}
            marks={marksCurency}
            valueLabelDisplay="on"
          />
          <br></br>
          <Divider></Divider>
          <br></br>
          <TextField
            type="number"
            name="tradeMargin"
            required
            value={this.state.model.tradeMargin}
            onChange={this.handleChangeTradeMargin.bind(this)}
            label={this.lang.get("tradeMargin")}
            fullWidth
          />
          <br></br>
          <Divider></Divider>
          <br></br>
          <FormControlLabel
            label={this.lang.get("workInConstruction")}
            control={
              <Checkbox
              checked={ typeof this.state.model.workInConstruction ==  'string' ? !!parseInt(this.state.model.workInConstruction):this.state.model.workInConstruction}
              onChange={this.handleChangeCheckBoxConstruction.bind(this)}
                style={{ color: theme.palette.header?.main }}
                size="large"
              />
            }
          />
          <br></br>
          <Divider></Divider>
          <br></br>
          <Grid item xs={12} sm={12} md={12} className={classes.center}>
            {super.render()}
          </Grid>

        </ValidatorForm>
      </div>
    );
  }
}

export default withStyles(styles)(OtherAccountInfo);
