/* eslint-disable react/prop-types */
import React, { Component} from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import withStyles from '@material-ui/styles/withStyles';
import { withRouter } from 'react-router-dom';
import Description from '@material-ui/icons/Description';
import Receipt from '@material-ui/icons/Receipt';
import People from '@material-ui/icons/People';
import ConfirmationNumber from '@material-ui/icons/ConfirmationNumber';
import Main from './Main'
import Dashboard from './Dashboard'

// function onChange (){
//   const [index, onChange] = useState(0);
// }

class  InstagramTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0
    };
  }
  
  current()  {
    if(this.index===0) {
      return (<Main></Main>)
    }
    if(this.index === 1) {
      return(<Dashboard></Dashboard>)
    }

  }
  onChangeTabIndex(e, val){
    this.setState({"index":val});
    console.log(e);
    console.log(val);
  }

  render(){
   
    const { classes} = this.props;
  return (
    
  
     <Tabs  centered value={this.state.index}  onChange={this.onChangeTabIndex.bind(this)}>
       
      <Tab className={classes.label} label="Documente" disableRipple icon={<Description className={classes.wrapper}/> } >     
       
      </Tab>
      <Tab className={classes.label} label="Facturi" disableRipple icon={<Receipt className={classes.wrapper}/>} > 
      </Tab>
 
    <Tab className={classes.label} label="Divizii" disableRipple icon={<People className={classes.wrapper}/>} />
 
      <Tab className={classes.label} label="Bonuri" disableRipple icon={<ConfirmationNumber className={classes.wrapper}/>}/>
     
    </Tabs>
   
    
    
    
  );
  }};

const styles = theme => ({
  MuiTabs: {
    root: {
      borderTop: "1px solid #efefef",
      overflow: "visible"
    },
    fixed: {
      overflowX: "visible"
    },
    indicator: {
      height: 1,
      transform: "translateY(-53px)",
      backgroundColor: "#262626"
    }
  },
 
    root: {
      lineHeight: "inherit",
      minWidth: 0,
      "&:not(:last-child)": {
        marginRight: 24,
        [theme.breakpoints.up("sm")]: {
          marginRight: 60
        }
      },
      [theme.breakpoints.up("md")]: {
        minWidth: 0
      }
    },
    labelIcon: {
      minHeight: 53,
      paddingTop: 0
    },
    textColorInherit: {
      opacity: 0.4
    },
  
    wrapper: {
     height:60,
     width:60,
     [theme.breakpoints.up("md")]: {
      height:50,
     width:50,
    }
     
    },

    labelContainer: {
      padding: 0,
      [theme.breakpoints.up("md")]: {
        padding: 0,
        paddingLeft: 0,
        paddingRight: 0
      }
    },
    label: {
      
      letterSpacing: "1px",
      textTransform: "uppercase",
      flexDirection: "row",
      fontSize:0,
      [theme.breakpoints.up("md")]: {
        letterSpacing: "1px",
        textTransform: "uppercase",
        fontSize:15,
      }
      
    }
  
});


export default withRouter(withStyles(styles)(InstagramTabs));
