import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Lang from '../../lang';
import { Paper, Button } from '@material-ui/core';
import GeneralInfo from '../Options/GeneralInfo';
import BankInfo from '../Options/BankInfo';
import AddressInfo from '../Options/AddressInfo';
import OtherInfo from './OtherInfo';
import { UrlEnum, post } from '../../Utils/Utils';
import CachedDataSingleton from '../../cachedDataSingleton';
import { handleInputChange } from '../../Utils/Utils';
import Alert from '@material-ui/lab/Alert';

let lang = Lang.getInstance();
/**
 * 
 * @param {*} props 
 */
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const useStylesTypography = makeStyles(theme => ({
    root: {
      backgroundColor: theme.palette.background.paper,
      width: "100%",
      height: "100%",
      maxWidth: 900,
    },
    tab: {
      [theme.breakpoints.down('md')]: {
        fontSize: 10
      },
    },
  }));

  const styles = useStylesTypography();
  return (
    <Typography
      className={styles.tab}
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

/**
 * 
 */
const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > div": {

      width: "100%",
      height: '120%',
      backgroundColor: "#fff"
    }
  }
})(props => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

/**
 * 
 */
const StyledTab = withStyles(theme => ({
  root: {
    textTransform: "none",
    color: "#fff",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(18),
    wordSpacing: theme.typography.pxToRem(0),
    marginRight: theme.spacing(0),

    [theme.breakpoints.down('md')]: {
      paddingTop: 20,
      fontSize: theme.typography.pxToRem(13),
    },

    "&:focus": {
      opacity: 20,
    },

  }
}))(props => <Tab disableRipple {...props} />);


TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
    height: "100%",
    maxWidth: 900,
    margin: "0 auto"
  },
  tab: {
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: '10%',
    width: '30%',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    [theme.breakpoints.down('md')]: {
      width: '90%',
    },

  },
  tabs: {
    backgroundColor: "#210331"
  },
  submit: {
    margin: "0px 0px 20px 40px"
  }
}));


/**
 * 
 */
export default function FullWidthTabs() {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const cachedData = CachedDataSingleton.getInstance();
  const [model, setModel] = useState(cachedData.get("company"));
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  /**
   * 
   * @param {*} event 
   * @param {*} newValue 
   */
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  /**
   * 
   * @param {*} index 
   */
  const handleChangeIndex = index => {
    setValue(index);

  };

  /**
   * Change phone number
   * @param {string} value 
   */
  function handlePhoneNoChange(value) {
    let m = Object.assign({}, model);
    m.mobile = value;
    setModel(m);
  }

  /**
   * 
   * @param {*} event 
   */
  function handleChange(event) {
    const m = handleInputChange(event, model);
    setModel(m);
  }

  /**
 * 
 * @param {*} logo 
 */
  function handleChangeDropFile(logo) {
    if(logo instanceof Array && logo.length === 0) return;
    let m = Object.assign({}, model);
    m.logo = logo instanceof Array ? logo[0] : logo;
    setModel(m);
  }

  function handleSetSignature(signature) {
    let m = Object.assign({}, model);
    m.signature = signature.blobSignature;
    setModel(m);
  }


  /**
   * 
   */
  function handleSubmit() {
    const formData = new FormData();
    const timeout = 7 * 1000;

    formData.append('name', model.name);
    formData.append('representativeName', model.representativeName);
    formData.append('vatNo', model.vatNo);
    formData.append('web',model.web);
    formData.append('bankAccount', model.bankAccount);
    formData.append('bankName', model.bankName);
    formData.append('bankBic', model.bankBic);

    formData.append('bankAccountSecondary', model.bankAccountSecondary);
    formData.append('bankNameSecondary', model.bankNameSecondary);
    formData.append('bankBicSecondary', model.bankBicSecondary);

    formData.append('address', model.address.address);
    formData.append('city', model.address.city);
    formData.append('code', model.address.code);
    formData.append('fk_countryId', model.address.fk_countryId);
    formData.append('company_options',model.company_options);

    formData.append('logo', model.logo);
    formData.append('signature', model.signature);
    formData.append('description', model.description);
    formData.append('mobile', model.mobile);
    formData.append('package', model.subscription);
    formData.append('emails', model.emails);
    formData.append('companyRegNo', model.companyRegNo);

    post(UrlEnum.companyInfo + "/" + model.id, formData).then(function (data) {
      if (data.errors) {
        setError(data.errors);
        setTimeout(() => {
          setError("");
        }, timeout);
        return;
      }
      data.company_options=model.company_options;
      setModel(data);
      cachedData.setKey("company", model);
      setMessage(lang.get("success"));
      setTimeout(() => {
        setMessage("");
      }, timeout);

    });
  }

  return (
    <Paper className={classes.root}  >
      <AppBar position="static" color="default" >
        <StyledTabs
          value={value}
          onChange={handleTabChange}
          indicatorColor="primary"
          style={{background:theme.palette.header?.main}}
          variant="fullWidth"
          className={classes.tabs}
        >
          <StyledTab label={lang.get("generalInfo")} {...a11yProps(0)} />
          <StyledTab label={lang.get("bankInfo")} {...a11yProps(1)} />
          <StyledTab label={lang.get("address")} {...a11yProps(2)} />
          <StyledTab label={lang.get("other")} {...a11yProps(3)} />
        </StyledTabs>

      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}

      >
        <TabPanel value={value} index={0} >
          <GeneralInfo
            model={model}
            phoneNoChange={handlePhoneNoChange}
            onChange={handleChange}
          />

        </TabPanel>
        <TabPanel value={value} index={1} >
          <BankInfo
            model={model}
            onChange={handleChange}
          />
        </TabPanel>
        <TabPanel value={value} index={2} >
          <AddressInfo
            model={model}
            onChange={handleChange}
            countries={cachedData.get("countries")}
          />
        </TabPanel>
        <TabPanel value={value} index={3} >
          <OtherInfo
            model={model}
            onChange={handleChange}
            onFileDrop={handleChangeDropFile}
            onSetSignature={handleSetSignature}
          />
        </TabPanel>
      </SwipeableViews>
      <Button
        classes={{ root: classes.submit }}
        style={{background:theme.palette.header?.main, color:theme.palette.textColorSecondary?.main}}
        variant="contained"
        type="submit"
        onClick={handleSubmit}
      >
        {lang.get("save")}
      </Button>
      {
        error ?
          <Alert severity="error">
            {error}
          </Alert> : ""
      }
      {
        message ?
          <Alert severity="success">
            {message}
          </Alert> : ""
      }
    </Paper>
  );
}


